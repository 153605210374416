import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, FormControl, makeStyles, Select, InputLabel, MenuItem, TextField, FormLabel, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import capitalize from "capitalize";
import Autocomplete from '@material-ui/lab/Autocomplete';

import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ICompanyContact, ICompanyPerEmailContact } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { Company } from "../../../Api/Company/Company";
import { Driver } from "../../../Api/Driver/Driver";
import { AppState } from "../../../Redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { USER_ROLES } from "../../../Types/Constants";
import SingleAutocomplete from "../../Common/SingleAutocomplete/SingleAutocomplete";
import { IAutoCompleteResponse } from "../../Common/types";
import { IEmailListDialogProps } from "./EmailListDialog.types";
import { AdminSettings } from "../../../Api/AdminSettings/AdminSettings"
import { IRatesManagement } from "../../../../../Application/Models/RatesManagementSettingsModel/RatesManagement.types";
import { isEqual, isNil } from 'lodash';
import MultiAutocomplete from "../../Common/MultiAutocomplete/MultiAutocomplete";
import { reponseToOption } from "../../../Utilities/Autocomplete";
import { DataGrid, GridColumns, GridValueGetterParams, GridRowsProp, GridCellValue, GridApi, GridCellParams, GridCellEditCommitParams } from "@material-ui/data-grid";
import BuySellDialog from "./BuySellDialog";
import { QuotesApi } from '../../../Api/Quotes/QuotesApi';
import { TShowTypes } from "../../Home/Quotes/Quotes.types";
import { PENDING, FAILED, QUOTES_WILDCARDS_KEYS, SUCCESSFUL, ACTIVE } from "../../Home/Quotes/Quotes.constants";
import { useRouteMatch } from 'react-router-dom';

const {
    COMPANY,
    DRIVER
} = USER_ROLES

const companyApi = new Company()
const driverApi = new Driver()
const adminSettingApi = new AdminSettings()
const quotesApi = new QuotesApi();

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    gap: {
        marginTop: 20,
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
    },
}));
function EmailListDialog(props: IEmailListDialogProps) {
    const [t] = useTranslation('ShowQuotes')
    const { open, handleClose, companyId, country, onSubmit, formData, selectedQuoteEmails, mode, selectedQuote } = props
    const [selectedEmails, setSelectedEmails] = useState<IAutoCompleteResponse[]>([])
    // const [selectedEmails, setSelectedEmails] = useState<IAutoCompleteResponse[]>([])

    const [emailOptions, setEmailOptions] = useState<IAutoCompleteResponse[]>([])
    const [companyContact, setCompanyContact] = useState<ICompanyPerEmailContact[]>([])

    const [companyOptions, setCompanyOptions] = useState<any>({})
    const [selectedCompany, setSelectedCompany] = useState<any>('')

    const [oldSelections, setOldSelections] = useState<IAutoCompleteResponse[]>([])

    const [isBuyRateOpen, setIsBuyRateOpen] = useState<boolean>(false)
    const [hasApproved, setHasApproved] = useState<boolean>(false)

    const user = useSelector((state: AppState) => state.user)
    const match = useRouteMatch();
    const classes = useStyles();

    const params: any = match.params;

    const showType: TShowTypes | undefined = (params[QUOTES_WILDCARDS_KEYS.SHOW_TYPE] as any) || PENDING;

    const [selectedDataForRates, setSelectedDataForRates] = useState<IAutoCompleteResponse>()
    const [selectRateBuy, setSelectRateBuy] = useState('0')

    const dispatch = useDispatch()

    const handleOnEmailSelect = (values: any) => {
        setSelectedEmails(values)
    }
    useEffect(() => {
        setOldSelections(selectedQuoteEmails)
    }, [selectedQuoteEmails])

    useEffect(() => {
        if (selectedQuoteEmails) {
            const approvedArr = selectedQuoteEmails.map((x: any) => x.is_approve == true)
            setHasApproved(approvedArr.includes(true))
        }
    }, [selectedQuote, open])

    const getEmailList = useCallback(
        (country: string) => {
            dispatch(setLoader(true))
            companyApi.getEmailAccounts(country, undefined, (mode == 'create') ? formData.get('company_id') : selectedQuote.company_id)
                .then(async (response) => {
                    let companies: any = response.data

                    if (selectedQuoteEmails) {
                        const companyNames = selectedQuoteEmails.map((x) => x.company_name)
                        for (let x = 0; x < companyNames.length; x++) {
                            delete companies[companyNames[x]]
                        }
                    }

                    dispatch(setLoader(false))
                    setCompanyOptions(companies)
                })
                .catch(err => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_get_company_list'))
                })
        },
        [dispatch, t, selectedQuote, formData, selectedQuoteEmails],
    )
    useEffect(() => {
        if (open) {
            getEmailList(country)
            setSelectedCompany('')
            setSelectedEmails([])
        }
    }, [open, mode])


    const handleSendOnly = async () => {
        const filteredList: IAutoCompleteResponse[] = selectedEmails.filter(
            ({ value: id1 }) => !oldSelections.some(({ value: id2 }) => id2 === id1)
        );
        if (filteredList.length > 0) {
            const merged = oldSelections.concat(filteredList)
            setSelectedCompany('')
            setSelectedEmails([])
            const formData = new FormData();
            formData.append('email_list', JSON.stringify(filteredList.map((opt: any) => opt)))
            quotesApi.sendEmails(formData, selectedQuote.id)
                .then(async (response) => {
                    toast.success(t('quotes_sent'))
                    setTimeout(async () => {
                        await quotesApi.getQuoteResponse(selectedQuote.id).then(async (response: any) => {
                            const companies: ICompanyContact[] = response.data
                            const holder = await companies.map<IAutoCompleteResponse>(reponseToOption)
                            await setOldSelections(holder)
                        })
                    }
                        , 2500)

                })
                .catch((err: any) => {
                    console.log('err', err)
                })

        }


    }


    const columns: GridColumns = [
        { field: 'company_name', headerName: t('company_name'), width: 100, editable: false, flex: 1 },
        { field: 'label', headerName: t('email'), width: 100, type: 'number', flex: 1 },
        {
            editable: [PENDING, ACTIVE].includes(showType),
            field: 'buy_rate', headerName: t('buy_rate'), width: 100, type: 'number', flex: 1,
            valueGetter: (params: GridValueGetterParams) => {
                return params.value
            },
        },
        {
            field: 'actions', headerName: t('actions'), width: 100, type: 'number', flex: 1,
            hide: [FAILED].includes(showType),
            renderCell: (params) => {
                if (params.row['is_approve']) {
                    return <Typography>{t('approved')}</Typography>

                }
                {
                    if (hasApproved) {
                        return <Typography></Typography>

                    }
                    if (params.row['buy_rate']) {
                        return <Button onClick={(event: any) => {

                            let selectedData: IAutoCompleteResponse = params.row as IAutoCompleteResponse
                            setSelectedDataForRates(selectedData)
                            setIsBuyRateOpen(true)
                            event.stopPropagation();
                            const api: GridApi = params.api;
                            const thisRow: Record<string, GridCellValue> = {};
                            api.getAllColumns()
                                .filter((c) => c.field !== "__check__" && !!c)
                                .forEach(
                                    (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
                                );

                            setSelectRateBuy(thisRow['buy_rate'] as any)
                        }
                        } variant="contained" color="primary">{t('approve')}
                        </Button>
                    }
                }
            }
        },

    ];

    const handleBuySellClose = useCallback(
        () => {
            setIsBuyRateOpen(false)
        },
        [showType],
    )

    const handleBuySellSubmit = useCallback((buy_rate: any, sell_rate: any, approve: any, id: any = undefined) => {
        quotesApi.approveQuoteResponse((id) ? id : selectedDataForRates.id, buy_rate, sell_rate, approve)
            .then((response) => {
                if (approve) {
                    handleClose(true)
                    toast.success(t('approved'))
                } else {
                    toast.success(t('buy_rate_set'))
                }
                setIsBuyRateOpen(false)

            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }, [selectedDataForRates])

    const companyListOptions = useMemo(
        () =>
            (Object.keys(companyOptions)).map((data, id) => (
                <MenuItem key={data} value={data}>
                    {data}
                </MenuItem>
            )),
        [companyOptions]
    );
    const handleSelectCompanyChange = (key: any) => {

        setSelectedCompany(key)
        if (key) {
            let companies: ICompanyContact[] = companyOptions[key]
            setSelectedEmails([])
            let newList = [{
                id: companies[0].id + "_0",
                company_name: companies[0].name,
                value: companies[0].id,
                email: companies[0].user.email,
                type: 'general_email',
                label: companies[0].user.email,
                buy_rate: ''
            }]

            if (!newList.some(function (value) {return this[0] == value.email;}, [companies[0].contact_email])) {
                newList.push(
                    {
                        id: companies[0].id + "_1",
                        company_name: companies[0].name,
                        value: companies[0].id,
                        email: companies[0].contact_email,
                        type: 'account_email',
                        label: companies[0].contact_email,
                        buy_rate: ''
                    })
            }
            if (!newList.some(function (value) {return this[0] == value.email;}, [companies[0].operation_email])) {
                newList.push({
                    id: companies[0].id + "_2",
                    company_name: companies[0].name,
                    value: companies[0].id,
                    email: companies[0].operation_email,
                    type: 'operation_email',
                    label: companies[0].operation_email,
                    buy_rate: ''

                })
            }
            setCompanyContact(newList)
        }
    }


    const handleCellEditCommit = useCallback(
        ({ id, field, value }: GridCellEditCommitParams) => {
            if (field == 'buy_rate') {
                handleBuySellSubmit(value, undefined, false, id.toString().split('_')[0])
            }
        },
        [],
    );


    return (
        <>
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t("quote_responses")}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                        style={(mode != 'create') ? { 'overflow': 'hidden' } : {}}
                    >
                        {(mode != 'create') ?
                            <Grid item style={{ "height": '300px' }} md={12}>
                                <DataGrid
                                    rows={oldSelections}
                                    columns={columns}
                                    onCellEditCommit={handleCellEditCommit}
                                />
                            </Grid>
                            : null
                        }
                        {
                            (![FAILED].includes(showType)) ?
                                <Grid item md={12}>
                                    <Grid item container md={12} spacing={1}>

                                        <Grid item md={6}>
                                            <FormControl variant="filled" className={classes.formControl}>
                                                <InputLabel className={classes.selectLabel}>
                                                    {t('select_company') + '*'}
                                                </InputLabel>
                                                <Select
                                                    value={selectedCompany}
                                                    onChange={(e) => {
                                                        handleSelectCompanyChange(e.target.value)
                                                    }}
                                                >
                                                    {companyListOptions}
                                                </Select>

                                            </FormControl>

                                        </Grid>
                                        <Grid item md={8}>
                                            <Autocomplete
                                                multiple
                                                onChange={(e, values) => {
                                                    handleOnEmailSelect(values)
                                                }}
                                                options={companyContact}
                                                disableCloseOnSelect
                                                getOptionLabel={(option: ICompanyPerEmailContact) => option.email}
                                                renderOption={(option: ICompanyPerEmailContact) => (
                                                    <React.Fragment>
                                                        <div style={{ 'color': 'gray' }}>{t(option.type)} &nbsp;</div> {option.email}
                                                    </React.Fragment>
                                                )}
                                                getOptionSelected={(option, value) => isEqual(option.id, value.id)}
                                                // value={selectedEmails}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={t('search_email')}
                                                        variant="outlined"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                                filterSelectedOptions
                                            />
                                            {/* <MultiAutocomplete

                                                onChange={handleOnEmailSelect}
                                                label={t('search_email')}
                                                options={emailOptions as IAutoCompleteResponse[]}
                                                getOptionLabel={(option) => option.label}
                                                renderOption={(option) => (
                                                    <React.Fragment>
                                                        <span>{countryToFlag(option.code)}</span>
                                                        {option.label} ({option.code}) +{option.phone}
                                                    </React.Fragment>
                                                )}
                                                placeholder={t('search_email')}

                                                values={selectedEmails}

                                            /> */}
                                        </Grid>

                                    </Grid>


                                </Grid> :
                                null
                        }


                    </Grid>
                </DialogContent>
                {
                    (![FAILED].includes(showType)) ?

                        < DialogActions >
                            <Button variant="contained" color="primary" onClick={() => {
                                if (mode == 'create') {
                                    // formData.append('email_list', JSON.stringify(selectedEmails.map((opt: any) => opt)))
                                    onSubmit(selectedEmails)

                                } else {
                                    handleSendOnly()
                                }
                            }}>
                                {t("send_quotes")}
                            </Button>
                        </DialogActions>
                        : null
                }

                <BuySellDialog
                    open={isBuyRateOpen}
                    handleClose={handleBuySellClose}
                    buy_rate={selectRateBuy}
                    selectedCompany={selectedDataForRates}
                    onSubmit={handleBuySellSubmit}
                />
            </Dialog >
        </>
    )
}


export default EmailListDialog