import { JOB_TYPES, MOVEMENT_TYPES } from "../Types/Constants";

const {
    SEA_FREIGHT,
    LCL,
    FCL
} = MOVEMENT_TYPES

export const getJobTypes = (movementType: string): string[] => {

    switch (movementType) {
        case SEA_FREIGHT:
            return [
                LCL,
                FCL
            ]
    
        default:
            return Object.values(JOB_TYPES)
    }
}