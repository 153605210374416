import { Dialog, DialogContent, DialogTitle, Typography, Grid, makeStyles, Checkbox, FormControlLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { COUNTRY_INSURANCES, USER_ROLES } from "../../../../Types/Constants";
import { IBiddersInfoDialogProps } from "./BiddersInfoDialog.types";

const useStyles = makeStyles(() => ({
    label: {
        fontWeight: 'bolder'
    }
}))

const {
    DRIVER
} = USER_ROLES

function BiddersInfoDialog(props: IBiddersInfoDialogProps) {
    const classes = useStyles()
    const { open, handleClose, userData } = props
       
    const insurances = userData?.company?.insurances ?? []

    const isTransport = userData?.company?.companyType 

    const [t] = useTranslation('BiddersInfoDialog')

    const INSURANCES = COUNTRY_INSURANCES[userData?.company?.country ?? ''] ?? []

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography>
                    {t('bidders_info')}
                </Typography>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    spacing={2}   
                    justifyContent="flex-start"         
                >
                    <Grid md={3} item>
                        <Typography className={classes.label}>
                            {t('company_name')}
                        </Typography>
                    </Grid>

                    <Grid md={3} item>
                        {userData?.company?.companyName || ''}
                    </Grid>
                    <Grid md={3} item>
                        <Typography className={classes.label}>
                            {t('country')}
                        </Typography>
                    </Grid>

                    <Grid md={3} item>
                        {t(userData?.company?.country || '')}
                    </Grid>

                    <Grid md={3} item>
                        <Typography className={classes.label}>
                            {t('company_type')}
                        </Typography>
                    </Grid>

                    <Grid md={3} item>
                        {t(userData?.company?.companyType || '')}
                    </Grid>

                    <Grid md={3} item>
                        <Typography className={classes.label}>
                            {t('company_abn')}
                        </Typography>
                    </Grid>

                    <Grid md={3} item>
                        {t(userData?.company?.companyUen || '')}
                    </Grid>
                    
                   { isTransport 
                        ?  <>
                                <Grid md={3} item>
                                    <Typography className={classes.label}>
                                        {t('waiting_time')}
                                    </Typography>
                                </Grid>
                
                                <Grid md={3} item>
                                    {userData?.company?.waitTime || 'NA' }
                                </Grid>  
                            </>         
                        : null
                   }

                    <Grid md={6} item>
                        {/* GAP */}
                    </Grid>

                    <Grid md={12} item>
                        <Typography className={classes.label}>
                            {t('insurance_coverage')}
                        </Typography>
                    </Grid>

                    {INSURANCES.map((insurance, i) => (
                        <Grid md={(i % 2) === 0 ? 3 : 9} item>
                            <FormControlLabel
                                control={<Checkbox checked={insurances.includes(insurance)} color="primary" />}
                                label={t(insurance)}
                            />
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default BiddersInfoDialog