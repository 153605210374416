import { Grid, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Warehouse } from '../../../../Api/Warehouse/Warehouse'

import { AppState } from '../../../../Redux'
import { ISiteSelectionProps } from './StocksTypes.types';

import { getColumns } from './StocksPage.columns'
import { USER_ROLES } from '../../../../Types/Constants';
const warehouseApi = new Warehouse()
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    },
    container: {
        padding: '20px'
    }
}))

function SitesSelection(props: ISiteSelectionProps) {
    const [t] = useTranslation(['Warehouse'])
    const classes = useStyles()
    const { onChangeCallback, onClearCallback } = props
    const user = useSelector((state: AppState) => state.user.response);
    const { ADMIN_USER, SUPER_ADMIN, WAREHOUSE, CUSTOMER } = USER_ROLES;
    let userId = user.company.id

    const isCustomer = [CUSTOMER].includes(user?.type);
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user?.type);
    const isWarehouse = [WAREHOUSE].includes(user?.type);
    // if (isCustomer) { userId = user.customer.id }
    // if (isWarehouse) { userId = user.warehouse.id }

    const [companyOptions, setCompanyOptions] = useState<any>([])
    const [selectedFilter, setSelectedFilter] = useState<any>(null)


    const getWarehouseOptions = async () => {
        return warehouseApi.getWarehouseList(isUserAdmin, userId as any)
            .then((response) => {
                const rows = response.data;
                const addresses = (rows.map((x: any) => (x.addresses))).flat()
                setCompanyOptions(addresses.map((x: any) => {
                    return {
                        label: x.address.company_name,
                        value: x.address_id,
                        company_id: x.address.company_id
                    }
                }))

            })
            .catch((err: any) => {
                // toast.error(t('error_getting_customers'));
                return { jobs: [], count: 0 }

            });

    }


    const warehouseListOptions = useMemo(
        () =>
            companyOptions.map((data: any) => (
                <MenuItem key={data.value} id={data.value} value={data.value}  >
                    {data.label}
                </MenuItem>
            )),
        [companyOptions]
    );


    const columns = getColumns(t)
    useEffect(() => {
        getWarehouseOptions()
    }, [])

    useEffect(() => {
        if (onClearCallback) {
            setSelectedFilter(null)
        }
    }, [onClearCallback])
    const handleFilterChange = (e: any) => {
        setSelectedFilter(e.target.value)
        let obj: any = companyOptions.find(o => o.value === e.target.value);
        onChangeCallback(e.target.value, obj.company_id)
    }
    return (
        <>
            <Grid item md={12}>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel style={{ fontWeight: 'bold' }}>{t('warehouse')}</InputLabel>
                    <Select
                        value={selectedFilter}
                        onChange={(e) => {
                            handleFilterChange(e)
                        }}
                    >
                        {warehouseListOptions}
                    </Select>
                </FormControl>
            </Grid>

        </>
    )
}

export default SitesSelection