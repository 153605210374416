import { Divider, Grid, Typography, Button, Box } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import {
  IDeliveryFilter,
  IJobInfo,
  IJobReportFilter,
  IScheduledJobsCount
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../../Redux/Loader/actions';
import { Job } from '../../../../Api/Job/Job';
import { toast } from 'react-toastify';
import { getColumns } from './scheduledDeliveryColumns';
import ViewEditJob from '../../../Dialogs/ViewEditJob/ViewEditJob';
import { GridRowParams } from '@material-ui/data-grid';
import { AppState } from '../../../../Redux';
import { JOB_STATUS, USER_ROLES } from '../../../../Types/Constants';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeliveryReportFilterDialog from '../../../Dialogs/DeliveryFilter/DeliveryFilter';
import moment from 'moment'
import { formatDate } from '../../../../Utilities/DateTime';
import { Paper, makeStyles, styled } from "@material-ui/core";
const { IN_PROGRESS } = JOB_STATUS;

const { ADMIN_USER, COMPANY, SUPER_ADMIN } = USER_ROLES;

const jobApi = new Job();
const useStyles = makeStyles(() => ({
  inbox: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    margin: '0px',
    fontSize: '0.75rem',
    fontWeight: 600,
    letterSpacing: '0.5px',
    lineHeight: '2.5',
    textTransform: 'uppercase',
    fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
    color: ' rgb(108, 115, 127)'
  },
  count: {
    fontWeight: 'bold',
    margin: '30px 0',
    fontSize: '2rem',
    lineHeight: '1.2'
  }
}))

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function ScheduledDeliveryReports() {
  const [t, i18n] = useTranslation('JobReport');
  const classes = useStyles()
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const [isViewEditJobDialogOpen, setIsViewEditJobDialogOpen] = useState(false);
  const [filterState, setFilterState] = useState<IDeliveryFilter>({
    filterType: 'delivery'
  });
  const [jobs, setJobs] = useState<IJobInfo[]>([]);
  const [selectedJob, setSelectedJob] = useState<IJobInfo | null>(null);
  const [adminUser, setIsAdmin] = useState<boolean>(false);

  const isAdminUser = user.response?.type === ADMIN_USER;
  const isSuperAdmin = user.response?.type === SUPER_ADMIN;

  const isCompanyUser = user.response?.type === COMPANY;
  const [isDeliveryReportFilterDialog, setIsDeliveryFilterDialogOpen] =
    useState(false);

  const [reportCounts, setReportCounts] = useState<IScheduledJobsCount>({
    loose_cargo_jobs: 0,
    interstate_jobs: 0,
    container_jobs: 0
  });
  const getJobsForReport = useCallback(
    (filters: IDeliveryFilter) => {
      let country: string | undefined;

      if (isAdminUser) {
        country = user.response?.country;
      } else if (isCompanyUser) {
        country = user.response?.company?.country;
      }

      filters.date = filters.date ? formatDate(filters.date, 'YYYY-MM-DD') : null
      dispatch(setLoader(true));
      jobApi
        .getDeliveryReports(filters)
        .then((response) => {
          const jobs: IJobInfo[] = response.data;
          setJobs(jobs);
          dispatch(setLoader(false));
        })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_jobs_for_reports'));
          dispatch(setLoader(false));
        });
      jobApi.getDeliveryReportCount(filters).then((response) => {
        const data: IScheduledJobsCount = response.data;
        setReportCounts(data)
      })
    },
    [dispatch, t]
  );

  useEffect(() => {
    getJobsForReport(filterState);

  }, [filterState, getJobsForReport]);

  useEffect(() => {
    setIsAdmin(isSuperAdmin);

  }, [isSuperAdmin, setIsAdmin]);
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isSearching = searchParams.has('id')
    if (isSearching) {
      const searchValue = searchParams.get('id')
      jobApi.jobDetail(searchValue).then(async (response) => {
        const details: IJobInfo = response.data;
        setSelectedJob(details);
        setIsViewEditJobDialogOpen(true);

        dispatch(setLoader(false));
      }).catch((err: any) => {
        toast.error(t('error_getting_job_details'));
        dispatch(setLoader(false));
      });

    }
  }, [])
  const handleJobReportFilterDialogClose = useCallback(() => {
    setIsDeliveryFilterDialogOpen(false);
  }, []);

  const handleOnFiltersApply = useCallback(
    (filters: IDeliveryFilter) => {
      setFilterState(filters);
      getJobsForReport(filters)
      handleJobReportFilterDialogClose();
    },
    [handleJobReportFilterDialogClose]
  );

  const handleFilterClick = useCallback(() => {
    setIsDeliveryFilterDialogOpen(true);
  }, []);


  const columns = getColumns(t);

  const handleOnViewEditJobDialogClose = useCallback(() => {
    setIsViewEditJobDialogOpen(false);
    setSelectedJob(null);
  }, []);

  const onRowClick = useCallback((params: GridRowParams) => {
    const job: IJobInfo = params.row as IJobInfo;
    setSelectedJob(job);
    setIsViewEditJobDialogOpen(true);
  }, []);

  return (
    <>

      {(isAdminUser || isSuperAdmin) ?
        <ViewEditJob

          selectedJob={selectedJob}
          type={selectedJob?.job_classification}
          open={isViewEditJobDialogOpen}
          handleClose={handleOnViewEditJobDialogClose}
        /> :

        <ViewEditJob
          hideActionButtons
          selectedJob={selectedJob}
          type={selectedJob?.job_classification}
          open={isViewEditJobDialogOpen}
          handleClose={handleOnViewEditJobDialogClose}
        />
      }
      <DeliveryReportFilterDialog
        onApplyCallback={handleOnFiltersApply}
        initialFilterState={filterState}
        open={isDeliveryReportFilterDialog}
        handleClose={handleJobReportFilterDialogClose}
        filterType={'delivery'}
      />
      <Grid
        direction="column"
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item md={3}>
          <Typography>{t('scheduled_delivery_report')}</Typography>
        </Grid>
       
        <Grid item md={12}>

          <Grid item md={10}></Grid>
          <Grid item md={2}>
            <Button
              onClick={handleFilterClick}
              fullWidth
              color="primary"
              variant="contained"
              startIcon={<FilterListIcon />}
            >
              {t('filter')}
            </Button>
          </Grid>
        </Grid>
        <Grid item md={12}>
          {/* <Grid container spacing={5}>
            <Grid container item spacing={3}>
              <Grid item xs>
                <Item className={classes.inbox}>
                  <span className={classes.label}>{t('loose_cargo')}</span>
                  <h4 className={classes.count}>{reportCounts.loose_cargo_jobs}</h4>
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item className={classes.inbox}>
                  <span className={classes.label}>{t('interstate')}</span>
                  <h4 className={classes.count}>{reportCounts.interstate_jobs}</h4>
                </Item>
              </Grid>
              <Grid item xs>
                <Item className={classes.inbox}>
                  <span className={classes.label}>{t('container')}</span>
                  <h4 className={classes.count}>{reportCounts.container_jobs}</h4>
                </Item>
              </Grid>

            </Grid>

          </Grid> */}
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={12}>
              <CustomTable
                columns={columns}
                rows={jobs}
                onRowClick={onRowClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
