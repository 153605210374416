import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, makeStyles, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
    // formControl: {
    //     minWidth: '100%',
    // },
    dot :{
        // backgroundColor : 'red',
        paddingTop:'2%',
        height: '30px',
        width: '30px',
        borderRadius: '50%',
        display: 'inline-block',
        textAlign:'center'
        
    },
    text :{
        margin : '0 0 0 10px'
    },
    container : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}))

const colorLegends = [
 
    {
        status : 'Open',
        color :  '#fb4b4b',
    },
    {
        status : 'Assigned',
        color :  '#2cba00'
    },
    {
        status : 'In Progress',
        color :  '#ffa700'
    },
    // {
    //     status : 'Owned',
    //     color :  '#003974'
    // }
]
function Legends() {
    const [t] = useTranslation('')
    const classes = useStyles()

    return (
        <>
        {colorLegends.map((element : any) =>{
         return(<Grid item md={3} key={element.status} className={classes.container}>
                <Grid style={{'backgroundColor' : element.color}} className={classes.dot}></Grid>
                <span className={classes.text}>{element.status}</span>
            </Grid> 
         )
        })
        }
        </>
    )
}

export default Legends