import { Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import capitalize from "capitalize";
import { TFunction } from "i18next";
import { IJobInfo, IJobReports } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import {
    calculateGST,
    computationWaitingTimeCharge,
} from '../../../../Utilities/Computation';
import { formatDate } from '../../../../Utilities/DateTime'



export const getInvoiceReportsColumns = (t: TFunction, access_type: string): GridColumns => {
    return [
        {
            field: 'reference',
            headerName: t('Columns:reference'),
            width: 300
        },
        {
            field: 'customer_reference',
            headerName: t('customer_reference'),
            width: 300
        },
        {
            field: 'postingCompany',
            headerName: t('Columns:posting_company_only'),
            width: 300,
            renderCell: (params) => {
                const job: IJobReports = params.row as IJobReports
                const postingCompanyName = capitalize.words(job.company.name ?? '')
                return (
                    <Typography>
                        {postingCompanyName}
                    </Typography>
                )
            }
        },
        {
            field: 'biddingCompany',
            headerName: t('Columns:bidding_company'),
            width: 500,
            renderCell: (params) => {
                const job: IJobReports = params.row as IJobReports
                const biddingCompanyName = capitalize.words(job.assigned_user?.company?.name || "")
                return (
                    <Typography>
                        {biddingCompanyName}
                    </Typography>
                )
            }
        },
        {
            field: 'job_type_movement_type',
            headerName: t('Columns:job_type_movement_type'),
            width: 300,
            renderCell: (params) => {
                const job: IJobReports = params.row as IJobReports
                return (
                    <Typography>
                        {t(job.job_type)} / {t(job.movement_type)}
                    </Typography>
                )
            }
        },
        {
            field: 'deliver_to',
            headerName: t('Columns:deliver_to'),
            width: 260,
            renderCell: (params) => {
                const job: IJobReports = params.row as IJobReports
                return (
                    <Typography>
                        {job.deliver_to_address.company_name}
                    </Typography>
                )
            }
        },
        {
            field: 'delivery_date',
            headerName: t('Columns:delivery_date'),
            width: 200,
            renderCell: (params) => {
                const jobInfo = params.row;
                const delivery_date = jobInfo.delivery_date_time
                return <Typography>{formatDate(delivery_date)}</Typography>;
            },
        },

        {
            field: 'buy_price',
            headerName: t('Columns:buy_price'),
            width: 150,
            renderCell: (params) => {
                const job: IJobReports = params.row as IJobReports
                return (
                    <Typography>
                        {job.initial_price || ''}
                    </Typography>
                )
            }
        },
        {
            field: 'sell_rate_no_gst',
            headerName: t(`sell_rate_no_gst`),
            width: 170,
            sortable: false,
            renderCell: (params) => {
                const jobInfo: IJobReports = params.row as IJobReports;
                const price: number = Number(jobInfo.job_bids?.[0]?.amount ?? 0);
                const marginPrice: number = Number(jobInfo.margin_price || 0);
                const actualWaitTime: number = Number(jobInfo.job_wait_time || '0');
                const allowWaitingTime: number = jobInfo.job_bids?.[0]?.waitTime ?? 0;
                const waitTimeCharge: number = Number(
                    jobInfo.company?.waitTimeCharge || 0
                );
                let totalWaitTimeCharge = computationWaitingTimeCharge(
                    actualWaitTime,
                    allowWaitingTime,
                    waitTimeCharge
                );
                let gst = calculateGST(
                    price + marginPrice,
                    totalWaitTimeCharge
                );

                return <Typography>{jobInfo.posted_price as unknown as number - gst}</Typography>

            }
        },
        {
            field: 'additional_charges_1',
            headerName: 'Extra Charge 1',
            sortable: false,
            width: 150,
            // hide: ![COMPLETED_JOBS].includes(showType),
            renderCell: (params) => {
                const jobInfo: IJobReports = params.row as IJobReports;
                return <Typography>{(params.row?.additional_charges?.amount1 + params.row?.additional_charges?.amount2).toString() || 0}</Typography>

            },
        },
        // {
        //     field: 'additional_charges_2',
        //     headerName: 'Extra Charge 2',
        //     sortable: false,
        //     width: 150,
        //     // hide: ![COMPLETED_JOBS].includes(showType),
        //     renderCell: (params) => {
        //         const jobInfo: IJobReports = params.row as IJobReports;

        //         return <Typography>{params.row?.additional_charges?.amount2.toString() || 0}</Typography>

        //     },
        // },

        {
            field: 'total_cost',
            headerName: t(`total_cost`),
            sortable: false,
            width: 150,
            renderCell: (params) => {
                const jobInfo: IJobReports = params.row as IJobReports;
                const price: number = Number(jobInfo.job_bids?.[0]?.amount ?? 0);
                const waitTimeCharge: number = Number(
                    jobInfo.company?.waitTimeCharge || 0
                );
                const actualWaitTime: number = Number(jobInfo.job_wait_time || '0');
                const allowWaitingTime: number = jobInfo.job_bids?.[0]?.waitTime ?? 0;
                const marginPrice: number = Number(jobInfo.margin_price || 0);

                let totalWaitTimeCharge = computationWaitingTimeCharge(
                    actualWaitTime,
                    allowWaitingTime,
                    waitTimeCharge
                );

                let gst = calculateGST(
                    price + marginPrice,
                    totalWaitTimeCharge
                );

                const { additional_charges } = jobInfo;
                const amount1 = additional_charges?.amount1 || 0
                const amount2 = additional_charges?.amount2 || 0
                const total_cost = (price / jobInfo.initial_price) + totalWaitTimeCharge + jobInfo.posted_price + (amount2 + amount1) + (gst * .10)
                return <Typography>{total_cost}</Typography>;
            },
        },
        // {
        //     field: 'pick_up_from',
        //     headerName: t('Columns:pick_up_from'),
        //     width: 500,
        //     renderCell: (params) => {
        //         const job: IJobReports = params.row as IJobReports
        //         return (
        //             <Typography>
        //                 {job.pick_up_address?.state + ',' + job.pick_up_address?.street_number || '' + job.pick_up_address?.zipcode}
        //             </Typography>
        //         )
        //     }
        // },

        // {
        //     field: 'accepted_price',
        //     headerName: t('Columns:accepted_price'),
        //     width: 300,
        //     renderCell: (params) => {
        //         const job: IJobReports = params.row as IJobReports
        //         return (
        //             <Typography>
        //                 {(job.job_bids?.[0].amount || 0) + job.margin_price}
        //             </Typography>
        //         )
        //     }
        // },
        // {
        //     field: 'commissionFee',
        //     headerName: t('Columns:commission'),
        //     width: 300
        // },

        {
            field: 'invoice',
            headerName: t('invoice_number'),
            width: 250,
            editable: (access_type == 'uninvoiced') ? true : false,
        },
    ]
}