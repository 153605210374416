
import { makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PRIMARY_COLOR } from '../../../Styles/colors'
import { IStatisticsCountTableProps } from './StatisticsCountTable.types'


const useStyles = makeStyles(() => ({
    table: {
        // minWidth: 500,
    },
    header: {
        backgroundColor: PRIMARY_COLOR + '60'
    }
}))


function StatisticsCountTable(props: IStatisticsCountTableProps) {
    const {title, statistic} = props
    const classes= useStyles()
    const [t] = useTranslation()

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table}>
                    <TableHead className={classes.header}>
                        <TableRow>
                            <TableCell>{title ?? ''}</TableCell>
                            <TableCell>{t('count')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                         <TableRow>
                             <TableCell>{t('current_day')}</TableCell>
                             <TableCell>{statistic?.perDay ?? 0}</TableCell>
                         </TableRow>
                         <TableRow>
                             <TableCell>{t('current_month')}</TableCell>
                             <TableCell>{statistic?.perMonth ?? 0}</TableCell>
                         </TableRow>
                         <TableRow>
                             <TableCell>{t('current_year')}</TableCell>
                             <TableCell>{statistic?.perYear ?? 0}</TableCell>
                         </TableRow>


                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default StatisticsCountTable