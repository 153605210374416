import { Typography } from '@material-ui/core';
import { GridColumns } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { formatDate } from '../../../../Utilities/DateTime';
import { IJobInfo, IProfitability } from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { IUserState } from '../../../../Redux/User/type';
import { COMPANY_TYPES, USER_ROLES } from '../../../../Types/Constants';

import {
  calculateGST,
  computationWaitingTimeCharge,
  getGST,
  getTotalFee,
  getAdditionalCost,
  getProfitability
} from '../../../../Utilities/Computation';



const getTotal = (jobInfo: any, additional_charges: any) => {
  return (jobInfo.posted_price || 0 + additional_charges + jobInfo.commission || 0) - jobInfo.initial_price || 0
}

export const getColumns = (t: TFunction, user: IUserState): GridColumns => {

  const { SUPER_ADMIN, ADMIN_USER } = USER_ROLES

  return [
    {
      field: 'reference',
      headerName: t('Columns:reference'),
      width: 200,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        const job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'transport_reference',
      headerName: t('Columns:transport_reference'),
      width: 200,
    },
    {
      field: 'company',
      headerName: t('Columns:posting_company_only'),
      width: 250,
      // resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const company = jobInfo.company.name;
        return <Typography>{company}</Typography>;
      },
    },
    {
      field: 'transport_company',
      headerName: t('Columns:transport_company'),
      width: 300,
      hide: ![ADMIN_USER, SUPER_ADMIN].includes(user.response?.type ?? ''),
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo

        return (
          <Typography>
            {params.row.assigned_user?.company?.name}
          </Typography>
        )

      }
    },
    {
      field: 'pickup_address',
      headerName: t('Columns:pick_up_suburb'),
      width: 250,
      // resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const address = jobInfo.pick_up_address?.city;
        return <Typography>{address}</Typography>;
      },
    },
    {
      field: 'deliver_address',
      headerName: t('Columns:delivery_suburb'),
      width: 250,
      // resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const address = jobInfo.deliver_to_address?.city;
        return <Typography>{address}</Typography>;
      },
    },
    {
      field: 'quantity',
      headerName: t('Columns:quantity'),
      renderCell: (params) => {
        const jobInfo: IProfitability = params.row as IProfitability;
        const pack_list: any[] = jobInfo.packages_type
        const nums = pack_list.map((x) => parseInt(x['quantity']) || 0)
        const count = nums.reduce((a: any, b: any) => a + b, 0)
        return <Typography>{count}</Typography>;
      },
      // width: 200,
    },
    {
      field: 'posted_price',
      headerName: t('Columns:sell_rate'),
    },
    {
      field: 'initial_price',
      headerName: t('Columns:buy_rate'),
    },
    {
      field: 'commission',
      headerName: t('Columns:moveit4u_commision'),
      width: 150
    },
    {
      field: 'additional_charges',
      headerName: t('Columns:additional_costs'),
      width: 200,
      renderCell: (params) => {

        return <Typography>{getAdditionalCost(params.row)}</Typography>;
      },
    },
    {
      field: 'total_price',
      headerName: t(`Columns:total_price`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        let total_price = getProfitability(jobInfo);

        return <Typography>{total_price}</Typography>;
      },
    },
    {
      field: 'actual_deliver_to_date_time',
      headerName: t('Columns:actual_delivery_date'),
      width: 200,
      renderCell: (params) => {
        const jobInfo = params.row;
        const delivery_date: string = String(
          jobInfo?.actual_deliver_to_date_time
        );
        return <Typography>{formatDate(delivery_date)}</Typography>;
      },
    },
  ];
};
