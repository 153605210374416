import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../../routes';
import Statistics from './Statistics/Statistics';
import Overdues from './Overdues';
import NoPodDocuments from './NoPodDocuments';
import Profitability from './Profitability';
import ScheduledDeliveryReports from './ScheduledDeliveryReport';
import ScheduledCollectionReports from './ScheduledCollectionReport'
import CNTRDehireReports from './CNTRDehireReports';



import OverdueCollections from './Collections';
import CNTRDetentionReports from './CNTRDetentionReports';
import CompletedJobsReport from './CompletedJobsReport';
import CompletedJobReportMileage from './CompletedJobsMileageReport';
import MPDReport from './MPDReport';





export default function Report() {
  const match = useRouteMatch();

  return (
    <>
      {/* /home/report/statistics */}
      <Route path={match.path + routes.home.report.marketplace_mgmnt_stat_report}>
        <Statistics />
      </Route>

      <Route path={match.path + routes.home.report.overdue_deliveries}>
        <Overdues type="transport" />
      </Route>

      <Route path={match.path + routes.home.report.overdue_collection}>
        <OverdueCollections type="transport" />
      </Route>

      <Route path={match.path + routes.home.report.no_pod_documents}>
        <NoPodDocuments />
      </Route>


      <Route path={match.path + routes.home.report.profitability_report}>
        <Profitability />
      </Route>
      <Route path={match.path + routes.home.report.scheduled_delivery_report}>
        <ScheduledDeliveryReports />
      </Route>
      <Route path={match.path + routes.home.report.scheduled_collection_report}>
        <ScheduledCollectionReports />
      </Route>
      <Route path={match.path + routes.home.report.cntr_dehire_report}>
        <CNTRDehireReports />
      </Route>
      <Route path={match.path + routes.home.report.cntr_detention_report}>
        <CNTRDetentionReports />
      </Route>
      <Route path={match.path + routes.home.report.completed_jobs_report}>
        <CompletedJobsReport type="transport" />
      </Route>
      <Route path={match.path + routes.home.report.completed_jobs_mileage_report}>
        <CompletedJobReportMileage type="transport" />
      </Route>
      <Route path={match.path + routes.home.report.mpd_reports}>
        <MPDReport/>
      </Route>
     

    </>
  );
}
