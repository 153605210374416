import { ILanguageState, SET_LANGUAGE, TLanguageActions } from "./types";



const initialState: ILanguageState = {
    selectedLanguage: 'en'
}




export function languageReducer(state: ILanguageState = initialState, actions: TLanguageActions): ILanguageState {
    switch (actions.type) {
        case SET_LANGUAGE:
            const newLanguage: string = actions.payload

            state.selectedLanguage = newLanguage

            return Object.assign({}, state)
    
        default:
            return state
    }
}