import { Dialog, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { IImagePreviewDialogProps } from "./ImagePreviewDialog.types"


const useStyles = makeStyles(() => ({
    img: {
        width: '100%',
        height: '100%',
    }
}))


function ImagePreviewDialog(props: IImagePreviewDialogProps) {
    const {open, handleClose, imageSource, altText} = props
    const [t] = useTranslation()
    const classes = useStyles()

    return (
        <>
            <Dialog maxWidth="lg" open={open} onClose={handleClose}>
                <img 
                    src={imageSource}
                    alt={altText ?? t('image_preview')}
                    className={classes.img}
                    onClick={handleClose}
                />
            </Dialog>
        </>
    )
}

export default ImagePreviewDialog