import { Button, Divider, Grid, Popover, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IJobFilters, IJobInfo } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { Job } from "../../../../Api/Job/Job";
import { setLoader } from "../../../../Redux/Loader/actions";
import CustomTable from "../../../Common/CustomTable/CustomTable";
import { ALL } from "../../Jobs/Jobs.constans";
import { getInvoiceReportsColumns } from './InvoiceReports.columns'
import SaveIcon from '@material-ui/icons/Save';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterPopover from "../../../Common/FilterPopover/FilterPopover";
import jsFileDownload from 'js-file-download'
import LazyTable from '../../../Common/LazyTable/LazyTable';

const jobApi = new Job()

function InvoiceReports() {
    const [t] = useTranslation('InvoiceReports')
    const dispatch = useDispatch()
    const [completedJobs, setCompletedJobs] = useState<IJobInfo[]>([])
    const [popOverAnchorEl, setPopOverAnchorEl] = useState(null)
    const [filters, setFilters] = useState<IJobFilters>({
        jobType: ALL,
        vehicleType: ALL
    })
    const [refreshTable, setRefreshTable] = useState<boolean>(true)


    const getCompletedJobs = useCallback(
        (country?: string, filters?: IJobFilters) => {
            dispatch(setLoader(true))
            jobApi.get(ALL, 'completed_jobs', country,filters)
                .then(response => {
                    setCompletedJobs(response.data)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log(`err`, err)
                    toast.error(t('error_getting_jobs'))
                    dispatch(setLoader(false))
                })

        },
        [dispatch, t],
    )

    const lazyGet = async( offset : number , pageNumber : number , limit :number , jobFilters : any)=>{
   

        // dispatch(setLoader(true));
        return jobApi
          .lazyGet(ALL, 'completed_jobs', filters.country, filters ,undefined,undefined, offset,pageNumber,limit)
          .then((response) => {
              const jobs: IJobInfo[] = response.data.jobList;
              const count = response.data.jobsCount;
              return {jobs , count }
          })
          .catch((err :any) => {
            const jobs: IJobInfo[] = [];
            const count = 0;
            console.log('err', err);
            toast.error(t('error_getting_jobs'));
            return {jobs , count }
            
          });
        
      }
    

    useEffect(() => {
        setRefreshTable(!refreshTable)

    }, [filters])


    const columns = getInvoiceReportsColumns(t)

    const handlePopOverOpen = useCallback(
        (e) => {
            setPopOverAnchorEl(e.currentTarget)
        },
        [],
    )

    const handlePopOverClose = useCallback(
        () => {
            setPopOverAnchorEl(null)
        },
        [],
    )

    const handleFilterChange = useCallback(
        (filter: IJobFilters) => {
            setFilters(filter)
        },
        [],
    )

    const handleDownloadAsCSV = useCallback(
        () => {
            dispatch(setLoader(true))
            jobApi.getInvoiceReportCSV(filters)
                .then(response => {
                    jsFileDownload(response.data, `report-${Date.now()}.csv`)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log(`err`, err)
                    toast.error(t('error_downloading_csv'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, filters],
    )

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid item>
                    <Typography>
                        {t('invoice_reports')}
                    </Typography>
                </Grid>
               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item md={3}>
                            <Button onClick={handlePopOverOpen} fullWidth startIcon={<FilterListIcon />} variant="contained" color="primary">
                                {t('filter')}
                            </Button>
                            <Popover
                                open={Boolean(popOverAnchorEl)}
                                anchorEl={popOverAnchorEl}
                                onClose={handlePopOverClose}

                            >
                                <FilterPopover hidePickUpDateSelector filters={filters} onFilterChange={handleFilterChange} />
                            </Popover>
                        </Grid>
                        <Grid item md={12}>
                            <LazyTable
                                columns={columns}
                                // rows={completedJobs}
                                lazyFilter={filters}
                                lazyFunction={lazyGet}
                                refresh={refreshTable}
                            />
                        </Grid>
                        <Grid item md={4}>
                            <Button onClick={handleDownloadAsCSV} startIcon={<SaveIcon />} fullWidth variant="contained" color="primary">
                                {t('download_as_csv')}
                            </Button>
                        </Grid>
                        {/* <Grid item md={4}>
                            <Button fullWidth variant="contained" startIcon={<SaveIcon />} color="primary">
                                {t('download_as_pdf')}
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default InvoiceReports