import { GridRowsProp } from "@material-ui/data-grid"

const baseUrl = process.env.REACT_APP_FILE_URL

export const formatFileUrl = (fileName: string) =>  `${baseUrl}/${fileName}`


export const formatForTableRows = (items: any[]): GridRowsProp => items.map((item, i) => ({
    id: i,
    ...item
}))

export const generateJobReportFileNameCsv = (): string => `job-report-${Date.now()}.csv`