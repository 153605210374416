import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button, TextField, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Address } from '../../../../Api/Address/Address';
import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import CountrySelect from '../../../Dialogs/CreateAddressDialog/CountrySelect';
import { useTranslation } from 'react-i18next';
import { COUNTRIES, ADDRESS_GROUPS, NZ_STATES, AU_STATES, STATES_BY_COUNTRY } from "../../../../Types/Constants";
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete'

const addressApi = new Address();




const AddressSelector = ({
    addresses,
    companyId,
    disabled,
    isEditMode,
    onEditClick,
    handleAddressSelected,
    handleSelectedGroup,
    handleSelectedState,
    handleSelectedCountry,
    defaultCountry,
    defaultState,
    defaultGroupType,
    defaultCompany,
    refresh,
    addressEvent
}) => {
    const { t } = useTranslation('CreateNewJob');
    const [selectedCountry, setSelectedCountry] = useState(defaultCountry || '');
    const [selectedState, setSelectedState] = useState(defaultState || '');
    const [selectedGroupType, setSelectedGroupType] = useState(defaultGroupType || '');
    const [selectedCompany, setSelectedCompany] = useState(defaultCompany || '');
    // const [addresses, setAddresses] = useState<IAddress[]>([]);
    const dispatch = useDispatch();



    const countriesArray = useMemo(
        () => Array.from(new Set(addresses.map((item) => item.country).filter((item) => item !== '' && item !== null))),
        [addresses]
    );

    const statesArray = useMemo(() => {
        if (!selectedCountry || !STATES_BY_COUNTRY[selectedCountry]) {
            return [];
        }

        // Return the state values directly from the selected country
        return Object.values(STATES_BY_COUNTRY[selectedCountry]);
    }, [selectedCountry]);

    const groupTypesArray = useMemo(() => {
        if (!selectedState) {
            return [];
        }
    
        // Directly return the values from ADDRESS_GROUPS
        return Object.values(ADDRESS_GROUPS);
    }, [selectedState]);

    const companiesArray = useMemo(() => {
        return Array.from(new Set(
            addresses
                .filter((item) => {
                    if (!selectedState && !selectedGroupType && !selectedCountry) {
                        return true;
                    }
                    return (selectedState ? item.state === selectedState : true) &&
                        (selectedGroupType ? item.address_group === selectedGroupType : true) && (selectedCountry ? item.country === selectedCountry : true);
                })
                .map((item) => ({
                    id: item.id,
                    name: item.company_name || '',
                    street_number: item.street_number || '',
                    city: item.city || '',
                    state: item.state || '',
                    address_group: item.address_group || '',
                    country: item.country || ''
                }))
        ));
    }, [addresses, selectedCountry ,selectedState, selectedGroupType]);

    useEffect(() => {
        setSelectedCountry(selectedCountry);
        setSelectedGroupType('');
        setSelectedCompany('');
    }, [selectedCountry, statesArray, defaultState, selectedCountry]);

    useEffect(() => {
        setSelectedState((prevState) => (statesArray.includes(defaultState) ? defaultState : ''));
        setSelectedGroupType('');
        setSelectedCompany('');
    }, [selectedCountry, statesArray, defaultState]);

    useEffect(() => {
        setSelectedGroupType((prevGroupType) => (groupTypesArray.includes(defaultGroupType) ? defaultGroupType : ''));
        setSelectedCompany('');
    }, [selectedState, groupTypesArray, defaultGroupType]);

    useEffect(() => {
        setSelectedCompany((prevCompany) =>
            companiesArray.some((company: any) => company.id == defaultCompany) ? defaultCompany : ''
        );
    }, [selectedGroupType, companiesArray, defaultCompany]);

    return (
        <Grid item container direction='row' spacing={2}>
            <Grid item xs={12} sm={12} md={4}>

                <CountrySelect
                    value={selectedCountry}
                    onChange={(e) => {
                        setSelectedCountry(e.target.value)
                        handleSelectedCountry(e)
                    }}
                    disabled={disabled}
                    isPreview={disabled}
                    isEditable={disabled}
                    t={t}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>

                <FormControl fullWidth variant="outlined" disabled={disabled || !selectedCountry}>
                    <InputLabel>{t('state')}</InputLabel>
                    <Select
                        value={selectedState}
                        onChange={(e) => {
                            setSelectedState(e.target.value);
                            handleSelectedState(e);
                        }}
                        label={t('state')}
                    >
                        <MenuItem value="">
                            <em>Select {t('state')}</em>
                        </MenuItem>
                        {statesArray.map((state: any) => (
                            <MenuItem key={state} value={state}>
                                {t(state)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth variant="outlined" disabled={disabled || !selectedState}>
                    <InputLabel>{t('pickup_select_group')}</InputLabel>
                    <Select
                        value={selectedGroupType}
                        onChange={(e) => {
                            setSelectedGroupType(e.target.value);
                            handleSelectedGroup(e);
                        }}
                        label={t('pickup_select_group')}
                    >
                        <MenuItem value="">
                            <em>Select {t('pickup_select_group')}</em>
                        </MenuItem>
                        {groupTypesArray.map((type: any) => (
                            <MenuItem key={type} value={type}>
                                {t(type)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} container spacing={1} sm={12} md={12}>

                <Grid item xs={10} sm={10} md={4} lg={4}>
                    <FormControl fullWidth variant="outlined" disabled={disabled}>
                        <Autocomplete
                            options={companiesArray}
                            getOptionLabel={(option: any) => {
                                return `${option.name} - ${option.street_number}, ${option.city}, ${option.state}` || '';
                            }}
                            value={companiesArray.find((company: any) => company.id == selectedCompany) || null}
                            onChange={(e: any, newValue: any) => {
                                if (newValue) {
                                    setSelectedCountry(newValue.country)
                                    setTimeout(() => {
                                        setSelectedState(newValue.state)
                                    }, 75)

                                    setSelectedCompany(newValue.id);
                                    setTimeout(() => {
                                        setSelectedGroupType(newValue.address_group)

                                    }, 100)

                                    handleAddressSelected({ target: { value: newValue.id } });
                                } else {
                                    setSelectedCompany('');
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('pickup_select_address')}
                                    variant="outlined"
                                />
                            )}
                            renderOption={(option: any) => (
                                <li style={{ listStyle: 'none' }}>
                                    <div>
                                        {option.name ? (
                                            <>
                                                <strong style={{ 'color': "gray" }}>{option.name}</strong> - {option.street_number || ''}, {option.city || ''}, {option.state || ''}
                                            </>
                                        ) : (
                                            `${option.street_number || ''}, ${option.city || ''}, ${option.state || ''}`
                                        )}
                                    </div>
                                </li>
                            )}

                        />
                    </FormControl>
                </Grid>

                <Grid alignContent='center' item xs={2} sm={2} md={1} lg={1}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => {
                            onEditClick(selectedCompany);
                        }}
                        disabled={!selectedCompany}
                    >
                        {t('Edit')}
                    </Button>

                </Grid>
                <Grid alignContent='center' item xs={3} sm={3} md={3} lg={3}>
                    <Button
                        fullWidth
                        onClick={addressEvent}
                        startIcon={<AddIcon />}
                        color="primary"
                        variant="contained"
                    >
                        {t('add_new_address')}
                    </Button>

                </Grid>

            </Grid>
        </Grid>
    );
};

export default AddressSelector;
