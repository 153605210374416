import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, TextField, Typography, Box, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IJobReportFilterDialogProps } from "./JobReportFilterDialog.types";
import { MobileDatePicker } from "@material-ui/pickers";
import { IJobReportFilter } from "../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { JOB_STATUS } from "../../../Types/Constants";
import { inArray } from "../../../Utilities/Array";
import { deepCopy } from "../../../Utilities/Object";
import { startOfDay, endOfDay } from 'date-fns'

const {
    COMPLETED,
    IN_PROGRESS,
    PICKEDUP,
    DELIVERED
} = JOB_STATUS

const useStyles = makeStyles(() => ({
    invisible: {
        opacity: 0
    },
    box1: { width: '20%' },
    box2: { width: '80%' }
}))

function JobReportFilterDialog(props: IJobReportFilterDialogProps) {
    const [t] = useTranslation()
    const { open, handleClose, initialJobReportFilterState, onApplyCallback } = props
    const classes = useStyles()
    const [filterState, setFilterState] = useState<IJobReportFilter>(initialJobReportFilterState ?? { job_status: [COMPLETED] })
    const [completedDateFrom, handleCompletedDateFrom] = useState<Date | null>(filterState.completed_date_range?.from ?? null)
    const [completedDateTo, handleCompletedDateTo] = useState<Date | null>(filterState.completed_date_range?.to ?? null)
    const [isJobStatusCompletedSelected, setIsJobStatusCompletedSelected] = useState(filterState.job_status.includes(COMPLETED))
    const [isJobStatusInProgressSelected, setIsJobStatusInProgressSelected] = useState(filterState.job_status.includes(IN_PROGRESS))
    const [isJobStatusPickedUpSelected, setIsJobStatusPickedUpSelected] = useState(filterState.job_status.includes(PICKEDUP))
    const [isJobStatusDeliveredSelected, setIsJobStatusDeliveredSelected] = useState(filterState.job_status.includes(DELIVERED))

    useEffect(() => {
        const filter: IJobReportFilter = {
            job_status: []
        }

        if (completedDateFrom && completedDateTo) {
            filter.completed_date_range = {
                from: startOfDay(completedDateFrom),
                to: endOfDay(completedDateTo)
            }
        }

        if (isJobStatusCompletedSelected) {
            if (!inArray(filter.job_status, COMPLETED))
                filter.job_status.push(COMPLETED)
        } else {
            filter.job_status = filter.job_status.filter(status => status !== COMPLETED)
        }

        if (isJobStatusInProgressSelected) {
            if (!inArray(filter.job_status, IN_PROGRESS))
                filter.job_status.push(IN_PROGRESS)
        } else {
            filter.job_status = filter.job_status.filter(status => status !== IN_PROGRESS)
        }

        if (isJobStatusPickedUpSelected) {
            if (!inArray(filter.job_status, PICKEDUP))
                filter.job_status.push(PICKEDUP)
        } else {
            filter.job_status = filter.job_status.filter(status => status !== PICKEDUP)
        }

        if (isJobStatusDeliveredSelected) {
            if (!inArray(filter.job_status, DELIVERED))
                filter.job_status.push(DELIVERED)
        } else {
            filter.job_status = filter.job_status.filter(status => status !== DELIVERED)
        }

        setFilterState(deepCopy(filter))
    }, [completedDateFrom, completedDateTo, isJobStatusCompletedSelected, isJobStatusInProgressSelected, isJobStatusPickedUpSelected, isJobStatusDeliveredSelected])

    const handleOnApplyClick = useCallback(
        () => {
            if(onApplyCallback) {
                onApplyCallback(filterState)
            }
        },
        [filterState, onApplyCallback],
    )

    return (
        <>
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    {t('job_report_filter')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Box className={classes.box1}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">{t('job_status')}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={isJobStatusCompletedSelected} onChange={(e) => setIsJobStatusCompletedSelected(e.target.checked)} />
                                        }
                                        label={t('completed')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={isJobStatusInProgressSelected} onChange={(e) => setIsJobStatusInProgressSelected(e.target.checked)} />
                                        }
                                        label={t('in_progress')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={isJobStatusPickedUpSelected} onChange={(e) => setIsJobStatusPickedUpSelected(e.target.checked)} />
                                        }
                                        label={t('picked_up')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={isJobStatusDeliveredSelected} onChange={(e) => setIsJobStatusDeliveredSelected(e.target.checked)} />
                                        }
                                        label={t('delivered')}
                                    />
                                </FormGroup>
                            </FormControl>
                        </Box>
                        <Box className={classes.box2}>
                            <Grid
                                container
                                spacing={1}
                            >
                                <Grid item md={6}>
                                    <Typography gutterBottom>
                                        {t('completed_date_range')}
                                    </Typography>
                                    <MobileDatePicker
                                        renderInput={props => <TextField
                                            fullWidth
                                            variant="outlined"
                                            {...props}
                                            label={t('from')}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />}
                                        value={completedDateFrom}
                                        onChange={handleCompletedDateFrom}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <Typography className={classes.invisible} gutterBottom>
                                        '
                                    </Typography>
                                    <MobileDatePicker
                                        renderInput={props => <TextField
                                            fullWidth
                                            variant="outlined"
                                            {...props}
                                            label={t('to')}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />}
                                        value={completedDateTo}
                                        onChange={handleCompletedDateTo}
                                    />
                                </Grid>
                            </Grid>

                        </Box>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnApplyClick} color="primary" variant="contained">
                        {t('apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default JobReportFilterDialog