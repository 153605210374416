import { Button, Divider, Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../Common/CustomTable/CustomTable";
import FilterListIcon from '@material-ui/icons/FilterList';
import SaveIcon from '@material-ui/icons/Save';
import JobReportFilterDialog from "../../../Dialogs/JobReportFilterDialog/JobReportFilterDialog";
import { IJobInfo, IJobReportFilter } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { JOB_STATUS } from "../../../../Types/Constants";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../../Redux/Loader/actions";
import { Job } from "../../../../Api/Job/Job";
import { toast } from "react-toastify";
import { getJobReportMileageColumns } from "./JobReportMileage.columns";
import ViewEditJob from "../../../Dialogs/ViewEditJob/ViewEditJob";
import { GridRowParams } from "@material-ui/data-grid";
import fileDownload from 'js-file-download'
import { generateJobReportFileNameCsv } from "../../../../Utilities/Formatting";
import SearchIcon from '@material-ui/icons/Search';
import { IJobReportMileageProps } from "./JobReportMileage.types";

const {
    COMPLETED
} = JOB_STATUS

const jobApi = new Job()

function JobReportMileage(props: IJobReportMileageProps) {
    const { type } = props
    const [t] = useTranslation('JobReport')
    const dispatch = useDispatch()
    const [isJobReportFilterDialogOpen, setIsJobReportFilterDialogOpen] = useState(false)
    const [isViewEditJobDialogOpen, setIsViewEditJobDialogOpen] = useState(false)
    const [filterState, setFilterState] = useState<IJobReportFilter>({ job_status: [COMPLETED] })
    const [jobs, setJobs] = useState<IJobInfo[]>([])
    const [selectedJob, setSelectedJob] = useState<IJobInfo | null>(null)
    const [searchByReference, setSearchByReference] = useState('')

    const getJobsForReport = useCallback(
        (filters: IJobReportFilter) => {
            dispatch(setLoader(true))
            jobApi.getJobsForReport({
                classification: type,
                filters
            })
                .then((response) => {
                    const jobs: IJobInfo[] = response.data
                    setJobs(jobs)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    toast.error(t('error_getting_jobs_for_reports'))
                    dispatch(setLoader(false))

                })
        },
        [dispatch, t, type],
    )

    const handleJobReportFilterDialogClose = useCallback(
        () => {
            setIsJobReportFilterDialogOpen(false)
        },
        [],
    )

    const handleFilterClick = useCallback(
        () => {
            setIsJobReportFilterDialogOpen(true)
        },
        [],
    )

    useEffect(() => {
        getJobsForReport(filterState)

    }, [filterState, getJobsForReport])

    const columns = getJobReportMileageColumns(t)

    const handleOnFiltersApply = useCallback(
        (filters: IJobReportFilter) => {
            setFilterState(filters)
            handleJobReportFilterDialogClose()
        },
        [handleJobReportFilterDialogClose],
    )

    const handleOnViewEditJobDialogClose = useCallback(
        () => {
            setIsViewEditJobDialogOpen(false)
            setSelectedJob(null)
        },
        [],
    )

    const onRowClick = useCallback(
        (params: GridRowParams) => {
            const job: IJobInfo = params.row as IJobInfo

            setSelectedJob(job)
            setIsViewEditJobDialogOpen(true)
        },
        [],
    )


    useEffect(() => {
        let timeOutId = setTimeout(() => {
            setFilterState({
                ...filterState,
                reference: searchByReference
            })

        }, 500)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchByReference])

    return (
        <>
            <ViewEditJob hideActionButtons selectedJob={selectedJob} type={props.type} open={isViewEditJobDialogOpen} handleClose={handleOnViewEditJobDialogClose} />
            <JobReportFilterDialog onApplyCallback={handleOnFiltersApply} initialJobReportFilterState={filterState} open={isJobReportFilterDialogOpen} handleClose={handleJobReportFilterDialogClose} />
            <Grid
                direction="column"
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
            >

                <Grid item md={3}>
                    <Typography>
                        {t('job_report_mileage')}
                    </Typography>
                </Grid>
               
                <Grid item md={12}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item md={4}>
                            <TextField
                                value={searchByReference}
                                onChange={(e) => setSearchByReference(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('search_by_reference')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        {/* <Grid item md={2} >
                            <Button onClick={handleFilterClick} fullWidth color="primary" variant="contained" startIcon={<FilterListIcon />}>
                                {t('filter')}
                            </Button>
                        </Grid> */}
                        <Grid item md={12}>
                            <CustomTable
                                columns={columns}
                                rows={jobs}
                                onRowClick={onRowClick}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default JobReportMileage