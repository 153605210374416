import { FormControl, Select, MenuItem, InputLabel, FormControlLabel, Checkbox, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button, makeStyles, Grid, TextField, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ICreateAddressDialogProps } from "./AddressDialog.types";
import DoneIcon from '@material-ui/icons/Done';
import EditLocationRoundedIcon from '@material-ui/icons/EditLocationRounded';
import { COUNTRIES, ADDRESS_GROUPS, USER_ROLES, AU_STATES } from "../../../../Types/Constants";
import HomeIcon from '@material-ui/icons/Home';
import { useCallback, useEffect, useMemo, useState } from "react";
import { Address } from "../../../../Api/Address/Address";
import { toast } from "react-toastify";
import { IUserInfo } from "../../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../Redux";
import { isNil } from "lodash";
import MapAutocomplete from "../../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../../BP/MapAddress";
import { ICompanyInfo } from "../../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { setLoader } from "../../../../Redux/Loader/actions";
import { Company } from "../../../../Api/Company/Company";
import { isNumeric } from "../../../../Utilities/Regex"
import TextFieldsIcon from '@material-ui/icons/TextFields';
import EditIcon from '@material-ui/icons/Edit';
import { Customer as CustomerAPI } from '../../../../Api/Customer/Customer'
import { Warehouse as WarehouseAPI } from "../../../../Api/Warehouse/Warehouse";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import DynamicPalletAccounts from "../../../Common/PalletAccount/DynamicPallets";
const useStyles = makeStyles(() => ({
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    formControl: {
        minWidth: '100%',
    }
}))

const { PHILIPPINES, AUSTRALIA, JAPAN, SINGAPORE } = COUNTRIES

const {
    CUSTOMER,
    DC_OR_WAREHOUSE,
    EMPTY_PARK,
    PACK_OR_UNPACK_DEPOT,
    RAIL,
    WHARF
} = ADDRESS_GROUPS

const {
    ADMIN,
    SUPER_ADMIN,
    ADMIN_USER
} = USER_ROLES

const {
    WA,
    ACT,
    VIC,
    TAS,
    NSW,
    NT,
    QLD,
    SA
} = AU_STATES

const addressApi = new Address()

const companyApi = new Company()
const customerApi = new CustomerAPI()
const warehouseApi = new WarehouseAPI()


function AddressDialog(props: ICreateAddressDialogProps) {
    const [t] = useTranslation('CreateAddressDialog')
    const { selectedAddress, handleClose, mode, customerData, selectedCompanyId, accessType } = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const user: IUserInfo | null = useSelector((state: AppState) => state.user.response)
    const isSuperAdmin = user?.type === SUPER_ADMIN || user?.type === ADMIN
    const isUserAdmin = user?.type === ADMIN_USER
    const isTransportCompany = user?.company.companyType == 'transport_company' //as bidding company
    const isIECompany = user?.company.companyType == 'import_exporter' //as post company
    const [companyId, setCompanyId] = useState<number | undefined>(!isSuperAdmin ? user?.company?.id : undefined)
    const [companies, setCompanies] = useState<ICompanyInfo[]>([])

    const [isSeaFreight, setIsSeaFreight] = useState(false)
    const [isLandFreight, setIsLandFreight] = useState(true)
    const [country, setCountry] = useState(isSuperAdmin ? AUSTRALIA : (user?.company?.country ?? ''))
    const [group, setGroup] = useState('')
    const [streetAddress, setStreetAddress] = useState('')
    const [instruction, setInstruction] = useState('')
    const [receivingHours, setReceivingHours] = useState('')

    const [state, setState] = useState('')
    const [stateType, setStateType] = useState('')

    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [latitude, setLatitude] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')
    const [hasSearchAddress, setHasSearchAddress] = useState(false)
    const [companyName, setCompanyName] = useState('')

    const [isPreview, setIsPreview] = useState(false)
    const [isEditable, setIsEditable] = useState(true)
    const [palletAccounts, setPalletAccounts] = useState([{ type: '', action: '', value: '' }]);
    const [email, setEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(true);
    const handlePalletAccountsChange = (newPalletAccounts) => {
        setPalletAccounts(newPalletAccounts);
    };


    const companyListOptions = useMemo(() => companies.map((company, i) => <MenuItem key={i} value={company.id}>{company.companyName}</MenuItem>), [companies])

    useEffect(() => {
        if (isUserAdmin && user?.country) {
            setCountry(user.country)
        }

    }, [isUserAdmin, user?.country])

    const handleMapAddressSelect = useCallback(
        (mapAddress: MapAddress) => {
            setHasSearchAddress(true)
            setStreetAddress([mapAddress.streetNum, mapAddress.route].join(' '))
            setState(mapAddress.state)
            setPostalCode(mapAddress.postalCode)
            setCity(mapAddress.city)
            setLatitude(String(mapAddress.lat))
            setLongitude(String(mapAddress.long))
        },
        [],
    )

    const getCompanies = useCallback(
        (country: string) => {
            dispatch(setLoader(true))
            companyApi.getAll(country)
                .then((response) => {
                    const list: ICompanyInfo[] = response.data
                    setCompanies(list)
                    setCompanyId(undefined)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_get_company_list'))
                })
        },
        [dispatch, t],
    )

    const handleCreateClick = () => {

        //Validations
        //TODO: add additional validations

        if (!hasSearchAddress) {
            toast.warn(t('warn_search_an_address'))
            return
        }

        if (!isSeaFreight && !isLandFreight) {
            toast.warn(t('warn_select_a_job_classification'))
            return
        }

        if (country === '') {
            toast.warn(t('warn_select_country'))
            return
        }

        if (group === '') {
            toast.warn(t('warn_select_a_group'))
            return
        }

        if (streetAddress === '') {
            toast.warn(t('warn_address_not_empty'))
            return
        }

        if (country === AUSTRALIA && state === '') {
            toast.warn(t('warn_select_a_state'))
            return
        } else if (state === '') {
            toast.warn(t('warn_province_not_empty'))
            return
        }
        if (city === '') {
            toast.warn(t('warn_city_not_empty'))
            return
        }

        if (postalCode === '') {
            toast.warn(t('warn_postal_code_not_empty'))
            return
        } else if (!isNumeric(postalCode)) {
            toast.warn(t('warn_postcode_not_numeric'))
            return
        }


        if (isSuperAdmin && !props.isCommon) {
            if (isNil(companyId)) {
                toast.warn(t('warn_select_company_id'))
                return
            }
        }

        if (latitude === '') {
            toast.warn(t('warn_latitude_not_empty'))
            return
        }

        if (longitude === '') {
            toast.warn(t('warn_longitude_not_empty'))
            return
        }
        const apiUser = accessType == 'warehouse' ? warehouseApi : customerApi
        apiUser.createAddress({
            address_group: group,
            city: city,
            country: country,
            postcode: postalCode,
            state: state,
            instruction: instruction,
            receiving_hours: receivingHours,
            street_number: streetAddress,
            company_id: selectedCompanyId,
            latitude: latitude,
            longitude: longitude,
            is_common: props.isCommon ?? false,
            is_land_freight: isLandFreight,
            is_sea_freight: isSeaFreight,
            company_name: companyName,
            ...(accessType == 'warehouse' ? { warehouse_id: customerData.id } : { customer_id: customerData.id }),
            state_type: stateType
        })
            .then(() => {
                toast.success(t('success_creating_address'))
                if (props.onDone)
                    props.onDone()
                handleClose()
            })
            .catch((err: any) => {
                console.log('err', err)
                toast.error(t('error_creating_address'))
            })
    }

    useEffect(() => {
        if (isSuperAdmin && !props.isCommon)
            getCompanies(country)

    }, [country, getCompanies, isSuperAdmin, props.isCommon])

    useEffect(() => {
        if (props.type) {
            setIsLandFreight(props.type === 'transport')
            setIsSeaFreight(props.type === 'seafreight')
        }

    }, [props.type])

    const isJobClassificationDefined = Boolean(props.type)

    useEffect(() => {
        const address = props.selectedAddress
        if (address) {
            setCompanyId(address.company_id)
            setGroup(address.address_group ? address.address_group?.code : '')
            setIsLandFreight(address.is_land_freight)
            setIsSeaFreight(address.is_sea_freight)
            setCountry(address.country)
            setStreetAddress(address.street_number)
            setInstruction(address.instruction)
            setReceivingHours(address.receiving_hours)
            setState(address.state)
            setCity(address.city)
            setPostalCode(address.postcode)
            setLatitude(address.latitude)
            setLongitude(address.longitude)
            setCompanyName(address.company_name)
            setEmail(address.email || '')
            setIsPreview(true)
            setIsEditable(false)
        } else {
            setCompanyId(!isSuperAdmin ? user?.company?.id : undefined)
            setGroup('')
            setCountry(isSuperAdmin ? AUSTRALIA : (user?.company?.country ?? ''))
            setStreetAddress('')
            setInstruction('')
            setReceivingHours('')
            setEmail('')
            setState('')
            setCity('')
            setPostalCode('')
            setLatitude('')
            setLongitude('')
            setCompanyName('')

            setIsPreview(false)
            setIsEditable(true)
        }
    }, [props.selectedAddress, isSuperAdmin, user])


    const handleEditClick = useCallback(
        () => {
            setIsEditable(true)
        },
        [],
    )

    const handleUpdateClick = () => {
        const address = props.selectedAddress
        if (address) {
            dispatch(setLoader(true))
            addressApi.update({
                address_group: group,
                city: city,
                country: country,
                postcode: postalCode,
                state: state,
                street_number: streetAddress,
                instruction: instruction,
                receiving_hours: receivingHours,
                company_id: companyId,
                latitude: latitude,
                longitude: longitude,
                is_common: props.isCommon ?? false,
                is_land_freight: isLandFreight,
                is_sea_freight: isSeaFreight,
                company_name: companyName,
                addressId: address.id,
                state_type: stateType,
                palletAccounts,
                email: email
            })
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_updating_address'))
                    handleClose()
                })
                .catch(err => {
                    console.log(`err`, err)
                    dispatch(setLoader(false))
                    toast.error(t('error_updating_address'))
                })
        }
    }
    const handleEmailChange = (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);

        // Custom email validation logic
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(enteredEmail);
        setIsValidEmail(isValid);
    };
    return (
        <>
            <Dialog maxWidth="md" fullWidth open={props.open} onClose={props.handleClose}>
                <DialogTitle>
                    <Typography>
                        {(mode == 'create') ? t('add_new_address') : t('edit_address')}

                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid md={12} item>
                            <MapAutocomplete onAddressSelect={handleMapAddressSelect} readOnly={!isEditable} />
                        </Grid>
                        {/* {
                            isSuperAdmin
                                ? <Grid md={12} item>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_country')}</InputLabel>
                                        <Select
                                            disabled={isPreview && !isEditable}
                                            value={country}
                                            onChange={(e) => setCountry(e.target.value as string)}
                                        > */}
                        {/* <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem> */}
                        {/* <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem> */}
                        {/* <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem> */}
                        {/* <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem> */}
                        {/* </Select>
                                    </FormControl>
                                </Grid>
                                : null
                        } */}
                        {/* {
                            !isJobClassificationDefined
                                ? <>
                                    <Grid md={3} item>
                                        <FormControlLabel
                                            control={<Checkbox disabled={isJobClassificationDefined || (isPreview && !isEditable)} checked={isSeaFreight} onChange={(e) => setIsSeaFreight(e.target.checked)} color="primary" />}
                                            label={t('sea_freight')}
                                        />
                                    </Grid>
                                    <Grid md={3} item>
                                        <FormControlLabel
                                            control={<Checkbox disabled={isJobClassificationDefined || (isPreview && !isEditable)} checked={isLandFreight} onChange={(e) => setIsLandFreight(e.target.checked)} color="primary" />}
                                            label={t('land_freight')}
                                        />
                                    </Grid>
                                </>
                                : null
                        } */}


                        {
                            isSuperAdmin && !props.isCommon
                                ? <Grid md={6} item>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel style={{ fontWeight: 'bold' }}>{t('select_company')}</InputLabel>
                                        <Select
                                            disabled={isPreview && !isEditable}
                                            value={companyId}
                                            onChange={(e) => {
                                                const selectedCompanyId = e.target.value as number
                                                setCompanyId(selectedCompanyId)
                                            }}
                                        >
                                            {companyListOptions}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                : null
                        }

                        <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('group')}</InputLabel>
                                <Select
                                    disabled={isPreview && !isEditable}
                                    value={group}
                                    onChange={(e) => setGroup(e.target.value as string)}
                                >
                                    <MenuItem value={CUSTOMER}>{t(CUSTOMER)}</MenuItem>
                                    <MenuItem value={DC_OR_WAREHOUSE}>{t(DC_OR_WAREHOUSE)}</MenuItem>
                                    <MenuItem value={EMPTY_PARK}>{t(EMPTY_PARK)}</MenuItem>
                                    <MenuItem value={PACK_OR_UNPACK_DEPOT}>{t(PACK_OR_UNPACK_DEPOT)}</MenuItem>
                                    <MenuItem value={RAIL}>{t(RAIL)}</MenuItem>
                                    <MenuItem value={WHARF}>{t(WHARF)}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={companyName}
                                onChange={e => setCompanyName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('enter_company_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={streetAddress}
                                onChange={(e) => setStreetAddress(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('address')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                error={!isValidEmail}
                                helperText={!isValidEmail && "Please enter a valid email address"}
                                value={email}
                                onChange={handleEmailChange}
                                variant="outlined"
                                fullWidth
                                type="email"
                                label={t('email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={instruction}
                                onChange={(e) => setInstruction(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('instruction')}
                                // InputProps={{
                                //     endAdornment: (
                                //         <InputAdornment position="end">
                                //             <HomeIcon />
                                //         </InputAdornment>
                                //     )
                                // }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={receivingHours}
                                onChange={(e) => setReceivingHours(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('receiving_hours')}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <DynamicPalletAccounts palletAccounts={palletAccounts} onPalletAccountsChange={handlePalletAccountsChange} readOnly={isPreview && !isEditable} />

                            {/* <PalletAccountV2
                                readOnly={!isEditable}
                                setPalletAccountType={setPalletAccountType}
                                setPalletAccountAction={setPalletAccountAction}
                                setPalletAccountValue={setPalletAccountValue}
                                palletAccountType={palletAccountType}
                                palletAccountAction={palletAccountAction}
                                palletAccountValue={palletAccountValue}
                            /> */}

                        </Grid>
                        {
                            country === AUSTRALIA
                                ? <>
                                    <Grid md={4} item>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel style={{ fontWeight: 'bold' }}>{t('state')}</InputLabel>
                                            <Select
                                                disabled={isPreview && !isEditable}
                                                value={state}
                                                onChange={(e) => setState(e.target.value as string)}
                                            >
                                                <MenuItem value={WA}>{t(WA)}</MenuItem>
                                                <MenuItem value={ACT}>{t(ACT)}</MenuItem>
                                                <MenuItem value={VIC}>{t(VIC)}</MenuItem>
                                                <MenuItem value={TAS}>{t(TAS)}</MenuItem>
                                                <MenuItem value={NSW}>{t(NSW)}</MenuItem>
                                                <MenuItem value={NT}>{t(NT)}</MenuItem>
                                                <MenuItem value={QLD}>{t(QLD)}</MenuItem>
                                                <MenuItem value={SA}>{t(SA)}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid md={4} item>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel style={{ fontWeight: 'bold' }}>{t('state_type')}</InputLabel>
                                            <Select
                                                disabled={isPreview && !isEditable}
                                                value={stateType}
                                                onChange={(e) => setStateType(e.target.value as string)}
                                            >
                                                <MenuItem value={WA}>{t('inner_metro')}</MenuItem>
                                                <MenuItem value={ACT}>{t('outer_metro')}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>


                                </>
                                : <>
                                    <Grid md={4} item>
                                        <TextField
                                            disabled={isPreview && !isEditable}
                                            value={state}
                                            onChange={(e) => setState(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('province')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HomeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                        }


                        <Grid md={4} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('suburb_city')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={4} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('postal_code')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={latitude}
                                onChange={(e) => setLatitude(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('latitude')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EditLocationRoundedIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={isPreview && !isEditable}
                                value={longitude}
                                onChange={(e) => setLongitude(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('longitude')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EditLocationRoundedIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        !isPreview && isEditable
                            ? <Button onClick={handleCreateClick} className={classes.btnCreate} startIcon={<DoneIcon />} variant="contained">
                                {t('create')}
                            </Button>
                            : null
                    }
                    {
                        isPreview && !isEditable
                            ? <Button onClick={handleEditClick} color="primary" startIcon={<EditIcon />} variant="contained">
                                {t('edit')}
                            </Button>
                            : null
                    }
                    {
                        (isPreview && isEditable)
                            ? <Button onClick={handleUpdateClick} className={classes.btnCreate} startIcon={<DoneIcon />} variant="contained">
                                {t('update')}
                            </Button>
                            : null
                    }
                    {isEditable ?
                        <Button variant="contained" onClick={props.handleClose}>
                            {t('cancel')}
                        </Button>
                        : null
                    }
                </DialogActions>
            </Dialog>
        </>
    )
}


export default AddressDialog