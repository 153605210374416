import { ILoaderState, SET_LOADER, TLoaderActions } from "./types";
import {nanoid} from 'nanoid'
import { deepCopy } from "../../Utilities/Object";


const initialState: ILoaderState = {
    loading: []
}

export function loaderReducer(state: ILoaderState = initialState, actions: TLoaderActions):ILoaderState {
    switch (actions.type) {
        case SET_LOADER:
            const isShown: boolean = actions.payload as boolean

            if(isShown) {
                state.loading.push(nanoid(5))
            } else {
                state.loading.pop()
            }

            return deepCopy(state)

    
        default:
            return state
    }
}