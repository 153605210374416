import { makeStyles, Typography, Grid, Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ICustomerAddressProps } from "./WarehouseAddress.types";
import { useState, useCallback, useMemo, useEffect } from "react";
import { Warehouse } from '../../../Api/Warehouse/Warehouse'
import { setLoader } from "../../../Redux/Loader/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomTable from "../../Common/CustomTable/CustomTable";
import { GridRowParams } from "@material-ui/data-grid";
import { getAddressesColumns } from "./WarehouseAddress.columns";
import { ICustomerMapping } from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types"
import { IAddress } from '../../../../../Application/DTOs/AddressDto/AddressDto.types';
import AddressDialog from "../../Dialogs/V2/AddressDialogV2/AddressDialog";
import { Address } from "../../../Api/Address/Address";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))



const warehouseApi = new Warehouse()
const addressApi = new Address()

function WarehouseAddress(props: ICustomerAddressProps) {
    const [t] = useTranslation('Warehouse')
    const { selectedAccount } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const [addresses, setAddresses] = useState<IAddress[]>([])
    const [addressDialogOpen, setAddressDialogOpen] = useState<boolean>(false)
    const [mode, setMode] = useState<'create' | 'edit'>('create')
    const [selectedAddress, setSelectedAddress] = useState<IAddress | null>(null)
    const [selectedCompany, setSelectedCompanyId] = useState<any>('')


    const getAddresses = useCallback(
        (xId: any) => {
            dispatch(setLoader(true))
            warehouseApi.getAddress(xId)
                .then(response => {
                    const xData: ICustomerMapping[] = response.data
                    let customerAddress = xData.map((x: any) => { return { ...x.address, is_main: x.is_main } })
                    setSelectedCompanyId(xData[0].company_id)
                    setAddresses(customerAddress)
                    dispatch(setLoader(false))

                })
                .catch((err) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_getting_company_detail'))
                    setAddresses([])
                })

        },
        [dispatch, t]
    )

    useEffect(() => {
        if (selectedAccount) {
            getAddresses(selectedAccount?.id)
        }

    }, [getAddresses, selectedAccount?.id])
    const handleDialogClose = useCallback(
        () => {
            setSelectedAddress(null)
            setAddressDialogOpen(false)
            if (selectedAccount) {
                getAddresses(selectedAccount?.id)
            }
        },
        [],
    )
    const onRowSelect = useCallback(
        (rowParams: GridRowParams) => {
            setSelectedAddress(rowParams.row as IAddress)
            setMode('edit')
            setAddressDialogOpen(true)
        },
        [],
    )

    const onAddEvent = () => {
        setSelectedAddress(null)
        setMode('create')
        setAddressDialogOpen(true)
    }

    const onAddressDisable = useCallback((param) => {

        addressApi.updateStatus(param.id, !param.is_disabled).then(() => {
            toast.success(t('address_was_' + param.is_disabled))
            if (selectedAccount) {
                getAddresses(selectedAccount?.id)
            }
        }).catch(() => {
            toast.error(t('error_encountered'))
        })

    }, [selectedAccount])

    
    const columns = getAddressesColumns(t, {
        onAddressDisable: onAddressDisable,
    })


    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                direction="column"
            >
                <Grid item md>
                    <Typography>
                        {t('addresses')}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <AddressDialog
                        handleClose={handleDialogClose}
                        open={addressDialogOpen}
                        isCommon={false}
                        mode={mode}
                        selectedAddress={selectedAddress}
                        customerData={selectedAccount}
                        selectedCompanyId={selectedCompany}
                        accessType="warehouse"
                    />
                    <Grid item md={12}>
                        <Box
                            m={1}
                            //margin
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Grid item md={2}>

                                <Button
                                    onClick={onAddEvent}
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                // startIcon={<FilterListIcon />}
                                >
                                    {t('add')}
                                </Button>
                            </Grid>
                        </Box>
                    </Grid>
                    <CustomTable
                        onRowClick={onRowSelect}
                        columns={columns}
                        rows={addresses}
                    />
                </Grid>
            </Grid>

        </div>
    )
}

export default WarehouseAddress