import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "../../../routes"

import CustomerManagementPage from "./CustomerManagementPage/CustomerManagementPage"
import CustomerAccountManagement from "./CustomerAccountManagementPage/CustomerAccountManagement"

import RatesManagementv2 from "../AdminSettings/RatesManagement/RatesMgmntv2"

function CustomerManagement() {
    const match = useRouteMatch()

    return (
        <>

            {/* /home/company/management/manage */}
            <Route path={match.path + routes.home.customer_management.customer_management}>
                <CustomerManagementPage />
            </Route>
            <Route path={match.path + routes.home.customer_management.account_management}>
                <CustomerAccountManagement />
            </Route>
            <Route path={match.path + routes.home.customer_management.rates}>
                <RatesManagementv2 />
            </Route>

        </>
    )
}


export default CustomerManagement