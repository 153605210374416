import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTranslation } from "react-i18next";
import { ICollapsibleProps } from './StocksTypes.types';
import { formatDateTimeDisplayDD } from '../../../../Utilities/DateTime';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function createData(xData: any) {
    return {
        id: xData.id,
        product_name: xData.product_name,
        product_code: xData.product_code,
        total: xData.total,
        description: xData.description,
        batches: xData.stocks_relations,
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const [t] = useTranslation('Warehouse')

    return (
        <React.Fragment>
            <TableRow key={row.id} className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{row.product_name}</TableCell>
                <TableCell >{row.product_code}</TableCell>
                <TableCell >{row.total}</TableCell>
                <TableCell >{row.description}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                                {t('lists')}
                            </Typography> */}
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('warehouse_site')}</TableCell>
                                        <TableCell>{t('batch_code')}</TableCell>
                                        <TableCell>{t('qty_type')}</TableCell>
                                        <TableCell >{t('quantity')}</TableCell>
                                        <TableCell >{t('expiry_date')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.batches.map((batch) => (
                                        <TableRow key={batch.id}  >
                                            <TableCell component="th" scope="row">{batch.address.company_name}</TableCell>
                                            <TableCell>{batch.batch_code}</TableCell>
                                            <TableCell>
                                                {(batch.qty_type == 'others') ?
                                                    <div class="tooltip">{t(batch.qty_type)}
                                                        <span class="tooltiptext">{batch.storing_procedure}</span>
                                                    </div>
                                                    :
                                                    t(batch.qty_type)
                                                }
                                            </TableCell>
                                            <TableCell>{batch.quantity}</TableCell>
                                            <TableCell>{batch.expiry_date}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
// product_name, product_code, description
Row.propTypes = {
    row: PropTypes.shape({
        product_name: PropTypes.string.isRequired,
        product_code: PropTypes.string.isRequired,
        total: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                warehouse: PropTypes.string.isRequired,
                batch_code: PropTypes.number.isRequired,
                qty_type: PropTypes.string.isRequired,
                quantity: PropTypes.number.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired
    }).isRequired,
};


export default function CollapsibleTable(props: ICollapsibleProps) {
    const [t] = useTranslation('Warehouse')
    const { rows } = props
    const [rowDataList, setRowDataList] = useState<any[]>([])

    useEffect(() => {
        const xRows = rows.map((xData: any) => {
            return createData(xData)
        })
        setRowDataList(xRows)
    }, [rows])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{t('product_name')}</TableCell>
                        <TableCell>{t('product_code')}</TableCell>
                        <TableCell >{t('total')}</TableCell>
                        <TableCell >{t('description')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowDataList.map((row) => (
                        <Row key={row.product_name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
