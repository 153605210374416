import { makeStyles } from '@material-ui/core'
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../../Styles/colors';
import { IMapPinProps } from './MapPin.types'

const K_WIDTH = 40;
const K_HEIGHT = 40;

const useStyles = makeStyles(() => ({
    pinDiv: {
        // initially any map object has left top corner at lat lng coordinates
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
        border: `5px solid ${PRIMARY_COLOR}`,
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: SECONDARY_COLOR,
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        cursor: 'pointer'
    }
}))


function MapPin(props: IMapPinProps) {
    const classes = useStyles()
    return (
        <div onClick={props.onClick} className={classes.pinDiv}>
            {props.text}
        </div>
    )
}

export default MapPin