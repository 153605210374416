import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Typography, Grid, Button, TextField, Checkbox, FormControlLabel, InputAdornment } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IViewUserDetailsDialogProps } from "./ViewUserDetailsDialog.types";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IUpdateUserPayload, IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { STATUS, USER_PRIVILEGES, USER_ROLES } from "../../../Types/Constants";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { UsersApi } from "../../../Api/Users/Users";
import { toast } from "react-toastify";
import WarningDialog from "../WarningDialog/WarningDialog";

const {
    ACTIVE,
    INACTIVE
} = STATUS

const {
    CONFIGURATIONS,
    DRIVER_MANAGEMENT,
    TRANSPORT_MANAGEMENT,
    SEA_FREIGHT_MANAGEMENT
} = USER_PRIVILEGES

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    }
}))

const userApi = new UsersApi()

const {
    DRIVER_MANAGER
} = USER_ROLES

function ViewUserDetailsDialog(props: IViewUserDetailsDialogProps) {
    const { open, handleClose, selectedUserId } = props
    const [t] = useTranslation('ViewUserDetailsDialog')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isEditMode, setIsEditMode] = useState(false)
    const [userInfo, setUserInfo] = useState<IUserInfo | null>(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [isTransportManagement, setIsTransportManagement] = useState(false)
    const [isConfiguration, setIsConfiguration] = useState(false)
    const [isSeaFreightManagement, setIsSeaFreightManagement] = useState(false)
    const [isDriverManagement, setIsDriverManagement] = useState(false)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
    const isDriverManager = userInfo?.type === DRIVER_MANAGER

    const handleWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
        },
        [],
    )

    const setUserDetailsToState = useCallback(
        (user: IUserInfo) => {
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setEmail(user.email)
            setMobileNumber(user.mobile)
            setIsTransportManagement(user.privileges.includes(TRANSPORT_MANAGEMENT))
            setIsConfiguration(user.privileges.includes(CONFIGURATIONS))
            setIsSeaFreightManagement(user.privileges.includes(SEA_FREIGHT_MANAGEMENT))
            setIsDriverManagement(user.privileges.includes(DRIVER_MANAGEMENT))
        },
        [],
    )

    const getUserDetails = useCallback(
        (selectedUserId: string | number) => {
            dispatch(setLoader(true))
            userApi.getDetails(selectedUserId)
                .then((response) => {
                    const user: IUserInfo = response.data
                    setUserInfo(user)
                    setUserDetailsToState(user)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    handleClose()
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_getting_user_detail'))
                })
        },
        [dispatch, t, setUserDetailsToState, handleClose],
    )

    useEffect(() => {
        if (selectedUserId) {
            getUserDetails(selectedUserId)
        }
    }, [selectedUserId, getUserDetails])

    const handleOnEditClick = useCallback(
        () => {
            setIsEditMode(true)
        },
        [],
    )

    const handleOnCancelClick = useCallback(
        () => {
            setIsEditMode(false)
            if (userInfo)
                setUserDetailsToState(userInfo)
        },
        [setUserDetailsToState, userInfo],
    )

    const handleOnUpdateClick = useCallback(
        () => {
            //TODO: ADD VALIDATIONS

            if (selectedUserId) {
                dispatch(setLoader(true))

                const privileges: string[] = []

                if (isConfiguration) privileges.push(CONFIGURATIONS)
                if (isTransportManagement) privileges.push(TRANSPORT_MANAGEMENT)
                if (isSeaFreightManagement) privileges.push(SEA_FREIGHT_MANAGEMENT)
                if (isDriverManagement) privileges.push(DRIVER_MANAGEMENT)

                let payload: IUpdateUserPayload = {
                    email,
                    firstName,
                    lastName,
                    mobile: mobileNumber,
                    privileges,
                    status: userInfo?.status,
                    type: userInfo?.type
                }

                userApi.update(selectedUserId, payload)
                    .then(() => {
                        dispatch(setLoader(false))
                        setIsEditMode(false)
                        if (selectedUserId)
                            getUserDetails(selectedUserId)
                    })
                    .catch((err :any) => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_updating_user_detail'))
                    })
            }
        },
        [email, firstName, lastName, mobileNumber, userInfo?.status, userInfo?.type, isConfiguration, isTransportManagement, isSeaFreightManagement, isDriverManagement, selectedUserId, getUserDetails, dispatch, t],
    )

    const handleUpdateStatusClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))

                userApi.update(selectedUserId, { status: userInfo?.status === INACTIVE ? ACTIVE : INACTIVE })
                    .then(() => {
                        dispatch(setLoader(false))
                        setIsEditMode(false)
                        if (selectedUserId)
                            getUserDetails(selectedUserId)
                    })
                    .catch((err :any) => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_updating_user_detail'))
                    })
            }
        },
        [userInfo?.status, dispatch, t, selectedUserId, getUserDetails],
    )

    const handleOnProceedDeleteClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))
                userApi.delete(selectedUserId)
                    .then(() => {
                        toast.success(t('success_deleting_user'))
                        handleWarningDialogClose()
                        handleClose()
                        dispatch(setLoader(false))
                    })
                    .catch(err => {
                        console.log('err', err)
                        toast.error(t('error_deleting_user'))
                        dispatch(setLoader(false))
                    })
            }
        },
        [dispatch, t, selectedUserId, handleWarningDialogClose, handleClose],
    )

    const handleOnDeleteClick = useCallback(
        () => {
            setIsWarningDialogOpen(true)
        },
        [],
    )

    return (
        <>
            <WarningDialog message={t('warning_about_to_delete_user')} open={isWarningDialogOpen} handleClose={handleWarningDialogClose} onProceedClick={handleOnProceedDeleteClick} />
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('user_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item md={6}>
                            <TextField
                                disabled={!isEditMode}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('enter_first_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                disabled={!isEditMode}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('enter_last_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                disabled={!isEditMode}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_email_address')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                      

                        {
                            !isDriverManager
                                ? <>
                                    <Grid md={12} item>
                                        <Typography align="left" variant="body1">
                                            {t('current_privileges')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={3} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isTransportManagement} onChange={(e) => setIsTransportManagement(e.target.checked)} />}
                                            label={t('transport_management')}
                                        />
                                    </Grid>
                                    {/* <Grid md={9} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isSeaFreightManagement} onChange={(e) => setIsSeaFreightManagement(e.target.checked)} />}
                                            label={t('sea_freight_management')}
                                        />
                                    </Grid> */}
                                    <Grid md={3} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isConfiguration} onChange={(e) => setIsConfiguration(e.target.checked)} />}
                                            label={t('configuration')}
                                        />
                                    </Grid><Grid md={9} item>
                                        <FormControlLabel
                                            control={<Checkbox color="primary" disabled={!isEditMode} checked={isDriverManagement} onChange={(e) => setIsDriverManagement(e.target.checked)} />}
                                            label={t('driver_management')}
                                        />
                                    </Grid>
                                </>
                                : null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item md={6}>
                            {
                                !isEditMode
                                    ? <Grid item md={3}>
                                        <Button onClick={handleOnDeleteClick} startIcon={<DeleteForeverIcon />} fullWidth className={classes.deletebtn} variant="contained">
                                            {t('delete')}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </Grid>

                        <Grid item md={6}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >
                                {
                                    !isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleUpdateStatusClick} className={classes.updatebtn} startIcon={userInfo?.status === INACTIVE ? <CheckIcon /> : <ClearIcon />} variant="contained" fullWidth>{t(userInfo?.status === INACTIVE ? 'mark_as_active' : 'mark_as_inactive')}</Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    !isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleOnEditClick} startIcon={<EditIcon />} fullWidth className={classes.editBtn} variant="contained">
                                                {t('edit')}
                                            </Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleOnUpdateClick} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                {t('update')}
                                            </Button>
                                        </Grid>
                                        : null
                                }

                                {
                                    isEditMode
                                        ? <Grid item md={6}>
                                            <Button onClick={handleOnCancelClick} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ViewUserDetailsDialog