import { Grid, Typography, Divider, Button, FormControl, MenuItem, makeStyles, InputLabel, Select } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../Common/CustomTable/CustomTable'
import { getColumns } from './AddressManagement.columns'
import AddIcon from '@material-ui/icons/Add';
import { useCallback, useEffect, useState, useMemo } from 'react';
import CreateAddressDialog from '../../../Dialogs/CreateAddressDialog/CreateAddressDialog';
import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import { useDispatch, useSelector } from 'react-redux';
import { Address } from '../../../../Api/Address/Address';
import { toast } from 'react-toastify';
import { setLoader } from '../../../../Redux/Loader/actions';
import { USER_ROLES, AU_STATES, ADDRESS_GROUPS } from '../../../../Types/Constants';
import { AppState } from '../../../../Redux';
import { GridRowParams } from '@material-ui/data-grid';
import { IAddresManagementProps } from './Address.props';
import Swal from 'sweetalert2'

import { IcompanyInfo } from '../../../../../../Application/DTOs/CustomerDto/CustomerDto.types';
import SectionHeader from '../../../Common/SectionHeader/SectionHeader';
const addressApi = new Address()

const {
    ADMIN_USER,
    SUPER_ADMIN
} = USER_ROLES


const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    gap: {
        marginTop: 20,
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
    },
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    gridList: {
        width: 500,
        height: 450,
    },
}));
function AddressManagement(props: IAddresManagementProps) {
    const [t] = useTranslation('Address')
    const { accessType } = props
    const dispatch = useDispatch()
    const [isAddNewAddressDialogOpen, setIsAddNewAddressDialogOpen] = useState(false)
    const [addresses, setAddresses] = useState<IAddress[]>([])
    const user = useSelector((state: AppState) => state.user)
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user.response?.type)
    const [selectedAddress, setSelectedAddress] = useState<any>(undefined)
    let company: any = useSelector((state: AppState) => state.company);
    const [companyId, setCompanyId] = useState<any>('');
    const [addressState, setAddressState] = useState('');
    const [addressGroup, setAddressGroup] = useState('');
    const [addressMode, setAddressMode] = useState<'create' | 'edit' | string>('create');

    const classes = useStyles();
    let companyListOptions = useMemo(() =>
        company.list.map((company, i) => (
            <MenuItem key={i} value={company.id}>
                {company.companyName}
            </MenuItem>
        )),
        [company.list]
    );

    const handleOnApplyClear = () => {
        setCompanyId('')
        setAddressState('')
        setAddressGroup('')
        getAddresses('', '', '')
    }
    const getAddresses = useCallback((companyid, address_state, address_group) => {
        dispatch(setLoader(true))
        addressApi.getFilteredAddress(companyid, address_state, address_group, accessType == 'common')
            .then((response) => {
                const addresses = response.data as IAddress[]

                setAddresses(addresses)
                dispatch(setLoader(false))
            })
            .catch((err: any) => {
                console.log('err', err)
                toast.error(t('error_getting_addresses'))
                dispatch(setLoader(false))
            })
    }, [])


    const stateOptions = useMemo(
        () =>
            Object.keys(AU_STATES).map((state, i) => {
                if (t(state) != "") {
                    return <MenuItem key={i} value={state}>
                        {t(state)}
                    </MenuItem>
                }
            }
            ),
        [AU_STATES, t]
    );

    const addressGroupOptions = useMemo(
        () =>
            Object.keys(ADDRESS_GROUPS).map((key, i) => (
                <MenuItem key={i} value={ADDRESS_GROUPS[key]}>
                    {t(ADDRESS_GROUPS[key])}
                </MenuItem>
            )),
        [ADDRESS_GROUPS]
    );

    const handleOnAddNewAddressDialogClose = useCallback(
        () => {
            setIsAddNewAddressDialogOpen(false)
            setSelectedAddress(undefined)
            getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup)

        },
        [companyId, addressState, addressGroup],
    )

    const handleOnAddNewAddressClick = useCallback(
        () => {
            setAddressMode('create')
            setIsAddNewAddressDialogOpen(true)
            setSelectedAddress(undefined)
        },
        [],
    )

    const handleFilterButton = () => {
        getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup)
    }
    const handleOnAddAddressDone = useCallback(
        () => {
            handleOnAddNewAddressDialogClose()
        },
        [handleOnAddNewAddressDialogClose],
    )

    useEffect(() => {
        getAddresses(companyId, addressState, addressGroup)
        if (!isUserAdmin) {
            setCompanyId(user.response?.company.id)
        }
    }, [])

    const onRowClick = useCallback(
        (row: GridRowParams) => {
            setSelectedAddress(row.id)
            setAddressMode('edit')
            setIsAddNewAddressDialogOpen(true)
        },
        [],
    )

    const onUpdateStatusCallback = useCallback(
        (addressId: string | number, newStatus: boolean) => {
            // dispatch(setLoader(true))
            addressApi.updateStatus(addressId, newStatus)
                .then(() => {
                    getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup)

                })
                .catch((err: any) => {
                    console.log('err', err)
                    toast.error(t('error_updating_address_status'))
                    dispatch(setLoader(false))
                })
        },
        [isUserAdmin, addressState, addressGroup],
    )

    const onDelete = useCallback(
        (addressId: string | number) => {


            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#303f9f",
                cancelButtonColor: "#f50057",
                confirmButtonText: "Yes, delete it!"
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setLoader(true))

                    addressApi.delete(addressId)
                        .then(() => {
                            dispatch(setLoader(false))
                            getAddresses(isUserAdmin ? companyId : '', addressState, addressGroup)

                        })
                        .catch((err: any) => {
                            console.log('err', err)
                            toast.error(t('error_deleting_address'))
                            dispatch(setLoader(false))
                        })
                }
                dispatch(setLoader(false))

            });

        },
        [],
    )


    const columns = getColumns(t, onUpdateStatusCallback, onDelete, isUserAdmin)
    //
    return (
        <>
            <CreateAddressDialog mode={addressMode} address={selectedAddress} onDone={handleOnAddAddressDone} isCommon={accessType == 'common'} open={isAddNewAddressDialogOpen} handleClose={handleOnAddNewAddressDialogClose} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("company_management")} title={t('Shipping Address')} />

               
                <Grid item>
                    <Grid
                        container
                        spacing={2}

                    >

                        <Grid container style={{ "paddingBottom": "10px" }} spacing={2} md={12}>
                            <Grid item container style={{ "alignItems": 'center' }} spacing={1} md={9}>
                                {(isUserAdmin && accessType != 'common') ?
                                    <Grid item md={3}>

                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel className={classes.selectLabel}>
                                                {t('select_company') + '*'}
                                            </InputLabel>
                                            <Select
                                                value={companyId}
                                                onChange={(e) => {
                                                    const companyId = e.target.value;
                                                    setCompanyId(companyId as string);
                                                }}
                                            >
                                                {companyListOptions}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    :
                                    null
                                }

                                <Grid item md={3}>
                                    <FormControl
                                        variant="filled"
                                        className={classes.formControl}
                                    >
                                        <InputLabel className={classes.selectLabel}>
                                            {t('pickup_select_state')}
                                        </InputLabel>
                                        <Select
                                            value={addressState}
                                            onChange={(e) => {
                                                setAddressState(e.target.value as any);
                                            }}
                                        >
                                            {stateOptions}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={3}>
                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel}>
                                            {t('deliver_select_group')}
                                        </InputLabel>
                                        <Select
                                            value={addressGroup}
                                            onChange={(e) => {
                                                setAddressGroup(e.target.value as any);
                                            }}
                                        >
                                            {addressGroupOptions}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item container spacing={2} md={3}>
                                    <Grid item md={6} >
                                        <Button fullWidth onClick={handleOnApplyClear} color="default" variant="contained">
                                            Clear
                                        </Button>
                                    </Grid>
                                    <Grid item md={6} >
                                        <Button

                                            fullWidth
                                            onClick={handleFilterButton}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {t('filter')}
                                        </Button>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid container style={{ "alignItems": 'center', "justifyContent": "right" }} spacing={1} md={3}>
                                <Grid item md={6} >
                                    <Button onClick={handleOnAddNewAddressClick} variant="contained" fullWidth color="primary">
                                        {t('add_new_address')}
                                    </Button>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container md={12}>
                            <Grid item md={12}>
                                <CustomTable
                                    rows={addresses}
                                    columns={columns}
                                    onRowClick={onRowClick}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AddressManagement