import { GridColumns } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import {
    STATUS,
} from "..././../../../UI/src/Types/Constants";
import { formatDateTimeDisplay } from '../../../../Utilities/DateTime';

interface IOnStatusChange {
    (user_id: string | number, isChecked: boolean, company_id: string | number): void
}

const { ACTIVE } = STATUS;


export const getColumns = (t: TFunction): GridColumns => [
    {
        field: 'warehouse',
        headerName: t('warehouse'),
        renderCell: ((params) => {
            return <Typography>{params.row.address.company_name}</Typography>

        }),
        width: 250
    },
    {
        field: 'movements_io',
        headerName: t('movements_io'),
        renderCell: ((params) => {
            return <Typography>{(params.row.status).toUpperCase()}</Typography>

        }),
        width: 250
    },
    {
        field: 'movement_date',
        headerName: t('movement_date'),
        renderCell: ((params) => {
            if (params.row.status == 'in') {
                return <Typography>{formatDateTimeDisplay(params.row.createdAt)}</Typography>
            } else {
                return <Typography>{formatDateTimeDisplay(params.row.out_date)}</Typography>

            }

        }),
        width: 250
    },
    {
        field: 'product_name',
        headerName: t('product_name'),
        renderCell: ((params) => {
            return <Typography>{params.row.stock.product_name}</Typography>

        }),
        width: 250
    },
    {
        field: 'batch_code',
        headerName: t('batch_code'),
        width: 250,
        renderCell: ((params) => {
            return <Typography>{params.row.batch_code}</Typography>

        }),
    }, {
        field: 'qty_type',
        headerName: t('qty_type'),
        width: 250,
        renderCell: ((params) => {
            return <Typography>{t(params.row.qty_type)}</Typography>

        }),
    },
    {
        field: 'quantity',
        headerName: t('quantity'),
        width: 250,
        renderCell: ((params) => {
            return <Typography>{t(params.row.quantity)}</Typography>

        }),
    },
    {
        field: 'description',
        headerName: t('description'),
        width: 250,
        renderCell: ((params) => {
            return <Typography>{params.row.stock.description}</Typography>

        }),
    },

]