import { Grid, Typography, Divider, Button, TextField, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Warehouse } from '../../../../Api/Warehouse/Warehouse'
import SitesSelection from '../Stocks/SitesSelection'
import { AppState } from '../../../../Redux'

import { MobileDatePicker } from '@material-ui/pickers'

import { getColumns } from './ScheduledList.columns'
import LazyTable from '../../../Common/LazyTable/LazyTable';

import { IScheduledProps } from './ScheduledList.types'
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'
const warehouseApi = new Warehouse()
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    },
    container: {
        padding: '20px'
    }
}))

function ScheduledList(props: IScheduledProps) {
    const [t] = useTranslation(['Warehouse', 'Columns', 'Common'])
    const { report_type } = props
    const [retainCurrent, setRetainCurrent] = useState(false);
    const [filterState, setFilterState] = useState<any>({
        report_type: report_type
    })
    const user = useSelector((state: AppState) => state.user.response);

    const [scheduledFrom, setScheduledFrom] = useState<Date | null>(null);
    const [scheduledTo, setScheduledTo] = useState<Date | null>(null);
    const [selectedComp, setSelectedComp] = useState<any>('');
    const [onClearCallbackTrigger, setOnClearCallbackTrigger] = useState(0)

    let userId = user.company.id
    const columns = getColumns(t, report_type)
    const lazyGet = async (offset: number, pageNumber: number, limit: number, filter_data: any) => {
        return warehouseApi.getScheduledCollectionJobs(userId, offset, limit, filter_data.report_type, filter_data.filter_id, filter_data.scheduledFrom, filter_data.scheduledTo)
            .then((response) => {
                const jobs = response.data.jobList;
                const count = response.data.jobsCount;
                return { jobs, count }
            })
            .catch((err: any) => {
                console.log('err', err);
                toast.error(t('error_getting_jobs_for_reports'));
            });
    }

    const filterCallback = useCallback((e: any) => {
        setSelectedComp(e)
    }, [])


    const handleOnApply = useCallback(
        () => {
            setFilterState({ filter_id: selectedComp, report_type: report_type, scheduledFrom, scheduledTo })
        },
        [filterState, selectedComp, scheduledFrom, scheduledTo],
    )
    const handleOnClear = useCallback(
        () => {
            setOnClearCallbackTrigger(onClearCallbackTrigger + 1)

            setFilterState('')
            setScheduledFrom(null)
            setScheduledTo(null)
            setFilterState({ filter_id: '', report_type: report_type, scheduledFrom: null, scheduledTo: null })
        },
        [filterState],
    )

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("warehouse_management")} title={(report_type == 'collection') ?
                    t('scheduled_collection')
                    :
                    t('scheduled_delivery')
                } />

                {/* <Grid md item>
                    <Typography>
                        {(report_type == 'collection') ?
                            t('scheduled_collection')
                            :
                            t('scheduled_delivery')
                        }
                    </Typography>
                </Grid> */}
               

                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">

                        <Grid md={12} item container>
                            <Grid item md={2}>
                                <SitesSelection onChangeCallback={filterCallback} onClearCallback={onClearCallbackTrigger} />

                            </Grid>
                            <Grid item md={4}>
                            </Grid>
                            <Grid item container spacing={1} direction='row' md={6}>
                                <Grid md={4} item>
                                    <MobileDatePicker
                                        label={'From:'}
                                        value={scheduledFrom}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={setScheduledFrom}
                                        renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                    />
                                </Grid>
                                <Grid md={4} item>
                                    <MobileDatePicker
                                        minDate={scheduledFrom}
                                        label={'To:'}
                                        value={scheduledTo}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={setScheduledTo}
                                        renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                    />
                                </Grid>
                                <Grid item container spacing={2} md={3} style={{ alignItems: 'center' }}>

                                    <Grid md={6} item>

                                        <Button onClick={handleOnClear} color="primary" variant="contained">
                                            Clear
                                        </Button>
                                    </Grid>
                                    <Grid md={6} item>

                                        <Button onClick={handleOnApply} color="primary" variant="contained">
                                            {t('apply')}
                                        </Button>
                                    </Grid>
                                </Grid>


                            </Grid>
                        </Grid>
                        <Grid item md={12}>
                            <LazyTable
                                columns={columns}
                                // onRowClick={onRowClick}
                                lazyFunction={lazyGet}
                                lazyFilter={filterState}
                                retainCurrent={retainCurrent}

                            />

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default ScheduledList