import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDeliveryFilterDialogProps } from "./DeliveryFilter.types";
import { DateTimePicker } from "@material-ui/pickers";
import { IDeliveryFilter, IJobReportFilter } from "../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { JOB_STATUS } from "../../../Types/Constants";
import { inArray } from "../../../Utilities/Array";
import { deepCopy } from "../../../Utilities/Object";
import { format } from 'date-fns'
import moment from 'moment-timezone';

import { MobileDatePicker } from "@material-ui/pickers";

const {
    COMPLETED,
    IN_PROGRESS,
    PICKEDUP,
    DELIVERED
} = JOB_STATUS

const useStyles = makeStyles(() => ({
    invisible: {
        opacity: 0
    },
    box1: { width: '100%' },
    box2: { width: '80%' }
}))

function DeliveryReportFilterDialog(props: IDeliveryFilterDialogProps) {
    const [t] = useTranslation()
    const { open, handleClose, initialFilterState, onApplyCallback, filterType } = props
    const classes = useStyles()
    const [filterState, setFilterState] = useState<IDeliveryFilter>(initialFilterState ?? { filterType: filterType, date: new Date() })
    // const [pickUpDate, handlepickUpDate] =  useState<Date | null>(filterState.date ?? null)
    const [selectedDate, handleDateChange] = useState<Date | undefined>(null)

    const [isShowDatePicker, setIsShowDatePicker] = useState<boolean>(false)
    const [selectedInput, setSelectedInput] = useState(filterState.filterType)



    useEffect(() => {
        setFilterState({
            filterType: filterType,
            date: selectedDate ? selectedDate : new Date()
        })
    }, [selectedDate])

    const handleOnApplyClick = useCallback(
        () => {
            if (onApplyCallback) {
                setFilterState({
                    filterType: filterType,
                    date: selectedDate
                })

                onApplyCallback(filterState)

            }
        },
        [filterState, onApplyCallback],
    )
    const handleOnApplyClear = useCallback(
        () => {
            if (onApplyCallback) {
                onApplyCallback({
                    filterType: filterType
                })

            }
        },
        [filterState, onApplyCallback],
    )

    const handleDateChanges = (data: any) => {

        handleDateChange(data)
    }

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    {t('job_report_filter')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >

                        <Grid item md={6}></Grid>

                        <Grid
                            container
                            spacing={1}
                        >

                            <Grid item md={6}>
                                {filterType == 'delivery' ?
                                    (<Typography gutterBottom> {t('scheduled_delivery')}   </Typography>)
                                    :
                                    (<Typography gutterBottom> {t('scheduled_collection')}   </Typography>)

                                }

                                <MobileDatePicker
                                    renderInput={props => <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Date"
                                        {...props}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />}
                                    inputFormat='dd/MM/yyyy'
                                    value={selectedDate}
                                    onChange={(e: any) => { handleDateChanges(e) }}
                                />
                            </Grid>

                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnApplyClear} color="primary" variant="contained">
                        Clear
                    </Button>
                    <Button onClick={handleOnApplyClick} color="primary" variant="contained">
                        {t('apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DeliveryReportFilterDialog