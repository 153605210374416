import { Grid, Typography, Divider } from '@material-ui/core'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Driver } from '../../../../Api/Driver/Driver'
import PaginatedTable from '../../../Common/PaginatedTable/PaginatedTable'
import { getWaitingForApprovalColumns } from './WaitingForApproval.columns'
import { Pageable } from '../../../../../../types/pageable'
import { IUserInfo } from '../../../../../../Application/DTOs/UsersDto/UsersDto.type'
import WaitingForApprovalDialog from '../../../Dialogs/WaitingForApprovalDialog/WaitingForApprovalDialog'
import { useSelector } from 'react-redux'
import { AppState } from '../../../../Redux'
import { USER_ROLES } from '../../../../Types/Constants'
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'

const driverApi = new Driver()

const INITIAL_PAGE = 0
const INITIAL_PAGE_SIZE = 5

const {
    ADMIN_USER
} = USER_ROLES

function WaitingForApproval() {
    const [t] = useTranslation('WaitingForApproval')
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
    const currentUser = useSelector((state: AppState) => state.user)
    const [totalCount, setTotalCount] = useState(0)
    const columns = getWaitingForApprovalColumns(t)
    const [isTableFetching, setIsTableFetching] = useState(false)
    const [users, setUsers] = useState<IUserInfo[]>([])
    const [isWaitingForApprovalDialogOpen, setIsWaitingForApprovalDialogOpen] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
    const isUserAdmin = currentUser.response?.type === ADMIN_USER

    const handlePageChange = useCallback(
        (params: GridPageChangeParams) => {
            setCurrentPage(params.page)
        },
        [],
    )

    const handlePageSizeChange = useCallback(
        (params: GridPageChangeParams) => {
            setPageSize(params.pageSize)
        },
        [],
    )

    const getDriversWaitingForApproval = useCallback(
        (currentPage: number, pageSize: number) => {
            setIsTableFetching(true)
            driverApi.getAwaitingForApproval(currentPage, pageSize, isUserAdmin ? currentUser.response?.country : undefined)
                .then((response) => {
                    const data: Pageable<IUserInfo> = response.data

                    setTotalCount(data.total)
                    setUsers(data.rows)

                    setIsTableFetching(false)

                })
                .catch((err :any) => {
                    setIsTableFetching(false)
                    setUsers([])
                    console.log('err', err)
                    toast.error(t('error_getting_drivers_waiting_for_approval'))
                })
        },
        [t, currentUser.response?.country, isUserAdmin]
    )

    const handleWaitingForApprovalDialogClose = useCallback(
        () => {
            setIsWaitingForApprovalDialogOpen(false)
            getDriversWaitingForApproval(currentPage + 1, pageSize)
        },
        [currentPage, pageSize, getDriversWaitingForApproval],
    )

    useEffect(() => {
        getDriversWaitingForApproval(currentPage + 1, pageSize)
        
    }, [getDriversWaitingForApproval, currentPage, pageSize])

    const onRowClick = useCallback(
        (rowParams: GridRowParams) => {
            setIsWaitingForApprovalDialogOpen(true)
            const user: IUserInfo = rowParams.row as IUserInfo
            setSelectedUserId(user.id)
        },
        [],
    )

    return (
        <>
            <WaitingForApprovalDialog selectedUserId={selectedUserId} open={isWaitingForApprovalDialogOpen} handleClose={handleWaitingForApprovalDialogClose} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("driver_management")} title={t('waiting_for_approval')} />

               
                <Grid item>
                    <Grid
                        container
                    >
                        <Grid item md={12}>
                            <PaginatedTable 
                                columns={columns}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                loading={isTableFetching}
                                onPageChange={handlePageChange}
                                rows={users}
                                totalCount={totalCount}
                                onPageSizeChange={handlePageSizeChange}
                                onRowClick={onRowClick}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}

export default WaitingForApproval