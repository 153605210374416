import { Typography } from '@material-ui/core';
import { GridColumns } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { formatDate } from '../../../../Utilities/DateTime';
import { IJobInfo } from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
export const getColumns = (t: TFunction): GridColumns => {
  return [
    {
      field: 'reference',
      headerName: t('Columns:reference'),
      width: 200,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        const job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'status',
      headerName: t('Columns:status'),
      width: 200,
    },
    {
      field: 'company',
      headerName: t('Columns:delivered_by'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const user = jobInfo?.job_bids[0].user;
        const company = user.company;
        const companyName: string = company
          ? company.name
          : `${user.first_name} ${user.last_name}`;
        return <Typography>{companyName}</Typography>;
      },
    },
    {
      field: 'actual_deliver_to_date_time',
      headerName: t('Columns:actual_delivery_date'),
      width: 200,
      renderCell: (params) => {
        const jobInfo = params.row;
        const delivery_date: string = String(
          jobInfo?.actual_deliver_to_date_time
        );
        return <Typography>{formatDate(delivery_date)}</Typography>;
      },
    },
  ];
};
