import {
  Grid,
  Typography,
  Divider,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COUNTRIES,
  COUNTRY_INSURANCES,
  USER_ROLES,
  VEHICLE_TYPES_BY_COUNTRY,
} from '../../../../Types/Constants';
import AttachmentIcon from '@material-ui/icons/Attachment';
import HomeIcon from '@material-ui/icons/Home';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../Redux';
import { IDriverRegistrationPayload } from '../../../../../../Application/DTOs/DriverDto/DriverDto.types';
import { setLoader } from '../../../../Redux/Loader/actions';
import { Driver } from '../../../../Api/Driver/Driver';
import { toast } from 'react-toastify';
import { isNil } from 'lodash';
import { isNumeric } from '../../../../Utilities/Regex';
import { getLabelForRegistrationNumberByCountry } from '../../../../Utilities/Country';
import isEmail from 'validator/lib/isEmail';
import SectionHeader from '../../../Common/SectionHeader/SectionHeader';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
  title: {
    fontWeight: 'bold',
  },
  gap: {
    marginTop: theme.spacing(3),
  },
  btnRegister: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
}));

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;

const { SUPER_ADMIN, ADMIN_USER, COMPANY } = USER_ROLES;

const { COMPANY_DRIVER, DRIVER } = USER_ROLES;

const driverApi = new Driver();

function AddDriver() {
  const [t] = useTranslation('AddDriver');
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const isSuperAdmin: boolean = [SUPER_ADMIN].includes(
    user.response?.type ?? ''
  );
  const isUserAdmin: boolean = user.response?.type === ADMIN_USER;
  const isCompany: boolean = user.response?.type === COMPANY;
  const classes = useStyles();
  const adminsCountry = user.response?.country;
  const companyDriverCountry = user.response?.company?.country;
  const [country, setCountry] = useState(
    !isSuperAdmin && !isUserAdmin
      ? companyDriverCountry ?? ''
      : adminsCountry ?? ''
  );
  const [email, setEmail] = useState('');
  const [emailConfirm, setEmailConfirm] = useState('');
  const [mobile, setMobile] = useState('');
  const [mobileConfirm, setMobileConfirm] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [truckRegistration, setTruckRegistration] = useState('');
  const [trailerRegistration, setTrailerRegistration] = useState('');
  const [showPass, setShowPass] = useState<boolean>(false);
  const [companyName, setCompanyName] = useState(
    isCompany ? user.response?.company?.companyName ?? '' : ''
  );
  const [companyUEN, setCompanyUEN] = useState(
    isCompany ? user.response?.company?.companyUen ?? '' : ''
  );
  const [streetNameAddress, setStreetNameAddress] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [waitTimeAfter, setWaitTimeAfter] = useState('0');
  const [waitTimeCharge, setWaitTimeCharge] = useState('0');
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [licensePhoto, setLicensePhoto] = useState<File | null>(null);
  const [insurances, setInsurances] = useState<string[]>([]);
  const INSURANCES = COUNTRY_INSURANCES[country] ?? [];

  const handleOnRegisterClick = () => {
    if (email === '' || emailConfirm === '') {
      toast.warning(t('email_not_empty'));
      return;
    } else {
      if (!isEmail(email) || !isEmail(emailConfirm)) {
        toast.warning(t('email_invalid_format'));
        return;
      }

      if (email !== emailConfirm) {
        toast.warning(t('email_not_match'));
        return;
      }
    }

    if (mobile === '') {
      toast.warning(t('mobile_not_empty'));
      return;
    } else if (!isNumeric(mobile)) {
      toast.warning(t('mobile_not_number'));
      return;
    }

    if (mobileConfirm === '') {
      toast.warning(t('confirm_mobile_not_empty'));
      return;
    } else if (!isNumeric(mobileConfirm)) {
      toast.warning(t('confirm_mobile_not_number'));
      return;
    }

    if (mobile !== '' && mobileConfirm !== '') {
      if (mobile !== mobileConfirm) {
        toast.warning(t('mobile_not_match'));
        return;
      }
    }

    if (firstName === '') {
      toast.warning(t('firstname_not_empty'));
      return;
    }

    if (lastName === '') {
      toast.warning(t('lastname_not_empty'));
      return;
    }

    if (password === '' || passwordConfirm === '') {
      toast.warning(t('password_not_null'));
      return;
    } else if (password.length < 6) {
      toast.warning(t('invalid_password_length'));
      return;
    } else if (password !== passwordConfirm) {
      toast.warning(t('password_did_not_match'));
      return;
    }

    if (country === PHILIPPINES) {
      if (companyUEN === '') {
        toast.warning(t('tin_not_empty'));
        return;
      } else if (!isNumeric(companyUEN)) {
        toast.warning(t('invalid_tin'));
        return;
      }
    }

    if (companyUEN === '') {
      if (country === AUSTRALIA) {
        toast.warning(t('abn_not_empty'));
        return;
      } else if (country === JAPAN) {
        toast.warning(t('company_registration_number_not_empty'));
        return;
      } else {
        toast.warning(t('eun_not_empty'));
        return;
      }
    }

    if (postalCode !== '' && !isNumeric(postalCode)) {
      toast.warning(t('postal_code_not_numeric'));
      return;
    }

    if (waitTimeAfter === '') {
      toast.warning(t('wait_time_after_not_empty'));
      return;
    } else if (!isNumeric(waitTimeAfter)) {
      toast.warning(t('wait_time_not_number'));
      return;
    }

    if (waitTimeCharge === '') {
      toast.warning(t('wait_time_charge_not_empty.'));
      return;
    } else if (!isNumeric(waitTimeCharge)) {
      toast.warning(t('wait_time_charge_not_number'));
      return;
    }

    // if (photoFile === null) {
    //     toast.warning(t('photo_not_empty'))
    //     return
    // }

    // if (licensePhoto === null) {
    //   toast.warning(t('license_not_empty'));
    //   return;
    // }

    const formData = new FormData();
    const payload: IDriverRegistrationPayload = {
      city,
      company_name: isSuperAdmin || isUserAdmin ? companyName : undefined,
      company_uen: isSuperAdmin || isUserAdmin ? companyUEN : undefined,
      contact_phone: mobile,
      email,
      first_name: firstName,
      last_name: lastName,
      is_marine_transit: false,
      is_motor_vehicle: false,
      is_public_liability: false,
      is_worker_comp: false,
      mobile,
      password,
      passwordConfirm,
      postcode: postalCode,
      street_num: streetNameAddress,
      trailer_registration: trailerRegistration,
      truck_registration: truckRegistration,
      userType: isSuperAdmin || isUserAdmin ? DRIVER : COMPANY_DRIVER,
      vehicle_type: vehicleType,
      wait_time_after: waitTimeAfter,
      wait_time_charge: waitTimeCharge,
      is_approved: false,
      country,
      company_id: !isSuperAdmin ? user.response?.company?.id : undefined,
      is_email_contactable: true,
      is_sms_contactable: true,
      insurances: [],
    };

    for (const key in payload) {
      const value: any = payload[key];

      if (!isNil(value)) {
        formData.append(key, value);
      }
    }

    if (photoFile) {
      formData.append('photoFile', photoFile);
    }

    if (licensePhoto) {
      formData.append('licenseFile', licensePhoto);
    }

    dispatch(setLoader(true));
    driverApi
      .create(formData)
      .then(() => {
        dispatch(setLoader(false));
        toast.success(t('succcess_registering_driver'));
      })
      .catch((err: any) => {
        dispatch(setLoader(false));
        toast.error(t(err.response?.data || 'error_registering_driver'));
        console.log('err', err);
      });
  };

  const handleInsuranceCheck = useCallback(
    (isChecked: boolean, insurance: string) => {
      if (isChecked) {
        if (!insurances.includes(insurance)) {
          insurances.push(insurance);
          setInsurances([...insurances]);
        }
      } else {
        setInsurances(insurances.filter((ins) => ins !== insurance));
      }
    },
    [insurances]
  );

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={5}
      >
        <SectionHeader subtitle={t("driver_management")} title={t('add_driver')} />

       
        <Grid item>
          <Grid container spacing={2}>
            {/* {isSuperAdmin ? (
              <Grid md={12} item>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel style={{fontWeight: 'bold'}}>
                    {t('select_your_country')}
                  </InputLabel>
                  <Select
                    value={country}
                    onChange={(e) => {
                      setCountry(e.target.value as string);
                      setVehicleType('');
                    }}
                  >
                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : null} */}
            <Grid md={6} item>
              <TextField
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('provide_email_address') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={emailConfirm}
                onChange={(e) => setEmailConfirm(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('confirm_email_address') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AlternateEmailIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('provide_mobile_number') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DialpadIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={mobileConfirm}
                onChange={(e) => setMobileConfirm(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('confirm_mobile_number') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <DialpadIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('enter_first_name') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('enter_last_name') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('enter_password') + '*'}
                type={showPass ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPass(!showPass)}>
                        {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('confirm_password') + '*'}
                type={showPass ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPass(!showPass)}>
                        {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>

            {!isCompany ? (
              <>
                <Grid md={12} item>
                  <Typography
                    className={classes.title}
                    align="left"
                    variant="body1"
                  >
                    {t('address_details')}
                  </Typography>
                </Grid>
                <Grid md={12} item>
                  <TextField
                    value={streetNameAddress}
                    onChange={(e) => setStreetNameAddress(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('enter_street_num_n_name')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <HomeIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
                <Grid md={6} item>
                  <TextField
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('enter_suburb_or_city')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <HomeIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
                <Grid md={6} item>
                  <TextField
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('enter_postal_code')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <HomeIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
              </>
            ) : null}

            {isSuperAdmin || isUserAdmin ? (
              <>
                <Grid className={classes.gap} md={12} item>
                  {/* GAP */}
                </Grid>
                <Grid md={6} item>
                  <TextField
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('enter_company_name') + '*'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
                <Grid md={6} item>
                  <TextField
                    value={companyUEN}
                    onChange={(e) => setCompanyUEN(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={
                      t(getLabelForRegistrationNumberByCountry(country)) + '*'
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
              </>
            ) : null}

            <Grid className={classes.gap} md={12} item>
              {/* GAP */}
            </Grid>
            <Grid md={6} item>
              <TextField
                value={truckRegistration}
                onChange={(e) => setTruckRegistration(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('truck_reg') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={6} item>
              <TextField
                value={trailerRegistration}
                onChange={(e) => setTrailerRegistration(e.target.value)}
                variant="outlined"
                fullWidth
                label={t('trailer_reg') + '*'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
              />
            </Grid>
            <Grid md={12} item>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel style={{ fontWeight: 'bold' }}>
                  {t('vehicle_type')}*
                </InputLabel>
                <Select
                  value={vehicleType}
                  onChange={(e) => setVehicleType(e.target.value as string)}
                >
                  {(VEHICLE_TYPES_BY_COUNTRY[country] || []).map(
                    (VEHICLE_TYPE, i) => (
                      <MenuItem key={i} value={VEHICLE_TYPE}>
                        {t(VEHICLE_TYPE)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            {!isCompany ? (
              <>
                <Grid md={6} item>
                  <TextField
                    value={waitTimeAfter}
                    onChange={(e) => setWaitTimeAfter(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('wait_time_after') + '*'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
                <Grid md={6} item>
                  <TextField
                    value={waitTimeCharge}
                    onChange={(e) => setWaitTimeCharge(e.target.value)}
                    variant="outlined"
                    fullWidth
                    label={t('wait_time_charge') + '*'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </Grid>
                <Grid className={classes.gap} md={12} item>
                  {/* GAP */}
                </Grid>
                <Grid lg={12} item>
                  <Typography
                    className={classes.title}
                    align="left"
                    variant="body1"
                  >
                    {t('please_confirm_your_insurance_coverage')}*
                  </Typography>
                </Grid>
                {INSURANCES.map((insurance, i) => (
                  <Grid key={i} md={i % 2 === 0 ? 3 : 9} item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={insurances.includes(insurance)}
                          onChange={(e) =>
                            handleInsuranceCheck(e.target.checked, insurance)
                          }
                          color="primary"
                        />
                      }
                      label={t(insurance)}
                    />
                  </Grid>
                ))}
                <Grid className={classes.gap} md={12} item>
                  {/* GAP */}
                </Grid>
                <Grid md={6} item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('upload_photo') + '*'}
                    type="file"
                    onChange={(e: any) => setPhotoFile(e.target.files[0])}
                    inputProps={{
                      multiple: false,
                      accept: 'image/*',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AttachmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid md={6} item>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={t('upload_license') + '*'}
                    type="file"
                    onChange={(e: any) => setLicensePhoto(e.target.files[0])}
                    inputProps={{
                      multiple: false,
                      accept: 'image/*',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <AttachmentIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}
            <Grid className={classes.gap} md={12} item>
              {/* GAP */}
            </Grid>
            <Grid md={12} item>
              <Button
                onClick={handleOnRegisterClick}
                startIcon={<DoneOutlineIcon />}
                className={classes.btnRegister}
                variant="contained"
                fullWidth
              >
                {t('register')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AddDriver;
