import { IMultiAutocompleteProps } from "./MultiAutocomplete.types";
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual } from "lodash";

function MultiAutocomplete(props: IMultiAutocompleteProps) {
    const {label, placeholder, options, values, onChange, disabled} = props

    return (
        <Autocomplete
            multiple
            onChange={(e, values) => {
                if(onChange) onChange(values)
            }}
            disableCloseOnSelect
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => isEqual(option.value, value.value)}
            value={values}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder ?? ''}
                    InputLabelProps={{
                        style: {
                            fontWeight: 'bold'
                        }
                    }}
                />
            )}
        />
    )
}

export default MultiAutocomplete