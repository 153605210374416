// store.ts

import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer, AppState } from '.'; // Assuming you have rootReducer and AppState defined

const composeEnhancers = composeWithDevTools({ trace: true });

const persistConfig = {
  key: 'root', // key for storage
  storage, // storage backend
  whitelist: ['auth', 'user', 'company' ,'theme'], // reducers to persist (only these)
};

const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(
      thunk
    )
  )
);

const persistor = persistStore(store);

export { store, persistor };
