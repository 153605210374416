import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";


export const getAdminUsersTableColumns = (t: TFunction):GridColumns => [
    {
        field: 'firstName',
        headerName: t('Columns:first_name'),
        width: 200
    },
    {
        field: 'lastName',
        headerName: t('Columns:last_name'),
        width: 200
    },
    {
        field: 'email',
        headerName: t('Columns:email'),
        width: 300
    },
    {
        field: 'status',
        headerName: t('Columns:status'),
        width: 200
    },
]