import { ICompanyFeeConfig } from "../../../../../../Application/DTOs/CompanyFeeSettingsDto/CompanyFeeSettingsDto.types"
import { deepCopy } from "../../../../Utilities/Object"
import { ICompanyFeeSettingsActions } from "./CompanyFeeSettings.types"


export function CompanyFeeSettingsReducer(state: ICompanyFeeConfig, action: ICompanyFeeSettingsActions): ICompanyFeeConfig {
    switch (action.type) {
        case 'UPDATE': 
            const {movementType, jobType, amount, commissionType} = action.payload

            state[movementType][jobType] = {
                amount,
                type: commissionType
            }
        
            return deepCopy(state)

        case 'SET':
            state = action.payload
            return deepCopy(state)
    
        default:
            return state
    }

}