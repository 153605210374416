import { Button, makeStyles, Dialog, DialogActions, InputAdornment, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IMarkCompleteDialogProps } from "./MarkCompleteDialog.types";
import { Job } from "../../../Api/Job/Job";
import { useEffect, useMemo, useState } from "react";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import AttachmentIcon from '@material-ui/icons/Attachment';
import no_image from '../../../Images/no_image.png'
import { toast } from "react-toastify";
import { isFloat } from "../../../Utilities/Regex";
import { DateTimePicker, MobileDatePicker } from '@material-ui/pickers';
import { setLoader } from '../../../Redux/Loader/actions';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateTimeDisplay } from '../../../Utilities/DateTime';
import TodayIcon from '@material-ui/icons/Today';
import { AdminSettings } from "../../../Api/AdminSettings/AdminSettings"
import { IRatesManagement } from "../../../../../Application/Models/RatesManagementSettingsModel/RatesManagement.types";
import moment from "moment";
const jobApi = new Job()

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}))

function BidDialog(props: IMarkCompleteDialogProps) {
    const classes = useStyles()

    const [t, i18n] = useTranslation('MarkCompleteDialog')

    const [signatureFile, setSignatureFile] = useState<File | null>(null)
    const [photoFile, setPhotoFile] = useState<File | null>(null)
    const [companyLogoFile, setCompanyLogoFile] = useState<File | null>(null)

    const [waitingTime, setWaitingTime] = useState('0')
    const [recipientName, setRecipientName] = useState('')
    const { selectedJob, handleClose, isAssignedUser } = props
    // Additional Charges
    const [additionalChargeDescription1, setAdditionalChargeDescription1] =
        useState('');
    const [additionalChargeDescription2, setAdditionalChargeDescription2] =
        useState('');
    const [additionalChargeAmount1, setAdditionalChargeAmount1] =
        useState<any>(0);
    const [additionalChargeAmount2, setAdditionalChargeAmount2] =
        useState<any>(0);

    const [CNTRDehireDay, setCNTRDehireDay] = useState<Date | null>(null);

    const dispatch = useDispatch();
    const adminSettingApi = new AdminSettings()

    const getRatesConnnect = () => {

        return adminSettingApi.getRates(
            {
                posting_company: selectedJob.company.id,
                transport_company: selectedJob.assigned_user.company.id,
                movement_type: selectedJob.movement_type,
                temperature: selectedJob.temperature,
                pick_up_address_id: selectedJob.pick_up_address.id,
                deliver_address_id: selectedJob.deliver_to_address.id,
                is_default: false,
                configuration_type: 'wharf',
                container_type: selectedJob.job_type,
                state_type: selectedJob.deliver_to_address.state_type || ''
            }
        )
            .then(async (response) => {
                const ratesData = response.data as IRatesManagement;
                if (ratesData.rates.length >0){
                    const rates = ratesData.rates[0]
                    const freeDaysStorage : number = rates.freeDaysStorage || 0
                    const storageFee= rates.storageFee || 0
                    const pickUpDate =moment(selectedJob.actual_pick_up_time)
                    var pickUpWithFreeDays = moment(pickUpDate.add(freeDaysStorage, 'days').toDate());
                    const dateDiff =moment().diff( pickUpWithFreeDays, 'days')
                    const fee = storageFee * dateDiff
                    setAdditionalChargeDescription1('Storage Fee')
                    setAdditionalChargeAmount1(fee)

                }

            })
            .catch((err: any) => {
                console.log('err', err);
                toast.error(t('error_getting_config'));
                return false
            });
    }
    const clearState=()=>{
        setSignatureFile(null)
        setPhotoFile(null)
        setCompanyLogoFile(null)
        setWaitingTime('0')
        setRecipientName('')
        setAdditionalChargeDescription1('')
        setAdditionalChargeAmount1('')
        setAdditionalChargeDescription2('')
        setAdditionalChargeAmount2('')
    }
    useEffect(()=>{
        if (selectedJob && selectedJob?.movement_type =='wharf' && selectedJob.assignedUser) {
            getRatesConnnect()
        }
        if(props.open) clearState()
      
    },[selectedJob])

    const handleSaveAdditionalCharges = (callback?: any) => {
        if (selectedJob) {
            jobApi
                .updateAdditionalCharges(selectedJob.id, {
                    description1: additionalChargeDescription1,
                    description2: additionalChargeDescription2,
                    amount1: additionalChargeAmount1,
                    amount2: additionalChargeAmount2,

                })
                .then(() => {
                    // toast.success('Additional Charges updated');

                    if (typeof callback === 'function') {
                        callback();
                    }
                })
                .catch((err: any) => {
                    toast.error(t('error_updating_additional_charges'));
                });
        }
    }
    const handleCompleteClick = () => {
        dispatch(setLoader(true));
        if (isFloat(waitingTime)) {
            toast.warning(t('invalid_wait_time'))
            dispatch(setLoader(false));
            return
        }
        if(selectedJob?.container_no  && CNTRDehireDay instanceof Date === false){
            toast.warning('Please Input a Dehire Date')
            dispatch(setLoader(false));
            return
        }
        if (selectedJob) {
            const formData = new FormData()

            formData.append('jobId', String(selectedJob.id))
            formData.append('signatureFile', signatureFile as any)
            formData.append('photoFile', photoFile as any)
            formData.append('companyLogoFile', companyLogoFile as any)
            formData.append('jobWaitTime', waitingTime)
            formData.append('recipient', recipientName)
            formData.append('cntr_dehire_day', CNTRDehireDay as any)
            formData.append('lang', i18n.language)
            handleSaveAdditionalCharges()
            jobApi.markComplete(formData)
                .then(() => {
                    handleClose()
                    dispatch(setLoader(false));

                    toast.success(t('success_marking_job_as_complete'))
                })
                .catch((err: any) => {
                    dispatch(setLoader(false));

                    console.log('err', err)
                    toast.error(t('error_marking_job_as_complete'))
                })

        }
    }

    const signatureImagePreview = useMemo(() => signatureFile ? URL.createObjectURL(signatureFile) : no_image, [signatureFile])
    const photoImagePreview = useMemo(() => photoFile ? URL.createObjectURL(photoFile) : no_image, [photoFile])
    const companyLogoImagePreview = useMemo(() => companyLogoFile ? URL.createObjectURL(companyLogoFile) : no_image, [companyLogoFile])

    return (
        <>
            <Dialog fullWidth maxWidth="md" open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('mark_job_as_complete')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item md={6}>
                            <TextField
                                value={waitingTime}
                                onChange={(e) => setWaitingTime(e.target.value)}
                                fullWidth
                                type="number"
                                variant="outlined"
                                label={t('waiting_time')}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>


                        <Grid item md={6}>
                            <TextField
                                value={recipientName}
                                onChange={(e) => setRecipientName(e.target.value)}
                                fullWidth
                                type="text"
                                variant="outlined"
                                label={t('recipient_name')}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        {selectedJob?.container_no ?
                            <Grid item md={12}>
                                <Grid item md={6}>
                                    <DateTimePicker
                                        inputFormat='dd/MM/yyyy HH:mm'
                                        ampm={false}
                                        renderInput={(props) => (
                                            <TextField fullWidth variant="outlined" {...props} />
                                        )}
                                        label={'CNTR Dehire Day'}
                                        value={CNTRDehireDay}
                                        onChange={setCNTRDehireDay}
                                    />
                                </Grid>
                            </Grid>
                            : null}

                      

                        <Grid item md={12}>
                            <TextField
                                onChange={(e: any) => setSignatureFile(e.target.files[0])}
                                fullWidth
                                label={t('signature')}
                                type="file"
                                variant="outlined"
                                inputProps={{
                                    accept: 'image/*'
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    },
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AttachmentIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <img className={classes.image} src={signatureImagePreview} alt="" />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                onChange={(e: any) => setPhotoFile(e.target.files[0])}
                                fullWidth
                                label={t('photo')}
                                type="file"
                                variant="outlined"
                                inputProps={{
                                    accept: 'image/*'
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    },
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AttachmentIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <img className={classes.image} src={photoImagePreview} alt="" />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                onChange={(e: any) => setCompanyLogoFile(e.target.files[0])}
                                fullWidth
                                label={t('company_logo')}
                                type="file"
                                variant="outlined"
                                inputProps={{
                                    accept: 'image/*'
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    },
                                    shrink: true
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AttachmentIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <img className={classes.image} src={companyLogoImagePreview} alt="" />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCompleteClick} variant="contained" color="primary">
                        {t('complete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BidDialog