import capitalize from "capitalize";
import { ICompanyInfo ,ICompanyContact } from "../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { IDriverInfo } from "../../../Application/DTOs/DriverDto/DriverDto.types";
import { IAutoCompleteResponse, IAutocompleteOption } from "../Components/Common/types";



export const companiesToOptions = (company: ICompanyInfo): IAutocompleteOption => ({
    label: company.companyName,
    value: company.id
})

export const driverToOptions = (driver: IDriverInfo): IAutocompleteOption => ({
    label: capitalize.words(`${driver.first_name ?? ''} ${driver.last_name ?? ''}`),
    value: driver.id
})
export const emailsToOption = (company: ICompanyContact): IAutocompleteOption => ({
    label: company.contact_email,
    value: company.id,
    id : company.id
})


export const reponseToOption = (company: ICompanyContact): IAutoCompleteResponse => ({
    label: company.contact_email,
    value: company.id, 
    id : company.id,
    company_name : company['company.name']  || company.name,
    is_approve : company.is_approve,
    buy_rate : company.buy_rate,
    sell_rate : company.sell_rate,
})
