import React, { useCallback, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer, InfoWindow } from '@react-google-maps/api';
import { Grid, Typography, makeStyles, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
};

const center = {
    lat: -34.0207477547681,
    lng: 151.04825829174933,
};

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    legendContainer: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        zIndex: theme.zIndex.tooltip,
    },
}));

const GoogleMapsComp = ({ mapData }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const classes = useStyles();
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [t] = useTranslation()
    const getStatusColor = (status) => {
        if (['created' ,'open'].includes(status)) {
            return '#fb4b4b';
        }
        switch (status) {
            case ['open','created']:
                return '#fb4b4b';
            case 'assigned':
                return '#2cba00';
            case 'in-progress':
                return '#ffa700';
            default:
                return '#000000'; // Default color
        }
    };

    const getMarkerIcon = (status) => {
        return {
            path: "M6,0C3.2385864,0,1,2.2385864,1,5s2.5,5,5,7c2.5-2,5-4.2385864,5-7S8.7614136,0,6,0z M6,7C4.8954468,7,4,6.1045532,4,5s0.8954468-2,2-2s2,0.8954468,2,2S7.1045532,7,6,7z",
            fillColor: getStatusColor(status),
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 2,
            anchor: new window.google.maps.Point(6, 15),
        };
    };

    const clusterStyles = [
        {
            url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
            height: 50,
            width: 50,
            textColor: '#FFFFFF',
            textSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Arial, sans-serif',
            backgroundPosition: 'center center'
        }
    ];
    const handleMarkerClick = useCallback((marker) => {
        setSelectedMarker(marker);
    }, []);

    const handleMarkerMouseOver = useCallback((marker) => {
        setHoveredMarker(marker);
    }, []);

    const handleMarkerMouseOut = useCallback(() => {
        setHoveredMarker(null);
    }, []);
    return isLoaded ? (
        <Grid container className={classes.mapContainer}>
            <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%' }}
                center={center}
                zoom={5}
                options={{
                    mapTypeControlOptions: {
                        mapTypeIds: ['roadmap']
                    },
                    disableDefaultUI: true,
                    zoomControl: true,
                }}
            >
                <MarkerClusterer
                    options={{ styles: clusterStyles }}
                >
                    {(clusterer) =>
                        mapData.map((marker, index) => (
                            <Marker
                                key={index}
                                position={{ lat: marker.lat, lng: marker.lng }}
                                clusterer={clusterer}
                                icon={getMarkerIcon(marker.status)}
                                onClick={() => handleMarkerClick(marker)}
                                onMouseOver={() => handleMarkerMouseOver(marker)}
                                onMouseOut={handleMarkerMouseOut}
                            >
                                {hoveredMarker === marker && (
                                    <InfoWindow>
                                        <div>
                                            <Typography variant="body2">
                                                Reference: {marker.title}
                                            </Typography>
                                            <Typography variant="body2">
                                                Status: {t(marker.status)}
                                            </Typography>
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker>
                        ))
                    }
                </MarkerClusterer>
            </GoogleMap>
            {/* <Paper className={classes.legendContainer}>
                <Typography variant="h6">Legend</Typography>
                <div style={{ marginBottom: '10px' }}>
                    <span style={{ color: '#fb4b4b', marginRight: '5px' }}>●</span> Open
                </div>
                <div>
                    <span style={{ color: '#2cba00', marginRight: '5px' }}>●</span> Assigned
                </div>
                <div>
                    <span style={{ color: '#ffa700', marginRight: '5px' }}>●</span> In Progress
                </div>
            </Paper> */}
        </Grid>
    ) : <div>Loading...</div>;
};

export default GoogleMapsComp;
