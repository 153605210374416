import { Button } from "@material-ui/core";
import { IFileInputProps } from "./FileInput.types";

import AttachmentIcon from '@material-ui/icons/Attachment';
import { useTranslation } from "react-i18next";
import { useCallback,  useState } from "react";

function FileInput(props: IFileInputProps) {
    const { onChange: onChangeProps,  multiple, disabled, label, accept , value } = props
    const [t] = useTranslation('FileInput')
    const noneSelectedText = t('none')
    const [fileNames, setFileNames] = useState<string[]>([])
    if(value)
    {
        if(fileNames.indexOf(value) == -1 ){
            fileNames.push(value)
        }
    }   
 const onChange = useCallback((e) => {
        onChangeProps(e)
        let files: FileList = e.target.files
        let fileNames: string[] = []
        
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                fileNames.push(file.name)
            }
        

        setFileNames(fileNames)

    }, [onChangeProps])

    return (
        <>
            <Button
                fullWidth
                startIcon={<AttachmentIcon />}
                variant="contained"
                component="label"
                disabled={disabled}
            >
                {label ?? t('upload_files')}
                <input
                    id={'check_'+label}
                    onChange={onChange}
                    type="file"
                    multiple={multiple}
                    hidden
                    accept={accept}
                />
            </Button>{
       
                 !disabled
                    ? <label style={{'color' :fileNames.length === 0 ? 'red' : 'green'}}> {t('selected_files', { fileNames: fileNames.length === 0 ? noneSelectedText : fileNames.join(', ') })}</label>
                    : null
            }
            
        </>
    )
}


export default FileInput