import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Grid, Button, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { COMPANY_TYPES, COUNTRIES, STATUS } from "../../../Types/Constants"
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import useQuery from "../../../hooks/useQuery";
import { ICustomerDetails } from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types";
import StocksDetails from "../../Common/StocksDetails/StocksDetails";
import { IStocksDialogProps } from "./StocksDialog.types"


const useStyles = makeStyles((theme) => ({
    driverRegisterBox: {
        backgroundColor: "white",
        padding: "40px 15px",
        borderRadius: "20px",
        boxShadow: "2px 3px 6px -1px rgba(0,0,0,0.5)",
        overflowY: "auto",
        height: "95vh",
    },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    }
}))



function StocksDialog(props: IStocksDialogProps) {
    const { open, handleClose, dialogMode, callback } = props
    const [t] = useTranslation('Warehouse')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isEditMode, setIsEditMode] = useState(false)
    const stocksRef = useRef();



    const params = useQuery();
    const [selectedLanguage] = useState(params.get("lang") ?? "en");
    const handleOnCancelClick = useCallback(() => {
        setIsEditMode(false)
    }, [])
    const handleSaveClick = () => {
        if (stocksRef.current) {
            (stocksRef.current as any).handleSave((call_data: any) => {
                handleOnCancelClick();
                handleClose()
                if (dialogMode == 'ctr_add') {
                    callback(call_data)
                }
            });
        }
    }
    return (
        <>

            {/* <WarningDialog onProceedClick={handleOnProceedDelete} open={isWarningDialogOpen} handleClose={handleOnWarningDialogClose} message={t('warning_about_to_delete_company')} /> */}
            <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('product_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>

                    <StocksDetails ref={stocksRef} editMode={isEditMode} onEditDone={handleOnCancelClick} showMode={open} />

                </DialogContent>


                <DialogActions>


                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        <Grid item md={12}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >

                                {
                                    (dialogMode == 'add' || dialogMode == 'ctr_add')
                                        ? <Grid item md={3}>
                                            <Button startIcon={<EditIcon />} fullWidth onClick={() => handleSaveClick()} className={classes.editBtn} variant="contained">
                                                {t('save')}
                                            </Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>



                    </Grid>
                </DialogActions>
            </Dialog>
        </>

    )
}


export default StocksDialog