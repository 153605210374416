import { GridCellParams, GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { Button } from "@material-ui/core";
import { IAssociateGroupInfo } from "../../../../../../Application/DTOs/AssociateGroupDto/AssociateGroupDto.types";




export const getAssociateGroupManagementColumns = (t:TFunction, onDeleteFunction: (associateGroupId:number) => void):GridColumns => {

    return [
        {
            field: 'name',
            headerName: t('group_name'),
            width: 300
        },
        {
            field: 'action',
            headerName: t('actions'),
            width: 200,
            disableClickEventBubbling: true,
            renderCell: (params: GridCellParams) => {
                const associateGroupInfo: IAssociateGroupInfo = params.row as IAssociateGroupInfo

                return (<Button onClick={() => onDeleteFunction(associateGroupInfo.id)} color="secondary" variant="contained">{t('delete')}</Button>)
            }
        }
    ]
}