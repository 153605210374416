import { Grid, makeStyles, Box, styled, Paper, Typography, Breadcrumbs, Link, MenuItem, InputLabel, FormControl, Select } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from 'react-i18next'
import JobDataPaper from '../../Common/JobDataPaper/JobDataPaper'
import GoogleMap from 'google-map-react'
import AggregatedJobsLineChart from '../../Common/AggregatedJobsLineChart/AggregatedJobsLineChart'
import DonutGraph from '../../Common/DonutGraph/DonutGraph'
import DriverRatingTable from '../../Common/DriverRatingTable/DriverRatingTable'
import CompanyRankingTable from '../../Common/CompanyRankingTable/CompanyRankingTable'
import AggregatedJobTable from '../../Common/AggregatedJobTable/AggregateJobTable'
import DailyMovementTable from '../../Common/DailyJobsMovementTable/DailyJobsMovementTable'
import { Job } from '../../../Api/Job/Job'
import { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '../../../Redux/Loader/actions'
import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants'
import { toast } from 'react-toastify'
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types'
import { IJobsWithTypes } from '../Jobs/Jobs.types'
import { AppState } from '../../../Redux'
import PieChart from '../../Common/PieChart/PieChart'
import WeeklyCount from './WeeklyCount/WeeklyCount'

const BOX_HEIGHT = 45

const useStyles = makeStyles(() => ({
    mapBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    formControl: {
        minWidth: '100%',
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    graphBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    inbox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "center"
    },
    label: {
        margin: '0px',
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        // color: ' rgb(108, 115, 127)'
    },
    count: {
        fontWeight: 'bold',
        margin: '30px 0',
        fontSize: '2rem',
        lineHeight: '1.2'
    },
    breadCrumbs: {
        float: 'right'
    },
    active: {
        fontWeight: 'bold'
    }

}))
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    color: theme.palette.text.secondary,
}));
const jobApi = new Job()

const {
    COMPLETED,
    POSTED,
    IN_PROGRESS,
    CREATED
} = JOB_STATUS

const {
    ADMIN_USER,
    SUPER_ADMIN
} = USER_ROLES

function WeeklyDashboard({ activeData, companyId }) {
    const [t] = useTranslation('Dashboard')

    const dispatch = useDispatch()
    const classes = useStyles()
    const [mapJobs, setMapJobs] = useState<IJobInfo[]>([])
    const user = useSelector((state: AppState) => state.user)
    const isAdminUser = user.response?.type === ADMIN_USER
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user.response?.type)

    let company: any = useSelector((state: AppState) => state.company);
    // const [companyId, setCompanyId] = useState<any>('');

    const [containerJobsBefore, setContainerJobsBefore] = useState<any>(0)
    const [palletJobsBefore, setPalletJobsBefore] = useState<any>(0)
    const [beforeTotal, setBeforeTotal] = useState<any>(0)
    const [afterPercentage, setAfterPercentage] = useState<any>(0)


    const [palletJobsAfter, setPalletJobsAfter] = useState<any>(0)
    const [containerJobsAfter, setContainerJobsAfter] = useState<any>(0)
    const [afterTotal, setAfterTotal] = useState<any>(0)
    const [beforePercentage, setBeforePercentage] = useState<any>(0)



    // const [activeData, setActiveData] = useState<any>('week')



    const getData = (dataType: string) => {
        dispatch(setLoader(true))
        jobApi.getWeeklyCompleteReports(dataType, companyId)
            .then((response) => {

                const dataset = response.data
                const totalBefore = dataset.before.pallet_jobs || 0 + dataset.before.container_jobs || 0
                const totalAfter = dataset.after.pallet_jobs || 0 + dataset.after.container_jobs || 0


                setPalletJobsBefore(dataset.before.pallet_jobs)
                setContainerJobsBefore(dataset.before.container_jobs)
                setBeforeTotal(totalBefore)

                setBeforePercentage(dataset.before.percentageChange)

                setPalletJobsAfter(dataset.after.container_jobs)
                setContainerJobsAfter(dataset.after.pallet_jobs)

                setAfterTotal(totalAfter)

                const afterJobPercentage = (totalAfter != 0 || totalAfter != 0) ? ((totalAfter - totalBefore) / totalBefore) * 100 : 0
                setAfterPercentage(afterJobPercentage)

                dispatch(setLoader(false))
            })
            .catch((err: any) => {
                dispatch(setLoader(false))
                // console.log('err==========', err)

                toast.error(t('error_getting_counts'))
            })
    }

    let companyListOptions = useMemo(() =>
        company.list.map((company, i) => (
            <MenuItem key={i} value={company.id}>
                {company.companyName}
            </MenuItem>
        )),
        [company.list]
    );

    useEffect(() => {
        getData(activeData)
    }, [dispatch, t, activeData, companyId])



    // const handleAlignment = (
    //     event: React.MouseEvent<HTMLElement>,
    //     newAlignment: string | null,
    // ) => {
    //     setActiveData(newAlignment);
    // };

    return (
        <>
            <Grid
                container
                spacing={3}
            >

                {/* <Grid item md={12}>
                    <Grid container direction='row'>
                        <Grid item md={8}>
                            <ToggleButtonGroup
                                value={activeData}
                                exclusive
                                onChange={handleAlignment}
                                style={{ height: '5vh' }}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="week" aria-label="left aligned">
                                    <p>Week</p>
                                </ToggleButton>
                                <ToggleButton value="month" aria-label="centered">
                                    <p>Month</p>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item md={4}>

                            {(isUserAdmin) ?
                                <Grid item >

                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel}>
                                            {t('select_company') + '*'}
                                        </InputLabel>
                                        <Select
                                            value={companyId}
                                            onChange={(e) => {
                                                const companyId = e.target.value;
                                                setCompanyId(companyId as string);
                                            }}
                                        >
                                            {companyListOptions}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item md={12}>

                    <Grid container spacing={5}>

                        <Grid container item spacing={3}>
                            <Grid item xs>
                                <Grid className={classes.inbox}>
                                    <WeeklyCount
                                        label={`Jobs Completed Last ${activeData}`}
                                        pallet_jobs={palletJobsBefore}
                                        container_jobs={containerJobsBefore}
                                        total={beforeTotal}
                                        percentage={beforePercentage}
                                    />
                                    <WeeklyCount
                                        label={`Jobs scheduled this ${activeData}`}
                                        pallet_jobs={palletJobsAfter}
                                        container_jobs={containerJobsAfter}
                                        total={afterTotal}
                                        percentage={afterPercentage}

                                    />


                                </Grid>
                            </Grid>

                        </Grid>
                        {/* <Grid xs={12} item>
                            <Breadcrumbs className={classes.breadCrumbs} aria-label="breadcrumb">
                                <Link underline="hover" color="inherit"
                                    className={activeData == 'monthly' ? classes.active : null}
                                    onClick={(e) => {

                                        breadClick(e, 'monthly')
                                    }}
                                >
                                    Month
                                </Link>
                                <Link
                                    onClick={(e) => {
                                        breadClick(e, 'yearly')
                                    }}
                                    className={activeData == 'yearly' ? classes.active : null}

                                    underline="hover"
                                    color="inherit"
                                >
                                    Year
                                </Link>
                            </Breadcrumbs>
                        </Grid> */}
                    </Grid>
                </Grid>


            </Grid>
        </>
    )
}

export default WeeklyDashboard