import { Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IJobInfo } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";



export const getJobReportMileageColumns = (t: TFunction): GridColumns => {
    return [
        {
            field: 'reference',
            headerName: t(`Columns:reference`),
            width: 400
        },
        {
            field: 'customer_reference',
            headerName: t('Columns:customer_reference'),
            width: 200,
            renderCell: (params) => {
                const job = params.row;
                return (
                    <Typography>
                        {job.customer_reference}
                    </Typography>
                )
            }
        },
        {
            field: 'vehicle_type',
            headerName: t(`Columns:vehicle_type`),
            renderCell: (params) => <Typography>{t((params.row as IJobInfo).vehicle_type)}</Typography>,
            width: 300
        },
        {
            field: 'job_type',
            headerName: t(`Columns:job_type`),
            renderCell: (params) => <Typography>{t((params.row as IJobInfo).job_type)}</Typography>,
            width: 300
        },
        {
            field: 'total_distance',
            headerName: t(`Columns:total_distance`),
            renderCell: (params) => <Typography>{((params.row as IJobInfo).total_distance_km ?? t('unknown')) + ' ' + t('km')}</Typography>,
            width: 300
        },
    ]
}