
import {FormControlLabel, FormGroup,Switch ,Grid } from '@material-ui/core'

import { useTranslation } from 'react-i18next'
import { ISwitchProps } from './SwitchButton.types'
import { useCallback, useEffect, useState } from "react";





function SwitchButton(props: ISwitchProps) {
    const {keyValue, onChange , label,selected , readOnly} = props
  
    const [t] = useTranslation()
    const [isChecked , setIsChecked] =  useState<boolean>(false);

    const handleChange = ()=>{
        setIsChecked(!isChecked)
        onChange(keyValue)
    }
    useEffect(()=>{
        setIsChecked(selected)
    },[selected])
    return (
        <>
        <Grid> 
            <FormGroup>
                <FormControlLabel control={<Switch disabled={readOnly} checked={isChecked} onChange={(e) =>{handleChange()}}/>} label={label} />
            </FormGroup>
        </Grid>
        </>
    )
}

export default SwitchButton