import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../../routes';
import QuotesDetails from './QuotesCreation/QuotesDetails'
import QuoteImport from './QuotesUpload/QuoteUpload';


export default function Quotes() {
  const match = useRouteMatch();

  return (
    <>
      {/* /home/report/statistics */}
      <Route path={match.path + routes.home.quotes.create}>
        <QuotesDetails type='transport' mode='create' isEditMode={false} />
      </Route>
      <Route path={match.path + routes.home.quotes.upload}>
        <QuoteImport/>
      </Route>
    </>
  );
}
