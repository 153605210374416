import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { PRIMARY_COLOR, TERTIARY_COLOR, SECONDARY_COLOR } from '../../../Styles/colors';
import Tooltip from '@material-ui/core/Tooltip';
import { IThemeStyle } from './Sidebar.types';

const useStyles = makeStyles((theme) => ({
  sidebarLink: {
    paddingLeft: '0px',
    display: 'flex',
    color: (props: IThemeStyle) => props.sidebar_font_color,
    justifyContent: 'space-between',
    alignItems: 'center',
    listStyle: 'none',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: TERTIARY_COLOR + '55',
      textDecoration: 'none',
    }
  },
  sidebarLabel: {
    marginLeft: 16,
    fontSize: '12px !important',
    color: 'inherit', // Inherit color from parent
  },
  dropdownLink: {
    // height: 60,
    padding: '5px 10px',
    margin: '0 !important',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: 17,
    '&:hover': {
      cursor: 'pointer',

      textDecoration: 'none',
      backgroundColor: TERTIARY_COLOR + '55',
      // borderRadius: '20px 0 0 20px'
    },
  },
  selected: {
    background: 'linear-gradient(90deg, #CDA900 1%, transparent 1%), linear-gradient(90deg, #1FA0ED 100%, transparent 0 )',
    color: 'white',
    '&:hover': {
      background: 'linear-gradient(90deg, #CDA900 1%, transparent 1%), linear-gradient(90deg, #1FA0ED 100%, transparent 0 )',
    },
  },
  selected_non_yellow: {
    color: 'white',

  }
}));

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const SubMenu = (props: { item: any; themeStyle: IThemeStyle }) => {
  const { item, themeStyle } = props;
  const classes = useStyles(themeStyle);
  const location = useLocation();
  const [subnav, setSubnav] = useState(false);

  useEffect(() => {
    if (item.subNav) {
      const isSubnavActive = item.subNav.some((subItem) => subItem.path === location.pathname);
      setSubnav(isSubnavActive);
    }
  }, [location.pathname, item.subNav]);

  const showSubnav = (e) => {
    if (item.subNav) {
      e.preventDefault(); // Prevent default link behavior
      setSubnav(!subnav); // Toggle subnav visibility
    }
  };

  return (
    <>
      {item.subNav ? (
        <>
          <ListItem
            button
            component="div"
            onClick={showSubnav}
            className={`${classes.sidebarLink} ${location.pathname === '#' || subnav ? '' : ''}`}
            selected={location.pathname === '#' || subnav}
          >
            <ListItemText>
              <span className={`${classes.sidebarLabel} ${location.pathname === '#' || subnav ? '' : ''}`}>{item.title}</span>
            </ListItemText>
            {subnav ? item.iconOpened : item.iconClosed}
          </ListItem>
          <Collapse in={subnav} timeout="auto" unmountOnExit>
            {item.subNav.map((subItem, index) => (
              subItem.hide ? null : (
                <ListItem
                  button
                  component={RouterLink}
                  to={subItem.path}
                  key={index}
                  className={`${classes.dropdownLink} ${location.pathname === subItem.path ? classes.selected : ''}`}
                  selected={location.pathname === subItem.path}
                >
                  <ListItemText>
                    <Tooltip title={subItem.title} placement="right">
                      <span style={{ padding: '0 0 0 2rem', whiteSpace: 'nowrap' }} className={`${classes.sidebarLabel} ${location.pathname === subItem.path ? '' : ''}`}>
                        {truncateText(subItem.title, 20)}
                      </span>
                    </Tooltip>
                  </ListItemText>
                </ListItem>
              )
            ))}
          </Collapse>
        </>
      ) : (
        <ListItem
          button
          component={RouterLink}
          to={item.path}
          className={`${classes.sidebarLink} ${location.pathname === item.path ? classes.selected : ''}`}
          selected={location.pathname === item.path}
        >
          <ListItemText>
            <span className={`${classes.sidebarLabel} ${location.pathname === item.path ? classes.selected_non_yellow : ''}`}>{item.title}</span>
          </ListItemText>
        </ListItem>
      )}
    </>
  );
};

export default SubMenu;
