import {  Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import { Divider, Grid, Typography, MenuItem, FormControl, InputLabel, Select, makeStyles, Button, FormControlLabel, Checkbox ,Accordion , AccordionSummary ,AccordionDetails  } from "@material-ui/core"
import { IAccordionProps } from "./Accordion.types";
import  AccordionJobs from "./AccordionJobs";

import MultiAutocomplete from "../../../Common/MultiAutocomplete/MultiAutocomplete"

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useEffect, useState } from 'react'
import { IAutocompleteOption } from "../../../Common/types"



const toValue = (opt: IAutocompleteOption): string => opt.value

function AccordionConfig(props : IAccordionProps)  {
    const { types , country , selectedClassification , job_types_option , jobArrayChange ,existingConfig} = props



    const [selectedJobArray, setSelectedJobArray] = useState({})
    


    useEffect(()=>{
        
        jobArrayChange(selectedJobArray)


    },[selectedJobArray])
    
    const handlePerchange =  (data : any , keyData :string) => {
        setSelectedJobArray(prevState => ({
            ...prevState,
            [keyData]: data
          }));

         
    }   

  
    const [t] = useTranslation('')

 


              return <>
               {types.map((element:any)=>{
                      
                    return <Accordion style={{border: '1px solid gray'}}>
                        
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>{element.label}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid md={12} item>
                           <AccordionJobs
                            type={element.value}
                             country={country}
                             selectedClassification={selectedClassification}
                             job_types_option={job_types_option}
                             handlePerchange={handlePerchange}
                             existingConfig={existingConfig}
                           />
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                 })}
    
         </>
        
        
    
}

export default AccordionConfig