


import React, {
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef
} from 'react';
import { makeStyles, Grid, MenuItem, FormControl, InputLabel, Select, TextField, InputAdornment, Button } from '@material-ui/core'
import TextFieldsIcon from '@material-ui/icons/TextFields';

import { CREATE_JOB_ELEMENTS, TYPES_OF_PACKAGES, MOVEMENT_TYPES } from '../../../Types/Constants';
import { ITypeOfPackage } from '../../Common/types';
import { IPackagesTypes } from './Packages.types';
import { useTranslation } from 'react-i18next';
import { IPackagesTypesPayload } from '../../../../../Application/DTOs/JobsDto/JobsDto.types';
const { QUANTITY, PACKAGE_TYPE } = CREATE_JOB_ELEMENTS;
const { CRATES, PACKAGES, PALLETS, PIECES, BAG } = TYPES_OF_PACKAGES;
const { FULL_TRUCK_LOAD, LOOSE_CARGO, OVERSIZED_ITEMS } = MOVEMENT_TYPES;

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    gap: {
        marginTop: 20,
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
    },
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    gridList: {
        width: 500,
        height: 450,
    },
}));

function MultiplePackageType(props: IPackagesTypes, ref: any) {
    const classes = useStyles()
    const { readonly, movementType, packageList } = props;
    const [t] = useTranslation()


    const initial_list: ITypeOfPackage = { id: 0, quantity: 0, type: '', sku: '', description: '' };

    const [typeOfPackageList, setTypeOfPackageList] = useState<IPackagesTypesPayload[]>(packageList ?? [initial_list]);

    useEffect(() => {
        setTypeOfPackageList(packageList ?? [])
    }, [packageList])
    const handleTypeChange = (value: string, element: any) => {
        const objIndex = typeOfPackageList.findIndex((obj => obj.id == element.id));

        let tempList = typeOfPackageList
        tempList[objIndex].type = value
        setTypeOfPackageList(tempList)
    }

    const handleQuantityChange = (value: number, element: any) => {
        const objIndex = typeOfPackageList.findIndex((obj => obj.id == element.id));

        let tempList = typeOfPackageList
        tempList[objIndex].quantity = value
        setTypeOfPackageList(tempList)
    }

    const handleSkuChange = (value: string, element: any) => {
        const objIndex = typeOfPackageList.findIndex((obj => obj.id == element.id));

        let tempList = typeOfPackageList
        tempList[objIndex].sku = value
        setTypeOfPackageList(tempList)
    }
    const handleDescChange = (value: string, element: any) => {
        const objIndex = typeOfPackageList.findIndex((obj => obj.id == element.id));

        let tempList = typeOfPackageList
        tempList[objIndex].description = value
        setTypeOfPackageList(tempList)
    }
    const handleAddOnClick = () => {
        if (typeOfPackageList.length == 4) {
            alert("Only 4 items are currently supported")
        } else {
            const maxId = (typeOfPackageList.length > 1) ? Math.max(...typeOfPackageList.map(o => o.id)) : 0
            let tempList = initial_list
            tempList['id'] = maxId + 1
            setTypeOfPackageList([...typeOfPackageList, tempList])
        }
    }
    const handleSubmit = (callback?: any) => {

        if (callback) callback(typeOfPackageList)
    }
    const handleRemove = (index: any, element: any) => {
        const objIndex = typeOfPackageList.findIndex((obj => obj.id == element.id));

        let data = [...typeOfPackageList];
        data.splice(objIndex, 1);

        setTypeOfPackageList(data)
    }

    useImperativeHandle(ref, () => ({
        handlePackageChanges: (callback: any) => {
            handleSubmit(callback);
        },
    }));

    return (

        <Grid container>

            <Grid item style={{ "padding": "10px 0" }} md={12}>
                <Button color='primary' disabled={readonly} variant='contained' onClick={handleAddOnClick}>Add Package Type</Button>
            </Grid>
            <Grid item md={12}>
                {/* <form onSubmit ={handleSubmit}> */}
                {typeOfPackageList.map((element, index) => {
                    return <Grid item container style={{ "padding": "10px 0" }} key={element.id}>
                        <Grid container spacing={2}>
                            <Grid item container spacing={2} md={12} xs={12} style={{ "padding": "10px 0" }} >
                                <Grid item xs={3} md={2}>

                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel}>
                                            {t(PACKAGE_TYPE)}
                                        </InputLabel>
                                        <Select
                                            disabled={readonly}
                                            name='type'
                                            defaultValue={element.type.toLocaleLowerCase()}
                                            onChange={(e) => handleTypeChange(e.target.value as string, element)}
                                        >
                                            <MenuItem value={CRATES}>{t(CRATES)}</MenuItem>
                                            <MenuItem value={PACKAGES}>{t(PACKAGES)}</MenuItem>
                                            <MenuItem value={PALLETS}>{t(PALLETS)}</MenuItem>
                                            <MenuItem value={PIECES}>{t(PIECES)}</MenuItem>
                                            <MenuItem value={BAG}>{t(BAG)}</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} md={2}>
                                    <TextField
                                        name='quantity'
                                        disabled={readonly}
                                        type="number"
                                        onChange={(e) => handleQuantityChange(e.target.value as unknown as number, element)}
                                        defaultValue={element.quantity}

                                        fullWidth
                                        label={
                                            t(QUANTITY) +
                                            (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                                                ? '*'
                                                : '')
                                        }
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold',
                                            },
                                        }}

                                    />
                                </Grid>
                                {[LOOSE_CARGO, OVERSIZED_ITEMS].includes(movementType) && (
                                    <>
                                        <Grid item xs={3} md={4}>

                                            <TextField
                                                name='Description'
                                                disabled={readonly}
                                                onChange={(e) => handleDescChange(e.target.value, element)}
                                                defaultValue={element.description}

                                                fullWidth
                                                label={
                                                    t('item_description')
                                                }
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {
                                                        fontWeight: 'bold',
                                                    },
                                                }}

                                            />

                                        </Grid>
                                        <Grid item xs={3} md={3}>
                                            <TextField
                                                name='sku'
                                                disabled={readonly}

                                                onChange={(e) => handleSkuChange(e.target.value, element)}
                                                defaultValue={element.sku}

                                                fullWidth
                                                label={
                                                    t('sku')
                                                }
                                                variant="outlined"
                                                InputLabelProps={{
                                                    style: {
                                                        fontWeight: 'bold',
                                                    },
                                                }}

                                            />
                                        </Grid>

                                    </>)
                                }
                                <Grid style={{ "padding": "10px", alignContent: 'center' }} item xs={1}>
                                    <Button fullWidth variant='contained' disabled={readonly} color='secondary' onClick={() => handleRemove(index, element)}>Remove</Button>
                                </Grid>
                            </Grid>


                        </Grid>
                    </Grid>

                })
                }

            </Grid>
        </Grid>


    );
}

export default forwardRef(MultiplePackageType);
