import { DataGrid, GridCellParams, GridSortModel } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core';
import { PRIMARY_COLOR, TERTIARY_COLOR } from '../../../Styles/colors';
import { ILazyProps } from './LazyTable.types';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: (props: ILazyProps) => props.theme?.height || 700
  }
}));

function LazyTable(props: ILazyProps) {
  const classes = useStyles(props);
  const [t] = useTranslation();
  const { lazyFunction, lazyFilter, refresh, retainCurrent = false, pageType, onCellEditStopCallback, pageSize = 10 } = props;
  
  const [data, setData] = useState({
    loading: true,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: [5, 10, 20],
    pageSize: pageSize,
    page: 1,
    order_by: null
  });

  const updateData = (k: any, v: any) => setData((prev) => ({ ...prev, [k]: v }));

  useEffect(() => {
    updateData("loading", true);
    loadData().then(() => updateData("loading", false));
  }, [data.page, data.pageSize, lazyFilter, data.order_by, refresh]);

  const loadData = async () => {
    if (lazyFunction) {
      const dataset: any = await lazyFunction(data.pageSize * (data.page - 1), data.page, data.pageSize, lazyFilter, data.order_by);
      const casted: { jobs: any, count: any } = dataset;
      await updateData("totalRows", casted.count);
      await updateData("rows", casted.jobs);
    }
  };

  useEffect(() => {

    updateData("page", 1); // Reset page to 1
  }, [pageType]);

  const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
    updateData("order_by", sortModel.length > 0 ? sortModel[0] : null);
  }, []);

  const noRowsLabel = t('no_rows');

  return (
    <div className={classes.root}>
      <DataGrid
        onRowClick={props.onRowClick}
        columns={props.columns}
        disableSelectionOnClick
        localeText={{ noRowsLabel }}
        onCellEditCommit={(params) => { onCellEditStopCallback(params); }}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        paginationMode="server"
        loading={data.loading}
        rowCount={data.totalRows}
        rowsPerPageOptions={data.rowsPerPageOptions}
        page={data.page - 1}
        pageSize={data.pageSize}
        rows={data.rows}
        onPageChange={(rowdata: any) => { updateData("page", rowdata + 1); }}
        onPageSizeChange={(rowdata: any) => {
          updateData("page", 1);
          updateData("pageSize", rowdata);
        }}
        disableColumnMenu
      />
    </div>
  );
}

export default LazyTable;
