import { WebStorage } from "../../Utilities/WebStorage"
import axios from "axios"
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"
import { IDriverUpdatePayload } from "../../../../Application/DTOs/DriverDto/DriverDto.types"
import { IDriverFeeConfig } from "../../../../Application/DTOs/DriverFeeSettingsDto/DriverFeeSettingsDto.types"

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()

export class Driver {
    create(formData: FormData) {
        return axios.post(`${baseUrl}/driver/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    approve(userId: string | number) {
        return axios.post(`${baseUrl}/driver/approve`, {userId}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    reject(userId: string | number, reasonForRejection: string) {
        return axios.post(`${baseUrl}/driver/reject`, {userId, reasonForRejection}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getAwaitingForApproval(currentPage: number, countPerPage: number, country?: string) {
        return axios.get(`${baseUrl}/driver/get/awaiting_for_approval`, {
            params: {
                currentPage,
                countPerPage,
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getApprovedDrivers(currentPage: number, countPerPage: number, companyId?: string | number, country?: string) {
        return axios.get(`${baseUrl}/driver/get/approved`, {
            params: {
                currentPage,
                countPerPage,
                companyId,
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getAllDriverLocation(companyId?: string | number, country?: string) {
        return axios.get(`${baseUrl}/driver/get/locations`, {
            params: {
                companyId,
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    gertDriversToAssign(userType: string) {
        return axios.get(`${baseUrl}/driver/get/asignee`, {
            params: {
                userType
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getDriverFeeConfig(driverId: string) {
        return axios.get(`${baseUrl}/driver/get/fee/config`, {
            params: {
                driverId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getApprovedAndActiveDrivers(country?: string) {
        return axios.get(`${baseUrl}/driver/get/all`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getAll(country?: string) {
        return axios.get(`${baseUrl}/driver/all`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getDetails(userId: string | number) {
        return axios.get(`${baseUrl}/driver/get/details`, {
            params: {
                userId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getRelatedDrivers(companyId: string | number) {
        return axios.get(`${baseUrl}/driver/related`, {
            params: {
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateStatus(userId: string | number, status: string) {
        return axios.post(`${baseUrl}/driver/update/status`, {userId, status}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateDriverCompanyRelationship(driverId: string | number, related: boolean) {
        return axios.post(`${baseUrl}/driver/relationship`, {driverId, related}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateDriverFeeConfig(driverId: string | number, config: IDriverFeeConfig) {
        return axios.post(`${baseUrl}/driver/save/fee/config/${driverId}`, {driverFeeConfig: config}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    update(userId: string | number, payload: IDriverUpdatePayload) {
        return axios.post(`${baseUrl}/driver/update/details/${userId}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    delete(userId: string | number) {
        return axios.post(`${baseUrl}/driver/delete`, {userId}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getDriverLocation(driverId : string | number){
        return axios.get(`${baseUrl}/driver/location/${driverId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
}