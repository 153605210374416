import { Grid, Typography, Divider, makeStyles, FormControl, Select, MenuItem, InputLabel, Button } from '@material-ui/core'
import React, { useMemo, useReducer, useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IFee } from '../../../../../../Application/DTOs/DriverFeeSettingsDto/DriverFeeSettingsDto.types'
import { COMMISION_RATES_TYPE, COUNTRIES, JOB_TYPES, MOVEMENT_TYPES, USER_ROLES } from '../../../../Types/Constants'
import TextFieldSelectCombo from '../../../Common/TextFieldSelectCombo/TextFieldSelectCombo'
import { CommisionSettingsReducer } from './Commision.reducer'
import { ICommisionFeeConfig, ICommissionJobTypeFee } from './Commision.types'
import CheckIcon from '@material-ui/icons/Check';
import { useDispatch, useSelector } from 'react-redux'
import { AdminSettings } from '../../../../Api/AdminSettings/AdminSettings'
import { setLoader } from '../../../../Redux/Loader/actions'
import { toast } from 'react-toastify'
import { isEmpty } from 'lodash'
import { AppState } from '../../../../Redux'

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bolder'
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
}))

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const {
    FLAT_RATE,
    PERCENTAGE,
    M3
} = COMMISION_RATES_TYPE

const COMMISION_RATES_TYPES_OPTIONS = [
    FLAT_RATE,
    PERCENTAGE,
    M3
]

const {
    EMPTY_PARK,
    FCL,
    FULL_TRUCK_LOAD,
    LAND_FREIGHT,
    LCL,
    LOOSE_CARGO,
    OTHER,
    RAIL,
    SEA_FREIGHT,
    WHARF,
    ALL
} = MOVEMENT_TYPES

const {
    IMPORT_CONTAINER_20,
    IMPORT_CONTAINER_40,
    EXPORT_CONTAINER_20,
    EXPORT_CONTAINER_40,
    LOCAL_CONTAINER_DELIVERY_20,
    LOCAL_CONTAINER_DELIVERY_40,
    FIRST_MILE,
    LAST_MILE,
    EMPTY_CONTAINER_RETURN,
    EMPTY_CONTAINER_COLLECTION,
    CONTAINER_SHIFT,
    LOOSE_CARGO: LOOSE_CARGO_JOB_TYPE,
    OTHER: OTHER_JOB_TYPE,
    RAIL: RAIL_JOB_TYPE,
    IMPORT_FULL_CONTAINER,
    EXPORT_FULL_CONTAINER
} = JOB_TYPES

const COMISSION_JOB_TYPES = [
    IMPORT_CONTAINER_20,
    IMPORT_CONTAINER_40,
    EXPORT_CONTAINER_20,
    EXPORT_CONTAINER_40,
    LOCAL_CONTAINER_DELIVERY_20,
    LOCAL_CONTAINER_DELIVERY_40,
    FIRST_MILE,
    LAST_MILE,
    EMPTY_CONTAINER_RETURN,
    EMPTY_CONTAINER_COLLECTION,
    CONTAINER_SHIFT,
    LOOSE_CARGO_JOB_TYPE,
    OTHER_JOB_TYPE,
    RAIL_JOB_TYPE,
    IMPORT_FULL_CONTAINER,
    EXPORT_FULL_CONTAINER
]

const {
    ADMIN_USER
} = USER_ROLES

const generateInitialState = (): ICommisionFeeConfig => {
    return Object.values(MOVEMENT_TYPES).reduce<ICommisionFeeConfig>((config, movementType) => {
        config[movementType] = COMISSION_JOB_TYPES.reduce<ICommissionJobTypeFee>((companyJobTypeFee, jobType) => {
            companyJobTypeFee[jobType] = {
                amount: 0,
                type: FLAT_RATE
            }

            return companyJobTypeFee
        }, {})

        return config
    }, {})
}

const initialState = generateInitialState()

const adminSettingsApi = new AdminSettings()

function Commision() {
    const [t] = useTranslation('Commision')
    const user = useSelector((state: AppState) => state.user)
    const isUserAdmin = user.response?.type === ADMIN_USER
    const dispatch = useDispatch()
    const [country, setCountry] = useState(isUserAdmin ? user.response?.country ?? AUSTRALIA: AUSTRALIA)
    const [movementType, setMovementType] = useState(ALL)
    const classes = useStyles()
    const [commisionFeeConfig, localDispatch] = useReducer(CommisionSettingsReducer, initialState)
    

    const commissionJobTypes = useMemo(() => COMISSION_JOB_TYPES.map((JOB_TYPE, i) => {
        const feeValues: IFee = commisionFeeConfig[movementType][JOB_TYPE]

        return (
            <Grid key={i} sm={12} md={6} item>
                <TextFieldSelectCombo
                    textPlaceholder={t(JOB_TYPE)}
                    selectPlacholder={t('type')}
                    items={COMMISION_RATES_TYPES_OPTIONS}
                    selectValue={feeValues.type}
                    textValue={String(feeValues.amount)}
                    onTextChange={(e: any) => {
                        localDispatch({
                            type: 'UPDATE',
                            payload: {
                                amount: Number(e.target.value),
                                commissionType: feeValues.type,
                                jobType: JOB_TYPE,
                                movementType
                            }
                        })
                    }}
                    onSelectChange={(e: any) => {
                        localDispatch({
                            type: 'UPDATE',
                            payload: {
                                amount: feeValues.amount,
                                commissionType: e.target.value,
                                jobType: JOB_TYPE,
                                movementType
                            }
                        })
                    }}
                />
            </Grid>
        )
    }), [t, commisionFeeConfig, movementType])

    const handleUpdateClick = useCallback(
        () => {
            dispatch(setLoader(true))
            adminSettingsApi.updateCommissionConfig(country, commisionFeeConfig)
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_updating_comission_config'))
                })
                .catch((err :any) => {
                    toast.error(t('error_updating_comission_config'))
                    console.log('err', err)
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, country,commisionFeeConfig],
    )

    const getCommissionConfig = useCallback(
        (country: string) => {
            dispatch(setLoader(true))
            adminSettingsApi.getCommissionFeeConfig(country)
                .then((response) => {
                    const config: ICommisionFeeConfig = response.data
                    if (isEmpty(config)) {
                        localDispatch({ type: 'SET', payload: initialState })
                    } else {
                        localDispatch({ type: 'SET', payload: config })
                    }
                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    toast.error(t('error_getting_comission_config'))
                    console.log('err', err)
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t],
    )

    useEffect(() => {
        getCommissionConfig(country)
        
    }, [country, getCommissionConfig])

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid item>
                    <Typography>
                        {t('commissioning_settings')}
                    </Typography>
                </Grid>
               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                    >
                        {/* <Grid item md={12}>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    disabled={isUserAdmin}
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value as string)
                                    }}
                                >
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('movement_type')}</InputLabel>
                                <Select
                                    value={movementType}
                                    onChange={(e) => setMovementType(e.target.value as string)}
                                >
                                    <MenuItem value={ALL}>{t(ALL)}</MenuItem>
                                    <MenuItem value={EMPTY_PARK}>{t(EMPTY_PARK)}</MenuItem>
                                    <MenuItem value={FCL}>{t(FCL)}</MenuItem>
                                    <MenuItem value={FULL_TRUCK_LOAD}>{t(FULL_TRUCK_LOAD)}</MenuItem>
                                    <MenuItem value={LAND_FREIGHT}>{t(LAND_FREIGHT)}</MenuItem>
                                    <MenuItem value={LCL}>{t(LCL)}</MenuItem>
                                    <MenuItem value={LOOSE_CARGO}>{t(LOOSE_CARGO)}</MenuItem>
                                    <MenuItem value={OTHER}>{t(OTHER)}</MenuItem>
                                    <MenuItem value={RAIL}>{t(RAIL)}</MenuItem>
                                    <MenuItem value={SEA_FREIGHT}>{t(SEA_FREIGHT)}</MenuItem>
                                    <MenuItem value={WHARF}>{t(WHARF)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <Typography className={classes.title}>
                                {t('commission_rates')}
                            </Typography>
                        </Grid>
                        {commissionJobTypes}
                        <Grid item md={12}>
                            <Grid container spacing={2}>
                                <Grid md={3} item>
                                    <Button onClick={handleUpdateClick} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                        {t('update')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Commision