import { IJobInfo } from "../../../../Application/DTOs/JobsDto/JobsDto.types";
import { ISetJobListAction, ISetSelectedJobAction, SET_JOB_LIST, SET_SELECTED_JOB } from "./types";


export const setJobList = (jobs: IJobInfo[]): ISetJobListAction => ({
    type: SET_JOB_LIST,
    payload: jobs
})

export const setSelectedJob = (job: IJobInfo | null): ISetSelectedJobAction => ({
    type: SET_SELECTED_JOB,
    payload: job
})