


export const SET_LANGUAGE = 'SET_LANGUAGE'

export interface ISetLanguageAction {
    type: typeof SET_LANGUAGE
    payload: string
}

export type TLanguageActions = ISetLanguageAction

export interface ILanguageState {
    selectedLanguage: string
}