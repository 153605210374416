import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DataGrid, GridColumns, GridRowsProp  , GridEditCellPropsParams  } from '@material-ui/data-grid';
import { Button ,makeStyles} from '@material-ui/core';

import { useTranslation } from "react-i18next";
import { IRatesTableProps} from './RatesTable.props'


const useStyles = makeStyles(() => ({
  root: {
    height: 700,
    'text-align': 'center'
    // minWidth: 800
  },
  titleTable:{
    
  }
}))
export default function RatesTable(props : IRatesTableProps) {
  const {dataValues ,title , isAdmin , onChange} = props
  const [t] = useTranslation('RatesManagement')
  const classes = useStyles()

  const columns: GridColumns = [
    { field: 'id',  headerName: t('pallet_no'), width: 100, editable: false , flex: 1},
    { field: 'sell_rate', headerName: t('sell_rate'),width: 100, type: 'number', editable: isAdmin , flex: 1}

  ];

  if(isAdmin){
    columns.splice(2,0,{ field: 'buy_rate', headerName: t('buy_rate'),width: 100 , type: 'number', editable: isAdmin , flex: 1},)
  }

  return (
    
    <div  className={classes.root}>
      <span className={classes.titleTable}>{title}</span>
      <DataGrid
        rows={dataValues}
        columns={columns}
        onEditCellPropsChange={onChange}
       
      />
    </div>
  );
}
// function getFullName(params: GridValueSetterParams) {
//     console.log(`${params.row.name || ''}`)
//     return `${params.row.name || ''}` ;
//   }



