import React from 'react';
import { Button, Box, Tooltip } from '@material-ui/core';
import { formatDateTimeDisplay, formatDateTimeDisplayDD } from '../../../Utilities/DateTime';
import { LockOpen, Public, Share, Visibility, CheckCircle, Delete, Cancel } from '@material-ui/icons'; // Import icons as needed
import { CloudDownload } from '@material-ui/icons';
import { isEmpty, isEqual } from "lodash";
import moment from 'moment';
import { JOB_STATUS, TRIP_TYPE, USER_ROLES, COMPANY_TYPES } from "../../../Types/Constants";
import { ALL, AVAILABLE, COMPLETED_JOBS, IN_PROGRESS as IN_PROGRESS_ST, MY_BIDS, PENDING, POSTED, BIDS_TO_CONFIRM, MY_IN_PROGRESS, BIDS, PICKED_UP } from "../Jobs/Jobs.constans";

const {
    DRIVER_MANAGER,
    COMPANY,
    DRIVER,
    COMPANY_DRIVER,
    ADMIN_USER,
    SUPER_ADMIN,
    CUSTOMER,
    WAREHOUSE
} = USER_ROLES

const {
    IN_PROGRESS,
    PICKEDUP,
    DELIVERED,
    RETURNED,
    CANCELLED,
    COMPLETED,
    STORAGE,
    VIA_YARD,
    FUMIGATION,
    CREATED, OPEN, CLONED,
    ASSIGNED,
} = JOB_STATUS

const {
    ROUND_TRIP
} = TRIP_TYPE
const getColumns = (t, pageType, currentUser, callbacks) => {
    const columnCollection = {
        id: { field: 'id', headerName: 'id', sort: true, hidden: true, width: 200 },
        reference: { field: 'reference', headerName: 'Reference', sort: true, hidden: false, width: 200 },
        customer_reference: { field: 'customer_reference', headerName: 'Customer Reference', sort: false, hidden: false, width: 250 },
        pickup_address_city: { field: 'pickup_address_city', headerName: 'Collection City', hidden: false, width: 200 },
        pickup_date_time: {
            field: 'pickup_date_time', headerName: 'Pick up Date/Time', hidden: false, width: 200,
            renderCell: (rowData) => formatDateTimeDisplay(rowData.pickup_date_time)
        },
        delivery_address_city: { field: 'delivery_address_city', headerName: 'Delivery City', hidden: false, width: 200 },
        delivery_date_time: {
            field: 'delivery_date_time', headerName: 'Delivery Date/Time', hidden: false, width: 250, sort: true,
            renderCell: (rowData) => formatDateTimeDisplay(rowData.delivery_date_time)
        },
        job_type_code: {
            field: 'job_type_code', headerName: 'Job Type', hidden: false, width: 150,
            renderCell: (rowData) => t(rowData.job_type_code)
        },
        status: {
            field: 'status', headerName: 'Status', hidden: false, width: 150,
            renderCell: (rowData) => t(rowData.status)
        },
        customer: {
            field: 'company_name', headerName: 'Customer', hidden: false, width: 200,
        },
        posting_company: {
            field: 'company_name', headerName: 'Posting Company', hidden: ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? '') || COMPLETED_JOBS != pageType
            , width: 200,
        },
        transport_company: {
            field: 'assigned_user_company', headerName: 'Transport Company', hidden: ![IN_PROGRESS, DELIVERED, COMPLETED, ALL, COMPLETED_JOBS].includes(pageType) || ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''),
            width: 250,
        },
        movement_type_code: {
            field: 'movement_type_code', headerName: 'Movement Type', hidden: false, width: 200,
            renderCell: (rowData) => t(rowData.movement_type_code)

        },
        quantity: {
            field: 'quantity', headerName: 'Quantity', hidden: false, width: 50,
        },
        driver: {
            field: 'assigned_user_first_name', headerName: 'Driver', hidden: false, width: 100,
        },
        date_completed: {
            field: 'date_completed', headerName: 'Date Completed', hidden: false, width: 200, sort: true,
            renderCell: (rowData) => formatDateTimeDisplayDD(rowData.job_histories[0]['createdAt'])
        },
        job_wait_time_charge: {
            field: 'job_wait_time_charge', headerName: 'Wait Time Charge', hidden: false, width: 150,
            renderCell: (rowData) => rowData.job_wait_time_charge || 0

        },
        actions: {
            field: 'actions',
            headerName: 'Actions',
            width: 300,
            hidden: determineActions(pageType).length === 0, // Hide if no actions
            renderCell: (rowData) => {
                return (
                    <Box sx={{ display: 'flex', gridGap: '10px' }}>
                        {determineActions(pageType).map((actionKey, index) => (
                            <Tooltip key={index} title={actionCollection[actionKey].tooltip || ''}>
                                <Button
                                    key={index}
                                    disabled={shouldDisableAction(actionKey, rowData, currentUser)}
                                    style={{ display: determineButtonVisibility(actionKey, currentUser, pageType) }}
                                    fullWidth
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        actionCollection[actionKey].onClick(event, rowData, callbacks, currentUser, t);
                                    }}
                                    variant={actionCollection[actionKey].icon ? 'text' : 'contained'}
                                    color={actionCollection[actionKey].color}
                                >
                                    {actionCollection[actionKey].icon || actionCollection[actionKey].label(rowData, t, currentUser)}
                                </Button>
                            </Tooltip>
                        ))}
                    </Box>
                );
            },
        },
    };

    const availableColumns = determineColumns(pageType);

    return availableColumns.map((key) => columnCollection[key]);
};

const determineColumns = (pageType) => {
    switch (pageType) {
        case 'pending':
            return ['reference', 'customer_reference', 'pickup_address_city', 'pickup_date_time', 'delivery_address_city', 'delivery_date_time', 'job_type_code', 'quantity', 'status', 'actions'];
        case 'all':
            return ['reference', 'customer_reference', 'transport_company', 'pickup_address_city', 'delivery_address_city', 'job_type_code', 'customer', 'driver', 'job_wait_time_charge', 'status', 'actions'];
        case 'in_progress':
            return ['reference', 'customer_reference', 'transport_company', 'pickup_address_city', 'pickup_date_time', 'delivery_address_city', 'job_type_code', 'customer', 'driver', 'job_wait_time_charge', 'status', 'actions'];
        case 'completed_jobs':
            return ['date_completed', 'reference', 'customer_reference', 'posting_company', 'transport_company', 'pickup_address_city', 'delivery_address_city', 'movement_type_code', 'quantity', 'actions'];
        case 'my_in_progress':
            return ['reference', 'customer_reference', 'pickup_address_city', 'delivery_address_city','delivery_date_time', 'job_type_code', 'job_wait_time_charge', 'status', 'actions'];
        default:
            return ['reference', 'customer_reference', 'pickup_address_city', 'pickup_date_time', 'delivery_address_city', 'delivery_date_time', 'job_type_code'];

    }
};
const determineButtonVisibility = (buttonType, currentUser, pageType) => {
    switch (buttonType) {
        case 'onConnoteClick':
            if ([COMPLETED_JOBS].includes(pageType) || ![IN_PROGRESS_ST].includes(pageType) && currentUser.response?.company.companyType == 'transport_company') {
                return 'none'
            } else {
                return 'inline-block'
            }
        default:
            return 'inline-block'
    }
}
const determineActions = (pageType) => {
    switch (pageType) {
        case 'pending':
            return ['makePublic', 'onConnoteClick', 'onCancelJob']; // Adjust actions based on your requirements
        case 'all':
            return ['onCancelJob'];
        case 'in_progress':
            return ['onHandleJobMovement', 'onConnoteClick'];
        case 'my_in_progress':
            return ['onHandleJobMovement', 'onConnoteClick', 'onCancelJob'];
        case 'completed_jobs':
            return ['onDownloadPod'];
        default:
            return [];
    }
};

const actionCollection = {
    makePublic: {
        label: 'Make Public',
        tooltip: 'Make Public',
        onClick: (event, rowData, callbacks, currentUser, t?) => {
            event.stopPropagation();
            callbacks.makePublic(rowData);
        },
        color: 'primary',
        icon: <Share />
    },
    delete: {
        label: 'Delete',
        tooltip: 'Delete',
        onClick: (event, rowData, callbacks, currentUser, t?) => {
            event.stopPropagation();
            callbacks.onDelete(rowData);
        },
        color: 'secondary',
        icon: <Delete />
    },
    onCancelJob: {
        label: 'Cancel Job',
        tooltip: 'Cancel Job',
        onClick: (event, rowData, callbacks, currentUser, t?) => {
            event.stopPropagation();
            callbacks.onCancelJob(rowData);
        },
        color: 'secondary',
        icon: <Cancel />
    },
    onConnoteClick: {
        label: (rowData, t, currentUser) => 'Attach',
        tooltip: 'Attach Label/Connote',
        onClick: (event, rowData, callbacks, currentUser, t?) => {
            event.stopPropagation();
            callbacks.onConnoteClick(rowData);
        },
        color: 'primary',
    },
    onDownloadPod: {
        label: (rowData, t, currentUser) => 'Download POD',
        tooltip: 'Download POD',
        onClick: (event, rowData, callbacks, currentUser, t?) => {
            event.stopPropagation();
            callbacks.onDownloadPod(rowData);
        },
        color: 'primary',
        icon: <CloudDownload />
    },
    onHandleJobMovement: {
        label: (rowData, t, currentUser) => {
            switch (rowData.status) {
                case IN_PROGRESS:
                case ASSIGNED:
                    if (!isEqual(rowData?.company_id, currentUser.response?.company?.id)) {
                        return t('set_pick_up_date')
                    } else {
                        return t('awaiting_pickup')
                    }
                case PICKEDUP:
                    if (!isEqual(rowData.company_id, currentUser.response?.company?.id)) {
                        if (!!rowData.additional_step_status &&
                            rowData.status === PICKEDUP &&
                            (!rowData.additional_step_end_date || moment().isSameOrBefore(rowData.additional_step_end_date))) {
                            return t(rowData.additional_step_status)

                        } else {
                            return t('deliver')
                        }
                    }
                    break
                case FUMIGATION:
                case PICKEDUP:
                case PICKEDUP:
                case VIA_YARD:
                case STORAGE:
                    if (!isEqual(rowData?.company_id, currentUser.response?.company?.id)) {

                        return t('set_delivery_date')
                    } else {
                        return t('picked_up')
                    }
                    break;
                case DELIVERED:
                    if (!isEqual(rowData?.company_id, currentUser.response?.company?.id)) {
                        if (rowData.trip_type === ROUND_TRIP) {
                            return t('set_return_date')
                        } else {
                            return t('complete_job')
                        }
                    } else {
                        return t('delivered')
                    }

                case RETURNED:
                    if (!isEqual(rowData?.company?.id, 1)) {
                        return t('complete_job')
                    } else {
                        return t('returned')
                    }
                    break;
            }
        },
        tooltip: 'Cancel Job',
        onClick: (event, rowData, callbacks, currentUser, t?) => {
            event.stopPropagation();

            // currentUser.response?.company?.id
            if (!isEqual(rowData.company_id, currentUser.response?.company?.id)) {
                if (!!rowData.additional_step_status &&
                    rowData.status === PICKEDUP &&
                    (!rowData.additional_step_end_date || moment().isSameOrBefore(rowData.additional_step_end_date))) {
                    callbacks.onAdditionalStep(rowData)
                } else {
                    if (rowData.status == DELIVERED || rowData.status == RETURNED) {
                        if (rowData.trip_type === ROUND_TRIP) {
                            callbacks.onSetDate(rowData)
                        } else {
                            callbacks.onMarkAsComplete(rowData)
                        }
                    } else {
                        callbacks.onSetDate(rowData)
                    }
                }
            }


        },
        color: 'primary'

    }
    // Add more button configurations as needed
};

const shouldDisableAction = (actionKey, rowData, currentUser) => {
    switch (actionKey) {
        case 'onCancelJob':
            if ([CANCELLED, PICKEDUP, DELIVERED, RETURNED, COMPLETED, STORAGE, VIA_YARD, FUMIGATION].includes(rowData.status))
                return true
            break
        case 'onHandleJobMovement':
            if (!isEqual(rowData?.company_id, currentUser.response?.company?.id)) {
                return false
            } else {
                return true
            }
        default:
            return false
    }
};

export { getColumns };
