import React, { useState, useEffect, useCallback } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, CircularProgress, Typography } from '@material-ui/core';
import { Job } from '../../../Api/Job/Job';
import { useTranslation } from 'react-i18next';
import { TRIP_TYPE } from '../../../Types/Constants';

interface DefaultValues {
    movementType: string;
    jobType: string;
    vehicleType: string;
    tripType: string;
    freeText: string;
    sku: string;
    itemDesc: string;
}

interface TransportSelectorProps {
    defaultValues: DefaultValues;
    onChange: (values: DefaultValues) => void;
    disabled: boolean;
}

const jobApi = new Job();

const TransportSelector: React.FC<TransportSelectorProps> = ({ defaultValues, onChange, disabled }) => {
    const [selectedMovementType, setSelectedMovementType] = useState<string>(defaultValues.movementType);
    const [selectedJobType, setSelectedJobType] = useState<string>(defaultValues.jobType);
    const [selectedVehicleType, setSelectedVehicleType] = useState<string>(defaultValues.vehicleType);
    const [selectedTripType, setSelectedTripType] = useState<string>(defaultValues.tripType);
    const [freeText, setFreeText] = useState<string>(defaultValues.freeText);
    const [sku, setSKU] = useState<string>(defaultValues.sku);
    const [itemDesc, setItemDesc] = useState<string>(defaultValues.itemDesc);

    const [dataSet, setDataset] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation();
    const { ONE_WAY, RETURN_TRIP, MULTI_STOP } = TRIP_TYPE;

    // Fetch data effect
    useEffect(() => {
        const fetchData = async () => {
            const cachedOptions = localStorage.getItem('transOptions');
            if (cachedOptions) {
                setDataset(JSON.parse(cachedOptions));
                setLoading(false);
            } else {
                try {
                    const response = await jobApi.getTransportMapping();
                    setDataset(response.data);
                    localStorage.setItem('transOptions', JSON.stringify(response.data));
                } catch (err) {
                    console.error('Failed to fetch transport mapping:', err);
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, []);

    // Set default values effect
    useEffect(() => {
        setSelectedMovementType(defaultValues.movementType);
        setSelectedJobType(defaultValues.jobType);
        setSelectedVehicleType(defaultValues.vehicleType);
        setSelectedTripType(defaultValues.tripType);
        setFreeText(defaultValues.freeText);
        setSKU(defaultValues.sku);
        setItemDesc(defaultValues.itemDesc);
    }, [defaultValues]);

    // Update effect with debounce to limit `onChange` calls
    const debouncedOnChange = useCallback(() => {
        onChange({
            movementType: selectedMovementType,
            jobType: selectedJobType,
            vehicleType: selectedVehicleType,
            tripType: selectedTripType,
            freeText,
            sku,
            itemDesc,
        });
    }, [selectedMovementType, selectedJobType, selectedVehicleType, selectedTripType, freeText, sku, itemDesc, onChange]);

    useEffect(() => {
        const handler = setTimeout(() => {
            debouncedOnChange();
        }, 300); // Adjust debounce delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedOnChange]);

    const handleMovementTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newMovementType = e.target.value as string;
        if (freeText || sku || itemDesc) {
            if (window.confirm(t('change_movement_type_prompt'))) {
                setSelectedMovementType(newMovementType);
                setSelectedJobType('');
                setSelectedVehicleType('');
                setFreeText('');
                setSKU('');
                setItemDesc('');
            }
        } else {
            setSelectedMovementType(newMovementType);
        }
    };

    const handleJobTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const newJobType = e.target.value as string;
        if (freeText || sku || itemDesc) {
            if (window.confirm(t('change_job_type_prompt'))) {
                setSelectedJobType(newJobType);
                setFreeText('');
                setSKU('');
                setItemDesc('');
            }
        } else {
            setSelectedJobType(newJobType);
        }
    };

    const handleVehicleTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedVehicleType(e.target.value as string);
    };

    const handleTripTypeChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedTripType(e.target.value as string);
    };

    const handleFreeTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFreeText(e.target.value);
    };

    const selectedMovementData = dataSet.find(movement => movement.movementType.code === selectedMovementType) ?? null;
    const jobTypes = selectedMovementData ? selectedMovementData.jobTypes : [];
    const vehicleTypes = selectedMovementData ? selectedMovementData.vehicleTypes : [];

    const showFreeTextInput = selectedJobType === 'other';
    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('movement_type')}</InputLabel>
                    <Select
                        value={selectedMovementType}
                        onChange={handleMovementTypeChange}
                        label="Select Movement Type"
                        disabled={disabled}
                    >
                        <MenuItem value="">
                            <em>Select Movement Type</em>
                        </MenuItem>
                        {dataSet.map(movement => (
                            <MenuItem key={movement.movementType.id} value={movement.movementType.code}>
                                {t(movement.movementType.code)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{t('trip_type')}</InputLabel>
                    <Select
                        value={selectedTripType}
                        onChange={handleTripTypeChange}
                        label="Select Trip Type"
                        disabled={disabled}
                    >
                        <MenuItem value="">
                            <em>Select Trip Type</em>
                        </MenuItem>
                        <MenuItem value={ONE_WAY}>{t(ONE_WAY)}</MenuItem>
                        <MenuItem value={RETURN_TRIP}>{t(RETURN_TRIP)}</MenuItem>
                        <MenuItem value={MULTI_STOP}>{t(MULTI_STOP)}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth variant="outlined" disabled={!selectedMovementType}>
                    <InputLabel>{t('job_type')}</InputLabel>
                    <Select
                        value={selectedJobType}
                        onChange={handleJobTypeChange}
                        label="Select Job Type"
                        disabled={disabled}
                    >
                        <MenuItem value="">
                            <em>Select Job Type</em>
                        </MenuItem>
                        {jobTypes.map(job => (
                            <MenuItem key={job.id} value={job.code}>
                                {t(job.code)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
                <FormControl fullWidth variant="outlined" disabled={!selectedMovementType}>
                    <InputLabel>{t('vehicle_type')}</InputLabel>
                    <Select
                        value={selectedVehicleType}
                        onChange={handleVehicleTypeChange}
                        label="Select Vehicle Type"
                        disabled={disabled}
                    >
                        <MenuItem value="">
                            <em>Select Vehicle Type</em>
                        </MenuItem>
                        {vehicleTypes.map(vehicle => (
                            <MenuItem key={vehicle.id} value={vehicle.code}>
                                {t(vehicle.code)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {showFreeTextInput && (
                <Grid item md={12}>
                    <TextField
                        fullWidth
                        label={t('free_text')}
                        value={freeText}
                        onChange={handleFreeTextChange}
                        variant="outlined"
                        disabled={disabled}
                    />
                </Grid>
            )}
        </Grid>
    );
};

export default TransportSelector;