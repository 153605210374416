import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
};

const useStyles = makeStyles((theme) => ({
    mapContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    legendContainer: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        zIndex: theme.zIndex.tooltip,
    },
}));

const center = {
    lat: -25.2744,
    lng: 133.7751,
};

const options = {
    disableDefaultUI: true,
    zoomControl: true,
    styles: [
        {
            featureType: "all",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#e6e6e6" }],
        },
        {
            featureType: "water",
            elementType: "geometry.fill",
            stylers: [{ color: "#a0d1ea" }],
        },
        {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ visibility: "on" }],
        },
        {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
        },
        {
            featureType: "points",
            elementType: "labels",
            stylers: [{ visibility: "on" }],
        },
    ],
};

const GoogleMaps = ({ mapData }) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });
    const classes = useStyles();
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [directions, setDirections] = useState([]);
    const [t] = useTranslation();
    const getMarkerIcon = (type) => {
        let color = '#000000'; // Default color
        if (type === 'pickup') {
            color = '#4CAF50'; // Green for pickup
        } else if (type === 'delivery') {
            color = '#800080'; // Purple for delivery
        }
        return {
            path: "M6,0C3.2385864,0,1,2.2385864,1,5s2.5,5,5,7c2.5-2,5-4.2385864,5-7S8.7614136,0,6,0z M6,7C4.8954468,7,4,6.1045532,4,5s0.8954468-2,2-2s2,0.8954468,2,2S7.1045532,7,6,7z",
            fillColor: color,
            fillOpacity: 1,
            strokeWeight: 0,
            scale: 2,
            anchor: new window.google.maps.Point(6, 15),
        };
    };

    const clusterStyles = [
        {
            url: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png',
            height: 50,
            width: 50,
            textColor: '#FFFFFF',
            textSize: 14,
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontFamily: 'Arial, sans-serif',
            backgroundPosition: 'center center'
        }
    ];

    const handleMarkerClick = useCallback((marker) => {
        setSelectedMarker(marker);
    }, []);

    const handleMarkerMouseOver = useCallback((marker) => {
        setHoveredMarker(marker);
    }, []);

    const handleMarkerMouseOut = useCallback(() => {
        setHoveredMarker(null);
    }, []);

    useEffect(() => {
        const loadDirections = () => {
            const directionsPromises = mapData.map((marker) => {
                const origin = {
                    lat: parseFloat(marker.pickup_address_latitude),
                    lng: parseFloat(marker.pickup_address_longitude),
                };
                const destination = {
                    lat: parseFloat(marker.delivery_address_latitude),
                    lng: parseFloat(marker.delivery_address_longitude),
                };

                return new Promise((resolve, reject) => {
                    const directionsService = new window.google.maps.DirectionsService();
                    directionsService.route(
                        {
                            origin,
                            destination,
                            travelMode: window.google.maps.TravelMode.DRIVING,
                        },
                        (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                                resolve(result);
                            } else {
                                reject(status);
                            }
                        }
                    );
                });
            });

            Promise.all(directionsPromises)
                .then((results) => setDirections(results))
                .catch((error) => console.error('Directions request failed', error));
        };
        if (isLoaded) {
            loadDirections();
        }
    }, [isLoaded, mapData]);

    // Separate pickup and delivery markers
    const pickupMarkers = mapData.map((marker, index) => ({
        reference: marker.reference,
        position: {
            lat: parseFloat(marker.pickup_address_latitude),
            lng: parseFloat(marker.pickup_address_longitude),
        },
        key: `pickup-${index}`,
        icon: getMarkerIcon('pickup'),
    }));

    const deliveryMarkers = mapData.map((marker, index) => ({
        reference: marker.reference,
        position: {
            lat: parseFloat(marker.delivery_address_latitude),
            lng: parseFloat(marker.delivery_address_longitude),
        },
        key: `delivery-${index}`,
        icon: getMarkerIcon('delivery'),
    }));

    return isLoaded ? (
        <Grid container className={classes.mapContainer}>
            <GoogleMap
                mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '16px' }}
                center={center}
                zoom={4}
                options={options}
            >
                <MarkerClusterer options={{ styles: clusterStyles }}>
                    {(clusterer) =>
                        pickupMarkers.map((marker) => (
                            <Marker
                                position={marker.position}
                                key={marker.key}
                                icon={marker.icon}
                                clusterer={clusterer}
                                onClick={(event: any) => handleMarkerClick(marker)}
                                onMouseOver={(event: any) => handleMarkerMouseOver(marker)}
                                onMouseOut={handleMarkerMouseOut}
                            >
                                {hoveredMarker?.key === marker.key && (
                                    <InfoWindow position={marker.position} onCloseClick={handleMarkerMouseOut}>
                                        <div>
                                            <Typography variant="body2">Reference: {marker.reference}</Typography>
                                            {/* <Typography variant="body2">Status: {t(marker.status)}</Typography> */}
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker>
                        ))
                    }
                </MarkerClusterer>

                <MarkerClusterer options={{ styles: clusterStyles }}>
                    {(clusterer) =>
                        deliveryMarkers.map((marker) => (
                            <Marker
                                position={marker.position}
                                key={marker.key}
                                icon={marker.icon}
                                clusterer={clusterer}
                                onClick={() => handleMarkerClick(marker)}
                                onMouseOver={(event: any) => handleMarkerMouseOver(marker)}
                                onMouseOut={handleMarkerMouseOut}
                            >
                                {hoveredMarker?.key === marker.key && (
                                    <InfoWindow position={marker.position} onCloseClick={handleMarkerMouseOut}>
                                        <div>
                                            <Typography variant="body2">Reference: {marker.reference}</Typography>
                                            {/* <Typography variant="body2">Status: {t(marker.status)}</Typography> */}
                                        </div>
                                    </InfoWindow>
                                )}
                            </Marker>
                        ))
                    }
                </MarkerClusterer>

                {directions.map((direction, index) => (
                    <DirectionsRenderer key={index} directions={direction} options={{ suppressMarkers: true }} />
                ))}

                {/* hide other map button that are not supported by disableDefaultUI  */}
                <style>
                    {`
                    .gm-style-cc { 
                        display: none !important; 
                    }
                    .gmnoprint a, .gmnoprint span, .gm-bundled-control .gmnoprint {
                        display: none !important;
                    }
                `}
                </style>
            </GoogleMap>
        </Grid>
    ) : <div>Loading...</div>;
};

export default GoogleMaps;
