import {Typography} from '@material-ui/core';
import {GridCellParams, GridColumns} from '@material-ui/data-grid';
import {TFunction} from 'i18next';
import {IJobInfo} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import {JOB_REPORT_COLUMNS} from './JobReportCSV.columns';
import {
  calculateGST,
  computationWaitingTimeCharge,
} from '../../../../Utilities/Computation';
import {IUserState} from '../../../../Redux/User/type';
import {COMPANY_TYPES, USER_ROLES} from '../../../../Types/Constants';

export const getJobReportColumns = (
  t: TFunction,
  user: IUserState
): GridColumns => {
  const {company, type} = user?.response || {
    company: undefined,
    type: undefined,
  };

  let isTransportCompany = false;

  if (
    typeof company !== 'undefined' &&
    company.companyType === COMPANY_TYPES.TRANSPORT_COMPANY
  ) {
    isTransportCompany = true;
  }

  const adminRoles = [
    USER_ROLES.ADMIN,
    USER_ROLES.ADMIN_USER,
    USER_ROLES.SUPER_ADMIN,
  ];
  const isAdmin = typeof type !== 'undefined' && adminRoles.includes(type);
  const isAdminTransport = isAdmin || isTransportCompany;
  const isAdminNonTransport = isAdmin || !isTransportCompany;

  return [
    {
      field: 'reference',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.REFERENCE}`),
      width: 400,
    },
    {
      field: 'agreed_price',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.AGREED_PRICE}`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;

        const price: string = String(jobInfo.job_bids?.[0]?.amount ?? '');
        const marginPrice: string = String(jobInfo.margin_price || '0');
        const agreed_price: number = Number(price) + Number(marginPrice);

        return <Typography>{agreed_price}</Typography>;
      },
    },
   

    //   field: 'fee',
    //   headerName: t(`Columns:${JOB_REPORT_COLUMNS.FEE}`),
    //   width: 150,
    //   // hide: user.response?.type !== ADMIN_USER && user.response?.type !== SUPER_ADMIN,
    //   renderCell: (params) => {
    //     const jobInfo: IJobInfo = params.row as IJobInfo;

    //     const fee: string = String(
    //       jobInfo.job_bids?.[0]?.user?.company?.waitTimeCharge ??
    //         jobInfo.job_bids?.[0]?.user?.driver?.wait_time_charge ??
    //         ''
    //     );

    //     return <Typography>{fee}</Typography>;
    //   },
    // },
    {
      field: 'driver_fee',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.DRIVER_FEE}`),
      width: 150,
      // hide: user.response?.type !== ADMIN_USER && user.response?.type !== SUPER_ADMIN,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;

          const fee: string = String(jobInfo.job_bids?.[0]?.user?.driver  ?  jobInfo.job_bids?.[0]?.waitTimeCharge : "");
    

        return <Typography>{fee}</Typography>;
      },
    },
    {
      field: 'company_fee',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.COMPANY_FEE}`),
      width: 150,
      // hide: user.response?.type !== ADMIN_USER && user.response?.type !== SUPER_ADMIN,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        const fee: string = String(jobInfo.assignedCompanyFee ||null);
        return <Typography>{fee}</Typography>;
      },
    },
    {
      field: 'job_wait_time',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.JOB_WAIT_TIME}`),
      width: 150,
    },
    {
      field: 'job_wait_time_charge',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.JOB_WAIT_TIME_CHARGE}`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;

          const total_fee: string = String(( jobInfo.job_bids?.[0]?.waitTimeCharge || 0) * ( jobInfo.job_bids?.[0]?.waitTime || 0));
    

        return <Typography>{total_fee}</Typography>;
      },
    },
    {
      field: 'gst',
      headerName: t('Columns:GST'),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        const price: string = String(jobInfo.job_bids?.[0]?.amount ?? '');
        const waitTimeCharge: number = Number(
          jobInfo.company?.waitTimeCharge || 0
        );
        const actualWaitTime: number = Number(jobInfo.job_wait_time || '0');
        const allowWaitingTime: number = jobInfo.job_bids?.[0]?.waitTime ?? 0;
        const marginPrice: string = String(jobInfo.margin_price || '0');

        let totalWaitTimeCharge = computationWaitingTimeCharge(
          actualWaitTime,
          allowWaitingTime,
          waitTimeCharge
        );

        let gst = calculateGST(
          Number(price) + Number(marginPrice),
          totalWaitTimeCharge
        );

        return <Typography>{gst}</Typography>;
      },
    },
    {
      field: 'additional_charges_1',
      headerName: t('Columns:extra_charges_amount') + ' 1',
      renderCell: ({row}) => (
        <Typography>{row.additional_charges.amount1.toString()}</Typography>
      ),
    },
    {
      field: 'additional_charges_2',
      headerName: t('Columns:extra_charges_amount') + ' 2',
      renderCell: ({row}) => (
        <Typography>{row.additional_charges.amount1.toString()}</Typography>
      ),
    },
    {
      field: 'buy_price',
      headerName: t('Columns:buy_price'),
      hide: !isAdminTransport,
      renderCell: (params) => {
        const row = params.row as IJobInfo;
        if (row.job_classification !== 'assigned-transport') {
          return <Typography>N/A</Typography>;
        }
        return <Typography>{row.initial_price}</Typography>;
      },
    },
    {
      field: 'sell_price',
      headerName: t('Columns:sell_price'),
      hide: !isAdminNonTransport,
      renderCell: (params) => {
        const row = params.row as IJobInfo;
        if (row.job_classification !== 'assigned-transport') {
          return <Typography>N/A</Typography>;
        }
        return <Typography>{row.posted_price}</Typography>;
      },
    },
    {
      field: 'total_price',
      headerName: t(`Columns:total_price`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        const price: string = String(jobInfo.job_bids?.[0]?.amount ?? '0');
        const waitTimeCharge: number = Number(
          jobInfo.company?.waitTimeCharge || 0
        );
        const actualWaitTime: number = Number(jobInfo.job_wait_time || '0');
        const allowWaitingTime: number = jobInfo.job_bids?.[0]?.waitTime ?? 0;
        const marginPrice: string = String(jobInfo.margin_price || '0');

        let totalWaitTimeCharge = computationWaitingTimeCharge(
          actualWaitTime,
          allowWaitingTime,
          waitTimeCharge
        );

        let gst = calculateGST(
          Number(price) + Number(marginPrice),
          totalWaitTimeCharge
        );

        let grandTotal =
          Number(price) +
          Number(marginPrice) +
          Number(gst) +
          Number(totalWaitTimeCharge);

        // additional charges
        const {additional_charges} = jobInfo;
        grandTotal += parseFloat(additional_charges.amount1.toString());
        grandTotal += parseFloat(additional_charges.amount2.toString());

        let total_price = Number(grandTotal).toFixed(2);

        return <Typography>{total_price}</Typography>;
      },
    },
  ];
};
