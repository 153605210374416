import { Button } from "@material-ui/core";
import { GridCellParams, GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";




export const getMarginColumns = (t: TFunction, onDeleteCallback: (cellParams: GridCellParams) => void): GridColumns => {

    return [
        {
            field: 'maxIndicator',
            headerName: t('max_indicator'),
            width: 300
        },
        {
            field: 'price',
            headerName: t('margin_price'),
            width: 300

        },
        {
            field: 'isActive',
            headerName: t('is_active'),
            width: 100,
            valueFormatter: (params) => t(String(params.row.isActive ? 'active' : 'inactive'))
        },
        {
            field: 'action',
            headerName: t('action'),
            width: 200,
            renderCell: (params: GridCellParams) => <Button onClick={() => onDeleteCallback(params)} color="secondary" variant="contained">{t('delete')}</Button>
        }
    ]
}