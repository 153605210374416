import { Grid, Typography, Divider , Button, TextField  , InputAdornment, makeStyles} from '@material-ui/core'
import { GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState , useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { Customer } from '../../../../Api/Customer/Customer'
import { AppState } from '../../../../Redux'
import { ICustomerDetails } from "../../../../../../Application/DTOs/CustomerDto/CustomerDto.types";

import { setLoader } from '../../../../Redux/Loader/actions'
import CustomTable from '../../../Common/CustomTable/CustomTable'
import ViewCustomerDialog from '../../../Dialogs/ViewCustomerDialog/ViewCustomerDialog'

import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import LazyTable from '../../../Common/LazyTable/LazyTable';
import CustomerDetails from "../../../Common/CustomerDetails/CustomerDetails"
import CustomerAddress from "../../../Common/CustomerAddress/CustomerAddress"

import ChangePassword from "../../../Dialogs/ViewCustomerDialog/ChangePassword"
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';


const customerApi = new Customer()

const useStyles = makeStyles((theme) => ({

    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    }
}))
function CustomerAccountManagement() {
    const [t] = useTranslation(['CustomerManagementPage', 'Columns'])
    const dispatch = useDispatch()
    const classes = useStyles()

    const [selectedCustomerId, setSelectedCustomerId] = useState<string | number | null>(null)
    const [retainCurrent, setRetainCurrent] = useState(false);
    const [filterState, setFilterState] = useState<any>('')
    const [viewCustomerDialog , setViewCustomerDialog] = useState(false);
    const editCustomerRef = useRef();
    const [isEditMode ,setIsEditMode] = useState(false)
    const user = useSelector((state: AppState) => state.user.response);

    const [selectedCustomer , setSelectedCustomer] = useState<ICustomerDetails> ()


    const [showChangePassword , setChangePassword] = useState(false)

   
    
      const getDetails = async (customerId : any) =>{
 
        await customerApi.getDetails(customerId)
        .then(response => {
            
            let companyDetails: ICustomerDetails = response.data
            setSelectedCustomer(companyDetails)
            // setCustomerDetails(companyDetails)
        
            // dispatch(setLoader(false))
            // setEmailNotification(companyDetails.email_notifications == null ? [] : companyDetails.email_notifications)
            // setCompanyDetails(companyDetails)
            // setCompanyInfo(companyDetails)
            
        })
        .catch((err :any) => 
        {
            dispatch(setLoader(false))
            console.log('err', err)
            toast.error(t('error_getting_company_detail'))
        })
    }
    useEffect(() => {
        if (user?.customer.id) {
            getDetails(user?.customer.id)
        }
    }, [user?.customer.id])
   
    // useEffect(() => {
    //     let timeOutId = setTimeout(() => {
    //         setFilterState({
    //             reference : searchByName
    //         })

    //     }, 500)

    //     return () => {
    //         clearTimeout(timeOutId)
    //     }
    // }, [searchByName])

 

    const handleOnEditClick = () => {
        setIsEditMode(true)
    }
    const handleOnCancelClick = useCallback( ()=> {
        setIsEditMode(false)
    },[])

    const handleOnUpdateClick = () => {
        setIsEditMode(false)

    }
  
    const handlePasswordClose =() =>{
        setChangePassword(false)
    }

    const handleChangePasswordSubmit = useCallback(
        (password : any) => {
            // dispatch(setLoader(true))
            customerApi.changePassword(user?.id ,password)
                .then(() => {
                    handlePasswordClose()
                    // handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.success(t('success_changing_password'))
                    // dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    handlePasswordClose()
                    // handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.error(t('error_changing_password'))
                    // dispatch(setLoader(false))
                })
        },[])
    const handleUpdateClick=()=>{
        if (editCustomerRef.current) {
            (editCustomerRef.current as any).handleUpdate(() => {
                handleOnCancelClick();
            });
          }
       }

    return (
        <>
           <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid md item>
                    <Typography>
                        {t('customer_management')}
                    </Typography>
                </Grid>
               

        <Grid item md={12}>
        <Grid item md={12}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >
                                <Grid item md={3}>
                                            <Button onClick={() => {setChangePassword(!showChangePassword)}} startIcon={<ClearIcon />} variant="contained" fullWidth>Change Password</Button>
                                </Grid>
                                
                                {
                                    !isEditMode
                                        ? <Grid item md={3}>
                                            <Button startIcon={<EditIcon />} fullWidth onClick={() => handleOnEditClick()} className={classes.editBtn} variant="contained">
                                                {t('edit')}
                                            </Button>
                                        </Grid>
                                        :  null
                                }
                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleUpdateClick()} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                {t('update')}
                                            </Button>
                                        </Grid>
                                        : null
                                }

                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnCancelClick()} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>
              
                <CustomerDetails  ref={editCustomerRef} editMode={isEditMode} selectedCustomer={selectedCustomer} onEditDone={handleOnCancelClick}/>
               

                <CustomerAddress selectedCustomer={selectedCustomer} />

        </Grid>

            </Grid>
            <ChangePassword
                    open={showChangePassword}
                    handleClose={() => { setChangePassword(false)}}
                    onSubmit={handleChangePasswordSubmit}
                />

        </>
    )
}

export default CustomerAccountManagement