import {ICompanyInfo} from '../../../../Application/DTOs/CompanyDto/CompanyDto.type';
import {ICompanyState, SET_COMPANY_LIST, TCompanyActions} from './types';

const initial_state: ICompanyState = {
  list: [],
};

export function companyReducer(
  state: ICompanyState = initial_state,
  actions: TCompanyActions
): ICompanyState {
  switch (actions.type) {
    case SET_COMPANY_LIST:
      const list: ICompanyInfo[] = actions.payload as ICompanyInfo[];

      state.list = list;

      return Object.assign({}, state);

    default:
      return state;
  }
}
