import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography, DialogActions, Button, makeStyles, Grid } from "@material-ui/core";
import { IWarningDialogProps } from "./WarningDialog.types";
import WarningIcon from '@material-ui/icons/Warning';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    proceedBtn: {
        backgroundColor: 'red',
        color: 'white',
        '&:hover': {
            backgroundColor: 'red',
            color: 'white',
        }
    },
    warningIcon: {
        color: '#D4AC0D'
    }
}))

function WarningDialog(props: IWarningDialogProps) {
    const [t] = useTranslation('WarningDialog')
    const { open, handleClose, message, onProceedClick } = props
    const classes = useStyles()

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                <Grid
                    container
                    spacing={4}
                >
                    <Grid item md={1}>
                        <WarningIcon className={classes.warningIcon} />
                    </Grid>
                    <Grid item md={2}>
                        <Typography>
                            {t('warning')}
                        </Typography>
                    </Grid>
                </Grid>


            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {if(onProceedClick) onProceedClick()}} className={classes.proceedBtn} variant="contained">
                    {t('proceed')}
                </Button>
                <Button onClick={handleClose}>
                    {t('cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default WarningDialog