import { Grid, Typography, Divider, FormControl, InputLabel, Select, MenuItem, makeStyles, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { COMMISION_RATES_TYPE, COUNTRIES, MOVEMENT_TYPES } from '../../../../Types/Constants'
import CheckIcon from '@material-ui/icons/Check';
import TextFieldSelectCombo from '../../../Common/TextFieldSelectCombo/TextFieldSelectCombo'
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Company } from '../../../../Api/Company/Company';
import { setLoader } from '../../../../Redux/Loader/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type';
import { ICompanyFeeConfig, ICompanyJobTypeFee } from '../../../../../../Application/DTOs/CompanyFeeSettingsDto/CompanyFeeSettingsDto.types';
import { CompanyFeeSettingsReducer } from './CompanyFeeSettings.reducer';
import { isEmpty } from 'lodash';
import { IFee } from '../../../../../../Application/DTOs/DriverFeeSettingsDto/DriverFeeSettingsDto.types';
import { getJobTypes } from '../../../../Utilities/FeeSettings';
import SectionHeader from '../../../Common/SectionHeader/SectionHeader';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bolder'
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
}))

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const {
    EMPTY_PARK,
    FULL_TRUCK_LOAD,
    LOOSE_CARGO,
    OTHER,
    RAIL,
    SEA_FREIGHT,
    WHARF,
    ALL,
    LAND_FREIGHT
} = MOVEMENT_TYPES

const {
    FLAT_RATE,
    PERCENTAGE,
    M3
} = COMMISION_RATES_TYPE

const COMMISION_RATES_TYPES_OPTIONS = [
    FLAT_RATE,
    PERCENTAGE,
    M3
]

const companyApi = new Company()

const generateInitialState = (): ICompanyFeeConfig => {
    return Object.values(MOVEMENT_TYPES).reduce<ICompanyFeeConfig>((config, movementType) => {
        config[movementType] = getJobTypes(movementType).reduce<ICompanyJobTypeFee>((companyJobTypeFee, jobType) => {
            companyJobTypeFee[jobType] = {
                amount: 0,
                type: FLAT_RATE
            }

            return companyJobTypeFee
        }, {})

        return config
    }, {})
}

const initialState: ICompanyFeeConfig = generateInitialState()

function CompanyFeeSettings() {
    const [t] = useTranslation('CompanyFeeSettings')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [country, setCountry] = useState(AUSTRALIA)
    const [movementType, setMovementType] = useState(ALL)
    const [selectedCompanyId, setSelectedCompanyId] = useState('')
    const [companies, setCompanies] = useState<ICompanyInfo[]>([])
    const [companyFeeConfig, localDispatch] = useReducer(CompanyFeeSettingsReducer, initialState)

    const getCompanies = useCallback(
        (selectedCountry: string) => {
            dispatch(setLoader(true))
            companyApi.getAll(selectedCountry)
                .then(response => {
                    const companies: ICompanyInfo[] = response.data
                    setCompanies(companies)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    toast.error(t('error_getting_all_companies'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t],
    )

    const getCompanyFeeConfig = useCallback(
        (companyId: string) => {
            dispatch(setLoader(true))
            companyApi.getCompanyFeeConfig(companyId)
                .then(response => {
                    const config: ICompanyFeeConfig = response.data
                    console.log(`config`, config)
                    if (isEmpty(config)) {
                        localDispatch({ type: 'SET', payload: initialState })
                    } else {
                        localDispatch({ type: 'SET', payload: config })
                    }
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    toast.error(t('error_getting_company_config'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t],
    )

    useEffect(() => {
        if (selectedCompanyId !== '') {
            getCompanyFeeConfig(selectedCompanyId)
        }

    }, [selectedCompanyId, getCompanyFeeConfig])

    useEffect(() => {
        getCompanies(country)

    }, [country, getCompanies])

    const commissionJobTypes = useMemo(() => getJobTypes(movementType).map((JOB_TYPE, i) => {
        const feeValues: IFee | undefined = companyFeeConfig[movementType][JOB_TYPE]

        const feeType: string = feeValues?.type ?? FLAT_RATE
        const amount: number = feeValues?.amount ?? 0

        return (
            <Grid key={i} sm={12} md={6} item>
                <TextFieldSelectCombo
                    disabled={selectedCompanyId === ''}
                    textPlaceholder={t(JOB_TYPE)}
                    selectPlacholder={t('type')}
                    items={COMMISION_RATES_TYPES_OPTIONS}
                    selectValue={feeType}
                    textValue={String(amount)}
                    onTextChange={(e: any) => {

                        const value = Number(e.target.value)

                        if (feeType === PERCENTAGE) {
                            if (value >= 100) {
                                return
                            }
                        }

                        localDispatch({
                            type: 'UPDATE',
                            payload: {
                                amount: value,
                                commissionType: feeType,
                                jobType: JOB_TYPE,
                                movementType
                            }
                        })
                    }}
                    onSelectChange={(e: any) => {
                        localDispatch({
                            type: 'UPDATE',
                            payload: {
                                amount: 0,
                                commissionType: e.target.value,
                                jobType: JOB_TYPE,
                                movementType
                            }
                        })
                    }}
                />
            </Grid>
        )
    }), [t, selectedCompanyId, companyFeeConfig, movementType])


    const handleUpdateClick = useCallback(
        () => {
            if (selectedCompanyId === '') return
            dispatch(setLoader(true))
            companyApi.saveCompanyFeeConfig(selectedCompanyId, companyFeeConfig)
                .then(() => {
                    toast.success(t('success_updating_company_config'))
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    toast.error(t('error_updating_company_config'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, selectedCompanyId, companyFeeConfig],
    )

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("company_management")} title={t('company_fee_settings')} />

               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid md={6} item>
                            {/* <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value as string)
                                        setSelectedCompanyId('')
                                        localDispatch({ type: 'SET', payload: initialState })
                                    }}
                                >
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>

                                </Select>
                            </FormControl> */}
                        </Grid>
                        <Grid md={6} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('companies')}</InputLabel>
                                <Select
                                    value={selectedCompanyId}
                                    onChange={(e) => setSelectedCompanyId(e.target.value as string)}
                                >
                                    {companies.map(company => <MenuItem key={company.id} value={company.id}>{company.companyName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('movement_type')}</InputLabel>
                                <Select
                                    value={movementType}
                                    onChange={(e) => setMovementType(e.target.value as string)}
                                >
                                    <MenuItem value={ALL}>{t(ALL)}</MenuItem>
                                    <MenuItem value={EMPTY_PARK}>{t(EMPTY_PARK)}</MenuItem>
                                    {/* <MenuItem value={FCL}>{t(FCL)}</MenuItem> */}
                                    <MenuItem value={FULL_TRUCK_LOAD}>{t(FULL_TRUCK_LOAD)}</MenuItem>
                                    <MenuItem value={LAND_FREIGHT}>{t(LAND_FREIGHT)}</MenuItem>
                                    {/* <MenuItem value={LCL}>{t(LCL)}</MenuItem> */}
                                    <MenuItem value={LOOSE_CARGO}>{t(LOOSE_CARGO)}</MenuItem>
                                    <MenuItem value={OTHER}>{t(OTHER)}</MenuItem>
                                    <MenuItem value={RAIL}>{t(RAIL)}</MenuItem>
                                    <MenuItem value={SEA_FREIGHT}>{t(SEA_FREIGHT)}</MenuItem>
                                    <MenuItem value={WHARF}>{t(WHARF)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6} item>
                            {/* GAP */}
                        </Grid>
                        <Grid item md={12}>
                            <Typography className={classes.title}>
                                {t('commission_rates')}
                            </Typography>
                        </Grid>
                        {commissionJobTypes}
                        <Grid item md={12}>
                            <Grid container spacing={2}>
                                <Grid md={3} item>
                                    <Button onClick={handleUpdateClick} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                        {t('update')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}

export default CompanyFeeSettings