import { IJobInfo } from "../../../../Application/DTOs/JobsDto/JobsDto.types";

export const SET_JOB_LIST = 'SET_JOB_LIST'

export const SET_SELECTED_JOB = 'SET_SELECTED_JOB'

export interface IJobState {
    list: IJobInfo[]
    selectedJob: IJobInfo | null
}

export interface ISetJobListAction {
    type: typeof SET_JOB_LIST
    payload: IJobInfo[]
}

export interface ISetSelectedJobAction {
    type: typeof SET_SELECTED_JOB
    payload: IJobInfo | null
}

export type TJobActions = ISetJobListAction | ISetSelectedJobAction