import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    rerouteCard: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
        margin: '16px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'transform 0.2s',
        '&:hover': {
            transform: 'scale(1.05)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
    },
    title: {
        margin: '0 0 2px',
        fontSize: '1.2em',
    },
    descriptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0',
        color: '#555',
    },
    description: {
        margin: '0',
        textAlign: 'left'
    },
    counter: {
        backgroundColor: '#eee',
        borderRadius: '12px',
        padding: '2px 8px',
        fontSize: '0.9em',
    },
});

const RerouteCard = ({ title, description, url, value }) => {
    const classes = useStyles();
    const history = useHistory();
    const [counter, setCounter] = useState(23);

    const handleClick = () => {
        history.push(url);
    };

    return (
        <Grid item className={classes.rerouteCard} onClick={handleClick}>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.descriptionContainer}>
                <p className={classes.description}>{description}</p>
                {value !== undefined && <span className={classes.counter}>{value}</span>}
            </div>
        </Grid>
    );
};

RerouteCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default RerouteCard;
