
export default class MapAddress {
    placeId: string | undefined
    formattedAddress: string | undefined
    streetNum: string
    route: string
    city: string
    province: string
    state: string
    country: string
    postalCode: string
    lat: number | undefined
    long: number | undefined

    constructor(streetNum: string, route: string, city: string, province: string, state: string, country: string, postalCode: string, lat?: number, long?: number, placeId?: string, formattedAddress?: string) {
        this.streetNum = streetNum
        this.route = route
        this.city = city
        this.province = province
        this.state = state
        this.country = country
        this.postalCode = postalCode
        this.lat = lat
        this.long = long
        this.placeId = placeId
        this.formattedAddress = formattedAddress
    }

    static parseMapResult(geocodeResult: google.maps.GeocoderResult, lat_long?: google.maps.LatLngLiteral): MapAddress {
        const {address_components, place_id, formatted_address} = geocodeResult
        
        const streetNumComp = address_components.find((addressComponent) => addressComponent.types.includes('street_number'))
        const routeComp = address_components.find((addressComponent) => addressComponent.types.includes('route'))
        const cityComp = address_components.find((addressComponent) => addressComponent.types.includes('locality'))
        const stateComp = address_components.find((addressComponent) => addressComponent.types.includes('administrative_area_level_1'))
        const provinceComp = address_components.find((addressComponent) => addressComponent.types.includes('administrative_area_level_2'))
        const countryComp = address_components.find((addressComponent) => addressComponent.types.includes('country'))
        const postalCodeComp = address_components.find((addressComponent) => addressComponent.types.includes('postal_code'))

        return new MapAddress(
            streetNumComp?.short_name || '',
            routeComp?.short_name || '',
            cityComp?.short_name || '',
            provinceComp?.short_name || '',
            stateComp?.short_name || '',
            countryComp?.short_name || '',
            postalCodeComp?.short_name || '',
            lat_long?.lat,
            lat_long?.lng,
            place_id,
            formatted_address
        );
    }
}