import { COUNTRIES, COUNTRIES_ABBR } from "../Types/Constants"

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const {
    AU,
    JP,
    PH,
    SG
} = COUNTRIES_ABBR

export const getCountryByAbbr = (abbr:string):string => {
    switch(abbr) {
        case PH:
            return PHILIPPINES
        case AU:
            return AUSTRALIA
        case JP:
            return JAPAN
        case SG:
            return SINGAPORE
        default:
            return ''
    }
}

export const getLabelForRegistrationNumberByCountry = (country:string):string => {
    switch(country) {
        case SINGAPORE:
            return 'provide_company_uen'
        case PHILIPPINES:
            return 'provide_company_tin'
        case JAPAN:
            return 'provide_registration_number'
        default:
            return 'provide_company_abn'
    }
}



