import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "../../../routes"

import ManagementPage from "./WarehouseManagementPage/WarehouseManagementPage"
import StocksPage from "./Stocks/StocksPage"
import StocksLedger from "./Stocks/StocksLedger"
import OnSitePage from "./Containers/Onsite"
import ScheduledList from "./ScheduledList/ScheduledList"
// import CustomerAccountManagement from "./CustomerAccountManagementPage/CustomerAccountManagement"



function WarehouseManagement() {
    const match = useRouteMatch()

    return (
        <>
            {/* /home/warehouse/management/manage */}
            <Route path={match.path + routes.home.warehouse_management.warehouse_management}>
                <ManagementPage />
            </Route>
            <Route path={match.path + routes.home.warehouse_management.my_warehouse}>
                <ManagementPage />
            </Route>
            <Route path={match.path + routes.home.warehouse_management.stocks}>
                <StocksPage />
            </Route>
            <Route path={match.path + routes.home.warehouse_management.stock_ledger}>
                <StocksLedger />
            </Route>
            <Route path={match.path + routes.home.warehouse_management.scheduled_collection}>
                <ScheduledList report_type={'collection'} />
            </Route>
            <Route path={match.path + routes.home.warehouse_management.scheduled_deliveries}>
                <ScheduledList report_type={'delivery'} />
            </Route>
            <Route path={match.path + routes.home.warehouse_management.containers + routes.home.warehouse_management.on_site}>
                <OnSitePage />
            </Route>
        </>
    )
}


export default WarehouseManagement