import {Divider, Grid, Typography} from '@material-ui/core';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import {
  IJobInfo,
  IJobReportFilter,
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import {useDispatch, useSelector} from 'react-redux';
import {setLoader} from '../../../../Redux/Loader/actions';
import {Job} from '../../../../Api/Job/Job';
import {toast} from 'react-toastify';
import {getColumns} from './profitabilityColumns';
import ViewEditJob from '../../../Dialogs/ViewEditJob/ViewEditJob';
import {GridRowParams} from '@material-ui/data-grid';
import {AppState} from '../../../../Redux';
import {JOB_STATUS} from '../../../../Types/Constants';

const jobApi = new Job();

export default function Profitability() {
  const [t, i18n] = useTranslation('JobReport');
  
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const [isViewEditJobDialogOpen, setIsViewEditJobDialogOpen] = useState(false);
  const [filterState] = useState<IJobReportFilter>({
    job_status: [JOB_STATUS.ARCHIVE],
    // is_expired: true,
  });
  const [jobs, setJobs] = useState<IJobInfo[]>([]);
  const [selectedJob, setSelectedJob] = useState<IJobInfo | null>(null);

  const getJobsForReport = useCallback(
    (filters: IJobReportFilter) => {
      dispatch(setLoader(true));
      jobApi
        .getProfitabilityReports()
        .then((response) => {
          const jobs: IJobInfo[] = response.data;
          setJobs(jobs);
          dispatch(setLoader(false));
        })
        .catch((err :any) => {
          console.log('err', err);
          toast.error(t('error_getting_jobs_for_reports'));
          dispatch(setLoader(false));
        });
    },
    [dispatch, t]
  );

  useEffect(() => {
    getJobsForReport(filterState);
  }, [filterState, getJobsForReport]);

  const columns = getColumns(t,user);

  const handleOnViewEditJobDialogClose = useCallback(() => {
    setIsViewEditJobDialogOpen(false);
    setSelectedJob(null);
  }, []);

  const onRowClick = useCallback((params: GridRowParams) => {
    const job: IJobInfo = params.row as IJobInfo;

    setSelectedJob(job);
    setIsViewEditJobDialogOpen(true);
  }, []);

  return (
    <>
      <ViewEditJob
        hideActionButtons
        selectedJob={selectedJob}
        type={selectedJob?.job_classification}
        open={isViewEditJobDialogOpen}
        handleClose={handleOnViewEditJobDialogClose}
      />
      <Grid
        direction="column"
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item md={3}>
          <Typography>{t('profitability_report')}</Typography>
        </Grid>
       
        <Grid item md={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={12}>
              <CustomTable
                columns={columns}
                rows={jobs}
                onRowClick={onRowClick}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
