import { combineReducers } from 'redux';
import { companyReducer } from './Company/reducer';
import { jobReducer } from './Job/reducer';
import { languageReducer } from './Language/reducer';
import { loaderReducer } from './Loader/reducer';
import { userReducer } from './User/reducer';
import { authReducer } from './Auth/reducer'
import { themeReducer } from './Theme/reducer'
export const rootReducer = combineReducers({
  loader: loaderReducer,
  theme: themeReducer,
  user: userReducer,
  auth: authReducer,
  company: companyReducer,
  job: jobReducer,
  language: languageReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
