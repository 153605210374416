import {  makeStyles, Typography, Grid, Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ICustomerAddressProps } from "./CustomerAddress.types";
import { useState , useCallback, useMemo, useEffect} from "react";
import { Customer } from '../../../Api/Customer/Customer'
import { setLoader } from "../../../Redux/Loader/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CustomTable from "../../Common/CustomTable/CustomTable";
import { GridRowParams } from "@material-ui/data-grid";
import { getAddressesColumns } from "./CustomerAddress.columns";
import {ICustomerMapping} from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types"
import { IAddress } from '../../../../../Application/DTOs/AddressDto/AddressDto.types';
import AddressDialog from "../../Dialogs/V2/AddressDialogV2/AddressDialog";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))



const customerApi = new Customer()

function CustomerAddress(props: ICustomerAddressProps) {
    const [t] = useTranslation('CreateAddressDialog')
    const { selectedCustomer } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const [addresses, setAddresses] = useState<IAddress[]>([])
    const [addressDialogOpen , setAddressDialogOpen] = useState<boolean>(false)
    const columns = useMemo(() => getAddressesColumns(t), [t])
    const [mode , setMode] = useState<'create' | 'edit'>('create')
    const [selectedAddress,setSelectedAddress]=useState<IAddress | null>(null)
    const [selectedCompany , setSelectedCompanyId] = useState<any>('')

    // const getAddresses = async (customerId : any) =>{
 
    //     await customerApi.getAddress(customerId)
    //     .then(response => {
    //         const customerData : ICustomerMapping[] = response.data
    //         let customerAddress = customerData.map((x:any) => x.address)
    //         return customerAddress
            
    //     })
    //     .catch((err) => 
    //     {
    //         dispatch(setLoader(false))
    //         console.log('err', err)
    //         toast.error(t('error_getting_company_detail'))
    //         return []
    //     })
    // }
    const getAddresses = useCallback(
        (customerId: any) => {
            dispatch(setLoader(true))
                 customerApi.getAddress(customerId)
                .then(response => {
                    const customerData : ICustomerMapping[] = response.data
                    let customerAddress = customerData.map((x:any) => {return {...x.address ,is_main : x.is_main}} )
                    setSelectedCompanyId(customerData[0].company_id)
                    setAddresses(customerAddress)
                    dispatch(setLoader(false))
                    
                })
                .catch((err) => 
                {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_getting_company_detail'))
                    setAddresses([])
                })

        },
        [dispatch, t]
    )

    useEffect(() => {
        if(selectedCustomer){
            getAddresses(selectedCustomer?.id)
        }
        
    }, [getAddresses, selectedCustomer?.id])
    const handleDialogClose = useCallback(
        () => {
            setSelectedAddress(null)
            setAddressDialogOpen(false)
            if(selectedCustomer){
                getAddresses(selectedCustomer?.id)
            }
        },
        [],
    ) 
    const onRowSelect = useCallback(
        (rowParams: GridRowParams) => {
            setSelectedAddress(rowParams.row as IAddress)
            setMode('edit')
            setAddressDialogOpen(true)
        },
        [],
    ) 

    const onAddEvent = ()=>{
        setSelectedAddress(null)
        setMode('create')
        setAddressDialogOpen(true)

    }
    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                direction="column"
            >
                <Grid item md>
                    <Typography>
                        {t('addresses')}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    <AddressDialog 
                    handleClose={handleDialogClose}
                    open={addressDialogOpen}
                    isCommon={true}
                    mode={mode}
                    selectedAddress={selectedAddress}
                    customerData={selectedCustomer}
                    selectedCompanyId={selectedCompany}
                    accessType="customer"
                    />
                <Grid item md={12}>
                <Box
                    m={1}
                    //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    >
                    <Grid item md={2}>

                        <Button
                        onClick={onAddEvent}
                        fullWidth
                        color="primary"
                        variant="contained"
                        // startIcon={<FilterListIcon />}
                    >
                        {t('add')}
                    </Button>
                    </Grid>
                    </Box>
                </Grid>
                <CustomTable
                    onRowClick={onRowSelect}
                    columns={columns}
                    rows={addresses}
                />
                </Grid>
            </Grid>

        </div>
    )
}

export default CustomerAddress