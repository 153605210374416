import { Grid, Typography, Divider, TextField, InputAdornment, IconButton, Button } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { UsersApi } from "../../../Api/Users/Users";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";

const userApi = new UsersApi()

function ChangePassword() {
    const [t] = useTranslation('ChangePassword')
    const dispatch = useDispatch()
    const [showPass, setShowPass] = useState<boolean>(false)
    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')

    const changePassword = useCallback(() => {
        if(currentPassword == '') {
            toast.warning('current_password_not_empty')
            return
        }
        if(newPassword == '') {
            toast.warning('new_password_not_empty')
            return
        } else if(newPassword.length < 6) {
            toast.warning('invalid_password_length')
            return
        }

        if(passwordConfirm == '') {
            toast.warning('confirm_password_not_empty')
            return
        } else if(passwordConfirm.length < 6) {
            toast.warning('invalid_password_length')
            return
        }

        dispatch(setLoader(true))
        userApi.changePassword({
            newPassword,
            passwordConfirm,
            currentPassword
        })
            .then(() => {
                toast.success(t('success_changing_password'))
                setNewPassword('')
                setPasswordConfirm('')
                setCurrentPassword('')
                dispatch(setLoader(false))
            })
            .catch(err => {
                console.log(`err`, err)
                dispatch(setLoader(false))
                toast.error(t(err.response?.data ?? 'error_changing_password'))
            })
    }, [newPassword, passwordConfirm, currentPassword, dispatch, t])

    const handleOnChangePassword = useCallback(
        () => {
            changePassword()
        },
        [changePassword],
    )

    return (
        <>
            <Grid
                direction="column"
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={4}
            >
                <Grid item md={3}>
                    <Typography>
                        {t('change_password')}
                    </Typography>
                </Grid>
               
                <Grid md={12} lg={6} item>
                    <TextField
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t('current_password')}
                        type={showPass ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontWeight: 'bold'
                            }
                        }}
                    />
                </Grid>
                <Grid md={12} lg={6} item>
                    <TextField
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t('enter_password')}
                        type={showPass ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontWeight: 'bold'
                            }
                        }}
                    />
                </Grid>
                <Grid md={12} lg={6} item>
                    <TextField
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t('confirm_password')}
                        type={showPass ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        InputLabelProps={{
                            style: {
                                fontWeight: 'bold'
                            }
                        }}
                    />
                </Grid>
                <Grid md={12} lg={6} item>
                    <Button onClick={handleOnChangePassword} fullWidth variant="contained" color="primary">
                        {t('change_password')}

                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default ChangePassword