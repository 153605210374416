import React, { useCallback } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import reportWebVitals from './reportWebVitals';
import './i18n'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider, useSelector } from 'react-redux'
import { store, persistor } from './Redux/store';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns'
import { LocalizationProvider } from '@material-ui/pickers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ja, enUS } from 'date-fns/locale'
import { AppState } from './Redux';
import { LANGUAGES } from './Types/Constants';
import { PersistGate } from 'redux-persist/integration/react';
const {
  JAPANESE
} = LANGUAGES

const Main = () => {
  const language = useSelector((state: AppState) => state.language)

  const getLanguageLocale = useCallback((lang: string) => {
    switch (lang) {
      case JAPANESE:
        return ja

      default:
        return enUS
    }
  }, [])

  return (

    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={getLanguageLocale(language.selectedLanguage)}>
      <ToastContainer />
      <App />
    </LocalizationProvider >

  )
}

ReactDOM.render(
  <BrowserRouter>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Main />
      </PersistGate>
    </ReduxProvider>
  </BrowserRouter>
  ,
  document.getElementById('root')
);
reportWebVitals();

if (module.hot) {
  module.hot.accept()
}