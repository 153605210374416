import React, {
    useImperativeHandle,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
  } from 'react';
import { DataGrid, GridColumns, GridRowsProp  , GridEditCellPropsParams  } from '@material-ui/data-grid';
import { Button ,makeStyles} from '@material-ui/core';
import jsFileDownload from 'js-file-download'
import { useTranslation } from "react-i18next";
import { IRatesTableProps} from './RatesTable.props'
import { IDownloadExcel } from "./DownloadExcel.props";
import Excel from 'exceljs';
const saveAs = require('file-saver');
const useStyles = makeStyles(() => ({
  root: {
    // height: 700,
    'text-align': 'center'
    // minWidth: 800
  },
  titleTable:{
    
  }
}))
  export default function DownloadExcel(props : IDownloadExcel) {
    const [t] = useTranslation('RatesManagement')
    const {type, data ,  gridData} = props

    const classes = useStyles()
        type Country = {
        name: string;
        countryCode: string;
        capital: string;
        phoneIndicator: number;
      };
      

      const exportCountriesFile = async () => {
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Config');
        data.single.forEach((item,index) => {
          var row = worksheet.getRow(index + 1);
          row.getCell(1).value =item.key; 
          row.getCell(2).value =item.value; 

        })

        const tableHeaders = [t('pallet_no'), t('buy_rate'), t('sell_rate')]
        if(type == 'single'){

          worksheet.getRow(data.single.length + 1).values = tableHeaders;
          worksheet.columns = [
            { key: 'id', },
            { key: 'buy_rate'},
            { key: 'sell_rate'  },

          ];
          worksheet.addRows(gridData.single);
        }else if(type == 'wharf'){
          // worksheet.getRow(data.single.length + 2).values = tableHeaders;
            data.wharf.forEach((item,index) => {
              var row = worksheet.getRow((data.single.length + 1) + index);
              row.getCell(1).value =item.key; 
              row.getCell(2).value = item.value; 
  
          })
        }else if(type =='multiple'){
          // const cell = worksheet.getRow(data.single.length + 2)
          const ctr = data.single.length + 2
          worksheet.mergeCells(`A${ctr}` ,`C${ctr}`)
          worksheet.getCell(`A${ctr}`).style = {font:{bold: true}}
          worksheet.getCell(`A${ctr}`).alignment ={ vertical: 'middle', horizontal: 'center' };
          worksheet.getCell(`A${ctr}`).value ="Metro Inner"
         

          worksheet.mergeCells(`E${ctr}` ,`G${ctr}`)
          worksheet.getCell(`E${ctr}`).style = {font:{bold: true}}
          worksheet.getCell(`E${ctr}`).alignment ={ vertical: 'middle', horizontal: 'center' };
          worksheet.getCell(`E${ctr}`).value ="Metro Outer"


          worksheet.mergeCells(`I${ctr}` ,`K${ctr}`)
          worksheet.getCell(`I${ctr}`).style = {font:{bold: true}}
          worksheet.getCell(`I${ctr}`).alignment ={ vertical: 'middle', horizontal: 'center' };
          worksheet.getCell(`I${ctr}`).value ="Interstate"

            worksheet.getRow(data.single.length + 3).values = [...tableHeaders , "" , ...tableHeaders , "" , ...tableHeaders];
            gridData.metroInnerGrid.forEach((x,index)=> {
              let metroOuter = gridData.metroOuterGrid[index]
              let interstate = gridData.interstateGrid[index]
              worksheet.addRow([x.id , x.buy_rate , x.sell_rate , " " , metroOuter.id ,metroOuter.buy_rate,metroOuter.sell_rate ,' ', interstate.id ,interstate.buy_rate,interstate.sell_rate])
            });
          
        }
      
        workbook.xlsx.writeBuffer().then((buffs)=>{
            const blob = new Blob([buffs], { type: "application/xlsx" });
            saveAs(blob, "Config.xlsx");
        })
      };
      
      
    return (
      
      <div  className={classes.root}>
        <Button variant="contained"   color="primary" onClick={(e)  => exportCountriesFile()}>Download Config</Button>
      </div>
    );
  }
  