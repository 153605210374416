import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useState, useMemo } from 'react'
import { Divider, Grid, Typography, Select, makeStyles, MenuItem, Button, TextField, } from "@material-ui/core"
import { USER_ROLES } from "../../../../Types/Constants"
import { IAutocompleteOption } from "../../../Common/types"
import { AdminSettings } from "../../../../Api/AdminSettings/AdminSettings"
import { useDispatch, useSelector } from "react-redux"
import { setLoader } from "../../../../Redux/Loader/actions"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"
import CustomTable from "../../../Common/CustomTable/CustomTable";
import { AppState } from "../../../../Redux"
import { getNotificationColumns } from "./Notifications.columns";
import { GridRowParams } from "@material-ui/data-grid";
import { MobileDatePicker } from "@material-ui/pickers";

import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    }
}))



const adminSettingApi = new AdminSettings()

const {
    ADMIN_USER
} = USER_ROLES

const toValue = (opt: IAutocompleteOption): string => opt.value
function NotificationSettings() {
    const [t] = useTranslation(['CountrySettings','Common']);
    const user = useSelector((state: AppState) => state.user);
    const isUserAdmin = user.response?.type === ADMIN_USER;
    const dispatch = useDispatch();
    const classes = useStyles();
    const columns = useMemo(() => getNotificationColumns(t), [t])

    const [accessMode, setAccessMode] = useState('create');
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState('');
    const [startDate, setStartDate] = useState<Date | undefined>(null)
    const [endDate, setEndDate] = useState<Date | undefined>(null)
    const [notificationId, setNotificationId] = useState('')
    const [targetPage, setTargetPage] = useState('');
    const [notificationList, setNotificationList] = useState([])

    const getConfigV2 = useCallback(() => {
        dispatch(setLoader(true));
        adminSettingApi
            .getNotificationSettings()
            .then((response) => {
                setNotificationList(response.data)
                dispatch(setLoader(false));
            })
            .catch((err) => {
                console.log(`err`, err);
                dispatch(setLoader(false));
                toast.error(t('error_getting_config'));
            });
    }, [dispatch, t]);

    useEffect(() => {
        getConfigV2();
    }, [getConfigV2]);

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        setEndDate(date);
    };
    const handleSaveClick = useCallback(() => {
        if (notificationMessage === '') return;
        const notificationData = {
            message: notificationMessage,
            type: notificationType,
            startDate: startDate,
            endDate: endDate,
            targetPage: 'login'
        };
        if (accessMode === 'create') {
            adminSettingApi
                .saveNotificationSettings(notificationData)
                .then(() => {
                    dispatch(setLoader(false));
                    toast.success(t('success_saving_config'));
                    getConfigV2();
                })
                .catch((err) => {
                    console.log(`err`, err);
                    dispatch(setLoader(false));
                    toast.error(t('error_saving_config'));
                });
        } else {
            adminSettingApi
                .updateNotificationSettings(notificationId, notificationData)
                .then(() => {
                    dispatch(setLoader(false));
                    toast.success(t('success_saving_config'));
                    getConfigV2();
                })
                .catch((err) => {
                    console.log(`err`, err);
                    dispatch(setLoader(false));
                    toast.error(t('error_saving_config'));
                });
        }
    }, [notificationMessage, notificationId, accessMode, notificationType, startDate, endDate, targetPage, dispatch, t, getConfigV2])
    const onRowSelect = useCallback(
        (rowParams: GridRowParams) => {
            const data = rowParams.row
            setNotificationType(data.type)
            setNotificationMessage(data.message)
            setStartDate(data.start_date)
            setEndDate(data.end_date)
            setNotificationId(data.id)
            setAccessMode('update')
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        },
        [],
    )
    const handleTypeChange = (event) => {
        setNotificationType(event.target.value);
    };
    const handleAddButtonClick = () => {
        // Reset form fields and set access mode to create
        setNotificationType('');
        setNotificationMessage('');
        setStartDate(null);
        setEndDate(null);
        setAccessMode('create');
        setNotificationId('')
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };
    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={5}>
            <Grid item>
                <Typography>{t('notification_settings')}</Typography>
            </Grid>
           
            <Grid item>
                <Button style={{ float: 'right' }} onClick={handleAddButtonClick} variant="contained" color="primary">
                    {t('add_notification')}
                </Button>
            </Grid>
            <Grid item>
                <CustomTable
                    onRowClick={onRowSelect}
                    columns={columns}
                    rows={notificationList}
                />
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                        <Select
                            label="Type"
                            fullWidth
                            variant="outlined"
                            value={notificationType}
                            onChange={handleTypeChange}
                        >
                            <MenuItem value="info">Info</MenuItem>
                            <MenuItem value="warning">Warning</MenuItem>
                            <MenuItem value="error">Error</MenuItem>
                            <MenuItem value="success">Success</MenuItem>
                        </Select>
                    </Grid>
                    <Grid xs={12} lg={9} item>
                        <TextField
                            value={notificationMessage}
                            onChange={(e) => setNotificationMessage(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t('notification_message') + '*'}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold',
                                },
                            }}
                        />
                    </Grid>


                    <Grid item xs={12} lg={4}>
                        <MobileDatePicker
                            label="Start Date"
                            renderInput={(props) => (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Start Date"
                                    {...props}
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: "bold",
                                        },
                                    }}
                                />
                            )}
                            inputFormat="dd/MM/yyyy"
                            value={startDate}
                            onChange={(e: any) => {
                                handleStartDateChange(e);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <MobileDatePicker
                            renderInput={props => <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="End Date"
                                {...props}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />}
                            minDate={startDate}
                            inputFormat='dd/MM/yyyy'
                            value={endDate}
                            onChange={(e: any) => { handleEndDateChange(e) }}
                        />
                    </Grid>
                    <Grid md={12} item>
                        <Button onClick={() => handleSaveClick()} startIcon={<SaveIcon />} variant="contained" color="primary">
                            {accessMode === 'create' ? t('save') : t('update')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default NotificationSettings;

