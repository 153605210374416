import {
  TextareaAutosize,
  MenuItem,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  makeStyles,
  Divider,
  TextField,
  InputAdornment,
  GridList,
  GridListTile,
  FormControlLabel,
  Checkbox,
  Badge,
  Tooltip
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import { useTranslation } from 'react-i18next';
import { ICreateQuoteProps } from './CreateQuote.types';
import AddIcon from '@material-ui/icons/Add';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { StaticDateTimePicker, DateTimePicker, MobileDatePicker, MobileDateTimePicker } from '@material-ui/pickers';
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import { ICompanyContact } from "../../../../../../Application/DTOs/CompanyDto/CompanyDto.type";

import DoneIcon from '@material-ui/icons/Done';
import CreateAddressDialog from '../../../Dialogs/CreateAddressDialog/CreateAddressDialog';
import EmailListDialog from '../../../Dialogs/EmailListDialog/EmailListDialog';

import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import { Address } from '../../../../Api/Address/Address';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../Redux';
import { toast } from 'react-toastify';
import {
  JOB_STATUS,
  POST_TO,
  TYPES_OF_PACKAGES,
  COUNTRIES,
  MOVEMENT_TYPES,
  TRIP_TYPE,
  USER_ROLES,
  JOB_FILES_TYPE,
  VEHICLE_TYPES_CONFIG,
  JOB_TYPES,
  CREATE_JOB_ELEMENTS,
} from '../../../../Types/Constants';
import { concat, conforms, create, get, groupBy, includes, isEmpty, isEqual, isNil } from 'lodash';
import { Job } from '../../../../Api/Job/Job';
import { QuotesApi } from '../../../../Api/Quotes/QuotesApi';
import fileDownload from 'js-file-download';

import { setLoader } from '../../../../Redux/Loader/actions';
import {
  IJobFileInfo,
  IJobInfo,
  IPackagesTypesPayload
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import JobHistories from '../../../Common/JobHistories/JobHistories';
import { formatDateTime, formatDateTimeDisplay, formatReadOffset, formatDisplayOffset } from '../../../../Utilities/DateTime';
import { isNonNumeric, isNumeric } from '../../../../Utilities/Regex';
import { Company } from '../../../../Api/Company/Company';
import { IAssociateGroupInfo } from '../../../../../../Application/DTOs/AssociateGroupDto/AssociateGroupDto.types';
import { IAutocompleteOption, ITypeOfPackage } from '../../../Common/types';
import MultiAutocomplete from '../../../Common/MultiAutocomplete/MultiAutocomplete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EditIcon from '@material-ui/icons/Edit';

import RequiredDocuments from '../../../Common/RequiredDocument/RequiredDocuments'
import { AdminSettings } from '../../../../Api/AdminSettings/AdminSettings';
import { IConfiguration, ICountryConfiguration } from '../../../../../../Application/Models/CountryConfigurations/CountryConfigurations.types';
import JobFilesTable from '../../../Common/JobFilesTable/JobFilesTable';
import FileInput from '../../../Common/FileInput/FileInput';

import { formatFileUrl } from '../../../../Utilities/Formatting';
import Image from '../../../Common/Image/Image';
import ImagePreviewDialog from '../../../Dialogs/ImagePreviewDialog/ImagePreviewDialog';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../routes';
import { AnyRecord } from 'dns';
import { read } from 'fs';
import { PICKED_UP } from '../../Report/types';
import MultiplePackageType from '../../../Common/Packages/Packages'
import moment from 'moment';
import CommentDialog from '../../../Dialogs/CommentDialog/CommentDialog';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import { IQuoteInfo } from '../../../../../../Application/DTOs/QuotesDto/QuoteDto.types';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  gap: {
    marginTop: 20,
  },
  selectLabel: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
  },
  btnCreate: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
  addressSelect: {
    whiteSpace: "unset",
    wordBreak: "break-all",
    maxWidth: "75%"
  }
}));

const addressApi = new Address();
const jobApi = new Job();
const quotesApi = new QuotesApi();

const companyApi = new Company();
const adminSettingApi = new AdminSettings();

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;

const { FULL_TRUCK_LOAD, LOOSE_CARGO, EMPTY_PARK, WHARF, RAIL } = MOVEMENT_TYPES;

const { ONE_WAY, ROUND_TRIP } = TRIP_TYPE;

const { ASSOCIATES, PUBLIC } = POST_TO;

const { CREATED, COMPLETED, FUMIGATION, VIA_YARD, STORAGE, PICKEDUP, ASSIGNED, DELIVERED, CLONED } = JOB_STATUS;

const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER } = USER_ROLES;

const { DAMAGE_PHOTO } = JOB_FILES_TYPE;

const { QUANTITY, PACKAGES_TYPE, VOLUME, CN, ECRNUM, SEAL_NO, WS, CNTR } =
  CREATE_JOB_ELEMENTS;

const dateNow = new Date();

const associateGroupsToOptions = (
  associateGroup: IAssociateGroupInfo
): IAutocompleteOption => ({
  label: associateGroup.name,
  value: associateGroup.id,
});

function QuotesDetails(props: ICreateQuoteProps, ref: any) {
  const [t] = useTranslation('ShowQuotes');
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [isCreateAddressDialogOpen, setIsCreateAddressDialogOpen] = useState(false);
  const [isSendEmailDialogOpen, setIsSendEmailDialogOpen] = useState(false);
  const user = useSelector((state: AppState) => state.user.response);
  const [allFormData, setFormData] = useState<FormData>()
  const company = useSelector((state: AppState) => state.company);

  const currentUserCompanyId: any = user?.company?.id || undefined;
  const currentuserId: any = user?.id || undefined;

  const isUserAdmin = user?.type === ADMIN_USER;
  const isSuperAdmin = user?.type === SUPER_ADMIN;
  const isDriverManager = user?.type === DRIVER_MANAGER;
  const [country, setCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);

  const [companyId, setCompanyId] = useState(
    isSuperAdmin || isUserAdmin ? '' : currentUserCompanyId
  );

  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [status, setStatus] = useState<string | undefined>(undefined);

  const [jobType, setJobType] = useState('');
  const [tripType, setTripType] = useState(ONE_WAY);
  const [movementType, setMovementType] = useState('');
  const [reference, setReference] = useState('');
  const [customerReference, setCustomerReference] = useState('');


  const [vehicleType, setVehicleType] = useState('');
  const [files, setFiles] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>(undefined);
  const [selectedAddressMode, setSelectedAddressMode] = useState('create');

  const [pickUpDateTime, setPickUpDateTime] = useState<Date | null>(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState<Date | null>(new Date());
  const [returnDateTime, setReturnDateTime] = useState<Date | null>(new Date());
  const [typeOfPackage, setTypeOfPackage] = useState('');
  const [quantity, setQuantity] = useState('');
  const [declaredGrossWeight, setDeclaredGrossWeight] = useState('');
  const [volume, setVolume] = useState('');
  const [jobRequirements, setJobRequirements] = useState('');
  const [postingCompanyName, setPostingCompanyName] = useState('');

  const [mergedInstruction, setMergedInstruction] = useState('');
  const [leadTime, setLeadTime] = useState('');

  const [deliverInstruction, setDeliverInstruction] = useState('');
  const [pickUpInstruction, setPickupInstruction] = useState('');

  const [selectedRequiredDocuments, setSelectedRequiredDocuments] = useState<any[]>([]);
  const [currentSelectedNoteUser, setCurrentSelectedNoteUser] = useState('admin')



  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  const [isCompanyJobOwner, setIsCompanyJobOwner] = useState(true);

  const [pickUpState, setPickUpState] = useState('');
  const [pickUpGroup, setPickUpGroup] = useState('');
  const [pickUpAddressId, setPickUpAddressId] = useState('');

  const [deliverToState, setDeliverToState] = useState('');
  const [deliverToGroup, setDeliverToGroup] = useState('');
  const [deliverToAddressId, setDeliverToAddressId] = useState('');

  const [deliverToStateType, setDeliverToStateType] = useState('');


  const [returnToState, setReturnToState] = useState('');
  const [returnToGroup, setReturnToGroup] = useState('');
  const [returnToAddressId, setReturnToAddressId] = useState('');

  const [associateGroups, setAssociateGroups] = useState<IAutocompleteOption[]>(
    []
  );

  const [containerNo, setContainerNo] = useState<any>('');
  const [sealNo, setSealNo] = useState<any>('');
  const [CNTRLastDay, setCNTRLastDay] = useState<Date | null>(new Date());
  const [emptyContainerReleaseNumber, setEmptyContainerReleaseNumber] = useState<any>('');
  const [wharfSlot, setWharfSlot] = useState<any>('');


  const [selectedAssociateGroups, setSelectedAssociateGroups] = useState<
    IAutocompleteOption[]
  >([]);



  const [movementTypes, setMovementTypes] = useState<string[]>([]);
  const [jobTypes, setJobTypes] = useState<string[]>([]);

  const [showAdditionalSteps, setShowAdditionalSteps] = useState<boolean>(true);

  const [addStepDatetime, setAddStepDatetime] = useState<Date | null>(null);
  const [addStepEndDatetime, setAddStepEndDatetime] = useState<Date | null>(null);
  const [additionalStepStatus, setAdditionalStepStatus] = useState('');



  const setTypeOfPackageRef = useRef();

  const [allocationSelectedJob, setAllocationSelectedJob] = useState<any>({});
  interface LooseObject {
    [key: string]: any
  }

  const [configJobTypes, setConfigJobTypes] = useState<LooseObject>({})



  const { readonly, selectedQuote, isEditMode, type: _jobClassification, isJobOwner, documentUploadReadOnly, mode } = props;

  const [selectedQuoteWholeData, setSelectedQuoteWholeData] = useState<IQuoteInfo | undefined>(undefined)

  const [typeOfPackageList, setTypeOfPackageList] = useState<any[]>(selectedQuoteWholeData?.packages_type || []);


  const jobClassification =
    _jobClassification === 'assigned-transport'
      ? 'transport'
      : _jobClassification;

  const formatAddress = useCallback(
    (address: IAddress) =>
      `${address.street_number} ${address.city} ${address.state} ${t(address.country)}`,
    [t]
  );

  const [currentPickUpAddressString, setCurrentPickUpAddressString] =
    useState('');
  const [currentDeliverToAddressString, setCurrentDeliverToAddressString] =
    useState('');
  const [currentReturnToAddressString, setCurrentReturnToAddressString] =
    useState('');

  // Additional Charges
  const [additionalChargeDescription1, setAdditionalChargeDescription1] =
    useState('');
  const [additionalChargeDescription2, setAdditionalChargeDescription2] =
    useState('');
  const [additionalChargeAmount1, setAdditionalChargeAmount1] =
    useState<any>(0);
  const [additionalChargeAmount2, setAdditionalChargeAmount2] =
    useState<any>(0);

  // Assigned job
  const [transportRef, setTransportRef] = useState('');
  const [transportCompany, setTransportCompany] = useState('');
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);

  //notes
  const [jobCommentData, setJobCommentData] = useState<any>({})

  const getCountryConfig = useCallback(() => {
    adminSettingApi
      .getCountryConfig(country)
      .then((response) => {
        const config: ICountryConfiguration = response.data;

        setMovementTypes(config.jobSettings[jobClassification].movementTypes);
        setJobTypes(config.jobSettings[jobClassification].jobTypes);

      })
      .catch((err: any) => {
        console.log(`err`, err);
        toast.error(t('error_getting_config'));
      });
  }, [country, t, jobClassification]);

  const getTypesConfig = useCallback(() => {
    adminSettingApi
      .getTypeConfig(country, jobClassification, tripType)
      .then((response) => {
        const config = response.data;
        setMovementTypes(config.movement_types);
        setConfigJobTypes(config.job_types)
      })
      .catch((err: any) => {
        console.log(`err`, err);
        toast.error(t('error_getting_config'));
      });
  }, [country, t, jobClassification, tripType]);

  const getAssociateGroups = useCallback(() => {
    dispatch(setLoader(true));
    companyApi
      .getCompanyAssociateGroups(companyId)
      .then((response) => {
        const associateGroups: IAssociateGroupInfo[] = response.data;

        setAssociateGroups(associateGroups.map(associateGroupsToOptions));

        dispatch(setLoader(false));
      })
      .catch((err: any) => {
        console.log('err', err);
        toast.error(t('error_getting_associate_groups'));
        dispatch(setLoader(false));
      });
  }, [dispatch, t, companyId]);

  const getAddresses = useCallback(
    (companyId?: any) => {
      if (companyId === '' || isNil(companyId)) return;

      dispatch(setLoader(true));
      addressApi
        .allForJob(
          companyId,
          true,
          !readonly ? country : null,
          false,
          false,
          true
        )
        .then((response) => {
          const addresses = response.data as IAddress[];
          setAddresses(addresses);
          dispatch(setLoader(false));
        })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });
    },
    [t, country, readonly, dispatch]
  );


  const handleMovementTypeChange = (e: string) => {
    setMovementType(e)
    setJobTypes(configJobTypes[e].map((a: any) => a.value))

  }

  useEffect(() => {
    if (selectedQuote) {
      try {
        setJobTypes(configJobTypes[movementType].map((a: any) => a.value))
      }
      catch (error) {
      }
    }

  }, [movementType])


  const setQuoteDetails = useCallback(
    (job: IQuoteInfo) => {
      const isCurrentCompanyPostingCompany: boolean = isEqual(
        job.company_id,
        currentUserCompanyId
      );
      if (job.quote_files) {
        setFiles(job.quote_files)
      }
      setTypeOfPackageList(job.packages_type || [])
      setIsCompanyJobOwner(isCurrentCompanyPostingCompany);

      setPostingCompanyName(job.company?.companyName || '');

      setCountry(job.pick_up_address?.country || '');

      setPickUpAddressId(String(job.pickup_address_id));

      setDeliverToAddressId(String(job.delivery_address_id));
      setReturnToAddressId(String(job.return_address_id));

      setStatus(job.status);
      setAdditionalStepStatus(job.additional_step_status)
      // setIsBonds(job.is_assigned_to_bonds);
      if (mode != 'generate') {
        setReference(job.reference);
      }
      setCompanyId(job.company_id);

      setMovementType(job.movement_type);
      setTripType(job.trip_type);
      setJobType(job.job_type);
      setVehicleType(job.vehicle_type);


      // setTypeOfPackage(job.package_type);
      setAddStepDatetime(job.additional_step_date ? new Date(job.additional_step_date) : null);
      setAddStepEndDatetime(job.additional_step_end_date ? new Date(job.additional_step_end_date) : null);


      setLeadTime(job.lead_time)
      setDeclaredGrossWeight(job.weight);
      if (mode == 'generate') {
        setJobRequirements(`Quote Ref: ${job.reference} \n${job.instructions}`)
      }
      addressApi.byId(job.pickup_address_id as any).then((response) => {
        setPickupInstruction(response.data[0]['instruction'] || null)
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });
      addressApi.byId(job.delivery_address_id as any).then((response) => {
        setDeliverInstruction(response.data[0]['instruction'] || null)
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });



      let currentPickUpAddressString = '';
      let currentDeliverToAddressString = '';
      let currentReturnToAddressString = '';

      if (job.pick_up_address) {
        currentPickUpAddressString =
          isCurrentCompanyPostingCompany ||
            isSuperAdmin ||
            isUserAdmin ||
            job.status !== CREATED
            ? formatAddress(job.pick_up_address)
            : job.pick_up_address.city;
        setCurrentPickUpAddressString(currentPickUpAddressString);
      }

      if (job.deliver_to_address) {
        currentDeliverToAddressString =
          isCurrentCompanyPostingCompany ||
            isSuperAdmin ||
            isUserAdmin ||
            job.status !== CREATED
            ? formatAddress(job.deliver_to_address)
            : job.deliver_to_address.city;
        setCurrentDeliverToAddressString(currentDeliverToAddressString);
      }

      if (job.return_to_address) {
        currentReturnToAddressString =
          isCurrentCompanyPostingCompany ||
            isSuperAdmin ||
            isUserAdmin ||
            job.status !== CREATED
            ? formatAddress(job.return_to_address)
            : job.return_to_address.city;
        setCurrentReturnToAddressString(currentReturnToAddressString);
      }

    },
    [selectedQuoteWholeData, readonly]
  );

  const handleDialogClose = useCallback(() => {
    getAddresses(companyId);
    setIsCreateAddressDialogOpen(false);
  }, [companyId, getAddresses]);

  const onCreateAddressDone = useCallback(() => {
    handleDialogClose();
  }, [handleDialogClose]);

  const onEmailDialogClose = useCallback(() => {
    setIsSendEmailDialogOpen(false);
  }, []);


  const handleEmailDialogOpen = () => {
    if (handleSendQuoteFormData() != false) {
      setIsSendEmailDialogOpen(true)
    }
  }


  const handleCountryChange = (e: any) => {
    const currentCountry = e.target.value;
    setCountry(currentCountry);
    setJobType('');
    setVehicleType('');
  };

  const addressesByState: any = useMemo(() => {
    if (country === AUSTRALIA) {
      return groupBy(addresses, 'state');
    }

    return [];
  }, [country, addresses]);

  const addressesByGroup: any = useMemo(() => {
    if (country !== AUSTRALIA) {
      return groupBy(addresses, 'address_group');
    }

    return [];
  }, [country, addresses]);



  const handleDocumentCheckBoxChange = (index: any) => {
    let tempSelected = selectedRequiredDocuments
    const idx = selectedRequiredDocuments.indexOf(index)
    index['checked'] = !index['checked']
    tempSelected[idx] = index
    setSelectedRequiredDocuments(tempSelected)
  };


  useEffect(() => {

    if (addresses.length > 0) {
      if (selectedQuoteWholeData && isEditMode) {
        const pickUpAddress = addresses.find(
          (address) => address.id === selectedQuoteWholeData.pickup_address_id
        );
        if (pickUpAddress && pickUpAddress.address_group) {
          setPickUpState(pickUpAddress.state);
          setPickUpGroup(pickUpAddress.address_group ?? 'Unknown');
        }

        const deliveryAddress = addresses.find(
          (address) => address.id === selectedQuoteWholeData.delivery_address_id
        );

        if (deliveryAddress && deliveryAddress.address_group) {
          setDeliverToState(deliveryAddress.state);
          setDeliverToGroup(deliveryAddress.address_group ?? 'Unknown');
        }

        const returnAddress = addresses.find(
          (address) => address.id === selectedQuoteWholeData.return_address_id
        );

        if (returnAddress && returnAddress.address_group) {
          setReturnToState(returnAddress.state);
          setReturnToGroup(returnAddress.address_group ?? 'Unknown');
        }
      }
    }

  }, [addresses, selectedQuoteWholeData, isEditMode]);

  useEffect(() => {
    getAddresses(companyId);
    getTypesConfig()

  }, [
    getAddresses,
    companyId,
    getAssociateGroups,
    getTypesConfig
  ]);
  useEffect(() => {
    if (mode == 'create') {
      quotesApi.getTempReference().then((response) => {
        setReference(response.data.reference || '')

      }).catch(() => {
        setReference("")

      })

    } else if (mode == 'generate') {
      setReference("")
    }
  }, [mode])
  const stateOptions = useMemo(
    () =>
      Object.keys(addressesByState).map((state, i) => {
        if (t(state) != "") {
          return <MenuItem key={i} value={state}>
            {t(state)}
          </MenuItem>
        }
      }
      ),
    [addressesByState, t]
  );

  useImperativeHandle(ref, () => ({
    handleGenerate: (callback: any) => {
      handleCreateOrUpdateClick(callback);
    },
  }));

  const getWholeData = useCallback(
    (jobId: string | number) => {
      dispatch(setLoader(true));
      quotesApi.getDetails(jobId)
        .then((response) => {
          const quote: IQuoteInfo = response.data;
          setQuoteDetails(quote)

          dispatch(setLoader(false));
        })
        .catch((err: any) => {
          dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t('error_getting_job_details'));
        });
    },
    [setQuoteDetails, t, dispatch]
  );

  useEffect(() => {
    if (readonly) {
      if (selectedQuote) {
        getWholeData(selectedQuote.id)
      }
    }
  }, [selectedQuote, readonly])


  const arrayOfofSteps = [{ 'key': 'storage', value: 'Storage' }, { 'key': 'via_yard', value: 'Via yard' }, { 'key': 'fumigation', value: 'Fumigation' }]
  const additionalStepOptions = useMemo(
    () =>

      arrayOfofSteps.map((state, i) => {
        if (t(state['value']) != "") {
          return <MenuItem key={state['key']} value={state['key']}>
            {t(state['value'])}
          </MenuItem>
        }
      }
      ),
    [addressesByState, t]
  );

  const pickUpGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[pickUpState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>
      )),
    [addressesByState, pickUpState, t, addressesByGroup, country]
  );

  const pickUpAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[pickUpState], 'address_group')
          : addressesByGroup)[pickUpGroup] || []
      ).map((address: IAddress, i: number) => (

        <MenuItem key={i} value={address.id}>
          <div style={{ 'color': 'gray' }}> {address.company_name} &nbsp;</div>  {formatAddress(address)}
        </MenuItem>
      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      pickUpGroup,
      pickUpState,
    ]
  );

  const deliverToGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[deliverToState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>
      )),
    [addressesByState, deliverToState, t, addressesByGroup, country]
  );

  const deliverToAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[deliverToState], 'address_group')
          : addressesByGroup)[deliverToGroup] || []
      ).map((address: IAddress, i: number) => (
        <MenuItem key={i} value={address.id}>
          <div style={{ 'color': 'gray' }}>{address.company_name} &nbsp;</div> {formatAddress(address)}
        </MenuItem>
      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      deliverToGroup,
      deliverToState,
    ]
  );

  const returnToGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[returnToState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>

      )),
    [addressesByState, returnToState, t, addressesByGroup, country]
  );

  const returnToAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[returnToState], 'address_group')
          : addressesByGroup)[returnToGroup] || []
      ).map((address: IAddress, i: number) => (
        <MenuItem key={i} value={address.id}>
          <div style={{ 'color': 'gray' }}>{address.company_name} &nbsp;</div> {formatAddress(address)}
        </MenuItem>

      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      returnToGroup,
      returnToState,
    ]
  );

  const companyListOptions = useMemo(
    () =>
      company.list.map((company, i) => (
        <MenuItem key={i} value={company.id}>
          {company.companyName}
        </MenuItem>
      )),
    [company.list]
  );

  const transportCompanyListOptions = useMemo(
    () =>
      company.list.map(
        (company, i) =>
          company.companyType === 'transport_company' &&
          typeof company.user !== 'undefined' && (
            <MenuItem key={i} value={company.user.id}>
              {company.companyName}
            </MenuItem>
          )
      ),
    [company.list]
  );


  const validateForm = () => {
    if ((isSuperAdmin || isUserAdmin) && companyId === '') {
      toast.warning(t('company_not_empty'));
      return false;
    } else if (movementType === '') {
      toast.warn(t('movement_type_not_empty'));
      return false;

    } else if (tripType === '') {
      toast.warn(t('trip_type_not_empty'));
      return false;

    } else if (jobType === '') {
      toast.warn(t('job_type_not_empty'));
      return false;

    } else if (vehicleType === '') {
      toast.warn(t('vehicle_type_not_empty'));
      return false;

    } else if (pickUpAddressId === '') {
      toast.warn(t('pickup_address_not_empty'));
      return false;

    } else if (deliverToAddressId === '') {
      toast.warn(t('delivery_address_not_empty'));
      return false;
    }
  }
  const handleCreateOrUpdateClick = (email_list: IAutocompleteOption[]) => {

    if (validateForm() == false) return false

    const formData = new FormData();
    formData.append('email_list', JSON.stringify(email_list.map((opt: any) => opt)))

    formData.append('reference', reference);
    formData.append('customer_reference', customerReference);

    formData.append('company_id', companyId);
    formData.append('movement_type', movementType);
    formData.append('job_type', jobType);
    formData.append('vehicle_type', vehicleType);

    formData.append('pickup_address_id', pickUpAddressId);
    // if(pickUpDateTime){
    formData.append('pickup_date_time', moment(pickUpDateTime) as any);
    // }


    formData.append('additional_step_status', additionalStepStatus);

    if (addStepDatetime) {
      formData.append('additional_step_date', moment(addStepDatetime) as any);
    }
    if (addStepEndDatetime) {
      formData.append('additional_step_end_date', addStepEndDatetime as any);
    }
    formData.append('delivery_address_id', deliverToAddressId);


    formData.append('delivery_date_time', moment(deliveryDateTime) as any);

    // if (CNTRDehireDay && containerNo) {
    //   formData.append('cntr_dehire_day', moment(CNTRDehireDay) as any);
    // }
    if (tripType === ROUND_TRIP) {
      formData.append('return_address_id', returnToAddressId);
      formData.append('return_date_time', returnDateTime as any);
    }
    formData.append('trip_type', tripType);


    formData.append('lead_time', leadTime);

    formData.append('package_type', typeOfPackage);
    formData.append('quantity', quantity);
    formData.append('weight', declaredGrossWeight);
    formData.append('volume', volume);
    formData.append('margin_price', '0');

    formData.append('is_split_delivery', 'false');
    formData.append('is_fixed_date', 'true');
    // formData.append('temperature', '')
    formData.append('lcl_type', '');
    formData.append('job_classification', props.type);
    formData.append('instructions', jobRequirements);

    formData.append('selected_required_documents', JSON.stringify(selectedRequiredDocuments.map((opt: any) => opt)));

    formData.append(
      'associate_group_ids',
      JSON.stringify(selectedAssociateGroups.map((opt) => opt.value))
    );

    if (props.type === 'assigned-transport') {
      formData.append('transport_ref', transportRef);
      formData.append('transport_company_user_id', transportCompany);
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file);
    }

    if (setTypeOfPackageRef.current) {
      (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {
        formData.append('packages_type', JSON.stringify(data.map((opt: any) => opt)));
      });
    }

    if (mode === 'create') {
      formData.append('job_comments', JSON.stringify(jobCommentData));
    }
    if (mode == 'create') {
      quotesApi.create(formData).then((response) => {
        toast.success(t('success_creating_quote') + response.data.reference);
        dispatch(setLoader(false));
        history.push(
          routes.home.root +
          routes.home.quotes.list_quotes.root +
          routes.home.quotes.list_quotes.pending
        );
      }).catch((err: any) => {
        dispatch(setLoader(false));
        toast.error(t(err.response?.data || 'error_creating_quote'));
      })
    }
  };
  const handleGenerateJob = useCallback(
    (callback: any, obj: any) => {

      const formData = new FormData();
      formData.append('company_id', selectedQuote.company_id);
      formData.append('movement_type', selectedQuote.movement_type);
      formData.append('job_type', selectedQuote.job_type);
      formData.append('vehicle_type', selectedQuote.vehicle_type);
      formData.append('pickup_address_id', selectedQuote.pick_up_address.id)
      // formData.append('pickup_date_time', moment(pickUpDateTime) as any);

      formData.append('delivery_address_id', selectedQuote.deliver_to_address.id);
      // formData.append('delivery_date_time', moment(deliveryDateTime) as any);

      formData.append('additional_step_status', additionalStepStatus);

      if (addStepDatetime) {
        formData.append('additional_step_date', moment(addStepDatetime) as any);
      }
      if (tripType === ROUND_TRIP) {
        formData.append('return_address_id', selectedQuote.return_to_address.id);
        formData.append('return_date_time', returnDateTime as any);
      }
      formData.append('trip_type', selectedQuote.trip_type);


      formData.append('weight', selectedQuote.weight);

      formData.append('is_split_delivery', 'false');
      formData.append('is_fixed_date', 'true');
      formData.append('lcl_type', '');
      formData.append('job_classification', props.type);


      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        formData.append('files', file);
      }
      Object.entries(obj).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      formData.set('posted_price', parseFloat(selectedQuote.rates[0].sell_rate).toFixed(2))
      formData.set('initial_price', parseFloat(selectedQuote.rates[0].buy_rate).toFixed(2))
      if (setTypeOfPackageRef.current) {
        (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {
          formData.append("packages_type", JSON.stringify(data.map((opt: any) => opt)))
        });
      }
      dispatch(setLoader(true));

      jobApi.createAndAllocate(formData, selectedQuote.rates[0].assigned_user.id)
        .then(async (resp) => {
          await quotesApi.setQuoteAsGenerated(selectedQuote.id)
            .then((response) => {
              toast.success(t('job_generated'));
              callback()
            }).catch((err: any) => {
              console.log('err', err)
            })


            .catch((err: any) => {
              console.log('err', err)
            })

        })
        .catch((err: any) => {
          toast.error(t(err.response?.data || 'error_allocating_job'));
        });
      dispatch(setLoader(false));
    },
    [dispatch, selectedQuote],
  )

  useImperativeHandle(ref, () => ({
    handleGenerateJob: (callback: any) => {
      let data = {
        pickup_date_time: moment(pickUpDateTime) as any,
        delivery_date_time: moment(deliveryDateTime) as any,
        container_no: containerNo,
        cntr_last_day: moment(CNTRLastDay) as any,
        seal_number: sealNo,
        ec_release_no: emptyContainerReleaseNumber,
        wharf_slot: wharfSlot,
        reference: reference,
        instructions: jobRequirements,
        customer_reference: customerReference
      }

      // if (setTypeOfPackageRef.current) {
      //     (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {
      //       console.log('--------------', data)
      //       data["packages_type"]= JSON.stringify(data.map((opt: any) => opt))
      //     });
      // }

      handleGenerateJob(callback, data);
    },
  }));

  const isHidden = (countries: string[], element: string) => {

    if (countries.indexOf(country) > 1) {

      if ([QUANTITY, VOLUME, PACKAGES_TYPE].includes(element)) {
        // return false
        return ![
          // JOB_TYPES.FIRST_MILE,
          // JOB_TYPES.LAST_MILE,
          // JOB_TYPES.EMPTY_CONTAINER_RETURN,
          // JOB_TYPES.EMPTY_CONTAINER_COLLECTION,
          JOB_TYPES.IMPORT_FULL_CONTAINER,
          JOB_TYPES.EXPORT_FULL_CONTAINER,
          JOB_TYPES.DOMESTIC_FULL_CONTAINER,
          JOB_TYPES.EMPTY,
          // JOB_TYPES.EXPORT_CONTAINER_20,
          // JOB_TYPES.EXPORT_CONTAINER_40,
          // JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20,
          // JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40,
          // JOB_TYPES.RAIL,

        ].includes(jobType);

      } else if ([QUANTITY, PACKAGES_TYPE, VOLUME, ECRNUM, WS].includes(element)) {
        return false
      }
      else if ([CN, CNTR, SEAL_NO, ECRNUM, WS].includes(element)) {
        return ![
          JOB_TYPES.IMPORT_LOOSE_CARGO,
          JOB_TYPES.EXPORT_LOOSE_CARGO,
          JOB_TYPES.DOMESTIC_LOOSE_CARGO,
          JOB_TYPES.BREAK_BULK,


        ].includes(jobType);
        // return false
      }
      else if ([PACKAGES_TYPE, QUANTITY, VOLUME].includes(element)) {
        return false
      }
      // else if([PACKAGES_TYPE, QUANTITY , VOLUME].includes(element)){
      //   return false
      // }
      // else if ([SEAL_NO, CN, ECRNUM, WS , CNTR].includes(element)) {
      //   const GOT_LOOSE = [ MOVEMENT_TYPES.LOOSE_CARGO == movementType , JOB_TYPES.LOOSE_CARGO == jobType ]
      //   return !GOT_LOOSE.includes(true)
      // }
    }
    return false;
  };
  const handleAddNewAddress = useCallback(
    () => {
      setSelectedAddress(undefined)
      setIsCreateAddressDialogOpen(true)
      setSelectedAddressMode('create')
    },
    [],
  )
  const handleEditPickUpAddress = (parameter: any) => (event: any) => {
    setSelectedAddressMode('edit')

    if (selectedQuoteWholeData) {
      setSelectedAddress(selectedQuoteWholeData?.pick_up_address)

    } else {
      addressApi.byId(pickUpAddressId).then((response) => {
        const addresses = response.data;
        setSelectedAddress(addresses[0])
        dispatch(setLoader(false));
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });
    }
    setIsCreateAddressDialogOpen(true)

  }
  const handleEditDeliverAddress = (parameter: any) => (event: any) => {

    setSelectedAddressMode('edit')
    if (selectedQuoteWholeData) {
      setSelectedAddress(selectedQuoteWholeData?.deliver_to_address)
    } else {
      addressApi.byId(deliverToAddressId).then((response) => {
        const addresses = response.data;
        setSelectedAddress(addresses[0])
        dispatch(setLoader(false));
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });
    }

    setIsCreateAddressDialogOpen(true)

  }
  const handleEditReturnAddress = (parameter: any) => (event: any) => {
    if (selectedQuoteWholeData) {
      setSelectedAddress(selectedQuoteWholeData?.return_to_address)
    } else {
      addressApi.byId(returnToAddressId).then((response) => {
        const addresses = response.data;
        setSelectedAddress(addresses[0])
        dispatch(setLoader(false));
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });
    }

    setIsCreateAddressDialogOpen(true)

  }

  const onCommentDialogUpdate = (comment: any, id: any) => {
    let rawJobComment = jobCommentData
    rawJobComment[currentSelectedNoteUser] = {
      comment_id: '',
      job_id: '',
      is_admin: isSuperAdmin,
      comment: comment,
      user_id: currentuserId,
      company_id: currentUserCompanyId
    }
    setJobCommentData(rawJobComment)

  };
  const handleCommentDialogClose = useCallback(() => {
    setIsCommentDialogOpen(false);
  }, []);
  const handleCommentDialogOpen = (type: boolean) => {
    setIsCommentDialogOpen(true);
    setCurrentSelectedNoteUser(type == false ? 'user' : 'admin')
  }


  const handleSubmitSendEmail = useCallback(
    (email_list: IAutocompleteOption[]) => {
      handleCreateOrUpdateClick(email_list)
    },
    [allFormData],
  )

  const handleSendQuoteFormData = () => {
    if (validateForm() == false) { return false }
    const formData = new FormData();

    formData.append('reference', reference);
    formData.append('company_id', companyId);
    formData.append('movement_type', movementType);
    formData.append('job_type', jobType);
    formData.append('vehicle_type', vehicleType);

    formData.append('pickup_address_id', pickUpAddressId);
    // if(pickUpDateTime){
    formData.append('pickup_date_time', moment(pickUpDateTime) as any);
    // }


    formData.append('additional_step_status', additionalStepStatus);

    if (addStepDatetime) {
      formData.append('additional_step_date', moment(addStepDatetime) as any);
    }
    if (addStepEndDatetime) {
      formData.append('additional_step_end_date', addStepEndDatetime as any);
    }
    formData.append('delivery_address_id', deliverToAddressId);


    formData.append('delivery_date_time', moment(deliveryDateTime) as any);

    // if (CNTRDehireDay && containerNo) {
    //   formData.append('cntr_dehire_day', moment(CNTRDehireDay) as any);
    // }
    if (tripType === ROUND_TRIP) {
      formData.append('return_address_id', returnToAddressId);
    }
    formData.append('trip_type', tripType);


    formData.append('lead_time', leadTime);

    formData.append('package_type', typeOfPackage);
    formData.append('quantity', quantity);
    formData.append('weight', declaredGrossWeight);
    formData.append('volume', volume);
    formData.append('margin_price', '0');

    formData.append('is_split_delivery', 'false');
    formData.append('is_fixed_date', 'true');
    // formData.append('temperature', '')
    formData.append('lcl_type', '');
    formData.append('job_classification', props.type);
    formData.append('instructions', jobRequirements);

    formData.append('selected_required_documents', JSON.stringify(selectedRequiredDocuments.map((opt: any) => opt)));

    formData.append(
      'associate_group_ids',
      JSON.stringify(selectedAssociateGroups.map((opt) => opt.value))
    );

    if (props.type === 'assigned-transport') {
      formData.append('transport_ref', transportRef);
      formData.append('transport_company_user_id', transportCompany);
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file);
    }

    if (setTypeOfPackageRef.current) {
      (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {
        formData.append('packages_type', JSON.stringify(data.map((opt: any) => opt)));
      });
    }

    if (mode === 'create') {
      formData.append('job_comments', JSON.stringify(jobCommentData));
    }
    setFormData(formData)
  };
  const handleOnDownloadQouteDownload = useCallback((file) => {
    if (file) {
      dispatch(setLoader(true));
      quotesApi.downloadQuoteFile(file.id)
        .then((response) => {
          fileDownload(
            response.data,
            `${file.original_file_name}`,
          );
          dispatch(setLoader(false));
        })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_downloading_all_job_files'));
          dispatch(setLoader(false));
        });
    }
  }, [dispatch, t]);
  const createMode = () => {
    return mode == 'create'
  }
  return (
    <>
      {/* 
      <RequiredDocuments
        onSubmit={handleDocumentSelectionSubmit}
        open={isRequiredDialogOpen}
        handleRequiredDocumentClose={handleRequiredDocumentClose}
        handleDocumentCheckBoxChange={handleDocumentCheckBoxChange}
        selectedData={selectedRequiredDocuments}
        isAdmin={isSuperAdmin || isCompanyJobOwner || isUserAdmin}
      /> */}

      <CreateAddressDialog
        type={jobClassification}
        open={isCreateAddressDialogOpen}
        handleClose={handleDialogClose}
        onDone={onCreateAddressDone}
        address={selectedAddress}
        mode={selectedAddressMode}
        isCommon={false}
      />
      <EmailListDialog
        open={isSendEmailDialogOpen}
        handleClose={onEmailDialogClose}
        onDone={onEmailDialogClose}
        country={country}
        onSubmit={handleSubmitSendEmail}
        formData={allFormData}
        mode='create'
      />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={5}
      >
        {!readonly ? (
          <>
            <Grid md={12} item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
              >
                <Grid item>
                  <Button
                    fullWidth
                    onClick={handleAddNewAddress}
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                  >
                    {t('add_new_address')}
                  </Button>
                </Grid>
                {mode === 'create' ?
                  <Grid item style={{ float: 'right' }}>
                    <Tooltip title="Posting Notes">
                      <Button onClick={(e) => { handleCommentDialogOpen(false) }}> <Badge overlap="rectangular" variant="dot" invisible={true} color="primary">
                        <SpeakerNotesIcon color="action" />
                      </Badge></Button>
                    </Tooltip>

                  </Grid> :
                  null}
              </Grid>

            </Grid>

           

          </>

        ) : null}


        <CommentDialog
          onBlurUpdate={onCommentDialogUpdate}
          commentData={jobCommentData}
          open={isCommentDialogOpen}
          handleClose={handleCommentDialogClose}
          isEditable={false}
        />
        <Grid md item >

          <Grid container spacing={2}>

            <Grid item container md={12} spacing={1}>

              <Grid container item md={8}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('select_company') + '*'}
                  </InputLabel>
                  <Select
                    value={companyId}
                    disabled={!(mode == 'create')}
                    onChange={(e) => {
                      const companyId = e.target.value;
                      const selectedCompany = company.list.find(
                        (c) => c.id === companyId
                      );

                      if (selectedCompany) {
                        setCountry(selectedCompany.country);
                        getAddresses(companyId);
                        // setSelectedAssociateGroups([]);
                      }

                      setCompanyId(companyId);
                    }}
                  >
                    {companyListOptions}
                  </Select>

                </FormControl>

              </Grid>

              {/* {(isSuperAdmin || isUserAdmin) && !readonly ? (
                <Grid container item md={4}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('select_your_country')}
                    </InputLabel>
                    <Select
                      disabled={readonly || isSuperAdmin || isUserAdmin}
                      value={country}
                      onChange={handleCountryChange}
                    >
                      <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                      <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                      <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                      <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) : null} */}

            </Grid>

            <Grid item container md={12} spacing={1}>
              <Grid item md={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('movement_type') + '*'}
                    {/* {t('movement_type') + '*'} */}

                  </InputLabel>
                  <Select
                    disabled={readonly}
                    value={movementType}
                    onChange={(e) => handleMovementTypeChange(e.target.value as string)}
                  >
                    {movementTypes.map((movementType, i) => (
                      <MenuItem key={i} value={movementType}>
                        {t(movementType)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('trip_type') + '*'}
                  </InputLabel>
                  <Select
                    disabled={readonly}
                    value={tripType}
                    onChange={(e) => {
                      setTripType(e.target.value as string)
                    }}
                  >
                    <MenuItem value={ONE_WAY}>{t(ONE_WAY)}</MenuItem>
                    <MenuItem value={ROUND_TRIP}>{t(ROUND_TRIP)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container alignItems="center" md={12} spacing={1}>

              <Grid item md={6}>
                <TextField
                  disabled
                  value={reference}
                  onChange={(e) => setReference(e.target.value)}
                  fullWidth
                  label={mode == 'generate' ? t('reference_auto_gen') : t('quote_number')}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {(mode == 'edit' || mode == 'generate') ?
                <Grid item md={6}>
                  <TextField
                    value={customerReference}
                    onChange={(e) => setCustomerReference(e.target.value)}
                    fullWidth
                    label={t('customer_reference')}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                : null
              }

              <Grid item md={6}>
                {(mode == 'edit' || mode == 'generate' && files) ?
                  files.map(file => (
                    <Button
                      onClick={(e) => { handleOnDownloadQouteDownload(file) }}
                      startIcon={<GetAppIcon />}
                      variant="contained"

                    > {file.original_file_name} </Button>
                  ))



                  :
                  <FileInput
                    onChange={(e: any) => setFiles(e.target.files)}
                    disabled={readonly}
                  />
                }

              </Grid>

            </Grid>


            <Grid item container alignItems="center" md={12} spacing={1}>
              <Grid item md={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('job_type') + '*'}
                  </InputLabel>
                  <Select
                    disabled={readonly}
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value as string)}
                  >
                    {jobTypes.map((jobType, i) => (
                      <MenuItem key={i} value={jobType}>
                        {t(jobType)}
                      </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('vehicle_type') + '*'}
                  </InputLabel>
                  <Select
                    disabled={readonly}
                    value={vehicleType}
                    onChange={(e) => setVehicleType(e.target.value as string)}
                  >
                    {useMemo(
                      () =>
                        (
                          get(VEHICLE_TYPES_CONFIG, [
                            country,
                            movementType,
                            tripType,
                            jobType,
                          ]) ?? []
                        ).map((vehicleType: string, i) => (
                          <MenuItem key={i} value={vehicleType}>
                            {t(vehicleType)}
                          </MenuItem>
                        )),
                      [country, t, movementType, tripType, jobType]
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* Addresses */}

            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('pick_up_from') + '*'}
              </Typography>
            </Grid>
            {!readonly ? (
              <>
                {country === AUSTRALIA ? (
                  <Grid item md={12}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <InputLabel className={classes.selectLabel}>
                        {t('pickup_select_state')}
                      </InputLabel>
                      <Select
                        disabled={readonly}
                        value={pickUpState}
                        onChange={(e) => {
                          setPickUpState(e.target.value as any);
                          setPickUpGroup('');
                          setPickUpAddressId('');
                        }}
                      >
                        {stateOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item md={12}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('pickup_select_group')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={pickUpGroup}
                      onChange={(e) => {
                        setPickUpGroup(e.target.value as any);
                        setPickUpAddressId('');
                      }}
                    >
                      {pickUpGroupOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={10}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('pickup_select_address')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={pickUpAddressId}
                      onChange={(e) => {
                        setPickUpAddressId(e.target.value as any);
                        addressApi.byId(e.target.value as any).then((response) => {

                          setPickupInstruction(response.data[0]['instruction'] || null)
                        })
                          .catch((err: any) => {
                            console.log('err', err);
                            toast.error(t('error_getting_addresses'));
                            dispatch(setLoader(false));
                          });
                      }}

                    >
                      {pickUpAddressOptions}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={2}>
                  <FormControl variant="filled" className={classes.formControl}>

                    <Button
                      onClick={handleEditPickUpAddress(selectedQuoteWholeData)}
                      startIcon={<EditIcon />}
                      fullWidth

                      color="primary"
                      variant="contained"
                    >{t('edit')}</Button>

                  </FormControl>
                </Grid>
              </>
            ) : (
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  value={currentPickUpAddressString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {(mode == 'generate') ? (
              <Grid item md={12}>
                <Grid item md={12}>
                  <Typography variant="body1" gutterBottom>
                    {t('pick_up_date_time') + '*'}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <DateTimePicker
                    inputFormat='dd/MM/yyyy HH:mm'
                    ampm={false}
                    toolbarTitle={t('select_date_time')}
                    // disabled={!isAssignedUser}
                    renderInput={(props) => (
                      <TextField fullWidth variant="outlined" {...props} />
                    )}
                    value={pickUpDateTime}
                    onChange={setPickUpDateTime}
                  // minDate={dateNow}
                  />

                </Grid>
              </Grid>

            ) : null}
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('deliver_to') + '*'}
              </Typography>
            </Grid>
            {!readonly ? (
              <>
                {country === AUSTRALIA ? (
                  <Grid item md={12}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <InputLabel className={classes.selectLabel}>
                        {t('deliver_select_state')}
                      </InputLabel>
                      <Select
                        disabled={readonly}
                        value={deliverToState}
                        onChange={(e) => {
                          setDeliverToState(e.target.value as any);
                          setDeliverToGroup('');
                          setDeliverToAddressId('');
                        }}
                      >
                        {stateOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item md={12}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('deliver_select_group')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={deliverToGroup}
                      onChange={(e) => {
                        setDeliverToGroup(e.target.value as any);
                        setDeliverToAddressId('');
                      }}
                    >
                      {deliverToGroupOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={10}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('deliver_select_address')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={deliverToAddressId}
                      onChange={(e) => {
                        setDeliverToAddressId(e.target.value as any);
                        addressApi.byId(e.target.value as any).then((response) => {
                          setDeliverInstruction(response.data[0]['instruction'] || null)
                          setDeliverToStateType(response.data[0]['state_type'] || '')
                        })
                          .catch((err: any) => {
                            console.log('err', err);
                            toast.error(t('error_getting_addresses'));
                            dispatch(setLoader(false));
                          });
                      }}
                    >
                      {deliverToAddressOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl variant="filled" className={classes.formControl}>

                    <Button
                      onClick={handleEditDeliverAddress(selectedQuoteWholeData)}
                      startIcon={<EditIcon />}
                      fullWidth

                      color="primary"
                      variant="contained"
                    >{t('edit')}</Button>

                  </FormControl>
                </Grid>
              </>
            ) : (
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  value={currentDeliverToAddressString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

            )}
            {(mode == 'generate') ? (
              <Grid item md={12}>
                <Grid item md={12}>
                  <Typography variant="body1" gutterBottom>
                    {t('deliver_to_date_time') + '*'}
                  </Typography>
                </Grid>
                <Grid item md={12}>

                  <DateTimePicker
                    inputFormat='dd/MM/yyyy HH:mm'
                    toolbarTitle={t('select_date_time')}
                    // disabled={readonly}
                    renderInput={(props) => (
                      <TextField fullWidth variant="outlined" {...props} />
                    )}
                    ampm={false}
                    value={deliveryDateTime}
                    onChange={setDeliveryDateTime}
                    minDateTime={pickUpDateTime}
                  />
                </Grid>
              </Grid>
            )
              : null}
            {/* <Grid item container md={12} >
              <Grid item md={6}  >
                <Grid item container direction='column' md={12} spacing={2}>
                  <Grid item md={6}  >
                    <Typography variant="body1" gutterBottom>
                      {t('pick_up_from') + '*'}
                    </Typography>
                  </Grid>
                  {!readonly ? (
                    <>
                      {country === AUSTRALIA ? (
                        <Grid item md={6}>
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                          >
                            <InputLabel className={classes.selectLabel}>
                              {t('pickup_select_state')}
                            </InputLabel>
                            <Select
                              disabled={readonly}
                              value={pickUpState}
                              onChange={(e) => {
                                setPickUpState(e.target.value as any);
                                setPickUpGroup('');
                                setPickUpAddressId('');
                              }}
                            >
                              {stateOptions}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : null}

                      <Grid item md={6}>
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel className={classes.selectLabel}>
                            {t('pickup_select_group')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={pickUpGroup}
                            onChange={(e) => {
                              setPickUpGroup(e.target.value as any);
                              setPickUpAddressId('');
                            }}
                          >
                            {pickUpGroupOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel className={classes.selectLabel}>
                            {t('pickup_select_address')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={pickUpAddressId}
                            className={classes.addressSelect}
                            onChange={(e) => {
                              setPickUpAddressId(e.target.value as any);
                              addressApi.byId(e.target.value as any).then((response) => {

                                setPickupInstruction(response.data[0]['instruction'] || null)
                              })
                                .catch((err: any) => {
                                  console.log('err', err);
                                  toast.error(t('error_getting_addresses'));
                                  dispatch(setLoader(false));
                                });
                            }}

                          >
                            {pickUpAddressOptions}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={2}>
                        <FormControl variant="filled" className={classes.formControl}>

                          <Button
                            onClick={handleEditPickUpAddress(selectedQuoteWholeData)}
                            startIcon={<EditIcon />}
                            fullWidth

                            color="primary"
                            variant="contained"
                          >{t('edit')}</Button>

                        </FormControl>
                      </Grid>


                    </>
                  ) : (
                    <Grid item md={12}>
                      <Grid item>

                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled={true}
                          value={currentPickUpAddressString}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <LocationOnIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <Typography variant="body1" gutterBottom>
                          {t('pick_up_date_time') + '*'}
                        </Typography>
                        <Grid item md={12}>
                          {(mode == 'generate') ? (
                            <MobileDateTimePicker
                              inputFormat='dd/MM/yyyy HH:mm'
                              ampm={false}
                              toolbarTitle={t('select_date_time')}
                              renderInput={(props) => (
                                <TextField fullWidth variant="outlined" {...props} />
                              )}
                              value={pickUpDateTime}
                              onChange={setPickUpDateTime}
                            // minDate={dateNow}
                            />
                          ) : (
                           null
                          )}
                        </Grid>
                      </Grid>

                    </Grid>
                  )}
                </Grid>
              </Grid>


              <Grid item md={6}  >
                <Grid item container direction='column' md={12} spacing={2}>

                  <Grid item container md={6}>
                    <Typography variant="body1" gutterBottom>
                      {t('deliver_to') + '*'}
                    </Typography>
                  </Grid>

                  {!readonly ? (
                    <>
                      {country === AUSTRALIA ? (
                        <Grid item md={6}>
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                          >
                            <InputLabel className={classes.selectLabel}>
                              {t('deliver_select_state')}
                            </InputLabel>
                            <Select
                              disabled={readonly}
                              value={deliverToState}
                              onChange={(e) => {
                                setDeliverToState(e.target.value as any);
                                setDeliverToGroup('');
                                setDeliverToAddressId('');
                              }}
                            >
                              {stateOptions}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : null}
                      <Grid item md={6}>
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel className={classes.selectLabel}>
                            {t('deliver_select_group')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={deliverToGroup}
                            onChange={(e) => {
                              setDeliverToGroup(e.target.value as any);
                              setDeliverToAddressId('');
                            }}
                          >
                            {deliverToGroupOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={12}>
                        <FormControl variant="filled" className={classes.formControl}>
                          <InputLabel className={classes.selectLabel}>
                            {t('deliver_select_address')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={deliverToAddressId}
                            className={classes.addressSelect}

                            onChange={(e) => {
                              setDeliverToAddressId(e.target.value as any);
                              addressApi.byId(e.target.value as any).then((response) => {
                                setDeliverInstruction(response.data[0]['instruction'] || null)
                                setDeliverToStateType(response.data[0]['state_type'] || '')
                              })
                                .catch((err: any) => {
                                  console.log('err', err);
                                  toast.error(t('error_getting_addresses'));
                                  dispatch(setLoader(false));
                                });
                            }}
                          >
                            {deliverToAddressOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={2}>
                        <FormControl variant="filled" className={classes.formControl}>

                          <Button
                            onClick={handleEditDeliverAddress(selectedQuoteWholeData)}
                            startIcon={<EditIcon />}
                            fullWidth

                            color="primary"
                            variant="contained"
                          >{t('edit')}</Button>

                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={12}>
                      <Grid item>
                        <TextField
                          variant="outlined"
                          fullWidth
                          disabled={true}
                          value={currentDeliverToAddressString}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <LocationOnIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item md={8}>
                        <Typography variant="body1" gutterBottom>
                          {t('deliver_to_date_time') + '*'}
                        </Typography>
                        <Grid item md={12}>
                          {(mode == 'generate') ? (
                            <MobileDateTimePicker
                              inputFormat='dd/MM/yyyy HH:mm'
                              ampm={false}
                              toolbarTitle={t('select_date_time')}
                              renderInput={(props) => (
                                <TextField fullWidth variant="outlined" {...props} />
                              )}
                              value={deliveryDateTime}
                              onChange={setDeliveryDateTime}
                              minDateTime={pickUpDateTime}
                            />
                          ) : (
                            <TextField
                              disabled={true}
                              value={formatDateTimeDisplay(pickUpDateTime)}
                              fullWidth
                              variant="outlined"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <TodayIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                  )}


                </Grid>
              </Grid>

            </Grid> */}
            <Grid item container alignItems="center" md={12} spacing={1}>
              <Grid item md={3}>
                <TextField
                  disabled={readonly}
                  value={leadTime}
                  onChange={(e) => {
                    const value = e.target.value;
                    setLeadTime(value);
                  }}
                  fullWidth
                  label={t('lead_time')}
                  type="number"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container alignItems="center" md={12} spacing={1}>

              {tripType === ROUND_TRIP ? (
                <>
                  <Grid item md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t('return_to') + '*'}
                    </Typography>
                  </Grid>
                  {!readonly ? (
                    <>
                      {country === AUSTRALIA ? (
                        <Grid item md={12}>
                          <FormControl
                            variant="filled"
                            className={classes.formControl}
                          >
                            <InputLabel className={classes.selectLabel}>
                              {t('return_select_state')}
                            </InputLabel>
                            <Select
                              disabled={readonly}
                              value={returnToState}
                              onChange={(e) => {
                                setReturnToState(e.target.value as any);
                                setReturnToGroup('');
                                setReturnToAddressId('');
                              }}
                            >
                              {stateOptions}
                            </Select>
                          </FormControl>
                        </Grid>
                      ) : null}
                      <Grid item md={12}>
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                        >
                          <InputLabel className={classes.selectLabel}>
                            {t('return_select_group')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={returnToGroup}
                            onChange={(e) => {
                              setReturnToGroup(e.target.value as any);
                              setReturnToAddressId('');
                            }}
                          >
                            {returnToGroupOptions}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={10}>
                        <FormControl variant="filled" className={classes.formControl} >
                          <InputLabel className={classes.selectLabel}>
                            {t('return_select_address')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={returnToAddressId}
                            onChange={(e) => {
                              setReturnToAddressId(e.target.value as any);
                            }}
                          >
                            {returnToAddressOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item md={2}>
                        <FormControl variant="filled" className={classes.formControl}>

                          <Button
                            onClick={handleEditReturnAddress(selectedQuoteWholeData)}
                            startIcon={<EditIcon />}
                            fullWidth

                            color="primary"
                            variant="contained"
                          >{t('edit')}</Button>

                        </FormControl>
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={12}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        disabled={true}
                        value={currentReturnToAddressString}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </>
              ) : null}
            </Grid>
            <Grid item container alignItems="center" md={12} spacing={1}>

              {isHidden([SINGAPORE, JAPAN, PHILIPPINES], PACKAGES_TYPE) && country !== AUSTRALIA ? (
                <Grid item md={12}>

                  <MultiplePackageType
                    ref={setTypeOfPackageRef}
                    readonly={false}
                    movementType={movementType}
                    packageList={typeOfPackageList}
                  />
                </Grid>
              ) : country === AUSTRALIA &&
                ![FULL_TRUCK_LOAD, EMPTY_PARK, RAIL, WHARF].includes(
                  movementType
                ) ? (
                <Grid item md={12}>

                  <MultiplePackageType
                    ref={setTypeOfPackageRef}
                    readonly={false}
                    movementType={movementType}
                    packageList={typeOfPackageList}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Grid item container alignItems="center" md={12} spacing={1}>

              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={declaredGrossWeight}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNumeric(value) || value === '')
                      setDeclaredGrossWeight(value);
                  }}
                  fullWidth
                  label={t('declared_gross_weight') + '*'}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {isHidden([SINGAPORE, JAPAN, PHILIPPINES], VOLUME) && country !== AUSTRALIA ? (
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={volume}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isNumeric(value) || value === '') setVolume(value);
                    }}
                    fullWidth
                    label={
                      t(VOLUME) +
                      (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                        ? '*'
                        : '')
                    }
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : country === AUSTRALIA &&
                ![FULL_TRUCK_LOAD, EMPTY_PARK, RAIL, WHARF].includes(
                  movementType
                ) ? (
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={volume}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (isNumeric(value) || value === '') setVolume(value);
                    }}
                    fullWidth
                    label={
                      t(VOLUME) +
                      (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                        ? '*'
                        : '')
                    }
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : null}
            </Grid>
            {
              ([EMPTY_PARK, FULL_TRUCK_LOAD, WHARF, RAIL].includes(movementType)) && mode == 'generate' ? (
                <Grid item md={6}>
                  <TextField
                    value={containerNo}
                    onChange={(e: any) => {
                      setContainerNo(e.target.value)
                    }}
                    fullWidth
                    label={t(CN)}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                </Grid>
              )
                : null
            }
            {
              ([EMPTY_PARK, FULL_TRUCK_LOAD, WHARF, RAIL].includes(movementType)) && mode == 'generate' ? (
                <Grid item md={6}>
                  <TextField
                    value={sealNo}
                    onChange={(e) => setSealNo(e.target.value)}
                    fullWidth
                    label={t(SEAL_NO)}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )
                : null}
            {
              ([EMPTY_PARK, FULL_TRUCK_LOAD, WHARF, RAIL].includes(movementType)) && mode == 'generate' ? (
                <Grid item md={6}>
                  <MobileDateTimePicker
                    inputFormat='dd/MM/yyyy HH:mm'
                    ampm={false}
                    toolbarTitle={"DT 1"}
                    renderInput={(props) => (
                      <TextField fullWidth variant="outlined" {...props} />
                    )}
                    label={t(CNTR)}
                    value={CNTRLastDay}
                    onChange={setCNTRLastDay}
                  />
                </Grid>
              )
                : null}
            {
              ([EMPTY_PARK, FULL_TRUCK_LOAD, WHARF, RAIL].includes(movementType)) && mode == 'generate' ? (

                <Grid item md={6}>
                  <TextField
                    value={emptyContainerReleaseNumber}
                    onChange={(e) => setEmptyContainerReleaseNumber(e.target.value)}
                    fullWidth
                    label={t(ECRNUM)}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )
                : null}
            {
              ([EMPTY_PARK, FULL_TRUCK_LOAD, WHARF, RAIL].includes(movementType)) && mode == 'generate' ? (
                <Grid item md={6}>
                  <TextField
                    value={wharfSlot}
                    onChange={(e) => setWharfSlot(e.target.value)}
                    fullWidth
                    label={t(WS)}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )
                : null}

            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('Requirements')}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {(!readonly) ?
                <TextareaAutosize
                  disabled={readonly}
                  value={jobRequirements}
                  onChange={(e) => setJobRequirements(e.target.value)}
                  className={classes.textArea}
                  minRows={5}
                />
                : <TextareaAutosize
                  disabled={readonly}
                  value={jobRequirements}
                  placeholder={mergedInstruction}
                  className={classes.textArea}
                  minRows={5}
                />}

            </Grid>
            {
              mode == 'create' ?
                <Grid md={2} item>
                  <Button onClick={handleEmailDialogOpen}
                    fullWidth
                    className={classes.btnCreate}
                    startIcon={<DoneIcon />}
                    color="primary"
                    // variant="contained"
                    type='button'
                  >
                    {t('send_quotes')}
                  </Button>
                </Grid>
                : null
            }




          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default forwardRef(QuotesDetails);