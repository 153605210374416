import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { routes } from '../../../routes';
import CreateNewJob from '../Jobs/CreateNewJob/CreateNewJob';
import JobReport from '../Jobs/JobReport/JobReport';
import JobReportMileage from '../Jobs/JobReportMileage/JobReportMileage';
import { JOBS_WILDCARDS_KEYS } from '../Jobs/Jobs.constans';
import { QUOTES_WILDCARDS_KEYS } from '../Quotes/Quotes.constants';

import ShowJobs from '../Jobs/ShowJobs/ShowJobs';
import ShowJobsLazy from '../Jobs/ShowJobs/ShowJobsLazy';
import ShowQuotesLazy from '../Quotes/ShowQuotes/ShowQuotesLazy'

import MapViewV2 from '../MapsV2/Maps';

function TransportManagement() {
  const match = useRouteMatch();
  return (
    <React.Fragment>
      {/* /home/transport/job/create */}
      <Route path={match.path + routes.home.jobs.create_new_job}>
        <CreateNewJob type="transport" mode="create" />
      </Route>
      {/* /home/transport/create/job/assigned */}
      <Route path={match.path + routes.home.jobs.create_new_assigned_job}>
        <CreateNewJob type="assigned-transport" mode="create" />
      </Route>
      {/* /home/transport/job_report */}
      <Route path={match.path + routes.home.jobs.job_report}>
        <JobReport type="transport" />
      </Route>
      {/* /home/transport/completed_jobs_mileage */}
      <Route path={match.path + routes.home.jobs.job_report_mileage}>
        <JobReportMileage type="transport" />
      </Route>
      {/* /home/transport/show/jobs/:showType */}
      <Route
        path={
          match.path +
          routes.home.jobs.show_jobs.root +
          `/:${JOBS_WILDCARDS_KEYS.SHOW_TYPE}`
        }
      >
        <ShowJobsLazy type="transport" />
      </Route>
      {/* /home/transport/map-view */}
      <Route path={match.path + routes.home.overview}>
        <MapViewV2 type="transport" />
      </Route>

    </React.Fragment>
  );
}

export default TransportManagement;
