import { Typography } from '@material-ui/core';
import { GridColumns } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { OVERDUE_CSV_COLUMNS } from './columns';
import { IUserState } from '../../../../Redux/User/type';
import { formatDateTimeDisplayDD } from '../../../../Utilities/DateTime';

export const getOverdueCollectionsReportColumns = (
  t: TFunction,
  user: IUserState
): GridColumns => {
  return [
    {
      field: 'reference',
      headerName: t(`Columns:${OVERDUE_CSV_COLUMNS.REFERENCE}`),
      width: 200,
      renderCell: (params) => {
        const job = params.row;
        const reference: string = String(
          job.reference || 'No reference'
        );
        return <Typography>{reference}</Typography>;
      },
      resizable: true,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        const job = params.row;
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'company',
      headerName: t('Columns:company'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const job = params.row;
        const user = `${job.user?.first_name} ${job.user?.last_name}`;
        const company: string = String(job.company?.name || user);
        return <Typography>{company}</Typography>;
      },
    },
    {
      field: 'due_collection_date',
      headerName: t(`Columns:${OVERDUE_CSV_COLUMNS.DUE_COLLECTION_DATE}`),
      width: 220,
      renderCell: (params) => {
        const job = params.row;
        if (job) {
          const collection_date: string = String(
            job.pickup_date_time
          );
          return <Typography>{formatDateTimeDisplayDD(collection_date)}</Typography>;
        } else {
          return <Typography>None</Typography>;
        }
      },
    },
    {
      field: 'transport_company',
      headerName: t('Columns:transport_company'),
      width: 250,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {params.row.assigned_user?.company?.name}
          </Typography>
        )

      }
    },
    {
      field: 'transport_driver',
      headerName: t('Columns:driver'),
      width: 250,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {params.row.assigned_user ? params.row.assigned_user?.first_name || '' + " " + params.row.assigned_user?.last_name || '' : ''}
          </Typography>
        )
      }
    },
    {
      field: 'transport_reference',
      headerName: t('Columns:transport_reference'),
      width: 300,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {job.transport_reference}
          </Typography>
        )
      }
    },
    {
      field: 'movement_type',
      headerName: t('Columns:movement_type'),
      width: 200,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {t(job.movement_type?.code)}
          </Typography>
        )
      }
    },
    {
      field: 'collection_city',
      headerName: t('Columns:collection_city'),
      width: 200,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {job.pickup_address?.city}
          </Typography>
        )
      }
    },

  ];
};
