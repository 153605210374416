import { Grid, makeStyles, Box, styled, Paper, Typography, Breadcrumbs, Link, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import JobDataPaper from '../../Common/JobDataPaper/JobDataPaper'
import GoogleMap from 'google-map-react'
import AggregatedJobsLineChart from '../../Common/AggregatedJobsLineChart/AggregatedJobsLineChart'
import DonutGraph from '../../Common/DonutGraph/DonutGraph'
import DriverRatingTable from '../../Common/DriverRatingTable/DriverRatingTable'
import CompanyRankingTable from '../../Common/CompanyRankingTable/CompanyRankingTable'
import AggregatedJobTable from '../../Common/AggregatedJobTable/AggregateJobTable'
import DailyMovementTable from '../../Common/DailyJobsMovementTable/DailyJobsMovementTable'
import { Job } from '../../../Api/Job/Job'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '../../../Redux/Loader/actions'
import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants'
import { toast } from 'react-toastify'
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types'
import { IJobsWithTypes } from '../Jobs/Jobs.types'
import { AppState } from '../../../Redux'
import PieChart from '../../Common/PieChart/PieChart'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import UpcomingJobs from './UpcomingJobs'
import RerouteCard from './RerouteCards'
import MapViewV2 from '../MapsV2/Maps'
import { AdminSettings } from "../../../Api/AdminSettings/AdminSettings"

const BOX_HEIGHT = 45

const useStyles = makeStyles(() => ({
    mapBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    formControl: {
        minWidth: '100%',
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    graphBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    inbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    label: {
        margin: '0px',
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: ' rgb(108, 115, 127)'
    },
    count: {
        fontWeight: 'bold',
        margin: '30px 0',
        fontSize: '2rem',
        lineHeight: '1.2'
    },
    breadCrumbs: {
        float: 'right'
    },
    active: {
        fontWeight: 'bold'
    },
    card: {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
        margin: '16px',
        // textAlign: 'center'
    },

}))
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    color: theme.palette.text.secondary,
}));
const jobApi = new Job()

const {
    COMPLETED,
    POSTED,
    IN_PROGRESS,
    CREATED
} = JOB_STATUS

const {
    ADMIN_USER,
    SUPER_ADMIN,
    CUSTOMER
} = USER_ROLES

function HomeDashboard() {
    const [t] = useTranslation('Dashboard')
    const adminSettingsApi = new AdminSettings()
    const [notifications, setNotifications] = useState([]);

    const dispatch = useDispatch()
    const classes = useStyles()
    const user = useSelector((state: AppState) => state.user)
    let company: any = useSelector((state: AppState) => state.company);
    const [jobsThisWeek, setJobsThisWeek] = useState<any>(0)
    const [baseValue, setbaseValue] = useState<any>({})

    const isAdminUser = user.response?.type === ADMIN_USER
    const isCustomer = user.response?.type === CUSTOMER


    const [activeDataWeek, setActiveDataWeek] = useState<any>('week')

    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user.response?.type)
    const [companyId, setCompanyId] = useState<any>('');


    let companyListOptions = useMemo(() =>
        company.list.map((company, i) => (
            <MenuItem key={i} value={company.id}>
                {company.companyName}
            </MenuItem>
        )),
        [company.list]
    );
    let cardsData = [
        { title: 'Overdue Collections', hidden: isCustomer, key: 'overdueCollections', description: 'List of all overdue collections', url: '/home/report/collections' },
        { title: 'Overdue Deliveries', hidden: isCustomer, key: 'overdueDelivery', description: 'List of all overdue deliveries', url: '/home/report/deliveries' },
        { title: 'No POD Documents', hidden: isCustomer, key: 'jobsWithoutPods', description: 'List of all delivered jobs without a POD', url: '/home/report/no_pod_docs' },
        { title: 'Scheduled Collections', hidden: isCustomer, key: 'collection', description: 'Report of upcoming collection', url: '/home/report/scheduled_collection_report' },
        { title: 'Scheduled Deliveries', hidden: false, key: 'delivery', description: 'Report of upcoming deliveries', url: '/home/report/scheduled_delivery_report' },
        { title: 'Completed Jobs', hidden: false, key: 'completed', description: 'List of all completed jobs', url: '/home/report/completed_jobs_report' },
    ];
    const getData = () => {
        dispatch(setLoader(true))
        jobApi.getUpcomingJobs(activeDataWeek, companyId)
            .then((response) => {
                const counts = response.data
                setJobsThisWeek(counts.overall)
                setbaseValue(counts)
                dispatch(setLoader(false))
            })
            .catch((err: any) => {
                dispatch(setLoader(false))
                toast.error(t('error_getting_counts'))
            })
    }
    useEffect(() => {
        if (!isUserAdmin) {
            setCompanyId(user.response?.company.id || '')
        }
        getData()
        // getNotifications()
    }, [dispatch, t, activeDataWeek])

    function hashCode(s) {
        let hash = 0;
        for (let i = 0; i < s.length; i++) {
            const char = s.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }
    const getNotifications = async () => {
        const toastKey = 'notificationsHash';
        try {
            await adminSettingsApi.getNotifications().then((response: any) => {
                const notifications = response.data;
                const notificationsString = JSON.stringify(notifications);
                // const currentHash = hashCode(notificationsString);

                // const storedHash = sessionStorage.getItem(toastKey);

                if (notifications.length > 0) {
                    toast.info(
                        <ul>
                            {notifications.map(notification => (
                                <li key={notification.id} >
                                    {notification.message}
                                </li>
                            ))}
                        </ul>,
                        {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined
                        }
                    );
                }
                // if (storedHash !== currentHash.toString()) {


                //     sessionStorage.setItem(toastKey, currentHash.toString());
                // }
            })
                .catch(error => {
                    console.error('Error fetching notifications:', error);
                });
        } catch (error) {
            console.error('Error fetching notification settings:', error);
        }
    };


    // const breadClick = (e, pieType: string) => {
    //     e.preventDefault()
    //     getPieData(pieType)
    //     setActiveData(pieType)
    // }

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        setActiveDataWeek(newAlignment);
    };

    function getNotificationColor(type) {
        switch (type) {
            case 'info':
                return 'blue';
            case 'warning':
                return 'orange';
            case 'error':
                return 'red';
            case 'success':
                return 'green';
            default:
                return 'black';
        }
    }
    // {notifications.length > 0 && (
    //     <Grid xs={12} item>
    //         <h2>Notifications:</h2>
    //         <ul>
    //             {notifications.map(notification => (
    //                 <li key={notification.id} style={{ color: getNotificationColor(notification.type) }}>
    //                     {notification.message}
    //                 </li>
    //             ))}
    //         </ul>
    //     </Grid>
    // )}
    return (
        <>
            <Grid
                container
                spacing={3}
            >
                <Grid container spacing={2}>
                    <Grid item md={2}>
                        <div className="container">
                            <Grid item xs={12}>
                                <div className="container">
                                    <UpcomingJobs jobsThisWeek={jobsThisWeek} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {cardsData.map((card, index) => (
                                    !card.hidden && (
                                        <RerouteCard
                                            key={index}
                                            title={card.title}
                                            description={card.description}
                                            url={card.url}
                                            value={baseValue[card.key]}
                                        />
                                    )
                                ))}

                            </Grid>
                        </div>
                    </Grid>
                    <Grid item md={10}>
                        <Grid className={classes.card}>
                            <MapViewV2 type="transport" />

                        </Grid>
                    </Grid>

                </Grid>



            </Grid>
        </>
    )
}

export default HomeDashboard