import { Dialog, DialogTitle, DialogContent, Grid, makeStyles, Typography, FormControlLabel, Checkbox, DialogActions, Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { IWaitingForApprovalDialogProps } from "./WaitingForApprovalDialog.types";
import no_image from '../../../Images/no_image.png'
import { Driver } from "../../../Api/Driver/Driver";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { formatFileUrl } from "../../../Utilities/Formatting";
import ImagePreviewDialog from '../ImagePreviewDialog/ImagePreviewDialog'
import TextAreaInputDialog from "../TextAreaInputDialog/TextAreaInputDialog";
import { getLabelForRegistrationNumberByCountry } from '../../../Utilities/Country'
import { COUNTRY_INSURANCES } from "../../../Types/Constants";

const useStyles = makeStyles(() => ({
    bold: {
        fontWeight: 'bolder'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    approve: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    reject: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    }
}))

const driverApi = new Driver()

function WaitingForApprovalDialog(props: IWaitingForApprovalDialogProps) {
    const { selectedUserId, open, handleClose } = props
    const [t] = useTranslation('WaitingForApprovalDialog')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [userInfo, setUserInfo] = useState<IUserInfo | null>(null)
    const [isWorkerComp, setIsWorkerComp] = useState(false)
    const [isMotorVehicle, setIsMotorVehicle] = useState(false)
    const [isPublicLiability, setPublicLiability] = useState(false)
    const [isTransit, setIsTransit] = useState(false)
    const [insurances, setInsurances] = useState<string[]>([])
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false)
    const [isReasonDialogOpen, setIsReasonDialogOpen] = useState(false)
    const [imageToPreview, setImageToPreview] = useState<string | undefined>(undefined)

    const handleImagePreviewClose = useCallback(
        () => {
            setIsImagePreviewOpen(false)
        },
        [],
    )

    const handleTextAreaDialogClose = useCallback(
        () => {
            setIsReasonDialogOpen(false)
        },
        [],
    )

    const getDriverDetails = useCallback(
        (userId: string | number) => {
            dispatch(setLoader(true))
            driverApi.getDetails(userId)
                .then((response) => {
                    const driverDetails: IUserInfo = response.data
                    setUserInfo(driverDetails)
                    setInsurances(driverDetails.driver?.insurances ?? [])
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_getting_driver_details'))
                })
        },
        [dispatch, t],
    )

    useEffect(() => {
        if (selectedUserId) {
            getDriverDetails(selectedUserId)
        }

    }, [selectedUserId, getDriverDetails])

    const handleOnImageSelect = useCallback(
        (imageSource: string) => {
            setImageToPreview(imageSource)
            setIsImagePreviewOpen(true)
        },
        [],
    )

    const handleOnRejectClick = useCallback(
        () => {
            if (selectedUserId) {
                setIsReasonDialogOpen(true)
            }
        },
        [selectedUserId],
    )

    const handleOnOkClick = useCallback(
        (reasonForRejection: string) => {
            if (selectedUserId) {
                dispatch(setLoader(true))
                driverApi.reject(selectedUserId, reasonForRejection)
                    .then(() => {
                        dispatch(setLoader(false))
                        handleClose()
                    })
                    .catch(err => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_rejecting_driver'))
                    })
            }
        },
        [dispatch, t, handleClose, selectedUserId],
    )

    const handleOnApproveClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))
                driverApi.approve(selectedUserId)
                    .then(() => {
                        dispatch(setLoader(false))
                        handleClose()
                        toast.success(t('success_approving_rider'))
                    })
                    .catch(err => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_approving_driver'))
                    })
            }
        },
        [dispatch, t, selectedUserId, handleClose]
    )
    
    const INSURANCES = COUNTRY_INSURANCES[userInfo?.driver?.country ?? ''] ?? []
    
    return (
        <>
            <TextAreaInputDialog onOKCallback={handleOnOkClick} header={t('reason_for_rejection')} open={isReasonDialogOpen} handleClose={handleTextAreaDialogClose} />
            <ImagePreviewDialog imageSource={imageToPreview} open={isImagePreviewOpen} handleClose={handleImagePreviewClose} />
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    {t('driver_details')}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('email')}
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <Typography>
                                {userInfo?.email}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('first_name')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.firstName}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('last_name')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.lastName}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('country')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {t(userInfo?.driver?.country || '')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('mobile')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.mobile}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('street_address')}
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <Typography>
                                {userInfo?.driver?.street_num}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('city')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.city}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('postcode')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.postcode}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('company_name')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.company_name}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t(getLabelForRegistrationNumberByCountry(userInfo?.driver?.country || ''))}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.company_uen}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('truck_registration')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.truck_registration}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('trailer_registration')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.trailer_registration}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('vehicle_type')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {t(userInfo?.driver?.vehicle_type || '')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('wait_time_after')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.wait_time_after}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('wait_time_charge')}
                            </Typography>
                        </Grid>
                        <Grid item md={3}>
                            <Typography>
                                {userInfo?.driver?.wait_time_charge}
                            </Typography>
                        </Grid>
                        <Grid md={12} item>
                            <Typography className={classes.bold} align="left" variant="body1">
                                {t('insurance_coverage')}
                            </Typography>
                        </Grid>
                        {INSURANCES.map((insurance, i) => (
                            <Grid key={i} md={(i % 2) === 0 ? 3 : 9} item>
                                <FormControlLabel
                                    control={<Checkbox checked={insurances.includes(insurance)} color="primary" />}
                                    label={t(insurance)}
                                />
                            </Grid>
                        ))}
                        <Grid item md={12}></Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('photo')}
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <img
                                alt={t('photo')}
                                src={userInfo?.driver?.driver_photo_file ? formatFileUrl(userInfo?.driver?.driver_photo_file) : no_image}
                                className={classes.image}
                                onClick={() => {
                                    if (userInfo?.driver?.driver_photo_file) {
                                        handleOnImageSelect(formatFileUrl(userInfo?.driver?.driver_photo_file))
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Typography className={classes.bold}>
                                {t('license')}
                            </Typography>
                        </Grid>
                        <Grid item md={9}>
                            <img
                                alt={t('license')}
                                src={userInfo?.driver?.driver_license_file ? formatFileUrl(userInfo?.driver?.driver_license_file) : no_image}
                                className={classes.image}
                                onClick={() => {
                                    if (userInfo?.driver?.driver_license_file) {
                                        handleOnImageSelect(formatFileUrl(userInfo?.driver?.driver_license_file))
                                    }
                                }}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        spacing={2}
                        container
                        justifyContent="flex-end"
                    >
                        <Grid item md={3}>
                            <Button onClick={handleOnApproveClick} fullWidth className={classes.approve} variant="contained">
                                {t('approve')}
                            </Button>
                        </Grid>
                        <Grid item md={3}>
                            <Button onClick={handleOnRejectClick} fullWidth className={classes.reject} variant="contained">
                                {t('reject')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default WaitingForApprovalDialog