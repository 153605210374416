import { Button, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { IStatistics } from '../../../../../../types/global'
import { Job } from '../../../../Api/Job/Job'
import { UsersApi } from '../../../../Api/Users/Users'
import { AppState } from '../../../../Redux'
import { setLoader } from '../../../../Redux/Loader/actions'
import { BID_STATUS, JOB_STATUS, USER_ROLES } from '../../../../Types/Constants'
import StatisticsCountTable from '../../../Common/StatisticsCountTable/StatisticsCountTable'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

const {
    ADMIN_USER,
} = USER_ROLES

const {
    CREATED,
    IN_PROGRESS,
    COMPLETED
} = JOB_STATUS

const {
    PENDING
} = BID_STATUS

const userApi = new UsersApi()

const jobApi = new Job()

function Statistics() {
    const [t] = useTranslation('Statistics')
    const dispatch = useDispatch()
    const user = useSelector((state: AppState) => state.user)
    const isUserAdmin = user.response?.type === ADMIN_USER
    const adminUserCountry = isUserAdmin ? user.response?.country : undefined

    const [newRegistrationsStats, setNewRegistrationsStats] = useState<IStatistics | null>(null)
    const [newJobStats, setNewJobStats] = useState<IStatistics | null>(null)
    const [numOfBidsStats, setNumOfBidsStats] = useState<IStatistics | null>(null)
    const [numOfAcceptedJobs, setNumOfAcceptedJobs] = useState<IStatistics | null>(null)
    const [numOfCompletedJobs, setNumOfCompletedJobs] = useState<IStatistics | null>(null)
    const [email, setEmail] = useState('')

    const getJobBidsAcceptedStats = useCallback(
        () => {
            dispatch(setLoader(true))
            jobApi.getJobsBidsStatistics(PENDING, adminUserCountry)
                .then(response => {
                    setNumOfBidsStats(response.data)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_jobs_bids_stats'))
                })
        },
        [dispatch, t, adminUserCountry],
    )

    const getUserStats = useCallback(
        () => {
            dispatch(setLoader(true))
            userApi.getUserStats(adminUserCountry)
                .then(response => {
                    setNewRegistrationsStats(response.data)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_users_stats'))
                })
        },
        [dispatch, t, adminUserCountry],
    )

    const getNewJobStats = useCallback(
        () => {
            dispatch(setLoader(true))
            jobApi.getJobsStatistics({
                status: CREATED,
                country: adminUserCountry
            })
                .then(response => {
                    setNewJobStats(response.data)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_jobs_stats'))
                })
        },
        [dispatch, t, adminUserCountry],
    )

    const getAcceptedJobStats = useCallback(
        () => {
            dispatch(setLoader(true))
            jobApi.getJobsStatistics({
                status: IN_PROGRESS,
                country: adminUserCountry
            })
                .then(response => {
                    setNumOfAcceptedJobs(response.data)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_jobs_stats'))
                })
        },
        [dispatch, t, adminUserCountry],
    )

    const getCompletedJobStats = useCallback(
        () => {
            dispatch(setLoader(true))
            jobApi.getJobsStatistics({
                status: COMPLETED,
                country: adminUserCountry
            })
                .then(response => {
                    setNumOfCompletedJobs(response.data)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_jobs_stats'))
                })
        },
        [dispatch, t, adminUserCountry],
    )

    useEffect(() => {
        getUserStats()
        getNewJobStats()
        getAcceptedJobStats()
        getCompletedJobStats()
        getJobBidsAcceptedStats()
    }, [getUserStats, getNewJobStats, getAcceptedJobStats, getCompletedJobStats, getJobBidsAcceptedStats])

    const handleOnSendClick = useCallback(
        () => {
            dispatch(setLoader(true))
            userApi.sendStatsEmail(email, adminUserCountry)
                .then(() => {
                    toast.success(t('success_sending_users_stats'))
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_sending_users_stats'))
                })
        },
        [dispatch, t, adminUserCountry, email],
    )

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid item>
                    <Typography>
                        {t('marketplace_mgmnt_stat_report')}
                    </Typography>
                </Grid>
               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item md={4}>
                            <TextField
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('email_address_to_send')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <Button onClick={handleOnSendClick} color="primary" fullWidth variant="contained">
                                {t('send')}
                            </Button>
                        </Grid>
                        <Grid item md={12}></Grid>
                        <Grid item md={6}>
                            <StatisticsCountTable title={t('new_registrations')} statistic={newRegistrationsStats} />
                        </Grid>
                        <Grid item md={6}>
                            <StatisticsCountTable title={t('new_jobs')} statistic={newJobStats} />
                        </Grid>
                        <Grid item md={4}>
                            <StatisticsCountTable title={t('num_of_bids')} statistic={numOfBidsStats} />
                        </Grid>
                        <Grid item md={4}>
                            <StatisticsCountTable title={t('num_of_accepted_jobs')} statistic={numOfAcceptedJobs} />
                        </Grid>
                        <Grid item md={4}>
                            <StatisticsCountTable title={t('num_of_completed_jobs')} statistic={numOfCompletedJobs} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default Statistics