import { Container, makeStyles, Box, Grid, Typography, Button } from "@material-ui/core"
import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import green_check from '../../../Images/green_check.png'
import red_x from '../../../Images/red_x.png'
import { routes } from "../../../routes"
import { VERIFICATION_STATUS } from "../../../Types/Constants"

const {
    FAILED,
    SUCCESS
} = VERIFICATION_STATUS

const useStyles = makeStyles(() => ({
    box: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)'
    },
    textCenter: {
        textAlign: 'center'
    },
    logo: {
        width: '150px',
        height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
}))

function AccountVerification() {
    const [t] = useTranslation('AccountVerification')
    const classes = useStyles()
    const [status, setStatus] = useState<string | null>(null)
    const history = useHistory()

    useEffect(() => {
        const href = window.location.href
        console.log('window.location.href', window.location.href)
        const url = new URL(href)

        let status = url.searchParams.get('status')

        setStatus(status)
    }, [])

    const onLoginClick = useCallback(
        () => {
            history.push(routes.landing.login[0])
        },
        [],
    )

    return (
        <Container maxWidth="sm">
            <Box className={classes.box}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                >
                    <Grid xs={12} item>
                        <img
                            className={classes.logo}
                            title="Moveit4u"
                            src={status === SUCCESS ? green_check : red_x}
                            alt="Moveit4u"
                        />
                    </Grid>
                    <Grid className={classes.textCenter} item md={12}>
                        <Typography variant="h2" gutterBottom>
                            {t(status === SUCCESS ? 'success' : 'failed')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.textCenter} item md={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            {t(status === SUCCESS ? 'success_account_verification_message' : 'failed_account_verification_message')}
                        </Typography>
                    </Grid>
                    <Grid xs={12} item>
                        <Button onClick={onLoginClick} fullWidth variant="contained" color="primary">
                            {t('login')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default AccountVerification