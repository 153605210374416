import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOCRHistoryDialogProps } from "./OCRHistoryDialog.types";
import { DateTimePicker } from "@material-ui/pickers";
import { IDeliveryFilter, IJobReportFilter } from "../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { JOB_STATUS } from "../../../Types/Constants";
import { inArray } from "../../../Utilities/Array";
import { deepCopy } from "../../../Utilities/Object";
import { format } from 'date-fns'
import moment from 'moment-timezone';
import { OCRlogs } from '../../../Api/OCR/OCR'
import { IOcrPayload, IOcrData } from '../../../../../Application/DTOs/JobsDto/JobsDto.types'

import { MobileDatePicker } from "@material-ui/pickers";
import { DataGrid, GridColumns } from "@material-ui/data-grid";
import { toast } from "react-toastify";
import { getMpdReportColumns } from "../../Home/Report/MPDReport/MPDReport.columns";
import CustomTable from "../../Common/CustomTable/CustomTable";

const {
    COMPLETED,
    IN_PROGRESS,
    PICKEDUP,
    DELIVERED
} = JOB_STATUS

const useStyles = makeStyles(() => ({
    invisible: {
        opacity: 0
    },
    box1: { width: '100%' },
    box2: { width: '80%' },
    dialogPaper: {
        // minHeight: '80vh',
        maxHeight: '80vh',
    },
}))

function OCRHistoryDialog(props: IOCRHistoryDialogProps) {
    const [t] = useTranslation('OCR')
    const classes = useStyles()
    const { open, handleClose, filename, dataSet } = props
    const [dataRow, setDataRow] = useState<any[]>([])

    const ocrLogsAPI = new OCRlogs()

    const columns = getMpdReportColumns(t, 'filter', {})

    const getOcrLogs = useCallback(
        (filename: any) => {

            ocrLogsAPI.getHistory(filename).then(async (response) => {
                const data = response.data
                await setDataRow(data)

            })
                .catch((err: any) => {
                    toast.error("Error Encountered")
                })
        },
        [t]
    )
    return (
        <>
            <Dialog classes={{ paper: classes.dialogPaper }} maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    History
                </DialogTitle>
                {/* <DialogContent dividers> */}

                    <CustomTable
                        rows={dataSet}
                        columns={columns}
                    />
                {/* </DialogContent> */}
            </Dialog>
        </>
    )
}

export default OCRHistoryDialog