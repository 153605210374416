import { IUserState, SET_LOGIN_RESPONSE, TUserActions } from "./type";
import { IUserInfo } from '../../../../Application/DTOs/UsersDto/UsersDto.type'



const initialState: IUserState = {
    response: null
}


export function userReducer(state: IUserState = initialState, actions: TUserActions): IUserState {
    switch (actions.type) {
        case SET_LOGIN_RESPONSE:
            const response: IUserInfo = actions.payload as IUserInfo
            state.response = response

            return Object.assign({}, state)

        default:
            return state
    }
}