

import { IMarginFeeSettingConfig } from '../../../../../../Application/DTOs/MarginFeeSettingsDto/MarginFeeSettingsDto.types'
import { TMarginActions } from './Margin.types'




export const MarginReducer = (state: IMarginFeeSettingConfig, actions: TMarginActions):IMarginFeeSettingConfig => {
    switch (actions.type) {
        case 'SET':
            state = actions.payload
            return Object.assign({}, state)

        case 'ADD': 
            const {movementType, marginFee} = actions.payload

            state[movementType].push(marginFee)

            return Object.assign({}, state)

        case 'UPDATE':
            const {index, movementType: movementType2, item} = actions.payload

            state[movementType2][index] = item

            return Object.assign({}, state)

        case 'REMOVE':
            const {indexToRemove, movementType:movementType3} = actions.payload

            state[movementType3].splice(indexToRemove, 1)

            return Object.assign({}, state)
        default:
            return state
    }
} 