import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IJobFileInfo } from "../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { IJobFilesTableProps } from "./JobFilesTable.types";
import GetAppIcon from '@material-ui/icons/GetApp';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Job } from '../../../Api/Job/Job';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { setLoader } from '../../../Redux/Loader/actions';
import { USER_ROLES } from '../../../Types/Constants';
import { AppState } from '../../../Redux';
const jobApi = new Job();


function JobFilesTable(props: IJobFilesTableProps) {
    const { job } = props
    const [t] = useTranslation('JobFilesTable')
    const [files, setFiles] = useState<IJobFileInfo[]>([]);
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user.response);
    const { ADMIN_USER, SUPER_ADMIN } = USER_ROLES;


    const isUserAdmin = user?.type === ADMIN_USER;
    const isSuperAdmin = user?.type === SUPER_ADMIN;
    const isAssignedUser = user?.id == job?.assignedUser?.id
    const isJobOwner = user?.company.id as unknown as string == job?.company_id
    const adminPrivilege = isAssignedUser || isSuperAdmin || isUserAdmin || isJobOwner
    const getFile = async (file: any) => {
        jobApi
            .downloadJobFile(file.job_id, file.type, file.id)
            .then((response) => {
                fileDownload(
                    response.data,
                    `${file.original_file_name}`,
                );
                dispatch(setLoader(false));
            })
            .catch((err: any) => {
                console.log('err', err);
                dispatch(setLoader(false));
                toast.error(t('error_download_proof_of_delivery'));
            });
    }
    useEffect(() => {
        const job_files_array: any[] = job.job_files || []
        const groupedData = job_files_array.map((element: any) => {
            const foundFile = job.required_document_selection.find((x: any) => x.fileName == element.original_file_name);

            return { ...element, document: foundFile ? foundFile.document : '' }
        })
        setFiles(groupedData)
    }, [setFiles])

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {t('job_files')}
                            </TableCell>
                            <TableCell>
                                {t('document')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.map(file => (
                            <TableRow key={file.id}>
                                <TableCell>
                                    {(adminPrivilege) ?
                                        <Button
                                            onClick={(e) => { getFile(file) }}
                                            startIcon={<GetAppIcon />}
                                            variant="contained"

                                        > {file.original_file_name} </Button>
                                        :
                                        <p>  {file.original_file_name}</p>

                                    }
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" >
                                        {file.document}
                                    </Typography>

                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}


export default JobFilesTable