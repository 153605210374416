import { Grid, Typography, Divider, Button, TextField, InputAdornment } from '@material-ui/core';
import { GridRowParams } from '@material-ui/data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type';
import { Company } from '../../../../Api/Company/Company';
import { AppState } from '../../../../Redux';
import { setCompanyList } from '../../../../Redux/Company/actions';
import { setLoader } from '../../../../Redux/Loader/actions';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import ViewCompanyDetailsDialog from '../../../Dialogs/ViewCompanyDetailsDialog/ViewCompanyDetailsDialog';
import { getCompanyManagementPageColumns } from './CompanyManagementPage.columns';
import SearchIcon from '@material-ui/icons/Search';
import SectionHeader from '../../../Common/SectionHeader/SectionHeader';
import debounce from 'lodash.debounce';

const companyApi = new Company();

function CompanyManagementPage() {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const companyList = useSelector((state: AppState) => state.company.list);

    const [dialogState, setDialogState] = useState({
        isOpen: false,
        selectedCompanyId: null as string | number | null,
    });
    const [searchByName, setSearchByName] = useState('');

    const onRowClick = useCallback(
        (params: GridRowParams) => {
            setDialogState({
                isOpen: true,
                selectedCompanyId: (params.row as ICompanyInfo).id,
            });
        },
        [],
    );

    const getAll = useCallback(() => {
        dispatch(setCompanyList([]))
        companyApi.getAll(undefined, searchByName || undefined)
            .then(response => {
                dispatch(setCompanyList(response.data));
            })
            .catch(() => {
                dispatch(setCompanyList([]));
                toast.error(t('error_getting_companies'));
            });
    }, [dispatch, searchByName, t]);

    const handleClose = useCallback(() => {
        setDialogState({ isOpen: false, selectedCompanyId: null });
        getAll()
    }, []);


    const handleOnContactableUpdate = useCallback(
        (companyId: string | number, isChecked: boolean, type: 'sms' | 'email') => {
            let payload: any = {}

            if (type === 'sms') {
                payload = {
                    is_sms_contactable: isChecked
                }
            } else if (type === 'email') {
                payload = {
                    is_email_contactable: isChecked
                }
            }
            dispatch(setLoader(true));
            companyApi.update(companyId, payload)
                .then(() => {
                    dispatch(setLoader(false));
                    getAll();
                })
                .catch(() => {
                    dispatch(setLoader(false));
                    toast.error(t('error_updating_company_contactable'));
                });
        },
        [getAll, dispatch, t],
    );
    const handleSearchChange = (event: any) => {
        setSearchByName(event.target.value)
    }

    const handleSearchKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            getAll()
        }
    }

    useEffect(() => {
        let timeOutId = setTimeout(() => {
            getAll()
        }, 500)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchByName])
    const columns = getCompanyManagementPageColumns(t, handleOnContactableUpdate);

    return (
        <>
            {dialogState.selectedCompanyId &&
                <ViewCompanyDetailsDialog
                    selectedCompanyId={dialogState.selectedCompanyId}
                    open={dialogState.isOpen}
                    handleClose={handleClose}
                />
            }
            <Grid container direction="column" justifyContent="space-between" alignItems="stretch" spacing={5}>
                <SectionHeader subtitle={t("company_management")} title={t('company_management')} />
                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item md={6}></Grid>
                        <Grid item md={4}>
                            <TextField
                                value={searchByName}
                                onChange={handleSearchChange}
                                onKeyDown={handleSearchKeyDown}
                                variant="outlined"
                                fullWidth
                                label={t('search')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: { fontWeight: 'bold' },
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <CustomTable
                                onRowClick={onRowClick}
                                columns={columns}
                                rows={companyList}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default CompanyManagementPage;
