import {
  Button,
  Dialog,
  makeStyles,
  DialogActions,
  InputAdornment,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Badge
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IViewEditQuoteProps } from './ViewEditQuote.types';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../Redux';

import QuotesDetails from '../../Home/Quotes/QuotesCreation/QuotesDetails'


import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants';

const { SUPER_ADMIN } = USER_ROLES;
const useStyles = makeStyles(() => ({
  cloneBtn: {
    backgroundColor: '#7FB3D5',
    '&:hover': {
      backgroundColor: '#7FB3D5',
    },
  },
  editBtn: {
    backgroundColor: '#F1C40F',
    '&:hover': {
      backgroundColor: '#F1C40F',
    },
  },
  updatebtn: {
    color: 'white',
    backgroundColor: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
}));

const CLONE_AMOUNT = {
  MIN: 1,
  MAX: 10,
};



function ViewEditQuote(props: IViewEditQuoteProps) {

  const { selectedQuote, open, handleClose, readonly, mode, onProceed } = props;
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const [t] = useTranslation('ShowQuotes');
  const generateQuoteref = useRef();


  const handleGenerateJob = useCallback(() => {
    if (generateQuoteref.current) {
      (generateQuoteref.current as any).handleGenerateJob(() => {
        onProceed();
      });
    }
  }, [])


  return (
    <>

      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography>{t('quote_details')}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <QuotesDetails
            ref={generateQuoteref}
            type='transport' selectedQuote={selectedQuote} mode={mode} isEditMode={true} readonly={readonly} />
        </DialogContent>
        {(mode == 'generate') ?
          <DialogActions>
            <Button onClick={() => handleGenerateJob()}
              variant="contained" color="primary">{t('generate_job')}</Button>
          </DialogActions>
          : null}


      </Dialog>
    </>
  );
}

export default ViewEditQuote;
