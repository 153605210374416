import { Grid, Typography, Divider, TextField, InputAdornment, IconButton, Button , Dialog, DialogActions, DialogContent, DialogTitle, } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { UsersApi } from "../../../Api/Users/Users";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";

// import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
// import { useTranslation } from "react-i18next";
import { IchangePasswordProps } from "./changePassword.props"
// import { Job } from "../../../Api/Job/Job";
// import { useCallback, useEffect, useState } from "react";
// import { toast } from "react-toastify";
// import { StaticDateTimePicker } from '@material-ui/pickers'
// import { useDispatch } from "react-redux";
// import { setLoader } from "../../../Redux/Loader/actions";


function ChangePassword(props: IchangePasswordProps) {
    const [t] = useTranslation('ChangePassword')
    const dispatch = useDispatch()

    const [date, setDate] = useState<Date | null>(new Date())

    const { open,  handleClose ,onSubmit } = props
    const [showPass, setShowPass] = useState<boolean>(false)
    const [newPassword, setNewPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const changePassword = useCallback(() => {
      
        if(newPassword == '') {
            toast.warning(t('new_password_not_empty'))
            return
        } else if(newPassword.length < 6) {
            toast.warning(t('invalid_password_length'))
            return
        }

        if(passwordConfirm == '') {
            toast.warning(t('confirm_password_not_empty'))
            return
        } else if(passwordConfirm.length < 6) {
            toast.warning(t('invalid_password_length'))
            return
        }

        if(newPassword !== passwordConfirm) {
            toast.warning(t('password_did_not_match'))
            return
        }

        onSubmit(newPassword)
        
    }, [newPassword, passwordConfirm, dispatch, t])

    const handleOnChangePassword = useCallback(
        () => {
            changePassword()
        },
        [changePassword],
    )


 
    return (
        <>
            <Dialog maxWidth="md" open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                    {t('change_password')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                      
                        <Grid md={12} item>
                                <TextField
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    label={t('enter_password')}
                                    type={showPass ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPass(!showPass)}>
                                                    {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: 'bold'
                                        }
                                    }}
                                />
                                </Grid>
                                <Grid md={12} item>
                                    <TextField
                                        value={passwordConfirm}
                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('confirm_password')}
                                        type={showPass ? 'text' : 'password'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                                        {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                       
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {/* <Grid></rid> */}
                    <Button onClick={handleClose}  variant="contained" color="primary">
                        {t('cancel')}

                    </Button>
            
                    <Button onClick={handleOnChangePassword} fullWidth variant="contained" color="primary">
                        {t('change_password')}

                    </Button>
            
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ChangePassword