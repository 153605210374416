import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { formatDateTimeDisplayDD } from "../../../../Utilities/DateTime";
import { Typography } from "@material-ui/core";
import { toUpper } from "lodash";
export const getNotificationColumns = (t: TFunction): GridColumns => [
    {
        field: 'type',
        headerName: t('type'),
        width: 200,
        renderCell: (params) => {
            let data = params.row
            return (
                <Typography>
                    {data.type.toUpperCase()}
                </Typography>
            )
        }
    }, {
        field: 'message',
        headerName: t('notification_message'),
        width: 400
    },
    // {
    //     field: 'targetPage',
    //     headerName: t('target_page'),
    //     width: 200
    // },
    {
        field: 'start_date',
        headerName: t('start_date'),
        width: 200,
        renderCell: (params) => {
            let data = params.row
            return (
                <Typography>
                    {formatDateTimeDisplayDD(data.start_date)}
                </Typography>
            )
        }
    },
    {
        field: 'end_date',
        headerName: t('end_date'),
        width: 200,
        renderCell: (params) => {
            let data = params.row
            return (
                <Typography>
                    {formatDateTimeDisplayDD(data.end_date)}
                </Typography>
            )
        }
    },
];
