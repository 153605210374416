import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
import { ILoaderProps } from "./Loader.type";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 500,
        color: '#fff',
    },
}));

function Loader(props: ILoaderProps) {
    const classes = useStyles()
    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loader