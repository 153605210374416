export const EXPAND_MENU = 'EXPAND_MENU';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';

export interface ISidebarItem {
  itemValue: string;
  url?: any
  hide?: boolean;
}

export interface ISidebarMenu {
  menuValue: string;
  isExpanded: boolean;
  hide?: boolean;
  items: ISidebarItem[];
}

export interface ISidebarConfig {
  [menu: string]: ISidebarMenu;
}

export interface ISidebarProps {
  isSidebarOpen: boolean;
  drawerWidth: number;
  toggleSideBar: Function;
  activeSide?: string
}

export interface IExpandSidebarMenu {
  type: typeof EXPAND_MENU;
  payload: string;
}

export interface ISetInitialState {
  type: typeof SET_INITIAL_STATE;
  payload: ISidebarConfig;
}

export type ISidebarActions = IExpandSidebarMenu | ISetInitialState;

export const DASHBOARD_MENU = 'dashboard_menu';
export const TRANSPORT_MANAGEMENT: string = 'transport_management';
export const SEA_FREIGHT_MANAGEMENT = 'sea_freight_management';
export const REPORT = 'admin_reports';
export const REPORT_ONLY = 'reports';

export const KPI_DASHBOARD = 'kpi_dashboard';
export const USER_MANAGEMENT = 'user_management';
export const DRIVER_MANAGEMENT = 'driver_management';
export const COMPANY_MANAGEMENT = 'company_management';
export const COMP_ADDRESS_MANAGEMENT = 'address_management';


export const CUSTOMER_MANAGEMENT = 'customer_management';
export const CUSTOMER_JOBS = 'jobs_in_progress_only';
export const ACCOUNT_MANAGEMENT = 'account_management';



export const ADMIN_SETTINGS = 'admin_settings';

export const DASHBOARD = 'dashboard';
export const WEEKLY_JOB_METRICS = 'weekly_job_metrics';
export const HOME_DASHBOARD = 'home_dashboard';


export const MAP_VIEW = 'map_view';
export const OVERVIEW = 'overview';
export const CREATE_NEW_JOB = 'create_new_job';
export const PENDING_JOBS = 'pending_jobs';
export const POSTED_JOBS = 'posted_jobs';
export const BIDS_TO_CONFIRM = 'bids_to_confirm';
export const MY_BIDS = 'my_bids';
export const ALL_JOBS = 'all_jobs';
export const AVAILABLE_JOBS = 'available_jobs';
export const OUR_ASSIGNED_JOBS = 'our_assigned_jobs';
export const JOBS_IN_PROGRESS = 'jobs_in_progress';
export const MY_JOBS_IN_PROGRESS = 'my_jobs_in_progress';
export const JOB_BIDS = 'job_bids';
export const PICKED_UP_JOBS = 'picked_up_jobs';
export const DELIEVERED_JOBS = 'delivered_jobs';
export const COMPLETED_JOBS = 'completed_jobs';
export const COMPLETED_JOBS_MILEAGE = 'completed_jobs_mileage';
export const JOB_REPORT = 'job_report';

export const STATISTICS = 'statistics';
export const MARKETPLACE_MANAGEMENT = 'marketplace_management';
export const MARKETPLACE_MANAGEMENT_STATISTICS_REPORT = 'marketplace_mgmnt_stat_report';


export const OVERDUE_COLLECTION = 'overdue_collections';
export const PROFITABILITY_REPORT = 'profitability_report';

export const SCHEDULED_DELIVERY_REPORT = 'scheduled_delivery_report';
export const SCHEDULED_COLLECTION_REPORT = 'scheduled_collection_report';

export const CNTR_DEHIRE_REPORT = 'cntr_dehire_report';
export const CNTR_DETENTION_REPORT = 'cntr_detention_report';
export const COMPLETED_JOBS_REPORT = 'completed_jobs_report';
export const COMPLETED_JOBS_MILEAGE_REPORT = 'completed_jobs_mileage_report';


export const RATES_MANAGEMENT = 'rates_management'


export const OVERDUE_DELIVERIES = 'overdue_deliveries';
export const NO_POD_DOCUMENTS = 'no_pod_documents';

export const ADD_USER = 'add_user';
export const ALL_USERS = 'all_users';
export const ADMIN_USERS = 'admin_users';

export const MY_DRIVERS = 'my_drivers';
export const ADD_DRIVER = 'add_driver';
export const DRIVER_FEE_SETTINGS = 'driver_fee_settings';
export const WAITING_FOR_APPROVAL = 'waiting_for_approval';

export const RELATIONSHIP_MANAGEMENT = 'relationship_management';
export const ASSOCIATE_GROUP_MANAGEMENT = 'associate_group_management';
export const COMPANY_ADDRESS = 'company_address';
export const COMPANY_FEE_SETTINGS = 'company_fee_settings';

export const COMMISSIONING_SETTINGS = 'commissioning_settings';
export const COMMON_ADDRESS = 'common_address';
export const ADDRESS_MANAGEMENT = 'address_management';
export const MARGIN = 'margin';
export const COUNTRY_SETTINGS = 'country_settings';
export const NOTIFICATION_SETTINGS = 'notification_settings';

export const INVOICE_REPORTS = 'invoice_reports';

export const JOB_NOT_INVOICED = 'job_not_invoiced';
export const JOB_INVOICED = 'job_invoiced';


export const CREATE_NEW_ASSIGNED_JOB = 'create_new_assigned_job';
export const MPD_REPORTS = 'mpd_reports';

export const QUOTES_MANAGEMENT = 'quotes_management';
export const CREATE_A_QUOTE = 'create_a_quote';
export const QUOTES = 'quotes';
export const SUCCESSFUL_QUOTES = 'successful_quotes';
export const FAILED_QUOTES = 'failed_quotes';
export const PENDING_QUOTES = 'pending_quotes';
export const ACTIVE_QUOTES = 'active_quotes';

export const WAREHOUSE_MANAGEMENT = 'warehouse_management';
export const SCHEDULED_COLLECTION_WAREHOUSE = 'scheduled_collection';
export const SCHEDULED_DELIVERIES_WAREHOUSE = 'scheduled_deliveries';



export const MY_WAREHOUSES = 'my_warehouses';
export const MY_STOCKS = 'my_stocks';
export const STOCK_LEDGER = 'stock_ledger';

export const CONTAINERS_ON_SITE = 'containers_on_site'





