import { GridColumns } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { TFunction } from "i18next";
import { IColumnsCallbacks } from "./Onsite.types";
import {
    STATUS,
} from "..././../../../UI/src/Types/Constants";
import { Grid } from "react-virtualized";
interface IOnStatusChange {
    (user_id: string | number, isChecked: boolean, company_id: string | number): void
}
const { ACTIVE } = STATUS;



export const getColumns = (t: TFunction, callbacks: IColumnsCallbacks): GridColumns => [
    {
        field: 'reference',
        headerName: t('reference'),
        width: 250
    },
    {
        field: 'container_no',
        headerName: t('container_no'),
        width: 250,
    },
    {
        field: 'status',
        headerName: t('status'),
        width: 250,
        renderCell: ((params) => {
            const btn_text = params.row['status'] == 'pack' ? 'unpack' : 'unpack'
            return (<Button onClick={(event: any) => {
                event.stopPropagation()
                callbacks.sendEmailNotification(params.row)
            }}
                variant="contained" color="primary" disabled ={params.row.is_email_sent}>{t(btn_text)}</Button>
            )

        }),
    },
    {
        field: 'action',
        headerName: t('action'),
        width: 250,
        renderCell: ((params) => {
            return (<Button onClick={(event: any) => {
                event.stopPropagation()
                callbacks.onGenerateOutTurn(params.row)
            }}
                variant="contained" color="primary">{t('generate_out_turn')}</Button>

            )

        }),
    }
]