import {
    TextareaAutosize,
    MenuItem,
    Button,
    Grid,
    FormControl,
    InputLabel,
    Select,
    Typography,
    makeStyles,
    Divider,
    TextField,
    InputAdornment,
    GridList,
    GridListTile,
    FormControlLabel,
    Checkbox,
    Badge,
    Tooltip,
    IconButton
} from '@material-ui/core';
import fileDownload from 'js-file-download';

import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { ICreateNewJobProps } from '../../Jobs/CreateNewJob/CreateNewJob.types';
import AddIcon from '@material-ui/icons/Add';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ImageIcon from '@material-ui/icons/Image';
import { StaticDateTimePicker, DateTimePicker, TimePicker, MobileDatePicker, MobileDateTimePicker, DatePicker } from '@material-ui/pickers';
import React, {
    useImperativeHandle,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useRef
} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CreateAddressDialog from '../../../Dialogs/CreateAddressDialog/CreateAddressDialog';
import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import { Address } from '../../../../Api/Address/Address';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../Redux';
import { toast } from 'react-toastify';
import { Customer } from '../../../../Api/Customer/Customer'

import {
    JOB_STATUS,
    POST_TO,
    TYPES_OF_PACKAGES,
    COUNTRIES,
    MOVEMENT_TYPES,
    TRIP_TYPE,
    USER_ROLES,
    JOB_FILES_TYPE,
    VEHICLE_TYPES_CONFIG,
    JOB_TYPES,
    CREATE_JOB_ELEMENTS,
    TEMPERATURELIST,
    TEMPERATURE,
    VEHICLE_TYPES
} from '../../../../Types/Constants';
import _, { conforms, get, groupBy, isEqual, isNil, toUpper, update } from 'lodash';
import { Job } from '../../../../Api/Job/Job';
import { setLoader } from '../../../../Redux/Loader/actions';
import {
    IJobFileInfo,
    IJobInfo,
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import JobHistories from '../../../Common/JobHistories/JobHistories';
import { formatDateTime, formatDateTimeDisplay, formatInputDateTime, formatDateTimeDisplayDD, formatSaveOffset, formatReadOffset, convertToUTC } from '../../../../Utilities/DateTime';
import { isNonNumeric, isNumeric } from '../../../../Utilities/Regex';
import { Company } from '../../../../Api/Company/Company';
import { IAssociateGroupInfo } from '../../../../../../Application/DTOs/AssociateGroupDto/AssociateGroupDto.types';
import { IAutocompleteOption } from '../../../Common/types';
import MultiAutocomplete from '../../../Common/MultiAutocomplete/MultiAutocomplete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EditIcon from '@material-ui/icons/Edit';

import SelectUserDialog from '../../Jobs/CreateNewJob/SelectUserDialog/SelectUserDialog';
import RequiredDocuments from '../../../Common/RequiredDocument/RequiredDocuments'
import { AdminSettings } from '../../../../Api/AdminSettings/AdminSettings';
import { IConfiguration, ICountryConfiguration } from '../../../../../../Application/Models/CountryConfigurations/CountryConfigurations.types';
import JobFilesTable from '../../../Common/JobFilesTable/JobFilesTable';
import FileInput from '../../../Common/FileInput/FileInput';
import { IN_PROGRESS } from '../../Jobs/Jobs.constans';
import { formatFileUrl } from '../../../../Utilities/Formatting';
import Image from '../../../Common/Image/Image';
import ImagePreviewDialog from '../../../Dialogs/ImagePreviewDialog/ImagePreviewDialog';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../routes';
import MultiplePackageType from '../../../Common/Packages/Packages'
import moment from 'moment';
import CommentDialog from '../../../Dialogs/CommentDialog/CommentDialog';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import PalletAccount from '../../../Common/PalletAccount/PalletAccount';
import CustomDateTimePicker from '../../Jobs/CreateNewJob/DatePickers'
import CountrySelect from '../../../Dialogs/CreateAddressDialog/CountrySelect';
import AddressSelector from '../../Jobs/CreateNewJob/AddressSelector';
import TransportSelector from '../../../Common/TransportSelector/TransportSelector';
import SectionHeader from '../../../Common/SectionHeader/SectionHeader';
import { DataGrid } from '@material-ui/data-grid';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import RatesTableV2 from './RatesTables';
const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: "white",
        fontSize: '10px !important',
    },
    formControl: {
        minWidth: '100%',
        fontSize: '1rem'
    },
    gap: {
        marginTop: 20,
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
    },
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    gridList: {
        width: 500,
        height: 450,
    },
    uploadedFile: {
        display: 'flex',
        alignItems: 'center',
        // marginTop: theme.spacing(2),
    },
    attachmentContainer: {

        border: '1px solid #ccc', // Border to make it look interactive
        borderRadius: '4px', // Rounded corners
        padding: '10px', // Space around content
        backgroundColor: '#f5f5f5', // Background color
        transition: 'background-color 0.3s ease', // Smooth background color transition

        margin: '6px',
        position: 'relative', // Ensure delete button can be positioned absolutely if needed
    },
    canDl: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e0e0e0', // Change background color on hover
        },
    },
    attachmentItem: {
        display: 'flex',
        alignItems: 'center',
    },
    deleteButton: {
        position: 'absolute',
        top: '10px',
        right: '-5px',
    },
    fileIcon: {
        marginRight: theme.spacing(1),
    },
    hiddenFileInput: {
        display: 'none',
    }
}
));

const addressApi = new Address();
const jobApi = new Job();
const companyApi = new Company();
const adminSettingApi = new AdminSettings();

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE, NEW_ZEALAND } = COUNTRIES;

const { FULL_TRUCK_LOAD, LOOSE_CARGO, EMPTY_PARK, WHARF, RAIL, OTHER, CONTAINERS } = MOVEMENT_TYPES;
const { REFEER_20, REFEER_40, HC_20, HC_40 } = JOB_TYPES;

const { CHILLED, FROZEN, AMBIENT } = TEMPERATURE;


const { ONE_WAY, ROUND_TRIP, RETURN_TRIP, MULTI_STOP } = TRIP_TYPE;

const { ASSOCIATES, PUBLIC, HIDDEN } = POST_TO;

const { CREATED, COMPLETED, FUMIGATION, VIA_YARD, STORAGE, PICKEDUP, ASSIGNED, DELIVERED, CLONED } = JOB_STATUS;

const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER } = USER_ROLES;

const { DAMAGE_PHOTO } = JOB_FILES_TYPE;
const { REEFER } = VEHICLE_TYPES;


const { QUANTITY, PACKAGES_TYPE, VOLUME, CN, ECRNUM, SEAL_NO, WS, CNTR } =
    CREATE_JOB_ELEMENTS;

const dateNow = new Date();

const associateGroupsToOptions = (
    associateGroup: IAssociateGroupInfo
): IAutocompleteOption => ({
    label: associateGroup.name,
    value: associateGroup.id,
});
const customerApi = new Customer()
function RatesManagementv2() {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [isCreateAddressDialogOpen, setIsCreateAddressDialogOpen] =
        useState(false);
    const user = useSelector((state: AppState) => state.user.response);

    const company = useSelector((state: AppState) => state.company);

    const currentUserCompanyId: any = user?.company?.id || undefined;
    const currentuserId: any = user?.id || undefined;

    const isUserAdmin = user?.type === ADMIN_USER;
    const isSuperAdmin = user?.type === SUPER_ADMIN;
    const isDriverManager = user?.type === DRIVER_MANAGER;
    const [country, setCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);
    const [pickUpCountry, setPickUpCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);
    const [deliveryCountry, setDeliveryCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);
    const [returnCountry, setReturnCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);
    const [thirdLegCountry, setThirdLegCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);

    const [triggerUpdate, setTriggerUpdate] = useState<number>(1);

    const [companyId, setCompanyId] = useState(
        isSuperAdmin || isUserAdmin ? '' : currentUserCompanyId
    );

    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [isBonds, setIsBonds] = useState<boolean>(true);

    const [jobType, setJobType] = useState('');
    const [tripType, setTripType] = useState('');
    const [movementType, setMovementType] = useState('');
    const [reference, setReference] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [serviceType, setServiceType] = useState('');

    const [temperature, setTemperature] = useState(AMBIENT);
    const [temperatureValue, setTemperatureValue] = useState('');

    const [freeText, setFreeText] = useState('');
    const [sku, setSKU] = useState('');
    const [itemDesc, setItemDesc] = useState('');

    const [files, setFiles] = useState<any>([]);
    const [photosOfDamages, setPhotosOfDamages] = useState<any>([]);
    const [selectedAddress, setSelectedAddress] = useState<any | undefined>(undefined);
    const [selectedAddressType, setSelectedAddressType] = useState('');;

    const [selectedAddressMode, setSelectedAddressMode] = useState('create');

    const [pickUpDateTime, setPickUpDateTime] = useState<string | null>(null);
    const [deliveryDateTime, setDeliveryDateTime] = useState<string | null>(null);
    const [returnDateTime, setReturnDateTime] = useState<string | null>(null);
    const [thirdLegDateTime, setThirdLegDateTime] = useState<string | null>(null);

    const [typeOfPackage, setTypeOfPackage] = useState('');
    const [quantity, setQuantity] = useState('');
    const [declaredGrossWeight, setDeclaredGrossWeight] = useState('');
    const [volume, setVolume] = useState('');
    const [containerNo, setContainerNo] = useState('');
    const [CNTRLastDay, setCNTRLastDay] = useState<Date | null>(new Date());
    const [vesselETA, setVesselETA] = useState<string | null>(null);

    const [selectedDocs, setSelectedDocs] = useState<{ id: string; document: string; file: File }[]>([]);


    const [selectedDocument, setSelectedDocument] = useState('');


    const [sealNo, setSealNo] = useState('');
    const [emptyContainerReleaseNumber, setEmptyContainerReleaseNumber] =
        useState('');
    const [initialPrice, setInitialPrice] = useState<any>(0);
    const [wharfSlot, setWharfSlot] = useState('');
    const [postedPrice, setPostedPrice] = useState<any>(0);
    const [postTo, setPostTo] = useState('');
    const [jobInstruction, setJobInstruction] = useState('');
    const [postingCompanyName, setPostingCompanyName] = useState('');

    const [biddingCompanyName, setBiddingCompanyName] = useState('');
    const [deliverInstruction, setDeliverInstruction] = useState('');
    const [pickUpInstruction, setPickupInstruction] = useState('');

    const [deliverPalletsConf, setDeliverPalletsConf] = useState([]);
    const [pickUpPalletsConf, setPickUpPalletsConf] = useState([]);

    const [pickUpReceivingHours, setPickUpReceivingHours] = useState('');
    const [deliveryReceivingHours, setDeliveryReceivingHours] = useState('');

    const [mergedInstruction, setMergedInstruction] = useState('');

    const [selectedRequiredDocuments, setSelectedRequiredDocuments] = useState<any[]>([]);
    const [currentSelectedNoteUser, setCurrentSelectedNoteUser] = useState('admin')


    const [bondsJobId, setBondsJobId] = useState('');


    const [jobFiles, setJobFiles] = useState<IJobFileInfo[] | undefined>(
        undefined
    );

    const [imageToPreview, setImageToPreview] = useState<string | undefined>(
        undefined
    );
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

    const [isCompanyJobOwner, setIsCompanyJobOwner] = useState(true);

    const [pickUpState, setPickUpState] = useState('');
    const [pickUpGroup, setPickUpGroup] = useState('');
    const [pickUpAddressId, setPickUpAddressId] = useState('');

    const [deliverToState, setDeliverToState] = useState('');
    const [deliverToGroup, setDeliverToGroup] = useState('');
    const [deliverToAddressId, setDeliverToAddressId] = useState('');

    const [deliverToStateType, setDeliverToStateType] = useState('');


    const [returnToState, setReturnToState] = useState('');
    const [returnToGroup, setReturnToGroup] = useState('');
    const [returnToAddressId, setReturnToAddressId] = useState('');

    const [thirdLegState, setThirdLegState] = useState('');
    const [thirdLegGroup, setThirdLegGroup] = useState('');
    const [thirdLegAddressId, setThirdLegAddressId] = useState('');
    const readonly = false
    const [associateGroups, setAssociateGroups] = useState<IAutocompleteOption[]>(
        []
    );
    const [selectedAssociateGroups, setSelectedAssociateGroups] = useState<
        IAutocompleteOption[]
    >([]);


    const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);
    // const [isRequiredDialogOpen, setIsRequiredDialogOpen] = useState(false);

    const fileInputRef = useRef(null);
    const [isDocumentUploading, setDocumentUploading] = useState(false);
    const [fileInputValue, setFileInputValue] = useState(null);

    const [movementTypes, setMovementTypes] = useState<string[]>([]);
    const [jobTypes, setJobTypes] = useState<string[]>([]);
    const [customerOptions, setCustomerOptions] = useState<string[]>([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [showAdditionalSteps, setShowAdditionalSteps] = useState<boolean>(true);

    const [addStepDatetime, setAddStepDatetime] = useState<Date | null>(null);
    const [addStepEndDatetime, setAddStepEndDatetime] = useState<Date | null>(null);
    const [additionalStepStatus, setAdditionalStepStatus] = useState('');

    const [CNTRDehireDay, setCNTRDehireDay] = useState<Date | null>(null);
    const [customerReference, setCustomerReference] = useState('');

    //pallets
    const [deliverPalletValue, setDeliverPalletValue] = useState('');
    const [pickUpPalletValue, setPickUpPalletValue] = useState('');
    const [palletAccountType, setPalletAccountType] = useState('');
    const [palletAccountAction, setPalletAccountAction] = useState('');
    const [palletAccountValue, setPalletAccountValue] = useState('');


    const setTypeOfPackageRef = useRef();

    const [allocationSelectedJob, setAllocationSelectedJob] = useState<any>({});
    interface LooseObject {
        [key: string]: any
    }

    const [configJobTypes, setConfigJobTypes] = useState<LooseObject>({})

    const [addressRefresh, setAddressRefresh] = useState(0);

    const handleUpdateRefresh = () => {
        setAddressRefresh((prevRefresh) => prevRefresh + 1);
    };

    const handleSelectUserDialogClose = useCallback(() => {
        setIsSelectUserDialogOpen(false);
    }, []);
    // const handleRequiredDocumentClose = useCallback(() => {
    //   setIsRequiredDialogOpen(false);
    // }, []);
    const [rawDocument, setRawDocuments] = useState<any[]>([]);


    // const { readonly, selectedJob, isEditMode, type: _jobClassification, isJobOwner, isAssignedUser, cntrReadOnly, documentUploadReadOnly, mode, readOnlyBidder } = props;
    const adminPrivilege = true

    const jobClassification = 'transport'

    const formatAddress = useCallback(
        (address: IAddress) =>
            `${address.street_number} ${address.city} ${address.state} ${t(address.country)}`,
        [t]
    );

    const [currentPickUpAddressString, setCurrentPickUpAddressString] =
        useState('');
    const [currentDeliverToAddressString, setCurrentDeliverToAddressString] =
        useState('');
    const [currentReturnToAddressString, setCurrentReturnToAddressString] =
        useState('');
    const [currentThirdLegToAddressString, setThirdlegAddressString] =
        useState('');

    // Additional Charges
    const [additionalChargeDescription1, setAdditionalChargeDescription1] =
        useState('');
    const [additionalChargeDescription2, setAdditionalChargeDescription2] =
        useState('');
    const [additionalChargeAmount1, setAdditionalChargeAmount1] =
        useState<any>(0);
    const [additionalChargeAmount2, setAdditionalChargeAmount2] =
        useState<any>(0);

    // Assigned job
    const [transportRef, setTransportRef] = useState('');
    const [transportCompany, setTransportCompany] = useState('');
    const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);

    //notes
    const [jobCommentData, setJobCommentData] = useState<any>({})

    const getCountryConfig = useCallback(() => {
        adminSettingApi
            .getCountryConfig(country)
            .then((response) => {
                const config: ICountryConfiguration = response.data;

                setMovementTypes(config.jobSettings[jobClassification].movementTypes);
                setJobTypes(config.jobSettings[jobClassification].jobTypes);

            })
            .catch((err: any) => {
                console.log(`err`, err);
                toast.error(t('error_getting_config'));
            });
    }, [country, t, jobClassification]);

    const getTypesConfig = useCallback(() => {

        // const cachedOptions = localStorage.getItem('configTypeOptions');
        // if (cachedOptions) {
        //   setMovementTypes(JSON.parse(cachedOptions).movement_types);
        //   setConfigJobTypes(JSON.parse(cachedOptions).job_types)
        // } else {
        //   adminSettingApi
        //     .getTypeConfig(country, jobClassification, tripType)
        //     .then((response) => {
        //       const config = response.data;
        //       setMovementTypes(config.movement_types);
        //       setConfigJobTypes(config.job_types)
        //       localStorage.setItem('configTypeOptions ', JSON.stringify(config));
        //     })
        //     .catch((err: any) => {
        //       console.log(`err`, err);
        //       toast.error(t('error_getting_config'));
        //     });
        // }

    }, [country, t, jobClassification, tripType]);

    const getAssociateGroups = useCallback(() => {

        companyApi
            .getCompanyAssociateGroups(companyId)
            .then((response) => {
                const associateGroups: IAssociateGroupInfo[] = response.data;

                setAssociateGroups(associateGroups.map(associateGroupsToOptions));

            })
            .catch((err: any) => {
                console.log('err', err);
                toast.error(t('error_getting_associate_groups'));
                dispatch(setLoader(false));
            });
    }, [dispatch, t, companyId]);

    // addressUserOptions
    const getAddresses = useCallback(
        (companyId?: any) => {

            if (companyId === '' || isNil(companyId)) return;
            // const cachedOptions = sessionStorage.getItem('addressUserOptions');
            // if (cachedOptions) {
            //   setAddresses(JSON.parse(cachedOptions));
            //   dispatch(setLoader(false));

            // } else {

            addressApi
                .allForJob(
                    companyId,
                    true,
                    !readonly ? country : null,
                    false,
                    false,
                    true
                )
                .then((response) => {
                    const addresses = response.data as IAddress[];
                    setAddresses(addresses);
                    // sessionStorage.setItem('addressUserOptions', JSON.stringify(addresses));
                    handleUpdateRefresh()
                    dispatch(setLoader(false));
                })
                .catch((err: any) => {
                    console.log('err', err);
                    toast.error(t('error_getting_addresses'));
                    dispatch(setLoader(false));
                });;
            // }

        },
        [t, country, readonly, dispatch]
    );


    const showTemperature = () => {
        if (['reefer_40', 'reefer_20'].includes(jobType)) {
            return true
        }
        return false
    }
    const showPackages = () => {
        if (['containers'].includes(movementType) || movementType == '') {
            return false
        }
        return true
    }
    const showPalletOptions = () => {
        const isLooseCargoOrOther = [LOOSE_CARGO, OTHER].includes(movementType);
        const isFullTruckLoadAndLC = movementType === FULL_TRUCK_LOAD && jobType === LOOSE_CARGO;
        if (isLooseCargoOrOther || isFullTruckLoadAndLC) {
            return true;
        }
        return false;
    };


    const handleDialogClose = useCallback((updated_data?: any) => {
        getAddresses(companyId)
        setIsCreateAddressDialogOpen(false);
        if (updated_data) {
            if (selectedAddressType == 'pickup') {
                setPickupInstruction(updated_data.updated_data || null)
                setPickUpPalletsConf(updated_data.palletAccounts || [])
                setPickUpReceivingHours(updated_data.instruction || null)
            }
            else if (selectedAddressType == 'delivery') {
                setDeliverInstruction(updated_data.updated_data || null)
                setDeliverPalletsConf(updated_data.palletAccounts || [])
                setDeliveryReceivingHours(updated_data.instruction || null)

            }
        }
        setTriggerUpdate(triggerUpdate + 1)
    }, [companyId, getAddresses, selectedAddressType]);

    const onCreateAddressDone = useCallback(() => {

        handleDialogClose();

    }, [handleDialogClose]);

    const handleCountryChange = (e: any) => {
        const currentCountry = e.target.value;
        setCountry(currentCountry);
        setJobType('');
        setVehicleType('');
    };

    const addressesByState: any = useMemo(() => {
        if (country === AUSTRALIA || country === NEW_ZEALAND) {
            return groupBy(addresses, 'state');
        }

        return [];
    }, [country, addresses]);

    const addressesByGroup: any = useMemo(() => {
        if (country !== AUSTRALIA || country === NEW_ZEALAND) {
            return groupBy(addresses, 'address_group');
        }

        return [];
    }, [country, addresses]);






    useEffect(() => {
        getAddresses(companyId);
        // getTypesConfig()

    }, [
        companyId,
        readonly,
    ]);


    const arrayOfofSteps = [{ 'key': 'storage', value: 'Storage' }, { 'key': 'via_yard', value: 'Via yard' }, { 'key': 'fumigation', value: 'Fumigation' }]
    const additionalStepOptions = useMemo(
        () =>

            arrayOfofSteps.map((state, i) => {
                if (t(state['value']) != "") {
                    return <MenuItem key={state['key']} value={state['key']}>
                        {t(state['value'])}
                    </MenuItem>
                }
            }
            ),
        [addressesByState, t]
    );

    const temperatureMemo = useMemo(() =>
        TEMPERATURELIST.map((temps, i) => (
            <MenuItem key={i} value={temps}>
                {t(temps)}
            </MenuItem>
        )),
        [TEMPERATURELIST]
    );

    const companyListOptions = useMemo(() =>
        company.list.map((company, i) => (
            <MenuItem key={i} value={company.id}>
                {company.companyName}
            </MenuItem>
        )),
        [company.list]
    );

    const transportCompanyListOptions = useMemo(
        () =>
            company.list.map(
                (company, i) =>
                    company.companyType === 'transport_company' &&
                    typeof company.user !== 'undefined' && (
                        <MenuItem key={i} value={company.user.id}>
                            {company.companyName}
                        </MenuItem>
                    )
            ),
        [company.list]
    );


    const validateBooking = () => {
        if (!pickUpCountry || !deliveryCountry) {
            return true;
        }

        return pickUpCountry == deliveryCountry;
    };



    const handleAssociateGroupSelect = useCallback(
        (values: IAutocompleteOption[]) => {
            setSelectedAssociateGroups(values);
        },
        []
    );
    const [packageAllocation, setPackageAllocation] = useState([]);

    const onAllocateClick = useCallback(() => {

        let packages_list: any;

        if (setTypeOfPackageRef.current) {
            (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {

                packages_list = data
            });
        }
        setPackageAllocation(packages_list)
        // setAllocationSelectedJob({movement_type : movementType,
        //   pickup_address_id : pickUpAddressId ,
        //   delivery_address_id : deliverToAddressId,
        //   company_id : companyId,
        //   quantity :(typeOfPackageList[0] ? typeOfPackageList[0].quantity : 0),
        //   packages_type: packages_list
        // })
        setIsSelectUserDialogOpen(true);
    }, []);
    const onDocumentListClick = useCallback(() => {
        setDocumentUploading(true);
    }, []);




    const isShow = () => {
        return false
    }
    const isCNTRAllowed = () => {
        if ([FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)) {
            return true
        } else {
            return false
        }
    }


    const handleAddNewAddress = useCallback(
        () => {
            setSelectedAddress(undefined)
            setIsCreateAddressDialogOpen(true)
            setSelectedAddressMode('create')
        },
        [],
    )

    const getCustomerPerCompany = (search_id: any) => {
        customerApi.getAll(0, 99999, isUserAdmin, undefined, search_id, true)
            .then((response) => {
                const rows = response.data.rows;
                const count = response.data.count;
                setCustomerOptions(rows)
                return { jobs: rows, count }
            })
            .catch((err: any) => {
                toast.error(t('error_getting_customers'));
                return { jobs: [], count: 0 }

            });
    }
    const handleEditAddress = (dataAddress: any, addressType: string, addressId: string | undefined) => {
        setSelectedAddressMode('edit');
        setSelectedAddressType(addressType);

        // if (selectedJob) {
        //     setSelectedAddress(addressId);
        // } else if (addressId) {
        //     addressApi.byId(addressId)
        //         .then((response) => {
        //             const addresses = response.data;
        //             setSelectedAddress(addressId);
        //         })
        //         .catch((err: any) => {
        //             console.log('err', err);
        //             toast.error(t('error_getting_addresses'));
        //         })
        //         .finally(() => {
        //             dispatch(setLoader(false));
        //         });
        // }

        setIsCreateAddressDialogOpen(true);
    };

    const handleEditPickUpAddress = (dataAddress: any) => handleEditAddress(dataAddress, 'pickup', pickUpAddressId);
    const handleEditDeliverAddress = (dataAddress: any) => handleEditAddress(dataAddress, 'delivery', deliverToAddressId);
    const handleEditReturnAddress = (dataAddress: any) => handleEditAddress(dataAddress, 'return', returnToAddressId);
    const handleThirdLegEdit = (dataAddress: any) => handleEditAddress(dataAddress, 'thirdleg', thirdLegAddressId);



    const getMimeType = (fileName) => {
        const extension = (fileName || '').split('.').pop().toLowerCase();

        const mimeTypes = {
            pdf: 'application/pdf',
            jpg: 'image/jpeg',
            jpeg: 'image/jpeg',
            png: 'image/png',
        };
        return mimeTypes[extension] || '';
    };

    const handleFileClick = (file) => {
        if (adminPrivilege) {
            jobApi
                .downloadJobFile(file.job_id, file.type, file.uploaded_id)
                .then((response) => {

                    fileDownload(
                        response.data,
                        `${file.name}`,
                    );
                    dispatch(setLoader(false));
                })
                .catch((err: any) => {
                    console.log('err', err);
                    dispatch(setLoader(false));
                    toast.error('Cant download File');
                });
        }
    }




    const onCommentDialogUpdate = (comment: any, id: any) => {
        let rawJobComment = jobCommentData
        rawJobComment[currentSelectedNoteUser] = {
            comment_id: '',
            job_id: '',
            is_admin: isSuperAdmin,
            comment: comment,
            user_id: currentuserId,
            company_id: currentUserCompanyId
        }
        setJobCommentData(rawJobComment)

    };
    const handleCommentDialogClose = useCallback(() => {
        setIsCommentDialogOpen(false);
    }, []);
    const handleCommentDialogOpen = (type: boolean) => {
        setIsCommentDialogOpen(true);
        setCurrentSelectedNoteUser(type == false ? 'user' : 'admin')
    }


    //Address Placeholder
    useEffect(() => {
        let message = ''
        if (jobInstruction) { message += jobInstruction.concat("\n") }
        if (pickUpInstruction) {
            message += ["Pickup Instruction : ", pickUpInstruction].filter(Boolean).join("")
            message += "\n"
        }
        if (pickUpReceivingHours) {
            message += ["Pickup Receival hours : ", pickUpReceivingHours].filter(Boolean).join("");
            message += "\n"
        }
        if (deliverInstruction) {
            message += ["Delivery Instruction : ", deliverInstruction].filter(Boolean).join("")
            message += "\n"
        }
        if (deliveryReceivingHours) {
            message += ["Delivery Receival hours : ", deliveryReceivingHours].filter(Boolean).join("");
            message += "\n"
        }
        let instructions = message ? message.split('\n') : [];

        instructions = instructions.filter((str, index) => str !== '\n' || index === instructions.indexOf('\n'));
        const regex = /Pallet\sAccount|Pick\sUp\sPallet\sAccount/g;
        instructions = instructions.filter((instruction, index, arr) => {
            // Remove empty strings
            if (instruction.trim() === '') return false;
            // Remove duplicate instructions
            return arr.indexOf(instruction) === index;
        });
        for (let i = 0; i < instructions.length; i++) {
            if (regex.test(instructions[i])) {
                instructions[i] = '';
                break;
            }
        }
        // Define the new instruction based on the type and action
        let newInstruction = '';
        if (palletAccountType === 'PLAIN') {
            newInstruction = `Pallet Account: Plain Pallets`;
            setPalletAccountValue('Plain Pallets');
        } else if ((palletAccountType === 'CHEPS' || palletAccountType === 'LOSCAMS' || palletAccountType === 'OTHER') && palletAccountAction === 'exchange') {
            if (palletAccountType != 'OTHER') {
                setPalletAccountValue(`${palletAccountType} Exchange`);
                newInstruction = `Pallet Account: ${palletAccountType} Exchange`;

            } else {
                newInstruction = 'Pallet Account: Exchange';
                setPalletAccountValue(`Exchange`);
            }
        } else if ((palletAccountType === 'CHEPS' || palletAccountType === 'LOSCAMS' || palletAccountType === 'OTHER') && palletAccountAction === 'transfer') {
            const pickUpPalletsConfMatched = pickUpPalletsConf.filter(item => item.type === palletAccountType && item.action === palletAccountAction);
            const deliverPalletsConfMatched = deliverPalletsConf.filter(item => item.type === palletAccountType && item.action === palletAccountAction);
            newInstruction = `Pick Up Pallet Account: ${pickUpPalletsConfMatched.length > 0 ? pickUpPalletsConfMatched[0].value : ''}, Delivery Pallet Account: ${deliverPalletsConfMatched.length > 0 ? deliverPalletsConfMatched[0].value : ''}`;
            setDeliverPalletValue(`${(palletAccountType).toUpperCase()}  :  ${deliverPalletsConfMatched.length > 0 ? deliverPalletsConfMatched[0].value : ''}`)
            if (palletAccountType != 'OTHER') {
                setPalletAccountValue(`${palletAccountType} Transfer`);

            } else {
                setPalletAccountValue(`Transfer`);

            }
        } else {
            setPalletAccountValue('');
        }

        // Check if the new instruction already exists in the instructions array
        const instructionExists = instructions.some(instruction => instruction === newInstruction);

        // If the new instruction doesn't already exist, add it to the instructions array
        if (!instructionExists && newInstruction) {
            instructions.push(newInstruction);
        }
        // const newArray = array.filter((str, index, arr) => str !== '\n' || (index !== arr.length - 1 && arr[index + 1] !== '\n'));
        // Update the merged instruction state
        const newArray = instructions.filter(str => str !== '\n' || ' ');
        const newData = newArray.join('\n')
        setMergedInstruction(newData);
        // handlePalletAccountSelection(palletAccountType, palletAccountAction);

    }, [pickUpInstruction, deliverInstruction, jobInstruction, deliveryReceivingHours, pickUpReceivingHours, palletAccountType, palletAccountAction, triggerUpdate])

    const handleSelectDocumentChange = (e) => {
        setSelectedDocument(e.target.value);
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            file.checked = true;
            file.required = true;
            file.fileName = file.name;
            file.id = selectedDocument;

            if (['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
                setSelectedDocs(prevDocs => {
                    const updatedDocs = { ...prevDocs };
                    const docId = selectedDocument;

                    if (docId) {
                        file.document = documentLabelMap[selectedDocument];
                        updatedDocs[docId] = file;
                    }
                    return updatedDocs;
                });

                setFiles(prevFiles => [...prevFiles, file]);
                setSelectedDocument('');
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Reset file input value
                }
            } else {
                alert('Please select a PDF, JPG, or PNG file.');
            }
        }
    };

    const availableDocuments = rawDocument.filter((doc) => !Object.keys(selectedDocs).includes(doc.id.toString()));

    const handleRemoveFile = (docId) => {
        setSelectedDocs((prevDocs: any) => {
            const { [docId]: _, ...remainingDocs } = prevDocs;
            return remainingDocs;
        });

        setFiles(prevFiles => prevFiles.filter(file => file.id !== docId));
    };
    const getFileIcon = (fileType) => {
        switch (fileType) {
            case 'application/pdf':
                return <PictureAsPdfIcon className={classes.fileIcon} />;
            case 'image/jpeg':
            case 'image/png':
                return <ImageIcon className={classes.fileIcon} />;
            default:
                return null;
        }
    };
    const documentLabelMap = rawDocument.reduce((acc, doc) => {
        acc[doc.id] = doc.document;
        return acc;
    }, {});
    //endof

    const handleFormChange = (values: any) => {

        setMovementType(values.movementType);
        setJobType(values.jobType);
        setVehicleType(values.vehicleType);
        setTripType(values.tripType);
        setFreeText(values.freeText);
        setSKU(values.sku);
        setItemDesc(values.itemDesc)
    };
    const showServiceType = ['loose_cargo'].includes(movementType);

    return (
        <>



            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
                className={classes.root}

            >
                <SectionHeader subtitle={t('customer_management')} title={'Customer Rates'} />


                <CommentDialog
                    onBlurUpdate={onCommentDialogUpdate}
                    commentData={jobCommentData}
                    open={isCommentDialogOpen}
                    handleClose={handleCommentDialogClose}
                    isEditable={false}
                />
                <Grid md item>
                    <Grid container justifyContent="flex-start" direction="row" spacing={2}>

                        {readonly && (isSuperAdmin || isUserAdmin || isCompanyJobOwner) && status !== CREATED ? (
                            <Grid item md={12}>
                                <TextField
                                    disabled={true}
                                    value={biddingCompanyName}
                                    // onChange={(e) => setReference(e.target.value)}
                                    label={t('bidding_company_name')}
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: 'bold',
                                        },
                                    }}

                                />
                            </Grid>
                        ) : null}
                        {readonly && (isSuperAdmin || isUserAdmin || isCompanyJobOwner) && isBonds && status !== CREATED && isShow ? (
                            <Grid item md={7}>
                                <TextField
                                    disabled={true}
                                    value={bondsJobId}
                                    // onChange={(e) => setReference(e.target.value)}
                                    label={t('bonds_job_id')}
                                    fullWidth
                                    type="text"
                                    variant="outlined"
                                    InputLabelProps={{
                                        style: {
                                            fontWeight: 'bold',
                                        },
                                    }}

                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography variant="body1" gutterBottom>
                                {t('General')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <FormControl fullWidth variant="outlined">
                                <InputLabel>
                                    {t('select_company') + '*'}
                                </InputLabel>
                                <Select
                                    value={companyId}
                                    label={t('select_company')}
                                    onChange={(e) => {
                                        const companyId = e.target.value;
                                        const selectedCompany = company.list.find(
                                            (c) => c.id === companyId
                                        );

                                        if (selectedCompany) {
                                            setCountry(selectedCompany.country);
                                            getAddresses(companyId);
                                            setSelectedAssociateGroups([]);
                                            getCustomerPerCompany(companyId)
                                        }

                                        setCompanyId(companyId);
                                    }}
                                >
                                    {companyListOptions}
                                </Select>
                            </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel className={classes.selectLabel}>
                                    {t('select_customer')}
                                </InputLabel>
                                <Select
                                    value={selectedCustomer}
                                    onChange={(e) => { setSelectedCustomer(e.target.value) }}
                                >
                                    <MenuItem key="placeholder" value="" disabled>
                                        Select an option
                                    </MenuItem>
                                    {customerOptions.map((customer, i) => (
                                        <MenuItem key={i} value={customer['user']['company_id']}>
                                            {customer['company_name']}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>

                            <TransportSelector
                                disabled={readonly}
                                defaultValues={{
                                    movementType,
                                    jobType,
                                    vehicleType,
                                    tripType,
                                    freeText,
                                    sku,
                                    itemDesc
                                }}
                                onChange={handleFormChange}
                            />
                        </Grid>
                        {/* <Grid item md={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('job_type') + '*'}
                  </InputLabel>
                  <Select
                    disabled={readonly}
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value as string)}
                  >
                    {jobTypes.map((jobType, i) => (
                      <MenuItem key={i} value={jobType}>
                        {t(jobType)}
                      </MenuItem>
                    ))}
  
                  </Select>
                </FormControl>
              </Grid> */}
                        {/* <Grid item md={6}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('vehicle_type') + '*'}
                  </InputLabel>
                  <Select
                    disabled={readonly}
                    value={vehicleType}
                    onChange={(e) => setVehicleType(e.target.value as string)}
                  >
                    {useMemo(
                      () =>
                        (
                          get(VEHICLE_TYPES_CONFIG, [
                            country,
                            movementType,
                            tripType,
                            jobType,
                          ]) ?? []
                        ).map((vehicleType: string, i) => (
                          <MenuItem key={i} value={vehicleType}>
                            {t(vehicleType)}
                          </MenuItem>
                        )),
                      [country, t, movementType, tripType, jobType]
                    )}
                  </Select>
                </FormControl>
              </Grid> */}
                        <Grid item container spacing={2} md={12}>
                            {showTemperature() && (
                                <Grid item md={3}>
                                    <FormControl variant="filled" className={classes.formControl} fullWidth>
                                        <InputLabel className={classes.selectLabel}>
                                            {t('temperature') + '*'}
                                        </InputLabel>
                                        <Select
                                            disabled={readonly}
                                            value={temperature}
                                            onChange={(e) => setTemperature(e.target.value as string)}
                                        >
                                            {temperatureMemo}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}

                            {[CHILLED, FROZEN].includes(temperature) && showTemperature() && (
                                <Grid item md={3}>
                                    <TextField
                                        disabled={readonly}
                                        value={temperatureValue}
                                        label={t('temperature')}
                                        onChange={(e) => setTemperatureValue(e.target.value)}
                                        fullWidth
                                        // type="number"
                                        variant="outlined"
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>



                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 20 }}>
                            <Typography variant="body1" gutterBottom>
                                {t('pick_up_from') + '*'}
                            </Typography>
                        </Grid>
                        {!readonly ? (
                            <>
                                <Grid item md={12}>
                                    <AddressSelector
                                        addressEvent={handleAddNewAddress}
                                        addresses={addresses}
                                        refresh={addressRefresh}
                                        companyId={companyId}
                                        disabled={readonly}
                                        isEditMode={false}
                                        handleAddressSelected={(e: any) => {
                                            setPickUpAddressId(e.target.value as any);
                                            addressApi.byId(e.target.value as any).then((response) => {
                                                setPickupInstruction(response.data[0]['instruction'] || null)
                                                setPickUpPalletsConf(response.data[0]['pallet_configurations'] || [])
                                                setPickUpReceivingHours(response.data[0]['receiving_hours'] || null)
                                                setTriggerUpdate(triggerUpdate + 1)
                                            })
                                                .catch((err: any) => {
                                                    console.log('err', err);
                                                    toast.error(t('error_getting_addresses'));
                                                    dispatch(setLoader(false));
                                                });
                                        }

                                        }
                                        handleSelectedGroup={(e: any) => {
                                            setPickUpGroup(e.target.value as any);
                                        }}
                                        handleSelectedState={(e: any) => {
                                            setPickUpState(e.target.value as any);
                                        }}
                                        onEditClick={(e: any) => {
                                            handleEditPickUpAddress(e)
                                        }}
                                        handleSelectedCountry={(e: any) => {
                                            setPickUpCountry(e.target.value as any);
                                        }}
                                        defaultCountry={pickUpCountry}
                                        defaultState={pickUpState}
                                        defaultGroupType={pickUpGroup}
                                        defaultCompany={pickUpAddressId} // company name was shown, but address id
                                    />
                                </Grid>
                            </>)
                            : <Grid item md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    value={currentPickUpAddressString}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        }


                        {/* <Grid item md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('pick_up_date_time') + '*'}
                </Typography>
              </Grid> */}

                        <Grid item xs={12} sm={12} md={12}>
                            {(!readonly) ? (
                                <CustomDateTimePicker
                                    label="Select Date and Time"
                                    date_value={pickUpDateTime}
                                    onDateChange={setPickUpDateTime}
                                    readOnlyBidder={false}
                                    minimumDate={''}
                                />
                            ) : (
                                <TextField
                                    disabled={true}
                                    value={formatDateTimeDisplay(pickUpDateTime)}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <TodayIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 20 }}>
                            <Typography variant="body1" gutterBottom>
                                {t('deliver_to') + '*'}
                            </Typography>
                        </Grid>
                        {!readonly ? (
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <AddressSelector
                                        addressEvent={handleAddNewAddress}
                                        addresses={addresses}
                                        refresh={addressRefresh}
                                        companyId={companyId}
                                        disabled={readonly}
                                        isEditMode={false}
                                        handleAddressSelected={(e: any) => {
                                            setDeliverToAddressId(e.target.value as any);
                                            addressApi.byId(e.target.value as any).then((response) => {
                                                setDeliverInstruction(response.data[0]['instruction'] || null)
                                                setDeliveryReceivingHours(response.data[0]['receiving_hours'] || null)
                                                setDeliverPalletsConf(response.data[0]['pallet_configurations'] || [])
                                                setDeliverToStateType(response.data[0]['state_type'] || '')
                                                setTriggerUpdate(triggerUpdate + 1)
                                            })
                                                .catch((err: any) => {
                                                    console.log('err', err);
                                                    toast.error(t('error_getting_addresses'));
                                                    dispatch(setLoader(false));
                                                });
                                        }

                                        }
                                        handleSelectedGroup={(e: any) => {
                                            setDeliverToGroup(e.target.value as any);
                                        }}
                                        handleSelectedState={(e: any) => {
                                            setDeliverToState(e.target.value as any);
                                        }}
                                        handleSelectedCountry={(e: any) => {
                                            setDeliveryCountry(e.target.value as any);
                                        }}
                                        onEditClick={(e: any) => {
                                            handleEditDeliverAddress(e)
                                        }}
                                        defaultCountry={deliveryCountry}
                                        defaultState={deliverToState}
                                        defaultGroupType={deliverToGroup}
                                        defaultCompany={deliverToAddressId} // company name was shown, but address id
                                    />
                                </Grid>
                            </>)
                            : <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    disabled={true}
                                    value={currentDeliverToAddressString}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <LocationOnIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        }

                        {/* 
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="body1" gutterBottom>
                  {t('deliver_to_date_time') + '*'}
                </Typography>
              </Grid> */}
                        <Grid item xs={12} sm={12} md={12}>
                            {(!readonly) ? (
                                <CustomDateTimePicker
                                    label="Select Date and Time"
                                    date_value={deliveryDateTime}
                                    onDateChange={setDeliveryDateTime}
                                    readOnlyBidder={false}
                                    minimumDate={new Date(pickUpDateTime).toString()}
                                    key={pickUpDateTime}
                                />
                            ) : (
                                <TextField
                                    disabled={true}
                                    value={formatDateTimeDisplay(
                                        deliveryDateTime
                                    )}
                                    fullWidth
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <TodayIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </Grid>
                        {([ROUND_TRIP, RETURN_TRIP, MULTI_STOP].includes(tripType)) ? (
                            <>

                                <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 20 }}>
                                    <Typography variant="body1" gutterBottom>
                                        {t('return_to') + '*'}
                                    </Typography>
                                </Grid>
                                {!readonly ? (
                                    <>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <AddressSelector
                                                addressEvent={handleAddNewAddress}

                                                addresses={addresses}
                                                refresh={addressRefresh}
                                                companyId={companyId}
                                                disabled={readonly}
                                                isEditMode={false}
                                                handleAddressSelected={(e: any) => {
                                                    setReturnToAddressId(e.target.value as any);
                                                    // addressApi.byId(e.target.value as any).then((response) => {
                                                    //   setPickupInstruction(response.data[0]['instruction'] || null)
                                                    //   setPickUpPalletsConf(response.data[0]['pallet_configurations'] || [])
                                                    //   setPickUpReceivingHours(response.data[0]['receiving_hours'] || null)
                                                    //   setTriggerUpdate(triggerUpdate + 1)
                                                    // })
                                                    //   .catch((err: any) => {
                                                    //     console.log('err', err);
                                                    //     toast.error(t('error_getting_addresses'));
                                                    //     dispatch(setLoader(false));
                                                    //   });
                                                }

                                                }
                                                handleSelectedGroup={(e: any) => {
                                                    setReturnToGroup(e.target.value as any);
                                                }}
                                                handleSelectedState={(e: any) => {
                                                    setReturnToState(e.target.value as any);
                                                }}
                                                onEditClick={(e: any) => {
                                                    handleEditPickUpAddress(e)
                                                }}
                                                handleSelectedCountry={(e: any) => {
                                                    setReturnCountry(e.target.value as any);
                                                }}
                                                defaultCountry={returnCountry}
                                                defaultState={returnToState}
                                                defaultGroupType={returnToGroup}
                                                defaultCompany={returnToAddressId} // company name was shown, but address id
                                            />
                                        </Grid>
                                    </>)
                                    : <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                            value={currentReturnToAddressString}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                }
                                {/* <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t('return_to_date_time') + '*'}
                    </Typography>
                  </Grid> */}
                                <Grid item xs={12} sm={12} md={12}>
                                    {!readonly ? (

                                        <CustomDateTimePicker
                                            label="Select Date and Time"
                                            date_value={returnDateTime}
                                            onDateChange={setReturnDateTime}
                                            readOnlyBidder={false}
                                            minimumDate={new Date(deliveryDateTime).toString()}
                                        />

                                    ) : (
                                        <TextField
                                            disabled={true}
                                            value={formatDateTimeDisplay(returnDateTime)}
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TodayIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Grid>
                            </>
                        ) : null}

                        {[MULTI_STOP, RETURN_TRIP, ROUND_TRIP].includes(tripType) ? (
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography variant="body1" gutterBottom>
                                        {t('leg_3') + '*'}
                                    </Typography>
                                </Grid>
                                {!readonly ? (
                                    <>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <AddressSelector
                                                addressEvent={handleAddNewAddress}

                                                addresses={addresses}
                                                refresh={addressRefresh}
                                                companyId={companyId}
                                                disabled={readonly}
                                                isEditMode={false}
                                                handleAddressSelected={(e: any) => {
                                                    setThirdLegAddressId(e.target.value as any);
                                                    // addressApi.byId(e.target.value as any).then((response) => {
                                                    //   setDeliverInstruction(response.data[0]['instruction'] || null)
                                                    //   setDeliveryReceivingHours(response.data[0]['receiving_hours'] || null)
                                                    //   setDeliverPalletsConf(response.data[0]['pallet_configurations'] || [])
                                                    //   setDeliverToStateType(response.data[0]['state_type'] || '')
                                                    //   setTriggerUpdate(triggerUpdate + 1)
                                                    // })
                                                    //   .catch((err: any) => {
                                                    //     console.log('err', err);
                                                    //     toast.error(t('error_getting_addresses'));
                                                    //     dispatch(setLoader(false));
                                                    //   });
                                                }

                                                }
                                                handleSelectedGroup={(e: any) => {
                                                    setThirdLegGroup(e.target.value as any);

                                                }}
                                                handleSelectedState={(e: any) => {
                                                    setThirdLegState(e.target.value as any);
                                                }}
                                                onEditClick={(e: any) => {
                                                    handleThirdLegEdit(e)
                                                }}
                                                handleSelectedCountry={(e: any) => {
                                                    setThirdLegCountry(e.target.value as any);
                                                }}
                                                defaultCountry={thirdLegCountry}
                                                defaultState={thirdLegState}
                                                defaultGroupType={thirdLegGroup}
                                                defaultCompany={thirdLegAddressId}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            disabled={true}
                                            value={currentThirdLegToAddressString}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <LocationOnIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                )}
                            </>
                        ) : null}

                        {[MULTI_STOP, RETURN_TRIP, ROUND_TRIP].includes(tripType) ? (
                            <>
                                {/* <Grid item xs={12} sm={12} md={12}>
                    <Typography variant="body1" gutterBottom>
                      {t('third_leg_delivery') + '*'}
                    </Typography>
                  </Grid> */}
                                <Grid item xs={12} sm={12} md={12}>
                                    {!readonly ? (

                                        <CustomDateTimePicker
                                            label="Select Date and Time"
                                            date_value={thirdLegDateTime}
                                            onDateChange={setThirdLegDateTime}
                                            readOnlyBidder={false}
                                            minimumDate={''}
                                        />

                                    ) : (
                                        <TextField
                                            disabled={true}
                                            value={formatDateTimeDisplay(thirdLegDateTime)}
                                            fullWidth
                                            variant="outlined"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TodayIcon />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                </Grid>
                            </>
                        ) : null}


                    </Grid>
                    <Grid md={12} style={{ paddingTop: 20 }} item>
                        <RatesTableV2 />
                    </Grid>

                </Grid>
            </Grid>
        </>
    );
}

export default RatesManagementv2