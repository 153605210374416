
import { makeStyles, Box } from "@material-ui/core";
import clsx from "clsx";
import { useCallback, useEffect, useState, useRef } from "react";
import { useRouteMatch, Route, useHistory } from "react-router-dom";
import { routes } from "../../routes";

import AppHeader from "./AppHeader/AppHeader";
import SidebarV2 from "./SidebarV2/SidebarV2";
import UserManagement from "./UserManagement/UserManagement";
import TransportManagement from "./TransportManagement/TransportManagement";
import SeaFreightManagement from "./SeaFreightManagement/SeaFreightManagement";
import { Company } from "../../Api/Company/Company";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { ICompanyInfo } from "../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { setCompanyList } from "../../Redux/Company/actions";
import { setLoader } from "../../Redux/Loader/actions";
import { AppState } from "../../Redux";
import { USER_ROLES, TOKEN_STORAGE_KEY } from "../../Types/Constants";
import AdminSettings from "./AdminSettings/AdminSettings";
import CompanyManagement from "./CompanyManagement/CompanyManagement";
import CustomerManagement from "./CustomerManagement/CustomerManagement";
import WarehouseManagement from "./WarehouseManagement/WarehouseManagement";
import DriverManagement from "./DriverManagement/DriverManagement";
import DashboardMenu from "./DashboardMenu/DashboardMenu";
import DashboardV2 from "./DashboardMenu/DashboardMenuV2";
import KPIMenu from "./KPI/KPIMenu";
import Report from "./Report/Report";
import Quotes from "./Quotes/Quotes";
import { QUOTES_WILDCARDS_KEYS } from './Quotes/Quotes.constants';
import JobsList from "./JobsV2/JobsList";
import ShowQuotesLazy from './Quotes/ShowQuotes/ShowQuotesLazy';
import ChangePassword from "./ChangePassword/ChangePassword";
import { WebStorage } from '../../Utilities/WebStorage';
import { setThemeStyle } from "../../Redux/Theme/action"; // Import the action
import { JOBS_WILDCARDS_KEYS } from "./MapsV2/Jobs.constans";
import { UsersApi } from '../../Api/Users/Users';
import { logout } from '../../Redux/Auth/actions';
import SessionExpiredDialog from "../Dialogs/SessionTimeOut/SessionExpiredDialog";
import { setUserLoginResponse } from "../../Redux/User/actions";
import { ToastContent, ToastOptions } from 'react-toastify';
const { SUPER_ADMIN, ADMIN_USER, COMPANY } = USER_ROLES;

const SIDEBAR_WIDTH = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    // backgroundColor: 'white',
    // backgroundImage: "linear-gradient(-120deg, #FFB700 35%, transparent 30%), linear-gradient(-15deg, #223A59 50%, transparent 50%)"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    // backgroundColor: "#EAECEE",
    overflowY: 'auto',
    // backgroundColor: 'white',
    // backgroundImage: "linear-gradient(-120deg, rgb(202, 177, 35) 35%, transparent 30%), linear-gradient(-15deg, #223A59 50%, transparent 50%)"
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: SIDEBAR_WIDTH
  },
  innerBox: {
    width: "100%",
    minHeight: "90vh",
    // borderRadius: '16px',
    padding: '20px 10px 0px 20px',
   
  },
  outerBox: {
    backgroundColor: "#F0ECEC",
    // padding: '10px',
  }
}));

const companyApi = new Company();
const usersApi = new UsersApi()
const themes = {
  light: {
    name: 'default',
    sidebar_color: '#747474',
    sidebar_font_color: 'white',
    sidebar_width: SIDEBAR_WIDTH,
    navbar_color: '#CDA900',
    navbar_font: '#223A59'
  },
  default: {
    name: 'light',
    sidebar_color: 'white',
    sidebar_font_color: '#223A59',
    sidebar_width: SIDEBAR_WIDTH,
    navbar_color: '#1FA0ED',
    navbar_font: 'white'
    // linear-gradient(to right, 1FA0ED , yellow);

  }
}


function Home() {
  const [t] = useTranslation();
  const user = useSelector((state: AppState) => state.user);
  let userType = user?.response.type
  const isTranportCompany = user?.response.company.companyType == 'transport_company'
  if (isTranportCompany && userType == COMPANY) {
    userType = 'transport_company'
  }
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeSide, setActiveSide] = useState('');
  const match = useRouteMatch();
  const history = useHistory();
  const storage = new WebStorage(false);
  const themeStyle = useSelector((state: AppState) => state.theme.themeStyle);
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(false);
  const originalToastError = useRef<typeof toast.error | null>(null);
  useEffect(() => {
    if (originalToastError.current === null) {
      originalToastError.current = toast.error;
    }
    if (isTokenExpired) {
      // Override toast.error to do nothing
      toast.error = (content: ToastContent, options?: ToastOptions) => {
        return '';
      };
    } else if (originalToastError.current) {
      // Restore the original toast.error function
      toast.error = originalToastError.current;
    }
  }, [isTokenExpired]);
  useEffect(() => {
    const checkTokenExpiration = async () => {

      const accessToken = storage.retrieve(TOKEN_STORAGE_KEY); // Retrieve token from storage
      if (accessToken) {
        try {
          await usersApi.verify(accessToken)
            .then((response: any) => {

              if (response && response.status === 200) {
                setIsTokenExpired(false); // Token is valid
              } else {
                setIsTokenExpired(true); // Token is expired or invalid
              }
            })
            .catch((error: any) => {
              console.error('Token verification error:', error);
              // dispatch(logout());
              setIsTokenExpired(true);
            });
        } catch (error) {
          console.error('Token verification error:', error);
          // dispatch(logout());
          setIsTokenExpired(true);
        }
      } else {
        // dispatch(logout());

        setIsTokenExpired(true);
      }
    };

    checkTokenExpiration();
  }, []);
  const handleLogout = () => {
    storage.clear(TOKEN_STORAGE_KEY);
    dispatch(setUserLoginResponse(null));
    dispatch(logout());
    history.push(routes.landing.login[0]);
  };
  const toggleTheme = () => {
    const newTheme = themeStyle === 'default' ? 'light' : 'default';
    dispatch(setThemeStyle(newTheme));
  };

  const toggleSideBar = useCallback((isShow) => {
    setIsSidebarOpen(isShow);
  }, []);

  const isAdminUser = user.response?.type === ADMIN_USER;

  useEffect(() => {
    if ([SUPER_ADMIN, COMPANY].includes(user.response?.type || "") || isAdminUser) {
      companyApi
        .getAll(isAdminUser ? user.response?.country : undefined)
        .then((response) => {
          dispatch(setLoader(false));
          const list: ICompanyInfo[] = response.data;
          dispatch(setCompanyList(list));
        })
        .catch((err) => {
          console.log("err", err);
          // toast.error(t("error_get_company_list"));
        });
    }
  }, [dispatch, t, user.response?.type, isAdminUser, user.response?.country]);

  return (
    <div className={classes.root}>
      <AppHeader
        toggleSideBar={toggleSideBar}
        isSidebarOpen={isSidebarOpen}
        drawerWidth={SIDEBAR_WIDTH}
        themeStyle={themes[themeStyle]}
        toggleTheme={toggleTheme} // Pass toggleTheme to AppHeader
      />
      <div className={classes.main}>
        <SidebarV2
          isSidebarOpen={isSidebarOpen}
          toggleSideBar={toggleSideBar}
          drawerWidth={SIDEBAR_WIDTH}
          activeSide={activeSide}
          userType={userType}
          themeStyle={themes[themeStyle]}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: isSidebarOpen,
          })}
        >
          <SessionExpiredDialog open={isTokenExpired} onClose={() => handleLogout()} />
          <Box display="flex" justifyContent="center" className={classes.outerBox} alignItems="center">
            <Box className={classes.innerBox}>
              <Route path={match.path + routes.home.change_password}>
                <ChangePassword />
              </Route>
              {/* <Route path={match.path + routes.home.jobs.test}>
                <JobsList />
              </Route> */}
              <Route
                path={`${match.path}${routes.home.jobs.jobs}/:${JOBS_WILDCARDS_KEYS.SHOW_TYPE}`}
                component={({ match }) => (
                  <JobsList type="transport" pageType={match.params[JOBS_WILDCARDS_KEYS.SHOW_TYPE]} />
                )}
              />
              <Route path={match.path + routes.home.report.root}>
                <Report />
              </Route>
              <Route path={match.path + routes.home.dashboard_menu.root}>
                <DashboardMenu />
              </Route>
              <Route path={match.path + routes.home.dashboard_v2.root}>
                <DashboardV2 />
              </Route>
              <Route path={match.path + routes.home.kpi.root}>
                <KPIMenu />
              </Route>
              <Route path={match.path + routes.home.transport_management.root}>
                <TransportManagement />
              </Route>
              <Route path={match.path + routes.home.sea_freight_management.root}>
                <SeaFreightManagement />
              </Route>
              <Route path={match.path + routes.home.user_management.root}>
                <UserManagement />
              </Route>
              <Route path={match.path + routes.home.admin_settings.root}>
                <AdminSettings />
              </Route>
              <Route path={match.path + routes.home.company_management.root}>
                <CompanyManagement />
              </Route>
              <Route path={match.path + routes.home.customer_management.root}>
                <CustomerManagement />
              </Route>
              <Route path={match.path + routes.home.warehouse_management.root}>
                <WarehouseManagement />
              </Route>
              <Route path={match.path + routes.home.driver_management.root}>
                <DriverManagement />
              </Route>
              <Route path={match.path + routes.home.quotes.root}>
                <Quotes />
              </Route>
              <Route path={match.path + routes.home.quotes.list_quotes.root + `/:${QUOTES_WILDCARDS_KEYS.SHOW_TYPE}`}>
                <ShowQuotesLazy />
              </Route>
            </Box>
          </Box>
        </main>
      </div>
    </div>
  );
}

export default Home;
