import axios from 'axios';
import {
  IJobFilters,
} from '../../../../Application/DTOs/JobsDto/JobsDto.types';
import { TShowTypes } from '../../Components/Home/Jobs/Jobs.types';
import { TActualDateTypes, TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';

const baseUrl = process.env.REACT_APP_API_URL;

const storage = new WebStorage();

export class Reports {
  lazyGet(
    classification: string,
    page: TShowTypes,
    country: string | undefined,
    filters?: IJobFilters,
    pagetype?: string,
    movementType?: string,
    offset?: number,
    pageNumber?: number,
    limit?: number,
    order_by?: any
  ) {
    return axios.get(`${baseUrl}/reports/get/optimize`, {
      params: {
        classification,
        page,
        country,
        filters,
        pagetype,
        movementType,
        offset,
        pageNumber,
        limit,
        order_by
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }


  setInvoiced(job_id: any, invoice: string) {
    return axios.post(`${baseUrl}/reports/set/invoice`, { job_id, invoice }, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}
