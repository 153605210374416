import { makeStyles } from "@material-ui/core";
import { IImageProps } from "./Image.types";

const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    }
}))

function Image(props: IImageProps) {
    const {alt, src, onClick} = props
    const classes = useStyles()

    return (
        <img
            alt={alt}
            src={src}
            className={classes.image}
            onClick={onClick}
        />
    )

}

export default Image