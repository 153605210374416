import { Grid, Button, Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { BID_STATUS, USER_ROLES, MOVEMENT_TYPES, JOB_CLASSIFICATION } from "../../../Types/Constants";
import { IShowBidderPropsRow } from "./ShowBiddersDialog.types";

const {ACCEPTED, REJECTED} = BID_STATUS

const {
    DRIVER
} = USER_ROLES

const {LCL} = MOVEMENT_TYPES

const {TRANSPORT, SEA_FREIGHT} = JOB_CLASSIFICATION

export const getShowBiddersColumn = (t: TFunction, onBidStatusCallback: Function, movementType: string, classification: string): GridColumns => [
    {
        field: 'companyName',
        headerName: t('bidding_company_name_or_driver'),
        width: 250,
        renderCell: (params) => {
           
            const row: IShowBidderPropsRow = params.row as IShowBidderPropsRow
            const user = row.user
           
            let name = ''
           
            if(user?.type === DRIVER) {
                name = `${user.firstName} ${user.lastName}`
            } else {
                name = row.companyName
            }

            return <Typography>{name}</Typography>
        }
    },
    {
        field: 'type',
        headerName: t('type'),
        width: 150,
        renderCell: (params) => {
            const row: IShowBidderPropsRow = params.row as IShowBidderPropsRow
            const user = row.user

            return <Typography>{t(user?.type ?? '')}</Typography>
        }
    },
    {
        field: 'amount',
        headerName: t('bid_amount'),
        hide: movementType === LCL,
        width: 200
    },
    {
        field: 'waitTime',
        headerName: t('wait_time'),
        hide: false,
        width: 200
    },
    {
        field: 'waitTimeCharge',
        headerName: t('wait_time_charge'),
        hide: movementType === LCL,
        width: 200
    },
    {
        field: 'totalFreight',
        headerName: t('total_freight'),
        hide: movementType !== LCL,
        width: 180, 
    },
    {
        field: 'originCharges',
        headerName: t('origin_charges'),
        hide: movementType !== LCL,
        width: 180, 
    },
    {
        field: 'destinationCharges',
        headerName: t('destination_charges'),
        hide: movementType !== LCL,
        width: 220,
    },
    {
        field: 'convertedTotalFreight',
        headerName: t('total_freight_charges'),
        hide: movementType !== LCL,
        width: 180, 
    },
    {
        field: 'convertedOriginCharges',
        headerName: t('origin_total_cost'),
        hide: movementType !== LCL,
        width: 180, 
    },
    {
        field: 'convertedDestinationCharges',
        headerName: t('destination_total_charge'),
        hide: movementType !== LCL,
        width: 220,
    },
    {
        field: 'action',
        headerName: t('action'),
        width: 250,
        sortable: false,
        renderCell: (params) => (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button onClick={onBidStatusCallback(ACCEPTED, params.row)} fullWidth variant="contained" color="primary">
                            {t('accept')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={onBidStatusCallback(REJECTED, params.row)} fullWidth variant="contained" color="secondary">
                            {t('reject')}
                        </Button>
                    </Grid>
                </Grid>
            </>
        )
    }
]