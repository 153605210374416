// CountrySelect.js
import React from 'react';
import { FormControl, Select, MenuItem, InputLabel, Dialog, DialogContent, DialogTitle, Typography, DialogActions, Button, makeStyles, Grid, TextField, InputAdornment } from "@material-ui/core";
import { COUNTRIES, ADDRESS_GROUPS, USER_ROLES, AU_STATES } from "../../../Types/Constants";
const { PHILIPPINES, AUSTRALIA, JAPAN, SINGAPORE, NEW_ZEALAND } = COUNTRIES

const useStyles = makeStyles(() => ({
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green'
        }
    },
    formControl: {
        minWidth: '100%',
    }
}))

const CountrySelect = ({ value, onChange, disabled, isPreview, isEditable, t }) => {
    const classes = useStyles()

    return (
        <FormControl fullWidth variant="outlined" >
            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_your_country')}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={t('select_your_country')}
                disabled={isPreview && !isEditable}

            >
                <MenuItem value="">
                    <em>Select {t('country')}</em>
                </MenuItem>
                <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                <MenuItem value={NEW_ZEALAND}>{t(NEW_ZEALAND)}</MenuItem>
            </Select>
        </FormControl>
    );
};

export default CountrySelect;
