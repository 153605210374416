import { useTranslation } from "react-i18next"
import React, { useCallback, useEffect, useState } from 'react'
import { Divider, Grid, Typography, MenuItem, FormControl, InputLabel, Select, makeStyles, Button, FormControlLabel, Checkbox ,Accordion , AccordionSummary ,AccordionDetails  } from "@material-ui/core"
import { COUNTRIES, JOB_CLASSIFICATION, JOB_TYPES, MOVEMENT_TYPES, USER_ROLES , TRIP_TYPE } from "../../../../Types/Constants"
import MultiAutocomplete from "../../../Common/MultiAutocomplete/MultiAutocomplete"
import { IAutocompleteOption } from "../../../Common/types"
import { ICountryConfiguration , IConfiguration } from '../../../../../../Application/Models/CountryConfigurations/CountryConfigurations.types'
import { useDidStateUpdate } from "../../../../Utilities/CustomHooks"
import { AdminSettings } from "../../../../Api/AdminSettings/AdminSettings"
import { useDispatch, useSelector } from "react-redux"
import { setLoader } from "../../../../Redux/Loader/actions"
import { toast } from "react-toastify"
import { isEmpty } from "lodash"
import { AppState } from "../../../../Redux"
import SaveIcon from '@material-ui/icons/Save';
import AccordionConfig from './Accordion'
const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    }
}))

const {
    SEA_FREIGHT: SEA_FREIGHT_CLASSIFICATION,
    TRANSPORT
} = JOB_CLASSIFICATION

const {
    ONE_WAY,
    ROUND_TRIP
} = TRIP_TYPE

const {
    EMPTY_PARK,
    FCL,
    FULL_TRUCK_LOAD,
    LAND_FREIGHT,
    LCL,
    LOOSE_CARGO,
    OTHER,
    RAIL,
    SEA_FREIGHT,
    WHARF
} = MOVEMENT_TYPES

const MOVEMENT_TYPE_OPTIONS = [
    EMPTY_PARK,
    FCL,
    FULL_TRUCK_LOAD,
    LAND_FREIGHT,
    LCL,
    LOOSE_CARGO,
    OTHER,
    RAIL,
    SEA_FREIGHT,
    WHARF
]

const JOB_TYPES_OPTIONS = Object.values(JOB_TYPES)

const adminSettingApi = new AdminSettings()

const {
    ADMIN_USER
} = USER_ROLES

const toValue = (opt: IAutocompleteOption): string => opt.value

function CountrySettingsV2() {
    const [t] = useTranslation('CountrySettings')
    const user = useSelector((state: AppState) => state.user)
    const isUserAdmin = user.response?.type === ADMIN_USER
    const dispatch = useDispatch()
    const classes = useStyles()
    const [country, setCountry] = useState(isUserAdmin ? user.response?.country ?? AUSTRALIA : AUSTRALIA)
    const [selectedMovementTypes, setSelectedMovementTypes] = useState<IAutocompleteOption[]>([])
    const [selectedJobTypes, setSelectedJobTypes] = useState<IAutocompleteOption[]>([])
    const [countryConfig, setCountryConfig] = useState<ICountryConfiguration | null>(null)

    const [newConfigurationData, setNewConfig] = useState<IConfiguration | null>(null)

    const [selectedClassification, setSelectedClassification] = useState('')
    const [selectedTripType, setSelectedTripType] = useState('')

    const [isTransportManagementEnabled, setIsTransportManagementEnabled] = useState(false)
    const [isSeaFreightManagementEnabled, setIsSeaFreightManagementEnabled] = useState(false)
    
    const [selectedJobArray, setSelectedJobArray] = useState({})
    const [existingConfig,setExistingConfig] = useState({})

    const jobArrayChange= (data : any )=> {
        setSelectedJobArray(data)
    }
   
    const toOption = useCallback(
        (val: string): IAutocompleteOption => {

            return {
                label: t(val),
                value: val
            }
        },
        [t],
    )


    // useDidStateUpdate(() => {
    //     if (countryConfig) {
    //         if (selectedClassification !== '') {
    //             setSelectedMovementTypes(countryConfig.jobSettings[selectedClassification].movementTypes.map(toOption))
    //             setSelectedJobTypes(countryConfig.jobSettings[selectedClassification].jobTypes.map(toOption))
    //         }
    //         setIsTransportManagementEnabled(countryConfig.menuSettings.isTransportFreightEnabled)
    //         setIsSeaFreightManagementEnabled(countryConfig.menuSettings.isSeaFreightEnabled)
    //     }

    // }, [countryConfig, selectedClassification])

    // const getConfig = useCallback(
    //     (country: string) => {
    //         dispatch(setLoader(true))
    //         adminSettingApi.getCountryConfig(country)
    //             .then(response => {
    //                 setCountryConfig(response.data)
    //                 dispatch(setLoader(false))
    //             })
    //             .catch(err => {
    //                 console.log(`err`, err)
    //                 dispatch(setLoader(false))
    //                 toast.error(t('error_getting_config'))
    //             })
    //     },
    //     [dispatch, t],
    // )

    const getConfigV2 = useCallback(
        (country: string , classification : string , selectedTripType:string) => {
            dispatch(setLoader(true))
            adminSettingApi.getTypeConfig(country,classification , selectedTripType)
                .then(response => {
                    setExistingConfig(response.data)
                    // setSelectedClassification(response.data.classification)
                    setSelectedMovementTypes(response.data.movement_types.map(toOption))
                    

                    setIsTransportManagementEnabled(response.data.menu_settings.isTransportFreightEnabled)
                    setIsSeaFreightManagementEnabled(response.data.menu_settings.isSeaFreightEnabled)

                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log(`err`, err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_config'))
                })
        },
        [dispatch, t],
    )

    useEffect(() => {
        if (!isEmpty(country) && !isEmpty(selectedClassification) && !isEmpty(selectedTripType))

        // getConfig(country)
        getConfigV2(country,selectedClassification,selectedTripType)

    }, [  country, selectedClassification, selectedTripType])

    const handleSaveClick = useCallback(
        () => {
            if(!selectedJobArray) return
            // let newConfig: ICountryConfiguration = {
            //     ...countryConfig,
            //     menuSettings: {
            //         isSeaFreightEnabled: isSeaFreightManagementEnabled,
            //         isTransportFreightEnabled: isTransportManagementEnabled
            //     }

            // }

            // if (selectedClassification !== '') {
            //     newConfig = {
            //         ...newConfig,
            //         jobSettings: {
            //             ...countryConfig?.jobSettings,
            //             [selectedClassification]: {
            //                 jobTypes: selectedJobTypes.map(toValue),
            //                 movementTypes: selectedMovementTypes.map(toValue)
            //             }
            //         },
            //     }
            // }
            const newConfigData = {
                country : country,
                trip_type:selectedTripType,
                classification : selectedClassification,
                movementTypes : selectedMovementTypes.map(toValue),
                jobTypes : selectedJobArray,
                menuSettings: {
                    isSeaFreightEnabled: isSeaFreightManagementEnabled,
                    isTransportFreightEnabled: isTransportManagementEnabled
                }
            }


            // dispatch(setLoader(true))

            // adminSettingApi.saveCountryConfig(country, newConfig)
            //     .then(() => {
            //         dispatch(setLoader(false))
            //         // toast.success(t('success_saving_config'))
            //     })
            //     .catch(err => {
            //         console.log(`err`, err)
            //         dispatch(setLoader(false))
            //         toast.error(t('error_saving_config'))
            //     })
             adminSettingApi.saveCountryConfigV2(country, newConfigData)
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_saving_config'))
                })
                .catch(err => {
                    console.log(`err`, err)
                    dispatch(setLoader(false))
                    toast.error(t('error_saving_config'))
                })
        },
        [dispatch, t, country, selectedJobTypes, selectedMovementTypes, selectedClassification, newConfigurationData , countryConfig, isSeaFreightManagementEnabled, isTransportManagementEnabled , selectedJobArray],
    )
  

    return (
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={5}

        >
            <Grid item>
                <Typography>
                    {t('country_settings')}
                </Typography>
            </Grid>
           
            <Grid item>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid md={6} item>
                        {/* <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                            <Select
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value as string)
                                    setSelectedMovementTypes([])
                                    setSelectedJobTypes([])
                                }}
                            >
                                <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                            </Select>
                        </FormControl> */}
                    </Grid>

                    <Grid item container spacing={2}>
                        <Grid item md={4}>
                            <Grid md={12}>
                                <Typography>
                                    {t('movement_type_n_job_type_settings')}
                                </Typography>
                            </Grid>
                            <Grid md={12} item>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel style={{ fontWeight: 'bold' }}>{t('classification')}</InputLabel>
                                    <Select
                                        value={selectedClassification}
                                        onChange={(e) => {
                                            setSelectedClassification(e.target.value as string)
                                        }}
                                    >
                                        <MenuItem value={TRANSPORT}>{t(TRANSPORT)}</MenuItem>
                                        <MenuItem value={SEA_FREIGHT_CLASSIFICATION}>{t(SEA_FREIGHT_CLASSIFICATION)}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item md={4}>
                            <Grid md={12}>
                                <Typography>
                                    {t('trip_type')}
                                </Typography>
                            </Grid>
                            <Grid md={12} item>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel style={{ fontWeight: 'bold' }}>{t('trip_type')}</InputLabel>
                                    <Select
                                        value={selectedTripType}
                                        onChange={(e) => {
                                            setSelectedTripType(e.target.value as string)
                                        }}
                                    >
                                        <MenuItem value={ONE_WAY}>{t(ONE_WAY)}</MenuItem>
                                        <MenuItem value={ROUND_TRIP}>{t(ROUND_TRIP)}</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>



                  
                    </Grid>

                    <Grid md={12}  item>
                            <MultiAutocomplete
                                disabled={country === '' && selectedClassification === ''}
                                label={t('movement_type')}
                                options={MOVEMENT_TYPE_OPTIONS.map(toOption)}
                                values={selectedMovementTypes}
                                onChange={(values) => setSelectedMovementTypes(values)}
                            />
                    </Grid>
                    <Grid md={12} item >

                        <AccordionConfig
                            types={selectedMovementTypes}
                            country={country}
                            selectedClassification={selectedClassification}
                            job_types_option={JOB_TYPES_OPTIONS}
                            jobArrayChange={jobArrayChange}
                            existingConfig={existingConfig}
                        />
                        </Grid>


                    {/* <Grid md={12} item>
                        <MultiAutocomplete
                            disabled={country === '' && selectedClassification === ''}
                            label={t('job_type')}
                            options={JOB_TYPES_OPTIONS.map(toOption)}
                            values={selectedJobTypes}
                            onChange={(values) => setSelectedJobTypes(values)}
                        />
                    </Grid>
 */}
                    
                   
                    <Grid item md={12}>
                        <Typography>
                            {t('menu_settings')}
                        </Typography>
                    </Grid>

                    <Grid md={12} item>
                        <FormControlLabel
                            control={<Checkbox checked={isTransportManagementEnabled} onChange={e => setIsTransportManagementEnabled(e.target.checked)} color="primary" />}
                            label={t('transport_management')}
                        />
                    </Grid>

                    <Grid md={12} item>
                        <FormControlLabel
                            control={<Checkbox checked={isSeaFreightManagementEnabled} onChange={e => setIsSeaFreightManagementEnabled(e.target.checked)} color="primary" />}
                            label={t('sea_freight_management')}
                        />
                    </Grid>


                    <Grid md={12} item>
                        <Button onClick={handleSaveClick} startIcon={<SaveIcon />} variant="contained" color="primary">
                            {t('save')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

}

export default CountrySettingsV2