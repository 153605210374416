import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, makeStyles, Grid, TextField, InputAdornment, FormControlLabel, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IMarginInputDialogProps } from "./MarginInputDialog.types";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DialPad from '@material-ui/icons/Dialpad';
import { useEffect, useState } from "react";
import { IMarginFee } from "../../../../../Application/DTOs/MarginFeeSettingsDto/MarginFeeSettingsDto.types";
const useStyles = makeStyles(() => ({
    saveBtn: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
            color: 'white',
        }
    }
}))

const INITIAL_MARGIN_FEE: IMarginFee = {
    isActive: true,
    maxIndicator: 0,
    price: 0
}

function MarginInputDialog(props: IMarginInputDialogProps) {
    const { open, handleClose, onSaveClick, currentMarginFee } = props
    const [t] = useTranslation('MarginInputDialog')
    const classes = useStyles()
    const [maxPriceIndicator, setMaxPriceIndicator] = useState(INITIAL_MARGIN_FEE.maxIndicator)
    const [price, setPrice] = useState(INITIAL_MARGIN_FEE.price)
    const [isActive, setIsActive] = useState(INITIAL_MARGIN_FEE.isActive)

    useEffect(() => {
        if(currentMarginFee) {
            setMaxPriceIndicator(currentMarginFee.maxIndicator)
            setPrice(currentMarginFee.price)
            setIsActive(currentMarginFee.isActive)
        } else {
            setMaxPriceIndicator(INITIAL_MARGIN_FEE.maxIndicator)
            setPrice(INITIAL_MARGIN_FEE.price)
            setIsActive(INITIAL_MARGIN_FEE.isActive)
        }
    }, [currentMarginFee])


    useEffect(() => {
        if(!open) {
            setMaxPriceIndicator(INITIAL_MARGIN_FEE.maxIndicator)
            setPrice(INITIAL_MARGIN_FEE.price)
            setIsActive(INITIAL_MARGIN_FEE.isActive)
        }
        
    }, [open])

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('input_margin')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item md={12}>
                            <TextField
                                value={maxPriceIndicator}
                                onChange={(e) => setMaxPriceIndicator(Number(e.target.value))}
                                variant="outlined"
                                fullWidth
                                label={t('provide_max_price_indicator')}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialPad />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                value={price}
                                onChange={(e) => setPrice(Number(e.target.value))}
                                variant="outlined"
                                fullWidth
                                label={t('provide_price_margin')}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialPad />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
                                label={t('active')}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        if(onSaveClick) {
                            onSaveClick(currentMarginFee === undefined, {
                                price,
                                isActive,
                                maxIndicator: maxPriceIndicator
                            })
                        }
                    }} className={classes.saveBtn} startIcon={<CheckIcon />} variant="contained">
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} startIcon={<ClearIcon />} variant="contained">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default MarginInputDialog