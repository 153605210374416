import { Grid, Typography, Divider, Button, TextField, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { Warehouse } from '../../../../Api/Warehouse/Warehouse'

import { AppState } from '../../../../Redux'

import { setLoader } from '../../../../Redux/Loader/actions'

import { getColumns } from './StocksPage.columns'
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import LazyTable from '../../../Common/LazyTable/LazyTable';
import { USER_ROLES } from '../../../../Types/Constants';
import SitesSelection from './SitesSelection'
import StocksDialog from '../../../Dialogs/StocksDialog/StocksDialog'
import CollapsibleTable from './SpanningTable'
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'

const warehouseApi = new Warehouse()
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    },
    container: {
        padding: '20px'
    }
}))

function StocksPage() {
    const [t] = useTranslation(['Warehouse', 'Columns'])


    const dispatch = useDispatch()
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | number | null>(null)

    const [viewDialog, setViewDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState('');

    const user = useSelector((state: AppState) => state.user.response);
    const { ADMIN_USER, SUPER_ADMIN, WAREHOUSE, CUSTOMER } = USER_ROLES;
    const isCustomer = [CUSTOMER].includes(user?.type);

    let userId = user.company.id
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user?.type);
    const [rowData, setRowData] = useState<any[]>([])

    const isWarehouse = [WAREHOUSE].includes(user?.type);
    if (isCustomer) { userId = user.customer.id }
    if (isWarehouse) { userId = user.warehouse.id }
    const getAll = async (site_id?: any) => {
        return warehouseApi.getAllProductList(userId, site_id)
            .then((response) => {
                setRowData(response.data)

            })
            .catch((err: any) => {
                toast.error(t('error_getting_stocks'));
                return { jobs: [], count: 0 }

            });

    }


    useEffect(() => {
        getAll()
    }, [])

    const handleDialogclose = useCallback(
        () => {
            setViewDialog(false)
            getAll()
        },
        [selectedCustomerId],
    )
    const onAddClick = () => {
        setDialogMode('add')
        setViewDialog(true)
    }

    const filterCallback = useCallback((site_id: any) => {
        getAll(site_id)
    }, [])
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("warehouse_management")} title={t('stocks')} />
                <Grid md item>
                    <Typography>
                        {t('stocks')}
                    </Typography>
                </Grid>
               

                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">

                        <Grid md={12} item container>
                            <Grid md={2} item container>
                                <SitesSelection onChangeCallback={filterCallback} />
                            </Grid>
                            <Grid item md={8}>
                            </Grid>


                            <Grid item md={2}>
                                <Button
                                    onClick={onAddClick}
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('add')}
                                </Button>
                            </Grid>

                        </Grid>


                        <Grid item md={12}>
                            <CollapsibleTable rows={rowData} />
                            {/* <LazyTable
                                columns={columns}
                                onRowClick={onRowClick}
                                lazyFunction={lazyGet}
                                lazyFilter={filterState}
                                retainCurrent={retainCurrent}

                            /> */}

                        </Grid>
                        <StocksDialog open={viewDialog} handleClose={handleDialogclose} dialogMode={dialogMode} />
                    </Grid>
                </Grid>

            </Grid >
        </>
    )
}

export default StocksPage