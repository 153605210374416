import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "../../../routes"
import KPIDashboard from "./KPIDashboard"
import Dashboard from "../DashboardMenu/Dashboard"


function KPIMenu() {
    const match = useRouteMatch()
    return (
        <>
            <Route path={match.path + routes.home.kpi.dashboard}>
                <Dashboard />
            </Route>
        </>
    )
}

export default KPIMenu