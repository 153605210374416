


export class WebStorage {

    // will use session storage if true
    private storage: Storage

    constructor(shouldUseSession: boolean = false) {
        this.storage = shouldUseSession ? sessionStorage : localStorage
    }

    store(key: string, item: any) {
        this.storage.setItem(key, item)
    }

    retrieve(key: string) {
        return this.storage.getItem(key)
    }

    clear(key?: string) {
        if(key) {
            this.storage.removeItem(key)
        } else {
            this.storage.clear()
        }
    }
}

export const WEB_STORAGE_KEYS = {
    PREFERRED_LANGUAGE: 'PREFERRED_LANGUAGE'
}