import {
    Button,
    Dialog,
    makeStyles,
    DialogActions,
    InputAdornment,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    Badge
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreateNewJob from './CreateNewJob/CreateNewJob';
import { IViewEditJobProps } from '../../Dialogs/ViewEditJob/ViewEditJob.types';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../Redux';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Job } from '../../../Api/Job/Job';
import { toast } from 'react-toastify';
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { setLoader } from '../../../Redux/Loader/actions';
import CreateNewJobSeaFreight from '../../Home/Jobs/CreateNewJobSeaFreight/CreateNewJobSeaFreight';
import SelectUserDialog from '../../Home/Jobs/CreateNewJob/SelectUserDialog/SelectUserDialog';
// import CommentDialog from '../CommentDialog/CommentDialog';
import CommentDialogV2 from '../../Dialogs/CommentDialog/CommentDialogV2';


import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants';
import GetAppIcon from '@material-ui/icons/GetApp';
import fileDownload from 'js-file-download';
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import DoneIcon from '@material-ui/icons/Done';
import { ASSIGNED } from '../../Home/Jobs/Jobs.constans';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
const { SUPER_ADMIN } = USER_ROLES;
const useStyles = makeStyles(() => ({
    cloneBtn: {
        backgroundColor: '#7FB3D5',
        '&:hover': {
            backgroundColor: '#7FB3D5',
        },
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        },
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
}));

const CLONE_AMOUNT = {
    MIN: 1,
    MAX: 10,
};

const jobApi = new Job();

const { COMPLETED, IN_PROGRESS, DELIVERED, PICKEDUP, ARCHIVE } = JOB_STATUS;


interface ViewEditJobDialogProps {
    open: boolean;
    handleClose: () => void;
    mode: 'create' | 'edit' | string;
    selectedJobId: number | null;
    hideActionButtons?: boolean;
    isInProgress?: boolean;
    type: string
}
function ViewEditJob(props: ViewEditJobDialogProps) {
    const { selectedJobId, mode, open, handleClose, type, hideActionButtons } = props
    const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);
    const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);
    const [compKey, setCompKey] = useState(0);

    // const { selectedJob: job, open, handleClose, type, hideActionButtons } = props;

    const isInProgress = false;
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user);
    const [cloneAmount, setCloneAmount] = useState(`${CLONE_AMOUNT.MIN}`);
    const [isCloneMode, setIsCloneMode] = useState(false);
    const [t] = useTranslation();
    const classes = useStyles();
    const [readOnly, setReadOnly] = useState(true);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isBidReadOnly, setIsBidReadOnly] = useState(true);

    const createNewJobRef = useRef();
    const [selectedJob, setSelectedJob] = useState<IJobInfo | undefined>(undefined);
    const [isAssignedUser, setIsAssignedUser] = useState(false);
    const [isJobOwner, setIsJobOwner] = useState(user.response?.company?.id as unknown as string == selectedJob?.company_id as unknown as string);
    const [cntrReadOnly, setcntrReadOnly] = useState<boolean>(true);
    const [documentUploadReadOnly, setDocumentUploadReadOnly] = useState<boolean>(true);
    const [jobCommentData, setJobCommentData] = useState<any>([])

    // const [postingCommentVisibility, setPostingCommentVisibility] = useState<boolean>(true);
    let isCurrentUserJobOwner = user.response?.company?.id as unknown as string == selectedJob?.company_id as unknown as string;
    let currentuserid = user.response?.company?.id
    let isAdminUser = user.response?.type === SUPER_ADMIN;
    if (isAdminUser) {
        isCurrentUserJobOwner = true
    }

    const isCurrentJobDelivered = [DELIVERED].includes(
        selectedJob?.status || ''
    );
    const isCurrentJobCompleted = [COMPLETED].includes(
        selectedJob?.status || ''
    );
    const isCurrentJobInProgress = [ARCHIVE, IN_PROGRESS, ASSIGNED, PICKEDUP, COMPLETED, DELIVERED].includes(
        selectedJob?.status || ''
    );
    const isCurrentJobComplete = selectedJob?.status === COMPLETED;

    const handleEditClick = () => {
        setIsCloneMode(false);
        if (isCurrentUserJobOwner || isJobOwner) {
            setReadOnly(false);
            setcntrReadOnly(false)
            setDocumentUploadReadOnly(false)
            setIsBidReadOnly(false)
        } else if (isAssignedUser) {
            setReadOnly(true);
            setIsBidReadOnly(false)
        }
        if (readOnly && isAssignedUser) {
            setcntrReadOnly(false)
            setDocumentUploadReadOnly(false)
        }
        setIsEditMode(true);
    };

    const handleCloneClick = () => {
        setIsCloneMode(true);
        setReadOnly(true);
        setIsEditMode(false);
    };
    const handleEditPickupAddress = () => {
        setIsCloneMode(true);
        setReadOnly(true);
        setIsEditMode(false);
    };

    const handleCancelClick = () => {
        setIsCloneMode(false);
        setIsEditMode(false);
        setIsBidReadOnly(true)
        setReadOnly(true);
        setcntrReadOnly(true)
        setDocumentUploadReadOnly(true)

        handleClose()
    };
    const onAllocateClick = useCallback(() => {
        setIsSelectUserDialogOpen(true);
    }, []);



    const handleAllocate = (userId: string | number, sell_price: string, buy_price: string, temperature: string) => {
        dispatch(setLoader(true));
        jobApi
            .allocateJob(selectedJob?.id || 0, userId, sell_price, buy_price, temperature)
            .then(() => {
                dispatch(setLoader(false));
                toast.success(t('success_allocating_job'));
                handleSelectUserDialogClose();

                // history.push(
                //   routes.home.root +
                //     jobManagementRoot +
                //     routes.home.jobs.show_jobs.root +
                //     routes.home.jobs.show_jobs.my_in_progress
                // );
            })
            .catch(async (err: any) => {
                dispatch(setLoader(false));
                let errorres = err.response.data.response
                const errorVal = Object.keys(errorres).map(key => errorres[key]);
                let listItems: any = []
                await errorVal.forEach((errorMsg) => {
                    listItems.push(errorMsg)
                })
                if (listItems.length > 0) {
                    toast.error(({ closeToast }) => <div>
                        <ul>
                            {listItems.map((item: any) => <li>{item}</li>)}
                        </ul>
                    </div>
                    );
                } else {
                    toast.error(({ closeToast }) => t('error_allocating_job'));
                }
            });


    }

    const onSelectedUserCallback = (userId: string | number, sell_price: string, buy_price: string, temperature: string) => {
        handleAllocate(userId, sell_price, buy_price, temperature);
    };

    const handleSelectUserDialogClose = useCallback(() => {
        setIsSelectUserDialogOpen(false);
    }, []);

    const handleOnUpdateClick = useCallback(() => {
        if (!selectedJob) return;

        if (isCloneMode) {
            jobApi
                .clone(selectedJob.id, cloneAmount)
                .then(() => {
                    toast.success(t('success_cloning_job'));
                    handleCancelClick();
                })
                .catch((err: any) => {
                    console.log('err', err);
                    toast.error(t('error_cloning_job'));
                });
        } else if (isEditMode) {
            if (createNewJobRef.current) {
                (createNewJobRef.current as any).handleUpdate(() => {
                    handleCancelClick();
                });
            }
        }
    }, [cloneAmount, selectedJob, isCloneMode, isEditMode, t]);

    const getJobDetails = useCallback(
        (job: IJobInfo) => {
            dispatch(setLoader(true));
            jobApi
                .jobDetail(job.id)
                .then(async (response) => {
                    const details: IJobInfo = response.data;
                    // if(details.assignedUser?.id == user.response?.id){
                    //   setIsCurrentUserJobOwner(true)
                    // }
                    setSelectedJob(details);
                    if (details?.assignedUser?.company?.id == user.response?.company?.id) {
                        setIsAssignedUser(true)
                    }

                    dispatch(setLoader(false));
                }).catch((err: any) => {
                    console.log('err', err);
                    toast.error(t('error_getting_job_details'));
                    dispatch(setLoader(false));
                });


        },
        [dispatch, t]
    );
    useEffect(() => {
        if (selectedJob) {
            setIsJobOwner(user.response?.company?.id == selectedJob?.company_id as unknown as number)
        }
    }, [selectedJob])

    useEffect(() => {
        if (mode === 'edit' && selectedJobId) {
            jobApi.jobDetail(selectedJobId)
                .then((response) => {
                    const job: IJobInfo = response.data;
                    setSelectedJob(job);
                    setCompKey(prevKey => prevKey + 1); // Update key to force re-render
                })
                .catch((err: any) => {
                    console.log('err', err);
                });
        } else {
            setSelectedJob(null);
            setCompKey(prevKey => prevKey + 1); // Update key to force re-render
        }
    }, [mode, selectedJobId]);

    const handleOnDownloadAllJobFilesClick = useCallback(() => {
        if (selectedJob) {
            dispatch(setLoader(true));
            jobApi
                .downloadAllJobFiles(selectedJob.id)
                .then((response) => {
                    fileDownload(response.data, 'job_files.zip', 'application/zip');
                    dispatch(setLoader(false));
                })
                .catch((err: any) => {
                    console.log('err', err);
                    toast.error(t('error_downloading_all_job_files'));
                    dispatch(setLoader(false));
                });
        }
    }, [dispatch, t, selectedJob]);

    const handleCommentDialogOpen = (type: boolean) => {

        jobApi.getJobComment(selectedJob?.id, type).then((commentResp) => {

            setJobCommentData({
                comment: commentResp.data.comment || '',
                id: commentResp.data.id || '',
                job_id: selectedJob?.id,
                is_admin: commentResp.data.is_admin || isAdminUser,
                user_id: commentResp.data.user_id || ''
            })

            setIsCommentDialogOpen(true);

        }).catch((err: any) => { })

    }
    const handleCommentDialogClose = useCallback(() => {
        setIsCommentDialogOpen(false);

    }, []);



    const onCommentDialogUpdate = (comment: any, id: any) => {
        jobApi.setJobComment({
            comment_id: id,
            job_id: selectedJob?.id || '',
            is_admin: isAdminUser,
            comment: comment,
            user_id: currentuserid
        }).then((response) => {
            setJobCommentData({ ...jobCommentData, comment: comment, id: response.data.id })
        })
    };

    // const insureJob =()=>{
    //   // if (!selectedJob) return;
    //   window.embedInsurance("TESTING COMPANY S5")
    // }
    return (
        <>
            <SelectUserDialog
                onSelectUserCallback={onSelectedUserCallback}
                open={isSelectUserDialogOpen}
                handleClose={handleSelectUserDialogClose}
                isAdmin={isAdminUser}
                companyId={selectedJob?.company_id}
                allocate={true}
                selectedJob={selectedJob}
            />
            <CommentDialogV2
                //  onBlurUpdate={onCommentDialogUpdate}
                //  commentData={jobCommentData}
                open={isCommentDialogOpen}
                jobId={selectedJob?.id}
                type={isAdminUser}
                handleClose={handleCommentDialogClose}
            //  isEditable={isAdminUser ? false :  !isJobOwner }
            />
            {/* <CommentDialog  
          onBlurUpdate={onCommentDialogUpdate}
          commentData={jobCommentData}
          open={isCommentDialogOpen} 
          handleClose={handleCommentDialogClose}
          isEditable={isAdminUser ? false :  !isJobOwner }
        /> */}

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>{t('job_details')}</Typography>

                    {isCurrentUserJobOwner || isAssignedUser ?
                        <Button
                            onClick={handleOnDownloadAllJobFilesClick}
                            startIcon={<GetAppIcon />}
                            variant="contained"
                        >
                            {t('download_all_job_files')}
                        </Button>
                        : null
                    }
                    <Grid style={{ float: 'right' }}>
                        {isCurrentUserJobOwner || isAssignedUser ?
                            <Button onClick={(e) => { handleCommentDialogOpen(false) }}> <Badge overlap="rectangular" variant="dot" invisible={true} color="primary">
                                <SpeakerNotesIcon color="action" />
                            </Badge></Button>
                            : null
                        }
                    </Grid>

                    {/* <Button type="button"    startIcon={<GetAppIcon />}variant="contained">
              Open menu dropdown
            </Button> */}
                    {/* {isCurrentJobCompleted ?
              <Grid style={{float:'right'}}>
              <Button
                onClick={handleOnDownloadAllJobFilesClick}
                startIcon={<GetAppIcon />}
                variant="contained"
              >
                {'Upload POD Document'}
              </Button>
              </Grid>
              : null}
         */}
                </DialogTitle>
                <DialogContent dividers>
                    {type && type === 'transport' ? (
                        <CreateNewJob
                            ref={createNewJobRef}
                            type={selectedJob?.job_classification || type}
                            selectedJob={selectedJob}
                            readonly={readOnly}
                            readOnlyBidder={isBidReadOnly}
                            isEditMode={isEditMode}
                            isInProgress={isInProgress}
                            mode="edit"
                            isJobOwner={isJobOwner}
                            isAssignedUser={isAssignedUser}
                            cntrReadOnly={cntrReadOnly}
                            documentUploadReadOnly={documentUploadReadOnly}
                        />
                    ) : type ? (
                        <CreateNewJobSeaFreight
                            ref={createNewJobRef}
                            type={type}
                            selectedJob={selectedJob}
                            readonly={readOnly}
                            isEditMode={isEditMode}
                            isInProgress={isInProgress}
                        />
                    ) : (
                        <></>
                    )}
                </DialogContent>
                {(isCurrentUserJobOwner || isJobOwner || isAssignedUser) &&
                    !hideActionButtons ? (
                    <DialogActions>
                        {/* <Grid  item md={3} style={{float:'left'}}>
                  {isAdminUser ?
                    <Button  onClick={(e) => {handleCommentDialogOpen(true)}}> <Badge  variant="dot" invisible={true}  color="primary">
                        <SpeakerNotesIcon color="action" />
                    </Badge></Button>
                      : null
                    }
                </Grid> */}

                        <Grid
                            spacing={2}
                            container
                            justifyContent="flex-end"
                            alignContent="center"
                        >

                            {/* <Button   color="primary"
                      variant="contained"
                      onClick={(e) => {insureJob()}} >Insure</Button> */}
                            <Grid item md={3}>
                                {isCloneMode ? (
                                    <TextField
                                        value={cloneAmount}
                                        onChange={(e) => setCloneAmount(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('enter_clone_amount')}
                                        type="number"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FileCopyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            min: CLONE_AMOUNT.MIN,
                                            max: CLONE_AMOUNT.MAX,
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    />
                                ) : null}
                            </Grid>


                            <Grid md={3} item>
                                {!isCurrentJobInProgress ?

                                    <Button
                                        onClick={onAllocateClick}
                                        fullWidth
                                        startIcon={<PlayArrowIcon />}
                                        color="primary"
                                        variant="contained"
                                    >
                                        {t('allocate')}
                                    </Button>
                                    : null}
                            </Grid>

                            <Grid item md={3}>
                                {!isEditMode && !isCloneMode ? (
                                    <Button
                                        onClick={handleCloneClick}
                                        startIcon={<FileCopyIcon />}
                                        className={classes.cloneBtn}
                                        variant="contained"
                                        fullWidth
                                    >
                                        {t('clone')}
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={handleCancelClick}
                                        startIcon={<ClearIcon />}
                                        variant="contained"
                                        fullWidth
                                    >
                                        {t('cancel')}
                                    </Button>
                                )}
                            </Grid>
                            <Grid item md={3}>
                                {!isEditMode && !isCloneMode ? (
                                    <Button
                                        onClick={handleEditClick}
                                        startIcon={<EditIcon />}
                                        className={classes.editBtn}
                                        variant="contained"
                                        fullWidth
                                    >
                                        {t('edit')}
                                    </Button>
                                ) : (
                                    <Button
                                        onClick={() => handleOnUpdateClick()}
                                        startIcon={<CheckIcon />}
                                        className={classes.updatebtn}
                                        variant="contained"
                                        fullWidth
                                    >
                                        {t(isCloneMode ? 'clone' : 'update')}
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </DialogActions>
                ) : null}
            </Dialog>
        </>
    );
}

export default ViewEditJob;
