import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { CREATE_JOB_ELEMENTS } from '../../../../Types/Constants';
const { QUANTITY, PACKAGES_TYPE, VOLUME, CN, ECRNUM, SEAL_NO, WS, CNTR } =
    CREATE_JOB_ELEMENTS;
interface ChildFormProps {
    index: number;
    formData: any;
    handleFormChange: (index: number, name: string, value: any) => void;
    readonly: boolean;
    country: string;
    movementType: string;
    jobType: string;
    handleContainerNoChange: (index: number, value: string) => void;
}

const ChildFormComponent: React.FC<ChildFormProps> = ({
    index,
    formData,
    handleFormChange,
    handleContainerNoChange,
    readonly,
    country,
    movementType,
    jobType
}) => {
    const [t] = useTranslation();

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    disabled={readonly}
                    value={formData.containerNo || ''}
                    onChange={(e) => handleContainerNoChange(index, e.target.value)}
                    fullWidth
                    label="Container No"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                        style: { fontWeight: 'bold' },
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    disabled={readonly}
                    value={formData.sealNo || ''}
                    onChange={(e) => handleFormChange(index, 'sealNo', e.target.value)}
                    fullWidth
                    label="Seal No"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                        style: { fontWeight: 'bold' },
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    disabled={readonly}
                    value={formData.declaredGrossWeight || ''}
                    onChange={(e) => handleFormChange(index, 'declaredGrossWeight', e.target.value)}
                    fullWidth
                    label="Declared Gross Weight"
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                        style: { fontWeight: 'bold' },
                    }}
                />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    disabled={readonly}
                    value={formData.wharfSlot || ''}
                    onChange={(e) => handleFormChange(index, 'wharfSlot', e.target.value)}
                    fullWidth
                    label="Wharf Slot"
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                        style: { fontWeight: 'bold' },
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <TextField
                    disabled={readonly}
                    // value={emptyContainerReleaseNumber}
                    value={formData.emptyContainerReleaseNumber || ''}
                    onChange={(e) => handleFormChange(index, 'emptyContainerReleaseNumber', e.target.value)}
                    fullWidth
                    label={t(ECRNUM)}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                        style: {
                            fontWeight: 'bold',
                        },
                    }}

                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
                <DateTimePicker
                    inputFormat="dd/MM/yyyy HH:mm"
                    ampm={false}
                    label="CNTR LFD"
                    disabled={readonly}
                    renderInput={(props) => <TextField fullWidth variant="outlined" {...props} />}
                    value={formData.CNTRLastDay || null}
                    onChange={(date) => handleFormChange(index, 'CNTRLastDay', date)}
                />
            </Grid>
        </Grid>
    );
};

export default ChildFormComponent;
