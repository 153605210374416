import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";




export const getMyDriversColumns = (t: TFunction): GridColumns => {
    return [
        {
            field: 'firstName',
            headerName: t('first_name'),
            width: 200
        },
        {
            field: 'lastName',
            headerName: t('last_name'),
            width: 200
        },
        {
            field: 'email',
            headerName: t('email'),
            width: 300
        },
        {
            field: 'status',
            headerName: t('status'),
            width: 300
        }
    ]
}