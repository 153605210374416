import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, makeStyles, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { Driver } from "../../../Api/Driver/Driver";
import { Job } from "../../../Api/Job/Job";
import { setLoader } from "../../../Redux/Loader/actions";
import { ISelectDriverDialogProps } from "./SelectDriverDialog.types";

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
}))

const jobApi = new Job()

const driverApi = new Driver()

function SelectDriverDialog(props: ISelectDriverDialogProps) {
    const [t] = useTranslation('SelectDriverDialog')
    const { open, handleClose, title, userTypeToGet, selectedJobId } = props
    const classes = useStyles()
    const [userDrivers, setUserDrivers] = useState<IUserInfo[]>([])
    const dispatch = useDispatch()
    const [selectedUserId, setSelectedUserId] = useState('')

    const getDriversToAssign = useCallback(
        (type: string) => {
            dispatch(setLoader(true))
            driverApi.gertDriversToAssign(type)
                .then((response) => {
                    const users: IUserInfo[] = response.data

                    setUserDrivers(users)

                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_drivers_to_assign'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t],
    )


    const handleOnSelectClick = useCallback(
        () => {
            if(selectedUserId === '') {
                toast.warn(t('select_driver_first'))
                return
            }
            dispatch(setLoader(true))
            jobApi.assignDriverToJob(selectedJobId ?? '', selectedUserId)
                .then(() => {
                    toast.success(t('success_assigning_driver'))
                    dispatch(setLoader(false))
                    handleClose()
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_assigning_driver'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, selectedJobId, selectedUserId],
    )

    useEffect(() => {
        if(open) {
            getDriversToAssign(userTypeToGet)
        } else {
            setSelectedUserId('')
        }
    }, [userTypeToGet,getDriversToAssign, open])

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {title}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <Select
                                    value={selectedUserId}
                                    onChange={(e) => setSelectedUserId(e.target.value as string)}
                                >
                                    {
                                        userDrivers.map((user) => (
                                            <MenuItem key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnSelectClick} variant="contained" color="primary">
                        {t('select')}
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SelectDriverDialog