import { GridColumns } from "@material-ui/data-grid";
import { Grid, Switch, FormGroup, FormControlLabel } from "@material-ui/core";
import { TFunction } from "i18next";
import {
    STATUS, USER_ROLES,
} from "..././../../../UI/src/Types/Constants";
interface IOnStatusChange {
    (params: any): void
}
const { ACTIVE } = STATUS;
const { COMPANY } = USER_ROLES

export const getColumns = (t: TFunction, user_type: any, onStatusChange: IOnStatusChange): GridColumns => [
    {
        field: 'warehouse',
        headerName: t('company_name'),
        width: 250
    },
    {
        field: 'site',
        headerName: t('warehouse_name'),
        width: 250
    },
    {
        field: 'posting_company',
        headerName: t('posting_company_only'),
        width: 250,
        hide : (user_type == COMPANY)
    },
    {
        field: 'status',
        headerName: t('status'),
        width: 200,
        renderCell: (params) => {
            const data = params.row
            // let company: ICompanyInfo = params.row as ICompanyInfo
            return (
                (data) ?
                    <Grid
                        container
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item md={4}>
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={!data.status}
                                    color="primary"
                                    onChange={(e) => {
                                        onStatusChange(data)
                                    }}
                                />} label={!data.status} />
                            </FormGroup>
                        </Grid>

                    </Grid>
                    :
                    <Grid />

            )

        }
    }
]