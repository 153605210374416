import React from 'react';
import { Line } from 'react-chartjs-2';
import { Grid, Typography } from '@material-ui/core';
import Chart from 'chart.js';
import { makeStyles } from '@material-ui/core';
// Define your movement type and color mappings
const movementTypeColors: { [key: string]: string } = {
    containers: '#1FA0ED',
    loose_cargo: '#223A59',
    full_truck_load: '#FFCE56'
};

const movementTypeLabels: { [key: string]: string } = {
    containers: 'Containers',
    loose_cargo: 'Loose Cargo',
    full_truck_load: 'Full Truck Load'
};
const useStyles = makeStyles(() => ({
    mainBox: {
        borderRadius: '13px',
        height: '300px',
        paddingTop: '20px',
        // margin: '0 10px 10px 0',
        backgroundColor: 'white',
        justifyContent: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    }

}))
// Transform data to the required format for the chart
const transformData = (data: any[]) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const movementTypes = Object.keys(movementTypeColors);

    const datasets = movementTypes.map(type => ({
        label: movementTypeLabels[type],
        data: months.map(month => {
            const entry = data.find(d => d.month === month && d.movementTypeCode === type);
            return entry ? entry.count : 0;
        }),
        borderColor: movementTypeColors[type],
        fill: false,
    }));

    return datasets;
};
const customLegendPlugin = {
    id: 'customLegendBorderRadius',
    beforeRender: function (chart) {
        const legend = chart.legend;
        legend.legendItems.forEach((legendItem) => {
            const original = legendItem.draw;
            legendItem.draw = function (ctx) {
                ctx.save();
                ctx.strokeStyle = ctx.fillStyle; // Ensure border matches the fill
                ctx.lineWidth = 2; // Set the border width

                const { x, y, boxWidth, boxHeight } = legendItem;
                const radius = 5; // Set your desired border radius here

                ctx.beginPath();
                ctx.moveTo(x + radius, y);
                ctx.lineTo(x + boxWidth - radius, y);
                ctx.quadraticCurveTo(x + boxWidth, y, x + boxWidth, y + radius);
                ctx.lineTo(x + boxWidth, y + boxHeight - radius);
                ctx.quadraticCurveTo(x + boxWidth, y + boxHeight, x + boxWidth - radius, y + boxHeight);
                ctx.lineTo(x + radius, y + boxHeight);
                ctx.quadraticCurveTo(x, y + boxHeight, x, y + boxHeight - radius);
                ctx.lineTo(x, y + radius);
                ctx.quadraticCurveTo(x, y, x + radius, y);
                ctx.closePath();

                ctx.fill();
                ctx.stroke();
                ctx.restore();
                original.apply(this, arguments); // Call original method to ensure text and other features are drawn
            };
        });
    }
};


const ChartComponent: React.FC<{ data: any[] }> = ({ data }) => {
    const datasets = transformData(data);
    const classes = useStyles();

    const chartData = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
        datasets: datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 10,
                padding: 10,
                fontSize: 12,

            },
        },
        tooltips: {
            callbacks: {
                title: (tooltipItems: any) => {
                    return `${tooltipItems[0].xLabel}`;
                },
            },
        },
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: 'Month',
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 12,
                },
            }],
            yAxes: [{
                scaleLabel: {
                    display: false,
                    labelString: 'Count',
                },
                ticks: {
                    beginAtZero: true,
                },
            }],
        },
    };

    return (
        // <Grid item style={{ borderRadius: '16px', textAlign: 'center', minHeight:'300px', height: '300px', backgroundColor: 'white',}}>
        <Grid item className={classes.mainBox}>
            <Typography style={{ fontSize: '1rem', marginBottom: '10px' }}>Jobs Completed</Typography>
            <div style={{ height: '250px' }}>

                <Line data={chartData} options={options} plugins={[customLegendPlugin]} />
            </div>
        </Grid>
    );
};

export default ChartComponent;
