// src/Redux/Theme/themeReducer.js

import { SET_THEME_STYLE } from './action';

const initialState = {
  themeStyle: 'default',
};

export function themeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_THEME_STYLE:
      return {
        ...state,
        themeStyle: action.payload,
      };
    default:
      return state;
  }
};

