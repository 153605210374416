import React from 'react';
import { Grid, MenuItem, FormControl, InputLabel, Select, TextField, InputAdornment } from '@material-ui/core';
import TextFieldsIcon from '@material-ui/icons/TextFields';

function PalletAccount({ readOnly,
    setPalletAccountType,
    setPalletAccountAction,
    setPalletAccountValue,
    palletAccountType,
    palletAccountAction,
    palletAccountValue }) {
    // const handleChanges = (type, action) => {
    //     let newValue = '';
    //     if (type === "PLAIN") {
    //         newValue = 'Plain Account';
    //     } else if ((type === 'CHEPS' || type === 'LOSCAMS') && action === 'exchange') {
    //         newValue = 'Exchange';
    //     } else {
    //         newValue = ''; // Open field
    //     }
    //     setPalletAccountValue(newValue);
    // };


    const handlePalletTypeChange = (event) => {
        const newValue = event.target.value;
        // handleChanges(newValue, palletAccountAction)
        setPalletAccountType(newValue);
    };
    const handlePalletActionChange = (event) => {
        const newValue = event.target.value;
        // handleChanges(palletAccountType, newValue)
        setPalletAccountAction(newValue);
    };
    return (
        <Grid>
            <Grid container direction="row" spacing={2}>
                <Grid md={4} item>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Pallet Account Type</InputLabel>
                        <Select
                            disabled={readOnly}
                            value={palletAccountType}
                            onChange={handlePalletTypeChange}
                        >
                            <MenuItem value="">Select Type</MenuItem>
                            <MenuItem value="CHEPS">CHEPS</MenuItem>
                            <MenuItem value="LOSCAMS">LOSCAMS</MenuItem>
                            <MenuItem value="PLAIN">PLAIN</MenuItem>
                            <MenuItem value="OTHER">OTHER</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid md={4} item>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel>Pallet Account Action</InputLabel>
                        <Select
                            disabled={readOnly}

                            value={palletAccountAction}
                            onChange={handlePalletActionChange}
                        >
                            <MenuItem value="">Select Action</MenuItem>
                            <MenuItem value="transfer">Transfer</MenuItem>
                            <MenuItem value="exchange">Exchange</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid hidden={true} md={4} item>
                    <TextField
                        disabled={readOnly || palletAccountAction == 'exchange'}
                        value={palletAccountValue}
                        onChange={(e) => setPalletAccountValue(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label="Pallet Account"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <TextFieldsIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default PalletAccount;
