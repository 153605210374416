import React, { useState, useEffect } from 'react';
import ChildFormComponent from './ContainerSelector';
import { Button, Grid, Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { setLoader } from '../../../../Redux/Loader/actions';
import {
    IContainerData
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
interface ChildFormProps {
    mode: "view" | "edit-allocated" | "edit" | "create" | "xml_mode"
    onChange: (data: any[]) => void;
    readonly: boolean;
    currentData: IContainerData[]
}

const useStyles = makeStyles((theme) => ({
    cards: {
        backgroundColor: '#e5e5e5',
    },
}));

const ContainerParent: React.FC<ChildFormProps> = ({ mode, onChange, readonly, currentData }) => {
    const [formInstances, setFormInstances] = useState<IContainerData[]>(currentData);
    const classes = useStyles();
    const country = 'AUSTRALIA';
    const movementType = 'LOOSE_CARGO';
    const jobType = 'LOOSE_CARGO';

    // Handle form field changes
    const handleFormChange = (index: number, name: string, value: any) => {
        const updatedForms = [...formInstances];
        updatedForms[index][name] = value;
        setFormInstances(updatedForms);
    };

    // Remove a form instance
    const removeForm = (index: number) => {
        if (formInstances.length > 1) {
            const updatedForms = formInstances.filter((_, i) => i !== index);
            setFormInstances(updatedForms);
        }
    };

    // Automatically submit the forms whenever formInstances changes
    useEffect(() => {
        onChange(formInstances);
    }, [formInstances, onChange]); // Dependency array ensures the effect runs when formInstances changes

    // Add a new form (up to 4 instances)
    const addForm = () => {
        if (formInstances.length < 10 && areAllFormsComplete()) {
            setFormInstances([
                ...formInstances,
                { containerNo: '', sealNo: '', declaredGrossWeight: '', wharfSlot: '', emptyContainerReleaseNumber: '', CNTRLastDay: null },
            ]);
        }
    };

    // Check if all forms are completed
    const areAllFormsComplete = () => {
        return formInstances.every(form =>
            form.containerNo && form.declaredGrossWeight
        );
    };

    // Check if containerNo is unique
    const isContainerNoUnique = (containerNo: string, index: number) => {
        return !formInstances.some((form, i) => form.containerNo === containerNo && i !== index);
    };

    // Handle containerNo change
    const handleContainerNoChange = (index: number, value: string) => {
        if (isContainerNoUnique(value, index)) {
            handleFormChange(index, 'containerNo', value);
        } else {
            alert('Container No must be unique');
        }
    };

    return (
        <Grid container md={12} spacing={1}>
            {mode !== 'edit' && (
                <Grid item md={12}>
                    <Button
                        onClick={addForm}
                        variant='contained'
                        color="primary"
                        disabled={formInstances.length >= 10 || !areAllFormsComplete() || readonly}
                    >
                        Add Container
                    </Button>
                </Grid>)}
            <Grid item md={12} spacing={1}>
                {formInstances.map((form, index) => (
                    <Card className={classes.cards} key={index} style={{ marginBottom: '16px' }}>
                        <CardContent>
                            <Typography variant="h6" style={{ fontSize: '.9rem' }}>Container {index + 1}</Typography>
                            <ChildFormComponent
                                index={index}
                                formData={form}
                                handleFormChange={handleFormChange}
                                handleContainerNoChange={handleContainerNoChange}
                                readonly={readonly}
                                country={country}
                                movementType={movementType}
                                jobType={jobType}
                            />
                            {mode !== 'edit' && (

                                <Button
                                    onClick={() => removeForm(index)}
                                    variant='contained'
                                    color="secondary"
                                    style={{ marginTop: '8px' }}
                                    disabled={formInstances.length <= 1 || readonly} // Disable if there's only 1 form
                                >
                                    Remove Container
                                </Button>
                            )}
                        </CardContent>
                    </Card>
                ))}
            </Grid>
        </Grid>
    );
};

export default ContainerParent;
