import { Button, Divider, Grid, Popover, Typography, FormControl, InputAdornment, InputLabel, Select, MenuItem, makeStyles, TextField } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IJobFilters, IJobInfo, IJobReports } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { Reports } from "../../../../Api/Reports/Reports";
import { ALL } from "../../Jobs/Jobs.constans";
import { getInvoiceReportsColumns } from './InvoiceReportsV2.columns'
import LazyTable from '../../../Common/LazyTable/LazyTable';
import { MONTHS } from '../../../../../../UI/src/Types/Constants';
import Swal from 'sweetalert2'
import SearchIcon from '@material-ui/icons/Search';

const reportsApi = new Reports()

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    gap: {
        marginTop: 20,
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
    },
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    gridList: {
        width: 500,
        height: 450,
    },
}));
function InvoiceReportsV2(props: { access_type: string }) {
    const [t] = useTranslation('InvoiceReports')
    const { access_type } = props
    const dispatch = useDispatch()
    const [completedJobs, setCompletedJobs] = useState<IJobInfo[]>([])
    const [popOverAnchorEl, setPopOverAnchorEl] = useState(null)
    const [selectedMonth, setSelectedMonth] = useState((new Date()).getMonth())
    const [selectedYear, setSelectedYear] = useState((new Date()).getFullYear())
    const [searchByReference, setSearchByReference] = useState('');

    const [filters, setFilters] = useState<IJobFilters>({
        jobType: ALL,
        vehicleType: ALL,
        access_type: access_type,
        month: selectedMonth,
        year: selectedYear,
        reference: searchByReference
    })
    const classes = useStyles();
    const [refreshTable, setRefreshTable] = useState<boolean>(true)

    const [invoiceInput, setInvoiceInput] = useState(""); // State to hold input value

    const monthsOption = useMemo(() =>
        Object.keys(MONTHS).map((month, i) => (
            <MenuItem key={i} value={i}>
                {month}
            </MenuItem>
        )),
        [MONTHS]
    );
    const currentYear = new Date().getFullYear();
    const YEARS = Array.from({ length: currentYear - 2014 }, (_, index) => currentYear - index);

    const yearsOption = useMemo(() =>
        YEARS.map((i) => (
            <MenuItem key={i} value={i}>
                {i}
            </MenuItem>
        )),
        [YEARS]
    );



    useEffect(() => {
        let timeOutId = setTimeout(() => {
            setFilters({
                ...filters,
                reference: searchByReference,
            });
        }, 500);
        return () => {
            clearTimeout(timeOutId);
        };
    }, [searchByReference]);
    const lazyGet = async (offset: number, pageNumber: number, limit: number,) => {
        // dispatch(setLoader(true));
        return reportsApi
            .lazyGet(ALL, 'completed_jobs', filters.country, filters, undefined, undefined, offset, pageNumber, limit, { "field": "date_completed", "sort": "asc" })
            .then((response) => {
                const jobs: IJobReports[] = response.data.jobList;
                const count = response.data.jobsCount;
                return { jobs, count }
            })
            .catch((err: any) => {
                const jobs: IJobInfo[] = [];
                const count = 0;
                console.log('err', err);
                toast.error(t('error_getting_jobs'));
                return { jobs, count }

            });

    }


    // useEffect(() => {
    //     setRefreshTable(!refreshTable)

    // }, [filters])


    const columns = getInvoiceReportsColumns(t, access_type)

    const handlePopOverOpen = useCallback(
        (e) => {
            setPopOverAnchorEl(e.currentTarget)
        },
        [],
    )

    const handlePopOverClose = useCallback(
        () => {
            setPopOverAnchorEl(null)
        },
        [],
    )

    const handleFilterChange = useCallback(
        (filter: IJobFilters) => {
            setFilters(filter)
        },
        [],
    )
    const handleEditingStopped = useCallback(
        (data: any) => {
            console.log(data)
            if (!data.value) {
                return;
            }
            reportsApi.setInvoiced(data.id, data.value)
                .then((response) => {
                    Swal.fire({
                        title: 'Job Successfully Invoiced',
                        icon: 'success',
                        confirmButtonText: 'Okay'
                    });

                    setRefreshTable(!refreshTable)
                })
                .catch((err: any) => {
                    setRefreshTable(!refreshTable)
                    toast.error(t('error_getting_jobs'));

                });


        },
        [refreshTable, dispatch],
    )
    // const handleDownloadAsCSV = useCallback(
    //     () => {
    //         dispatch(setLoader(true))
    //         reportsApi.getInvoiceReportCSV(filters)
    //             .then(response => {
    //                 jsFileDownload(response.data, `report-${Date.now()}.csv`)
    //                 dispatch(setLoader(false))
    //             })
    //             .catch(err => {
    //                 console.log(`err`, err)
    //                 toast.error(t('error_downloading_csv'))
    //                 dispatch(setLoader(false))
    //             })
    //     },
    //     [dispatch, t, filters],
    // )

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid item>
                    <Typography>
                        {t('invoice_reports')}
                    </Typography>
                </Grid>
               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                    >

                        <Grid item md={2}>

                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel className={classes.selectLabel}>
                                    Month
                                </InputLabel>
                                <Select
                                    value={selectedMonth}
                                    onChange={(e) => {
                                        const monthId = e.target.value;
                                        setSelectedMonth(monthId as any);
                                        let oldFilts = filters
                                        oldFilts.month = monthId
                                        setFilters(oldFilts)
                                        setRefreshTable(!refreshTable)

                                    }}
                                >
                                    {monthsOption}
                                </Select>
                            </FormControl>



                        </Grid>
                        <Grid item md={2}>

                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel className={classes.selectLabel}>
                                    Year
                                </InputLabel>
                                <Select
                                    value={selectedYear}
                                    onChange={(e) => {
                                        const yearId = e.target.value;
                                        setSelectedYear(yearId as any);
                                        let oldFilts = filters
                                        oldFilts.year = yearId
                                        setFilters(oldFilts)
                                        setRefreshTable(!refreshTable)

                                    }}
                                >
                                    {yearsOption}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={4} ></Grid>
                        <Grid md={4} item spacing={2} justifyContent="flex-end">
                            <TextField
                                value={searchByReference}
                                onChange={(e) => setSearchByReference(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('search_by_reference')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <LazyTable
                                columns={columns}
                                // rows={completedJobs}
                                lazyFilter={filters}
                                lazyFunction={lazyGet}
                                refresh={refreshTable}
                                onCellEditStopCallback={handleEditingStopped}
                            />
                        </Grid>
                        {/* <Grid item md={4}>
                            <Button onClick={handleDownloadAsCSV} startIcon={<SaveIcon />} fullWidth variant="contained" color="primary">
                                {t('download_as_csv')}
                            </Button>
                        </Grid> */}
                        {/* <Grid item md={4}>
                            <Button fullWidth variant="contained" startIcon={<SaveIcon />} color="primary">
                                {t('download_as_pdf')}
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid >
        </>
    )
}


export default InvoiceReportsV2