import { GridColumns } from "@material-ui/data-grid";
import { Grid, Button, FormGroup, FormControlLabel, Switch } from "@material-ui/core";
import { TFunction } from "i18next";
import { IAddress } from "../../../../../Application/DTOs/AddressDto/AddressDto.types";
import { IColumnsCallbacks } from "./WarehouseAddress.types";

import SwitchButton from '../../Common/SwitchButton/SwitchButton'

export const getAddressesColumns = (t: TFunction, callbacks: IColumnsCallbacks): GridColumns => [
    {
        field: 'city',
        headerName: t('city'),
        width: 200
    },
    {
        field: 'street_number',
        headerName: t('address'),
        width: 200
    },
    {
        field: 'postcode',
        headerName: t('postcode'),
        width: 200
    },
    {
        field: 'country',
        headerName: t('country'),
        width: 200,
        valueGetter: (params) => t((params.row as IAddress).country)
    },
    {
        field: 'is_main',
        headerName: t('branch_type'),
        width: 200,
        renderCell: (params) => {

            return (params.row.is_main) ? <Grid>Main Branch </Grid> : <Grid>Secondary</Grid>
        }
    },
    {
        field: 'action',
        headerName: t('action'),
        width: 250,
        renderCell: ((params: any) => {
            const is_disabled = params.row['is_disabled']
            const text_label = is_disabled ? 'Disabled' : 'Enabled'
            return (
                <Grid
                    container
                    alignContent="center"
                    justifyContent="center"
                >
                    <Grid item md={4}>
                        <FormGroup>
                            <FormControlLabel control={<Switch
                                checked={!is_disabled}
                                color="primary"
                                onChange={(e) => {
                                    callbacks.onAddressDisable(params.row)
                                }}
                            />} label={text_label} />
                        </FormGroup>
                    </Grid>

                </Grid>
            )
            // return (
            //     <Grid>
            //             <FormControlLabel label={text_label} control={<Switch
            //                 checked={!is_disabled} onChange={(event: any) => {
            //                     event.stopPropagation()
            //                     callbacks.onAddressDisable(params.row)
            //                 }} />} />
            //     </Grid>
            // )

        }),
    }

]