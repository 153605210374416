
import {ILocationtracking} from './LocationTrackingDialog.types'
import { Button,Box, Dialog, DialogActions,FormLabel, DialogContent, DialogTitle, Typography, makeStyles, Grid, TextField, InputAdornment, FormControlLabel, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Driver } from "../../../Api/Driver/Driver";
import { GoogleMap,useJsApiLoader ,  Marker ,DirectionsRenderer  } from '@react-google-maps/api';
// import {DirectionsRenderer } from '@react-google-maps/api'
// import  GoogleMap from 'google-map-react'
// import DirectionsRenderer from 'google-map-react'
// import Pusher from 'pusher-js';

import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { formatDateTimeDisplay } from '../../../Utilities/DateTime';


const useStyles = makeStyles(() => ({
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    mapBox: {
        width: '100%',
        height: '70vh',
    }
}))


function LocationTrackingDialog(props: any ) {
    const {selectedJob ,handleClose , open} = props
    const classes = useStyles()
    const [directionResponse ,setDirectionResponse] = useState<any>(null)
    const driverApi = new Driver()
  
    const [distance ,setDistance] = useState('')
    const [duration ,setDuration] = useState('')
    const [lastSeen , setLastSeen] =useState<any>('')

    const google = window.google

    const [center , setCenter] =useState<any>('')
    const [origin ,setOrigin] = useState<any>('')
    const [destination ,setDestination] = useState<any>('')

    const [t, i18n] = useTranslation('LocationTracking')

    /** @type React.MutableRefObject<HTMLInputElement> */
    const  centerRef  = useRef('')
    /** @type React.MutableRefObject<HTMLInputElement> */
    const  originRef  = useRef('')
    /** @type React.MutableRefObject<HTMLInputElement> */
    const destinationref  = useRef('')


    const {isLoaded }=useJsApiLoader({
        googleMapsApiKey : 'AIzaSyCrc2cJDNc_jKlPWUs9X15BUFmyfkUo5oY' 
    })

    const calculateRoute =async ()=>{
       
        const directionsService = new google.maps.DirectionsService();
        await directionsService.route({
            origin : origin,
            destination: destination,
            travelMode : google.maps.TravelMode.DRIVING
        },(result, status)=>{
        
            if(status === google.maps.DirectionsStatus.OK){
 
                setDirectionResponse(result)
                setDistance(result.routes[0].legs[0].distance.text)
                setDuration(result.routes[0].legs[0].duration.text)
            }
            // else if(status === google.maps.DirectionsStatus.INVALID_REQUEST){
            //     toast.warning("Cannot get current location")
            // }
        })
       
    }

    const handleUpdatedestination= async ()=>{
        if(selectedJob && open){
            const driverInfo = await driverApi.getDriverLocation(selectedJob.assigned_user.id)   
    
            setLastSeen(formatDateTimeDisplay(driverInfo.data['updatedAt']))
            await setCenter(new google.maps.LatLng(driverInfo.data['latitude'] , driverInfo.data['longitude']))
            await setOrigin(new google.maps.LatLng(driverInfo.data['latitude'] , driverInfo.data['longitude']))
            await  calculateRoute()
        }
       }
  

    const setAddress = async ()=>{
        if(selectedJob && open)
        {   
            if(center === '' ){
                setCenter(new google.maps.LatLng(selectedJob.deliver_to_address['latitude'] , selectedJob.deliver_to_address['longitude']))
                // setOrigin(new google.maps.LatLng(selectedJob.deliver_to_address['latitude'] , selectedJob.deliver_to_address['longitude']))

            }
            setDestination(new google.maps.LatLng(selectedJob.deliver_to_address['latitude'] , selectedJob.deliver_to_address['longitude']))
            
            await handleUpdatedestination()
           
        }
    }

    useEffect(()=>{
        if(open){
            setAddress()
        }
    },[open])

    const clearRoute = () =>{
        setDirectionResponse(null)
        setDistance('')
        setDuration('')
        setLastSeen('')
        setCenter('')
        // setOrigin('')
        setDestination('')
    }
    const onClose=()=>{
        clearRoute()
        handleClose()
       
    }
    const destinationicon ="https://cdn-icons-png.flaticon.com/512/252/252025.png";
    const originIcon ="https://cdn-icons-png.flaticon.com/512/741/741407.png"
    return (
        <>
        <Dialog maxWidth="xl" fullWidth open={open} onClose={()=>{onClose()}}>
                <DialogTitle>
                    <Typography>
                        Location Tracking
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid container item    sm={12}>
                        {/* <Grid container item   sm={12} justifyContent={'flex-end'}>
                                <Button variant='contained' color='primary' onClick={(e) =>{handleUpdatedestination()}}>Update Location</Button>
                            </Grid> */}
                            <Grid item   sm={3} >
                                <FormLabel>Last updated: {lastSeen} </FormLabel>
                              
                            </Grid>
                            <Grid item   sm={3} >
                            <FormLabel>Distance: {distance} </FormLabel>
                            </Grid>
                            <Grid item   sm={3} >
                            <FormLabel>Duration: {duration} </FormLabel>
                            </Grid>
                            <Grid container item  justifyContent={'flex-end'}  sm={3} >
                            <Button variant='contained' color='primary' onClick={(e) =>{handleUpdatedestination()}}>Update Location</Button>
                            </Grid>
                            </Grid>
                           
                           <Grid item md={12}>
                           {/* <Box > */}
                          { (center)?
                            <GoogleMap
                            
                                mapContainerStyle={{
                                    width: '100%',
                                    height: '700px'
                                    }}
                                center={center}
                                zoom={10}
                                >
                                   
                                   <Marker  icon={{
                                    url:originIcon,
                                    scaledSize:new google.maps.Size(50, 50)
                                    // size:10
                                   }} 
                                   position={origin} />
                                   <Marker  icon={{
                                    url:destinationicon,
                                    scaledSize:new google.maps.Size(50, 50)
                                    // size:10
                                   }}  
                                   title='Destination' position={destination}/>
                                    {directionResponse ?
                                        <DirectionsRenderer  
                                        options={{suppressMarkers:true}}
                                        directions={directionResponse}/> : null}  
                                <></>
                            </GoogleMap>
                            : null
}
      

                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                
                 
                </DialogActions>
            </Dialog>
        </>
        )
}
export default LocationTrackingDialog
