import { WebStorage } from "../../Utilities/WebStorage"
import axios from "axios"
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"

import { IWarehouseInfo } from "../../../../Application/DTOs/WarehouseDto/WarehouseDto.types";
const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()


export class Warehouse {
    create(payload: IWarehouseInfo) {
        return axios.post(`${baseUrl}/warehouse/register`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }

    update(id: any, payload: any) {
        return axios.post(`${baseUrl}/warehouse/update/${id}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }

    getAll(is_admin: boolean, site_name?: string, company_id?: string) {
        return axios.get(`${baseUrl}/warehouse/list`, {
            params: {
                site_name,
                company_id,
                is_admin
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getWarehouseList(is_admin: boolean, company_id?: string) {
        return axios.get(`${baseUrl}/warehouse/list/connected`, {
            params: {
                company_id,
                is_admin
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    changePassword(id: any, password: any) {
        return axios.post(`${baseUrl}/warehouse/update/password/${id}`, { password }, {

            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    lazyGet(
        classification: string,
        country: string | undefined,
        pagetype?: string,
        movementType?: string,
        offset?: number,
        pageNumber?: number,
        limit?: number,
        order_by?: any
    ) {
        return axios.get(`${baseUrl}/job/get/optimize`, {
            params: {
                classification,
                country,
                pagetype,
                movementType,
                offset,
                pageNumber,
                limit,
                order_by
            },
            headers: {
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            },
        });
    }

    getDetails(warehouse_id: any) {
        return axios.get(`${baseUrl}/warehouse/detail/${warehouse_id}`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getAddress(warehouse_id: any) {
        return axios.get(`${baseUrl}/warehouse/address/${warehouse_id}`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    createAddress(payload: any) {
        return axios.post(`${baseUrl}/warehouse/address/create`, payload, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    statusChange(warehouse_id: any, status: any, company_id: any) {
        return axios.post(`${baseUrl}/warehouse/status/update`, { warehouse_id, status, company_id }, {

            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }


    addStock(payload: any) {
        return axios.post(`${baseUrl}/warehouse/stocks/add`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    getProductList() {
        return axios.get(`${baseUrl}/warehouse/stocks/products`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    getMyProductList(owner_id: any) {
        return axios.get(`${baseUrl}/warehouse/stocks/my/${owner_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    getAllProductList(owner_id: any, site_id?: any) {
        return axios.get(`${baseUrl}/warehouse/stocks/site/${owner_id}`, {
            params: { filter: 'warehouse', site_id: site_id },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    getLedgerProductList(owner_id: any, moveIo: string, fromDate: Date | string | undefined | null, toDate: Date | string | undefined | null, site_id?: any) {
        return axios.get(`${baseUrl}/warehouse/stocks/ledger/site/${owner_id}`, {
            params: { filter: 'warehouse', site_id, moveIo, fromDate, toDate },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    getLazyProductList(limit: any, offset: any, owner_id: any, site_id?: any) {
        return axios.get(`${baseUrl}/warehouse/lazy/stocks/site/${owner_id}`, {
            params: { filter: 'warehouse', site_id: site_id, limit, offset },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    editStock(payload: any) {
        return axios.post(`${baseUrl}/warehouse/stocks/update`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    addStockQty(payload: any) {
        return axios.post(`${baseUrl}/warehouse/stocks/qty/add`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
    getScheduledCollectionJobs(id: any, offset: any, limit: any, report_type, filter_id: any, from: any, to: any) {
        return axios.get(`${baseUrl}/warehouse/scheduled/list/${id}`, {
            params: { offset, limit, report_type, filter_id  , from , to},
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }

    getContainerOnsite(
        owner_id: any,
        offset?: number,
        limit?: number
    ) {
        return axios.get(`${baseUrl}/warehouse/containers/list`, {
            params: {
                owner_id,
                offset,
                limit,
            },
            headers: {
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            },
        });
    }

    sendEmailNotification(container_id: any) {
        return axios.post(`${baseUrl}/warehouse/container/notify/${container_id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        });
    }
    createContainerStock(container_id: any, stock_id: any) {
        return axios.post(`${baseUrl}/warehouse/container/stock/add/${container_id}`, { stock_id }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        });
    }

    getContainerStock(container_id: any) {
        return axios.get(`${baseUrl}/warehouse/container/stock/list/${container_id}`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        });
    }

    markOut(id: any) {
        return axios.post(`${baseUrl}/warehouse/stock/markout/${id}`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }
}