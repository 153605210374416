import { Box, Button, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import { Rating } from '@material-ui/lab'
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moveit4u_logo from '../../../Images/moveit4u-logo.png'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { UsersApi } from "../../../Api/Users/Users"
import { useDispatch } from "react-redux"
import { setLoader } from "../../../Redux/Loader/actions"
import { toast } from "react-toastify"
import { useHistory } from "react-router"
import { routes } from "../../../routes"

const useStyles = makeStyles(() => ({
    box: {
        backgroundColor: 'white',
        padding: '40px 15px',
        borderRadius: '20px',
        boxShadow: '2px 3px 6px -1px rgba(0,0,0,0.5)'
    },
    center: {
        textAlign: 'center'
    },
    logo: {
        width: '150px',
        height: '150px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
}))

const userApi = new UsersApi()

function RateUser() {
    const [t] = useTranslation('RateUser')
    const classes = useStyles()
    const [rating, setRating] = useState<number | null>(null)
    const [token, setToken] = useState('')
    const [name, setName] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const href = window.location.href
        console.log('window.location.href', window.location.href)
        const url = new URL(href)

        let token = url.searchParams.get('token')
        let name = url.searchParams.get('name')

        if (token)
            setToken(token)

        if (name)
            setName(name)
    }, [])

    const handleOnRateClick = () => {
        if (rating) {
            dispatch(setLoader(true))
            userApi.rate(rating, token)
                .then(() => {
                    toast.success(t('success_rating_user'))
                    dispatch(setLoader(false))
                    history.push(routes.landing.login[0])
                })
                .catch((err :any) => {
                    console.log(`err`, err)
                    toast.error(t('error_rating_user'))
                    dispatch(setLoader(false))
                })
        }
    }

    return (
        <>
            <Container maxWidth="sm">
                <Box className={classes.box}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5}
                    >
                        <Grid xs={12} item>
                            <img
                                className={classes.logo}
                                title="Moveit4u"
                                src={moveit4u_logo}
                                alt="Moveit4u"
                            />
                        </Grid>
                        <Grid xs={12} item>
                            <Typography variant="h6" className={classes.center} gutterBottom>
                                {t('please_rate_user')}
                            </Typography>
                            <Typography variant="h4" className={classes.center} gutterBottom>
                                {name}
                            </Typography>
                        </Grid>
                        <Grid className={classes.center} xs={12} item>
                            <Rating value={rating} onChange={(_, val) => { setRating(val) }} size="large" />
                        </Grid>
                        <Grid className={classes.center} item xs={12}>
                            <Button onClick={handleOnRateClick} fullWidth startIcon={<ThumbUpAltIcon />} variant="contained" color="primary">
                                {t('rate')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </>
    )
}


export default RateUser