import { Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IJobInfo } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { formatDateTimeDisplay } from "../../../../Utilities/DateTime";


export const getJobReportMileageColumns = (t: TFunction): GridColumns => {
    return [
        {
            field: 'reference',
            headerName: t(`Columns:reference`),
            width: 150
        },
        {
          field: 'customer_reference',
          headerName: t('Columns:customer_reference'),
          width: 200,
          renderCell: (params) => {
            const job = params.row
            return (
              <Typography>
                {job.customer_reference}
              </Typography>
            )
          }
        },
        
        {
            field: 'job_type',
            headerName: t(`Columns:job_type`),
            renderCell: (params) => <Typography>{t((params.row as IJobInfo).job_type)}</Typography>,
            width: 250
        },
        {
            field: 'vehicle_type',
            headerName: t(`Columns:vehicle_type`),
            renderCell: (params) => <Typography>{t((params.row as IJobInfo).vehicle_type)}</Typography>,
            width: 250
        },
        {
            field: 'collection_city',
            headerName: t('Columns:pick_up_suburb'),
            width: 200,
            renderCell: (params) => {
                let job = params.row
      
                return (
                    <Typography>
                      {job.pick_up_address?.city}
                    </Typography>
                ) 
            }
          },
          {
            field: 'collection_time',
            headerName: t('Columns:pick_up_date_time'),
            width: 200,
            renderCell: (params) => {
                let job = params.row
      
                return (
                    <Typography>
                      {formatDateTimeDisplay(job.pickup_date_time)}
                    </Typography>
                ) 
            }
          },
          {
            field: 'delivery_city',
            headerName: t('Columns:delivery_suburb'),
            width: 200,
            renderCell: (params) => {
                let job = params.row
      
                return (
                    <Typography>
                      {job.deliver_to_address?.city}
                    </Typography>
                ) 
            }
          },
          {
            field: 'deliver_time',
            headerName: t('Columns:deliver_to_date_time'),
            width: 200,
            renderCell: (params) => {
                let job = params.row
      
                return (
                    <Typography>
                      {formatDateTimeDisplay(job.deliver_date_time)}
                    </Typography>
                ) 
            }
          },
        {
            field: 'total_distance',
            headerName: t(`Columns:total_distance`),
            renderCell: (params) => <Typography>{(params.row as IJobInfo).total_distance_km}</Typography>,
            width: 300
        },
    ]
}