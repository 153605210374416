import { Grid, Typography, Divider } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { IUserInfo } from '../../../../../../Application/DTOs/UsersDto/UsersDto.type'
import { Company } from '../../../../Api/Company/Company'
import { Driver } from '../../../../Api/Driver/Driver'
import { AppState } from '../../../../Redux'
import { setLoader } from '../../../../Redux/Loader/actions'
import CustomTable from '../../../Common/CustomTable/CustomTable'
import { getCompanyRelationshipManagementColumns, getDriverRelationshipManagementColumns } from './RelationshipManagement.columns'

const companyApi = new Company()

const driverApi = new Driver()

function RelationshipManagement() {
    const [t] = useTranslation('RelationshipManagement')
    const country = useSelector((state: AppState) => state.user.response?.company?.country)
    const dispatch = useDispatch()
    const [companies, setCompanies] = useState<ICompanyInfo[]>([])
    const [drivers, setDrivers] = useState<IUserInfo[]>([])


    const getCompanies = useCallback(
        (country: string) => {
            dispatch(setLoader(true))
            companyApi.getCompaniesRelationShip(country)
                .then((response) => {
                    const companies: ICompanyInfo[] = response.data

                    setCompanies(companies)

                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_relationship_companies'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t]
    )

    const getDrivers = useCallback(
        (country: string) => {
            dispatch(setLoader(true))
            driverApi.getAll(country)
                .then((response) => {
                    setDrivers(response.data)
                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_drivers'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t]
    )

    useEffect(() => {
        if (country) {
            getCompanies(country)
            getDrivers(country)
        }


    }, [getCompanies, getDrivers, country])

    const onSwitchChange = useCallback(
        (companyId: string | number, checked: boolean) => {
            dispatch(setLoader(true))
            companyApi.updateCompanyRelationship(companyId, checked)
                .then(() => {
                    dispatch(setLoader(false))
                    if (country)
                        getCompanies(country)
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_updating_relationship_companies'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, getCompanies, country],
    )

    const onSwitchChangeDriver = useCallback(
        (driverId: string | number, checked: boolean) => {
            dispatch(setLoader(true))
            driverApi.updateDriverCompanyRelationship(driverId, checked)
                .then(() => {
                    dispatch(setLoader(false))
                    if (country)
                        getDrivers(country)
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_updating_relationship_companies'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, getDrivers, country],
    )

    const companyRelationShipsColumns = getCompanyRelationshipManagementColumns(t, onSwitchChange)

    const driverColumns = getDriverRelationshipManagementColumns(t, onSwitchChangeDriver)

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid item>
                    <Typography>
                        {t('relationship_management')}
                    </Typography>
                </Grid>
               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item md={12}>
                            <CustomTable
                                columns={companyRelationShipsColumns}
                                rows={companies}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <CustomTable
                                columns={driverColumns}
                                rows={drivers}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default RelationshipManagement