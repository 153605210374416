import { Grid, makeStyles, Box, styled, Paper, Typography, Breadcrumbs, Link, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import JobDataPaper from '../../Common/JobDataPaper/JobDataPaper'
import GoogleMap from 'google-map-react'
import AggregatedJobsLineChart from '../../Common/AggregatedJobsLineChart/AggregatedJobsLineChart'
import DonutGraph from '../../Common/DonutGraph/DonutGraph'
import DriverRatingTable from '../../Common/DriverRatingTable/DriverRatingTable'
import CompanyRankingTable from '../../Common/CompanyRankingTable/CompanyRankingTable'
import AggregatedJobTable from '../../Common/AggregatedJobTable/AggregateJobTable'
import DailyMovementTable from '../../Common/DailyJobsMovementTable/DailyJobsMovementTable'
import { Job } from '../../../Api/Job/Job'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '../../../Redux/Loader/actions'
import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants'
import { toast } from 'react-toastify'
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types'
import { IJobsWithTypes } from '../Jobs/Jobs.types'
import { AppState } from '../../../Redux'
import PieChart from '../../Common/PieChart/PieChart'
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import WeeklyDashboard from './WeeklyDashboard'
const BOX_HEIGHT = 45

const useStyles = makeStyles(() => ({
    mapBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    formControl: {
        minWidth: '100%',
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    graphBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    inbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    label: {
        margin: '0px',
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: ' rgb(108, 115, 127)'
    },
    count: {
        fontWeight: 'bold',
        margin: '30px 0',
        fontSize: '2rem',
        lineHeight: '1.2'
    },
    breadCrumbs: {
        float: 'right'
    },
    active: {
        fontWeight: 'bold'
    }

}))
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    color: theme.palette.text.secondary,
}));
const jobApi = new Job()

const {
    COMPLETED,
    POSTED,
    IN_PROGRESS,
    CREATED
} = JOB_STATUS

const {
    ADMIN_USER,
    SUPER_ADMIN
} = USER_ROLES

function Dashboard() {
    const [t] = useTranslation('Dashboard')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [mapJobs, setMapJobs] = useState<IJobInfo[]>([])
    const user = useSelector((state: AppState) => state.user)
    let company: any = useSelector((state: AppState) => state.company);

    const isAdminUser = user.response?.type === ADMIN_USER
    const [looseCargoJobsMonthly, setLooseCargoJobsMonthly] = useState<any>(0)
    const [containerJobsMonthly, setContainerJobsMonthly] = useState<any>(0)
    const [interstateJobsMonthly, setInterstateJobsMonthly] = useState<any>(0)
    const [overallMonthly, setOverallMonthly] = useState<any>(0)
    // const [activeData, setActiveData] = useState<any>('monthly')
    const [activeDataWeek, setActiveDataWeek] = useState<any>('week')

    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user.response?.type)
    const [companyId, setCompanyId] = useState<any>('');
    
    const getPieData = (pieType: string) => {
        dispatch(setLoader(true))
        jobApi.getWeeklyCompleteReports(pieType, companyId)
            .then((response) => {
                const counts = response.data
                setLooseCargoJobsMonthly(counts.before.loose_cargo_jobs)
                setContainerJobsMonthly(counts.before.container_jobs)
                setInterstateJobsMonthly(counts.before.interstate_jobs)
                setOverallMonthly(counts.before.overall)
                dispatch(setLoader(false))
            })
            .catch((err: any) => {
                dispatch(setLoader(false))
                console.log('err', err)
                // toast.error(t('error_getting_counts'))
            })
    }
    let companyListOptions = useMemo(() =>
        company.list.map((company, i) => (
            <MenuItem key={i} value={company.id}>
                {company.companyName}
            </MenuItem>
        )),
        [company.list]
    );

    useEffect(() => {
        if (!isUserAdmin) {
            setCompanyId(user.response?.company.id || '')
        }
        getPieData(activeDataWeek)
    }, [dispatch, t, activeDataWeek])

    // const breadClick = (e, pieType: string) => {
    //     e.preventDefault()
    //     getPieData(pieType)
    //     setActiveData(pieType)
    // }

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        setActiveDataWeek(newAlignment);
    };
    return (
        <>
            <Grid
                container
                spacing={3}
            >

                <Grid item md={12}>
                    <Grid container direction='row'>
                        <Grid item md={8}>
                            <ToggleButtonGroup
                                value={activeDataWeek}
                                exclusive
                                onChange={handleAlignment}
                                style={{ height: '5vh' }}
                                aria-label="text alignment"
                            >
                                <ToggleButton value="week" aria-label="left aligned">
                                    <p>Week</p>
                                </ToggleButton>
                                <ToggleButton value="month" aria-label="centered">
                                    <p>Month</p>
                                </ToggleButton>
                                <ToggleButton value="year" aria-label="centered">
                                    <p>Year</p>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        <Grid item md={4}>

                            {(isUserAdmin) ?
                                <Grid item >

                                    <FormControl variant="filled" className={classes.formControl}>
                                        <InputLabel className={classes.selectLabel}>
                                            {t('select_company') + '*'}
                                        </InputLabel>
                                        <Select
                                            value={companyId}
                                            onChange={(e) => {
                                                const companyId = e.target.value;
                                                setCompanyId(companyId as string);
                                            }}
                                        >
                                            {companyListOptions}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                :
                                null
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={12}>

                    <WeeklyDashboard companyId={companyId} activeData={activeDataWeek} />
                </Grid>
                <Grid item md={12}>

                    <Grid container spacing={5}>

                        <Grid container item spacing={3}>
                            <Grid item xs>
                                <Grid className={classes.inbox}>
                                    <PieChart paperHeight='2'
                                        colorTheme='teal'
                                        activeType={activeDataWeek}
                                        labels={['Overall', t('loose_cargo')]} title={t('loose_cargo')} values={[overallMonthly, looseCargoJobsMonthly]} />

                                    <PieChart paperHeight='2'
                                        colorTheme='teal'
                                        // legendPosition='bottom'
                                        activeType={activeDataWeek}

                                        labels={['Overall', t('interstate')]} title={t('interstate')} values={[overallMonthly, containerJobsMonthly]} />
                                    {/* <Typography></Typography> */}
                                    <PieChart paperHeight='2'
                                        colorTheme='teal'
                                        activeType={activeDataWeek}
                                        labels={['Overall', t('container')]} title={t('container')} values={[overallMonthly, interstateJobsMonthly]} />


                                </Grid>
                            </Grid>

                        </Grid>
                        {/* <Grid xs={12} item>
                            <Breadcrumbs className={classes.breadCrumbs} aria-label="breadcrumb">
                                <Link underline="hover" color="inherit"
                                    className={activeData == 'monthly' ? classes.active : null}
                                    onClick={(e) => {

                                        breadClick(e, 'monthly')
                                    }}
                                >
                                    Month
                                </Link>
                                <Link
                                    onClick={(e) => {
                                        breadClick(e, 'yearly')
                                    }}
                                    className={activeData == 'yearly' ? classes.active : null}

                                    underline="hover"
                                    color="inherit"
                                >
                                    Year
                                </Link>
                            </Breadcrumbs>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item md={12}>
                    <Box className={classes.graphBox}>
                        <Grid
                            container
                            spacing={1}
                        >

                            {/* <Grid item md={12}>
                                <AggregatedJobsLineChart paperHeight={`45vh`} />
                            </Grid>
                            <Grid item md={12}>
                                <AggregatedJobTable paperHeight={`60vh`} />
                            </Grid> */}
                            {/* <Grid item md={12}>
                    <DailyMovementTable paperHeight={`40vh`}/>
                </Grid> */}
                            {/* <Grid item md={6}>
                                <LineGraph paperHeight={`${BOX_HEIGHT/2}vh`}/>
                            </Grid>
                            <Grid item md={6}>
                                <DriverRatingTable paperHeight={`${BOX_HEIGHT}vh`}/>
                            </Grid>
                            <Grid item md={3}>
                                <DonutGraph paperHeight={`${BOX_HEIGHT/2}vh`}/>
                            </Grid>
                            <Grid item md={3}>
                                <DonutGraph paperHeight={`${BOX_HEIGHT/2}vh`}/>
                            </Grid>
                            <Grid item md={6}>
                                <CompanyRankingTable paperHeight={`${BOX_HEIGHT}vh`}/>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Grid>

            </Grid>
        </>
    )
}

export default Dashboard