import { Grid } from "@material-ui/core"
import { GridRowParams } from "@material-ui/data-grid"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { IUserInfo } from "../../../../../../Application/DTOs/UsersDto/UsersDto.type"
import { UsersApi } from "../../../../Api/Users/Users"
import { setLoader } from "../../../../Redux/Loader/actions"
import { USER_ROLES } from "../../../../Types/Constants"
import CustomTable from '../../../Common/CustomTable/CustomTable'
import ViewUserDetailsDialog from "../../../Dialogs/ViewUserDetailsDialog/ViewUserDetailsDialog"
import { getAdminUsersTableColumns } from "./AdminUsers.columns"

const usersApi = new UsersApi()

const {
    ADMIN_USER
} = USER_ROLES

function AdminUsers() {

    const [t] = useTranslation(['AllUsers', 'Columns'])
    const dispatch = useDispatch()
    const [rows, setRows] = useState<IUserInfo[]>([])
    const [isViewUserDetailsDialogOpen, setIsViewUserDetailsDialogOpen] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState<number | null>(null)
    const columns = useMemo(() => getAdminUsersTableColumns(t), [t])

    const onRowClick = useCallback(
        (rowParams: GridRowParams) => {
            const userInfo: IUserInfo = rowParams.row as IUserInfo

            setSelectedUserId(userInfo.id)
            setIsViewUserDetailsDialogOpen(true)
        },
        [],
    )

    const getUsers = useCallback(
        () => {
            dispatch(setLoader(true))
            usersApi.all([ADMIN_USER])
                .then((response) => {
                    const users: IUserInfo[] = response.data
                    setRows(users)
                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_all_users'))
                    dispatch(setLoader(false))
                })
        },
        [t, dispatch],
    )

    const handleViewUserDialogClose = useCallback(
        () => {
            setSelectedUserId(null)
            setIsViewUserDetailsDialogOpen(false)
            getUsers()
        },
        [getUsers],
    )

    useEffect(() => {
        getUsers()
    }, [getUsers])

    return (
        <>
            <ViewUserDetailsDialog selectedUserId={selectedUserId} open={isViewUserDetailsDialogOpen} handleClose={handleViewUserDialogClose} />
            <Grid
                container
            >
                <Grid item md={12}>
                    <CustomTable
                        rows={rows}
                        columns={columns}
                        onRowClick={onRowClick}
                    />
                </Grid>
            </Grid>
        </>
    )
}


export default AdminUsers