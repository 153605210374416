import { Paper, Grid, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from 'react-i18next'
import { Chart as ChartJS, Tooltip } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEffect, useRef } from "react";
import { IPieChartProps } from "./PieChart.types";
import moment from "moment";
const useStyles = makeStyles(() => ({
    paper: {
        width: '33%',
        // position: 'relative'
        alignItems: 'center',
        textAlign: 'center',
    },
    canvas: {
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    label: {
        margin: '0px',
        fontSize: '1rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: ' rgb(108, 115, 127)'
    },
    mainLabel: {
        marginTop: '10px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        // textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: ' rgb(108, 115, 127)'
    },
    secondary: {
        // marginTop: '10px',
        // alignContent:'left',
        // float:'left',
        // align:'left',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: ' rgb(108, 115, 127)'
    },
    middleHeader: {
        alignItems: 'left',
        width: 'auto'
    },
    noData: {
        height: '15rem',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color:'grey'
    }
}))
// ChartJS.(ArcElement, Tooltip, Legend);
const mainColor = '#044364'
const teal = '#02cc9c'
const colorPatterns = {
    yellow: [mainColor, 'rgba(255, 159, 64, 192)'],
    red: [mainColor, 'rgba(153, 102, 255)'],
    blue: [mainColor, 'rgba(255, 99, 132)'],
    teal: [mainColor, teal]

}
function PieChart(props: IPieChartProps) {
    const canvasRef = useRef(null)
    const [t] = useTranslation('Dashboard')

    const { labels, values, colorTheme, title, legendPosition = 'top' , activeType } = props
    const classes = useStyles(props)

    const data = {
        labels: labels,
        // anchor: 'end',
        datasets: [
            {
                // label: '# of Votes',
                data: values,
                backgroundColor: colorPatterns[colorTheme],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Grid className={classes.paper}>
            {(legendPosition == 'top') ?
                <span className={classes.label}>{t(title)}</span> : null}
            {(values[0] != 0) ?
                <Pie data={data} />
                :
                <div className={classes.noData}><Typography >No Data</Typography></div>
            }

            {(legendPosition == 'bottom') ?
                <div>
                    <span className={classes.label}>{t(title)}</span>

                    <div className="middleHeader">
                        <Typography className={classes.mainLabel}>{`Completed Jobs (${(activeType == 'month')? moment().startOf("month").format('MMMM') : moment().startOf("year").format('YYYY') })`}</Typography>
                        <span className={classes.secondary}>{values[0]}</span>

                    </div>
                </div>
                :
                null}
        </Grid>
    )
}

export default PieChart