import { Typography } from '@material-ui/core';
import { GridCellParams, GridColumns } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { IJobInfo } from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { JOB_REPORT_COLUMNS } from './columns';
import {
  calculateGST,
  computationWaitingTimeCharge,
  getGST,
  getTotalFee,
  computeGST
} from '../../../../Utilities/Computation';
import { IUserState } from '../../../../Redux/User/type';
import { COMPANY_TYPES, USER_ROLES } from '../../../../Types/Constants';
import { formatDateTimeDisplay } from "../../../../Utilities/DateTime";

export const getJobReportColumns = (
  t: TFunction,
  user: IUserState
): GridColumns => {
  const { company, type } = user?.response || {
    company: undefined,
    type: undefined,
  };

  let isTransportCompany = false;

  if (
    typeof company !== 'undefined' &&
    company.companyType === COMPANY_TYPES.TRANSPORT_COMPANY
  ) {
    isTransportCompany = true;
  }

  const adminRoles = [
    USER_ROLES.ADMIN,
    USER_ROLES.ADMIN_USER,
    USER_ROLES.SUPER_ADMIN,
  ];
  const { SUPER_ADMIN, ADMIN_USER } = USER_ROLES
  const isAdmin = typeof type !== 'undefined' && adminRoles.includes(type);
  const isAdminTransport = isAdmin || isTransportCompany;
  const isAdminNonTransport = isAdmin || !isTransportCompany;

  return [
    {
      field: 'reference',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.REFERENCE}`),
      width: 250,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'postingCompanyName',
      headerName: t('posting_company_only'),
      hide: ![ADMIN_USER, SUPER_ADMIN].includes(user.response?.type ?? ''),
      width: 300,
      renderCell: (params) => {
        const job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.company?.companyName || params.row.company?.name}
          </Typography>
        )

      }
    },
    {
      field: 'transport_company',
      headerName: t('Columns:transport_company'),
      width: 300,
      hide: ![ADMIN_USER, SUPER_ADMIN].includes(user.response?.type ?? ''),
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo

        return (
          <Typography>
            {params.row.assigned_user?.company?.name}
          </Typography>
        )

      }
    },

    {
      field: 'transport_driver',
      headerName: t('Columns:driver'),
      width: 200,
      hide: ![ADMIN_USER, SUPER_ADMIN].includes(user.response?.type ?? ''),
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo

        return (
          <Typography>
            {params.row.assigned_user ? params.row.assigned_user?.first_name || '' + " " + params.row.assigned_user?.last_name || '' : ''}
          </Typography>
        )

      }
    },
    {
      field: 'delivery_city',
      headerName: t('Columns:delivery_city'),
      width: 200,
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.deliver_to_address?.city}
          </Typography>
        )
      }
    },

    {
      field: 'pickup_city',
      headerName: t('Columns:collection_city'),
      width: 200,
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.pick_up_address?.city}
          </Typography>
        )
      }
    },


    {
      field: 'movement_type',
      headerName: t('Columns:movement_type'),
      width: 200,
      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {t(job.movement_type)}
          </Typography>
        )
      }
    },

    {
      field: 'actual_pickup_date_time',
      headerName: t('Columns:collection_date'),
      width: 250,

      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {formatDateTimeDisplay(job.actual_pick_up_time)}
          </Typography>
        )
      }
    },
    {
      field: 'actualdelivery_date_time',
      headerName: t('Columns:deliver_to_date_time'),
      width: 250,

      renderCell: (params) => {
        let job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {formatDateTimeDisplay(job.delivery_date_time)}
          </Typography>
        )
      }
    },
    // {
    //   field: 'agreed_price',
    //   headerName: t(`Columns:${JOB_REPORT_COLUMNS.AGREED_PRICE}`),
    //   width: 150,
    //   renderCell: (params) => {
    //     const jobInfo: IJobInfo = params.row as IJobInfo;

    //     const price: string = String(jobInfo.job_bids?.[0]?.amount ?? '');
    //     const marginPrice: string = String(jobInfo.margin_price || '0');
    //     const agreed_price: number = Number(price) + Number(marginPrice);

    //     return <Typography>{agreed_price}</Typography>;
    //   },
    // },

    {
      field: 'driver_fee',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.DRIVER_FEE}`),
      width: 150,
      hide: user.response?.type !== ADMIN_USER && user.response?.type !== SUPER_ADMIN,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;

        const fee: string = String(jobInfo.job_bids?.[0]?.user?.driver ? params.row.job_bids?.[0]?.wait_time_charge != null ? params.row.job_bids?.[0]?.wait_time_charge : 0 : 0);


        return <Typography>{fee}</Typography>;
      },
    },
    {
      field: 'company_fee',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.COMPANY_FEE}`),
      width: 150,
      hide: user.response?.type !== ADMIN_USER && user.response?.type !== SUPER_ADMIN,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        const fee: string = String(jobInfo.assignedCompanyFee || 0);
        return <Typography>{fee}</Typography>;
      },
    },
    {
      field: 'job_wait_time',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.JOB_WAIT_TIME}`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        const job_wait_time: number = Number(jobInfo.job_wait_time || 0);

        return <Typography>{job_wait_time}</Typography>;
      },
    },
    {
      field: 'job_wait_time_charge',
      headerName: t(`Columns:${JOB_REPORT_COLUMNS.JOB_WAIT_TIME_CHARGE}`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        const wait_time_charge: string = String((jobInfo.job_bids?.[0]?.waitTimeCharge || 0) * (jobInfo.job_bids?.[0]?.waitTime || 0));

        return <Typography>{wait_time_charge}</Typography>;
      },
    },
    {
      field: 'gst',
      headerName: t('Columns:GST'),
      width: 150,
      renderCell: (params) => {

        const gst = getGST(params.row as IJobInfo)
        return <Typography>{gst}</Typography>;
      },
    },
    {
      field: 'additional_charges_1',
      headerName: t('Columns:extra_charges_amount') + ' 1',
      renderCell: (params) => {
        const jobInfo = params.row as IJobInfo
        return <Typography>{jobInfo.additional_charges?.amount1 || 0}</Typography>
      }
    },
    {
      field: 'additional_charges_2',
      headerName: t('Columns:extra_charges_amount') + ' 2',
      renderCell: (params) => {
        const jobInfo = params.row as IJobInfo
        return <Typography>{jobInfo.additional_charges?.amount2 || 0}</Typography>
      }
    },
    {
      field: 'buy_price',
      headerName: t('Columns:buy_price'),
      hide: !isAdminTransport,
      renderCell: (params) => {
        const row = params.row as IJobInfo;
        if (row.job_classification !== 'transport') {
          return <Typography>N/A</Typography>;
        }
        return <Typography>{row.initial_price}</Typography>;
      },
    },
    {
      field: 'sell_price',
      headerName: t('Columns:sell_price'),
      hide: !isAdminNonTransport,
      renderCell: (params) => {
        const row = params.row as IJobInfo;
        if (row.job_classification !== 'transport') {
          return <Typography>N/A</Typography>;
        }
        return <Typography>{row.posted_price}</Typography>;
      },
    },
    {
      field: 'total_price',
      headerName: t(`Columns:total_price`),
      width: 150,
      renderCell: (params) => {
        const jobInfo: IJobInfo = params.row as IJobInfo;
        // const price: string = String(jobInfo.job_bids?.[0]?.amount ?? '0');
        // const waitTimeCharge: number = Number(
        //   jobInfo.company?.waitTimeCharge || 0
        // );
        // const actualWaitTime: number = Number(jobInfo.job_wait_time || '0');
        // const allowWaitingTime: number = jobInfo.job_bids?.[0]?.waitTime ?? 0;
        // const marginPrice: string = String(jobInfo.margin_price || '0');

        // let totalWaitTimeCharge = computationWaitingTimeCharge(
        //   actualWaitTime,
        //   allowWaitingTime,
        //   waitTimeCharge
        // );

        // let gst = calculateGST(
        //   Number(price) + Number(marginPrice),
        //   totalWaitTimeCharge
        // );

        // let grandTotal =
        //   Number(price) +
        //   Number(marginPrice) +
        //   Number(gst) +
        //   Number(totalWaitTimeCharge);

        // // additional charges
        // const {additional_charges} = jobInfo;
        // grandTotal += parseFloat(additional_charges.amount1.toString());
        // grandTotal += parseFloat(additional_charges.amount2.toString());


        let total_price = getTotalFee(jobInfo);

        return <Typography>{total_price}</Typography>;
      },
    },
  ];
};
