import React from 'react';
import { Card, Grid, CardContent, Typography, LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: '13px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '20px 5px',
        boxSizing: 'border-box',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flexGrow: 1, // Allows the content to grow and take up available space
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Aligns the progress bar at the bottom
    },
    title: {
        color: '#223A59',
        marginBottom: theme.spacing(1),
        fontSize: '1rem', // Base font size
        [theme.breakpoints.down('xs')]: {
            fontSize: '.3rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.5rem', 
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem', 
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '.7rem', 
        },
    },
    percentage: {
        fontWeight: 'bold',
        color: '#223A59',
        fontSize: '2rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.5rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem', 
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem', 
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2rem', 
        },
    },
    total: {
        fontWeight: 'bold',
        color: '#223A59',
        fontSize: '1.5rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.2rem', // Adjusting total font size for small screens
        },
    },
    details: {
        fontSize: '0.8rem',
        color: '#777',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.6rem', // Adjusting details font size for small screens
        },
    },
    progressBar: {
        marginTop: theme.spacing(2),
        height: 2,
        borderRadius: 5,
    },
}));

const PercentCard = ({ label, total, number, showPercentage = true }) => {
    const classes = useStyles();
    const percentage = total > 0 ? ((number / total) * 100).toFixed(2) : (0).toFixed(2);

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ height: '100%' }}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    <div>
                        <Typography className={classes.percentage} variant="h4">
                            {showPercentage ? `${percentage}%` : total}
                        </Typography>
                        <Typography className={classes.title} gutterBottom>
                            {label}
                        </Typography>
                    </div>
                    <LinearProgress
                        variant="determinate"
                        value={showPercentage ? parseFloat(percentage) : 100}
                        className={classes.progressBar}
                        color="primary"
                    />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default PercentCard;
