import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, IconButton, makeStyles, Menu, MenuItem, Toolbar, Typography, InputBase, Paper, List, ListItem, ListItemText, Switch, Grid } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { WebStorage } from "../../../Utilities/WebStorage";
import { TOKEN_STORAGE_KEY } from "../../../Types/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserLoginResponse } from "../../../Redux/User/actions";
import { routes } from "../../../routes";
import { AppState } from "../../../Redux";
import { logout } from '../../../Redux/Auth/actions';
import { Job } from '../../../Api/Job/Job';
import useDebouncedValue from '../../../hooks/useDebouncedValue';
import { IAppHeaderProps } from "./AppHeader.types";
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    searchWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            width: '30%',
        },
    },
    search: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '2px 4px',
        backgroundColor: (props: IAppHeaderProps) => props.themeStyle.navbar_color,
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        // borderBottom: '1px solid #ccc', // Line color
        backgroundColor:'transparent !important',
        width: '100%',

    },
    inputInput: {
        padding: theme.spacing(1),
        color: 'white !important',

    },
    focused: {},
    suggestionsList: {
        position: 'absolute',
        zIndex: 1000,
        top: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px', // Added border radius to suggestions list
        boxShadow: theme.shadows[8], // Added shadow to suggestions list
        marginTop: '1px'
    },
    lower: { height: '25px', backgroundColor: '#223A59' },

    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // height: '70px',
        // background: 'linear-gradient(to right,black -99%, #1FA0ED 100%)',
        color: (props: IAppHeaderProps) => props.themeStyle.navbar_font,
        backgroundColor: (props: IAppHeaderProps) => props.themeStyle.navbar_color,
    },
    appBarShift: {
        width: (props: IAppHeaderProps) => `calc(100% - ${props.drawerWidth}px)`,
        marginLeft: (props: IAppHeaderProps) => props.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    profileIcon: {
        fontSize: '1rem',
        borderRadius: '10px'
    },
    halfHeight: {
        height: "50%"
    }
}));

const storage = new WebStorage(false);

function AppHeader(props: IAppHeaderProps) {
    const classes = useStyles(props);
    const { toggleTheme } = props
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const jobApi = new Job();

    const user = useSelector((state: AppState) => state.user);
    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const debouncedSearchQuery = useDebouncedValue(searchQuery, 500);

    let userType = user?.response.type;
    const companyName = user.response.company.companyName
    const handleUserIconClick = (event) => {
        setUserMenuAnchor(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    const handleSidebarMenuClick = () => {
        props.toggleSideBar(true);
    };

    const handleSignOutClick = () => {
        storage.clear(TOKEN_STORAGE_KEY);
        dispatch(setUserLoginResponse(null));
        dispatch(logout());
        history.push(routes.landing.login[0]);
    };

    const handleChangePasswordClick = () => {
        history.push(routes.home.root + routes.home.change_password);
    };

    const handleSearchChange = useCallback((e) => {
        setSearchQuery(e.target.value);
    }, []);

    useEffect(() => {
        if (debouncedSearchQuery) {
            fetchSuggestions(debouncedSearchQuery);
        } else {
            setSearchSuggestions([]);
        }
    }, [debouncedSearchQuery]);

    const fetchSuggestions = async (query) => {
        try {
            const response = await jobApi.searchJobs(query);
            setSearchSuggestions(response.data);
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSearchSuggestions([]);
        }
    };

    const handleThemeToggle = () => {
        if (props.toggleTheme) {
            props.toggleTheme(); // Call the toggleTheme function passed via props
        }
    };

    const reRoutes = {
        pending: '/home/transport/show/jobs/pending',
        in_progress: '/home/transport/show/jobs/in_progress',
        completed: '/home/transport/show/jobs/completed_jobs',
        available: '/home/transport/show/jobs/available',
        created: '/home/transport/show/jobs/pending',
    };

    const handleSuggestionClick = (suggestion) => {
        history.push(`${reRoutes[suggestion.status] || '/home/transport/show/jobs/all'}?reference=${suggestion.reference}`);
        setSearchQuery('');
        setSearchSuggestions([]);
    };

    const isMenuOpen = Boolean(userMenuAnchor);

    const handleBlur = () => {
        setTimeout(() => {
            setSearchSuggestions([]);
            setSearchQuery('');
        }, 200); // Delay to allow click event to register
    };

    return (
        <AppBar
            position="static"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: props.isSidebarOpen,
            })}
        >
            {/* <Grid container direction='row'> */}
            <Grid item md={12}>

                <Toolbar style={{ height: '50px !important' , minHeight :'50px' }}>
                    {userType === "SUPER_ADMIN" && (
                        <div className={classes.searchWrapper}>
                            {/* <Paper className={classes.search} > */}
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                              
                                placeholder="Search..."
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                    focused: classes.focused,
                                }}

                                inputProps={{ 'aria-label': 'search' }}
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onBlur={handleBlur}
                            />
                            {/* </Paper> */}
                            {searchQuery && searchSuggestions.length > 0 && (
                                <Paper className={classes.suggestionsList}>
                                    <List component="nav" aria-label="search suggestions">
                                        {searchSuggestions.map((suggestion) => (
                                            <ListItem
                                                button
                                                key={suggestion.id}
                                                onClick={() => handleSuggestionClick(suggestion)}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography>
                                                            <strong>Reference:</strong> {suggestion.reference}
                                                        </Typography>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                color="textPrimary"
                                                            >
                                                                Customer Reference: {suggestion.customer_reference || ''}
                                                            </Typography>
                                                            <br />
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                color="textPrimary"
                                                            >
                                                                Status: {suggestion.status}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Paper>
                            )}
                        </div>
                    )}
                    <div style={{ flexGrow: 1 }} />
                    <IconButton
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleUserIconClick}
                        color="inherit"
                        className={classes.profileIcon}
                    >
                        <span style={{ marginRight: '8px' }}><strong style={{color:'#e5e5e5'}}>Hello,</strong> {companyName}</span>
                        <AccountCircle />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={userMenuAnchor}
                        keepMounted
                        open={isMenuOpen}
                        onClose={handleUserMenuClose}
                    >
                        <MenuItem onClick={handleChangePasswordClick}>Change Password</MenuItem>
                        <MenuItem onClick={handleSignOutClick}>Sign Out</MenuItem>
                    </Menu>
                    {(process.env.ENV != 'production') &&
                        < IconButton
                            edge="end"
                            aria-label="toggle light/dark theme"
                            aria-haspopup="true"
                            onClick={handleThemeToggle}
                            color="inherit"
                        >
                            {props.themeStyle.name === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                        </IconButton>
                    }
                </Toolbar>
                {/* <Grid className={classes.lower}></Grid> */}

            </Grid>


        </AppBar >
    );
}

export default AppHeader;

