
import { Button, Divider, FormControl, InputLabel, MenuItem, makeStyles, Select, Grid, Box, InputAdornment, OutlinedInput, Popover, TextField, Typography, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from '../../../../Redux';
import { concat, conforms, get, groupBy, includes, isEqual, isNil } from 'lodash';
import { setLoader } from '../../../../Redux/Loader/actions';
import { Address } from '../../../../Api/Address/Address';
import { Company } from '../../../../Api/Company/Company'
import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import { toast, ToastContainer } from 'react-toastify';
import RatesTable from "./RatesTable";
import { GridEditCellPropsParams, GridRowsProp } from '@material-ui/data-grid';
import { AdminSettings } from "../../../../Api/AdminSettings/AdminSettings"
import RatesDialog from './RatesConfirmation'
import FileInput from '../../../Common/FileInput/FileInput';
import readXlsxFile from 'read-excel-file'
import readSheetNames from 'read-excel-file'
import { ICompanyInfo } from "../../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import DownloadExcel from './DownloadExcelv2'
import { IGridRowsProps } from './RatesTable.props'
import { pickBy } from 'lodash';

import {
    JOB_STATUS,
    POST_TO,
    TYPES_OF_PACKAGES,
    COUNTRIES,
    MOVEMENT_TYPES,
    TRIP_TYPE,
    USER_ROLES,
    JOB_TYPES
} from '../../../../Types/Constants';
import { IRatesManagement } from "../../../../../../Application/Models/RatesManagementSettingsModel/RatesManagement.types";
import SectionHeader from "../../../Common/SectionHeader/SectionHeader";



const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER } = USER_ROLES;
const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;
const addressApi = new Address();
const companyApi = new Company()

const adminSettingApi = new AdminSettings()

const tableClassification = ['single', 'metro_inner', 'metro_outer', 'interstate']
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    gap: {
        marginTop: 20,
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    textArea: {
        width: '100%',
    },
    btnCreate: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
    gridList: {
        width: 500,
        height: 450,
    },
    displayFlex: {
        display: 'flex'
    },
    displayGrid: {
        display: 'grid'
    },
    halfWidth: {
        width: '50%',
        margin: '1% 0 1% 0'
    }
}));

const generateDefaultValue = function () {
    let defaultValues = []
    for (let i = 1; i <= 34; i++) {
        defaultValues.push(
            {
                id: i,
                sell_rate: 0.00,
                buy_rate: 0.00
            }
        )
    }
    return defaultValues
}

function RatesManagement() {
    const [t] = useTranslation('RatesManagement')
    const dispatch = useDispatch()
    const classes = useStyles();
    const user = useSelector((state: AppState) => state.user.response);
    // const company = useSelector((state: AppState) => state.company)

    const isUserAdmin = user?.type === ADMIN_USER;
    const isSuperAdmin = user?.type === SUPER_ADMIN;
    const isDriverManager = user?.type === DRIVER_MANAGER;
    const [company, setCompanyList] = useState<ICompanyInfo[]>([])
    const [country, setCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);
    const hasAllPrivilege = isUserAdmin || isSuperAdmin
    const currentUserCompanyId: any = user?.company?.id || undefined;
    const [movementTypes, setMovementTypes] = useState<string[]>([]);
    const [containerTypes, setContainerTypes] = useState<string[]>([]);
    const [containerType, setContainerType] = useState('');

    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedTransportName, setSelectedTransportName] = useState('');
    const [selectedPickUpStreet, setSelectedPickUpStreet] = useState('');
    const [selectedDeliverStreet, setSelectedDeliverStreet] = useState('');

    const [movementType, setMovementType] = useState('');
    const [temperature, setTemperature] = useState('');
    const [pickUpAddressId, setPickUpAddressId] = useState('');
    const [deliverAddressId, setDeliverAddressId] = useState('');
    const [fuelSurchargeBuy, setFuelSurchargeBuy] = useState<number>(0);
    const [fuelSurchargeSell, setFuelSurchargeSell] = useState<number>(0);


    const [ratePerContainer, setRatePerContainer] = useState<number>(0);
    const [fuelSurcharge, setFuelSurcharge] = useState<number>(0);
    const [slotFee, setSlotFee] = useState<number>(0);
    const [terminalFee, setTerminalFee] = useState<number>(0);
    const [emptyBookingFee, setEmptyBookingFee] = useState<number>(0);
    const [tollFee, setTollFee] = useState<number>(0);
    const [freeDaysStorage, setFreeDaysStorage] = useState<number>(0);
    const [redeliveryFee, setRedeliveryFee] = useState<number>(0);
    const [unpackFee, setUnpackFee] = useState<number>(0);
    const [fumigationFee, setFumigationFee] = useState<number>(0);
    const [storageFee, setStorageFee] = useState<number>(0);
    const [viaYardFee, setViaYardFee] = useState<number>(0);
    const [liftFee, setLiftFee] = useState<number>(0);


    const [addresses, setAddresses] = useState<IAddress[]>([]);
    const [gridValues, setGridRows] = useState<GridRowsProp>(generateDefaultValue());

    const [metroInnerGrid, setmetroInnerGrid] = useState<GridRowsProp>(generateDefaultValue());
    const [metroOuterGrid, setMetroOuterGrid] = useState<GridRowsProp>(generateDefaultValue());
    const [interstateGrid, setInterstateGrid] = useState<GridRowsProp>(generateDefaultValue());

    const [hiddenTables, setHiddenTables] = useState<boolean>(true)

    const [configurationType, setconfigurationType] = useState<string>("single")
    const [wharfSellRate, setWharfSellRate] = useState<any>(0)
    const [wharfBuyRate, setWharfBuyRate] = useState<any>(0)


    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };
    const checkIfEmpty = (data: any) => {
        return (data === '' || isNil(data));
    }

    const [companyId, setCompanyId] = useState(
        isSuperAdmin || isUserAdmin ? '' : currentUserCompanyId
    );
    const [transportcompanyId, setTransportCompanyId] = useState<any>('');


    const formatAddress = useCallback(
        (address: IAddress) =>
            `${address.street_number} ${address.city} ${address.state
            } ${t(address.country)}`,
        [t]
    );



    const companyListOptions = useMemo(
        () => {

            return company.map((company, i) => (
                <MenuItem key={i} value={company?.id}>
                    {company?.companyName}
                </MenuItem>
            ))


        },

        [company]
    );

    useEffect(() => {
        setMovementTypes(Object.values(MOVEMENT_TYPES))
        setContainerTypes(Object.values(JOB_TYPES))
    }, [])


    // const getAddresses = useCallback(

    //   );
    const getAddresses = (companyId: string) => {

        if (companyId === '' || isNil(companyId)) return;
        dispatch(setLoader(true));
        addressApi
            .allForJob(
                companyId,
                true,
                null,
                false,
                false,
                true
            )
            .then((response) => {
                const addresses = response.data as IAddress[];
                setAddresses(addresses);
                dispatch(setLoader(false));
            })
            .catch((err: any) => {
                console.log('err', err);
                toast.error(t('error_getting_addresses'));
                dispatch(setLoader(false));
            });

    }

    useEffect(() => {
        getAddresses(companyId)
    }, [companyId])


    useEffect(() => {
        if (movementType == 'wharf') {
            setHiddenTables(false)
            if (!checkIfEmpty(companyId) && !checkIfEmpty(containerType) && !checkIfEmpty(transportcompanyId) && !checkIfEmpty(movementType) && !checkIfEmpty(temperature) && !checkIfEmpty(pickUpAddressId) && !checkIfEmpty(deliverAddressId)) {
                getRatesConfig(false, 'wharf')

            }

        } else {
            if (!checkIfEmpty(companyId) && !checkIfEmpty(transportcompanyId) && !checkIfEmpty(movementType) && !checkIfEmpty(temperature) && !checkIfEmpty(pickUpAddressId) && !checkIfEmpty(deliverAddressId)) {
                setconfigurationType('single')
                console.log("single")
                setHiddenTables(false)
                getRatesConfig(false, 'single')

            } else if (!checkIfEmpty(companyId) && !checkIfEmpty(transportcompanyId) && !checkIfEmpty(movementType) && !checkIfEmpty(temperature) && !checkIfEmpty(pickUpAddressId) && checkIfEmpty(deliverAddressId)) {
                setconfigurationType('multiple')
                setHiddenTables(false)
                getRatesConfig(false, 'multiple')

            }
            else {
                setHiddenTables(true)
                setGridRows(generateDefaultValue());
                setFuelSurchargeBuy(0)
                setFuelSurchargeSell(0)
            }

        }
        setSelectedCompanyName(convertId(companyId, 'company') || '')
        setSelectedTransportName(convertId(transportcompanyId, 'company') || '')
        setSelectedPickUpStreet(convertId(pickUpAddressId, 'address') || '')
        setSelectedDeliverStreet(convertId(deliverAddressId, 'address') || '')

    }, [companyId, transportcompanyId, movementType, temperature, pickUpAddressId, deliverAddressId, containerType])

    const getRatesConfig = (is_default: boolean, configType: string) => {
        adminSettingApi
            .getRates(
                {
                    posting_company: is_default ? '' : companyId,
                    transport_company: transportcompanyId,
                    movement_type: movementType,
                    temperature: temperature,
                    pick_up_address_id: pickUpAddressId,
                    deliver_address_id: deliverAddressId,
                    is_default: is_default,
                    configuration_type: configType,
                    container_type: containerType
                }
            )
            .then((response) => {
                const ratesData = response.data as IRatesManagement;
                if (configType == 'single') {
                    setGridRows(ratesData.rates || generateDefaultValue());
                } else if (configType == 'multiple') {
                    setInterstateGrid(ratesData.rates ? ratesData.rates[0]['interstate'] : generateDefaultValue())
                    setMetroOuterGrid(ratesData.rates ? ratesData.rates[0]['metroOuter'] : generateDefaultValue())
                    setmetroInnerGrid(ratesData.rates ? ratesData.rates[0]['metroInner'] : generateDefaultValue())
                } else if (configType == 'wharf') {

                    const ratesVal = ratesData.rates ? ratesData.rates[0] : {}
                    setRatePerContainer(ratesVal.ratePerContainer as number || 0)
                    setFuelSurcharge(ratesVal.fuelSurcharge as number || 0)
                    setSlotFee(ratesVal.slotFee as number || 0)
                    setTerminalFee(ratesVal.terminalFee as number || 0)
                    setEmptyBookingFee(ratesVal.emptyBookingFee as number || 0)
                    setTollFee(ratesVal.tollFee as number || 0)
                    setWharfBuyRate(ratesVal.buyRate as number || 0)
                    setWharfSellRate(ratesVal.sellRate as number || 0)
                    setFreeDaysStorage(ratesVal.freeDaysStorage as number || 0)
                    setRedeliveryFee(ratesVal.redeliveryFee as number || 0)
                    setUnpackFee(ratesVal.unpackFee as number || 0)
                    setFumigationFee(ratesVal.fumigationFee as number || 0)
                    setStorageFee(ratesVal.storageFee as number || 0)
                    setViaYardFee(ratesVal.viaYardFee as number || 0)
                    setLiftFee(ratesVal.liftFee as number || 0)
                    // setContainerType(ratesData.container_type || '')
                }


                setFuelSurchargeBuy(ratesData.fuel_surcharge_buy || 0)
                setFuelSurchargeSell(ratesData.fuel_surcharge_sell || 0)

                dispatch(setLoader(false));
            })
            .catch((err: any) => {
                toast.error(t('error_getting_config'));
                dispatch(setLoader(false));
            });
    }
    const getCompanies = useCallback(
        () => {
            dispatch(setLoader(true))
            companyApi.getAll()
                .then((response) => {
                    dispatch(setLoader(false))
                    setCompanyList(response.data)
                })
                .catch((err: any) => {
                    dispatch(setLoader(false))
                    setCompanyList([])
                    console.log('err', err)
                    toast.error(t('error_getting_companies'))
                })
        },
        [dispatch, t],
    )
    useEffect(() => {
        // getRatesConfig(true)
        getCompanies()

    }, [])

    const convertId = (id: any, type: any) => {
        if (type == 'address') {
            return addresses.find(x => x.id == id)?.street_number || ''

        } else if (type == 'company') {
            return company.find(x => x.id == id)?.companyName || ''
        }
    }

    const handleSetAsDefault = async () => {
        const newConfigData: IRatesManagement = {
            rates: gridValues,
            posting_company: '',
            transport_company: '',
            movement_type: '',
            temperature: '',
            pick_up_address_id: '',
            deliver_address_id: '',
            fuel_surcharge_buy: fuelSurchargeBuy,
            fuel_surcharge_sell: fuelSurchargeSell,
            configuration_type: configurationType,
            container_type: containerType

        }
        dispatch(setLoader(true))
        await adminSettingApi.setDefaultRates(newConfigData).then(response => {
            toast.success(t('rates_configuration_has_bee_updated'))

            dispatch(setLoader(false))
            handleDialogClose()
        })
            .catch(err => {
                console.log(`err`, err)
                dispatch(setLoader(false))
                toast.error(t('error_getting_config'))
            })
    }
    function isNull(arr: any[]) {
        return arr.every(element => element === null);
    }
    const handleSave = async () => {
        if (checkIfEmpty(companyId)) { toast.warning(t('posting_company') + ' is required.'); return }
        if (checkIfEmpty(temperature)) { toast.warning(t('temperature') + ' is required.'); return }

        if (checkIfEmpty(transportcompanyId)) { toast.warning(t('transport_company') + ' is required.'); return }
        if (checkIfEmpty(movementType)) { toast.warning(t('movement_type') + ' is required.'); return }
        if (checkIfEmpty(pickUpAddressId)) { toast.warning(t('pick_up_address') + ' is required.'); return }
        // 
        let rates_data: any
        if (configurationType == 'single') {
            if (checkIfEmpty(deliverAddressId)) { toast.warning(t('delivery_address') + ' is required.'); return }
            rates_data = gridValues
        } else if (configurationType == 'multiple') {
            rates_data = [{
                metroInner: metroInnerGrid,
                metroOuter: metroOuterGrid,
                interstate: interstateGrid
            }]
        }
        else if (configurationType == 'wharf') {
            rates_data = [
                {
                    // ratePerContainer: ratePerContainer,
                    buyRate: wharfBuyRate,
                    sellRate: wharfSellRate,
                    fuelSurcharge: fuelSurcharge,
                    slotFee: slotFee,
                    terminalFee: terminalFee,
                    emptyBookingFee: emptyBookingFee,
                    tollFee: tollFee,
                    freeDaysStorage: freeDaysStorage,
                    redeliveryFee: redeliveryFee,
                    unpackFee: unpackFee,
                    fumigationFee: fumigationFee,
                    storageFee: storageFee,
                    viaYardFee: viaYardFee,
                    liftFee: liftFee,

                }
            ]
        }
        const newConfigData: IRatesManagement = {
            rates: rates_data,
            posting_company: companyId,
            transport_company: transportcompanyId,
            movement_type: movementType,
            temperature: temperature,
            pick_up_address_id: pickUpAddressId,
            deliver_address_id: deliverAddressId,
            fuel_surcharge_buy: fuelSurchargeBuy,
            fuel_surcharge_sell: fuelSurchargeSell,
            configuration_type: configurationType,
            container_type: containerType
        }
        await adminSettingApi.setRatesConfig(newConfigData).then(response => {
            toast.success(t('rates_configuration_has_bee_updated'))

            dispatch(setLoader(false))
            handleDialogClose()
        })
            .catch(err => {
                console.log(`err`, err)
                dispatch(setLoader(false))
                toast.error(t('error_getting_config'))
            })

    }
    const addressOptions = useMemo(
        () =>
            addresses.map((address, i) => (
                <MenuItem key={i} value={address.id}>
                    <div style={{ 'color': 'gray' }}>{address.company_name} &nbsp;</div>  {formatAddress(address)}
                </MenuItem>
            )),
        [addresses, country]
    );

    const handleGridChange = (params: GridEditCellPropsParams) => {
        let gridTemp = gridValues
        const newState = gridTemp.map(obj =>
            obj.id === params.id ? { ...obj, [params.field]: params.props.value } : obj
        );
        setGridRows(newState)
    }

    const handleMetroInnerGridChange = (params: GridEditCellPropsParams) => {
        let gridTemp = metroInnerGrid
        const newState = gridTemp.map(obj =>
            obj.id === params.id ? { ...obj, [params.field]: params.props.value } : obj
        );
        setmetroInnerGrid(newState)
    }
    const handleMetroOuterGridChange = (params: GridEditCellPropsParams) => {
        let gridTemp = metroOuterGrid
        const newState = gridTemp.map(obj =>
            obj.id === params.id ? { ...obj, [params.field]: params.props.value } : obj
        );
        setMetroOuterGrid(newState)
    }
    const handleInterstateGridChange = (params: GridEditCellPropsParams) => {
        let gridTemp = interstateGrid
        const newState = gridTemp.map(obj =>
            obj.id === params.id ? { ...obj, [params.field]: params.props.value } : obj
        );
        setInterstateGrid(newState)
    }

    const handleUpload = async (file: any) => {
        if (file.length != 0) {

            await readXlsxFile(file[0]).then((rows) => {
                let castedRows = rows.filter(row => row.filter(column => column !== null).length > 0)
                const key_length = castedRows.length
                
                if (key_length >= 14 && key_length <= 22) {
                    if (configurationType != 'wharf') {
                        toast.warning(t('Invalid Template'))
                        return
                    }
                    let objectedRow = pickBy(Object.fromEntries(castedRows))
                    let remap = Object.keys(objectedRow).map(x => {
                        let ctext = x.replace(/[^a-zA-Z ]/g, "").toLowerCase().replaceAll(" ", "_");
                        return [ctext, objectedRow[x]]
                    })
                    const final = Object.fromEntries(remap)

                    setFuelSurchargeBuy(final.fuel_surchargebuy || 0)
                    setFuelSurchargeSell(final.fuel_surchargesell || 0)
                    setWharfBuyRate(final.buy_rate || 0)
                    setWharfSellRate(final.sell_rate || 0)
                    setFreeDaysStorage(final.free_days_in_storage || 0)
                    setRatePerContainer(final.rate_per_container || 0)
                    setFuelSurcharge(final.fuel_surcharge || 0)
                    setSlotFee(final.slot_fee || 0)
                    setTerminalFee(final.terminal_fee || 0)
                    setEmptyBookingFee(final.empty_booking_fee || 0)
                    setTollFee(final.toll_fee || 0)
                    setRedeliveryFee(final.redelivery_fee_flat_fee || 0)
                    setUnpackFee(final.unpack_fee_flat_fee || 0)
                    setFumigationFee(final.fumigation_fee_flat_fee || 0)
                    setStorageFee(final.storage_per_day || 0)
                    setViaYardFee(final.via_yard_flat_fee || 0)
                    setLiftFee(final.lift_on__lift_off_fee_flat_fee || 0)
                } else if (key_length == 44) {
                    if (configurationType != 'multiple') {
                        toast.warning(t('Invalid Template'))
                        return
                    }
                    let foundHeader = false
                    let metroInnerRate: any[] = []
                    let metroOuterRate: any[] = []

                    let interstateRate: any[] = []

                    let objectedRow = pickBy(Object.fromEntries(castedRows))
                    let remap = Object.keys(objectedRow).map(x => {
                        let ctext = x.replace(/[^a-zA-Z ]/g, "").toLowerCase().replaceAll(" ", "_");
                        return [ctext, objectedRow[x]]
                    })
                    const final = Object.fromEntries(remap)

                    setFuelSurchargeBuy(final.fuel_surchargebuy || 0)
                    setFuelSurchargeSell(final.fuel_surchargesell || 0)
                    rows.forEach(element => {
                        if (!isNull(element)) {

                            if (!foundHeader) {
                                if (element.indexOf('Pallet No') > -1 || element.indexOf('Pallet no') > -1) {
                                    foundHeader = true;
                                }
                            } else {

                                metroInnerRate.push({
                                    id: element[0],
                                    sell_rate: element[1].toString(),
                                    buy_rate: element[2].toString()

                                })
                                metroOuterRate.push({
                                    id: element[4],
                                    sell_rate: element[5].toString(),
                                    buy_rate: element[6].toString()

                                })
                                interstateRate.push({
                                    id: element[8],
                                    sell_rate: element[9].toString(),
                                    buy_rate: element[10].toString()

                                })
                            }
                        }
                    }

                    )
                    setMetroOuterGrid(metroOuterRate)
                    setmetroInnerGrid(metroInnerRate)
                    setInterstateGrid(interstateRate)

                } else if (key_length == 35) {
                    if (configurationType != 'single') {
                        toast.warning(t('Invalid Template'))
                        return
                    }
                    let foundHeader = false
                    let rates: any[] = []

                    let objectedRow = pickBy(Object.fromEntries(castedRows))
                    let remap = Object.keys(objectedRow).map(x => {
                        let ctext = x.replace(/[^a-zA-Z ]/g, "").toLowerCase().replaceAll(" ", "_");
                        return [ctext, objectedRow[x]]
                    })
                    const final = Object.fromEntries(remap)

                    setFuelSurchargeBuy(final.fuel_surchargebuy || 0)
                    setFuelSurchargeSell(final.fuel_surchargesell || 0)

                    rows.forEach(element => {
                        if (!isNull(element)) {
                            if (!foundHeader) {
                                if (element.indexOf('Pallet No') > -1 || element.indexOf('Pallet no') > -1) {
                                    foundHeader = true;
                                }
                            } else {
                                rates.push({
                                    id: element[0],
                                    sell_rate: element[1].toString(),
                                    buy_rate: element[2].toString()

                                })
                            }
                        }
                    });
                    setGridRows(rates)
                } else {
                    toast.error("Invalid Template")
                }

            })

        }
    }

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("company_management")} title={t('rates_management')} />
               

                <Grid item>
                    <Grid container spacing={2}>
                        <Grid className={classes.displayFlex} container xs={7} direction="column" spacing={2}>
                            <Grid item >



                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel className={classes.selectLabel}>
                                        {t('posting_company')}
                                    </InputLabel>
                                    <Select
                                        value={companyId}
                                        readOnly={hasAllPrivilege ? false : true}
                                        disabled={hasAllPrivilege ? false : true}
                                        onChange={(e) => {
                                            const companyId = e.target.value;
                                            const selectedCompany = company.find(
                                                (c) => c.id === companyId
                                            );

                                            if (selectedCompany) {
                                                setCountry(selectedCompany.country);
                                                // getAddresses(companyId);
                                            }

                                            setCompanyId(companyId);
                                        }}
                                    >
                                        {companyListOptions}
                                    </Select>

                                </FormControl>

                            </Grid>

                            <Grid item >
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel className={classes.selectLabel}>
                                        {t('transport_company')}
                                    </InputLabel>
                                    <Select
                                        value={transportcompanyId}
                                        onChange={(e) => {
                                            const companyId = e.target.value;
                                            const selectedCompany = company.find(
                                                (c) => c.id === companyId
                                            );

                                            // if (selectedCompany) {
                                            //     setCountry(selectedCompany.country);
                                            //     // getAddresses(companyId);
                                            // }

                                            setTransportCompanyId(companyId);
                                        }}
                                    >
                                        {companyListOptions}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item />

                            <Grid item>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel className={classes.selectLabel}>
                                        {t('movement_type')}
                                    </InputLabel>
                                    <Select
                                        value={movementType}
                                        onChange={(e) => {
                                            if (e.target.value == 'wharf') {
                                                setconfigurationType('wharf')
                                            }
                                            setMovementType(e.target.value as string)
                                        }}
                                    >
                                        {movementTypes.map((movementType, i) => (
                                            <MenuItem key={i} value={movementType}>
                                                {t(movementType)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item />
                            {(() => {
                                if (movementType == 'wharf')
                                    return <Grid item>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel className={classes.selectLabel}>
                                                {t('container_type')}
                                            </InputLabel>
                                            <Select
                                                value={containerType}
                                                onChange={(e) => setContainerType(e.target.value as string)}
                                            >
                                                {containerTypes.map((containerType, i) => (
                                                    <MenuItem key={i} value={containerType}>
                                                        {t(containerType)}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>



                            })()}


                            <Grid item>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel className={classes.selectLabel}>
                                        {t('pick_up_address')}
                                    </InputLabel>
                                    <Select
                                        value={pickUpAddressId}
                                        onChange={(e) => {
                                            setPickUpAddressId(e.target.value as any);
                                        }}

                                    >
                                        {addressOptions}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel className={classes.selectLabel}>
                                        {t('delivery_address')}
                                    </InputLabel>
                                    <Select
                                        value={deliverAddressId}
                                        onChange={(e) => {
                                            setDeliverAddressId(e.target.value as any);
                                        }}

                                    >
                                        {addressOptions}
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid container xs={1} ></Grid>
                        <Grid className={classes.displayFlex} container xs={4} >
                            {/* <FormControl>
                            <FormLabel >{t('temperature')}</FormLabel>
                            <RadioGroup
                              
                                value={temperature}
                                name="radio-buttons-group"
                                onChange={(e)=>{setTemperature(e.target.value)}}>

                                <FormControlLabel value="ambient" control={<Radio />} label={t('ambient')} />
                                <FormControlLabel value="chilled" control={<Radio />} label={t('chilled')} />
                                <FormControlLabel value="frozen" control={<Radio />} label={t('frozen')} />
                            </RadioGroup>
                            </FormControl> */}
                            <FormControl>
                                <FormLabel id="demo-controlled-radio-buttons-group">Temperature</FormLabel>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={temperature}
                                    onChange={((e) => { setTemperature(e.target.value) })}
                                >
                                    <FormControlLabel value="ambient" control={<Radio />} label={t('ambient')} />
                                    <FormControlLabel value="chilled" control={<Radio />} label={t('chilled')} />
                                    <FormControlLabel value="frozen" control={<Radio />} label={t('frozen')} />
                                </RadioGroup>
                            </FormControl>
                            <TextField
                                value={fuelSurchargeBuy}
                                disabled={!hasAllPrivilege}
                                onChange={(e) => setFuelSurchargeBuy(e.target.value as any)}
                                label={t('fuel_surcharge_buy')}
                                fullWidth
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}

                            />
                            <TextField
                                value={fuelSurchargeSell}
                                disabled={!hasAllPrivilege}

                                onChange={(e) => setFuelSurchargeSell(e.target.value as any)}
                                label={t('fuel_surcharge_sell')}
                                fullWidth
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>


                </Grid>
                <Grid item hidden={hiddenTables}>
                    <Grid item>
                        {(() => {
                            if (configurationType == "single")
                                return <RatesTable
                                    dataValues={gridValues}
                                    isAdmin={hasAllPrivilege}
                                    onChange={handleGridChange} />
                            if (configurationType == 'multiple')
                                return <Grid container
                                    direction="row"
                                    spacing={1}
                                    justifyContent="space-between"
                                >
                                    <Grid md={4} item >
                                        <RatesTable
                                            dataValues={metroInnerGrid}
                                            title={'Metro Inner'}
                                            isAdmin={hasAllPrivilege}
                                            onChange={handleMetroInnerGridChange} />
                                    </Grid>

                                    <Grid md={4} item >
                                        <RatesTable
                                            dataValues={metroOuterGrid}
                                            title={'Metro Outer'}
                                            isAdmin={hasAllPrivilege}
                                            onChange={handleMetroOuterGridChange} />
                                    </Grid>

                                    <Grid md={4} item >
                                        <RatesTable
                                            dataValues={interstateGrid}
                                            title={'Interstate'}

                                            isAdmin={hasAllPrivilege}
                                            onChange={handleInterstateGridChange} />
                                    </Grid>
                                </Grid>
                            if (configurationType == 'wharf')
                                return (<Grid container spacing={2}>

                                    <Grid item className={classes.displayGrid} xs={6}   >
                                        <TextField
                                            value={wharfSellRate}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}
                                            onChange={(e) => setWharfSellRate(e.target.value as any)}
                                            label={t('sell_rate')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={wharfBuyRate}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}
                                            onChange={(e) => setWharfBuyRate(e.target.value as any)}
                                            label={t('buy_rate')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />

                                        <TextField
                                            value={slotFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setSlotFee(e.target.value as any)}
                                            label={t('slot_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={terminalFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setTerminalFee(e.target.value as any)}
                                            label={t('terminal_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={emptyBookingFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setEmptyBookingFee(e.target.value as any)}
                                            label={t('empty_booking_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={tollFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setTollFee(e.target.value as any)}
                                            label={t('tolls')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={freeDaysStorage}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setFreeDaysStorage(e.target.value as any)}
                                            label={t('free_days_in_storage')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />

                                    </Grid>

                                    <Grid item className={classes.displayGrid} xs={6}   >
                                        <TextField
                                            value={redeliveryFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}
                                            onChange={(e) => setRedeliveryFee(e.target.value as any)}
                                            label={t('redelivery_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />


                                        <TextField
                                            value={unpackFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setUnpackFee(e.target.value as any)}
                                            label={t('unpack_fee_flat')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={fumigationFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setFumigationFee(e.target.value as any)}
                                            label={t('fumigation_flat_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={storageFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setStorageFee(e.target.value as any)}
                                            label={t('storage_per_day')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={viaYardFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setViaYardFee(e.target.value as any)}
                                            label={t('via_yard_flat_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                        <TextField
                                            value={liftFee}
                                            disabled={!hasAllPrivilege}
                                            className={classes.halfWidth}

                                            onChange={(e) => setLiftFee(e.target.value as any)}
                                            label={t('lift_on_off_flat_fee')}

                                            type="number"
                                            variant="outlined"
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold',
                                                },
                                            }}

                                        />
                                    </Grid>

                                </Grid>
                                )


                        })()


                        }

                    </Grid>

                    <Grid style={{ 'padding': '3% 0 0 0 ' }} item hidden={!hasAllPrivilege} >
                        <Grid style={{ 'padding': '3% 0 0 0 ' }} container>
                            <Grid item sm={6} spacing={2}>

                                <Box display="flex" justifyContent="flex-start">
                                    <Grid item style={{ marginLeft: '10px' }} md={2}>

                                        <Button variant="contained" onClick={(e) => { getRatesConfig(true, configurationType) }} color="primary">{t("copy")}</Button>
                                    </Grid>

                                    <Grid item style={{ marginLeft: '10px' }} md={8}>

                                        <FileInput
                                            label="Upload Config"
                                            onChange={(e: any) => handleUpload(e.target.files)}
                                            accept=".xls,.xlsx"
                                        />
                                    </Grid>
                                    <Grid item hidden={hiddenTables} style={{ marginLeft: '10px' }} md={4}>

                                        <DownloadExcel
                                            type={configurationType}
                                            data={{
                                                "single": [
                                                    { key: t("posting_company"), value: selectedCompanyName },
                                                    { key: t("transport_company"), value: selectedTransportName },
                                                    { key: t("movement_type"), value: t(movementType) },
                                                    { key: t("pick_up_address"), value: selectedPickUpStreet },
                                                    { key: t("delivery_address"), value: selectedDeliverStreet },
                                                    { key: t("temperature"), value: temperature },
                                                    { key: t("fuel_surcharge_sell"), value: fuelSurchargeSell },
                                                    { key: t("fuel_surcharge_buy"), value: fuelSurchargeBuy }

                                                ],
                                                "wharf": [
                                                    { key: t("container_type"), value: t(containerType) },
                                                    { key: t("sell_rate"), value: wharfSellRate },
                                                    { key: t("buy_rate"), value: wharfBuyRate },
                                                    // { key: t("rate_per_container"), value: ratePerContainer as unknown as string },
                                                    // { key: t("fuel_surcharge"), value: fuelSurcharge as unknown as string },
                                                    { key: t("slot_fee"), value: slotFee as unknown as string },
                                                    { key: t("terminal_fee"), value: terminalFee as unknown as string },
                                                    { key: t("empty_booking_fee"), value: emptyBookingFee as unknown as string },
                                                    { key: t("toll_fee"), value: tollFee as unknown as string },
                                                    { key: t("free_days_in_storage"), value: freeDaysStorage as unknown as string },
                                                    { key: t("redelivery_fee"), value: redeliveryFee as unknown as string },
                                                    { key: t("unpack_fee_flat"), value: unpackFee as unknown as string },
                                                    { key: t("fumigation_flat_fee"), value: fumigationFee as unknown as string },
                                                    { key: t("storage_per_day"), value: storageFee as unknown as string },
                                                    { key: t("via_yard_flat_fee"), value: viaYardFee as unknown as string },
                                                    { key: t("lift_on_off_flat_fee"), value: liftFee as unknown as string },
                                                ]

                                            }}
                                            gridData={{
                                                single: gridValues,
                                                metroInnerGrid: metroInnerGrid,
                                                metroOuterGrid: metroOuterGrid,
                                                interstateGrid: interstateGrid
                                            }}
                                        />

                                    </Grid>


                                </Box>
                            </Grid>
                            <Grid item sm={6} spacing={2}>

                                <Box display="flex" justifyContent="flex-end">

                                    <Button variant="contained" onClick={(e) => { handleSave() }} color="primary">{t('save')}</Button>

                                    <Button style={{ marginLeft: '10px' }} variant="contained" onClick={(e) => { handleDialogOpen() }} color="secondary">{t('set_as_default')}</Button>

                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
                <RatesDialog
                    open={dialogOpen}
                    handleClose={handleDialogClose}
                    handleSubmit={handleSetAsDefault}
                />
            </Grid>
        </>
    )
}


export default RatesManagement