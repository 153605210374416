import axios from 'axios';
import {
    IDeliveryFilter,
    IJobBidPayload,
    IJobFilters,
    IJobReportCSVPayload,
    IJobReportPayload,
    IJobLazyReportPayload,
    IJobStatisticsPayload,
    IUpdateAdditionalChargesPayload,
} from '../../../../Application/DTOs/JobsDto/JobsDto.types';
import { TShowTypes } from '../../Components/Home/Jobs/Jobs.types';
import { TActualDateTypes, TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';

const baseUrl = process.env.REACT_APP_API_URL;

const storage = new WebStorage();

export class JobV2 {
    get(
        classification: string,
        country: string | undefined,
        filters?: IJobFilters,
        pagetype?: string,
        movementType?: string,

    ) {
        return axios.get(`${baseUrl}/job/get/maps`, {
            params: {
                classification,
                country,
                filters,
                pagetype,
                movementType
            },
            headers: {
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            },
        });
    }
    getJobListing(
        classification: string,
        country: string | undefined,
        offset: any,
        limit: any,
        page: string,
        sortConfig : any,
        filters?: IJobFilters,
    ) {
        return axios.get(`${baseUrl}/job/get/list`, {
            params: {
                classification,
                country,
                page,
                offset,
                limit, 
                sortConfig,
                filters,
            },
            headers: {
                Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
            },
        });
    }
}
