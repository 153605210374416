import { GridColumns } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import { TFunction } from "i18next";
import { IAddress } from "../../../../../Application/DTOs/AddressDto/AddressDto.types";



export const getAddressesColumns = (t: TFunction): GridColumns => [
    {
        field: 'city',
        headerName: t('city'),
        width: 200
    },
    {
        field: 'street_number',
        headerName: t('address'),
        width: 200
    },
    {
        field: 'postcode',
        headerName: t('postcode'),
        width: 200
    },
    {
        field: 'country',
        headerName: t('country'),
        width: 200,
        valueGetter: (params) => t((params.row as IAddress).country)
    },
    {
        field: 'is_main',
        headerName: t('branch_type'),
        width: 200,
        renderCell: (params) =>  {
            
            return (params.row.is_main) ? <Grid>Main Branch </Grid> :<Grid>Secondary</Grid>
        }
    },
    
]