export const OVERDUE_CSV_COLUMNS = {
  REFERENCE: 'reference',
  COMPANY: 'company',
  DRIVER: 'driver',
  MOVEMENT_TYPE: 'movement_type',
  VEHICLE_TYPE: 'vehicle_type',
  JOB_TYPE: 'job_type',
  JOB_WAIT_TIME: 'job_wait_time',
  JOB_WAIT_TIME_CHARGE: 'job_wait_time_charge',
  BIDDING_COMPANY_NAME: 'bidding_company_name',
  AGREED_PRICE: 'agreed_price',
  POSTED_PRICE: 'posted_price',
  FEE: 'fee',
  COMISSION_FEE: 'commission',
  DELIVER_DATE: 'deliver_date',
  SUPPOSED_DELIVER_DATE: 'supposed_deliver_date',
  DELIVERY_DATE: 'delivery_date',
  DELIVERY_TIME: 'delivery_time',
  DUE_DELIVERY_DATE : 'due_delivery_date'

};
