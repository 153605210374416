import { makeStyles, Grid, TextField, Select, FormControl, InputLabel, MenuItem, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ITextFieldSelectComboProps } from "./TextFieldSelectCombo.types";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%'
    },
    formControl: {
        minWidth: '100%',
    }
}))

function TextFieldSelectCombo(props: ITextFieldSelectComboProps) {
    const {items, selectPlacholder, textPlaceholder, selectValue, textValue, onSelectChange, onTextChange, disabled} = props
    const classes = useStyles()
    const [t] = useTranslation()

    return (
        <>
            <div className={classes.root}>
                <Grid
                    spacing={0}
                    container
                >
                    <Grid item md={9}>
                        <TextField
                            disabled={disabled}
                            label={textPlaceholder}
                            value={textValue}
                            onChange={onTextChange}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <FormControl variant="filled" className={classes.formControl}>
                            <InputLabel style={{ fontWeight: 'bold' }}>{selectPlacholder}</InputLabel>
                            <Select
                                disabled={disabled}
                                value={selectValue}
                                onChange={onSelectChange}
                            >
                                {items.map((item, i) => (
                                    <MenuItem key={i} value={item}>{t(item)}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}


export default TextFieldSelectCombo