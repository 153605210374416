import { Grid, makeStyles, Box, styled, Paper, Typography, Breadcrumbs, Link, MenuItem, Container, InputLabel, FormControl, Select } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Job } from '../../../Api/Job/Job'
import { useCallback, useMemo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLoader } from '../../../Redux/Loader/actions'
import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants'
import { toast } from 'react-toastify'
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types'
import { AppState } from '../../../Redux'
import WeeklyCount from './WeeklyCount/WeeklyCount'
import RerouteCard from './RerouteCards'
const BOX_HEIGHT = 45

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        margin: theme.spacing(2),
        borderRadius: '15px', // Adjust the border radius as needed
        boxShadow: theme.shadows[5],
    },
    label: {
        margin: '0px',
        fontSize: '1rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        // color: 'rgb(108, 115, 127)'
    },
    mainLabel: {
        marginTop: '10px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: 'rgb(108, 115, 127)'
    },
    secondary: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: 'rgb(108, 115, 127)'
    },
    middleHeader: {
        alignItems: 'left',
        width: 'auto'
    },
    noData: {
        height: '15rem',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'grey'
    },
    jobContainer: {
        textAlign: 'center',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    jobItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    jobText: {
        fontSize: '2rem',
        fontWeight: 'bold',
        width: '5rem', // Adjust width to fit the largest number
        textAlign: 'center', // Ensures numbers are right-aligned within the fixed width
        marginRight: theme.spacing(1),
    },
    jobLabel: {
        fontSize: '1rem', // Adjust the size as needed
    },
    totalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    percentageChange: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem', // Adjust the size as needed
        color: theme.palette.text.primary,
        marginLeft: 'auto',
    },
    iconUp: {
        color: 'green',
        marginLeft: theme.spacing(1),
    },
    iconDown: {
        color: 'red',
        marginLeft: theme.spacing(1),
    },
}));
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    color: theme.palette.text.secondary,
}));
const jobApi = new Job()

const {
    COMPLETED,
    POSTED,
    IN_PROGRESS,
    CREATED
} = JOB_STATUS

const {
    ADMIN_USER,
    SUPER_ADMIN
} = USER_ROLES


function UpcomingJobs({ jobsThisWeek }) {
    const [t] = useTranslation('Dashboard')

    const dispatch = useDispatch()
    const classes = useStyles()
    const [mapJobs, setMapJobs] = useState<IJobInfo[]>([])
    const user = useSelector((state: AppState) => state.user)
    const isAdminUser = user.response?.type === ADMIN_USER
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user.response?.type)

    let company: any = useSelector((state: AppState) => state.company);
    // const [companyId, setCompanyId] = useState<any>('');



    // useEffect(() => {
    //     getData()
    // }, [dispatch, t])



    return (
        <>
            <Grid
                container
                spacing={3}
            >
                <Paper className={classes.paper}>
                    <Typography className={classes.label}>Jobs This Week</Typography>
                    <Grid container className={classes.jobContainer}>
                        <Grid item className={classes.jobItem}>
                            <span className={classes.jobText}>{jobsThisWeek}</span>
                        </Grid>

                    </Grid>
                </Paper>
            </Grid>


        </>
    )
}

export default UpcomingJobs