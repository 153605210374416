import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from "@material-ui/core";
import { GridRowParams } from "@material-ui/data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { Job } from "../../../Api/Job/Job";
import { setLoader } from "../../../Redux/Loader/actions";
import { BID_STATUS, USER_ROLES } from "../../../Types/Constants";
import CustomTable from "../../Common/CustomTable/CustomTable";
import DriverDetailsDialog from "../DriverDetailsDialog/DriverDetailsDialog";
import BiddersInfoDialog from "./BiddersInfoDialog/BiddersInfoDialog";
import { getShowBiddersColumn } from "./ShowBiddersDialog.columns";
import { IShowBidderPropsRow, IShowBiddersDialogProps } from "./ShowBiddersDialog.types";

const jobApi = new Job()

const {
    DRIVER,
    COMPANY_DRIVER
} = USER_ROLES

function ShowBiddersDialog(props: IShowBiddersDialogProps) {
    const [t] = useTranslation('ShowBiddersDialog')
    const dispatch = useDispatch()
    const [isJobBiddersInfoDialogOpen, setIsJobBiddersInfoDialogOpen] = useState(false)
    const [userToShow, setUserToShow] = useState<IUserInfo | undefined>(undefined)
    const [isDriversDetailsDialogOpen, setIsDriversDetailsDialogOpen] = useState(false)
    const [jobBidIdsToHide, setJobBidIdsToHide] = useState<any[]>([])
    const [userIdForDriverDetails, setUserIdForDriverDetails] = useState<number | undefined>(undefined)

    const { handleClose, open } = props

    const handleJobBiddersDialogClose = useCallback(
        () => {
            setIsJobBiddersInfoDialogOpen(false)
        },
        [],
    )

    const handleDriverDetailsDialogClose = useCallback(
        () => {
            setIsDriversDetailsDialogOpen(false)
        },
        [],
    )

    const onBidUpdate = useCallback(
        (status: string, jobBid: IShowBidderPropsRow) => () => {
            dispatch(setLoader(true))
            jobApi.updateBidStatus(jobBid.id, status)
                .then(() => {
                    toast.success(t('success_updating_bid_status'))
                    dispatch(setLoader(false))
                    if (status === BID_STATUS.ACCEPTED)
                        handleClose()
                    else {
                        setJobBidIdsToHide([...jobBidIdsToHide, jobBid.id])
                    }
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_updating_bid_status'))
                    dispatch(setLoader(false))
                })
        },
        [handleClose, t, dispatch, jobBidIdsToHide],
    )

    const formatCharges = (currency:string, amount:number) => currency + ' - ' + (amount.toFixed(2)).toString()

    const rows = useMemo(() => {
        const job = props.selectedJob
        if (job) {
            const jobBids = job.job_bids

            if (jobBids) {
                return jobBids.filter(jobBid => !jobBidIdsToHide.includes(jobBid.id)).map<IShowBidderPropsRow>((jobBid, i) => ({
                    id: jobBid.id,
                    companyName: jobBid.user?.company?.companyName || 'Unknown',
                    amount: jobBid.amount + job.margin_price,
                    user: jobBid.user,
                    waitTime: jobBid.waitTime,
                    waitTimeCharge: jobBid.waitTimeCharge,
                    totalFreight:formatCharges(jobBid.totalFreightCurrencyCode || '', jobBid.totalFreight || 0),
                    originCharges: formatCharges(jobBid.originChargesCurrencyCode || '', jobBid.originCharges || 0),
                    destinationCharges: formatCharges(jobBid.destinationChargesCurrencyCode || '', jobBid.destinationCharges || 0),
                    convertedDestinationCharges: (jobBid.convertedDestinationCharges?.amount || 0).toFixed(2),
                    convertedOriginCharges: (jobBid.convertedOriginCharges?.amount || 0).toFixed(2),
                    convertedTotalFreight: (jobBid.convertedTotalFreight?.amount || 0).toFixed(2)
                }))
            }
        }

        return []
    }, [props.selectedJob, jobBidIdsToHide])

    const onRowSelect = useCallback(
        (rowParams: GridRowParams) => {
            const rowData = rowParams.row as IShowBidderPropsRow
            const user: IUserInfo = rowData.user as IUserInfo

            if (rowData.user) {
                const isUserDriver = [DRIVER, COMPANY_DRIVER].includes(user.type)

                if (isUserDriver) {
                    setUserIdForDriverDetails(user.id)
                    setIsDriversDetailsDialogOpen(true)
                } else {
                    setIsJobBiddersInfoDialogOpen(true)
                    setUserToShow(user)


                }

            }
        },
        [],
    )

    useEffect(() => {
        if (rows.length === 0 && open) {
            handleClose()
        }

    }, [rows, handleClose, open])

    const columns = useMemo(() => getShowBiddersColumn(t, onBidUpdate, (props.selectedJob?.movement_type || ''), (props.selectedJob?.job_classification || '')), [t, onBidUpdate, props.selectedJob?.movement_type, props.selectedJob?.job_classification])

    return (
        <>
            <DriverDetailsDialog showDriverPhotos hideActions selectedUserId={userIdForDriverDetails} open={isDriversDetailsDialogOpen} handleClose={handleDriverDetailsDialogClose} />
            <BiddersInfoDialog userData={userToShow} open={isJobBiddersInfoDialogOpen} handleClose={handleJobBiddersDialogClose} />
            <Dialog maxWidth="xl" fullWidth open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('job_bid_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item xs={2}>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {t('current_job_reference')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                {props.selectedJob?.reference || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {t('current_posted_price')}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                {props.selectedJob?.initial_price || props.selectedJob?.posted_price}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTable
                                onRowClick={onRowSelect}
                                columns={columns}
                                rows={rows}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} variant="contained">
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ShowBiddersDialog