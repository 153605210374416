import { IJobPinProps } from "./JobPin.types";
import moveit4uLogo from '../../../Images/moveit4u-logo.png'
import shipLogo from '../../../Images/ship-logo.jpg'
import { makeStyles } from "@material-ui/core";
import { PRIMARY_COLOR, SECONDARY_COLOR  } from '../../../Styles/colors';

const K_WIDTH = 40;
const K_HEIGHT = 40;

const useStyles = makeStyles((test : any) => ({
    logo: {
        position: 'absolute',
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT / 2,
        // border: `5px solid ${test}`,
        borderRadius: K_HEIGHT,
        backgroundColor: 'white',
        textAlign: 'center',
        color: SECONDARY_COLOR,
        fontSize: 16,
        fontWeight: 'bold',
        padding: 4,
        cursor: 'pointer'
    
        // height: 30,
        // width: 30
    }
}))

function JobPin(props: IJobPinProps) {
    const classes = useStyles()
    const {isSeaFreight , status , reference} = props
    let  pin_color : any = PRIMARY_COLOR
    let statusText = status
    switch (status) {
        case 'created' :
        case 'open' :
        case 'pending' :
        case 'cloned' :
        // red
          pin_color = {'border': '5px solid #fb4b4b' }
          statusText ="Created"
          break

        case 'assigned' :
        // green
        //in progess and assigned
          pin_color = {'border': '5px solid #2cba00' }
          statusText ="Assigned"

          break

        case 'in_progress':
        // orange no bids
          pin_color = {'border': '5px solid #ffa700' }
          statusText ="In Progress"

          break
        // case 'posted':
        // // blue by user. no bids
        //       pin_color = {'border': '5px solid #003974' }
        //       statusText ="Posted"

        //       break
    
        default:
          pin_color = {"border": '5px solid blue'}

    }
    return (
        <div >
            <img alt="Moveit4u Job"  onClick={props.onClick} className={classes.logo} title={reference + " : "  + statusText} style={pin_color} src={isSeaFreight ? shipLogo : moveit4uLogo }/>
        </div>
    )
}

export default JobPin