import { GridColumns } from "@material-ui/data-grid";
import { Grid, Switch } from "@material-ui/core";
import { TFunction } from "i18next";
import { ICompanyInfo } from "../../../../../../Application/DTOs/CompanyDto/CompanyDto.type";

interface IOnSwitchCallback {
    (companyId: string | number, isChecked: boolean, type: 'sms' | 'email'): void
}



export const getCompanyManagementPageColumns = (t: TFunction, onCantactableChange: IOnSwitchCallback): GridColumns => [
    {
        field: 'companyName',
        headerName: t('company_name'),
        width: 800
    },
    {
        field: 'status',
        headerName: t('status'),
        width: 200
    },
    {
        field: 'smsContactability',
        headerName: t('is_sms_contactable'),
        width: 200,
        hide: true,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            let company: ICompanyInfo = params.row as ICompanyInfo

            return (
                <Grid 
                    container
                    alignContent="center"
                    justifyContent="center"
                >
                    <Grid item md={4}>
                        <Switch
                            checked={company.is_sms_contactable}
                            color="primary"
                            onChange={(e) => {
                                onCantactableChange(company.id, e.target.checked, 'sms')
                            }}
                        />
                    </Grid>

                </Grid>
            )
        }
    },
    {
        field: 'emailContactability',
        headerName: t('is_email_contactable'),
        width: 200,
        disableClickEventBubbling: true,
        renderCell: (params) => {
            let company: ICompanyInfo = params.row as ICompanyInfo

            return (
                <Grid 
                    container
                    alignContent="center"
                    justifyContent="center"
                >
                    <Grid item md={4}>
                        <Switch
                            checked={company.is_email_contactable}
                            color="primary"
                            onChange={(e) => {
                                onCantactableChange(company.id, e.target.checked, 'email')
                            }}
                        />
                    </Grid>

                </Grid>
            )
        }
    }
]