import { IUserInfo } from '../../../../Application/DTOs/UsersDto/UsersDto.type'

export const SET_LOGIN_RESPONSE = 'SET_LOGIN_RESPONSE'

export interface IUserState {
    response: IUserInfo | null
}

export interface ISetUserResponse {
    type: typeof SET_LOGIN_RESPONSE
    payload: IUserInfo | null
}

export type TUserActions = ISetUserResponse