import { Hidden, Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IOcrData } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { formatDateTimeDisplay, formatDateTimeDisplayDD } from '../../../../Utilities/DateTime'
import { isEmpty, isEqual } from "lodash";

export const getColumns = (t: TFunction): GridColumns => {
    return [
        {
            field: 'reference',
            headerName: 'MI4U Ref',
            width: 110,
            flex: 1,
        },
        {
            field: 'postingCompanyName',
            headerName: t('company'),
            width: 150,
            sortable: false,
            renderCell: (params) => {
                let job = params.row
                return (
                    <Typography>
                        {job.company_name}
                    </Typography>
                )

            }
        },
        {
            field: 'customer_reference',
            headerName: t(`customer_reference`),
            width: 200
        },
        {
            field: 'quantity',
            headerName: t('QTY'),
            width: 90,
            sortable: false,
            renderCell: (params) => {
                let job = params.row
                return (
                    <Typography>
                        {(isEmpty(job.container_no)) ? t(job.quantity) : '1'}
                    </Typography>
                )
            }
        },
        {
            field: 'pickup_date_time',
            headerName: 'Pick up Date',
            width: 120,
            sortable: false,
            renderCell: (params) => {
                let job = params.row
                return (
                    <Typography>
                        {formatDateTimeDisplayDD(job.pickup_date_time)}
                    </Typography>
                )
            }
        },
        {
            field: 'delivery_date_time',
            headerName: 'Delivery Date',
            width: 120,
            sortable: false,
            renderCell: (params) => {
                let job = params.row
                return (
                    <Typography>
                        {formatDateTimeDisplayDD(job.delivery_date_time)}
                    </Typography>
                )
            }
        },

    ]
}