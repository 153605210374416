import { GridColumns } from "@material-ui/data-grid";
import { Grid, Switch, FormGroup, FormControlLabel, Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import {
    STATUS, USER_ROLES
} from "..././../../../UI/src/Types/Constants";
import { IUserState } from "../../../../Redux/User/type";

interface IOnStatusChange {
    (user_id: string | number, isChecked: boolean, company_id: string | number): void
}
const { ACTIVE } = STATUS;

const {
    CUSTOMER,
    ADMIN, ADMIN_USER, SUPER_ADMIN,
    WAREHOUSE
} = USER_ROLES


export const getCustomersColumns = (t: TFunction, onStatusChange: IOnStatusChange, currentUser: IUserState): GridColumns => [
    {
        field: 'posting_company',
        headerName: t('posting_company_only'),
        width: 250,
        hide: !([ADMIN_USER, SUPER_ADMIN,].includes(currentUser.response?.type ?? '')),
        renderCell: (params) => {
            const data = params.row
            return <Typography>{data.customer_company_relationships[0]?.company.name || ''}</Typography>
        }
    },
    {
        field: 'company_name',
        headerName: t('customer_name'),
        width: 250
    },
    {
        field: 'first_name',
        headerName: t('first_name'),
        width: 250
    },
    {
        field: 'email',
        headerName: t('email_address'),
        width: 550,
        renderCell: (params) => {
            const data = params.row
            return data.user.email || ''
        }
    },
    {
        field: 'status',
        headerName: t('status'),
        width: 200,
        renderCell: (params) => {
            const data = params.row

            // let company: ICompanyInfo = params.row as ICompanyInfo
            return (
                (data && data.user) ?
                    <Grid
                        container
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item md={4}>
                            <FormGroup>
                                <FormControlLabel control={<Switch
                                    checked={data?.user?.status == ACTIVE ? true : false}
                                    color="primary"
                                    onChange={(e) => {
                                        onStatusChange(data?.user?.id, e.target.checked, data?.user?.company_id)
                                    }}
                                />} label={data?.user?.status} />
                            </FormGroup>
                        </Grid>

                    </Grid>
                    :
                    <Grid />

            )

        }
    }
]