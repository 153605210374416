import { Dialog, DialogContent, DialogTitle, Typography, FormControlLabel, Switch, FormGroup, Checkbox, DialogActions, Grid, Button, FormControl, InputLabel, MenuItem, Select, makeStyles, TextField, InputAdornment } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { COMPANY_TYPES, COUNTRIES, STATUS, TStatus, COUNTRY_INSURANCES } from "../../../Types/Constants"
import { IViewCompanyDetailsDialogProps } from "./ViewCompanyDetailsDialog.types"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { useCallback, useEffect, useState } from "react";
import { Company } from "../../../Api/Company/Company";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { ICompanyInfo, ICompanyUpdatePayload } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WarningDialog from "../WarningDialog/WarningDialog";
import { isNumeric } from "../../../Utilities/Regex";
import isEmail from 'validator/lib/isEmail';
import MapAutocomplete from "../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../BP/MapAddress";
import ChangePassword from "./ChangePassword"
import SwitchButton from '../../Common/SwitchButton/SwitchButton'



const {
    ACTIVE,
    INACTIVE
} = STATUS

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    }
}))

const { FREIGHT_FORWARED, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY, NVOCC } = COMPANY_TYPES

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const companyApi = new Company()

function ViewCompanyDetailsDialog(props: IViewCompanyDetailsDialogProps) {
    const { open, handleClose, selectedCompanyId } = props
    const [t] = useTranslation('ViewCompanyDetailsDialog')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isEditMode, setIsEditMode] = useState(false)
    const [accountName, setAccountName] = useState('')
    const [accountPhone, setAccountPhone] = useState('')
    const [accountEmail, setAccountEmail] = useState('')
    const [operationsName, setOperationsName] = useState('')
    const [operationsPhone, setOperationsPhone] = useState('')
    const [operationsEmail, setOperationsEmail] = useState('')
    const [country, setCountry] = useState(AUSTRALIA)
    const [companyType, setCompanyType] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyUen, setCompanyUen] = useState('')
    const [companyEmail, setCompanyEmail] = useState('')
    const [companyMobile, setCompanyMobile] = useState('')
    const [streetName, setStreetName] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [companyInfo, setCompanyInfo] = useState<ICompanyInfo | null>(null)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
    const [insurances, setInsurances] = useState<string[]>([])
    const [waitingTime, setWaitingTime] = useState('')
    const [waitingTimeCharge, setWaitingTimeCharge] = useState('')
    const [newPasswod, setNewPasswod] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [latitude, setLatitude] = useState<number | undefined>(undefined)
    const [longitude, setLongitude] = useState<number | undefined>(undefined)

    const [importerName, setImporterName] = useState('')
    const [importerPhone, setImporterPhone] = useState('')
    const [importerEmail, setImporterEmail] = useState('')
    const [exporterName, setExporterName] = useState('')
    const [exporterPhone, setExporterPhone] = useState('')
    const [exporterEmail, setExporterEmail] = useState('')
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [emailNotification, setEmailNotification] = useState<string[]>([])

    const [ratesAccess, setRatesAccess] = useState<boolean>(false)


    const INSURANCES = COUNTRY_INSURANCES[country] ?? []

    const handleOnWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
        },
        [],
    )

    const handleInsuranceCheck = useCallback(
        (isChecked: boolean, insurance: string) => {
            if (isChecked) {
                if (!insurances.includes(insurance)) {
                    insurances.push(insurance)
                    setInsurances([...insurances])
                }
            } else {
                setInsurances(insurances.filter(ins => ins !== insurance))
            }
        },
        [insurances],
    )

    const handlePasswordOpen = useCallback(
        () => {
            setShowChangePassword(true)
        },
        [],
    )
    const handlePasswordClose = useCallback(
        () => {
            setShowChangePassword(false)
        },
        [],
    )


    const handleChangePasswordSubmit = useCallback(
        (password: any) => {
            // dispatch(setLoader(true))
            companyApi.changepassword(selectedCompanyId, password)
                .then(() => {
                    handlePasswordClose()
                    handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.success(t('success_changing_password'))
                    // dispatch(setLoader(false))
                })
                .catch((err: any) => {
                    handlePasswordClose()
                    handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.error(t('error_changing_password'))
                    // dispatch(setLoader(false))
                })
        },
        [t, selectedCompanyId, handlePasswordClose, handleOnWarningDialogClose],
    )


    const handleOnProceedDelete = useCallback(
        () => {
            dispatch(setLoader(true))
            companyApi.delete(selectedCompanyId)
                .then(() => {
                    handleOnWarningDialogClose()
                    dispatch(setLoader(false))
                    toast.success(t('success_deleting_company'))
                })
                .catch((err: any) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_deleting_company'))
                })
        },
        [dispatch, t, selectedCompanyId, handleClose, handleOnWarningDialogClose],
    )


    const handleOnDeleteClick = () => {
        setIsWarningDialogOpen(true)
    }

    const handleOnUpdateClick = () => {
        if (country === '') {
            toast.warning(t('country_cannot_be_empty'))
            return
        }

        if (companyType === '') {
            toast.warning(t('company_type_not_empty'))
            return
        }

        if (companyName === '') {
            toast.warning(t('company_name_not_empty'))
            return
        }

        if (country === PHILIPPINES) {
            if (companyUen === '') {
                toast.warning(t('tin_not_empty'))
                return
            } else if (!isNumeric(companyUen)) {
                toast.warning(t('invalid_tin'))
                return
            }

        }

        if (companyUen === '') {
            if (country === AUSTRALIA) {
                toast.warning(t('abn_not_empty'))
                return
            } else if (country === JAPAN) {
                toast.warning(t('company_registration_number_not_empty'))
                return
            } else {
                toast.warning(t('eun_not_empty'))
                return
            }
        }

        if (companyEmail === '') {
            toast.warning(t('email_not_empty'))
            return
        } else if (!isEmail(companyEmail)) {
            toast.warning(t('email_invalid_format'))
            return
        }

        if (companyMobile === '') {
            toast.warning(t('mobile_not_empty'))
            return
        } else if (!isNumeric(companyMobile)) {
            toast.warning(t('mobile_not_number'))
            return
        }

        // if (password === '' || passwordConfirm === '') {
        //     toast.warning(t('password_not_null'))
        //     return
        // } else if (password.length < 6) {
        //     toast.warning(t('invalid_password_length'))
        //     return
        // } else if (password !== passwordConfirm) {
        //     toast.warning(t('password_did_not_match'))
        //     return
        // }

        // if (streetAndName === '') {
        //     toast.warning(t('street_name_not_empty'))
        //     return
        // }

        // if (suburbOrCity === '') {
        //     toast.warning(t('suburb_city_not_empty'))
        //     return
        // }

        if (postalCode === '') {
            toast.warning(t('postcode_not_empty'))
            return
        }

        if (accountName === '') {
            toast.warning(t('account_name_not_empty'))
            return
        }

        if (accountPhone === '') {
            toast.warning(t('account_phone_not_empty'))
            return
        } else if (!isNumeric(accountPhone)) {
            toast.warning(t('account_phone_invalid'))
            return
        }

        if (accountEmail === '') {
            toast.warning(t('account_email_not_empty'))
            return
        } else if (!isEmail(accountEmail)) {
            toast.warning(t('account_invalid_email'))
            return
        }


        if (insurances.length === 0) {
            toast.warning(t('select_coverage'))
            return
        }

        if (operationsName === '') {
            toast.warning(t('operation_name_not_empty'))
            return
        }

        if (operationsPhone === '') {
            toast.warning(t('operation_phone_not_empty'))
            return
        } else if (!isNumeric(operationsPhone)) {
            toast.warning(t('operation_phone_invalid'))
            return
        }

        if (operationsEmail === '') {
            toast.warning(t('operation_email_not_empty'))
            return
        } else if (!isEmail(operationsEmail)) {
            toast.warning(t('operation_invalid_email'))
            return
        }

        // if (companyType === TRANSPORT_COMPANY && (waitingTime !== '' || waitingTimeCharge !== '')) {
        //     if (!isNumeric(waitingTime)) {
        //         toast.warning(t('waiting_time_invalid'))
        //         return
        //     } else if (!isNumeric(waitingTimeCharge)) {
        //         toast.warning(t('waiting_time_charge_invalid'))
        //         return
        //     }
        // }

        if (newPasswod !== '' || confirmPassword !== '') {
            if (newPasswod !== confirmPassword) {
                toast.warning(t('password_do_not_match'))
                return
            }

            if (newPasswod.length < 6) {
                toast.warning(t('passwords_length_too_short'))
                return
            }
        }



        if (selectedCompanyId) {
            const payload: ICompanyUpdatePayload = {
                email: companyEmail,
                accountContactEmail: accountEmail,
                accountContactName: accountName,
                accountContactPhone: accountPhone,
                operationsContactEmail: operationsEmail,
                operationsContactName: operationsName,
                operationsContactPhone: operationsPhone,
                addressCity: city,
                addressStreet: streetName,
                addressPostalCode: postalCode,
                companyName: companyName,
                companyType: companyType,
                companyUen: companyUen,
                country: country,
                mobile: companyMobile,
                insurances,
                waitTime: waitingTime,
                waitTimeCharge: waitingTimeCharge,
                exporterEmail,
                exporterName,
                exporterPhone,
                importerEmail,
                importerName,
                importerPhone,
                newPassword: newPasswod === '' ? undefined : newPasswod,
                confirmPassword: confirmPassword === '' ? undefined : confirmPassword,
                email_notifications: emailNotification,
                rates_management_access: ratesAccess,
                latitude,
                longitude,


            }
            dispatch(setLoader(true))
            companyApi.update(selectedCompanyId, payload)
                .then(() => {
                    dispatch(setLoader(false))
                    setIsEditMode(false)
                    getCompanyDetails(selectedCompanyId)
                    toast.success(t('success_updating_company_config'))

                })
                .catch((err: any) => {
                    dispatch(setLoader(false))

                    if (err.response.data.message != "Email address already exist") {
                        toast.error(t('error_updating_company_details'))
                    }
                    else {
                        toast.error(err.response.data.message)


                    }
                })
        }
    }

    const setCompanyDetails = useCallback(
        (company: ICompanyInfo) => {
            setCompanyName(company.companyName)
            setCountry(company.country)
            setCompanyType(company.companyType)
            setCompanyUen(company.companyUen)
            setCompanyEmail(company.user?.email || '')
            setCompanyMobile(company.mobile)
            setStreetName(company.addressStreet)
            setCity(company.addressCity)
            setPostalCode(company.addressPostalCode)
            setAccountName(company.accountContactName)
            setAccountPhone(company.accountContactPhone)
            setAccountEmail(company.accountContactEmail)
            setOperationsName(company.operationsContactName)
            setOperationsEmail(company.operationsContactEmail)
            setOperationsPhone(company.operationsContactPhone)
            setInsurances(company.insurances)
            setWaitingTime(company?.waitTime || '')
            setWaitingTimeCharge(company?.waitTimeCharge || '')
            setImporterEmail(company.importerEmail ?? '')
            setImporterName(company.importerName ?? '')
            setImporterPhone(company.importerPhone ?? '')
            setExporterEmail(company.exporterEmail ?? '')
            setExporterName(company.exporterName ?? '')
            setExporterPhone(company.exporterPhone ?? '')
            setLatitude(company.latitude)
            setLongitude(company.longitude)
            setRatesAccess(company.rates_management_access)
        },
        [],
    )

    const getCompanyDetails = useCallback(
        (companyId: string | number) => {
            dispatch(setLoader(true))
            companyApi.getCompanyDetails(companyId)
                .then(response => {
                    let companyDetails: ICompanyInfo = response.data

                    dispatch(setLoader(false))
                    setEmailNotification(Array.isArray(companyDetails.email_notifications) ? companyDetails.email_notifications : [])
                    setCompanyDetails(companyDetails)
                    setCompanyInfo(companyDetails)

                })
                .catch((err: any) => {
                    handleClose()
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_getting_company_detail'))
                })
        },
        [dispatch, t, setCompanyDetails, handleClose]
    )

    useEffect(() => {
        if (selectedCompanyId) {
            getCompanyDetails(selectedCompanyId)
        }
    }, [selectedCompanyId, getCompanyDetails])

    const handleOnEditClick = () => {
        setIsEditMode(true)
    }

    const handleOnCancelClick = () => {
        setIsEditMode(false)
        if (companyInfo)
            setCompanyDetails(companyInfo)
    }
    const handleEmailNotificationChange = (key: any) => {
        let templist: any[] = emailNotification

        templist.indexOf(key) === -1 ? templist.push(key) : templist.splice(templist.indexOf(key), 1);
        setEmailNotification(templist)
    }
    const handleRatesAccess = () => {
        setRatesAccess(!ratesAccess)
    }




    const emailIsSelected = (key: any) => {
        const data = emailNotification ? emailNotification : []
        return !data.includes(key)
    }
    const handleOnUpdateStatusClick = () => {
        let status: TStatus

        if (companyInfo?.status === INACTIVE) {
            status = ACTIVE
        } else {
            status = INACTIVE
        }

        if (selectedCompanyId) {
            dispatch(setLoader(true))
            companyApi.updateStatus(selectedCompanyId, status)
                .then(() => {
                    dispatch(setLoader(false))
                    getCompanyDetails(selectedCompanyId)
                })
                .catch((err: any) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_updating_campaign_status'))
                })

        }
    }

    const getNumberLabelByCountry = (country: string): string => {
        switch (country) {
            case 'australia':
                return 'company_abn'
            case 'singapore':
                return 'company_eun'
            case 'japan':
                return 'company_registration_number'
            default:
                return 'company_tin'
        }
    }

    const handleOnSelectAddress = (mapAddress: MapAddress) => {
        setStreetName([mapAddress.streetNum, mapAddress.route].join(' '))
        setCity(mapAddress.city)
        setPostalCode(mapAddress.postalCode)
        setLatitude(mapAddress.lat)
        setLongitude(mapAddress.long)
    }

    return (
        <>

            <WarningDialog onProceedClick={handleOnProceedDelete} open={isWarningDialogOpen} handleClose={handleOnWarningDialogClose} message={t('warning_about_to_delete_company')} />
            <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('company_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start"
                    >
                        <Grid md={6} item>
                            {/* <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value as any)}
                                >
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Grid>
                        <Grid md={6} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('company_type')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={companyType}
                                    onChange={(e) => setCompanyType(e.target.value as any)}
                                >
                                    <MenuItem value={NVOCC}>{t(NVOCC)}</MenuItem>
                                    <MenuItem value={FREIGHT_FORWARED}>{t(FREIGHT_FORWARED)}</MenuItem>
                                    <MenuItem value={TRANSPORT_COMPANY}>{t(TRANSPORT_COMPANY)}</MenuItem>
                                    <MenuItem value={IMPORT_EXPORTER}>{t(IMPORT_EXPORTER)}</MenuItem>
                                    <MenuItem value={SHIPPING_LINE}>{t(SHIPPING_LINE)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyUen}
                                onChange={(e) => setCompanyUen(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t(getNumberLabelByCountry(country))}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyEmail}
                                onChange={(e) => setCompanyEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={companyMobile}
                                onChange={(e) => setCompanyMobile(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('company_mobile')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        {/* {
                            isEditMode
                                ? <>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={newPasswod}
                                            onChange={(e) => setNewPasswod(e.target.value)}
                                            type="password"
                                            variant="outlined"
                                            fullWidth
                                            label={t('new_password')}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            variant="outlined"
                                            type="password"
                                            fullWidth
                                            label={t('confirm_password')}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                                : null
                        } */}
                        <Grid md={12} item>
                            <Typography className={classes.title} align="left" variant="body1">
                                {t('address_details')}
                            </Typography>
                        </Grid>
                        <Grid md={12} item>
                            <MapAutocomplete onAddressSelect={handleOnSelectAddress} />
                        </Grid>
                        <Grid md={12} item>
                            <TextField
                                disabled={!isEditMode}
                                value={streetName}
                                onChange={(e) => setStreetName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('street_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('city')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('postal_code')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <HomeIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <Typography className={classes.title} align="left" variant="body1">
                                {t('please_confirm_your_insurance_coverage')}
                            </Typography>
                        </Grid>
                        {INSURANCES.map((insurance, i) => (
                            <Grid md={(i % 2) === 0 ? 3 : 9} item>
                                <FormControlLabel
                                    control={<Checkbox disabled={!isEditMode} checked={insurances.includes(insurance)} onChange={(e) => handleInsuranceCheck(e.target.checked, insurance)} color="primary" />}
                                    label={t(insurance)}
                                />
                            </Grid>
                        ))}
                        <Grid className={classes.gap} md={12} item>
                            {/* GAP */}
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_contact_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={accountPhone}
                                onChange={(e) => setAccountPhone(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_contact_phone')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={accountEmail}
                                onChange={(e) => setAccountEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_contact_email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            {/* EMPTY */}
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={operationsName}
                                onChange={(e) => setOperationsName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_operations_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={operationsPhone}
                                onChange={(e) => setOperationsPhone(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_operations_phone')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={operationsEmail}
                                onChange={(e) => setOperationsEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('provide_account_operations_email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>



                        {
                            companyType === TRANSPORT_COMPANY
                                ? <>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={waitingTime}
                                            onChange={(e) => setWaitingTime(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('waiting_time')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={waitingTimeCharge}
                                            onChange={(e) => setWaitingTimeCharge(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('waiting_time_charge')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                </>
                                : null

                        }
                        <Grid md={6} item>
                            {/* EMPTY */}
                        </Grid>
                        {
                            [NVOCC, FREIGHT_FORWARED].includes(companyType)
                                ? <>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                    <Grid md={12} item>
                                        <Typography className={classes.title} align="left" variant="body1">
                                            {t('importer_details')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={importerName}
                                            onChange={e => setImporterName(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_importer_name')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={importerPhone}
                                            onChange={e => setImporterPhone(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_importer_phone')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DialpadIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={importerEmail}
                                            onChange={e => setImporterEmail(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_importer_email')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <AlternateEmailIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        {/* EMPTY */}
                                    </Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                    <Grid md={12} item>
                                        <Typography className={classes.title} align="left" variant="body1">
                                            {t('exporter_details')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={exporterName}
                                            onChange={e => setExporterName(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_exporter_name')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={exporterPhone}
                                            onChange={e => setExporterPhone(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_exporter_phone')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <DialpadIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={exporterEmail}
                                            onChange={e => setExporterEmail(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('provide_exporter_email')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <AlternateEmailIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        {/* EMPTY */}
                                    </Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                </>
                                : null
                        }
                        <Grid lg={12} item>

                            <Grid lg={12} container>
                                <Grid lg={12} item>
                                    <Typography
                                        className={classes.title}
                                        align="left"
                                        variant="body1"
                                    >
                                        Notification Settings
                                    </Typography>
                                </Grid>
                                <SwitchButton
                                    readOnly={!isEditMode}
                                    label={'Job Completion'}
                                    keyValue={'job_completion'}
                                    selected={emailIsSelected('job_completion')}
                                    onChange={handleEmailNotificationChange}
                                />
                                <SwitchButton
                                    readOnly={!isEditMode}
                                    label={'Scheduled Delivery'}
                                    keyValue={'scheduled_delivery'}
                                    selected={emailIsSelected('scheduled_delivery')}
                                    onChange={handleEmailNotificationChange}
                                />
                            </Grid>
                        </Grid>

                        <Grid lg={12} item>

                            <Grid lg={12} container>
                                <Grid lg={12} item>
                                    <Typography
                                        className={classes.title}
                                        align="left"
                                        variant="body1"
                                    >{t('access_management')}
                                    </Typography>
                                </Grid>
                                <SwitchButton
                                    readOnly={!isEditMode}
                                    label={t('rates_management')}
                                    keyValue={'rates_management'}
                                    selected={ratesAccess}
                                    onChange={handleRatesAccess}
                                />
                            </Grid>
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item md={3}>
                            {
                                !isEditMode
                                    ? <Grid item md={6}>
                                        <Button onClick={() => handleOnDeleteClick()} startIcon={<DeleteForeverIcon />} fullWidth className={classes.deletebtn} variant="contained">
                                            {t('delete')}
                                        </Button>
                                    </Grid>
                                    : null
                            }
                        </Grid>

                        <Grid item md={9}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >
                                <Grid item md={3}>
                                    <Button onClick={() => handlePasswordOpen()} startIcon={<ClearIcon />} variant="contained" fullWidth>Change Password</Button>
                                </Grid>
                                {
                                    !isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnUpdateStatusClick()} className={classes.updatebtn} startIcon={companyInfo?.status === INACTIVE ? <CheckIcon /> : <ClearIcon />} variant="contained" fullWidth>{t(companyInfo?.status === INACTIVE ? 'mark_as_active' : 'mark_as_inactive')}</Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    !isEditMode
                                        ? <Grid item md={3}>
                                            <Button startIcon={<EditIcon />} fullWidth onClick={() => handleOnEditClick()} className={classes.editBtn} variant="contained">
                                                {t('edit')}
                                            </Button>
                                        </Grid>
                                        : null
                                }
                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnUpdateClick()} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                {t('update')}
                                            </Button>
                                        </Grid>
                                        : null
                                }

                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnCancelClick()} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>



                    </Grid>
                </DialogActions>
                <ChangePassword
                    open={showChangePassword}
                    handleClose={handlePasswordClose}
                    onSubmit={handleChangePasswordSubmit}
                />
            </Dialog>
        </>

    )
}


export default ViewCompanyDetailsDialog