import React, { useState, useEffect } from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import './popup.css';

const useStyles = makeStyles((theme) => ({
    MuiSelect: {
        backgroundColor: 'transparent',
    },
    transparentInput: {
        backgroundColor: 'transparent',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
        },
    },
    dateField: {
        width: '100%',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
            border: `1px solid ${theme.palette.primary.main}`,
            padding: '10px',
        },
        '&:focus-within input': {
            borderColor: theme.palette.primary.main,
        },
    },
    timeField: {
        width: '150px',
        '& input': {
            backgroundColor: 'white',
            borderRadius: '4px',
        },
        '& label': {
            color: '#333',
        },
    },
    customInput: {
        width: '100%',
        height: '2rem',
        '& input': {
            padding: '10px',
        },
    },
}));

const CustomDateTimePicker = ({
    date_value,
    onDateChange,
    readOnlyBidder,
    minimumDate,
}) => {
    const classes = useStyles();
    const [date, setDate] = useState<string>('');
    const [time, setTime] = useState<string>('12:00'); // Default time

    useEffect(() => {
        if (date_value) {
            const parsedDate = new Date(date_value);
            const formattedDate = parsedDate.toISOString().split('T')[0]; // Format to YYYY-MM-DD
            setDate(formattedDate);
            const hours = parsedDate.getUTCHours().toString().padStart(2, '0');
            const minutes = parsedDate.getUTCMinutes().toString().padStart(2, '0');
            setTime(`${hours}:${minutes}`);
        }
    }, [date_value]);

    const handleDateChange = (newDate: Date | null) => {
        if (newDate) {
            const formattedDate = newDate.toISOString().split('T')[0];
            setDate(formattedDate);
        } else {
            setDate('');
        }
        mergeDateTime(newDate, time);
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = event.target.value;
        setTime(newTime);
        mergeDateTime(date ? new Date(date) : null, newTime);
    };

    const mergeDateTime = (newDate: Date | null, newTime: string) => {
        if (newDate && newTime) {
            const [hours, minutes] = newTime.split(':').map(Number);
            // Set hours and minutes
            newDate.setUTCHours(hours, minutes, 0, 0);
            onDateChange(newDate.toISOString()); // Pass ISO string to parent component
        }
    };

    return (
        <Grid item container spacing={2} direction="row">
            <Grid item xs={6} sm={6} md={4}>
                <DatePicker
                    selected={date ? new Date(date) : null}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/MM/yyyy"
                    readOnly={readOnlyBidder}
                    minDate={minimumDate ? moment(minimumDate).toDate() : undefined}
                    customInput={
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Date"
                            InputLabelProps={{ shrink: true }}
                            className={classes.customInput}
                            InputProps={{
                                inputProps: {
                                    style: { height: '2rem' },
                                },
                                readOnly: readOnlyBidder,
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
                <TextField
                    id="time"
                    label="Time"
                    fullWidth
                    variant='outlined'
                    type="time"
                    value={time}
                    onChange={handleTimeChange}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ step: 300 }} // 5 min
                    className={classes.timeField}
                    readOnly={readOnlyBidder}
                />
            </Grid>
        </Grid>
    );
};

export default CustomDateTimePicker;
