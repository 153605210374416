import { GridColumns } from "@material-ui/data-grid"
import { TFunction } from "i18next"


export const getWaitingForApprovalColumns = (t: TFunction): GridColumns => {
    return [
        {
            field: 'firstName',
            headerName: t('first_name'),
            width: 200,
            sortable: false
        },
        {
            field: 'lastName',
            headerName: t('last_name'),
            width: 200,
            sortable: false

        },
        {
            field: 'email',
            headerName: t('email'),
            width: 300,
            sortable: false
        }
    ]
}