import { Typography } from '@material-ui/core';
import { GridColumns } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { formatDateTimeDisplay } from '../../../../Utilities/DateTime'



export const getColumns = (t: TFunction): GridColumns => {
  return [
    {
      field: 'reference',
      headerName: t('Columns:reference'),
      width: 200,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        const job = params.row
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'company',
      headerName: t('Columns:posting_company_only'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const company = jobInfo.company?.name;
        return <Typography>{company}</Typography>;
      },
    },
    {
      field: 'transport_company',
      headerName: t('Columns:transport_company_only'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const company = jobInfo.assigned_user?.company?.name;
        return <Typography>{company}</Typography>;
      },
    },
    {
      field: 'driver',
      headerName: t('Columns:driver'),
      width: 200,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const driver = jobInfo.assigned_user?.first_name != undefined ? jobInfo.assigned_user?.first_name : "" + " " + jobInfo.assigned_user?.last_name != undefined ? jobInfo.assigned_user?.last_name : "";
        return <Typography>{driver}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: t('Columns:status'),
      width: 200,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const status = jobInfo.status;
        return <Typography>{t(status)}</Typography>;
      },
    },
    {
      field: 'pickup_date_time',
      headerName: t('Columns:scheduled_pickup_date_time'),
      width: 300,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const datetime = jobInfo.pickup_date_time;
        return <Typography>{formatDateTimeDisplay(datetime)}</Typography>;
      },
    },
    {
      field: 'delivery_date_time',
      headerName: t('Columns:scheduled_deliver_date_time'),
      width: 300,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const delivery_date_time = jobInfo.delivery_date_time;
        return <Typography>{formatDateTimeDisplay(delivery_date_time)}</Typography>;
      },
    },
  ];
};
