export const SET_LOADER = 'SET_LOADER'

export interface ILoaderState {
    loading: string[]
}

export interface ISetLoaderAction {
    type: typeof SET_LOADER
    payload: boolean
}

export type TLoaderActions = ISetLoaderAction
