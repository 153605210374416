import { Accordion, AccordionSummary, makeStyles, Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IJobHistoriesProps } from "./JobHistories.types";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from "react";
import { formatDateTime } from "../../../Utilities/DateTime";
import { JOB_STATUS } from "../../../Types/Constants";
import {uniqBy} from 'lodash';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}))


const {
    PICKEDUP,
    DELIVERED,
    RETURNED
} = JOB_STATUS

function JobHistories(props: IJobHistoriesProps) {
    const [t] = useTranslation('JobHistories')
    const { job } = props
    let jobHistories = job.job_histories ?? []
    const classes = useStyles()
    const [expandedPanelId, setExpandedPanelId] = useState('')
   
    if(jobHistories.length >0){
       jobHistories = uniqBy(jobHistories , 'status')
    }
   
    const handleOnExpand = (id: any) => (_: any, isExpanded: boolean) => {
        setExpandedPanelId(isExpanded ? id : false);
    }

    const getStatusActualDate = (jobStatus: string, def: string): string | null => {
        switch (jobStatus) {
            case PICKEDUP:
                return job.actual_pick_up_time ?? job.pickup_date_time
            case DELIVERED:
                return job.actual_delivery_time ?? job.delivery_date_time
            case RETURNED:
                return job.return_date_time
            default:
                return def
        }
    }

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={2}
                direction="column"
            >
                <Grid item md>
                    <Typography>
                        {t('job_history')}
                    </Typography>
                </Grid>
                <Grid item md={12}>
                    {
                        jobHistories.map((jobHistory) => (
                            <Accordion key={jobHistory.id} expanded={jobHistory.id === expandedPanelId} onChange={handleOnExpand(jobHistory.id)}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography className={classes.heading}>{t(jobHistory.status)}</Typography>
                                    <Typography className={classes.secondaryHeading}>{formatDateTime(getStatusActualDate(jobHistory.status, jobHistory.createdAt))}</Typography>
                                </AccordionSummary>
                                {/* <AccordionDetails>
                                    <Typography>
                                        Details
                                    </Typography>
                                </AccordionDetails> */}
                            </Accordion>
                        ))
                    }
                </Grid>
            </Grid>

        </div>
    )
}

export default JobHistories