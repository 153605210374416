import { Grid, Typography, Divider, Button, TextField, makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Warehouse } from '../../../../Api/Warehouse/Warehouse'
import ContainerUnpackDialog from '../../../Dialogs/ContainerUnpackDialog/ContainerUnpackDialog'
import { AppState } from '../../../../Redux'

import { setLoader } from '../../../../Redux/Loader/actions'

import { getColumns } from './Onsite.columns'
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import LazyTable from '../../../Common/LazyTable/LazyTable';
import { USER_ROLES } from '../../../../Types/Constants';
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'

const warehouseApi = new Warehouse()
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    },
    container: {
        padding: '20px'
    }
}))

function OnSitePage() {
    const [t] = useTranslation(['Warehouse', 'Columns'])
    const [filterState, setFilterState] = useState<any>({
        report_type: 'test'
    })
    const [retainCurrent, setRetainCurrent] = useState(false);

    const dispatch = useDispatch()


    const user = useSelector((state: AppState) => state.user.response);
    const { ADMIN_USER, SUPER_ADMIN, WAREHOUSE, CUSTOMER } = USER_ROLES;
    const isCustomer = [CUSTOMER].includes(user?.type);

    let userId = user.company.id
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user?.type);
    const [rowData, setRowData] = useState<any[]>([])
    const [showUnpack, setShowUnpack] = useState(false)
    const [selectedContainerId, setSelectedContainerId] = useState<any>('')


    const isWarehouse = [WAREHOUSE].includes(user?.type);
    if (isCustomer) { userId = user.customer.id }
    if (isWarehouse) { userId = user.warehouse.id }


    const onGenerateOutTurn = useCallback((row_data: any) => {
        setSelectedContainerId(row_data['id'])
        setShowUnpack(true)
    }, [])
    const handleUnpackClose = useCallback((row_data: any) => {
        setShowUnpack(false)
    }, [])
    const onSendEmailNotification = useCallback((row_data: any) => {
        warehouseApi.sendEmailNotification(row_data['id'])

    }, [])
    const columns = getColumns(t, {
        onGenerateOutTurn: onGenerateOutTurn,
        sendEmailNotification: onSendEmailNotification
    })

    const lazyGet = async (offset: number, pageNumber: number, limit: number, filter_data: any) => {
        return warehouseApi.getContainerOnsite(userId, offset, limit)
            .then((response) => {
                const jobs = response.data.jobList;
                const count = response.data.jobsCount;
                return { jobs, count }
            })
            .catch((err: any) => {
                console.log('err', err);
                toast.error(t('error_getting_data'));
            });
    }

    return (
        <>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("warehouse_management")} title= {t('containers_on_site')} />

               

                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">

                        <Grid md={12} item container>
                            <Grid md={2} item container>
                            </Grid>
                            <Grid item md={8}>
                            </Grid>


                            <Grid item md={2}>
                                {/* <Button
                                    onClick={onAddClick}
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('add')}
                                </Button> */}
                            </Grid>

                        </Grid>


                        <Grid item md={12}>
                            <LazyTable
                                columns={columns}
                                lazyFunction={lazyGet}
                                lazyFilter={filterState}
                                retainCurrent={retainCurrent}
                            />

                        </Grid>

                    </Grid>
                </Grid>
                <ContainerUnpackDialog
                    open={showUnpack}
                    container_id={selectedContainerId}
                    handleClose={handleUnpackClose}
                />

            </Grid>
        </>
    )
}

export default OnSitePage