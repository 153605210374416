import axios from 'axios'
import { ICompanyRegistrationPayload, ICompanyUpdatePayload } from '../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { ICompanyFeeConfig } from '../../../../Application/DTOs/CompanyFeeSettingsDto/CompanyFeeSettingsDto.types'
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"
import { WebStorage } from "../../Utilities/WebStorage"

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()
export class Company {
    register(payload: ICompanyRegistrationPayload) {
        return axios.post(`${baseUrl}/company/register`, payload)
    }
    changepassword(companyId: any , password : any) {
        return axios.post(`${baseUrl}/company/change/password/${companyId}`, {password : password }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    update(companyId: any, payload: ICompanyUpdatePayload) {
        return axios.post(`${baseUrl}/company/update/${companyId}`, payload, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateStatus(companyId: any, status: string) {
        return axios.post(`${baseUrl}/company/update/status/${companyId}`, {status}, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    delete(companyId: any) {
        return axios.post(`${baseUrl}/company/delete`, {companyId}, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getAll(country?: string,name?:string ) {
        return axios.get(`${baseUrl}/company/get/all`, {
            params: {
                country,
                name
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getEmailAccounts(country?: string,name?:string , companyId? :any) {
        return axios.get(`${baseUrl}/company/get/contacts`, {
            params: {
                country,
                name,
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }


    getCompaniesWithJobCount(country?: string) {
        return axios.get(`${baseUrl}/company/get/jobcount`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getjobCountRef(country?: string) {
        return axios.get(`${baseUrl}/company/get/jobcountref`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getAggregatedJobs(status?: string) {
        return axios.get(`${baseUrl}/job/statistics/today`, {
            params: {
                status
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getCompaniesRelationShip(country: string) {
        return axios.get(`${baseUrl}/company/get/relationships/all`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getCompanyAssociateGroups(companyId: string | number) {
        return axios.get(`${baseUrl}/company/associate/group/get`, {
            params: {
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    saveAssociatedGroup(name: string, companyIds: any[], driverIds: any[], associateGroupId?: number) {
        return axios.post(`${baseUrl}/company/save/associatedgroup`, {name, companyIds, driverIds, associateGroupId}, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    deleteAssociatedGroup(associateGroupId: number) {
        return axios.post(`${baseUrl}/company/associate/group/delete`, {associateGroupId}, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getRelatedCompanies(companyId: string, country: string) {
        return axios.get(`${baseUrl}/company/get/related`, {
            params: {
                country,
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateCompanyRelationship(foreignCompanyId: string | number, related: boolean) {
        return axios.post(`${baseUrl}/company/relationship/update`, {foreignCompanyId, related}, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getCompanyDetails(companyId: string | number) {
        return axios.get(`${baseUrl}/company/get`, {
            params: {
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getCompanyFeeConfig(companyId: string | number) {
        return axios.get(`${baseUrl}/company/get/fee/config`, {
            params: {
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    saveCompanyFeeConfig(companyId: any, config: ICompanyFeeConfig) {
        return axios.post(`${baseUrl}/company/update/fee/${companyId}`, {companyFeeConfig: config}, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
}