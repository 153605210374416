import { ICommisionFeeConfig, ICommisionFeeSettingsActions } from "./Commision.types"


export function CommisionSettingsReducer(state: ICommisionFeeConfig, action: ICommisionFeeSettingsActions): ICommisionFeeConfig {
    switch (action.type) {
        case 'UPDATE': 
            const {movementType, jobType, amount, commissionType} = action.payload

            state[movementType][jobType] = {
                amount,
                type: commissionType
            }
        
            return Object.assign({}, state)

        case 'SET':
            state = action.payload
            return Object.assign({}, state)
    
        default:
            return state
    }

}