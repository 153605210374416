import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
// Define initial rows
const initialRows = [
    { id: 1, chargeName: 'Cartage', charge: '540', notes: 'Per FCL' },
    { id: 2, chargeName: 'Fuel', charge: '124', notes: 'Per FCL' },
    { id: 3, chargeName: 'Tolls', charge: '80', notes: 'Per FCL' },
    { id: 4, chargeName: 'Demurrage', charge: '100', notes: 'Per Hour' },
    { id: 5, chargeName: 'De-Hire', charge: '190', notes: 'Per FCL' },
    { id: 6, chargeName: 'Out of Area De-Hire', charge: '60', notes: 'If Applicable' },
    { id: 7, chargeName: 'Admin Fee', charge: '15', notes: 'Per Shipment' },
    { id: 8, chargeName: 'Underbond Fee', charge: '50', notes: 'Per FCL' },
    { id: 9, chargeName: 'Underbond Transport', charge: '575', notes: 'Per FCL' },
    { id: 10, chargeName: 'Underbond Fuel', charge: '132.50', notes: 'Per FCL If applicable' },
    { id: 11, chargeName: 'Underbond Storage', charge: '50', notes: 'Per Day' },
    { id: 12, chargeName: 'Lifts', charge: '100', notes: 'If Applicable Per Lift' },
    { id: 13, chargeName: 'Heavy Surcharge', charge: '200', notes: 'If Applicable' },
    { id: 14, chargeName: 'Tailgate', charge: '200', notes: 'If Applicable' },
    { id: 15, chargeName: 'Futile', charge: '250', notes: 'If Applicable' },
];

const useStyles = makeStyles(() => ({
    textField: {
        width: '100%', backgroundColor: 'transparent !important',
        '&:hover': {
            backgroundColor: 'transparent  !important',
        },
    },
    input: {
        backgroundColor: 'transparent  !important',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));
export default function RatesTableV2() {
    const classes = useStyles()
    const [gridRows, setGridRows] = useState(initialRows);
    const { t } = useTranslation();

    const handleGridChange = (params) => {
        const updatedRows = gridRows.map(row =>
            row.id === params.id ? { ...row, [params.field]: params.props.value } : row
        );
        setGridRows(updatedRows);
    };

    // Define columns
    const columns = [
        { field: 'chargeName', headerName: 'Charge Name', width: 200, sortable: false },
        {
            field: 'charge',
            headerName: 'Charge',
            width: 200,
            sortable: false,
            renderCell: (params) => (
                <TextField
                    variant="standard"
                    value={params.value || ''}
                    onChange={(e) => handleGridChange({ id: params.id, field: 'charge', props: { value: e.target.value } })}
                    style={{ width: '100%' }}
                    className={classes.textField}
                    InputProps={{
                        startAdornment: <span style={{ marginRight: 8 }}>AUD</span>,
                        disableUnderline: true, // Remove the underline
                    }}
                    type="number"
                />
            )
        },
        {
            field: 'notes',
            headerName: 'Notes',
            sortable: false,
            flex: 1,
            renderCell: (params) => (
                <TextField
                    variant="standard"
                    value={params.value || ''}
                    className={classes.textField}
                    onChange={(e) => handleGridChange({ id: params.id, field: 'notes', props: { value: e.target.value } })}
                    style={{ width: '100%' }}
                    InputProps={{
                        disableUnderline: true, // Remove the underline
                    }}
                />
            )
        }
    ];

    return (
        <CustomTable
            rows={gridRows}
            pageSize={15}
            columns={columns}
        />
    );
}
