import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "../../../routes"
import AssociateGroupManagement from "./AssociateGroupManagement/AssociateGroupManagement"
import CompanyAddress from "./CompanyAddress/CompanyAddress"
import CompanyFeeSettings from "./CompanyFeeSettings/CompanyFeeSettings"
import CompanyManagementPage from "./CompanyManagementPage/CompanyManagementPage"
import RelationshipManagement from "./RelationshipManagement/RelationshipManagement"
import AddressManagement from "../AdminSettings/AddressManagement/AddressManagement"
function CompanyManagement() {
    const match = useRouteMatch()



    return (
        <>
            {/* /home/company/management/relationship_management */}
            <Route path={match.path + routes.home.company_management.relationship_management}>
                <RelationshipManagement />
            </Route>

            {/* /home/company/management/associate_group_management */}
            <Route path={match.path + routes.home.company_management.associate_group_management}>
                <AssociateGroupManagement />
            </Route>

            {/* /home/company/management/company_address */}
            <Route path={match.path + routes.home.company_management.address_management}>
                <AddressManagement accessType="all" />
            </Route>

            {/* /home/company/management/manage */}
            <Route path={match.path + routes.home.company_management.company_management}>
                <CompanyManagementPage />
            </Route>


            {/* /home/company/management/company_fee_settings */}
            <Route path={match.path + routes.home.company_management.company_fee_settings}>
                <CompanyFeeSettings />
            </Route>
        </>
    )
}


export default CompanyManagement