import React, { useMemo } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs'
const transports = ['jobs', 'pending_jobs', 'all_jobs', 'completed_jobs', 'in_progress'];
const marketplace = ['marketplace', 'available_jobs', 'bids'];
const quotes = ['quotes', 'create_quote', 'upload_quote', 'active_quote', 'pending_quote', 'failed_quote', 'successful_quote'];
const users = ['users', 'add_user', 'all_users'];
const admin_reports = ['mm_stat', 'invoice_reports', 'uninvoiced_reports', 'collections_report', 'delivery_report', 'profitability_report', 'mpd_reports'];
const drivers = ['driver_management', 'driver_fee', 'for_approval'];

const accessList = {
    super_admin: ['dashboard', 'create_job', ...transports, 'map_view', ...marketplace, ...quotes, 'admin_report', ...admin_reports, 'admin_users', 'admin_settings', ...users, 'driver', ...drivers, 'company', 'customer', 'admin_settings', 'warehouse_management', 'company_mgmt', 'company_fee', 'rates_management'],
    company: ['dashboard', ...transports, 'map_view', 'marketplace', 'kpi_report', 'posted_jobs_in_progress', 'bids_to_confirm', 'admin_report', ...users, 'driver', 'my_drivers', 'relationship_mgmnt', 'associate_group', 'company', 'customer', 'warehouse_management'],
    transport_company: ['dashboard', ...transports, 'map_view', 'marketplace', 'posted_jobs_in_progress', 'available_jobs', 'my_bids', 'kpi_report', 'bids_to_confirm', 'admin_report', ...users, 'driver', 'my_drivers', 'relationship_mgmnt', 'associate_group', 'company', 'customer', 'warehouse_management'],
    warehouse: ['dashboard', 'jobs', 'map_view', 'pending_jobs', 'posted_jobs', 'in_progress', 'posted_jobs_in_progress', 'completed_jobs', 'marketplace', 'available_jobs', 'bids_to_confirm', 'my_bids', 'admin_report', 'collections_report', 'delivery_report', 'kpi_report', 'warehouse_management'],
    customer: ['dashboard', 'jobs', 'map_view', 'all_jobs', 'pending_jobs', 'posted_jobs', 'in_progress', 'posted_jobs_in_progress', 'completed_jobs', 'marketplace', 'available_jobs', 'bids_to_confirm', 'my_bids', 'admin_report', 'collections_report', 'delivery_report', 'kpi_report', 'customer'],

};

function checkAccess(userType, sideColumn) {
    return !accessList[userType.toLowerCase()]?.includes(sideColumn);
}

export const SidebarData = (userType, themeStyle) => {
    const memoizedCheckAccess = useMemo(() => {
        return (sideColumn) => checkAccess(userType, sideColumn);
    }, [userType]);

    return [
        {
            title: 'Dashboard',
            // path: '/home/dashboardv2/main', second version
            path: '/home/dashboard/main',
            icon: <AiIcons.AiFillHome />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('dashboard'),
        },
        {
            title: 'Quotes',
            path: '/quotes',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('quotes'),
            subNav: [
                {
                    title: 'Create A Quote',
                    path: '/home/quotes/create',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('create_quote')
                },
                {
                    title: 'Batch Creation',
                    path: '/home/quotes/upload',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('upload_quote')
                },

                {
                    title: 'Active Quote',
                    path: '/home/list/active',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('active_quote')
                },
                {
                    title: 'Pending Quote',
                    path: '/home/list/pending',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('pending_quote')
                },
                {
                    title: 'Failed Quote',
                    path: '/home/list/failed',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('failed_quote')
                },
                {
                    title: 'Successful Quote',
                    path: '/home/list/successful',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('successful_quote')
                },
            ]
        },
        {
            title: 'Transport Management',
            path: '/jobs',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('jobs'),
            subNav: [
                {
                    title: 'Overview',
                    path: '/home/transport/overview',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('map_view')
                },
                {
                    title: ' Add a Job',
                    path: '/home/transport/create/job',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('create_job')
                },
                {
                    title: 'Jobs to be Assigned',
                    path: '/home/transport/show/jobs/pending',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('pending_jobs')
                },

                // {
                //     title: 'Assigned Jobs in progress',
                //     path: '/home/transport/show/jobs/in_progress',
                //     icon: <IoIcons.IoIosPaper />,
                //     hide: memoizedCheckAccess('in_progress')
                // },
                {
                    title: 'All Jobs',
                    path: '/home/transport/show/jobs/all',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('all_jobs')
                },

                // {
                //     title: 'Posted Jobs in Progress',
                //     path: '/home/transport/show/jobs/my_in_progress',
                //     icon: <IoIcons.IoIosPaper />,
                //     cName: 'sub-nav',
                //     hide: memoizedCheckAccess('posted_jobs_in_progress')
                // },

                {
                    title: 'Completed Jobs',
                    path: '/home/transport/show/jobs/completed_jobs',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('completed_jobs')
                },
            ]
        },
        {
            title: 'Marketplace Management',
            path: '/marketplace',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('marketplace'),
            subNav: [
                {
                    title: 'Posted Jobs',
                    path: '/home/transport/show/jobs/posted',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    // hide: memoizedCheckAccess('posted_jobs')
                },
                {
                    title: 'Available Jobs',
                    path: '/home/transport/show/jobs/available',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('available_jobs')
                },
                {
                    title: 'Job bids',
                    path: '/home/transport/show/jobs/bids',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('bids')
                },
                {
                    title: 'Bids to Confirm',
                    path: '/home/transport/show/jobs/bids_to_confirm',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('bids_to_confirm')
                },
                {
                    title: 'My Bids',
                    path: '/home/transport/show/jobs/my_bids',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('my_bids')
                },
            ]
        },
        {
            title: 'Warehouse Management',
            path: '/warehouse_management',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            // hide: themeStyle.name == 'default',
            subNav: [
                {
                    title: 'My Warehouses',
                    path: '/home/warehouse/management/my',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Scheduled Collections',
                    path: '/home/warehouse/management/scheduled/collection',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Scheduled Deliveries',
                    path: '/home/warehouse/management/scheduled/deliveries',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'My Stocks',
                    path: '/home/warehouse/management/stocks',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Containers On-site',
                    path: '/home/warehouse/management/containers/onsite',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Stock Ledger',
                    path: '/home/warehouse/management/stock_ledger',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },

            ]
        },
        {
            title: 'Driver Management',
            path: '/driver_management',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('driver_management'),
            subNav: [


                {
                    title: 'My Drivers',
                    path: '/home/driver/management/drivers/list',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('my_drivers'),
                },
                {
                    title: 'Driver Management',
                    path: '/home/driver/management/page',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('driver_management'),
                },
                {
                    title: 'Add Drivers',
                    path: '/home/driver/management/add',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav'
                },
                {
                    title: 'Driver Fee Settings',
                    path: '/home/driver/management/fee/settings',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('driver_fee'),

                },

                {
                    title: 'Waiting for Approval',
                    path: '/home/driver/management/waiting_for_approval',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('for_approval'),

                },
            ]
        },

        {
            title: 'Company Management',
            path: '/company',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('company'),
            subNav: [
                {
                    title: 'Relationship Management',
                    path: '/home/company/management/relationship_management',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('relationship_mgmnt'),

                },
                {
                    title: 'Associate Group Management',
                    path: '/home/company/management/associate_group_management',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('associate_mgmnt'),

                },

                {
                    title: 'Company Management',
                    path: '/home/company/management/manage',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('company_mgmt'),
                },

                {
                    title: 'Shipping Addresses',
                    path: '/home/admin/settings/adress_management',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false
                },
                {
                    title: 'Company Fee Settings',
                    path: '/home/company/management/company_fee_settings',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('company_fee'),
                },
                {
                    title: 'Rates Management',
                    path: '/home/admin/settings/rates_management',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('rates_management'),
                },

            ]
        },
        {
            title: 'Customer Management',
            path: '/customer',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('customer'),
            subNav: [
                {
                    title: 'Customer Management',
                    path: '/home/customer/management/manage',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Customer Rates',
                    path: '/home/customer/management/rates',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },

            ]
        },

        {
            title: 'Optimized Transport',
            path: '/jobs',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: true,
            subNav: [
                {
                    title: 'Jobs to be Assigned',
                    path: '/home/jobs/pending',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('pending_jobs')
                },
                // {
                //     title: 'Posted Jobs',
                //     path: '/home/jobs/posted',
                //     icon: <IoIcons.IoIosPaper />,
                //     cName: 'sub-nav',
                //     hide: memoizedCheckAccess('posted_jobs')
                // },
                // {
                //     title: 'Assigned Jobs in progress',
                //     path: '/home/jobs/in_progress',
                //     icon: <IoIcons.IoIosPaper />,
                //     hide: memoizedCheckAccess('in_progress')
                // },
                {
                    title: 'All Jobs',
                    path: '/home/jobs/all',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('all_jobs')
                },
                {
                    title: 'Posted Jobs in Progress',
                    path: '/home/jobs/my_in_progress',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('posted_jobs_in_progress')
                },
                {
                    title: 'Completed Jobs',
                    path: '/home/jobs/completed_jobs',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('completed_jobs')
                },
            ]
        },


        {
            title: `${userType === 'super_admin' ? 'Admin Report' : 'Reports'}`,
            path: '/admin_report',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('admin_report'),
            subNav: [
                {
                    title: 'Market Mgmt Stats Report',
                    path: '/home/report/marketplace_mgmnt_stat_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('mm_stat')
                },
                {
                    title: 'Invoice Reports',
                    path: '/home/admin/settings/invoice_reports',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('invoice_reports')
                },
                {
                    title: 'Job Completed / Invoiced',
                    path: '/home/admin/settings/job/invoiced',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('invoice_reports')
                },
                {
                    title: 'Job Completed / Invoiced',
                    path: '/home/admin/settings/job/uninvoiced',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('uninvoiced_reports')
                },
                {
                    title: 'Overdue Collections',
                    path: '/home/report/collections',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('collections_report')
                },
                {
                    title: 'Overdue Deliveries',
                    path: '/home/report/deliveries',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('delivery_report'),

                },

                {
                    title: 'No Pod Documents',
                    path: '/home/report/no_pod_docs',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Profitability Reports',
                    path: '/home/report/profitability',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('profitability_report')
                },

                {
                    title: 'Scheduled Collection Report',
                    path: '/home/report/scheduled_collection_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Scheduled Delivery Report',
                    path: '/home/report/scheduled_delivery_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'CNTR Dehire Report',
                    path: '/home/report/cntr_dehire_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'CNTR Detention Report',
                    path: '/home/report/cntr_detention_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Completed Jobs Report',
                    path: '/home/report/completed_jobs_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Completed Jobs Mileage Report',
                    path: '/home/report/completed_jobs_mileage_report',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'MPD Performance Dashboard',
                    path: '/home/report/mpd_reports',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('mpd_reports')
                },
            ]
        },
        {
            title: `KPI Dashboard`,
            path: '/home/kpi/dashboard',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('kpi_report')
        },
        {
            title: 'User Management',
            path: '/users',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('users'),
            subNav: [
                {
                    title: 'Add User',
                    path: '/home/user/management/add',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('add_user'),

                },
                {
                    title: 'All User',
                    path: '/home/user/management/all',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('all_users'),

                },
                {
                    title: 'Admin User',
                    path: '/home/user/management/admin/all',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: memoizedCheckAccess('admin_users'),

                },
            ]
        },

        {
            title: 'Admin Settings',
            path: '/admin_settings',
            icon: <IoIcons.IoIosPaper />,
            iconClosed: <BsIcons.BsChevronDown />,
            iconOpened: <BsIcons.BsChevronUp />,
            hide: memoizedCheckAccess('admin_settings'),
            subNav: [
                {
                    title: 'Commission Settings',
                    path: '/home/admin/settings/commission',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Common Address',
                    path: '/home/admin/settings/common_address',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Margin',
                    path: '/home/admin/settings/margin',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'System Configuration',
                    path: '/home/admin/settings/country_settings',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false

                },
                {
                    title: 'Rates Management',
                    path: '/home/admin/settings/rates_management',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false
                },
                {
                    title: 'System Notifications',
                    path: '/home/admin/settings/notification_settings',
                    icon: <IoIcons.IoIosPaper />,
                    cName: 'sub-nav',
                    hide: false
                },

            ]
        },
    ];
};
