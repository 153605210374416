import { IDriverFeeConfig } from "../../../../../../Application/DTOs/DriverFeeSettingsDto/DriverFeeSettingsDto.types";
import { deepCopy } from "../../../../Utilities/Object";
import { IDriverFreeSettingsActions } from "./DriverFeeSettings.types";




export function DriverFeeSettingsReducer(state: IDriverFeeConfig, action: IDriverFreeSettingsActions) {
    switch (action.type) {
        case 'UPDATE': 
            const {movementType, jobType, amount, commissionType} = action.payload

            state[movementType][jobType] = {
                amount,
                type: commissionType
            }
        
            return deepCopy(state)

        case 'SET':
            state = action.payload
            return deepCopy(state)
    
        default:
            return state
    }

}