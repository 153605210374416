import { Dialog, DialogContent, DialogTitle, Typography, DialogActions, Grid, Button, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { COMPANY_TYPES, COUNTRIES, STATUS } from "../../../Types/Constants"
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import useQuery from "../../../hooks/useQuery";
import { ICustomerDetails } from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types";
import StocksDetails from "../../Common/StocksDetails/StocksDetails";
import { IContainerDialogProps } from "./ContainerUnpackDialog.types";
import { DataGrid } from "@material-ui/data-grid";

import { getColumns } from './ContainerUnpackDialog.columns'
import StocksDialog from "../StocksDialog/StocksDialog";
import { Warehouse } from "../../../Api/Warehouse/Warehouse";

const useStyles = makeStyles((theme) => ({
    driverRegisterBox: {
        backgroundColor: "white",
        padding: "40px 15px",
        borderRadius: "20px",
        boxShadow: "2px 3px 6px -1px rgba(0,0,0,0.5)",
        overflowY: "auto",
        height: "95vh",
    },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    }
}))

const warehouseApi = new Warehouse();


function ContainerUnpackDialog(props: IContainerDialogProps) {
    const { open, handleClose, container_id } = props
    const [t] = useTranslation('Warehouse')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [isEditMode, setIsEditMode] = useState(false)
    const stocksRef = useRef();
    const [dataSet, setDataSet] = useState([])
    const [viewStockDialog, setViewStockDialog] = useState(false);
    const [viewStockMode, setViewStockMode] = useState('ctr_add');



    const handleOnCancelClick = useCallback(() => {
        setIsEditMode(false)
    }, [])

    const columns = getColumns(t)
    const getContainerStock = () => {
        warehouseApi.getContainerStock(container_id).then((response: any) => {
            setDataSet(response.data)
        })
    }
    useEffect(() =>{
        if(open) {getContainerStock()}
    }, [open])
    const handleAddClick = useCallback(() => {
        setViewStockMode('ctr_add')
        setViewStockDialog(true)

    }, [container_id])
    const handleStockDialogclose = useCallback(
        () => {
            setViewStockDialog(false)
        },
        [],
    )

    const handleCreatedStock = useCallback((stock_data: any) => {
        warehouseApi.createContainerStock(container_id, stock_data['stock']['id'])
    }, [container_id])
    return (
        <>

            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('container_unpack')}
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container spacing={2} justifyContent="flex-end">

                        <Grid md={12} item container>
                            <Grid md={2} item container>
                            </Grid>
                            <Grid item md={8}>
                            </Grid>


                            <Grid item md={2}>
                                <Button
                                    onClick={handleAddClick}
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('add')}
                                </Button>
                            </Grid>

                        </Grid>


                        <Grid style={{ "height": '500px', width: "100%" }} item md={12}>

                            <DataGrid
                                rows={dataSet}
                                columns={columns}
                            />

                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>


                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >

                    </Grid>
                </DialogActions>
                <StocksDialog open={viewStockDialog} handleClose={handleStockDialogclose} dialogMode={viewStockMode} callback={handleCreatedStock} />

            </Dialog>
        </>

    )
}


export default ContainerUnpackDialog