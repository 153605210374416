import { ISingleAutocompleteProps } from "./SingleAutocomplete.types";
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { isEqual } from "lodash";

function SingleAutocomplete(props: ISingleAutocompleteProps) {
    const {label, placeholder, options, value, onChange, disabled} = props

    return (
        <Autocomplete
            multiple={false}
            onChange={(e, values) => {
                if(onChange) onChange(values)
            }}
            disabled={disabled}
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => isEqual(option.value, value.value)}
            value={value}
            filterSelectedOptions
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder ?? ''}
                    InputLabelProps={{
                        style: {
                            fontWeight: 'bold'
                        }
                    }}
                />
            )}
        />
    )
}

export default SingleAutocomplete