import { Grid, makeStyles, styled, Paper, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

import { DatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next'
import { Job } from '../../../Api/Job/Job'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { JOB_STATUS, USER_ROLES } from '../../../Types/Constants'
import { toast } from 'react-toastify'
import { IJobInfo } from '../../../../../Application/DTOs/JobsDto/JobsDto.types'
import { AppState } from '../../../Redux'
import HorizontalBarChart from './GraphsRebuild/HorizontalBarChart'
import GoogleMaps from './GraphsRebuild/GoogleMaps'
import DoughnutChart from './GraphsRebuild/Doughnut'
import { Customer } from '../../../Api/Customer/Customer'
import SectionHeader from '../../Common/SectionHeader/SectionHeader'
import PercentCard from './GraphsRebuild/PercentCard'
import MultiAxisLineChart from './GraphsRebuild/LineChart'
import LazyTable from '../../Common/LazyTable/LazyTable'
import { getColumns } from './GraphsRebuild/columns'
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { GridRowParams } from '@material-ui/data-grid'
const BOX_HEIGHT = 45
const labelSize = '0.8rem'
const useStyles = makeStyles(() => ({
    MuiSelect: {
        backgroundColor: 'transparent',
        fontSize: labelSize,
    },
    mapBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
        borderRadius: '15px'
    },
    pies: {
        borderRadius: '13px',
        backgroundColor: 'white',

    },
    formControl: {
        minWidth: '100%',
    },
    selectLabel: {
        fontWeight: 'bold',
        backgroundColor: 'transparent !important',
        fontSize: labelSize,
    },
    graphBox: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
    },
    inbox: {
        display: 'flex',
        flexDirection: 'row'
    },
    transparentInput: {
        backgroundColor: 'transparent',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
            fontSize: labelSize,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: 'transparent',
            fontSize: labelSize,
        }
    },
    select: {
        '& .MuiSelect-root': {
            backgroundColor: '#F0ECEC',
            fontSize: labelSize,
        },
        // '& .MuiInputBase-root': {
        //     backgroundColor: 'transparent',
        // },
        // '& .MuiSelect-select': {
        //     backgroundColor: 'transparent',
        // },
    },
    jobGrid: {
        width: '100%',
        height: `${BOX_HEIGHT}vh`,
        // padding: '10px !important',
        borderRadius: '13px !important',
        backgroundColor: 'white',
        // overflowY: 'auto',
        /* WebKit-based browsers */
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
        },
        /* Firefox */
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1',
    },
    label: {
        margin: '0px',
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: ' rgb(108, 115, 127)'
    },
    count: {
        fontWeight: 'bold',
        margin: '30px 0',
        fontSize: '2rem',
        lineHeight: '1.2'
    },
    breadCrumbs: {
        float: 'right'
    },
    active: {
        fontWeight: 'bold'
    }

}))

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    color: theme.palette.text.secondary,
}));

const {
    COMPLETED,
    POSTED,
    IN_PROGRESS,
    CREATED
} = JOB_STATUS

const {
    ADMIN_USER,
    SUPER_ADMIN,
    WAREHOUSE, CUSTOMER
} = USER_ROLES

const jobApi = new Job()
const customerApi = new Customer()
interface ProgressData {
    number: number
    total: number
}

interface filtersData {
    company_id: string | number;
    customer_id: string | number;
    start_date?: string;
    end_date?: string;
}
type PalletResponse = {
    topDeliveryContainer: any;
    topDeliveryFullTruckLoad: any;
    topLooseCargoDelivery: any;
    topLooseCargoPickUp: any;
    topPickupContainer: any;
    topPickupFullTruckLoad: any;
};

function Dashboard() {
    const [t] = useTranslation(['Columns'])
    const dispatch = useDispatch()
    const classes = useStyles()
    const [mapJobs, setMapJobs] = useState<IJobInfo[]>([])
    const user = useSelector((state: AppState) => state.user.response);
    const [filterState, setFilterState] = useState<filtersData>({
        company_id: '',
        customer_id: '',
        start_date: '',
        end_date: '',
    });
    const columns = getColumns(t)
    const history = useHistory();

    const company = useSelector((state: AppState) => state.company);

    const currentUserCompanyId: any = user?.company?.id || undefined;
    const currentuserId: any = user?.id || undefined;

    const isUserAdmin = user?.type === ADMIN_USER;
    const isSuperAdmin = user?.type === SUPER_ADMIN;


    const adminPrivilege = isSuperAdmin || isUserAdmin

    const isAdminUser = user?.type === ADMIN_USER

    // const [activeData, setActiveData] = useState<any>('monthly')

    const [filteredMapData, setFilteredMapData] = useState([]);
    const [activeDataWeek, setActiveDataWeek] = useState<any>('week')


    //filtering
    const [companyId, setCompanyId] = useState<any>('');
    const [selectedCustomer, setSelectedCustomer] = useState<any>('');
    const [customerOptions, setCustomerOptions] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<Date | string | null>(null);
    const [endDate, setEndDate] = useState<Date | string | null>(null);


    const [topLoosecargoPickUp, setTopLooseCargoPickUp] = useState<any[]>([])
    const [topFullPickUp, setTopFullPickUp] = useState<any[]>([])
    const [topContainerPickUp, setTopContainerPickUp] = useState<any[]>([])

    const [topLoosecargoDeliver, setTopLooseCargoDeliver] = useState<any[]>([])
    const [topFullDeliver, setTopFullDeliver] = useState<any[]>([])
    const [topContainerDeliver, setTopContainerDeliver] = useState<any[]>([])
    const isCustomer = [CUSTOMER].includes(user?.type);

    const hideFilters = [CUSTOMER, WAREHOUSE].includes(user?.type);

    const [palletDeliver, setPalletDeliver] = useState<{ loose_cargo: any[], full_truck_load: any[] }>({ loose_cargo: [], full_truck_load: [] })

    const [completionRateChartData, setCompletionRateChartData] = useState<ProgressData>({ number: 0, total: 0 });
    const [completionPerStatesData, setCompletionPerStatesData] = useState(null);

    const [interStateData, setInterstateDate] = useState<ProgressData>({ number: 0, total: 0 });
    const [stateCompletionTotal, setStateCompletionTotal] = useState<any>(0);
    const [totalCompletedJobs, setTotalCompletedJobs] = useState<any>(0);

    const [movementPerMonth, setMovementPerMonth] = useState<any[]>([]);


    const getGroupPallets = (
        companyId: any | number,
        startDate: string | any | null,
        endDate: string | any | null,
        customerId: number | null
    ) => {
        jobApi.getGroupedPallets(companyId, 5, 0, startDate, endDate, customerId)
            .then((response) => {
                const {
                    topDeliveryContainer,
                    topDeliveryFullTruckLoad,
                    topLooseCargoDelivery,
                    topLooseCargoPickUp,
                    topPickupContainer,
                    topPickupFullTruckLoad
                }: PalletResponse = response.data;
                setTopLooseCargoPickUp(topLooseCargoPickUp);
                setTopContainerPickUp(topPickupContainer);
                setTopFullPickUp(topPickupFullTruckLoad);

                setTopLooseCargoDeliver(topLooseCargoDelivery);
                setTopFullDeliver(topDeliveryFullTruckLoad);
                setTopContainerDeliver(topDeliveryContainer);
            })
            .catch((err: any) => {
                console.error('Error fetching grouped pallets:', err);
                // toast.error(t('error_getting_counts'));
            });
    };
    const getJobCompletion = (
        companyId: any | number,
        startDate: string | any | null,
        endDate: string | any | null,
        customerId: number | null
    ) => {
        jobApi.getJobCompletion(companyId, 5, 0, startDate, endDate, customerId)
            .then((response) => {
                const { completionrate, completionPerStates, interstateCompletion, completedPerMonth } = response.data;

                // Update state as before
                const completionPieData = {
                    total: completionrate.totalCompletedJobs,
                    number: completionrate.completedJobsOnTime,
                };
                setTotalCompletedJobs(completionrate.totalCompletedJobs);
                setCompletionRateChartData(completionPieData);
                setStateCompletionTotal(completionPerStates['totalCompletedJobs']);

                const completionPerStatesData = {
                    labels: completionPerStates.completionPerStates.map(item => item.state),
                    datasets: [
                        {
                            label: 'Job Count by State',
                            data: completionPerStates.completionPerStates.map(item => parseInt(item.jobCount, 10)),
                        }
                    ]
                };
                setCompletionPerStatesData(completionPerStatesData);

                const interstateCompletionData = {
                    total: interstateCompletion.totalCompletedJobs,
                    number: interstateCompletion.interstateJobs,
                };
                setInterstateDate(interstateCompletionData);
                setMovementPerMonth(completedPerMonth);
            })
            .catch((err: any) => {
                console.error('Error fetching grouped pallets:', err);
                // toast.error(t('error_getting_counts'));
            });
    };



    const debouncedFilter = useCallback(
        debounce(() => {
            const companyFilterId = selectedCustomer ? selectedCustomer : companyId
            getGroupPallets(companyFilterId, startDate, endDate, selectedCustomer)
            getJobCompletion(companyFilterId, startDate, endDate, selectedCustomer)
            // const lazyGet = async (offset: number, pageNumber: number, limit: number, jobFilters: any, order_by: any) => {
            setFilterState({
                company_id: companyFilterId,
                customer_id: selectedCustomer,
                start_date: startDate as unknown as string,
                end_date: endDate as unknown as string
            })
        }, 500),
        [startDate, endDate, companyId, selectedCustomer]
    );
    useEffect(() => {
        if (!adminPrivilege) {
            setCompanyId(user.company.id)

        }
        if (isCustomer) {
            setCustomerOptions([{ 'company_name': user.company.companyName as any, user: { company_id: user.company.id } }])
            setSelectedCustomer(user.company.id)
        }

        // getGroupPallets(companyId, startDate, endDate, selectedCustomer)
        // getJobCompletion(activeDataWeek)
    }, [dispatch, t, activeDataWeek])

    useEffect(() => {
        debouncedFilter();
    }, [startDate, endDate, companyId, selectedCustomer]);



    let companyListOptions = useMemo(() =>
        company.list.map((company, i) => (
            <MenuItem key={i} value={company.id}>
                {company.companyName}
            </MenuItem>
        )),
        [company.list]
    );

    useEffect(() => {
        if (companyId && !hideFilters) {
            getCustomerPerCompany(companyId)
        }
    }, [companyId])
    const getCustomerPerCompany = (search_id: any) => {
        customerApi.getAll(0, 99999, isUserAdmin, undefined, search_id, true)
            .then((response) => {
                const rows = response.data.rows;
                const count = response.data.count;
                setCustomerOptions(rows)
                return { jobs: rows, count }
            })
            .catch((err: any) => {
                toast.error(t('error_getting_customers'));
                return { jobs: [], count: 0 }

            });
    }

    const lazyGet = async (offset: number, pageNumber: number, limit: number, jobFilters: any, order_by: any) => {

        return jobApi
            .getJobCompleted(selectedCustomer ? selectedCustomer : companyId, limit, offset, startDate, endDate, selectedCustomer, order_by)
            .then((response) => {
                const jobs = response.data.rows;
                const count = response.data.count;
                setFilteredMapData(jobs)
                return { jobs, count }
            })
            .catch((err: AxiosError) => {
                const jobs: IJobInfo[] = [];
                const count = 0;
                if (err.response.status != 502) {
                    toast.error(t('error_getting_jobs'));
                }
                return { jobs, count }

            });

    }
    const reRoutes = {
        pending: '/home/transport/show/jobs/pending',
        in_progress: '/home/transport/show/jobs/in_progress',
        delivered: '/home/transport/show/jobs/in_progress',
        pickedup: '/home/transport/show/jobs/in_progress',
        assigned: '/home/transport/show/jobs/in_progress',
        fumigation: '/home/transport/show/jobs/in_progress',
        via_yard: '/home/transport/show/jobs/in_progress',
        storage: '/home/transport/show/jobs/in_progress',
        completed: '/home/transport/show/jobs/completed_jobs',
        available: '/home/transport/show/jobs/available',
        created: '/home/transport/show/jobs/pending',
    };
    const onRowClick = useCallback(
        (rowParams: GridRowParams) => {
            const job: IJobInfo = rowParams.row as IJobInfo;
            history.push(`${reRoutes[job.status] || '/home/transport/show/jobs/all'}?reference=${job.reference}`);
        },
        []
    );
    const clearFilters = () => {
        if (adminPrivilege) {
            setCompanyId('');
            setSelectedCustomer('');
        }

        setStartDate(null);
        setEndDate(null);
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction='row'
                xs={12} sm={12} md={12}
            >
                <Grid item xs={12} sm={12} md={12}>
                    <SectionHeader title={"Dashboard"} />
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    style={{ paddingBottom: '20px' }}
                >
                    <Grid item xs={3} sm={12} md={2}>
                    </Grid>

                    {!adminPrivilege && (
                        <Grid item xs={false} sm={false} md={2}></Grid>
                    )}
                    {hideFilters && (< Grid item xs={12} sm={12} md={2}> </Grid>)}
                    <Grid item xs={6} sm={6} md={2}>
                        <DatePicker
                            className={classes.MuiSelect}
                            label={t('start_date')}
                            value={startDate}
                            onChange={setStartDate}
                            inputFormat="MM/dd/yyyy"
                            disableFuture
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    helperText={null}
                                    className={classes.transparentInput}
                                />
                            )}
                        />
                    </Grid>

                    {/* End Date Picker */}
                    <Grid item xs={6} sm={6} md={2}>
                        <DatePicker
                            className={classes.MuiSelect}
                            label={t('end_date')}
                            value={endDate}
                            onChange={setEndDate}
                            inputFormat="MM/dd/yyyy"
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    helperText={null}
                                    className={classes.transparentInput}
                                />
                            )}
                        />
                    </Grid>

                    {/* Company Selection (only for admins) */}
                    {(adminPrivilege && !hideFilters) && (
                        <Grid item xs={12} sm={12} md={2}>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel className={classes.selectLabel} shrink>
                                    {t('select_company') + '*'}
                                </InputLabel>
                                <Select
                                    className={classes.MuiSelect}
                                    value={companyId}
                                    onChange={(e) => {
                                        const companyId = e.target.value;
                                        const selectedCompany = company.list.find((c) => c.id === companyId);
                                        setCompanyId(companyId);
                                    }}
                                >
                                    <MenuItem key="placeholder" value="" disabled>
                                        Select an option
                                    </MenuItem>
                                    {companyListOptions}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Customer Selection */}
                    {!hideFilters && (< Grid item xs={12} sm={12} md={2}>
                        <FormControl variant="standard" fullWidth>
                            <InputLabel className={classes.selectLabel} shrink>
                                {t('select_customer')}
                            </InputLabel>
                            <Select
                                className={classes.MuiSelect}
                                value={selectedCustomer}
                                onChange={(e) => setSelectedCustomer(e.target.value)}
                            >
                                <MenuItem key="placeholder" value="" disabled>
                                    Select an option
                                </MenuItem>
                                {customerOptions.map((customer, i) => (
                                    <MenuItem key={i} value={customer['user']['company_id']}>
                                        {customer['company_name']}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>)}
                    {/* Customer Selection */}
                    <Grid item xs={12} sm={12} md={2}>

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={clearFilters}
                        >
                            Clear Filters
                        </Button>
                    </Grid>

                </Grid>


                <Grid container spacing={1}>
                    {/* Column 1 */}
                    <Grid item xs={12} md={6}>
                        <Grid container direction="column" spacing={1}>
                            <Grid item style={{ height: '50vh' }}>
                                <GoogleMaps mapData={filteredMapData} />

                            </Grid>
                            <Grid item style={{ height: '50%' }}>
                                <Paper>
                                    <LazyTable
                                        columns={columns}
                                        theme={{ height: '550px' }}
                                        onRowClick={onRowClick}
                                        lazyFilter={filterState}
                                        lazyFunction={lazyGet}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Column 2 */}
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            {/* Percentage Cards */}
                            <Grid item container spacing={1}>
                                <Grid item xs={4} md={4}>
                                    <PercentCard label="Delivered in Full and On-time" total={completionRateChartData.total} number={completionRateChartData.number} />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <PercentCard label="Jobs Completed" showPercentage={false} total={totalCompletedJobs} number={0} />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <PercentCard label="Interstate Orders" total={interStateData.total} number={interStateData.number} />
                                </Grid>
                            </Grid>

                            {/* DoughnutChart and MultiAxisLineChart */}
                            <Grid item container spacing={1}>
                                <Grid item xs={12} md={4}>
                                    {completionPerStatesData && completionPerStatesData.datasets && (
                                        <DoughnutChart
                                            title={'Jobs Per State'}
                                            showCenterText={true}
                                            labels={completionPerStatesData.labels}
                                            data={completionPerStatesData.datasets[0].data}
                                            chartLabel={completionPerStatesData.datasets[0].label}
                                            centerText={stateCompletionTotal as string}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    {movementPerMonth && (
                                        <MultiAxisLineChart data={movementPerMonth} />
                                    )}
                                </Grid>
                            </Grid>

                            {/* Horizontal Bar Charts */}
                            <Grid item container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    {topLoosecargoPickUp && (
                                        <HorizontalBarChart
                                            color="#1FA0ED"
                                            label="Top Pick Up Locations Loose Cargo"
                                            graphData={topLoosecargoPickUp}
                                            graphData2={topFullPickUp}
                                            graphData3={topContainerPickUp}
                                            key={'hor1'}
                                        />
                                    )}
                                    {topFullPickUp && (
                                        <HorizontalBarChart
                                            color="#223A59"
                                            label="Top Pick Up Locations Full Truck Load"
                                            graphData={topFullPickUp}
                                            key={'hor2'}
                                        />
                                    )}
                                    {topContainerPickUp && (
                                        <HorizontalBarChart
                                            color="rgb(255, 196, 68)"
                                            label="Top Pick Up Locations Container"
                                            graphData={topContainerPickUp}
                                            key={'hor3'}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {topLoosecargoDeliver && (
                                        <HorizontalBarChart
                                            color="#1FA0ED"
                                            label="Top Delivery Locations Loose Cargo"
                                            graphData={topLoosecargoDeliver}
                                            key={'hor4'}
                                        />
                                    )}
                                    {topFullDeliver && (
                                        <HorizontalBarChart
                                            color="#223A59"
                                            label="Top Delivery Locations Full Truck Load"
                                            graphData={topFullDeliver}
                                            key={'hor5'}
                                        />
                                    )}
                                    {topContainerDeliver && (
                                        <HorizontalBarChart
                                            color="rgb(255, 196, 68)"
                                            label="Top Delivery Locations Container"
                                            graphData={topContainerDeliver}
                                            key={'hor6'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid >
        </>
    )
}

export default Dashboard