import React, { useRef } from 'react';
import { Grid, Paper, Typography, makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { IWeeklyCountProps } from './WeeklyCount.types';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '33%',
        alignItems: 'center',
        textAlign: 'center',
        margin: theme.spacing(2),
        borderRadius: '15px', // Adjust the border radius as needed
        boxShadow: theme.shadows[5],
    },
    label: {
        margin: '0px',
        fontSize: '1rem',
        fontWeight: 600,
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: 'rgb(108, 115, 127)'
    },
    mainLabel: {
        marginTop: '10px',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: 'rgb(108, 115, 127)'
    },
    secondary: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '2.5',
        textTransform: 'uppercase',
        fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: 'rgb(108, 115, 127)'
    },
    middleHeader: {
        alignItems: 'left',
        width: 'auto'
    },
    noData: {
        height: '15rem',
        alignContent: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'grey'
    },
    jobContainer: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    jobItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    jobText: {
        fontSize: '2rem',
        fontWeight: 'bold',
        width: '5rem', // Adjust width to fit the largest number
        textAlign: 'right', // Ensures numbers are right-aligned within the fixed width
        marginRight: theme.spacing(1),
    },
    jobLabel: {
        fontSize: '1rem', // Adjust the size as needed
    },
    totalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    percentageChange: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1rem', // Adjust the size as needed
        color: theme.palette.text.primary,
        marginLeft: 'auto',
    },
    iconUp: {
        color: 'green',
        marginLeft: theme.spacing(1),
    },
    iconDown: {
        color: 'red',
        marginLeft: theme.spacing(1),
    },
}));

const mainColor = '#044364';
const teal = '#02cc9c';

const colorPatterns = {
    yellow: [mainColor, 'rgba(255, 159, 64, 192)'],
    red: [mainColor, 'rgba(153, 102, 255)'],
    blue: [mainColor, 'rgba(255, 99, 132)'],
    teal: [mainColor, teal]
};

function WeeklyCount(props: IWeeklyCountProps) {
    const { label, pallet_jobs, container_jobs, total, percentage } = props;
    const canvasRef = useRef(null);
    const [t] = useTranslation('Dashboard');

    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <Typography className={classes.label}>{label}</Typography>
            <Grid container className={classes.jobContainer}>
                <Grid item className={classes.jobItem}>
                    <span className={classes.jobText}>{pallet_jobs}</span>
                    <span className={classes.jobLabel}>Pallets</span>
                </Grid>
                <Grid item className={classes.jobItem}>
                    <span className={classes.jobText}>{container_jobs}</span>
                    <span className={classes.jobLabel}>Containers</span>
                </Grid>
                <Grid item className={classes.totalContainer}>
                    <span className={classes.jobText}>{total}</span>
                    <span className={classes.jobLabel}>Total</span>
                    <Tooltip title={percentage > 0 ? `Higher than before` : "Lower than before"}>
                        <span className={classes.percentageChange}>
                            {(Math.round(percentage * 100) / 100).toFixed(2)}{'% '}

                            {percentage > 0 ? (
                                <ArrowUpwardIcon className={classes.iconUp} />
                            ) : (
                                <ArrowDownwardIcon className={classes.iconDown} />
                            )}
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default WeeklyCount;
