import { Grid, Switch, Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import capitalize from "capitalize";
import { TFunction } from "i18next";
import { ICompanyInfo } from "../../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { IUserInfo } from "../../../../../../Application/DTOs/UsersDto/UsersDto.type";

export const getCompanyRelationshipManagementColumns = (t: TFunction, onSwitchChange: (companyId: string | number, checked: boolean) => void): GridColumns => {
    return [
        {
            field: 'companyName',
            headerName: t('Columns:company_name'),
            width: 300
        },
        {
            field: 'toggle_relationship',
            headerName: t('toggle_relationship'),
            width: 200,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                let company: ICompanyInfo = params.row as ICompanyInfo
                let isSwitchChecked:boolean = Boolean(company.relationships?.[0]?.related)

                return (
                    <Grid 
                        container
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item md={4}>
                            <Switch
                                checked={isSwitchChecked}
                                color="primary"
                                onChange={(e) => {
                                    onSwitchChange(company.id, e.target.checked)
                                }}
                            />
                        </Grid>

                    </Grid>
                )
            }
        }
    ]
}

export const getDriverRelationshipManagementColumns = (t: TFunction, onSwitchChange: (driverId: string | number, checked: boolean) => void): GridColumns => {
    return [
        {
            field: 'driverName',
            headerName: t('Columns:driver_name'),
            width: 300,
            renderCell: (params) => {
                const user: IUserInfo = params.row as IUserInfo

                return <Typography>{capitalize.words(`${user.driver?.first_name} ${user.driver?.last_name}`)}</Typography>
            }
        },
        {
            field: 'toggle_relationship',
            headerName: t('toggle_relationship'),
            width: 200,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const user: IUserInfo = params.row as IUserInfo
                let isChecked = Boolean(user.driver?.driverCompanyRelationships?.[0]?.related)
                let driverId = ''

                if(user.driver?.id) {
                    driverId = String(user.driver.id)
                }

                return (
                    <Grid 
                        container
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item md={4}>
                            <Switch
                                checked={isChecked}
                                color="primary"
                                onChange={(e) => onSwitchChange(driverId, e.target.checked)}
                            />
                        </Grid>

                    </Grid>
                )
            }
        }
    ]
}