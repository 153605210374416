import { GridColumns } from "@material-ui/data-grid";
import { Button } from "@material-ui/core";
import { TFunction } from "i18next";
import { IColumnsCallbacks } from "./Onsite.types";
import {
    STATUS,
} from "..././../../../UI/src/Types/Constants";
import { Grid } from "react-virtualized";
interface IOnStatusChange {
    (user_id: string | number, isChecked: boolean, company_id: string | number): void
}
const { ACTIVE } = STATUS;



export const getColumns = (t: TFunction): GridColumns => [
    {
        field: 'stock',
        headerName: t('stock'),
        width: 250
    },
    {
        field: 'product_name',
        headerName: t('product_name'),
        width: 250,
    },
    {
        field: 'product_code',
        headerName: t('product_code'),
        width: 250,
    },
    {
        field: 'file',
        headerName: t('file'),
        width: 250,
    }
]