import React from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { routes } from "../../../routes"
import CreateNewJobSeaFreight from "../Jobs/CreateNewJobSeaFreight/CreateNewJobSeaFreight"
import JobReport from "../Jobs/JobReport/JobReport"
import { JOBS_WILDCARDS_KEYS } from "../Jobs/Jobs.constans"
import ShowJobs from "../Jobs/ShowJobs/ShowJobs"
import MapView from "../MapView/MapView"


function SeaFreightManagement() {
    const match = useRouteMatch()
    return (
        <React.Fragment>
            {/* /home/seafreight/job/create */}
            <Route path={match.path + routes.home.jobs.create_new_job}>
                <CreateNewJobSeaFreight type="seafreight" />
            </Route>
            {/* /home/seafreight/job_report */}
            <Route path={match.path + routes.home.jobs.job_report}>
                <JobReport type="seafreight" />
            </Route>
            {/* /home/seafreight/show/jobs/:showType */}
            <Route path={match.path + routes.home.jobs.show_jobs.root + `/:${JOBS_WILDCARDS_KEYS.SHOW_TYPE}`}>
                <ShowJobs type="seafreight"/>
            </Route>
            {/* /home/seafreight/map-view */}
            <Route path={match.path + routes.home.overview}>
                <MapView type="seafreight" />
            </Route>
        </React.Fragment>
    )
}

export default SeaFreightManagement