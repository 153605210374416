import { Button,Grid, Dialog,  DialogContent ,DialogActions,TextField ,FormControl,
    InputLabel,
    OutlinedInput,
    IconButton,
    InputAdornment,
    makeStyles
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ICommentDialogProps,ICommentDialogPropsV2 } from "./CommentDialog.types";
import { Job } from "../../../Api/Job/Job";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AppState } from "../../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES, JOB_CLASSIFICATION, MOVEMENT_TYPES } from "../../../Types/Constants";



import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Typography from '@material-ui/core/Typography';
import { render } from "react-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import { formatDateTimeDisplay } from '../../../Utilities/DateTime';

import {Chip} from '@material-ui/core';
const jobApi = new Job()


const {
    SUPER_ADMIN,
    ADMIN_USER,
    COMPANY,
    DRIVER
} = USER_ROLES

const useStyles = makeStyles(() => ({
    texts: {
        "font-size": "10px",
        "font-color" : 'gray',
        "text-align": "center",
    },
    chips: {
        width: "100%",
        position: "relative"
    },
      deleteIcon: {
        position: "absolute",
        right: 0
    },
    noData:{
        "color" : 'gray',
        "text-align": "center",
    }
}))



export default function CommentDialogV2(props: ICommentDialogPropsV2) {
    const [t] = useTranslation()
    const classes = useStyles()

    const dispatch = useDispatch()
    const { open ,jobId , type} = props
    const userType = type
    const user = useSelector((state: AppState) => state.user)
    const isAdmin = [SUPER_ADMIN, ADMIN_USER].includes(user.response?.type ?? '')
    const isUserAdmin = user.response?.type === ADMIN_USER
    const userId = user.response?.id
    const companyId = user.response?.company.id

    const [activeComment, setActiveComment] = useState<any>('')
    const [commentLists, setCommentLists] = useState([])


    useEffect(()=>{
        if(open){
            jobApi.getJobCommentList( jobId , type).then((commentResp)=>{
                setCommentLists(commentResp.data)
            })
        }
    },[open])
      const handleDelete = (comment_id : any) => {
        jobApi.deleteComment(comment_id ,{is_admin:userType , job_id :jobId}).then((commentResp)=>{
            setCommentLists(commentResp.data)
        })
      };

      
    const handleSend= () => {
        jobApi.setJobCommentV2( {
            job_id: jobId,
            comment : activeComment,
            user_id: userId,
            company_id : companyId,
            is_admin: userType
        }).then((commentResp)=>{

            setCommentLists(commentResp.data)
            setActiveComment('')
        })
    };

    return (
            <Dialog maxWidth="sm" fullWidth open={props.open} onClose={props.handleClose}>
                
                <DialogContent dividers>
              
                {
                commentLists.length > 0 ?
                <Grid style={{"overflow" :"auto" , display: "flex" , "max-height": "400px"}}>
                <Timeline position="alternate" >
                    {
                        commentLists.map((element : any) => {
                        if (element.user_id == userId){
                            console.log(element.is_admin)
                            return (<TimelineItem>
                                
                                 <TimelineSeparator>
                                        <TimelineConnector />
                                        <TimelineDot color={element.is_admin ? "secondary" :"primary"} />
                                        <TimelineConnector />
                                        </TimelineSeparator>
                                <TimelineContent>
                                    <Chip
                                        className={classes.chips}
                                        label={element.comment}
                                        onDelete={ (e) => {handleDelete(element.id)}}
                                        deleteIcon={<DeleteIcon className={classes.deleteIcon} />}
                                        // variant="outlined"
                                    />
                                    <Typography className={classes.texts}>{formatDateTimeDisplay(element.createdAt)}</Typography>
                                </TimelineContent>
                            </TimelineItem>)
                        }else{
                          return  (<TimelineItem>
                                  
                                        <TimelineOppositeContent>
                                            <Chip
                                                className={classes.chips}
                                                label={element.comment}
                                                // onDelete={ (e) => {handleDelete(element.id)}}
                                                // deleteIcon={<DeleteIcon className={classes.deleteIcon} />}
                                                // variant="outlined"
                                            />
                                    <Typography className={classes.texts}>{formatDateTimeDisplay(element.createdAt)}</Typography>

                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                        <TimelineConnector />
                                        <TimelineDot />
                                        <TimelineConnector />
                                        </TimelineSeparator>
                                            <TimelineContent></TimelineContent>    
                                    </TimelineItem>)
                        }  
                        })

                    }
                    
                </Timeline>
                </Grid>
                : <Grid  className={classes.noData}><Typography> No data</Typography>  </Grid>}
             
                </DialogContent>

                <DialogActions>
                    <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-note">Note</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-note"
                        onChange={(e) => {setActiveComment(e.target.value)}}
                        value={activeComment}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            onClick={handleSend}
                            // edge="end"
                            >
                            <SendIcon/>
                            </IconButton>
                        </InputAdornment>
                        }
                        label="Password"
                    />
                    </FormControl>
                </DialogActions>
            </Dialog>
        
    )
}
