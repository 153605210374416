import { Button, Divider, Grid, Typography, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PaginatedTable from '../../../Common/PaginatedTable/PaginatedTable';
import { getMpdReportColumns } from './MPDReport.columns';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../Redux';
import { GridRowParams } from '@material-ui/data-grid';
import { OCRlogs } from '../../../../Api/OCR/OCR';
import { Pageable } from '../../../../../../types/pageable';
import { IOcrPayload } from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { MobileDatePicker } from "@material-ui/pickers";
import OCRHistoryDialog from '../../../Dialogs/OcrHistoryDialog/OCRHistoryDialog';
import JobGeneration from "./JobGeneration";

export default function MPDReport() {
    const [t] = useTranslation('OCR');
    const [isJobGenerationOpen, setIsJobGenerationOpen] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>(null);

    const onGenerate = (rowData: any) => {
        setSelectedData(rowData);
        setIsJobGenerationOpen(true);
    };

    const handleGenerateClose = () => {

        setIsJobGenerationOpen(false);
        setSelectedData(null);
    };

    const columns = useMemo(() => getMpdReportColumns(t, 'no', { onGenerate }), [t]);

    const ocrLogsAPI = new OCRlogs();

    const INITIAL_PAGE = 0;
    const INITIAL_PAGE_SIZE = 10;
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
    const currentUser = useSelector((state: AppState) => state.user);
    const [totalCount, setTotalCount] = useState(0);
    const [isTableFetching, setIsTableFetching] = useState(false);
    const [ocrLogList, setOcrLogList] = useState<any[]>([]);
    const [dateFilter, setDateFilter] = useState<Date>(new Date());
    const [selectedDataSet, setSelectedDataSet] = useState<any>({});
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const getOcrLogs = useCallback(
        (currentPage: number, pageSize: number, dateFilterInner: any) => {
            setIsTableFetching(true);
            ocrLogsAPI.getLogs(currentPage, pageSize, dateFilterInner)
                .then((response) => {
                    const data: Pageable<IOcrPayload> = response.data;
                    setTotalCount(data.total);
                    setOcrLogList(data.rows);
                    setIsTableFetching(false);
                })
                .catch((err: any) => {
                    setIsTableFetching(false);
                    setOcrLogList([]);
                    console.log('Error fetching OCR logs:', err);
                });
        },
        [t, currentUser.response?.country]
    );

    const onRowClick = useCallback(
        (rowParams: GridRowParams) => {
            ocrLogsAPI.getHistory(rowParams.row.filename).then(async (response) => {
                const data = response.data;
                await setSelectedDataSet(data);
                setIsDialogOpen(true);
            });
        },
        []
    );

    const handleDialogClose = useCallback(
        () => {
            setIsDialogOpen(false);
            getOcrLogs(currentPage + 1, pageSize, dateFilter);

        },
        []
    );

    useEffect(() => {
        getOcrLogs(currentPage + 1, pageSize, dateFilter);
    }, [getOcrLogs, currentPage, pageSize, dateFilter]);

    return (
        <>
            {isJobGenerationOpen && selectedData && (
                <JobGeneration
                    isOpen={isJobGenerationOpen}
                    selectedJob={selectedData}
                    handleClose={handleGenerateClose}
                />
            )}

            <Grid
                direction="column"
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
            >
                <Grid item md={3}>
                    <Typography>{t('mpd_reports')}</Typography>
                </Grid>
               
                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        <Grid item md={2}>
                            <MobileDatePicker
                                renderInput={props => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        placeholder="Filter"
                                        {...props}
                                        InputLabelProps={{ style: { fontWeight: 'bold' } }}
                                    />
                                )}
                                inputFormat="dd/MM/yyyy"
                                value={dateFilter}
                                onChange={setDateFilter}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <PaginatedTable
                                columns={columns}
                                currentPage={currentPage}
                                loading={isTableFetching}
                                onPageChange={(params: any) => { setCurrentPage(params); }}
                                onPageSizeChange={(params: any) => { setPageSize(params); }}
                                pageSize={pageSize}
                                rows={ocrLogList}
                                totalCount={totalCount}
                                onRowClick={onRowClick}
                            />
                        </Grid>
                        <OCRHistoryDialog
                            open={isDialogOpen}
                            filename={selectedDataSet.filename}
                            handleClose={handleDialogClose}
                            dataSet={selectedDataSet}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
