import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid } from "@material-ui/core";
import { IRequiredDocumentProps, ISelectUploadFilesProps } from "./RequiredDocument.types";
import { useCallback, useEffect, useState } from "react";
import GenerateCheckBox from './checkbox'

// import DocumentTables from './DocumentsTable'
import { useTranslation } from "react-i18next";



function RequiredDocuments(props: IRequiredDocumentProps) {
    const { onSubmit, open, handleRequiredDocumentClose, selectedData, handleDocumentCheckBoxChange, isAdmin } = props
    const [selectedCheckBoxes, setselectedCheckBoxes] = useState<any[]>([])
    const [files, setFiles] = useState<any>([]);


    const [selectedfilesId, setSelectedFilesId] = useState<any[]>([])

    const documentOnChange = useCallback((id: any, is_required: any, creation_id: any, job_id: any) => {
        let listSelected = selectedCheckBoxes
        // const findIdx = listSelected.indexOf(id);
        const isFound = listSelected.find(x => x.id == id);
        const findIdx = listSelected.indexOf(isFound);

        if (isFound) {
            listSelected.splice(findIdx, 1);

        } else {
            listSelected.push({ id: id, is_required: is_required, creation_id: creation_id, job_id: job_id });

        }
        setselectedCheckBoxes(listSelected)
    }, [])

    const handleCancel = () => {
        // setselectedCheckBoxes([])
        if (handleRequiredDocumentClose) {
            handleRequiredDocumentClose()
        }
    }
    const handleSubmitClick = () => {

        if (onSubmit) {
            onSubmit(selectedCheckBoxes, files)
        }
    }
    useEffect(() => {
        let tempSelected: any[] = []
        selectedData.forEach((selected: any) => {
            if (selected['checked'] == true) {
                tempSelected.push({ id: selected['id'], creation_id: selected['creation_id'], is_required: selected['is_required'] })
            }
        })
        if (tempSelected.length > 0) {
            setselectedCheckBoxes(tempSelected)
        }
    }, [selectedData])


    const handleOnChangeCallBack = useCallback((data: ISelectUploadFilesProps, action?: string) => {
        let uploaded = selectedfilesId
        let itemIndex = uploaded.indexOf(data.id)
        let selectedFiles = files
        // let rawFilenames = fileNames
        if (itemIndex > -1) {
            uploaded.splice(itemIndex, 1)
            selectedFiles.splice(itemIndex, 1)
            // rawFilenames.splice(itemIndex,1)
        } else {
            if (action != 'clear') {
                uploaded.push(data.id)
                selectedFiles.push(data)
                // rawFilenames.push({id: data.id })

            }

        }
        setFiles(selectedFiles)
        setSelectedFilesId(uploaded)
    }
        , []);


    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open}  >
                <DialogTitle>
                    <Typography>
                        Set required Documents
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid>

                        {selectedData.map((item, i) => {
                            return <GenerateCheckBox key={i} onChange={handleOnChangeCallBack} checkOnChange={documentOnChange} primaryChange={handleDocumentCheckBoxChange} item={item} isAdmin={isAdmin} />
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={(e) => { handleCancel() }} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmitClick} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default RequiredDocuments