import {
    Dialog,
    makeStyles,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    Button
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CreateNewJob from '../../../Home/Jobs/CreateNewJob/CreateNewJob';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../Redux';
import { Job } from '../../../../Api/Job/Job';
import { setSelectedJob } from '../../../../Redux/Job/actions';
import { OCRlogs } from '../../../../Api/OCR/OCR';

const ocrLogsAPI = new OCRlogs();

const useStyles = makeStyles(() => ({
    cloneBtn: {
        backgroundColor: '#7FB3D5',
        '&:hover': {
            backgroundColor: '#7FB3D5',
        },
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        },
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        },
    },
}));

interface JobGenerationProps {
    isOpen: boolean;
    selectedJob: any;
    handleClose: () => void;
}

function JobGeneration({ isOpen, selectedJob, handleClose }: JobGenerationProps) {
    const [originalData, setOriginalData] = useState<any>(selectedJob)
    const dispatch = useDispatch();
    const [formattedJob, setFormattedJob] = useState<any>(null)
    const [t] = useTranslation();
    const [errList, setErrList] = useState<any>([])
    const createNewJobRef = useRef<any>(null);


    const updateOcr = (createdJob: any) => {
        ocrLogsAPI.setLogs({
            'reference': createdJob["reference"],
            'customer_reference': createdJob["customer_reference"],
            'job_id': createdJob['id'],
            'pickup_add_id': createdJob['pickup_address_id'],
            'delivery_add_id': createdJob['delivery_address_id'],
            'delivery_date_time': createdJob['delivery_date_time'],
            'pickup_date_time': createdJob['pickup_date_time'],
            'quantity': createdJob['quantity'] || 0,
            'weight': createdJob['weight'] || 0,
            "remarks": "Created Manually",
            "filename": originalData['filename'],
            "status": "created",
            "date_processed": new Date()
        })
            .then((response) => {
                handleClose();
            }).catch((err: any) => {
                handleClose();

            });

    };

    const handleCreateClick = useCallback(() => {
        console.log("ParentComponent: handleCreateClick called");
        if (createNewJobRef.current) {
            (createNewJobRef.current as any).handleUpdate((data: any) => {

                updateOcr(data);
            });
        } else {
            console.error("createNewJobRef.current is null or undefined");
        }
    }, [updateOcr]);

    const convertErrors = (errString: any) => {
        return errString.split(',').map(err => {
            if (err.includes('Pick up')) return 'err_pickup';
            if (err.includes('Delivery')) return 'err_delivery';
            if (err.includes('Packages')) return 'err_packages';
            if (err.includes('User Area')) return 'err_user_area';
            if (err.includes('customer reference')) return 'err_reference';
            return 'err_unknown'; // Fallback in case of unexpected errors
        });
    }
    useEffect(() => {
        if (isOpen && selectedJob) {
            const statusData = !['created', 'success'].includes(selectedJob.status) ? convertErrors(selectedJob.remarks) : [];
            setErrList(statusData)
            const formattedData = {
                "reference": '',
                "customer_reference": selectedJob.customer_reference,
                "company_id": selectedJob.company_id,
                "movement_type": "loose_cargo",
                "job_type": "pallets",
                "vehicle_type": "other",
                "pickup_address_id": selectedJob.pickup_add?.id || null,
                "delivery_address_id": selectedJob.delivery_add?.id || null,
                "pickup_date_time": selectedJob.pick_up_date,
                "delivery_date_time": selectedJob.delivery_date,
                "trip_type": "one_way",
                "container_no": null,
                "seal_number": null,
                "package_type": "pallets",
                "packages_type": selectedJob.packages,
                "quantity": selectedJob.quantity,
                "weight": selectedJob.weight,
                // "volume": selectedJob,
                "posted_price": 0,
                "initial_price": 0,
                "margin_price": 0,
                "reason": null,
                "instructions": '',
                "ec_release_no": null,
                "wharf_slot": null,
                "post_to": "hidden",
                "is_split_delivery": "false",
                "is_fixed_date": "true",
                "lcl_type": null,
                "job_classification": "transport",
                "temperature": "ambient",
                "associate_group_ids": [],
                "required_document_selection": [],
                "associateGroups": [],
                "cntr_last_day": new Date()
            }
            setFormattedJob(formattedData)
        }
    }, [isOpen, selectedJob]);


    return (
        <Dialog maxWidth="md" fullWidth open={isOpen} onClose={() => handleClose()}>
            <DialogTitle>
                <Typography>{t('job_details')}</Typography>
            </DialogTitle>
            <DialogContent dividers>
                <CreateNewJob
                    errList={errList}
                    ref={createNewJobRef}
                    type="transport" mode="create" selectedJob={formattedJob} xmlMode={true} />
            </DialogContent>
            <DialogActions>
                <Grid
                    spacing={2}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item md={3}>
                        <Button
                            onClick={() => { handleCreateClick() }}
                            fullWidth
                            // startIcon={<PlayArrowIcon />}
                            color="primary"
                            variant="contained"
                        >
                            {t('Create')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default JobGeneration;
