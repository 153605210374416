import { Dialog, DialogTitle, DialogContent, TextareaAutosize, makeStyles, DialogActions, Grid, Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ITextAreaInputDialogProps } from "./TextAreaInputDialog.types";

const useStyles = makeStyles(() => ({
    approve: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    reject: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    textArea: {
        width: '100%'
    }
}))


function TextAreaInputDialog(props: ITextAreaInputDialogProps) {
    const { open, handleClose, header, onOKCallback } = props
    const [t] = useTranslation('TextAreaInputDialog')
    const classes = useStyles()
    const [textValue, setTextValue] = useState('')

    const handleOkClick = useCallback(
        () => {
            if(onOKCallback) onOKCallback(textValue)

            handleClose()
        },
        [onOKCallback, handleClose,textValue],
    )

    useEffect(() => {
        setTextValue('')
        
    }, [open])

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    {header}
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item md={12}>
                            <TextareaAutosize
                                value={textValue}
                                onChange={(e) => setTextValue(e.target.value)}
                                className={classes.textArea}
                                rowsMin={8}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        spacing={2}
                        container
                        justifyContent="flex-end"
                    >
                        <Grid item md={3}>
                            <Button onClick={handleOkClick} fullWidth className={classes.approve} variant="contained">
                                {t('ok')}
                            </Button>
                        </Grid>
                        <Grid item md={3}>
                            <Button onClick={handleClose} fullWidth>
                                {t('cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default TextAreaInputDialog