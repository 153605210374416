import { Button, Grid, Typography, Tooltip, Link } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { isEqual } from "lodash";
import { IUserState } from "../../../../Redux/User/type";
import { JOB_STATUS, TRIP_TYPE, USER_ROLES, QUOTE_STATUS } from "../../../../Types/Constants";
import { AVAILABLE_JOBS, DELIEVERED_JOBS, JOBS_IN_PROGRESS } from "../../Sidebar/Sidebar.types";
import { PENDING, FAILED, ACTIVE, SUCCESSFUL } from '../Quotes.constants';

import { TShowTypes } from "../Quotes.types";
import { IColumnsCallbacks } from "./ShowQuotes.types";
import { formatDateTimeDisplay } from "../../../../Utilities/DateTime";

import moment from "moment";
import { IQuoteData } from "../../../../../../Application/DTOs/QuotesDto/QuoteDto.types";



const {
    ROUND_TRIP
} = TRIP_TYPE

const {
    DRIVER_MANAGER,
    COMPANY,
    DRIVER,
    COMPANY_DRIVER,
    ADMIN_USER,
    SUPER_ADMIN,
    CUSTOMER
} = USER_ROLES

const { GENERATED } = QUOTE_STATUS
export const getShowJobsTableColumns = (t: TFunction, showType: TShowTypes, callbacks: IColumnsCallbacks, currentUser: IUserState): GridColumns => [
    {
        field: 'reference',
        headerName: t('quote_number'),
        width: 200,
    },
    {
        field: 'postingCompanyName',
        headerName: t('posting_company_only'),
        hide: ![ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? ''),
        width: 300,
        sortable: false,
        renderCell: (params) => {
            const quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {quote.company?.companyName || params.row.company?.name}
                </Typography>
            )

        }
    },
    {
        field: 'job_type',
        headerName: t('Columns:job_type'),
        sortable: false,
        width: 200,
        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {t(quote.job_type)}
                </Typography>
            )
        }
    },
    {
        field: 'pickup_city',
        headerName: t('Columns:pick_up_suburb'),
        sortable: false,
        width: 200,
        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {quote.pick_up_address?.city}
                </Typography>
            )
        }
    },
    {
        field: 'delivery_city',
        headerName: t('Columns:delivery_suburb'),
        sortable: false,
        width: 200,
        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {quote.deliver_to_address?.city}
                </Typography>
            )
        }
    },

    {
        field: 'created_at',
        headerName: t('ShowQuotes:date_generated'),
        sortable: false,
        width: 250,

        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {formatDateTimeDisplay(quote.created_at)}
                </Typography>
            )
        }
    },

    {
        field: 'buy_rate',
        headerName: t('Columns:buy_rate'),
        width: 200,
        sortable: false,
        hide: ![SUCCESSFUL].includes(showType),
        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {quote.rates[0]?.buy_rate || 0}
                </Typography>
            )
        }
    },
    {
        field: 'sell_rate',
        headerName: t('Columns:sell_rate'),
        width: 200,
        sortable: false,
        hide: ![SUCCESSFUL].includes(showType),
        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            return (
                <Typography>
                    {quote.rates[0]?.sell_rate || 0}
                </Typography>
            )
        }
    },
    {
        field: 'cancel',
        headerName: t('Columns:cancel'),
        width: 200,
        sortable: false,
        hide: ![PENDING, ACTIVE].includes(showType),
        renderCell: (params) => <Button onClick={(event: any) => {
            callbacks.onCancelQuote(params)
            event.stopPropagation();
        }
        } variant="contained" color="primary">{t('cancel')}</Button>
    },
    {
        field: 'quote_responses',
        headerName: t('ShowQuotes:quote_responses'),
        width: 200,
        sortable: false,
        renderCell: (params) => <Button onClick={(event: any) => {
            let quote: IQuoteData = params.row as IQuoteData
            callbacks.handleEmailDialogOpen(quote)
            event.stopPropagation();
        }
        } variant="contained" color="primary">{t('quote_responses')}</Button>
    },
    {
        field: 'generate_job',
        headerName: t('ShowQuotes:generate_job'),
        width: 200,
        sortable: false,
        hide: ![SUCCESSFUL].includes(showType),
        renderCell: (params) => {
            let quote: IQuoteData = params.row as IQuoteData
            if (params.row.status == GENERATED) {
                return <Typography>{t('job_generated')}</Typography>
            } else {
                return <Button onClick={(event: any) => {

                    callbacks.handleGenerateJobOpen(quote)
                    event.stopPropagation();
                }} variant="contained" color="primary" > {t('generate_job')}</Button >
            }

        }
    },
]