import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IDateEntryDialogProps } from "./DateEntryDialog.types";
import { Job } from "../../../Api/Job/Job";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StaticDateTimePicker } from '@material-ui/pickers'
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";

const jobApi = new Job()

function DateEntryDialog(props: IDateEntryDialogProps) {
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const [date, setDate] = useState<Date | null>(new Date())

    const { selectedJob, open, type, handleClose } = props

    const handleSetClick = () => {
        if (selectedJob && date && type) {
            dispatch(setLoader(true))
            jobApi.updateActualDate(selectedJob.id, date, type)
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_updating_actual_date'))
                    handleClose()

                })
                .catch((err :any) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_updating_actual_date'))
                })
        }
    }

    useEffect(() => {
        setDate(new Date())

    }, [open])

    const determineMinDateTime = useCallback(
        () => {
            const now = new Date()
            switch (type) {
                case 'pickup':
                    return undefined

                case 'delivery':
                    return new Date(selectedJob?.actual_pick_up_time ?? now)

                case 'return':
                    return new Date(selectedJob?.actual_delivery_time ?? now)
                default:
                    return now
            }
        },
        [type, selectedJob],
    )

    const determineMaxDateTime = useCallback(
        () => {
            const now = new Date()

            switch (type) {
                case 'delivery':
                    return now
                default:
                    return undefined
            }
        },
        [type],
    )

    return (
        <>
            <Dialog maxWidth="md" open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('enter_date_and_time')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item md={12}>
                            <StaticDateTimePicker
                                renderInput={props => <TextField fullWidth variant="outlined" {...props} />}
                                value={date}
                                onChange={setDate}
                                minDateTime={determineMinDateTime()}
                                // maxDateTime={determineMaxDateTime()}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSetClick} variant="contained" color="primary">
                        {t('set')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DateEntryDialog