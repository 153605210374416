import { Grid, FormControl, InputLabel, MenuItem, Select, makeStyles, TextField, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { IStockDetails } from "./StocksDetails.types";
import { MobileDatePicker } from "@material-ui/pickers";
import { useState, useCallback, useEffect, useImperativeHandle, forwardRef, useMemo } from "react";
import { COUNTRIES, TYPES_OF_STOCKS } from "../../../Types/Constants";
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../Redux'
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { isNumeric } from "../../../Utilities/Regex";
import { Warehouse } from "../../../Api/Warehouse/Warehouse";
import CloseIcon from '@material-ui/icons/Close';
import { formatDateTime, formatDisplayOffset, formatReadOffset, formatSaveOffset } from "../../../Utilities/DateTime";

import SitesSelection from "../../Home/WarehouseManagement/Stocks/SitesSelection";
const { CRATES, PALLETS, OTHERS, CARTONS } = TYPES_OF_STOCKS;
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    },
    selectLabel: {
        fontWeight: 'bold',
    },
    closeBtn: {
        backgroundColor: '#d1d1d1',
        color: '#4d4d4d',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    }
}))



const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const warehouseApi = new Warehouse();

function StocksDetails(props: IStockDetails, ref: any) {
    const [t] = useTranslation('Warehouse')
    const classes = useStyles()
    const dispatch = useDispatch();
    const user = useSelector((state: AppState) => state.user)
    const userID = user.response?.company?.id
    const { editMode, showMode } = props


    // const [isEditMode ,setIsEditMode] = useState(false)
    const [country, setCountry] = useState(AUSTRALIA);
    const [productId, setProductId] = useState("");

    const [productName, setProductName] = useState("");
    const [productCode, setProductCode] = useState("");
    const [batchCode, setBatchCode] = useState("");

    const [qtyType, setQtyType] = useState('');
    const [quantity, setQuantity] = useState('');
    const [expiryDate, setExpiryDate] = useState<Date | undefined | null | string>(null);
    const [description, setDescription] = useState("");
    const [storingProcedure, setStoringProcedure] = useState("");



    const [selectedSite, setSelectedSite] = useState("");
    const [selectedSiteCompany, setSelectedSiteCompany] = useState("");
    const [onClearCallbackTrigger, setOnClearCallbackTrigger] = useState(0)


    const [productOptions, setProductOptions] = useState<any>([])


    const [gotSelectedProduct, setGotSelectedProduct] = useState(false);


    const handleSave = (
        callback?: any,
    ) => {
        if (productName === "") {
            toast.warning(t("product_name_required"));
            return;
        }
        if (productCode === "") {
            toast.warning(t("product_code_required"));
            return;
        }
        if (productId != '') {
            if (batchCode === "") {
                toast.warning(t("batch_code_required"));
                return;
            }
            if (qtyType === "") {
                toast.warning(t("please_select_type"));
                return;
            }

            if (quantity !== "" && !isNumeric(quantity)) {
                toast.warning(t("quantity_required"));
                return;
            }
            if (selectedSite == '') {
                toast.warning(t("site_required"));
                return;
            }
            if (qtyType == 'others' && storingProcedure == '') {
                toast.warning(t("storing_procedure_required"));
                return;
            }

        }

        const payload = {
            product_name: productName,
            product_code: productCode,
            description: description,
            batch_code: batchCode,
            qty_type: qtyType,
            quantity: quantity,
            expiry_date: expiryDate,
            product_id: productId,
            site_id: selectedSite,
            owner_id: selectedSiteCompany,
            storing_procedure: storingProcedure
        };
        dispatch(setLoader(true));
        warehouseApi.addStock(payload)
            .then((response: any) => {
                dispatch(setLoader(false));
                if (callback) callback(response.data);
                gatherProducts()
                toast.success(t("success_adding"));
            })
            .catch((err: any) => {
                dispatch(setLoader(false));
                toast.error(t(err.response?.data || "error"));
                console.log("err", err);
            });

    }
    useImperativeHandle(ref, () => ({
        handleSave: (callback: any) => {
            handleSave(callback);
        },
    }));
    const handleTypeChange = (value: string) => {
        setQtyType(value)
    }

    useEffect(() => {
        if (productId) {
            setGotSelectedProduct(true)
        }

    }, [productId])

    const gatherProducts = () => {
        warehouseApi.getProductList()
            .then((response) => {
                setProductOptions(response.data)
            })
            .catch((err: any) => {
                dispatch(setLoader(false));
                toast.error(t(err.response?.data || "error"));
                console.log("err", err);
            });
    }
    useEffect(() => {
        if (showMode) {
            gatherProducts()
        }
    }, [showMode])

    const formatProductDisplay = useCallback(
        (xdata: any) => `${xdata.product_name}`, [t]
    );
    const productOptionMenu = useMemo(
        () =>
            productOptions.map((product, i) => (
                <MenuItem key={i} value={product.id}>
                    <div style={{ 'color': 'gray' }}>{product.product_code} &nbsp;</div>  {formatProductDisplay(product)}
                </MenuItem>
            )),
        [productOptions]
    );


    const clearSelected = () => {
        setGotSelectedProduct(false)
        setProductId('')
        setProductCode('')
        setProductName('')
        setDescription('')
        setQtyType('')
        setQuantity('')
        setExpiryDate(null)
        setBatchCode('')
        setSelectedSite('')
        setStoringProcedure('')
        setOnClearCallbackTrigger(onClearCallbackTrigger + 1)
    }

    const filterCallback = useCallback((e: any, comp_id: any) => {
        setSelectedSite(e)  // address
        setSelectedSiteCompany(comp_id)
    }, [])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
        >
            <Grid xs={11} lg={11} item>
                <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel style={{ fontWeight: "bold" }}>
                        {t("select_product")}
                    </InputLabel>
                    <Select
                        className={classes.formControl}
                        value={productId}
                        onChange={(e) => {
                            clearSelected()
                            setProductId(e.target.value as string);
                            let obj = productOptions.find(o => o.id === e.target.value);
                            setProductCode(obj.product_code)
                            setProductName(obj.product_name)
                            setDescription(obj.description)
                        }}
                    >
                        {productOptionMenu}
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={1} lg={1} item>
                <Button startIcon={<CloseIcon />} onClick={() => clearSelected()} className={classes.closeBtn} variant="contained">Clear</Button>
            </Grid>
            <Grid item container spacing={2}>
                <Grid xs={6} item>
                    <TextField
                        value={productName}
                        disabled={gotSelectedProduct}
                        onChange={(e) => setProductName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("product_name") + "*"}
                        InputLabelProps={{
                            style: {
                                fontWeight: "bold",
                            },
                        }}
                    />
                </Grid>
                <Grid xs={6} item>
                    <TextField
                        value={productCode}
                        onChange={(e) => setProductCode(e.target.value)}
                        disabled={gotSelectedProduct}
                        variant="outlined"
                        fullWidth
                        label={t("product_code") + "*"}
                        InputLabelProps={{
                            style: {
                                fontWeight: "bold",
                            },
                        }}
                    />
                </Grid>
            </Grid>
            <Grid item container spacing={2}>
                <Grid xs={12} item>
                    <TextField
                        value={description}
                        disabled={gotSelectedProduct}

                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("description") + "*"}
                        InputLabelProps={{
                            style: {
                                fontWeight: "bold",
                            },
                        }}
                    />
                </Grid>


            </Grid>

            <Grid item container spacing={2}>
                <Grid xs={3} item>
                    <SitesSelection onChangeCallback={filterCallback} onClearCallback={onClearCallbackTrigger} />
                </Grid>
                <Grid xs={2} item>
                    <TextField
                        value={batchCode}
                        onChange={(e) => setBatchCode(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("batch_code") + "*"}
                        InputLabelProps={{
                            style: {
                                fontWeight: "bold",
                            },
                        }}
                    />
                </Grid>
                <Grid item md={2}  >
                    <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel className={classes.selectLabel}>
                            {t('type')}
                        </InputLabel>
                        <Select
                            // disabled={readonly}
                            name='type'
                            defaultValue={qtyType}
                            onChange={(e) => handleTypeChange(e.target.value as string)}
                        >
                            <MenuItem value={CARTONS}>{t(CARTONS)}</MenuItem>
                            <MenuItem value={PALLETS}>{t(PALLETS)}</MenuItem>
                            <MenuItem value={CRATES}>{t(CRATES)}</MenuItem>
                            <MenuItem value={OTHERS}>{t(OTHERS)}</MenuItem>

                        </Select>
                    </FormControl>
                </Grid>

                <Grid xs={2} item>
                    <TextField
                        value={quantity}
                        type="number"
                        onChange={(e) => setQuantity(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("quantity") + "*"}
                        InputLabelProps={{
                            style: {
                                fontWeight: "bold",
                            },
                        }}
                    />
                </Grid>
                <Grid xs={3} item>

                    <MobileDatePicker
                        renderInput={props => <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Expiry Date"
                            {...props}
                            InputLabelProps={{
                                style: {
                                    fontWeight: 'bold'
                                }
                            }}
                        />}
                        inputFormat='dd/MM/yyyy'
                        value={formatSaveOffset(expiryDate)}
                        onChange={(e) => { }}
                        onAccept={(e: any) => {
                            setExpiryDate(formatReadOffset(e))
                        }}
                    />

                </Grid>
            </Grid>
            {
                (qtyType == 'others')
                    ? <Grid xs={12} item>
                        <TextField
                            value={storingProcedure}
                            onChange={(e) => setStoringProcedure(e.target.value)}
                            variant="outlined"
                            fullWidth
                            label={t("storing_procedure") + "*"}
                            InputLabelProps={{
                                style: {
                                    fontWeight: "bold",
                                },
                            }}
                        />
                    </Grid> : null
            }


        </Grid>


    )
}

export default forwardRef(StocksDetails)