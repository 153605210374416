import { Grid, Typography, Divider, Button, TextField, InputAdornment } from '@material-ui/core'
import { GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { Customer } from '../../../../Api/Customer/Customer'
import { AppState } from '../../../../Redux'

import { setLoader } from '../../../../Redux/Loader/actions'
import CustomTable from '../../../Common/CustomTable/CustomTable'
import ViewCustomerDialog from '../../../Dialogs/ViewCustomerDialog/ViewCustomerDialog'
import { getCustomersColumns } from './CustomerManagementPage.columns'
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import LazyTable from '../../../Common/LazyTable/LazyTable';
import { USER_ROLES } from '../../../../Types/Constants';
const customerApi = new Customer()

function CompanyManagementPage() {
    const [t] = useTranslation(['CustomerManagementPage', 'Columns'])
    const dispatch = useDispatch()
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | number | null>(null)
    const [searchByName, setSearchByName] = useState('');
    const [retainCurrent, setRetainCurrent] = useState(false);
    const [filterState, setFilterState] = useState<any>('')
    const [viewCustomerDialog, setViewCustomerDialog] = useState(false);
    const user = useSelector((state: AppState) => state.user);

    const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER } = USER_ROLES;

    const userId = user.response.company.id
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user?.response.type);
    const onRowClick = useCallback((params: GridRowParams) => {
        setViewCustomerDialog(true)
        setSelectedCustomerId(params.row.id)
    }, []);

    const lazyGet = async (offset: number, pageNumber: number, limit: number, filter: any) => {
        return customerApi.getAll(offset, limit, isUserAdmin, searchByName == '' ? undefined : searchByName, userId as any)
            .then((response) => {
                const rows = response.data.rows;
                const count = response.data.count;
                return { jobs: rows, count }
            })
            .catch((err: any) => {
                toast.error(t('error_getting_customers'));
                return { jobs: [], count: 0 }

            });

    }


    const onStatusChange = useCallback(
        (customer_id: string | number, isChecked: boolean, company_id: string | number) => {
            let payload: any = {}

            dispatch(setLoader(true))
            customerApi.statusChange(customer_id, isChecked, company_id)
                .then(() => {
                    dispatch(setLoader(false))
                    setRetainCurrent(!retainCurrent)
                })
                .catch((err: any) => {
                    dispatch(setLoader(false))
                    console.log('err', err)
                    toast.error(t('error_updating_staus'))
                })

        },
        [lazyGet, dispatch, t],
    )

    const columns = getCustomersColumns(t, onStatusChange, user)
    useEffect(() => {
        let timeOutId = setTimeout(() => {
            setFilterState({
                reference: searchByName
            })

        }, 500)

        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchByName])

    const handleViewCustomerDialogclose = useCallback(
        () => {

            setRetainCurrent(!retainCurrent)
            setSelectedCustomerId(null)
            setViewCustomerDialog(false)
        },
        [selectedCustomerId],
    )

    return (
        <>
            <ViewCustomerDialog customerId={selectedCustomerId} handleClose={handleViewCustomerDialogclose} open={viewCustomerDialog} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid md item>
                    <Typography>
                        {t('customer_management')}
                    </Typography>
                </Grid>
               

                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">

                        <Grid item md={6}></Grid>

                        <Grid item md={4}>
                            <TextField
                                value={searchByName}
                                onChange={(e) => setSearchByName(e.target.value)}
                                // onKeyDown={(e) => handleSearch(e)}
                                variant="outlined"
                                fullWidth
                                label={t('search')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>

                        <Grid item md={12}>

                            <LazyTable
                                columns={columns}
                                onRowClick={onRowClick}
                                lazyFunction={lazyGet}
                                lazyFilter={filterState}
                                retainCurrent={retainCurrent}

                            />

                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default CompanyManagementPage