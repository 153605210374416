import { Grid, Button, TextField, InputAdornment } from '@material-ui/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import { getShowJobsTableColumns } from './ShowQuotes.columns';
import { useRouteMatch } from 'react-router-dom';
import { TShowTypes } from '../Quotes.types';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import {
  PENDING,
  PENDING_QUOTES,
  ACTIVE_QUOTES,
  QUOTES_WILDCARDS_KEYS,
} from '../Quotes.constants';
import { IShowQuotesProps } from './ShowQuotes.types';
import { QuotesApi } from '../../../../Api/Quotes/QuotesApi';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../../Redux/Loader/actions';
import { GridRowParams } from '@material-ui/data-grid';
import {
  JOB_FILES_TYPE,
  JOB_STATUS,
  POST_TO,
  TActualDateTypes,
  USER_ROLES,
  COUNTRIES,
  TRIP_TYPE
} from '../../../../Types/Constants';
import { AppState } from '../../../../Redux';
import { WebStorage } from '../../../../Utilities/WebStorage';
// import LazyTable from '../../../Common/LazyTable/LazyTable';
import PaginatedTable from '../../../Common/PaginatedTable/PaginatedTable'
import { Pageable } from '../../../../../../types/pageable'
import { IQuoteData, IQuoteInfo } from '../../../../../../Application/DTOs/QuotesDto/QuoteDto.types'
import ViewEditQuote from '../../../Dialogs/ViewEditQuote/ViewEditQuote'
import WarningDialog from '../../../Dialogs/WarningDialog/WarningDialog'
import EmailListDialog from '../../../Dialogs/EmailListDialog/EmailListDialog';
import GenerateJobDialog from '../../../Dialogs/GenerateJob/GenerateJob';

import { IAutoCompleteResponse, IAutocompleteOption } from '../../../Common/types';
import { ICompanyContact } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type';
import { emailsToOption, reponseToOption } from '../../../../Utilities/Autocomplete';

import { Job } from '../../../../Api/Job/Job';
import { FaWeight } from 'react-icons/fa';

const quotesApi = new QuotesApi();

const { PUBLIC } = POST_TO;
const { ONE_WAY, ROUND_TRIP } = TRIP_TYPE;


const { DRIVER_MANAGER, COMPANY_DRIVER, ADMIN_USER, COMPANY } = USER_ROLES;
const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;

const { POD } = JOB_FILES_TYPE;

function ShowQuotes(props: IShowQuotesProps) {
  const [t] = useTranslation(['ShowQuotes', 'Columns']);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const params: any = match.params;


  // const {} = props;
  const showType: TShowTypes | undefined = (params[QUOTES_WILDCARDS_KEYS.SHOW_TYPE] as any) || PENDING_QUOTES;
  const user = useSelector((state: AppState) => state.user);
  const [country, setCountry] = useState(AUSTRALIA);



  const storage = new WebStorage(false)

  const [dataList, setDataList] = useState<any[]>([])


  const INITIAL_PAGE = 0
  const INITIAL_PAGE_SIZE = 10
  const [isGenerating, setIsGenerating] = useState(false);
  const [cooldown, setCooldown] = useState(false);
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE)
  const [currentPage, setCurrentPage] = useState(INITIAL_PAGE)
  const [isTableFetching, setIsTableFetching] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [selectedQuote, setSelectedQuote] = useState(null)
  const [isViewQuoteOpen, setIsViewQuoteOpen] = useState(false)
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isGenerateJobOpen, setIsGenerateJobOpen] = useState(false);
  const [quoteMode, setQuoteMode] = useState('view');


  const [selectedQuoteEmails, setSelectedQuoteEmails] = useState<IAutoCompleteResponse[]>([])


  const onRowClick = useCallback(
    (rowParams: GridRowParams) => {
      const quote: IQuoteInfo = rowParams.row as IQuoteInfo;
      setSelectedQuote(quote)
      setQuoteMode('view')
      setIsViewQuoteOpen(true)
    },
    []
  );


  const handleViewQuoteClose = useCallback(
    () => {
      refreshTable()
      handleClose()
      setIsViewQuoteOpen(false)
    },
    [showType, selectedQuote],
  )

  const handleClose = () => {
    setSelectedQuote(null)
  }
  const handleWarningDialogClose = useCallback(
    () => {
      setIsWarningDialogOpen(false)
    },
    [],
  )

  const refreshTable = () => {
    getData(currentPage + 1, pageSize, '')
  }

  const handleCancelQuote = useCallback((rowParams: GridRowParams) => {
    const quote: IQuoteInfo = rowParams.row as IQuoteInfo;
    setSelectedQuote(quote)
    setIsWarningDialogOpen(true)
  }, [])


  const handleResponses = useCallback((rowParams: GridRowParams) => {
    const quote: IQuoteInfo = rowParams.row as IQuoteInfo;
    setSelectedQuote(quote)
  }, [])
  const handleOnProceedDeleteClick = () => {
    if (selectedQuote) {
      dispatch(setLoader(true))
      quotesApi.cancelQuote(selectedQuote.id)
        .then(() => {
          dispatch(setLoader(false))
          toast.success(t('quote_cancelled'))
          refreshTable()
          handleWarningDialogClose()
          handleClose()
        })
        .catch((err: any) => {
          console.log('err', err)
          dispatch(setLoader(false))
          toast.error(t('error_cancelling_quote'))
        })
    }
  }

  const handleEmailDialogOpen = useCallback(async (quote: any) => {
    await quotesApi.getQuoteResponse(quote.id).then(async (response: any) => {
      const companies: ICompanyContact[] = response.data
      const holder = await companies.map<IAutoCompleteResponse>(reponseToOption)
      await setSelectedQuote(quote)
      await setSelectedQuoteEmails(holder)
      await setIsEmailDialogOpen(true)
    })
  },
    [selectedQuote, selectedQuoteEmails],
  )

  const handleGenerateJobOpen = useCallback(async (rowParams: any) => {
    const quote: IQuoteData = rowParams as IQuoteData;
    setSelectedQuote(quote)
    setQuoteMode('generate')
    setIsViewQuoteOpen(true)
  }, []
  )




  const columns = useMemo(
    () =>
      showType
        ? getShowJobsTableColumns(
          t,
          showType,
          {
            onCancelQuote: handleCancelQuote,
            handleResponses: handleResponses,
            handleEmailDialogOpen: handleEmailDialogOpen,
            handleGenerateJobOpen: handleGenerateJobOpen
          },
          user
        )
        : [],
    [
      t,
      user,
      showType,
      selectedQuoteEmails
    ]
  );


  const getData = useCallback(
    (currentPage: number, pageSize: number, filter: any) => {
      setIsTableFetching(true)
      quotesApi.lazyGet(currentPage, pageSize, filter, showType)
        .then((response) => {
          const data: Pageable<IQuoteData> = response.data

          setTotalCount(data.total)
          setDataList(data.rows)

          setIsTableFetching(false)

        })
        .catch((err: any) => {
          setIsTableFetching(false)
          setDataList([])
          console.log('err', err)
        })
    },
    [t, showType]
  )


  useEffect(() => {
    getData(currentPage + 1, pageSize, '')
  }, [getData, currentPage, pageSize, '', showType])

  const onEmailDialogClose = useCallback((response_approve: boolean = false) => {
    if (response_approve) {
      setIsEmailDialogOpen(false);
      getData(1, pageSize, '')
    } else {
      if (showType === PENDING) {
        dispatch(setLoader(true))

        quotesApi.setQuoteToActive(selectedQuote.id)
          .then(() => {
            dispatch(setLoader(false))
            toast.success(t('quote_activated'))
            setIsEmailDialogOpen(false);
            getData(1, pageSize, '')
          })
          .catch((err: any) => {
            dispatch(setLoader(false))
            toast.error(t('error_activating_quote'))
          })

      } else {
        setIsEmailDialogOpen(false);
        getData(1, pageSize, '')
      }
    }



  }, [showType, selectedQuote, selectedQuoteEmails]);

  const onGenerateJobClose = useCallback(() => {
    setIsGenerateJobOpen(false)

  }, []);

  const handleSubmitSendEmail = useCallback(
    (email_list: IAutoCompleteResponse[]) => {
      // setIsTableFetching(true)
      const formData = new FormData();
      formData.append('email_list', JSON.stringify(email_list.map((opt: any) => opt)))
      quotesApi.sendEmails(formData, selectedQuote.id)
        .then(async (response) => {
          toast.success(t('quotes_sent'))
          const dataList: ICompanyContact[] = response.data
          const holder = await dataList.map<IAutoCompleteResponse>(reponseToOption)
          await setSelectedQuoteEmails(holder)

          // setIsTableFetching(false)
          // setIsEmailDialogOpen(false);
          // getData(1, pageSize, '')
        })
        .catch((err: any) => {
          setIsTableFetching(false)
          setDataList([])
          console.log('err', err)
        })
    },
    [selectedQuote],
  )




  const handleGenerateJob = useCallback(
    () => {
      setIsViewQuoteOpen(false)
      refreshTable()
    },
    [dispatch, selectedQuote],
  )

  const generateExcel = useCallback(async () => {
    if (isGenerating || cooldown) return;

    setIsGenerating(true);
    setCooldown(true);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Active Quotes');

    // Define columns
    worksheet.columns = [
      { header: 'Quote Number', key: 'quoteNumber', width: 20 },
      { header: 'Posting Company', key: 'postingCompany', width: 20 },
      { header: 'Movement Type', key: 'movementType', width: 20 },
      { header: 'Trip Type', key: 'tripType', width: 20 },
      { header: 'Job Type', key: 'jobType', width: 20 },
      { header: 'Weight', key: 'weight', width: 10 },
      { header: 'Pick Up Suburb', key: 'pickUpSuburb', width: 20 },
      { header: 'Delivery Suburb', key: 'deliverySuburb', width: 20 },
      { header: 'Lead Time', key: 'leadTime', width: 20 },
      { header: 'Instructions', key: 'instructions', width: 30 }
    ];
    worksheet.getRow(1).font = { bold: true };

    // Add rows
    dataList.forEach(item => {
      worksheet.addRow({
        quoteNumber: item.reference,
        postingCompany: item.company.companyName,
        movementType: t(item.movement_type),
        tripType: t(item.trip_type),
        jobType: t(item.vehicle_type),
        weight: item.weight,
        pickUpSuburb: item.pick_up_address?.city || '',
        deliverySuburb: item.deliver_to_address?.city || '',
        leadTime: item.lead_time,
        instructions: item.instructions.replace(/\n/g, ' ')
      });
    });

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'Active Quotes.xlsx');
    } catch (error) {
      console.error('Error generating Excel file', error);
    } finally {
      setIsGenerating(false);
      setTimeout(() => setCooldown(false), 3000);
    }
  }, [dataList, t, isGenerating, cooldown]);

  return (
    <>
      <WarningDialog onProceedClick={handleOnProceedDeleteClick} open={isWarningDialogOpen} handleClose={handleWarningDialogClose} message={t('warning_cancel_a_quote')} />
      <EmailListDialog
        open={isEmailDialogOpen}
        handleClose={onEmailDialogClose}
        onDone={onEmailDialogClose}
        country={country}
        onSubmit={handleSubmitSendEmail}
        selectedQuoteEmails={selectedQuoteEmails}
        selectedQuote={selectedQuote}
        mode={'send'}
      />
      <ViewEditQuote
        selectedQuote={selectedQuote}
        readonly={true}
        mode={quoteMode}
        open={isViewQuoteOpen}
        handleClose={handleViewQuoteClose}
        onProceed={handleGenerateJob}
      />
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item md={12}>
          {(showType == ACTIVE_QUOTES && columns.length > 0) &&
            <Grid item container spacing={2} md={6} style={{ alignItems: 'center', paddingBottom: '15px' }}>

              <Grid md={6} item>
                <Button disabled={isGenerating || cooldown} onClick={generateExcel} color="default" variant="contained">  {isGenerating ? 'Generating...' : 'Generate Excel'}</Button>

              </Grid>
            </Grid>

          }
          <PaginatedTable
            columns={columns}
            currentPage={currentPage}
            loading={isTableFetching}
            onPageChange={(params: any) => { setCurrentPage(params) }}
            onPageSizeChange={(params: any) => { setPageSize(params) }}
            pageSize={pageSize}
            rows={dataList}
            totalCount={totalCount}
            onRowClick={onRowClick}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ShowQuotes;
