import {
  Grid,
  TextField,
  InputAdornment,
  makeStyles,
  IconButton,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Tabs,
  Tab,
  Box
} from "@material-ui/core";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import DoneIcon from "@material-ui/icons/Done";
import { UsersApi } from "../../../../Api/Users/Users";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import CustomerRegister from '../../../Landing/CustomerRegister/CustomerRegister'
import {
  COUNTRIES,
  USER_PRIVILEGES,
  USER_ROLES,
} from "../../../../Types/Constants";
import { AppState } from "../../../../Redux";

import isEmail from "validator/lib/isEmail";
import WarehouseRegister from "../../../Landing/WarehouseRegister/WarehouseRegister";

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;

const { ADMIN, ADMIN_USER, COMPANY, SUPER_ADMIN, DRIVER_MANAGER, OTHER, CUSTOMER } =
  USER_ROLES;

const {
  CONFIGURATIONS,
  DRIVER_MANAGEMENT,
  TRANSPORT_MANAGEMENT,
  SEA_FREIGHT_MANAGEMENT,
} = USER_PRIVILEGES;

const useStyles = makeStyles(() => ({
  btnCreate: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  formControl: {
    minWidth: "100%",
  },
  hidden: {
    display: 'none'
  }
}));

const userApi = new UsersApi();


interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function AddUser() {
  const classes = useStyles();
  const user = useSelector((state: AppState) => state.user);
  const isUserSuperAdmin = user.response?.type === SUPER_ADMIN;
  const isUserAdminUser = user.response?.type === ADMIN_USER;
  const isUserCustomer = user.response?.type === CUSTOMER;
  const isUserCompany = user.response?.type === COMPANY;

  const dispatch = useDispatch();
  const [t] = useTranslation("AddUser");
  const [showPass, setShowPass] = useState<boolean>(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isConfiguration, setIsConfiguration] = useState(false);
  const [isDriverManagement, setIsDriverManagement] = useState(false);
  const [isSeaFreightManagement, setIsSeaFreightManagement] = useState(false);
  const [isTransportManagement, setIsTransportManagement] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [country, setCountry] = useState<string | undefined>(
    isUserSuperAdmin ? AUSTRALIA : user.response?.company?.country
  );

  const isAdmin = isUserSuperAdmin || isUserAdminUser
  const [userType, setUserType] = useState(DRIVER_MANAGER);

  const handleOnCreateClick = () => {
    let type: string = "";
    const privileges: string[] = [];

    if (firstName === "") {
      toast.warning(t("firstname_not_empty"));
      return;
    }

    if (lastName === "") {
      toast.warning(t("lastname_not_empty"));
      return;
    }

    if (email === "") {
      toast.warning(t("email_not_empty"));
      return;
    } else if (!isEmail(email)) {
      toast.warning(t("email_invalid_format"));
      return;
    }

    if (password === "" || passwordConfirm === "") {
      toast.warning(t("password_not_null"));
      return;
    } else if (password.length < 6) {
      toast.warning(t("invalid_password_length"));
      return;
    } else if (password !== "" && passwordConfirm !== "") {
      if (password !== passwordConfirm) {
        toast.warning(t("password_did_not_match"));
        return;
      }
    }

    if (isConfiguration) privileges.push(CONFIGURATIONS);
    if (isTransportManagement) privileges.push(TRANSPORT_MANAGEMENT);
    if (isSeaFreightManagement) privileges.push(SEA_FREIGHT_MANAGEMENT);
    if (isDriverManagement) privileges.push(DRIVER_MANAGEMENT);

    if ([ADMIN, SUPER_ADMIN].includes(user.response?.type || "")) {
      type = ADMIN_USER;
    } else if (user.response?.type === COMPANY || user.response?.type === CUSTOMER) {
      type = userType;
    }

    dispatch(setLoader(true));
    userApi
      .add({
        emailAddress: email,
        firstName,
        lastName,
        password,
        password_confirm: passwordConfirm,
        privileges,
        type,
        country,
      })
      .then(() => {
        dispatch(setLoader(false));
        toast.success(t("success_adding_user"));
      })
      .catch((err: any) => {
        dispatch(setLoader(false));
        console.log("err", err);
        toast.error(t(err.response?.data || "error_adding_user"));
      });
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  return (

    <Grid container spacing={5} style={{ "height": "100%" }}>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
        <Tab label="User" {...tabProps(0)} />
        <Tab label="Customer" className={(!isAdmin && !isUserCompany) ? classes.hidden : ''} {...tabProps(1)} />
        {/* <Tab label="Warehouse"  className={(!isAdmin && !isUserCompany) ? classes.hidden : ''}  {...tabProps(2)} /> */}



      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container spacing={5}>

          {isUserCompany ? (
            <Grid md={12} item>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel style={{ fontWeight: "bold" }}>
                  {t("select_user_type")}
                </InputLabel>
                <Select
                  value={userType}
                  onChange={(e) => setUserType(e.target.value as string)}
                >
                  <MenuItem value={DRIVER_MANAGER}>{t(DRIVER_MANAGER)}</MenuItem>
                  <MenuItem value={OTHER}>{t(OTHER)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null}
          {/* {isUserSuperAdmin ? (
            <Grid md={12} item>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel style={{ fontWeight: "bold" }}>
                  {t("select_your_country")}
                </InputLabel>
                <Select
                  value={country ?? ""}
                  onChange={(e) => setCountry(e.target.value as string)}
                >
                  <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                  <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                  <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                  <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          ) : null} */}
          <Grid item md={6}>
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_first_name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_last_name")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("provide_email_address")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid item md={6} />
          <Grid md={6} item>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_password")}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid md={6} item>
            <TextField
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_password")}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          {isUserSuperAdmin || userType === OTHER ? (
            <>
              <Grid md={12} item>
                <Typography align="left" variant="body1">
                  {t("set_privileges")}
                </Typography>
              </Grid>
              <Grid md={3} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isTransportManagement}
                      onChange={(e) => setIsTransportManagement(e.target.checked)}
                    />
                  }
                  label={t("transport_management")}
                />
              </Grid>
              {/* <Grid md={9} item>
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={isSeaFreightManagement} onChange={(e) => setIsSeaFreightManagement(e.target.checked)} />}
                                    label={t('sea_freight_management')}
                                />
                            </Grid> */}
              <Grid md={3} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isConfiguration}
                      onChange={(e) => setIsConfiguration(e.target.checked)}
                    />
                  }
                  label={t("configuration")}
                />
              </Grid>
              <Grid md={9} item>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isDriverManagement}
                      onChange={(e) => setIsDriverManagement(e.target.checked)}
                    />
                  }
                  label={t("driver_management")}
                />
              </Grid>
            </>
          ) : null}

          <Grid md={6} item>
            <Button
              startIcon={<DoneIcon />}
              className={classes.btnCreate}
              variant="contained"
              onClick={handleOnCreateClick}
              fullWidth
            >
              {t("create")}
            </Button>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CustomerRegister />
      </TabPanel>
      {/* <TabPanel value={tabValue} index={2}>
        <WarehouseRegister />
      </TabPanel> */}
    </Grid>
  );
}

export default AddUser;
