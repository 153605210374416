import { ICompanyInfo } from "../../../../Application/DTOs/CompanyDto/CompanyDto.type";

export const SET_COMPANY_LIST = 'SET_COMPANY_LIST'

export interface ICompanyState {
    list: ICompanyInfo[]
}

export interface ISetCompanyListAction {
    type: typeof SET_COMPANY_LIST
    payload: ICompanyInfo[]
}

export type TCompanyActions = ISetCompanyListAction