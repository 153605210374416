import {GridColumns, GridValueGetterParams } from "@material-ui/data-grid";
import { Grid, Switch } from "@material-ui/core";
import { TFunction } from "i18next";
import { IUserInfo } from "../../../../../../Application/DTOs/UsersDto/UsersDto.type";

interface IOnSwitchCallback {
    (userId: string | number, isChecked: boolean, type: 'sms' | 'email'): void
}

export const getDriverManagementColumns = (t: TFunction, onSwitchCallback: IOnSwitchCallback): GridColumns => {
    return [
        {
            field: 'company_name',
            headerName: t('company_name'),
            sortable: false,
            width: 200,
            valueGetter: (params: GridValueGetterParams) => (params.row as IUserInfo).driver?.company_name
        },
        {
            field: 'firstName',
            headerName: t('first_name'),
            sortable: false,
            width: 200

        },
        {
            field: 'lastName',
            headerName: t('last_name'),
            sortable: false,
            width: 200

        },
        {
            field: 'email',
            headerName: t('email'),
            sortable: false,
            width: 300

        },
        {
            field: 'status',
            headerName: t('status'),
            sortable: false,
            width: 200

        },
        {
            field: 'smsContactability',
            headerName: t('is_sms_contactable'),
            width: 200,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const user: IUserInfo = params.row as IUserInfo
                const isSmsContactable = user.driver?.is_sms_contactable


                return (
                    <Grid
                        container
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item md={4}>
                            <Switch
                                checked={isSmsContactable}
                                color="primary"
                                onChange={(e) => {
                                    onSwitchCallback(user.id, e.target.checked, 'sms')
                                }}
                            />
                        </Grid>

                    </Grid>
                )
            }
        },
        {
            field: 'emailContactability',
            headerName: t('is_email_contactable'),
            width: 200,
            hide: true,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                const user: IUserInfo = params.row as IUserInfo
                const isEmailContactable = user.driver?.is_email_contactable

                return (
                    <Grid
                        container
                        alignContent="center"
                        justifyContent="center"
                    >
                        <Grid item md={4}>
                            <Switch
                                checked={isEmailContactable}
                                color="primary"
                                onChange={(e) => {
                                    onSwitchCallback(user.id, e.target.checked, 'email')
                                }}
                            />
                        </Grid>

                    </Grid>
                )
            }
        }
    ]
}