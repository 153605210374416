import { Button, Dialog, DialogActions, InputAdornment, DialogContent, DialogTitle, Grid, TextField, Typography, makeStyles, Select, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IAdditionalStepProps } from "./AdditionalStepdialog.types";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Job } from "../../../Api/Job/Job";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AppState } from "../../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { USER_ROLES, JOB_CLASSIFICATION, MOVEMENT_TYPES } from "../../../Types/Constants";
import SingleAutocomplete from "../../Common/SingleAutocomplete/SingleAutocomplete";
import { Company } from "../../../Api/Company/Company";
import { setLoader } from "../../../Redux/Loader/actions";
import { ICompanyInfo } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { IAutocompleteOption } from "../../Common/types";
import { Driver } from "../../../Api/Driver/Driver";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import capitalize from "capitalize";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { CURRENCIES } from "../../../Types/Currencies";
import { isFloat, isNonNumeric } from "../../../Utilities/Regex";
import {StaticDateTimePicker ,DateTimePicker} from '@material-ui/pickers';

const jobApi = new Job()

const companyApi = new Company()

const driverApi = new Driver()

const {
    SUPER_ADMIN,
    ADMIN_USER,
    COMPANY,
    DRIVER
} = USER_ROLES


const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
    selectLabel: {
        fontWeight: 'bold',
      },
}))


function AdditionalStepDialog(props: IAdditionalStepProps) {
    const [t] = useTranslation('AdditionalStep')
    const dispatch = useDispatch()
    const {selectedJob} = props
    const user = useSelector((state: AppState) => state.user)
    const isAdmin = [SUPER_ADMIN, ADMIN_USER].includes(user.response?.type ?? '')
    const isUserAdmin = user.response?.type === ADMIN_USER
    const [addStepDatetime, setAddStepDatetime] = useState<Date | null>(new Date());
    const [addStepEndDatetime, setAddStepEndDatetime] = useState<Date | null>(new Date());
    const classes = useStyles()
    const [additionalStepStatus, setAdditionalStepStatus] = useState('');

    const arrayOfofSteps = [{'key': 'storage' , value: 'Storage' },{'key': 'via_yard' , value: 'Via yard' } ,{'key': 'fumigation' , value: 'Fumigation' }]
    const additionalStepOptions = useMemo(
      () =>
     
      arrayOfofSteps.map((state, i) =>
        {
          if(t(state['value'])!= ""){
            return <MenuItem key={state['key']} value={state['key']}>
              {t(state['value'])}
            </MenuItem>
          }
        }
        ),
      [arrayOfofSteps, t]
    );
  
   const handleStepSubmit= ()=>{
   
    if(selectedJob){
      if(!addStepDatetime || !addStepEndDatetime){
        toast.warn(t('Dates Required'));
        return;
      }
      jobApi.setAdditionalStep(selectedJob.id ,{
        additional_step_status : additionalStepStatus,
        additional_step_date : addStepDatetime ,
        additional_step_end_date :addStepEndDatetime 
      } )
      .then(() => {
          toast.success(t('additional_step_success'))
          if (props.onDone) props.onDone()
          props.handleClose()
      })
      .catch((err :any) => {
          console.log('err', err)
          toast.error(t(err.response?.data?.error_code || 'error_bidding_job'))
      }) 
      
    }
   }
   useEffect(()=>{
    setAddStepDatetime(selectedJob?.additional_step_date as any )
    setAddStepEndDatetime(selectedJob?.additional_step_end_date as any)
    setAdditionalStepStatus(selectedJob?.additional_step_status || '')

   },[selectedJob])
    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={props.open} onClose={props.handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('set_additional_step')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item md={12}>
                          <FormControl     
                            variant="filled"
                            className={classes.formControl}
                          >
                            <InputLabel className={classes.selectLabel}>
                            Status
                            </InputLabel>
                            <Select
                              value={additionalStepStatus}
                              onChange={(e) => {
                                setAdditionalStepStatus(e.target.value as any);
                    
                                // setReturnToGroup('');
                                // setReturnToAddressId('');
                              }}
                            >
                              {additionalStepOptions}
                            </Select>
                  </FormControl>
                  </Grid>
                  <Grid item md={12} container spacing={2}>
                          <Grid item md={6}>
                           <Typography variant="body1" gutterBottom>
                            Start
                          </Typography>
                          <DateTimePicker
                          inputFormat='dd/MM/yyyy'
                          toolbarTitle={t('select_date_time') +'*'}
                          // disabled={readonly}
                          renderInput={(props) => (
                            <TextField fullWidth variant="outlined" {...props} />
                          )}
                          ampm={false}
                          value={addStepDatetime}
                          onChange={setAddStepDatetime}
                          minDateTime={selectedJob?.pickup_date_time}
                        />
                        </Grid>

                        <Grid item md={6}>
                        <Typography variant="body1" gutterBottom>
                            End 
                          </Typography>
                           <DateTimePicker
                          inputFormat='dd/MM/yyyy'
                          toolbarTitle={t('select_date_time')}
                          // disabled={readonly}
                          renderInput={(props) => (
                            <TextField fullWidth variant="outlined" {...props} />
                          )}
                          ampm={false}
                          value={addStepEndDatetime}
                          onChange={setAddStepEndDatetime}
                          minDateTime={addStepDatetime}
                        />
                        </Grid>

                        </Grid>
                 
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleStepSubmit} variant="contained" color="primary">
                        {t('Set')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AdditionalStepDialog