import axios from 'axios';
import {
  IDeliveryFilter,
  IJobBidPayload,
  IJobFilters,
  IJobReportCSVPayload,
  IJobReportPayload,
  IJobLazyReportPayload,
  IJobStatisticsPayload,
  IUpdateAdditionalChargesPayload,
} from '../../../../Application/DTOs/JobsDto/JobsDto.types';
import { TShowTypes } from '../../Components/Home/Jobs/Jobs.types';
import { TActualDateTypes, TOKEN_STORAGE_KEY } from '../../Types/Constants';
import { WebStorage } from '../../Utilities/WebStorage';

const baseUrl = process.env.REACT_APP_API_URL;

const storage = new WebStorage();

export class Job {
  create(formData: FormData) {
    return axios.post(`${baseUrl}/job/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  createAndAllocate(formData: FormData, userId: string | number) {
    return axios.post(`${baseUrl}/job/create/allocate/${userId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  allocateJob(jobId: string | number, userId: string | number, sell_price: any, buy_price: any, temperature: string) {
    return axios.post(`${baseUrl}/job/allocate/${jobId}`,
      { userId: userId, sell_price: sell_price, buy_price: buy_price, temperature: temperature }, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  createJobToBonds(xmlData: XMLDocument) {
    return axios.post(`https://appsrv.bondscouriers.com.au/bondsweb/api/upload-xml-job.htm`, xmlData, {
      headers: {
        headers: { 'Content-Type': 'text/xml' }
      },
    });
  }

  jobDetail(jobId: string | number) {
    return axios.get(`${baseUrl}/job/detail/${jobId}`, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  setAdditionalStep(jobId: string | number, formData: any) {
    return axios.post(`${baseUrl}/job/set/additionalstep/${jobId}`, formData, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }


  update(jobId: string | number, formData: FormData) {
    return axios.post(`${baseUrl}/job/update/${jobId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  uploadFiles(creationId: any, files: File[], job_id: any, is_required: any, document_id: any) {
    const formData = new FormData();

    formData.append('creation_id', creationId);
    formData.append('job_id', job_id);
    formData.append('is_required', is_required);
    formData.append('document_id', document_id);
    for (const i in files) {
      if (Object.prototype.hasOwnProperty.call(files, i)) {
        const image = files[i];
        formData.append('files', image);
      }
    }

    return axios.post(`${baseUrl}/job/upload/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  sendConnotationEmail(job_id: any, files: File[]) {
    const formData = new FormData();
    for (const i in files) {
      if (Object.prototype.hasOwnProperty.call(files, i)) {
        const file = files[i];
        formData.append('files', file);
      }
    }

    return axios.post(`${baseUrl}/job/send/connotation/${job_id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  get(
    classification: string,
    page: TShowTypes,
    country: string | undefined,
    filters?: IJobFilters,
    pagetype?: string,
    movementType?: string,

  ) {
    return axios.get(`${baseUrl}/job/get/optimize`, {
      params: {
        classification,
        page,
        country,
        filters,
        pagetype,
        movementType
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getAggregatedCompleteReports(
    classification: string,
  ) {
    return axios.get(`${baseUrl}/job/reports/completed`, {
      params: {
        classification,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getWeeklyCompleteReports(
    classification: string,
    companyId
  ) {
    return axios.get(`${baseUrl}/job/reports/completed/weekly`, {
      params: {
        classification,
        companyId
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getGroupedPallets(
    companyId: number,
    limit: number,
    offset: number,
    startDate?: string | null,
    endDate?: string | null,
    customerId?: number | null
  ): Promise<any> {

    const params: Record<string, any> = {
      companyId,
      limit,
      offset,
    };

    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    if (customerId) {
      params.customerId = customerId;
    }

    return axios.get(`${baseUrl}/job/reports/sites`, {
      params,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getJobCompletion(
    companyId: number,
    limit: number,
    offset: number,
    startDate?: string | null,
    endDate?: string | null,
    customerId?: number | null
  ) {
    const params: Record<string, any> = {
      companyId,
      limit,
      offset,
    };

    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }

    if (customerId) {
      params.customerId = customerId;
    }

    return axios.get(`${baseUrl}/job/reports/job-completion`, {
      params,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getUpcomingJobs(
    classification: string,
    companyId
  ) {
    return axios.get(`${baseUrl}/dashboard/upcoming/jobs`, {
      params: {
        classification,
        companyId
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  lazyGet(
    classification: string,
    page: TShowTypes,
    country: string | undefined,
    filters?: IJobFilters,
    pagetype?: string,
    movementType?: string,
    offset?: number,
    pageNumber?: number,
    limit?: number,
    order_by?: any
  ) {
    return axios.get(`${baseUrl}/job/get/optimize`, {
      params: {
        classification,
        page,
        country,
        filters,
        pagetype,
        movementType,
        offset,
        pageNumber,
        limit,
        order_by
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getJobCompleted(
    companyId: number,
    limit: number,
    offset: number,
    startDate?: Date | string | null,
    endDate?: Date | string | null,
    customerId?: number | null,
    sort?: { field: string; sort?: 'asc' | 'desc' }
  ) {
    const params: Record<string, any> = {
      companyId,
      limit,
      offset,
    };

    if (startDate) {
      params.startDate = startDate;
    }
    if (endDate) {
      params.endDate = endDate;
    }
    if (customerId) {
      params.customerId = customerId;
    }

    if (sort) {
      params.sortField = sort.field;
      params.sortOrder = sort.sort || 'asc';
    }
    return axios.get(`${baseUrl}/job/reports/job-completed`, {
      params,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getJobsForReport(payload: IJobReportPayload) {
    return axios.get(`${baseUrl}/job/get/reports`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getJobsForReport_v2(payload: IJobReportPayload) {
    return axios.get(`${baseUrl}/job/get/optimize/reports`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getJobsForReport_lazy(payload: IJobLazyReportPayload) {
    return axios.get(`${baseUrl}/job/get/optimize/reports`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }


  getJobsStatistics(payload: IJobStatisticsPayload) {
    return axios.get(`${baseUrl}/job/statistics`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getJobsBidsStatistics(status: string, country?: string) {
    return axios.get(`${baseUrl}/job/bids/statistics`, {
      params: {
        status,
        country,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  downloadJobFile(jobId: any, type: string, fileId: string) {
    return axios.get(`${baseUrl}/job/file/download`, {
      responseType: 'blob',
      params: {
        jobId,
        type,
        fileId
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  downloadAllJobFiles(jobId: any) {
    return axios.get(`${baseUrl}/job/file/download/all`, {
      responseType: 'blob',
      params: {
        jobId,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getJobsForReportCSV(payload: IJobReportCSVPayload) {
    return axios.get(`${baseUrl}/job/csv/reports`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getInvoiceReportCSV(filters: IJobFilters) {
    return axios.post(
      `${baseUrl}/job/csv/reports/invoice`,
      { filters },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  cancelJob(jobId: string | number) {
    return axios.post(
      `${baseUrl}/job/cancel`,
      { jobId },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  bid(payload: IJobBidPayload) {
    return axios.post(`${baseUrl}/job/bid`, payload, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  clone(jobId: number | number, cloneAmount: string) {
    return axios.post(
      `${baseUrl}/job/clone`,
      { cloneAmount, jobId },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  updateBidStatus(jobBidId: string | number, status: string) {
    return axios.post(
      `${baseUrl}/job/bid/status/update`,
      { jobBidId, status },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  updateActualDate(jobId: string | number, date: Date, type: TActualDateTypes) {
    return axios.post(
      `${baseUrl}/job/actual-date/update`,
      { jobId, date, type },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  assignDriverToJob(jobId: string | number, userId: string | number) {
    return axios.post(
      `${baseUrl}/job/driver/assign`,
      { jobId, userId },
      {
        headers: {
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  markComplete(formData: FormData) {
    return axios.post(`${baseUrl}/job/mark/complete`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  uploadImage(jobId: string, fileType: string, images: File[]) {
    const formData = new FormData();

    formData.append('jobId', jobId);
    formData.append('fileType', fileType);

    for (const i in images) {
      if (Object.prototype.hasOwnProperty.call(images, i)) {
        const image = images[i];
        formData.append('image', image);
      }
    }

    return axios.post(`${baseUrl}/job/upload/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getArchivedJobs() {
    return axios.get(`${baseUrl}/job/get/reports/archived`, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getDeliveredJobsWithNoPods() {
    return axios.get(`${baseUrl}/job/get/no-pods`, {
      headers: { Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}` },
    });
  }
  getProfitabilityReports() {
    return axios.get(`${baseUrl}/job/get/profitability`, {
      headers: { Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}` },
    });
  }
  getDeliveryReports(payload: IDeliveryFilter) {
    return axios.get(`${baseUrl}/job/get/delivery-reports`, {
      params: payload,
      headers: { Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}` },
    });
  }
  getDeliveryReportCount(payload: IDeliveryFilter) {
    return axios.get(`${baseUrl}/job/get/reports/scheduled/count`, {
      params: payload,
      headers: { Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}` },
    });
  }
  getOverdueCollections() {
    return axios.get(`${baseUrl}/job/get/overdue-collections`, {
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }
  getOverdueDeliveries() {
    return axios.get(`${baseUrl}/job/get/overdue-delivery`, {
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }

  updateAdditionalCharges(
    jobId: string | number,
    data: IUpdateAdditionalChargesPayload
  ) {
    return axios.post(
      `${baseUrl}/job/update/${jobId}/additional_charges`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  getDocumentListing() {
    return axios.get(`${baseUrl}/job/document/listing`, {
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }

  getJobComment(jobId: any, forAdmin: any) {
    return axios.get(`${baseUrl}/job/comments/${jobId}`, {
      params: { forAdmin: forAdmin },
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }
  getJobCommentList(jobId: any, forAdmin: any) {
    return axios.get(`${baseUrl}/job/comments/lists/${jobId}`, {
      params: { forAdmin: forAdmin },
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }
  setJobComment(data: any) {
    return axios.post(`${baseUrl}/job/comments`, data, {
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }
  setJobCommentV2(data: any) {
    return axios.post(`${baseUrl}/job/comments/manage`, data, {
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }
  deleteComment(id: any, data: any) {
    return axios.post(`${baseUrl}/job/comments/delete/${id}`, data, {
      headers: { Authorization: 'Bearer ' + storage.retrieve(TOKEN_STORAGE_KEY) },
    });
  }


  getJobReport(
    classification: string,
    page: TShowTypes,
    country: string | undefined,
    filters?: IJobFilters,
    pagetype?: string,
    movementType?: string,

  ) {
    return axios.get(`${baseUrl}/job/get/optimize`, {
      params: {
        classification,
        page,
        country,
        filters,
        pagetype,
        movementType
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  searchJobs(search: any) {
    return axios.get(`${baseUrl}/job/search/job`, {
      params: { search },
      headers: { Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}` }

    });
  }
  getTransportMapping() {
    return axios.get(`${baseUrl}/job/transport/config`, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}
