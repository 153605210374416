import React, { useEffect, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';

interface HorizontalBarChartProps {
    graphData: any[];
    label: string;
    color: string;
}

const useStyles = makeStyles((theme) => ({
    gridBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '13px',
        paddingTop: '20px',
        margin: '0 0px 10px 0px',
        backgroundColor: 'white',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '10px',
        },
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1',
    },
    title: {
        fontSize: '.8rem',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '.3rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.6rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.7rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '.8rem',
        },
    },
}));

const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({ graphData, label, color }) => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState<any>({});
    const [chartId] = useState(uuidv4());
    const classes = useStyles();

    // Predefined values for the bars
    const HiToLow = [1500, 1400, 1300, 1200, 1100];

    useEffect(() => {
        // Filter out items with zero or null total_quantity
        const filteredData = graphData.filter((item: any) => {
            const quantity = parseInt(item['total_quantity'], 10);
            return quantity && quantity > 0;
        });

        // Sort filtered data by total_quantity from highest to lowest
        const sortedData = [...filteredData].sort((a: any, b: any) =>
            parseInt(b['total_quantity'], 10) - parseInt(a['total_quantity'], 10)
        );

        // Ensure we have exactly 5 data points, adding filler if necessary
        const dataPoints = sortedData.slice(0, 5).map((item: any, index: number) => {
            const originalQuantity = parseInt(item['total_quantity'], 10) || 0;
            const adjustedQuantity = HiToLow[index] || originalQuantity; // Use predefined value or original quantity

            return {
                company_name: item['company_name'] || '',
                total_quantity: adjustedQuantity,
                original_quantity: originalQuantity // Keep original value for tooltip
            };
        });

        // Add filler data if less than 5 items
        while (dataPoints.length < 5) {
            dataPoints.push({
                company_name: '',
                total_quantity: 0,
                original_quantity: 0
            });
        }

        const labels = dataPoints.map(point => point.company_name);
        const data = dataPoints.map(point => point.total_quantity);

        setChartData({
            labels,
            datasets: [
                {
                    label: label,
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1,
                    hoverBackgroundColor: color,
                    hoverBorderColor: color,
                    data,
                    originalQuantities: dataPoints.map(point => point.original_quantity)
                }
            ]
        });
    }, [graphData, label, color, t]);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            position: 'bottom',
            display: false,
            labels: {
                boxWidth: 10,
                padding: 10,
                fontSize: 12,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const index = tooltipItem.index;
                    const originalQuantity = dataset.originalQuantities[index];
                    const label = data.labels[index] || '';
                    return `${label} - ${originalQuantity}`;
                }
            }
        },
        scales: {
            xAxes: [{
                ticks: {
                    display: false,
                    min: 20
                },
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                ticks: {
                    padding: -5,
                    fontColor: 'white',
                    z: 1,
                    mirror: true,
                },
                gridLines: {
                    display: false
                }
            }]
        }
    };

    return (
        <Grid
            item
            key={chartId}
            className={classes.gridBox}
            md={12}
        >
            <Typography className={classes.title}>{label}</Typography>
            <div style={{ width: '100%' }}>
                <HorizontalBar data={chartData} options={options} />
            </div>
        </Grid>
    );
};

export default HorizontalBarChart;
