import React, { useRef, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomLegend from './CustomLegend'
const useStyles = makeStyles(() => ({
    legendContainer: {
        display: 'flex',
        flexWrap: 'wrap',  // Ensures legend items wrap to new lines
        justifyContent: 'center',
        marginTop: 10,
        width: '100%',     // Ensures container does not exceed grid width
        boxSizing: 'border-box', // Includes padding and border in the element's total width and height
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px 5px 0', // Spacing between items and rows
    },
    legendBox: {
        width: 12,
        height: 12,
        marginRight: 5,
    },
    mainBox: {
        paddingTop: '20px',
        borderRadius: '13px',
        textAlign: 'center',
        minHeight: '300px',
        height: '300px',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));
const textCenterPlugin = (centerText: string) => ({
    id: 'textCenter',
    beforeDraw: (chart: any) => {
        if (!centerText) return;

        const { ctx, width, height } = chart;
        const centerX = width / 2;
        const centerY = height / 2;

        ctx.restore();
        const fontSize = (height / 10).toFixed(2);
        ctx.font = `${fontSize}px sans-serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#223A59';
        ctx.fillText(centerText, centerX, centerY);
        ctx.save();
    }
});

const DoughnutChart = ({
    labels,
    data,
    chartLabel,
    showCenterText = false,
    centerText = '',
    backgroundColors = ['#1FA0ED', '#223A59', '#FFCE56', '#4CAF50', '#FF7043', '#B423BE', 'red'],
    title = ''
}) => {
    const chartRef = useRef(null);
    const classes = useStyles();

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: chartLabel,
                data: data,
                backgroundColor: backgroundColors,
                hoverBackgroundColor: backgroundColors,
            }
        ]
    };

    const options = {
        responsive: true,
        legend: {
            display: false,
            position: 'bottom',
            labels: {
                fontSize: 12,
                fontColor: '#000',
                boxWidth: 10,
            }
        },
        animation: {
            duration: 0
        },
        cutoutPercentage: 50,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.label}: ${context.raw}`;
                    }
                }
            }
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.chartInstance.update();
        }
    }, [centerText]);
    const legendItems = labels.map((label, index) => ({
        text: label,
        fillStyle: backgroundColors[index]
    }));
    return (
        <Grid item className={classes.mainBox}>
            {title && <Typography style={{ fontSize: '1rem', marginBottom: '10px' }}>{title}</Typography>}
            <div style={{ height: '100%', width: '70%' }}>
                <Doughnut
                    data={chartData}
                    options={options}
                    ref={chartRef}
                />
            </div>
            <CustomLegend
                legendItems={legendItems}
                chart={chartRef.current?.chartInstance}
                width={200}
                height={100}
            />
        </Grid>
    );
}

export default DoughnutChart;
