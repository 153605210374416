import { useTranslation } from "react-i18next";
import { IEditJobDataDialogProps } from "./EditJobDataDialog.types";
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputAdornment, InputLabel, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { StaticDateTimePicker } from "@material-ui/pickers";
import { Job } from "../../../Api/Job/Job";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { AppState } from "../../../Redux";
import { JOB_STATUS } from "../../../Types/Constants";

const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%',
    },
}))

const PICK_UP_DATE = 'PICK_UP_DATE'
const DELIVERY_DATE = 'DELIVERY_DATE'
const RETURN_DATE = 'RETURN_DATE'

const jobApi = new Job()

const {
    IN_PROGRESS,
    PICKEDUP
} = JOB_STATUS

function EditJobDataDialog(props: IEditJobDataDialogProps) {
    const [t] = useTranslation('EditActualDatesDialog')
    const { open, handleClose, selectedJob } = props
    const user = useSelector((state: AppState) => state.user)
    const classes = useStyles()
    const dispatch = useDispatch()
    const [date, setDate] = useState<Date | null>(new Date())
    const [actualDateType, setActualDateType] = useState('')
    const [initialDateType, setInitialDateType] = useState('')
    const [containerNo, setContainerNo] = useState('')
    const [sealNo, setSealNo] = useState('')
    const isPostingCompany = user.response?.company?.id as any === selectedJob?.company_id as any

    useEffect(() => {
        if (!open) {
            setActualDateType('')
        }
    }, [open])

    const handleSaveClick = useCallback(
        () => {
            if (selectedJob && date) {
                const formData = new FormData()
                const dateString = date.toISOString()

                switch (actualDateType) {
                    case PICK_UP_DATE:
                        formData.append('actual_pick_up_time', dateString)
                        break;
                    case DELIVERY_DATE:
                        formData.append('actual_delivery_time', dateString)
                        break;
                }

                switch (initialDateType) {
                    case PICK_UP_DATE:
                        formData.append('pickup_date_time', dateString)
                        break;
                    case DELIVERY_DATE:
                        formData.append('delivery_date_time', dateString)
                        break;
                }

                formData.append('container_no', containerNo)
                formData.append('seal_number', sealNo)

                dispatch(setLoader(true))
                jobApi.update(selectedJob.id, formData)
                    .then(() => {
                        toast.success(t('success_updating_job'))
                        dispatch(setLoader(false))
                        handleClose()
                    })
                    .catch((err: any) => {
                        console.log(`err`, err)
                        dispatch(setLoader(false))
                        toast.error(t('error_updating_job'))
                    })
            }

        },
        [selectedJob, dispatch, t, actualDateType, date, handleClose, containerNo, sealNo, initialDateType],
    )

    useEffect(() => {
        switch (actualDateType) {
            case PICK_UP_DATE:
                setDate(selectedJob?.actual_pick_up_time ? new Date(selectedJob?.actual_pick_up_time) : null)
                break;

            case DELIVERY_DATE:
                setDate(selectedJob?.actual_delivery_time ? new Date(selectedJob?.actual_delivery_time) : null)
                break;
        }

        setContainerNo(selectedJob?.container_no ?? '')
        setSealNo(selectedJob?.seal_number ?? '')

    }, [actualDateType, selectedJob])

    return (
        <>
            <Dialog maxWidth="xs" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('edit_actual_dates')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >

                        {
                            isPostingCompany
                                ? <>
                                    <Grid md={12} item>
                                        <Typography>
                                            {t('initial_dates')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={12} item>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_initial_date')}</InputLabel>
                                            <Select
                                                value={initialDateType}
                                                onChange={(e) => setInitialDateType(e.target.value as string)}
                                            >
                                                {[IN_PROGRESS].includes(selectedJob?.status ?? '') ? <MenuItem value={PICK_UP_DATE}>{t(PICK_UP_DATE)}</MenuItem> : null}
                                                {[IN_PROGRESS, PICKEDUP].includes(selectedJob?.status ?? '') ? <MenuItem value={DELIVERY_DATE}>{t(DELIVERY_DATE)}</MenuItem> : null}
                                                {/* {selectedJob?.actual_pick_up_time ? <MenuItem value={RETURN_DATE}>{t(RETURN_DATE)}</MenuItem> : null} */}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                                : <>
                                    <Grid md={12} item>
                                        <Typography>
                                            {t('actual_dates')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={12} item>
                                        <FormControl variant="filled" className={classes.formControl}>
                                            <InputLabel style={{ fontWeight: 'bold' }}>{t('select_actual_date')}</InputLabel>
                                            <Select
                                                value={actualDateType}
                                                onChange={(e) => setActualDateType(e.target.value as string)}
                                            >
                                                {selectedJob?.actual_pick_up_time ? <MenuItem value={PICK_UP_DATE}>{t(PICK_UP_DATE)}</MenuItem> : null}
                                                {selectedJob?.actual_delivery_time ? <MenuItem value={DELIVERY_DATE}>{t(DELIVERY_DATE)}</MenuItem> : null}
                                                {/* {selectedJob?.actual_pick_up_time ? <MenuItem value={RETURN_DATE}>{t(RETURN_DATE)}</MenuItem> : null} */}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </>
                        }
                        {
                            actualDateType !== '' || initialDateType !== ''
                                ? <Grid item md={12}>
                                    <StaticDateTimePicker
                                        toolbarTitle={t('select_date_time')}
                                        disabled
                                        renderInput={props => <TextField disabled fullWidth variant="outlined" {...props} />}
                                        value={date}
                                        onChange={setDate}
                                    // minDateTime={determineMinDateTime()}
                                    />
                                </Grid>
                                : null
                        }
                        {
                            isPostingCompany
                                ? <>
                                    <Grid md={12} item>
                                        <Typography>
                                            {t('job_details')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={12} item>
                                        <TextField
                                            value={containerNo}
                                            onChange={(e) => setContainerNo(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('container_no')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={12} item>
                                        <TextField
                                            value={sealNo}
                                            onChange={(e) => setSealNo(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('seal_no')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>

                                </>
                                : null
                        }


                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveClick} variant="contained" color="primary">
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default EditJobDataDialog