import { Button, Dialog,  DialogContent ,TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ICommentDialogProps } from "./CommentDialog.types";
import { Job } from "../../../Api/Job/Job";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AppState } from "../../../Redux";
import { useDispatch, useSelector } from "react-redux";
import { USER_ROLES, JOB_CLASSIFICATION, MOVEMENT_TYPES } from "../../../Types/Constants";


const jobApi = new Job()


const {
    SUPER_ADMIN,
    ADMIN_USER,
    COMPANY,
    DRIVER
} = USER_ROLES




export default function CommentDialog(props: ICommentDialogProps) {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const {commentData ,open,onBlurUpdate ,isEditable} = props
    const user = useSelector((state: AppState) => state.user)
    const isAdmin = [SUPER_ADMIN, ADMIN_USER].includes(user.response?.type ?? '')
    const isUserAdmin = user.response?.type === ADMIN_USER
    const userId = user.response?.id
    const [activeComment, setActiveComment] = useState<any>('')
    const [oldComment, setOldComment] = useState<any>('')

    useEffect(()=>{
        if (open) {
            setActiveComment( commentData?.comment || '')
            setOldComment(commentData?.comment || '')
            console.log(commentData)
        }
    },[open])
    const handeOnCommentBlur =( value : any)=>{
        if(value != oldComment){
            onBlurUpdate(value , commentData?.id || '')
        }

    }
    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={props.open} onClose={props.handleClose}>
                
                <DialogContent dividers>
                    <TextField 
                    id="standard-textarea"
                    label="notes"
                    placeholder="Notes"
                    multiline
                    variant="standard"
                    fullWidth
                    value={activeComment}
                    disabled={isEditable}
                    onChange={(e) => setActiveComment(e.target.value)}
                    onBlur={(e:any )=> {handeOnCommentBlur(e.target.value)}}/>
                </DialogContent>
                {/* <DialogActions>
                </DialogActions> */}
            </Dialog>
        </>
    )
}
