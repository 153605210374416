export const TOSPH = () => {
    return (
        <>

                <h4>1.Terms and Conditions of Use of the Application and the Website</h4>
                <p>1.1.The following terms and conditions (the Terms) apply to the use by the Transporter and the Enquirer (you, your) of the MoveIt4u™ Application (the Application) and the website www.MoveIt4u.com.au (the Website) as operated by MoveIt4u Philippines Inc (MoveIt4u™, we, us, our). Please carefully read the Terms.</p>
                <p>1.2.In these Terms the term ‘Application’ is to be given its widest meaning and includes use of the Website, services as specified on the Website and any material in any form (including printed, electronic or otherwise), which is provided to you by us or our employees or contractors.</p>
                <p>1.3.By using, browsing or reading the Application, you indicate that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must immediately cease use of the Application, or any of its products or Services (as defined below).</p>
                <p>1.4.If you breach these Terms, we may immediately suspend or terminate your use of the Application and/or the Website, without refund, and take appropriate legal action against You.</p>

                <h4>2.About the Application</h4>
                <p>2.1.The Application is a technology platform which facilitates interactions between:</p>
                <p>2.1.1.persons providing Transport Services (the Transporter); and</p>
                <p>2.1.2.persons seeking Transport Services (the Enquirer);
                so as to make it easier for the Transporter and the Enquirer to locate, communicate, arrange payment and deliver the Transport Services sought by the Enquirer (the Services) in a fast and secure manner. In these Terms, Transport Services mean transport and associated services for the delivery of goods.</p>
                <p>2.2.The Application is operated by MoveIt4u™. Access to and use of the Application, the Website or any of its associated products or Services, is provided by MoveIt4u™.</p>

                <h4>3.Accessing the Services</h4>
                <p>3.1.In order to access the Services, both the Transporter and the Enquirer are required to register for an account through the Application or the Website (the Account).</p>
                <p>3.2.As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details).</p>
                <p>3.3.You warrant that any information you give to MoveIt4u™ in the course of completing the registration process, or in the use of the Services, will always be accurate, correct and up to date.</p>
                <p>3.4.Once you have completed the registration process, you will be a registered member of the Services, including the Application and the Website, (Member). By registering, you confirm your agreement to be bound by the Terms.</p>
                <p>3.5.You may not use the Services and may not accept the Terms if:</p>
                <p>3.5.1.you are not of legal age to form a binding contract with MoveIt4u™; or</p>
                <p>3.5.2.you are a person barred from receiving the Services under the laws of Philippines or other countries including the country in which you are resident or from which you use the Services.</p>

                <h4>4.Your obligations as a Member</h4>
                <p>4.1.As a Member, you agree that:</p>
                <p>4.1.1.you will not share your profile with any other person;</p>
                <p>4.1.2.you will use the Services only for purposes that are permitted by:</p>
                <p>4.1.2.1.the Terms; and</p>
                <p>4.1.2.2.any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions;</p>
                <p>4.1.3.you have sole responsibility for protecting the confidentiality of your password and/or email address. Use of your password by any other person may result in the immediate cancellation of your access to and use of the Services;</p>
                <p>4.1.4.any use of your registration information by any other person, or third parties, is strictly prohibited. You agree to immediately notify MoveIt4u™ of any unauthorised use of your password or email address or any breach of security of which you have become aware;</p>
                <p>4.1.5.you must not expressly or impliedly impersonate another Member or use the profile or password of another Member at any time;</p>
                <p>4.1.6.any content that you broadcast, publish, upload, transmit, post or distribute on the Application or the Website, or in accessing the Services or using the Services (Your Content) will always be accurate, correct and up to date and you will maintain reasonable records of Your Content;</p>
                <p>4.1.7.you will not harass, impersonate, stalk, or threaten another Member (where interaction with other Members is made available to you);</p>
                <p>4.1.8.your access and use of both the Application and the Website or either of them, is limited, non-transferable, and only for the purpose of the Services;</p>
                <p>4.1.9.you will not use the Services, the Application and the Website, or either of them, in connection with any commercial endeavours except those that are specifically endorsed or approved by MoveIt4u™;</p>
                <p>4.1.10.you will not use the Services, Application and the Website, or either of them, for any illegal or unauthorised use, such as, the collection of email addresses of Members by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Application and the Website, or either of them;</p>
                <p>4.1.11.commercial advertisements, affiliate links and other forms of solicitation may be removed from Member profiles without notice and may result in your access to and use of the Services being terminated. Appropriate legal action will be taken by Moveit4u™ for any illegal or unauthorised use of the Application and the Website or either of them; and</p>
                <p>4.1.12.any automated use of the Application or the Website or the Services is prohibited.</p>


                <h4>5.Using the Application as the Transporter</h4>
                <p>5.1.The Transporter is required to undertake the following steps to use the Application:</p>
                <p>5.1.1.The Transporter will register on the Website as a Member/Transporter and will be required to disclose certain information such as a current driver’s licence number, relevant vehicle registration details, insurance details and relevant compliance details.</p>
                <p>5.1.2.The Transporter is then required to download the Application.</p>
                <p>5.1.3.By using the Application, a Transporter may then bid on any Transport Services sought by an Enquirer.</p>
                <p>5.1.4.If the Transporter is successful in its bid, the Transporter will accept the Enquirer’s payment terms and the Transporter will undertake the Transport Services as instructed by the Enquirer.</p>
                <p>5.1.5.The completion of Transport Services is established by the Transporter updating the Application to indicate “completion”.</p>
                <p>5.1.6.On completion, Moveit4u™ will issue an invoice to the Enquirer. The invoice is to be paid by the Enquirer within seven (7) days from the date of the invoice (the Payment Date).</p>
                <p>5.1.7.If an Invoice is not paid by the Payment Date, MoveIt4u™ reserves the right to charge interest on unpaid invoices at a rate 2% higher than the rate fixed under section 2</p>
                <p>5.1.8.Moveit4u™ will make payment to the Transporter within fourteen (14) days of Moveit4u™ receiving payment of its invoice referred to in clause 5.1.6 above.</p>

                <h4>6.Using the Application as the Enquirer</h4>
                <p>6.1.The Enquirer is required to undertake the following steps to use the Application:</p>
                <p>6.1.1.The Enquirer will register as a Member/Enquirer on the Website.</p>
                <p>6.1.2.The Enquirer will use the Website and the Application to post details of the Transport Services it seeks Transporters to bid on.</p>
                <p>6.1.3.By the Enquirer accepting bids from the Transporter, the Enquirer also accepts the Transporter's waiting time fee schedule.</p>
                <p>6.1.4.The Enquirer will provide the Transporter with all information necessary for the completion of the Transport Services.</p>
                <p>6.1.5.On the Transporter completing the Transport Services, Moveit4u™ will issue an invoice to the Enquirer. The Enquirer will pay the invoice within seven (7) days from the date of the invoice (the Payment Date).</p>
                <p>6.1.6.If an Invoice is not paid by the Payment Date, Moveit4u™ reserves the right to charge interest on unpaid invoices at a rate 2% higher than the rate fixed under section 2</p>
                <p>6.1.7.MoveIt4u™ will make payment to the Transporter within fourteen (14) days of MoveIt4u™ receiving payment of its invoice referred to in clause 6.1.5 above.</p>

                <h4>7.Transporters' Obligations</h4>
                <p>7.1.MoveIt4u™ accepts no responsibility for any vehicles of the Transporter or any of the Transport Services provided by the Transporter whether or not the vehicles comply with clause 7.1 above.</p>
                <p>7.2.Transporters must hold the following current insurance policies:</p>
                <p>7.2.1. Workers Compensation;</p>
                <p>7.2.2. Marine Transit Insurance for such cover as stated on the Website from time to time;</p>
                <p>7.2.3. Public Liability Insurance for such cover as stated on the Website from time to time;</p>
                <p>7.2.4.Comprehensive Insurance cover with the Transporter as policyholder and with all drivers being named insured drivers for such amounts as stated on the Website from time to time; and</p>

                <p>7.2.5. Such other insurance which may be stipulated by law from time to time.</p>

                <p>7.3.At the request of MoveIt4u™, the Transporter must produce evidence of its compliance with the provisions of this clause.</p>

                <h4>8.Enquirers' Acknowledgements and Obligations</h4>
                <p>8.1.As a party in the supply chain,  Enquirers acknowledge that they have an obligation to ensure breaches of road transport laws do not occur. Duty holders need to make sure that their action or inaction does not contribute to or encourage breaches of the law. If a party’s actions, inactions or demands cause or contribute to an offence, they can be held legally accountable. Enquirers acknowledge such obligations and undertake to comply with them.</p>
                <p>8.2.As consignor or consignee or an entity commissioning the carrying of goods the Enquirers’ responsibilities include ensuring that:</p>
                <p>loads do not exceed vehicle mass or dimension limits</p>
                <p>goods carried on the Enquirers’ behalf are able to be appropriately secured</p>
                <p>operators carrying freight containers have a valid Container Weight Declaration</p>
                <p>delivery requirements do not require or encourage drivers to:</p>
                <p>exceed the speed limits</p>
                <p>exceed regulated driving hours</p>
                <p>fail to meet the minimum rest requirements</p>
                <p>drive while impaired by fatigue.</p>
                <p>8.3.Enquirers acknowledge that Transporters are not common carriers and have none of the obligations or liabilities of a common carrier. Transporters reserve the right to accept or refuse the carriage of any of the Enquirers’ Goods at their discretion.</p>

                <h4>9.Payment and Refunds</h4>
                <p>9.1.By MoveIt4u™ offering the Services to you, you agree to pay to MoveIt4u™ a subscription fee and transaction fee.</p>
                <p>9.2. If a subscription fee or a transaction fee is not paid by the date stated on the invoice, MoveIt4u™ reserves the right to charge interest on unpaid invoices at a rate 2% higher than the rate fixed under section 2</p>
                <p>9.3.By agreeing to these Terms you agree to be bound by any requirement of you to make payment in accordance with clauses 9.1 and 9.2 above. Any such payment made is non-refundable.</p>
                <p>9.4.All payments made in the course of your use of the Services are made using, electronic funds transfer, or credit card payment, or any other preferred payment method designated in your Account, on Moveit4u™ issuing an invoice. In using the Application and/or the Website, the Services, or when making any payment in relation to your use of the Services, you warrant that you have read, understood and agree to be bound by these Terms.</p>
                <p>9.5.As Moveit4u™ is only a facilitator in introducing the Transporter to the Enquirer and provides a system for the making of safe payments between them, MoveIt4u™ will not be held responsible and does not accept liability to the Transporter or the Enquirer save as set out in these Terms.</p>
                <p>9.6.The Transporter indemnifies MoveIt4u™ against any liability whatsoever for any claim relating to or arising out of unpaid invoices owed by the Enquirer.</p>
                <p>9.7.Notwithstanding the preceding clause, if an Enquirer is dissatisfied with the Transport Services provided by the Transporter or believes that they may be entitled to a refund, then the Enquirer agrees to undertake the following:</p>
                <p>9.7.1.namely, to first contact the Transporter directly to request a refund; and</p>
                <p>9.7.2.if on contacting the Transporter the Enquirer is not successful in resolving the issues between them within a period of fourteen (14) days, then the Enquirer will contact MoveIt4u™ through the 'Contact Us' section of the Application outlining why the Enquirer believes the Enquirer is entitled to a refund, from the Transporter, so that MoveIt4u™ is able to determine if the Transporter or the Enquirer or both should be removed from the Services and as a Member.</p>
                <p>9.8.If a Transporter is contacted by an Enquirer who is requesting a refund pursuant to the preceding clause, the Transporter agrees that it will immediately:</p>
                <p>9.8.1.complete the MoveIt4u™ refund request form (the Request Form) provided on the Application; and</p>
                <p>9.8.2.provide both the Request Form and the email from the Enquirer requesting the refund to MoveIt4u™.</p>
                <p>9.9.If the Transporter agrees to a refund, the Transporter will instruct MoveIt4u™ to refund all or part of the payments made to the Transporter directly, less any fees and charges incurred by MoveIt4u™ in processing the refund.</p>
                <p>9.10.Both the Transporter and the Enquirer agree that they will comply with the Refund Policy .</p>
                <p>9.11.You agree to indemnify MoveIt4u™ against any liability relating to or in any way arising out of any loss or damage suffered as a result of you not receiving a refund or the issuing by you of a refund.</p>

                <h4>10.Security of Information</h4>
                <p>No data transmission can be guaranteed as secure. While we strive to protect such information, we do not warrant and cannot ensure the security of any information which you transmit to us. Accordingly, any information which you transmit to us is transmitted at your own risk. Nevertheless, once we receive your transmission, we will take reasonable steps to preserve the security of such information.</p>

                <h4>11.Third party products or services</h4>
                <p>Members and third parties are not permitted to advertise or promote their products or services, or the products or services of others, on any part of the Website or the Application; without prior written consent from Moveit4u™.</p>

                <h4>12.Transporter/Driver to act Professionally</h4>
                <p>12.1.Transporters/Drivers are to act professionally at all times whilst providing the Transport Services for the Enquirer.</p>
                <p>12.2.Should Moveit4u™ become privy to information indicating that a Transporter/Driver has not been acting professionally, MoveIt4u™ has the express right to terminate the use of the Services by the Transporter, without notice to the Transporter/Driver.</p>

                <h4>13.No Liability for Loss/Damage as a result of  Transporter's Actions</h4>
                <p>13.1.MoveIt4u™ is a facilitator of the Services bringing the Enquirer and the Transporter together.</p>
                <p>13.2.The Transporter is responsible for the provision of the Transport Services and is responsible for the carriage and delivery of the goods in accordance with the requirements of the Enquirer.</p>

                <h4>14.Member-Generated Content</h4>
                <p>14.1.As part of becoming a Member, you are required to input certain information into the Application and/or the Website (Member Content).</p>
                <p>14.2.We have the right, but not the obligation, to monitor and review Member Content from time to time.</p>
                <p>14.3.Notwithstanding our right to monitor and review any Member Content, we are not obliged to do so. We accept no responsibility or liability for any Member Content. In particular, you acknowledge and agree that we do not authorise, condone or endorse any Member Content of any other Member, and are not responsible for the accuracy, legality or decency of such content. You are solely responsible for verifying the veracity of any information made in any Member Content.</p>
                <p>14.4.We are not responsible for how other Members may use or see your Member Content, such as a Transporter’s (drivers) profile, and other Members relying on such Member Content to engage in the Services.</p>
                <p>14.5.You are responsible for all Member Content that you submit and rely on and you further agree to indemnify Moveit4u™ against any loss or damage associated with or arising out of this.</p>

                <h4>15.Liability</h4>
                <p>15.1.Moveit4u™ accepts liability for the Services that it provides to you subject to these Terms. Transporters are responsible for the Transport Services they provide to Enquirers. Enquirers are responsible for their obligations under these Terms including payments for the Transport Services provided to them by Transporters.</p>
                <p>15.2.To the extent permitted by law, Moveit4u™ does not accept responsibility for any loss or damage (including damage of goods or goods being stolen), however caused (including through negligence), which you may directly or indirectly suffer in connection with or arising out of the use of the Services including acts, omissions and conduct of any person.</p>
                <p>15.3.To the extent permitted by law, Moveit4u™'s total liability in respect of all claims in connection with these Terms and the Services (whether based in negligence or any other tort, contract, statutory liability or otherwise) will not exceed the resupply of the Services to you.</p>
                <p>15.4.To the extent permitted by law, in no event will We (or our employees, agents and subcontractors) be liable to you for indirect, special or incidental, punitive, exemplary or consequential loss, costs, expenses and damages (or any loss of revenue, loss of data, loss of profits or loss of opportunity whether the losses be direct or indirect), suffered or incurred by you and arising out of or in connection with your access to or use of the Services regardless of whether liability is based on any breach of contract, tort (including negligence) or warranty, arises under statute, or any other basis of liability.</p>
                <p>15.5.You acknowledge and agree that Moveit4u™ is not liable for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you as a result of or arising out of the providing of Your Content to the Application and/or the Website.</p>
                <p>15.6.The limitation of liability set out in these Terms does not attempt or purport to exclude or alter your rights which cannot be excluded under applicable law.</p>

                <h4>16.Indemnity</h4>
                <p>16.1.You agree to indemnify and keep Us, our employees, agents and subcontractors, indemnified from and against any claims and all losses, expenses, damages and costs (including reasonable legal fees incurred on a solicitor/client basis) suffered or incurred by us, our employees, agents and subcontractors, or any third party relating to or arising out of:</p>
                <p>16.1.1.your breach of these Terms;</p>
                <p>16.1.2.all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with Your Content; and</p>
                <p>16.1.3.any direct or indirect consequences of you accessing, using or transacting on the Application and/or the Website or attempts to do so.</p>
                <p>16.2.In addition to clause 16.1 above, you agree to indemnify and hold Us, our employees, agents and subcontractors, harmless against all demands, claims, causes of action, interest and costs which We, our employees, agents and subcontractors, may suffer as a result of your negligent actions and omissions.</p>
                <p>16.3.You agree to indemnify Us and our employees, agents and subcontractors, against all liability for loss, costs, damages, interest and expenses (including all legal costs incurred by us on a full indemnity basis) which may be paid, suffered or incurred by Us, our employees, agents and subcontractors, in connection with any proceedings commenced by any person against Us in any way relating to your conduct.  Any such amounts will be payable by you on demand by Us.</p>
                <p>16.4.You acknowledge and agree that MoveIt4u™ enters this clause  for its own benefit and further as trustee for any indemnified person, company or incorporated body (severally and collectively "the  indemnified person")  pursuant to this clause who is not a party to this document . The indemnified person shall be able to rely on this clause and have standing to enforce it in its own right.</p>

                <h4>17.Intellectual Property</h4>
                <p>17.1.The Application, the Website, the Services and all of the related products of Moveit4u™ are subject to copyright. The material on the Application and the Website is protected by copyright under the laws of Philippines and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Application and the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Application, code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by Moveit4u™ or its contributors.</p>
                <p>17.2.All trademarks, service marks and trade names are owned, registered and/or licensed by Moveit4u™, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to:</p>
                <p>17.2.1.use the Application pursuant to the Terms;</p>
                <p>17.2.2.copy and store the Application and the material contained in the Application in your device's cache memory; and</p>
                <p>17.2.3.print pages from the Application for your own personal and non-commercial use.</p>
                <p>17.3.Moveit4u™ does not grant you any other rights whatsoever in relation to the Application, the Website or the Services. MoveIt4u™ expressly reserves all other rights.</p>
                <p>17.4.MoveIt4u™ retains all rights, title and interest in and to the Application and all related Services. Nothing in relation to the Application will transfer any:</p>
                <p>17.4.1.business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright; or</p>
                <p>17.4.2.a right to use or exploit a business name, trading name, domain name, trade mark or industrial design; and/or</p>
                <p>17.4.3.a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process),
                to you.</p>
                <p>17.5.You may not, without the prior written permission of MoveIt4u™ and the permission of any other relevant rights owners: broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Application or the Website, which are freely available for re-use or are in the public domain.</p>
                <p>17.6.Where you broadcast, publish, upload, transmit, post or distribute Your Content on the Application, then you grant to Moveit4u™ a non-exclusive, transferrable, perpetual, royalty-free, irrevocable, worldwide licence to broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change Your Content.</p>

                <h4>18.Confidential Information</h4>
                <p>18.1.Unless otherwise indicated, the Services, the Application and the Website (including but not limited to its content, Your Content, Member Content, materials, text, graphics, logos, icons, sound recordings, video, software and advertisements) is owned or licensed by Moveit4u™.</p>
                <p>18.2.You must not:</p>
                <p>18.2.1.share any information obtained from the use of the Services, including Member Content with any other person whatsoever, except to the extent required for the performance of these Terms;</p>
                <p>18.2.2.publish or post any of the Services' content on any other website, including on social media pages or websites;</p>
                <p>18.2.3.use the Services to describe, market, endorse or promote any goods or services; and</p>
                <p>18.2.4.register or maintain any social media pages or websites that misrepresent or are reasonably likely to misrepresent any affiliation with, or endorsement by MoveIt4u™.</p>
                <p>18.3.We may, from time to time, monitor your use of the Services, the Application and the Website to determine if you are in breach of these Terms. Such monitoring may include:</p>
                <p>18.3.1.the frequency and nature of any downloads; and</p>
                <p>18.3.2.the time of access and IP addresses used to access the Application and the Website.</p>

                <h4>19.Competitors and Clients of MoveIt4u™</h4>
                <p>19.1.If you carry on a business providing a similar service to the Services for a commercial/financial gain in whatever capacity and to whomever, then you are deemed to be a competitor of Moveit4u™ (Competitor).</p>
                <p>19.2.Moveit4u™ does not permit any Competitor to use or access any information or content on the Application and the Website. If MoveIt4u™ discovers that you are a Competitor and have become a Member, you will be held liable for any loss or damage suffered by MoveIt4u™ as a result of or arising out of this.</p>
                <p>19.3.You further agree, that at all times throughout the duration of membership and after the termination of these Terms, you will not for any reason:</p>
                <p>19.3.1.Use any information accessed by you through the Application or the Website, including any intellectual property and confidential information belonging to Moveit4u™ to your own advantage or the advantage of any other person, company or entity without the prior written consent of MoveIt4u™;</p>
                <p>19.3.2.Disclose any information as accessed by you through the Application or the Website, including any intellectual property and confidential information belonging to MoveIt4u™ to any other person, company or entity without the prior written consent of Moveiit4u™;</p>
                <p>19.3.3.Solicit, attempt to solicit (via phone, email, postal mail, Social Media or otherwise), entice or encourage any client of MoveIt4u™ to leave the Services.; and</p>
                <p>19.3.4.Solicit or attempt to solicit (via phone, email, postal mail, Social Media or otherwise) any client of MoveIt4u™ for private use, including but not limited to, contacting any client of MoveIt4u™ directly to provide the Services and not using the Application.</p>
                <p>19.3.5.For the purposes of this clause, a client of MoveIt4u™ is defined as any Member of the Application and/or Website.</p>

                <h4>20.Variation</h4>
                <p>20.1.You agree to be bound by the latest version of the Terms. MoveIt4u™ may amend the Terms, or any policies, such as for example minimum amounts of insurance cover or privacy, related to the Services from time to time. Moveit4u™ will provide you with at least 30 days' written notice in the event of a material change to any Terms, or policies that detrimentally affects your rights under these Terms. Amendments will be effective upon MoveIt4u™'s posting of such updated Terms at this location or the amended policies on the Website. Your continued access or use of the Services after such posting, or after the expiry of the notice period (whichever is later), constitutes your consent to be bound by the Terms, as amended.</p>

                <h4>21.Personal Information Collection Notice</h4>
                <p>21.1.In registering for access or using the Services, you may be required to give us certain personal information in which you have certain rights. Personal information relating to you which we collect, use and disclose may include sensitive information, such as information about your personal details.</p>
                <p>21.2.We collect, use and disclose your personal information in accordance with our Privacy Policy. You consent to your personal information being used and/or disclosed for any reasons given to you by us (at the time of collection) in relation to the use and disclosure of your personal information, including the reasons set out in our Privacy Policy.</p>
                <p>21.3.Our Privacy Policy, amongst other things, sets out the procedure for accessing the personal information we hold about you, and for you to have such information corrected, as well as the way you can notify us about a breach of the privacy laws.</p>

                <h4>22.Termination</h4>
                <p>22.1.If you want to terminate the Terms, you may do so by providing MoveIt4u™ with 30 days’ notice of your intention to terminate. This can be done by sending us notice of your intention to terminate via the “Contact Us” link on the Website.</p>
                <p>22.2.Moveit4u™ may at any time, terminate the Terms with you if:</p>
                <p>22.2.1.you have breached any provision of the Terms or intend to breach any provision;</p>
                <p>22.2.2.MoveIt4u™ is required to do so by law;
                <p>22.2.3.MoveIt4u™ is transitioning to no longer providing the Services to Members in the area in which you are resident or from which you use the Services; or</p>
                <p>22.2.4.the provision of the Services to you by MoveIt4u™ is, at the sole discretion of MoveIt4u™, no longer commercially viable.</p>
                <p>22.3.Subject to local applicable laws, MoveIt4u™ reserves the right to discontinue or cancel your membership at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Application, the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts MoveIt4u™’s name or reputation or violates the rights of those of another party.</p>
                <p>22.4.When the Terms come to an end, all of the legal rights, obligations and liabilities that you and MoveIt4u™ have benefited from, been subject to (or which have accrued over time whilst the Terms have been in force) or which are expressed to continue indefinitely, shall be unaffected by this cessation, and the provisions of this clause shall continue to apply to such rights, obligations and liabilities indefinitely.</p>

                <h4>23.Dispute Resolution</h4>
                <p>23.1.If a dispute arises out of or relates to the Terms or the Services (including any dispute as to breach or termination of the Terms or Services or as to any claim in tort, in equity or pursuant to any statute) neither party may commence any Court or arbitration proceedings relating to the dispute unless they have complied with this clause except where they seek urgent interlocutory relief.</p>
                <p>23.2.The party claiming that a dispute has arisen under or in relation to the Terms or Services must give written notice to the other party specifying the nature of the dispute.</p>
                <p>23.3.On receipt of the notice referred to in this clause by that party, both parties must endeavour to either resolve the dispute expeditiously using informal dispute resolution techniques such as mediation, expert evaluation, or determination or similar techniques agreed by them.</p>
                <p>23.4.If the parties do not agree within seven days of receipt of the notice (or such further period as agreed in writing by them) as to:</p>
                <p>23.4.1.The dispute resolution technique and procedures to be adopted;</p>
                <p>23.4.2.The timetable for all steps in those procedures; and</p>
                <p>23.4.3.The selection and compensation of the independent person required for such technique,</p>
                then they must mediate the dispute.</p>
                <p>23.5.The Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed or his or her nominee.</p>
                <p>23.6.The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a pre-condition to the mediation commencing. The Parties must each pay their own costs associated with the mediation.</p>
                <p>23.7.The mediation will be held in Philippines.</p>
                <p>23.8.If the mediation referred to above is not completed within four weeks of reference to a mediator either party may commence any court or arbitration proceedings relating to the dispute as they see fit.</p>
                <p>23.9.All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</p>

                <h4>24.Survival</h4>
                <p>24.1.Without limiting clause 22.4 above, the terms underneath the following headings survive the termination and expiry of these Terms: "Payments and Refunds", "Security of Information" “No Liability For Loss/Damage As A Result Of Transporter's Actions”, “Member-Generated Content”, “Liability”, “Indemnity”, “Intellectual Property”, “Confidential Information”, and "Dispute Resolution".</p>

                <h4>25.Governing Law & Jurisdiction</h4>
                <p>25.1.The law of South Australia governs these Terms, and the parties, submit to the non-exclusive jurisdiction of the courts of that State.</p>

                <h4>26.General</h4>
                <p>26.1.These Terms constitute the entire agreement between you and MoveIt4u™ and govern your use of the Application and/or the Website and supersede any prior version of these Terms between you and us with respect to the Application, the Services and/or the website.</p>
                <p>26.2.Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver or relinquishment to any extent of our right to assert or rely upon any such provision or right in that or any other instance, and the same shall be and remain in full force and effect.</p>
                <p>26.3.In these Terms references to the parties is to be read as references to or including, as appropriate, their respective successors, transferees and assigns as well as employees, partners, agents and related bodies corporate.</p>
                <p>26.4.If a provision, or part of a provision, of these Terms are void or voidable that provision is severable and the remainder of these Terms has full force and effect.</p>
                <p>26.5.Use of the Application, the Website and the Services is at your own risk. Everything on the Application, the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of Moveit4u™ make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of Moveit4u™) referred to on the Application and/or the Website. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:</p>
                <p>26.5.1.failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;</p>
                <p>26.5.2.the accuracy, suitability or currency of any information on the Application, the Website, the Services, or any of its Services related products (including third party material and advertisements on the Application and the Website);</p>
                <p>26.5.3.costs incurred as a result of you using the Application, the Services or any of the products of Moveit4u™; and</p>
                <p>26.5.4.the Services or operation in respect to links which are provided for your convenience.</p>
                <p>26.6.You acknowledge that the Moveit4u™ Application and/or the Website and the Services are only intended to facilitate the interactions between the Transporter and the Enquirer and does not offer any services other than the Services and that MoveIt4u™ is not liable to you as a result of or arising out of any conduct of the Members or the misuse of Your Content and Member Content or either of them by any party (including other Members).</p>
    </>
        )}