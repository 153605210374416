export const QUOTES_WILDCARDS_KEYS = {
  SHOW_TYPE: 'showType',
};

export const PENDING_QUOTES = 'pending';

export const FAILED_QUOTES = 'failed';

export const ACTIVE_QUOTES = 'active';

export const SUCCESSFUL_QUOTES = 'successful';


export const PENDING = 'pending';

export const FAILED = 'failed';

export const ACTIVE = 'active';

export const SUCCESSFUL = 'successful';


