import { Grid, Typography, Divider, Button, TextField, makeStyles, FormControl, InputLabel, Select, MenuItem, setRef, Box } from '@material-ui/core'
import { GridRowParams } from '@material-ui/data-grid'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { Warehouse } from '../../../../Api/Warehouse/Warehouse'
import CustomTable from '../../../Common/CustomTable/CustomTable'
import { AppState } from '../../../../Redux'
import { MobileDatePicker, DateRangePicker } from "@material-ui/pickers";
import { saveAs } from 'file-saver';
import { setLoader } from '../../../../Redux/Loader/actions'
import Swal from 'sweetalert2'

import { getColumns } from './StocksPage.columns'
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import LazyTable from '../../../Common/LazyTable/LazyTable';
import { USER_ROLES } from '../../../../Types/Constants';
import SitesSelection from './SitesSelection'
import StocksDialog from '../../../Dialogs/StocksDialog/StocksDialog'
import CollapsibleTable from './SpanningTable'
import { formatDate } from '../../../../Utilities/DateTime'
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'
const warehouseApi = new Warehouse()
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    },
    container: {
        padding: '20px'
    }
}))

function StocksLedger() {
    const [t] = useTranslation(['Warehouse', 'Columns'])
    const classes = useStyles()
    const columns = getColumns(t)

    const dispatch = useDispatch()
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | number | null>(null)

    const [viewDialog, setViewDialog] = useState(false);
    const [dialogMode, setDialogMode] = useState('');

    const user = useSelector((state: AppState) => state.user.response);
    const { ADMIN_USER, SUPER_ADMIN, WAREHOUSE, CUSTOMER } = USER_ROLES;
    const isCustomer = [CUSTOMER].includes(user?.type);

    let userId = user.company.id
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user?.type);
    const [rowData, setRowData] = useState<any[]>([])
    const [selectedMoveIo, setSelectedMoveIo] = useState<any>('')
    const [selectedSite, setSelectedSite] = useState<any>(null)

    const [selectedFromDate, setSelectedFromDate] = useState<Date | undefined>(null)
    const [selectedToDate, setSelectedToDate] = useState<Date | undefined>(null)
    const [onClearCallbackTrigger, setOnClearCallbackTrigger] = useState(0)

    const [selectedDateRange, handleDateRangeChange] = useState<[Date | null, Date | null]>([null, null]);


    const isWarehouse = [WAREHOUSE].includes(user?.type);
    if (isCustomer) { userId = user.customer.id }
    if (isWarehouse) { userId = user.warehouse.id }


    const getAll = async () => {

        return warehouseApi.getLedgerProductList(userId, selectedMoveIo, selectedFromDate, selectedToDate, selectedSite)
            .then((response) => {
                setRowData(response.data)

            })
            .catch((err: any) => {
                toast.error(t('error_getting_stocks'));
                return []

            });

    }


    useEffect(() => {
        getAll()
    }, [])
    const handleOnApplyClear = useCallback(() => {
        setOnClearCallbackTrigger(onClearCallbackTrigger + 1)
        setSelectedSite(null)
        setSelectedMoveIo('')
        setSelectedFromDate(null)
        setSelectedToDate(null)
        getAll()
    }, [])
    const handleDialogclose = useCallback(
        () => {
            setViewDialog(false)
            getAll()
        },
        [selectedCustomerId],
    )
    const onAddClick = () => {
        setDialogMode('add')
        setViewDialog(true)
    }
    const filterData = () => {
        getAll()
    }

    const filterCallback = useCallback((site_id: any) => {
        setSelectedSite(site_id)
    }, [])


    const onRowClick = useCallback((data: any) => {
        if (data.row.status != 'out') {
            Swal.fire({
                title: "Remove stock from the warehouse?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#303f9f",
                cancelButtonColor: "#f50057",
                confirmButtonText: "Yes, please"
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(setLoader(true))

                    warehouseApi.markOut(data.row.id)
                        .then(() => {
                            dispatch(setLoader(false))
                            toast.success(t('stock_is_out'))
                            getAll()
                        })
                        .catch((err: any) => {
                            toast.error(t('stock_update_error'))
                            dispatch(setLoader(false))
                        })
                }
                dispatch(setLoader(false))

            });
        }
    }, [userId, selectedMoveIo, selectedFromDate, selectedToDate, selectedSite])
    const handleDateRange = (dateRange: [Date | null, Date | null]) => {
        handleDateRangeChange(dateRange);
    };

    const generateCSV = useCallback(() => {
        const transformedData = rowData.map(item => ({
            'Company Name': item.company.name,
            'Batch Code': item.batch_code,
            'Status': item.status,
            'In': item.createdAt,
            'Out': item.out_date || '',  // Assuming out_date can be null
            'Product Name': item.stock.product_name,
            'Product Code': item.stock.product_code,
            'Quantity': item.quantity,
        }));

        const csvRows = [
            ['Company Name', 'Batch Code', 'Status', 'In', 'Out', 'Product Name', 'Product Code', 'Quantity'],
            ...transformedData.map(row => [
                row['Company Name'],
                row['Batch Code'],
                row['Status'],
                row['In'],
                row['Out'],
                row['Product Name'],
                row['Product Code'],
                row['Quantity'],
            ]),
        ]
            .map(e => e.join(','))
            .join('\n');

        const blob = new Blob([csvRows], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'StockLedger.csv');
    }, [rowData])
    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("warehouse_management")} title={t('stock_ledger')} />

               

                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">

                        <Grid md={12} spacing={2} item container>
                            <Grid md={2} item >
                                <SitesSelection onChangeCallback={filterCallback} onClearCallback={onClearCallbackTrigger} />
                            </Grid>
                            <Grid item md={2}>
                                <FormControl variant="filled" className={classes.formControl}>
                                    <InputLabel style={{ fontWeight: "bold" }}>
                                        {t("type")}
                                    </InputLabel>
                                    <Select
                                        value={selectedMoveIo}
                                        onChange={(e) => {
                                            setSelectedMoveIo(e.target.value)
                                        }}
                                    >
                                        <MenuItem value='in'>In</MenuItem>
                                        <MenuItem value='out'>Out</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item container spacing={1} direction='row' md={6}>
                                <Grid md={3} item>
                                    <MobileDatePicker
                                        label={'From:'}
                                        value={selectedFromDate}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={setSelectedFromDate}
                                        renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                    />
                                </Grid>
                                <Grid md={3} item>
                                    <MobileDatePicker
                                        minDate={selectedFromDate}
                                        label={'To:'}
                                        value={selectedToDate}
                                        inputFormat="dd/MM/yyyy"
                                        onChange={setSelectedToDate}
                                        renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                    />
                                </Grid>
                                <Grid item container spacing={2} md={6} style={{ alignItems: 'center' }}>

                                    <Grid md={3} item>
                                        <Button onClick={handleOnApplyClear} color="primary" variant="contained">
                                            Clear
                                        </Button>
                                    </Grid>
                                    <Grid md={3} item>

                                        <Button onClick={filterData} color="primary" variant="contained">
                                            {t('apply')}
                                        </Button>
                                    </Grid>
                                    {rowData.length > 0 &&
                                        <Grid md={6} item>
                                            <Button onClick={generateCSV} color="default" variant="contained">Download CSV</Button>

                                        </Grid>
                                    }
                                </Grid>


                            </Grid>
                            {/* <Grid item md={2}>
                                <Button
                                    onClick={filterData}
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                >
                                    {t('filter')}
                                </Button>
                            </Grid> */}

                        </Grid>


                        <Grid item md={12}>
                            <CustomTable
                                onRowClick={onRowClick}
                                columns={columns}
                                rows={rowData}
                            />
                        </Grid>
                        <StocksDialog open={viewDialog} handleClose={handleDialogclose} dialogMode={dialogMode} />
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}

export default StocksLedger