import {  Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import { Divider, Grid, Typography, MenuItem, FormControl, InputLabel, Select, makeStyles, Button, FormControlLabel, Checkbox ,Accordion , AccordionSummary ,AccordionDetails  } from "@material-ui/core"
import { IAccordionJobProps } from "./Accordion.types";
import MultiAutocomplete from "../../../Common/MultiAutocomplete/MultiAutocomplete"

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useEffect, useState } from 'react'
import { IAutocompleteOption } from "../../../Common/types"




const toValue = (opt: IAutocompleteOption): string => opt.value

function AccordionJobs(props : IAccordionJobProps)  {
    const { type , country , selectedClassification , job_types_option  , handlePerchange , existingConfig} = props

    const [t] = useTranslation('')
    const [selectedJobTypes, setSelectedJobTypes] = useState<IAutocompleteOption[]>([])
    

    useEffect(()=>{
        if(Object.keys(existingConfig).length){
           const selectedJobType = existingConfig.job_types[type] ? existingConfig.job_types[type].map((val:any) : IAutocompleteOption =>{return val}) :[]
           setSelectedJobTypes(selectedJobType)
           handlePerchange( selectedJobType , type)
        }


    },[existingConfig])
    const onChange =async (data : any)=>{
        
        await setSelectedJobTypes(data)
        handlePerchange( data , type)
    }

    const toOption = useCallback(
        (val: string): IAutocompleteOption => {

            return {
                label: t(val),
                value: val
            }
        },
        [t],
    )

              return <>
              <Grid md={12} item>
                    <MultiAutocomplete
                        disabled={country === '' && selectedClassification === ''}
                        label={t('job_type')}
                        options={job_types_option.map(toOption)}
                        values={selectedJobTypes}
                        onChange={(values) => onChange(values)}
                    />
                </Grid>
    
         </>
        
        
    
}

export default AccordionJobs