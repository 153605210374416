import {Typography} from '@material-ui/core';
import {GridColumns} from '@material-ui/data-grid';
import {TFunction} from 'i18next';
import { formatDateTimeDisplay} from '../../../../Utilities/DateTime'
import {IUserState} from '../../../../Redux/User/type';
import {COMPANY_TYPES, USER_ROLES} from '../../../../Types/Constants';
import moment from 'moment'

export const getColumns = (t: TFunction ,  user: IUserState): GridColumns => {
  const {company, type} = user?.response || {
    company: undefined,
    type: undefined,
  };

  let isTransportCompany = true;
  let isShippingLine = false;

  let hideTransport = true
  const {SUPER_ADMIN, ADMIN_USER} = USER_ROLES;

  if (typeof company !== 'undefined' &&company.companyType === COMPANY_TYPES.TRANSPORT_COMPANY) {
    isTransportCompany = true;
    hideTransport = true
  }

  if (typeof company !== 'undefined' &&company.companyType === COMPANY_TYPES.SHIPPING_LINE) {
    isShippingLine = true;
    hideTransport = false
  }

  const isUserAdmin = [SUPER_ADMIN, ADMIN_USER].includes(user.response?.type ?? '');
  const hideCompany = isUserAdmin || isShippingLine ? true : false;
  return [
    {
      field: 'reference',
      headerName: t('Columns:reference'),
      width: 200,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        const job = params.row
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'company',
      headerName: t('Columns:company'),
      width: 250,
      resizable: true,
      hide : hideCompany,
      renderCell: (params) => {
        const jobInfo = params.row;
        const company = jobInfo.company?.name;
        return <Typography>{company}</Typography>;
      },
    },
    {
      field: 'transport_company',
      headerName: t('Columns:transport_company_only'),
      width: 250,
      resizable: true,
      hide : !hideCompany,
      renderCell: (params) => {
        const jobInfo = params.row;
        const company = jobInfo.assigned_user?.company?.name;
        return <Typography>{company}</Typography>;
      },
    },
    {
      field: 'driver',
      headerName: t('Columns:driver'),
      width: 200,
      resizable: true,
      hide : !hideCompany,
      renderCell: (params) => {
        const jobInfo = params.row;
        const firstName = jobInfo.assigned_user?.first_name || ''
        const lastName = jobInfo.assigned_user?.last_name || ''
        const driver = firstName.concat(' ' , lastName)
      
        // const driver = jobInfo.assigned_user?.first_name != undefined ? jobInfo.assigned_user?.first_name : "" + " " + jobInfo.assigned_user?.last_name != undefined ? jobInfo.assigned_user?.last_name :"" ;
        return <Typography>{driver}</Typography>;
      },
    },
    {
      field: 'job_type',
      headerName: t('Columns:job_type'),
      width: 200,
      renderCell: (params) => {
           const jobInfo = params.row;

          return (
              <Typography>
                  {t(jobInfo?.job_type)}
              </Typography>
          )
      }
    },
    {
      field: 'container_no',
      headerName: t('Columns:container_no'),
      width: 200,

    },
    {
      field: 'no_days_till_last_free_day',
      headerName: t('no_days_till_last_free_day'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        var start = moment();
        var end = moment( jobInfo.cntr_last_day, "YYYY-MM-DD");
        const datediff  = start.diff(end, 'days');
        return <Typography>{Math.abs(datediff) || 'Pending'}</Typography>;
      },
    },
    
    {
      field: 'cntr_last_day',
      headerName: t('cntr_last_free_day'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const cntr_last_day = jobInfo.cntr_last_day;
        return <Typography>{formatDateTimeDisplay(cntr_last_day)}</Typography>;
      },
    }
  ];
};
