import * as React from 'react';
import {IRatesDialogProps} from './RatesTable.props'
import { Button, Dialog,DialogActions , DialogContent , DialogContentText ,DialogTitle } from "@material-ui/core";

export default function RatesDialog(props:IRatesDialogProps) {
    const {open , handleClose , handleSubmit} = props

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Overwrite default configuration?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Default Rates has already been set. Do you want to overwrite existing configuration?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained"  color="primary" autoFocus>
            Overwrite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
