import { Grid, Typography, Divider } from '@material-ui/core'
import { GridPageChangeParams, GridRowParams } from '@material-ui/data-grid'
import { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { IUserInfo } from '../../../../../../Application/DTOs/UsersDto/UsersDto.type'
import { Pageable } from '../../../../../../types/pageable'
import { Driver } from '../../../../Api/Driver/Driver'
import { AppState } from '../../../../Redux'
import { USER_ROLES } from '../../../../Types/Constants'
import PaginatedTable from '../../../Common/PaginatedTable/PaginatedTable'
import DriverDetailsDialog from '../../../Dialogs/DriverDetailsDialog/DriverDetailsDialog'
import { getDriverManagementColumns } from './DriverManagementPage.columns'
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'

const {
    ADMIN_USER
} = USER_ROLES

const driverApi = new Driver()

function DriverManagementPage() {
    const [t] = useTranslation('DriverManagementPage')
    const user = useSelector((state: AppState) => state.user)
    const isUserAdmin = user.response?.type === ADMIN_USER
    const [isTableFetching, setIsTableFetching] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [pageSize, setPageSize] = useState(5)
    const [totalCount, setTotalCount] = useState(0)
    const [users, setUsers] = useState<IUserInfo[]>([])
    const [selectedUserId, setSelectedUserId] = useState<string | number | undefined>(undefined)
    const [isDriverDetailsDialogOpen, setIsDriverDetailsDialogOpen] = useState(false)



    const handlePageChange = useCallback(
        (params: GridPageChangeParams) => {
            setCurrentPage(params)
        },
        [],
    )

    const handlePageSizeChange = useCallback(
        (params: GridPageChangeParams) => {
            setPageSize(params)
        },
        [],
    )

    const getApprovedDrivers = useCallback(
        (currentPage: number, pageSize: number) => {
            setIsTableFetching(true)
            driverApi.getApprovedDrivers(currentPage, pageSize, undefined, isUserAdmin ? user.response?.country : undefined)
                .then((response) => {
                    const data: Pageable<IUserInfo> = response.data

                    setTotalCount(data.total)
                    setUsers(data.rows)

                    setIsTableFetching(false)

                })
                .catch((err: any) => {
                    setIsTableFetching(false)
                    setUsers([])
                    console.log('err', err)
                    toast.error(t('error_getting_drivers_waiting_for_approval'))
                })
        },
        [t, isUserAdmin, user.response?.country]
    )

    const handleDriverDetailsDialogClose = useCallback(
        () => {
            setIsDriverDetailsDialogOpen(false)
            getApprovedDrivers(currentPage + 1, pageSize)
        },
        [getApprovedDrivers, currentPage, pageSize]
    )

    useEffect(() => {
        getApprovedDrivers(currentPage + 1, pageSize)

    }, [getApprovedDrivers, currentPage, pageSize])

    const onRowClick = useCallback(
        (rowParams: GridRowParams) => {
            const user: IUserInfo = rowParams.row as IUserInfo
            setSelectedUserId(user.id)
            setIsDriverDetailsDialogOpen(true)
        },
        [],
    )

    const handleOnContactableChange = useCallback(
        (userId: string | number, isChecked: boolean, type: 'sms' | 'email') => {
            let payload: any = {}

            if (type === 'sms') {
                payload = {
                    is_sms_contactable: isChecked
                }
            } else if (type === 'email') {
                payload = {
                    is_email_contactable: isChecked
                }
            }


            setIsTableFetching(true)
            driverApi.update(userId, payload)
                .then(() => {
                    getApprovedDrivers(currentPage + 1, pageSize)
                    setIsTableFetching(false)
                })
                .catch((err: any) => {
                    setIsTableFetching(false)
                    console.log('err', err)
                    toast.error(t('error_updating_driver_contactable'))
                })
        },
        [getApprovedDrivers, currentPage, pageSize]
    )

    const columns = getDriverManagementColumns(t, handleOnContactableChange)

    return (
        <>
            <DriverDetailsDialog selectedUserId={selectedUserId} open={isDriverDetailsDialogOpen} handleClose={handleDriverDetailsDialogClose} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("driver_management")} title={t('driver_management')} />
            
               
                <Grid item>
                    <Grid
                        container
                    >
                        <Grid item md={12}>
                            <PaginatedTable
                                columns={columns}
                                currentPage={currentPage}
                                loading={isTableFetching}
                                onPageChange={handlePageChange}
                                onPageSizeChange={handlePageSizeChange}
                                pageSize={pageSize}
                                rows={users}
                                totalCount={totalCount}
                                onRowClick={onRowClick}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )

}

export default DriverManagementPage