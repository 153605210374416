import { WebStorage } from "../../Utilities/WebStorage"
import axios from "axios"
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"
import { ICustomerInfo } from "../../../../Application/DTOs/CustomerDto/CustomerDto.types";

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()


export class Customer {
    create(payload: ICustomerInfo) {
        return axios.post(`${baseUrl}/customer/register`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }

    update(id: any, payload: any) {
        return axios.post(`${baseUrl}/customer/update/${id}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`

            }
        })
    }

    getAll(offset: any, limit: any, is_admin: boolean, customer_name?: string, company_id?: string, active: boolean = false) {
        return axios.get(`${baseUrl}/customer/list`, {
            params: {
                offset,
                limit,
                customer_name,
                company_id,
                is_admin,
                active
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    changePassword(id: any, password: any) {
        return axios.post(`${baseUrl}/customer/update/password/${id}`, { password }, {

            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getDetails(customer_id: any) {
        return axios.get(`${baseUrl}/customer/detail/${customer_id}`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getAddress(customer_id: any) {
        return axios.get(`${baseUrl}/customer/address/${customer_id}`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    createAddress(payload: any) {
        return axios.post(`${baseUrl}/customer/address/create`, payload, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    statusChange(customer_id: any, status: any, company_id: any) {
        return axios.post(`${baseUrl}/customer/status/update`, { customer_id, status, company_id }, {

            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }




}