import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { WebStorage } from "../../../Utilities/WebStorage";
import { TOKEN_STORAGE_KEY } from "../../../Types/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserLoginResponse } from "../../../Redux/User/actions";
import { logout } from '../../../Redux/Auth/actions';
import { routes } from "../../../routes";

const SessionExpiredDialog = ({ open, onClose }) => {
    const history = useHistory();
    const storage = new WebStorage(false);
    const dispatch = useDispatch()
    const handleLogout = () => {

        storage.clear(TOKEN_STORAGE_KEY);
        dispatch(setUserLoginResponse(null));
        dispatch(logout());
        history.push(routes.landing.login[0]);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Session Expired</DialogTitle>
            <DialogContent>Your session has expired. Please log in again.</DialogContent>
            <DialogActions>
                <Button onClick={handleLogout} color="primary">
                    Log In
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SessionExpiredDialog;
