export const TOKEN_STORAGE_KEY = 'user_token';

export const JOB_CLASSIFICATION = {
  TRANSPORT: 'transport',
  SEA_FREIGHT: 'seafreight',
};

export const COMPANY_TYPES = {
  NVOCC: 'nvocc',
  FREIGHT_FORWARED: 'freight_forwarder',
  TRANSPORT_COMPANY: 'transport_company',
  IMPORT_EXPORTER: 'import_exporter',
  SHIPPING_LINE: 'shipping_line',
};

export const COUNTRY_CODES = {
  AUSTRALIA: 'AU',
};

export const COUNTRIES = {
  PHILIPPINES: 'philippines',
  JAPAN: 'japan',
  SINGAPORE: 'singapore',
  AUSTRALIA: 'australia',
  NEW_ZEALAND: 'new_zealand'
};

export const COUNTRIES_ABBR = {
  JP: 'JP',
  PH: 'PH',
  AU: 'AU',
  SG: 'SG',
};

export const ADDRESS_GROUPS = {
  CUSTOMER: 'customer',
  DC_OR_WAREHOUSE: 'dc_or_warehouse',
  EMPTY_PARK: 'empty_park',
  PACK_OR_UNPACK_DEPOT: 'pack_or_unpack_depot',
  RAIL: 'rail',
  WHARF: 'wharf',
  RESIDENTIAL: 'residential'
};

export const MOVEMENT_TYPES = {
  EMPTY_PARK: 'empty_park',
  FULL_TRUCK_LOAD: 'full_truck_load',
  LAND_FREIGHT: 'land_freight',
  LOOSE_CARGO: 'loose_cargo',
  OTHER: 'other',
  RAIL: 'rail',
  SEA_FREIGHT: 'sea_freight',
  WHARF: 'wharf',
  FCL: 'fcl',
  LCL: 'lcl',
  ALL: 'all',
  OVERSIZED_ITEMS: 'oversized_items',
  CONTAINERS: 'containers'
};

export const DEFAULT = 'default';

export const TRIP_TYPE = {
  ONE_WAY: 'one_way',
  ROUND_TRIP: 'round_trip',
  RETURN_TRIP: 'return_trip',
  MULTI_STOP: 'multi_stop'
};

export const JOB_TYPES = {
  EMPTY_20: 'empty_20',
  EMPTY_40: 'empty_40',
  FULL_20: 'full_20',
  FULL_40: 'full_40',
  LOOSE_CARGO: 'loose_cargo',
  LINEHAUL: 'linehaul',
  REFEER_20: 'refeer_20',
  REFEER_40: 'refeer_40',
  HC_20: 'hc_20',
  HC_40: 'hc_40',
  OTHER: 'other',
  IMPORT_FULL_CONTAINER: 'import_full_container',
  EXPORT_FULL_CONTAINER: 'export_full_container',
  IMPORT_LOOSE_CARGO: 'import_loose_cargo',
  EXPORT_LOOSE_CARGO: 'export_loose_cargo',
  DOMESTIC_FULL_CONTAINER: 'domestic_full_container',
  DOMESTIC_LOOSE_CARGO: 'domestic_loose_cargo',
  BREAK_BULK: 'break_bulk',
  EMPTY: 'empty',
  PROJECT_CARGO: 'project',
  IMPORT_CONTAINER_20: '20_import_container',
  IMPORT_CONTAINER_40: '40_import_container',
  EXPORT_CONTAINER_20: '20_export_container',
  EXPORT_CONTAINER_40: '40_export_container',
  LOCAL_CONTAINER_DELIVERY_20: '20_local_container_delivery',
  LOCAL_CONTAINER_DELIVERY_40: '40_local_container_delivery',
  FIRST_MILE: 'first_mile',
  LAST_MILE: 'last_mile',
  EMPTY_CONTAINER_RETURN: 'empty_container_return',
  EMPTY_CONTAINER_COLLECTION: 'empty_container_collection',
  CONTAINER_SHIFT: 'container_shift',
  RAIL: 'rail',
  PROJECT: 'project',
};

export const JOB_TYPES_BY_COUNTRY = {
  [COUNTRIES.AUSTRALIA]: [
    JOB_TYPES.EMPTY_20,
    JOB_TYPES.EMPTY_40,
    JOB_TYPES.FULL_20,
    JOB_TYPES.FULL_40,
    JOB_TYPES.LOOSE_CARGO,
    JOB_TYPES.LINEHAUL,
    JOB_TYPES.REFEER_20,
    JOB_TYPES.REFEER_40,
    JOB_TYPES.HC_20,
    JOB_TYPES.HC_40,
    JOB_TYPES.OTHER,
  ],
  [COUNTRIES.PHILIPPINES]: [
    JOB_TYPES.IMPORT_FULL_CONTAINER,
    JOB_TYPES.EXPORT_FULL_CONTAINER,
    JOB_TYPES.IMPORT_LOOSE_CARGO,
    JOB_TYPES.EXPORT_LOOSE_CARGO,
    JOB_TYPES.DOMESTIC_FULL_CONTAINER,
    JOB_TYPES.DOMESTIC_LOOSE_CARGO,
    JOB_TYPES.BREAK_BULK,
    JOB_TYPES.EMPTY,
    JOB_TYPES.PROJECT_CARGO,
  ],
  [COUNTRIES.SINGAPORE]: [
    JOB_TYPES.IMPORT_CONTAINER_20,
    JOB_TYPES.IMPORT_CONTAINER_40,
    JOB_TYPES.EXPORT_CONTAINER_20,
    JOB_TYPES.EXPORT_CONTAINER_40,
    JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20,
    JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40,
  ],
  [COUNTRIES.JAPAN]: [
    JOB_TYPES.IMPORT_FULL_CONTAINER,
    JOB_TYPES.EXPORT_FULL_CONTAINER,
    JOB_TYPES.FIRST_MILE,
    JOB_TYPES.LAST_MILE,
    JOB_TYPES.EMPTY_CONTAINER_RETURN,
    JOB_TYPES.EMPTY_CONTAINER_COLLECTION,
    JOB_TYPES.CONTAINER_SHIFT,
  ],
};

export const VEHICLE_TYPES = {
  TRAILER: 'trailer',
  SIDELOADER: 'sideloader',
  OTHER: 'other',
  B_DOUBLE: 'b_double',
  DROP_DECK: 'drop_deck',
  TAUTLINER: 'tautliner',
  TILT_TRAY: 'tilt_tray',
  FLAT_BED: 'flat_bed',
  REEFER: 'reefer',
  CHASSIS_20: '20_chassis',
  CHASSIS_40: '40_chassis',
  FLAT_BED_TRAILER: 'flat_bed_trailer',
  LOW_BED_TRAILER: 'low_bed_trailer',
  WHEELER_CLOSED_VAN_4: '4_wheeler_closed_van',
  WHEELER_CLOSED_VAN_6: '6_wheeler_closed_van',
  WHEELER_CLOSED_VAN_10: '10_wheeler_closed_van',
  PRIME_MOVERS: 'prime_movers',
  TRAILER_WITH_CONTAINER: 'trailer_with_container',
  TRAILER_SOC_ONLY: 'trailer_soc_only',
  BIKE: 'bike',
  PASSENGER_VEHICLE: 'passenger_vehicle',
  VAN: 'van',
  LORRY: 'lorry',
  HAND_CARRY_OR_OTHER: 'hand_carry_or_other',
  GEN_20: '20_gen',
  GEN_40: '40_gen',
  TRI_AXLE_20: '20_tri_axle',
  TRI_AXLE_40: '40_tri_axle',
};

export const VEHICLE_TYPES_BY_COUNTRY = {
  [COUNTRIES.AUSTRALIA]: [
    VEHICLE_TYPES.TRAILER,
    VEHICLE_TYPES.SIDELOADER,
    VEHICLE_TYPES.OTHER,
    VEHICLE_TYPES.B_DOUBLE,
    VEHICLE_TYPES.DROP_DECK,
    VEHICLE_TYPES.TAUTLINER,
    VEHICLE_TYPES.TILT_TRAY,
    VEHICLE_TYPES.FLAT_BED,
    VEHICLE_TYPES.REEFER,
  ],
  [COUNTRIES.PHILIPPINES]: [
    VEHICLE_TYPES.CHASSIS_20,
    VEHICLE_TYPES.CHASSIS_40,
    VEHICLE_TYPES.FLAT_BED_TRAILER,
    VEHICLE_TYPES.LOW_BED_TRAILER,
    VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
    VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
    VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
  ],
  [COUNTRIES.SINGAPORE]: [
    VEHICLE_TYPES.CHASSIS_20,
    VEHICLE_TYPES.CHASSIS_40,
    VEHICLE_TYPES.PRIME_MOVERS,
    VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
    VEHICLE_TYPES.TRAILER_SOC_ONLY,
    VEHICLE_TYPES.BIKE,
    VEHICLE_TYPES.PASSENGER_VEHICLE,
    VEHICLE_TYPES.VAN,
    VEHICLE_TYPES.LORRY,
    VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
    VEHICLE_TYPES.GEN_20,
    VEHICLE_TYPES.GEN_40,
    VEHICLE_TYPES.TRI_AXLE_20,
    VEHICLE_TYPES.TRI_AXLE_40,
  ],
  [COUNTRIES.JAPAN]: [
    VEHICLE_TYPES.CHASSIS_20,
    VEHICLE_TYPES.CHASSIS_40,
    VEHICLE_TYPES.PRIME_MOVERS,
    VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
    VEHICLE_TYPES.TRAILER_SOC_ONLY,
    VEHICLE_TYPES.BIKE,
    VEHICLE_TYPES.PASSENGER_VEHICLE,
    VEHICLE_TYPES.VAN,
    VEHICLE_TYPES.LORRY,
    VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
    VEHICLE_TYPES.GEN_20,
    VEHICLE_TYPES.GEN_40,
    VEHICLE_TYPES.TRI_AXLE_20,
    VEHICLE_TYPES.TRI_AXLE_40,
  ],
};

export const TYPES_OF_PACKAGES = {
  CRATES: 'crates',
  PALLETS: 'pallets',
  PIECES: 'pieces',
  PACKAGES: 'packages',
  BAG: 'bag'
};

export const TYPES_OF_STOCKS = {
  CARTONS: 'cartons',
  PALLETS: 'pallets',
  CRATES: 'crates',
  OTHERS: 'others'
};

export const POST_TO = {
  PUBLIC: 'public',
  ASSOCIATES: 'associates',
  HIDDEN: 'hidden',
  ARCHIVE: 'archive',
};

export const USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  COMPANY: 'COMPANY',
  ADMIN: 'ADMIN',
  DRIVER: 'DRIVER',
  COMPANY_USER: 'COMPANY_USER',
  ADMIN_USER: 'ADMIN_USER', // ADMIN FOR EACH COUNTRY
  COMPANY_DRIVER: 'COMPANY_DRIVER',
  DRIVER_MANAGER: 'DRIVER_MANAGER',
  OTHER: 'OTHER',
  CUSTOMER: 'CUSTOMER',
  WAREHOUSE: 'WAREHOUSE',
};

export const VERIFICATION_STATUS = {
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const JOB_STATUS = {
  CREATED: 'created',
  POSTED: 'posted',
  OPEN: 'open',
  CLONED: 'cloned',
  IN_PROGRESS: 'in_progress',
  PICKEDUP: 'pickedup',
  DELIVERED: 'delivered',
  RETURNED: 'returned',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  ASSIGNED: 'assigned',
  ARCHIVE: 'archive',
  OVERDUE_DELIVERY: 'overdue_delivery',
  OVERDUE_COLLECTIONS: 'overdue_collections',
  NO_POD_DOCUMENT: 'no_pod_document',
  STORAGE: 'storage',
  VIA_YARD: 'via_yard',
  FUMIGATION: 'fumigation',
  PICKED_UP: 'picked_up'
};

export const BID_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
};

export const ERROR_CODES = {
  BID_ALREADY_SUBMITTED: 'bid_already_submitted',
};

export type TActualDateTypes = 'pickup' | 'delivery' | 'return';

export const JOB_FILES_TYPE = {
  FILES: 'files',
  SIGNATURE: 'signature',
  PHOTO: 'photo',
  COMPANY_LOGO: 'company_logo',
  POD: 'proof-of-delivery',
  DAMAGE_PHOTO: 'damage_photo',
  PICKUP_PHOTO: 'pick_up_photo',
};

export const USER_PRIVILEGES = {
  JOB_MANAGEMENT: 'job_management',
  TRANSPORT_MANAGEMENT: 'transport_management',
  SEA_FREIGHT_MANAGEMENT: 'sea_management',
  REPORT: 'report',
  CONFIGURATIONS: 'configuration',
  DRIVER_MANAGEMENT: 'driver_management',
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const FILE_PREFIX = {
  JOB: 'job',
  DRIVER: 'driver',
};

export const COMMISION_RATES_TYPE = {
  FLAT_RATE: 'flat_rate',
  PERCENTAGE: 'percentage',
  M3: 'per_cubic',
};

export const AU_STATES = {
  WA: 'WA',
  ACT: 'ACT',
  VIC: 'VIC',
  TAS: 'TAS',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
};


export const NZ_STATES = {
  AUCKLAND: 'AUCKLAND',
  HAWKES_BAY: 'HAWKES_BAY',
  WELLINGTON: 'WELLINGTON',
  TARANAKI: 'TARANAKI',
  NELSON: 'NELSON',
  CANTERBURY: 'CANTERBURY',
  OTAGO: 'OTAGO',
};
export const STATES_BY_COUNTRY = {
  australia: AU_STATES,
  new_zealand: NZ_STATES,
};

export const LANGUAGES = {
  ENGLISH: 'en',
  JAPANESE: 'jp',
};
export const INSURANCES = {
  MOTOR_VEHICLE: 'motor_vehicle',
  PUBLIC_LIABILITY: 'public_liability',
  MARINE_TRANSIT: 'marine_transit',
  WORKERS_COMP: 'workers_comp',
  INSURANCE_FOR_TRAFFIC_VICTIM_RELIEF: 'insurance_for_traffic_victim',
  VOLUNTARY_INSURANCE: 'voluntary_insurance',
  INTERPERSONAL: 'interpersonal',
  OBJECTIVES: 'objectives',
  VEHICLE: 'vehicle',
};

export const COUNTRY_INSURANCES = {
  [COUNTRIES.PHILIPPINES]: [
    INSURANCES.MOTOR_VEHICLE,
    INSURANCES.PUBLIC_LIABILITY,
    INSURANCES.MARINE_TRANSIT,
    INSURANCES.WORKERS_COMP,
  ],
  [COUNTRIES.AUSTRALIA]: [
    INSURANCES.MOTOR_VEHICLE,
    INSURANCES.PUBLIC_LIABILITY,
    INSURANCES.MARINE_TRANSIT,
    INSURANCES.WORKERS_COMP,
  ],
  [COUNTRIES.SINGAPORE]: [
    INSURANCES.MOTOR_VEHICLE,
    INSURANCES.PUBLIC_LIABILITY,
    INSURANCES.MARINE_TRANSIT,
    INSURANCES.WORKERS_COMP,
  ],
  [COUNTRIES.JAPAN]: [
    INSURANCES.INSURANCE_FOR_TRAFFIC_VICTIM_RELIEF,
    INSURANCES.VOLUNTARY_INSURANCE,
    INSURANCES.INTERPERSONAL,
    INSURANCES.OBJECTIVES,
    INSURANCES.VEHICLE,
  ],
};

export type TStatus = typeof STATUS.ACTIVE | typeof STATUS.INACTIVE;

export const ALL = 'all';

export const CONTAINERS = {
  GENERAL_CONTAINER_20: '20_general_container',
  GENERAL_CONTAINER_40: '40_general_container',
  REEFER_CONTAINER_20: '20_reefer_container',
  REEFER_CONTAINER_40: '40_reefer_container',
  HI_CUBE_CONTAINER_20: '20_hi_cube_container',
  HI_CUBE_CONTAINER_40: '40_hi_cube_container',
};

export interface IVehicleTypeConfig {
  [country: string]: {
    [movementType: string]: {
      [tripType: string]: {
        [jobType: string]: string[];
      };
    };
  };
}

export const VEHICLE_TYPES_CONFIG: IVehicleTypeConfig = {
  [COUNTRIES.AUSTRALIA]: {
    [MOVEMENT_TYPES.EMPTY_PARK]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
    },
    [MOVEMENT_TYPES.FULL_TRUCK_LOAD]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
    },
    [MOVEMENT_TYPES.LOOSE_CARGO]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
    },
    [MOVEMENT_TYPES.OTHER]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
    },
    [MOVEMENT_TYPES.RAIL]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
    },
    [MOVEMENT_TYPES.WHARF]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.EMPTY_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.EMPTY_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.FULL_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.REFEER_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_20]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.HC_40]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.TRAILER,
          VEHICLE_TYPES.SIDELOADER,
          VEHICLE_TYPES.OTHER,
          VEHICLE_TYPES.B_DOUBLE,
          VEHICLE_TYPES.DROP_DECK,
          VEHICLE_TYPES.TAUTLINER,
          VEHICLE_TYPES.FLAT_BED,
          VEHICLE_TYPES.TILT_TRAY,
          VEHICLE_TYPES.REEFER,
        ],
      },
    },
  },
  [COUNTRIES.PHILIPPINES]: {
    [MOVEMENT_TYPES.LAND_FREIGHT]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.IMPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.EXPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.IMPORT_LOOSE_CARGO]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.EXPORT_LOOSE_CARGO]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.DOMESTIC_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.DOMESTIC_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.BREAK_BULK]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.EMPTY]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.PROJECT]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.IMPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.EXPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.IMPORT_LOOSE_CARGO]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.EXPORT_LOOSE_CARGO]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.DOMESTIC_FULL_CONTAINER]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.DOMESTIC_LOOSE_CARGO]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.BREAK_BULK]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.EMPTY]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
        [JOB_TYPES.PROJECT]: [
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.FLAT_BED_TRAILER,
          VEHICLE_TYPES.LOW_BED_TRAILER,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_4,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_6,
          VEHICLE_TYPES.WHEELER_CLOSED_VAN_10,
        ],
      },
    },
  },
  [COUNTRIES.JAPAN]: {
    [MOVEMENT_TYPES.LAND_FREIGHT]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.FIRST_MILE]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
        ],
        [JOB_TYPES.LAST_MILE]: [
          VEHICLE_TYPES.TRI_AXLE_40,
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_RETURN]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_COLLECTION]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.GEN_40,
        ],
        [JOB_TYPES.EXPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.RAIL]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.FIRST_MILE]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LAST_MILE]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_RETURN]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_COLLECTION]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.RAIL]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
      },
    },
  },
  [COUNTRIES.SINGAPORE]: {
    [MOVEMENT_TYPES.LAND_FREIGHT]: {
      [TRIP_TYPE.ONE_WAY]: {
        [JOB_TYPES.FIRST_MILE]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
        ],
        [JOB_TYPES.LAST_MILE]: [
          VEHICLE_TYPES.TRI_AXLE_40,
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_RETURN]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_COLLECTION]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.GEN_40,
        ],
        [JOB_TYPES.EXPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.RAIL]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
      },
      [TRIP_TYPE.ROUND_TRIP]: {
        [JOB_TYPES.FIRST_MILE]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LAST_MILE]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_RETURN]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EMPTY_CONTAINER_COLLECTION]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_FULL_CONTAINER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.IMPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.EXPORT_CONTAINER_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.LOOSE_CARGO]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.OTHER]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
        [JOB_TYPES.RAIL]: [
          VEHICLE_TYPES.PRIME_MOVERS,
          VEHICLE_TYPES.TRAILER_WITH_CONTAINER,
          VEHICLE_TYPES.TRAILER_SOC_ONLY,
          VEHICLE_TYPES.BIKE,
          VEHICLE_TYPES.PASSENGER_VEHICLE,
          VEHICLE_TYPES.VAN,
          VEHICLE_TYPES.LORRY,
          VEHICLE_TYPES.HAND_CARRY_OR_OTHER,
          VEHICLE_TYPES.CHASSIS_20,
          VEHICLE_TYPES.CHASSIS_40,
          VEHICLE_TYPES.GEN_20,
          VEHICLE_TYPES.GEN_40,
          VEHICLE_TYPES.TRI_AXLE_20,
          VEHICLE_TYPES.TRI_AXLE_40,
        ],
      },
    },
  },
};

export const CREATE_JOB_ELEMENTS = {
  QUANTITY: 'quantity',
  VOLUME: 'volume',
  PACKAGES_TYPE: 'type_of_packages',
  SEAL_NO: 'seal_no',
  CN: 'container_no',
  ECRNUM: 'empty_container_release_number',
  WS: 'wharf_slot',
  CNTR: 'cntr_last_free_day',
  PACKAGE_TYPE: 'type_of_package',

};

export const JOB_POD_LOG_STATUS = {
  EMAIL_SENT: 'email_sent',
  POD_RECEIVED: 'pod_received',
  COMPLETED: 'completed',
};

export const QUOTE_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  ACTIVE: 'active',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  FAILED: 'failed',
  SUCCESSFUL: 'successful',
  GENERATED: 'generated'
}

export const TEMPERATURE = {
  AMBIENT: 'ambient',
  CHILLED: 'chilled',
  FROZEN: 'frozen'
}

export const TEMPERATURELIST = [
  TEMPERATURE.AMBIENT, TEMPERATURE.CHILLED, TEMPERATURE.FROZEN
]

export const MONTHS = {
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
}

export const PALLET_ACCOUNTS = {
  CHEPS: 'cheps',
  LOSCAMS: 'loscams',
  PLAIN: 'plain',
  OTHER: 'other'
}