import { Route, useRouteMatch } from "react-router-dom"
import { routes } from "../../../routes"
import Commision from "./Commision/Commision"
import CommonAddress from "./CommonAddress/CommonAddress"
import AddressManagement from "./AddressManagement/AddressManagement"
import CountrySettings from "./CountrySettings/CountrySettings"
import CountrySettingsV2 from "./CountrySettingsV2/CountrySettingsV2"
import NotificationSettings from "./NotificationSettings/NotificationSettings"
import InvoiceReports from "./InvoiceReports/InvoiceReports"
import InvoiceReportsV2 from "./InvoiceReportsV2/InvoiceReportsV2"

import RatesManagement from "./RatesManagement/RatesManagement"


import { USER_ROLES } from "../../../Types/Constants"
import Margin from "./Margin/Margin"



function AdminSettings() {
    const match = useRouteMatch()



    return (
        <>
            {/* /home/admin/settings/commision */}
            <Route path={match.path + routes.home.admin_settings.commission}>
                <Commision />
            </Route>

            {/* /home/admin/settings/margin */}
            <Route path={match.path + routes.home.admin_settings.margin}>
                <Margin />
            </Route>

            {/* /home/admin/settings/common_address */}
            <Route path={match.path + routes.home.admin_settings.common_address}>
                <AddressManagement accessType="common" />
            </Route>

            <Route path={match.path + routes.home.admin_settings.address_management}>
                <AddressManagement accessType="all" />
            </Route>

            {/* /home/admin/settings/country_settings */}
            <Route path={match.path + routes.home.admin_settings.country_settings}>
                <CountrySettingsV2 />
            </Route>
            {/* /home/admin/settings/notification_settings */}
            <Route path={match.path + routes.home.admin_settings.notification_settings}>
                <NotificationSettings />
            </Route>

            {/* /home/admin/settings/invoice_reports */}
            <Route path={match.path + routes.home.admin_settings.invoice_reports}>
                <InvoiceReports />
            </Route>
            <Route path={match.path + routes.home.admin_settings.job_invoiced}>
                <InvoiceReportsV2 access_type="invoiced" />
            </Route>
            <Route path={match.path + routes.home.admin_settings.job_not_invoiced}>
                <InvoiceReportsV2 access_type="uninvoiced" />
            </Route>
            <Route path={match.path + routes.home.admin_settings.rates_management}>
                <RatesManagement />
            </Route>
          
        </>
    )

}

export default AdminSettings