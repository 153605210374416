import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "../../../routes"
import HomeDashboard from "./DashboardV2"

function DashboardV2() {
    const match = useRouteMatch()
    return (
        <>
            <Route path={match.path + routes.home.dashboard_v2.main}>
                <HomeDashboard />
            </Route>
        </>
        // /home/v2/dashboard
    )
}

export default DashboardV2