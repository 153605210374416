import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Grid, FormControl, Select, InputLabel, MenuItem, TextField, FormLabel, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import capitalize from "capitalize";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ICompanyContact } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { Company } from "../../../Api/Company/Company";
import { Driver } from "../../../Api/Driver/Driver";
import { AppState } from "../../../Redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { USER_ROLES } from "../../../Types/Constants";
import SingleAutocomplete from "../../Common/SingleAutocomplete/SingleAutocomplete";
import { IAutocompleteOption } from "../../Common/types";
import { IBuySellDialogProps } from "./EmailListDialog.types";
import { AdminSettings } from "../../../Api/AdminSettings/AdminSettings"
import { IRatesManagement } from "../../../../../Application/Models/RatesManagementSettingsModel/RatesManagement.types";
import { isNil } from 'lodash';
import MultiAutocomplete from "../../Common/MultiAutocomplete/MultiAutocomplete";
import { emailsToOption } from "../../../Utilities/Autocomplete";
import { DataGrid, GridColumns, GridValueGetterParams, GridRowsProp } from "@material-ui/data-grid";

const {
    COMPANY,
    DRIVER
} = USER_ROLES

const companyApi = new Company()
const driverApi = new Driver()
const adminSettingApi = new AdminSettings()





function BuySellDialog(props: IBuySellDialogProps) {
    const [t] = useTranslation('ShowQuotes')
    const { open, handleClose, selectedCompany, buy_rate, onSubmit } = props
    const [selectedEmails, setSelectedEmails] = useState<IAutocompleteOption[]>([])
    const [emailOptions, setEmailOptions] = useState<IAutocompleteOption[]>([])
    const [sellPrice, setSellPrice] = useState('0');
    const [buyPrice, setBuyPrice] = useState(buy_rate);
    const user = useSelector((state: AppState) => state.user)
    const dispatch = useDispatch()
    useEffect(() => {
        if (open) {
            setBuyPrice(buy_rate)
        }
    }, [open])
    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
                {/* <DialogTitle>
                    <Typography>
                        {t("send_quote_to")}

                    </Typography>
                </DialogTitle> */}
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item sm={12}>

                            <TextField
                                value={buyPrice}
                                // disabled
                                type='number'
                                onChange={(e) => setBuyPrice(e.target.value)}
                                label={t('buy_price')}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>

                            <TextField
                                value={sellPrice}
                                // disabled
                                type='number'
                                onChange={(e) => setSellPrice(e.target.value)}
                                label={t('sell_price')}
                                fullWidth
                                variant="outlined"
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>

                    <Button variant="contained" color="primary" onClick={() => {
                        onSubmit(buyPrice, sellPrice , true)
                    }}>
                        {t("approve")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


export default BuySellDialog