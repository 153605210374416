import { Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import capitalize from "capitalize";
import { TFunction } from "i18next";
import { IJobInfo } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";




export const getInvoiceReportsColumns = (t: TFunction): GridColumns => {


    return [
        {
            field: 'reference',
            headerName: t('Columns:reference'),
            width: 300
        },
        {
            field: 'customer_reference',
            headerName: t('Columns:customer_reference'),
            width: 200,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {job.customer_reference}
                    </Typography>
                )
            }
        },
        {
            field: 'postingCompany',
            headerName: t('Columns:posting_company'),
            width: 500,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                const postingCompanyName = capitalize.words(job.company?.companyName ?? '')
                return (
                    <Typography>
                        {postingCompanyName}
                    </Typography>
                )
            }
        },
        {
            field: 'biddingCompany',
            headerName: t('Columns:bidding_company'),
            width: 500,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                const biddingCompanyName = capitalize.words(job.job_bids?.[0].user?.company?.companyName ?? '')
                return (
                    <Typography>
                        {biddingCompanyName}
                    </Typography>
                )
            }
        },
        {
            field: 'job_type',
            headerName: t('Columns:job_type'),
            width: 300,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {t(job.job_type)}
                    </Typography>
                )
            }
        },
        {
            field: 'movement_type',
            headerName: t('Columns:movement_type'),
            width: 300,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {t(job.movement_type)}
                    </Typography>
                )
            }
        },
        {
            field: 'pick_up_from',
            headerName: t('Columns:pick_up_from'),
            width: 500,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {job.pick_up_address?.state + ',' + job.pick_up_address?.street_number || '' + job.pick_up_address?.zipcode }
                    </Typography>
                )
            }
        },
        {
            field: 'deliver_to',
            headerName: t('Columns:deliver_to'),
            width: 500,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {job.deliver_to_address?.state + ',' + job.deliver_to_address?.street_number || ',' + job.deliver_to_address?.zipcode}
                    </Typography>
                )
            }
        },
        {
            field: 'posted_price',
            headerName: t('Columns:posted_price'),
            width: 300,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {job.posted_price}
                    </Typography>
                )
            }
        },
        {
            field: 'bidded_price',
            headerName: t('Columns:bidded_price'),
            width: 300,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {job.job_bids?.[0].amount || ''}
                    </Typography>
                )
            }
        },
        {
            field: 'accepted_price',
            headerName: t('Columns:accepted_price'),
            width: 300,
            renderCell: (params) => {
                const job: IJobInfo = params.row as IJobInfo
                return (
                    <Typography>
                        {(job.job_bids?.[0].amount || 0) + job.margin_price }
                    </Typography>
                )
            }
        },
        {
            field: 'commissionFee',
            headerName: t('Columns:commission'),
            width: 300
        },

    ]
}