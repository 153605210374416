import { Dialog, DialogContent, DialogTitle, Typography, FormControlLabel, Switch, FormGroup, Checkbox, DialogActions, Grid, Button, FormControl, InputLabel, MenuItem, Select, makeStyles, TextField, InputAdornment } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { COMPANY_TYPES, COUNTRIES, STATUS, TStatus, COUNTRY_INSURANCES } from "../../../Types/Constants"
import { IViewCustomerDialogProps } from "./ViewCustomerDialog.types"
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { useCallback, useEffect, useRef, useState } from "react";
import { Company } from "../../../Api/Company/Company";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import { ICompanyInfo, ICompanyUpdatePayload } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WarningDialog from "../WarningDialog/WarningDialog";
import { isNumeric } from "../../../Utilities/Regex";
import isEmail from 'validator/lib/isEmail';
import MapAutocomplete from "../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../BP/MapAddress";
import ChangePassword from "./ChangePassword"
import SwitchButton from'../../Common/SwitchButton/SwitchButton'
import IconButton from "@material-ui/core/IconButton";
import useQuery from "../../../hooks/useQuery";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { Hidden ,Box } from "@material-ui/core";
import { getLabelForRegistrationNumberByCountry } from "../../../Utilities/Country";
import { Customer } from '../../../Api/Customer/Customer'
import { ICustomerDetails } from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types";
import CustomerDetails from "../../Common/CustomerDetails/CustomerDetails"
import CustomerAddress from "../../Common/CustomerAddress/CustomerAddress"


const {
    ACTIVE,
    INACTIVE
} = STATUS

const useStyles = makeStyles((theme) => ({
    driverRegisterBox: {
        backgroundColor: "white",
        padding: "40px 15px",
        borderRadius: "20px",
        boxShadow: "2px 3px 6px -1px rgba(0,0,0,0.5)",
        overflowY: "auto",
        height: "95vh",
      },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    }
}))

const { FREIGHT_FORWARED, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY, NVOCC } = COMPANY_TYPES

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const customerApi = new Customer()

function ViewCustomerDialog(props: IViewCustomerDialogProps) {
    const { open, handleClose, customerId  } = props
    const [t] = useTranslation('ViewCompanyDetailsDialog')
    const dispatch = useDispatch()
    const classes = useStyles()
    const [showPass, setShowPass] = useState<boolean>(false);

    const [isEditMode ,setIsEditMode] = useState(false)
    const [selectedCustomer , setSelectedCustomer] = useState<ICustomerDetails> ()
    const editCustomerRef = useRef();

  

    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)

    // const [agreedTos, setAgreedTos] = useState(false);
    // const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] =useState(false);

    const params = useQuery();
    const [selectedLanguage] = useState(params.get("lang") ?? "en");
    const [isLoading, setIsLoading] = useState(selectedLanguage !== "en");
    const [showChangePassword , setChangePassword] = useState(false)
    const [userId , setUserId] = useState('')

    // const handleMapAddressSelect = useCallback(
    //   (mapAddress: MapAddress) => {
    //       setHasSearchAddress(true)
    //       setStreetNameAddress([mapAddress.streetNum, mapAddress.route].join(' '))
    //       setState(mapAddress.state)
    //       setPostalCode(mapAddress.postalCode)
    //       setCity(mapAddress.city)
    //       setLatitude(String(mapAddress.lat))
    //       setLongitude(String(mapAddress.long))
    //   },
    //   [],
    // )


    const getDetails = async (customerId : any) =>{
 
        await customerApi.getDetails(customerId)
        .then(response => {
            
            let companyDetails: ICustomerDetails = response.data
            setSelectedCustomer(companyDetails)
            setUserId(companyDetails.user.id)
            // setCustomerDetails(companyDetails)
        
            // dispatch(setLoader(false))
            // setEmailNotification(companyDetails.email_notifications == null ? [] : companyDetails.email_notifications)
            // setCompanyDetails(companyDetails)
            // setCompanyInfo(companyDetails)
            
        })
        .catch((err :any) => 
        {
            dispatch(setLoader(false))
            console.log('err', err)
            toast.error(t('error_getting_company_detail'))
        })
    }
    useEffect(() => {
        if (customerId) {
            getDetails(customerId)
        }
    }, [customerId])


    const handlePasswordClose =() =>{
        setChangePassword(false)
    }
    const handleOnWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
        },
        [],
    )
    const handleChangePasswordSubmit = useCallback(
        (password : any) => {
            // dispatch(setLoader(true))
            customerApi.changePassword(userId ,password)
                .then(() => {
                    handlePasswordClose()
                    // handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.success(t('success_changing_password'))
                    // dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    handlePasswordClose()
                    // handleOnWarningDialogClose()
                    dispatch(setLoader(false))

                    toast.error(t('error_changing_password'))
                    // dispatch(setLoader(false))
                })
        },
        [ t, customerId , handlePasswordClose ],
    )
    const handleOnEditClick = () => {
        setIsEditMode(true)
    }
    const handleOnCancelClick = useCallback( ()=> {
        setIsEditMode(false)
    },[])

    const handleOnUpdateClick = () => {
        setIsEditMode(false)

    }
   const handleUpdateClick=()=>{
    if (editCustomerRef.current) {
        (editCustomerRef.current as any).handleUpdate(() => {
            handleOnCancelClick();
            handleClose()
        });
      }
   }
    return (
        <>
       
            {/* <WarningDialog onProceedClick={handleOnProceedDelete} open={isWarningDialogOpen} handleClose={handleOnWarningDialogClose} message={t('warning_about_to_delete_company')} /> */}
            <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('customer_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
              
                <CustomerDetails  ref={editCustomerRef} editMode={isEditMode} selectedCustomer={selectedCustomer} onEditDone={handleOnCancelClick}/>
                <CustomerAddress selectedCustomer={selectedCustomer} />

                </DialogContent>
                
             
                <DialogActions>
            

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >

                        <Grid item md={12}>
                            <Grid
                                spacing={2}
                                container
                                justifyContent="flex-end"
                            >
                                <Grid item md={3}>
                                            <Button onClick={() => {setChangePassword(!showChangePassword)}} startIcon={<ClearIcon />} variant="contained" fullWidth>Change Password</Button>
                                </Grid>
                                
                                {
                                    !isEditMode
                                        ? <Grid item md={3}>
                                            <Button startIcon={<EditIcon />} fullWidth onClick={() => handleOnEditClick()} className={classes.editBtn} variant="contained">
                                                {t('edit')}
                                            </Button>
                                        </Grid>
                                        :  null
                                }
                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleUpdateClick()} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                {t('update')}
                                            </Button>
                                        </Grid>
                                        : null
                                }

                                {
                                    isEditMode
                                        ? <Grid item md={3}>
                                            <Button onClick={() => handleOnCancelClick()} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                        </Grid>
                                        : null
                                }
                            </Grid>

                        </Grid>



                    </Grid>
                </DialogActions>
                <ChangePassword
                    open={showChangePassword}
                    handleClose={() => { setChangePassword(false)}}
                    onSubmit={handleChangePasswordSubmit}
                />
            </Dialog>
        </>
        
    )
}


export default ViewCustomerDialog