import {Divider, Grid, Typography , Button} from '@material-ui/core';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CustomTable from '../../../Common/CustomTable/CustomTable';
import {
  IDeliveryFilter,
  IJobInfo,
  IJobReportFilter,
  IJobFilters
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import {useDispatch, useSelector} from 'react-redux';
import {setLoader} from '../../../../Redux/Loader/actions';
import {Job} from '../../../../Api/Job/Job';
import {toast} from 'react-toastify';
import {getColumns} from './cntrDetentionColumns';
import ViewEditJob from '../../../Dialogs/ViewEditJob/ViewEditJob';
import {GridRowParams} from '@material-ui/data-grid';
import {AppState} from '../../../../Redux';
import {JOB_STATUS, USER_ROLES} from '../../../../Types/Constants';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeliveryReportFilterDialog from '../../../Dialogs/DeliveryFilter/DeliveryFilter';
import moment from 'moment'
import { ALL } from "../../Jobs/Jobs.constans";
import LazyTable from '../../../Common/LazyTable/LazyTable';

const {IN_PROGRESS} = JOB_STATUS;

const { ADMIN_USER, COMPANY , SUPER_ADMIN} = USER_ROLES;

const jobApi = new Job();

export default function CNTRDehireReports() {
  const [t, i18n] = useTranslation('JobReport');
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user);
  const [isViewEditJobDialogOpen, setIsViewEditJobDialogOpen] = useState(false);
  // const [filterState, setFilterState] = useState<IDeliveryFilter>({
  //   date : moment().toDate(),
  // });
  const [filterState, setFilterState] = useState<IJobFilters>({
    reference:'',
    jobType: ALL,
    vehicleType: ALL
})
  const [jobs, setJobs] = useState<IJobInfo[]>([]);
  const [selectedJob, setSelectedJob] = useState<IJobInfo | null>(null);
  const [adminUser, setIsAdmin] = useState<boolean>(false);

  const isAdminUser = user.response?.type === ADMIN_USER;
  const isSuperAdmin = user.response?.type === SUPER_ADMIN;

  const isCompanyUser = user.response?.type === COMPANY;
  const [isDeliveryReportFilterDialog, setIsDeliveryFilterDialogOpen] =
    useState(false);

    const getJobsForReport = useCallback(
      (country?: string, filters?: IJobFilters) => {
          dispatch(setLoader(true))
          jobApi.get(ALL, 'completed_jobs', country,filters,'cntrreport' , 'wharf')
              .then(response => {
                const jobs: IJobInfo[] = response.data;
            
                setJobs(jobs)
                dispatch(setLoader(false))
              })
              .catch(err => {
                  console.log(`err`, err)
                  toast.error(t('error_getting_jobs'))
                  dispatch(setLoader(false))
              })

      },
      [dispatch, t],
  )

  const lazyGet = async( offset : number , pageNumber : number , limit :number , jobFilters : any)=>{
   

    // dispatch(setLoader(true));
    return jobApi
      .lazyGet(ALL, 'completed_jobs', undefined, filterState ,'cntrreport','wharf', offset,pageNumber,limit)
      .then((response) => {
          const jobs: IJobInfo[] = response.data.jobList;
          const count = response.data.jobsCount;
          return {jobs , count }
      })
      .catch((err :any) => {
        const jobs: IJobInfo[] = [];
        const count = 0;
        console.log('err', err);
        toast.error(t('error_getting_jobs'));
        return {jobs , count }
        
      });
    
  }


  // useEffect(() => {
  //   getJobsForReport(undefined ,filterState);
  // }, [filterState, getJobsForReport]);

  useEffect(() => {
    setIsAdmin(isSuperAdmin);
  }, [isSuperAdmin, setIsAdmin]);

  const handleJobReportFilterDialogClose = useCallback(() => {
    setIsDeliveryFilterDialogOpen(false);
  }, []);

  const handleOnFiltersApply = useCallback(
    (filters: IJobFilters) => {
      setFilterState(filters);
      getJobsForReport(undefined , filters)
      handleJobReportFilterDialogClose();
    },
    [handleJobReportFilterDialogClose]
  );
  
  const handleFilterClick = useCallback(() => {
    setIsDeliveryFilterDialogOpen(true);
  }, []);


  const columns = getColumns(t,user);

  const handleOnViewEditJobDialogClose = useCallback(() => {
    setIsViewEditJobDialogOpen(false);
    setSelectedJob(null);
  }, []);

  const onRowClick = useCallback((params: GridRowParams) => {
    const job: IJobInfo = params.row as IJobInfo;
    setSelectedJob(job);
    setIsViewEditJobDialogOpen(true);
  }, []);

  return (
    <>
       
    {adminUser ? 
      <ViewEditJob
      hideActionButtons
        selectedJob={selectedJob}
        type={selectedJob?.job_classification}
        open={isViewEditJobDialogOpen}
        handleClose={handleOnViewEditJobDialogClose}
      /> :

      <ViewEditJob

      selectedJob={selectedJob}
      type={selectedJob?.job_classification}
      open={isViewEditJobDialogOpen}
      handleClose={handleOnViewEditJobDialogClose}
    />
    }
        {/* <DeliveryReportFilterDialog
        onApplyCallback={handleOnFiltersApply}
        initialFilterState={filterState}
        open={isDeliveryReportFilterDialog}
        handleClose={handleJobReportFilterDialogClose}
      /> */}
      <Grid
        direction="column"
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item md={3}>
          {/* Originally CNTR Dehire Report was renamed to Detention */}
          <Typography>{t('cntr_detention_report')}</Typography>
        </Grid>
       
        <Grid item md={12}>

        <Grid item md={10}></Grid>
        {/* <Grid item md={2}>
              <Button
                onClick={handleFilterClick}
                fullWidth
                color="primary"
                variant="contained"
                startIcon={<FilterListIcon />}
              >
                {t('filter')}
              </Button>
            </Grid> */}
        </Grid>
        <Grid item md={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item md={12}>
              <LazyTable
                columns={columns}
                // rows={jobs}
                onRowClick={onRowClick}
                lazyFilter={filterState}
                lazyFunction={lazyGet}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
