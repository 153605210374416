import { Checkbox, FormControlLabel ,Grid,  } from "@material-ui/core";
import {  useEffect, useState, } from "react";
import FileInput from '../../Common/FileInput/FileInput';

// import DocumentTables from './DocumentsTable'
import { useTranslation } from "react-i18next";
import {IDocumentTableProps} from './RequiredDocument.types'



function GenerateCheckBox(props: IDocumentTableProps) {
    let { item ,onChange , checkOnChange , primaryChange,isAdmin} = props
    const [t] = useTranslation('FileInput')
    const [buttonReadOnly , setButtonReadonly] = useState<boolean>(true)
    const [checkBoxId , setCheckBoxId] = useState<any>()
    const documentOnChange = ( id:any ) => {
        setButtonReadonly(!buttonReadOnly)
      };
    useEffect(() => {
      
            if(onChange){
                onChange({id :checkBoxId , data : []} , 'clear')
            }
       
       
    }, [buttonReadOnly])

    const handleSettingFiles =(data: any , itemId : any , creation_id : any , job_id : any , is_required : boolean)=>{
        if(onChange){
            
            onChange({id :itemId , data : data , job_id : job_id , creation_id :  creation_id , is_required : is_required } , 'add')
        }
    }

    return (
        <>
         <Grid id={"grid_"+ item.id} key={"grid_"+ item.id} item md={12}>
            <Grid item md={4}>
                {!isAdmin ?  
                <Grid  item style={{'fontWeight' :'bold'}} >{item.document}</Grid>
         
                :
                <FormControlLabel  control={<Checkbox  checked={item.checked} id={item.id} onChange={(e) =>{
                    documentOnChange(item.id)
                    setCheckBoxId(item.id)
                    if(primaryChange){
                        primaryChange(item)
                    }
                    if(checkOnChange){
                        checkOnChange(item.id, item.checked ,  item.creation_id ,item.job_id)
                    }
                }} />}
                label={item.document}/>
                
                }
            </Grid>
            <Grid item md={8}>   
                <FileInput key={"file_"+ item.id} onChange={(e: any) =>handleSettingFiles(e.target.files ,item.id , item.creation_id , item.job_id , item.checked)}
                    disabled={!item.checked}
                    value={item.fileName}
                    // buttonId={item.id}
                    multiple={false}
                />
            </Grid>
        </Grid>
        </>
    )
}


export default GenerateCheckBox