import { IJobInfo } from '../../../Application/DTOs/JobsDto/JobsDto.types';


export const calculateGST = (
  agreedPrice: number,
  waitTimeCharge: number
): any => {
  const taxRate = 10;
  return ((agreedPrice + waitTimeCharge) * (taxRate / 100)).toFixed(2);
};

export const computationWaitingTimeCharge = (
  actualWaitingTime: number,
  freeWaitTime: number,
  waitingTimeCharge: number
): number => {
  let chargableWaitTime = actualWaitingTime - freeWaitTime;
  let chargableWaitTimeIn = (chargableWaitTime * 100) / 60;
  return (chargableWaitTimeIn / 100) * waitingTimeCharge;
};



export const calculateAgreedPrice = (
  job_bids_amount: number,
  margin_price: number
): any => {

  const agreed_price: number = Number(job_bids_amount) + Number(margin_price);
  return Number(agreed_price)
};


export const rawTotal = (jobInfo: IJobInfo): any => {

  const jobBidAmount: number = Number(jobInfo.job_bids?.[0]?.amount ?? 0);
  const marginPrice: number = Number(jobInfo.margin_price || 0);
  const driver_fee: number = Number(jobInfo.job_bids?.[0]?.user?.driver ? jobInfo.job_bids?.[0]?.waitTimeCharge != null ? jobInfo.job_bids?.[0]?.waitTimeCharge : 0 : 0);
  // const : number = Number(jobInfo.job_bids?.[0]?.user?.driver  ?  jobInfo.job_bids?.[0]?.waitTimeCharge : 0);
  const company_fee: number = Number(jobInfo.assignedCompanyFee || 0);

  const wait_time_charge: number = Number((jobInfo.job_bids?.[0]?.waitTimeCharge || 0) * (jobInfo.job_bids?.[0]?.waitTime || 0));


  const amount1: number = Number(jobInfo.additional_charges?.amount1 || 0)
  const amount2: number = Number(jobInfo.additional_charges?.amount2 || 0)
  const extra_charge: number = amount1 + amount2
  const agreedPrice: number = Number(calculateAgreedPrice(jobBidAmount, marginPrice))

  return Number(agreedPrice - driver_fee + company_fee + wait_time_charge + extra_charge)
}

// export const computeGST = (rawTotal : number): any => {
//   const percentage = 0.1;
//   return Number(rawTotal * percentage)
// };

// export const getGST =  ( jobInfo : IJobInfo) : any =>{
//   const rawTotalFee = rawTotal(jobInfo) 
//   const gst = computeGST(rawTotalFee)
//   return Number(gst).toFixed(2);
// }

export const getTotalFee = (jobInfo: IJobInfo) => {
  const total_fee = Number(getGST(jobInfo)) + Number(rawTotal(jobInfo))

  return Number(total_fee).toFixed(2);

}
export const getAdditionalCost = (jobInfo: any) => {
  const amount1 = jobInfo.additional_charges?.amount1 || 0
  const amount2 = jobInfo.additional_charges?.amount2 || 0

  const additional_charges: Number = Number(
    amount1 + amount2
  );
  return additional_charges

}


// GST = Sell Price + Wait Time Charge + Extra Charges / 10
export const getGST = (jobInfo: IJobInfo): any => {
  const sp   = parseFloat((jobInfo?.posted_price  || 0) as string)   //posted price is  sell price
  const wtc  = parseFloat(jobInfo.job_wait_time_charge as unknown as string)
  const ac  = parseFloat(getAdditionalCost(jobInfo)as unknown as string )
  const gst = (sp + wtc + ac) / 10  
  return gst || 0;
}

export const getProfitability = (jobInfo: IJobInfo) => {
  // (Sell Rate - Buy Rate) + Additional Cost + MI4U Commission

  // const less_price : number = (jobInfo.posted_price as unknown as number) - (jobInfo.initial_price  as unknown as number)
  // const comms : number = jobInfo.commision || 0
  // const additional_cost : number= getAdditionalCost(jobInfo)  as number
  // const total_fee = less_price + additional_cost + comms
  // return Number(total_fee).toFixed(2); 

  // Changed to  Sell Rate + M14U Commission + Additional cost  -- 8/25/23
  // const sell_price : number = (jobInfo.posted_price as unknown as number) 
  // const comms : number = jobInfo.commision || 0
  // const additional_cost : number= getAdditionalCost(jobInfo)  as number
  // const total_fee = sell_price + additional_cost + comms
  // return Number(total_fee).toFixed(2); 


  // Change to Total Price = Sell Price + Wait Time Charge + Extra Charges + GST -- 10/04/23
  const sp   = parseFloat((jobInfo?.posted_price  || 0) as string)   // posted price is  sell price
  const wtc  = parseFloat(jobInfo.job_wait_time_charge as unknown as string)
  const ac  = parseFloat(getAdditionalCost(jobInfo) as unknown as string )
  const gst  = getGST(jobInfo)
  const total_fee = sp + wtc + ac + gst
  return total_fee || 0
}