import {useRouteMatch, Route} from 'react-router-dom';
import {routes} from '../../../routes';
import AddDriver from './AddDriver/AddDriver';
import MyDrivers from './MyDrivers/MyDrivers';
import WaitingForApproval from './WaitingForApproval/WaitingForApproval';
import DriverFeeSettings from './DriverFreeSettings/DriverFeeSettings';
import DriverManagementPage from './DriverManagementPage/DriverManagementPage';

function DriverManagement() {
  const match = useRouteMatch();

  return (
    <>
      {/* /home/driver/management/add */}
      <Route path={match.path + routes.home.driver_management.add_driver}>
        <AddDriver />
      </Route>
      {/* /home/driver/management/drivers/list */}
      <Route path={match.path + routes.home.driver_management.my_drivers}>
        <MyDrivers />
      </Route>

      {/* /home/driver/management/waiting_for_approval */}
      <Route
        path={match.path + routes.home.driver_management.waiting_for_approval}
      >
        <WaitingForApproval />
      </Route>

      {/* /home/driver/management/fee/settings */}
      <Route
        path={match.path + routes.home.driver_management.driver_fee_settings}
      >
        <DriverFeeSettings />
      </Route>

      {/* /home/driver/management/page */}
      <Route
        path={match.path + routes.home.driver_management.driver_management_page}
      >
        <DriverManagementPage />
      </Route>
    </>
  );
}

export default DriverManagement;
