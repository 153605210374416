import React, { useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import PalletAccount from './PalletAccountV2'; // Importing the provided PalletAccount component

function DynamicPalletAccounts({ palletAccounts, onPalletAccountsChange, readOnly }) {
    const handlePalletAccountsChange = (index, key, value) => {
        const newPalletAccounts = palletAccounts.map((account, i) => {
            if (i === index) {
                if (key === 'action' && value === 'transfer') {
                    if (account.type == 'PLAIN') {
                        return { ...account, [key]: value, value: 'Plain Pallets' }; // Clear value if action is 'Exchange'
                    } else {
                        return { ...account, [key]: value, value: '' }; // Clear value if action is 'transfer'
                    }
                }
                else if (key === 'action' && value === 'exchange') {
                    return { ...account, [key]: value, value: 'Exchange' }; // Clear value if action is 'Exchange'
                }
                return { ...account, [key]: value };
            }
            return account;
        });
        onPalletAccountsChange(newPalletAccounts);
    };

    const handleAddPalletAccount = () => {
        const lastAccount = palletAccounts[palletAccounts.length - 1];
        if (lastAccount && (lastAccount.type === '' || lastAccount.action === '')) {
            return; // Don't add incomplete accounts
        }

        const isDuplicate = palletAccounts.some(
            (account, index) => index !== palletAccounts.length - 1 && account.type === lastAccount.type && account.action === lastAccount.action
        );

        if (!isDuplicate) {
            const newPalletAccounts = [...palletAccounts, { type: '', action: '', value: '' }];
            onPalletAccountsChange(newPalletAccounts);
        }
    };

    const handleRemovePalletAccount = (index) => {
        if (palletAccounts.length === 1) {
            return; // Do not remove if there is only one item
        }

        const newPalletAccounts = [...palletAccounts];
        newPalletAccounts.splice(index, 1);
        onPalletAccountsChange(newPalletAccounts);
    };

    return (
        <Grid container direction='column' spacing={2}>
            <Grid item style={{ textAlign: 'right' }}>
                <Button  disabled={(!palletAccounts || palletAccounts.length === 8) || readOnly} color="primary" variant="contained" onClick={handleAddPalletAccount}>Add Pallet Account</Button>
            </Grid>
            <Grid item>
                {palletAccounts && palletAccounts.map((palletAccount, index) => (
                    <Grid key={index} style={{ padding: "10px 0" }}>
                        <Grid container spacing={1} direction="row">
                            <Grid item md={11}>
                                <PalletAccount
                                    readOnly={readOnly}
                                    palletAccount={palletAccount}
                                    onChange={(key, value) => handlePalletAccountsChange(index, key, value)}
                                    disabledOptions={palletAccounts.slice(0, index)}
                                />
                            </Grid>
                            <Grid item style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} md={1}>
                                <Button
                                    color="secondary"
                                    style={{ height: "30px" }}
                                    variant="contained"
                                    onClick={() => handleRemovePalletAccount(index)}
                                    disabled={palletAccounts.length === 1 || readOnly} // Disable remove button when there is only one item
                                >
                                    -
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

export default DynamicPalletAccounts;
