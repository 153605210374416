import { Grid, Typography, Divider, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import CustomTable from '../../../Common/CustomTable/CustomTable'
import AddIcon from '@material-ui/icons/Add';
import React, { useCallback, useEffect, useState } from 'react';
import AssociateGroupDialog from '../../../Dialogs/AssociateGroupDialog/AssociateGroupDialog';
import { IAssociateGroupInfo } from '../../../../../../Application/DTOs/AssociateGroupDto/AssociateGroupDto.types'
import { Company } from '../../../../Api/Company/Company';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../../Redux/Loader/actions';
import { toast } from 'react-toastify';
import { getAssociateGroupManagementColumns } from './AssociateGroupManagement.columns';
import { GridRowParams } from '@material-ui/data-grid';
import WarningDialog from '../../../Dialogs/WarningDialog/WarningDialog';
import { AppState } from '../../../../Redux';

const companyApi = new Company()

function AssociateGroupManagement() {
    const [t] = useTranslation('AssociateGroupManagement')
    const user = useSelector((state: AppState) => state.user)
    const currentCompanyId = user.response?.company?.id
    const [isAssociateDialogOpen, setIsAssociateDialogOpen] = useState(false)
    const [associateGroups, setAssociateGroups] = useState<IAssociateGroupInfo[]>([])
    const [selectedGroup, setSelectedGroup] = useState<IAssociateGroupInfo | undefined>(undefined)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
    const [associateIdToDelete, setAssociateIdToDelete] = useState<number | undefined>(undefined)
    const dispatch = useDispatch()

    const handleOnWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
            setAssociateIdToDelete(undefined)
        },
        [],
    )

    const getAssociateGroups = useCallback(
        (companyId: string | number) => {
            dispatch(setLoader(true))
            companyApi.getCompanyAssociateGroups(companyId)
                .then(response => {
                    const associateGroups: IAssociateGroupInfo[] = response.data

                    setAssociateGroups(associateGroups)

                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_associate_groups'))
                    dispatch(setLoader(false))

                })
        },
        [dispatch, t],
    )

    const handleAssociateDialogClose = useCallback(
        () => {
            if (currentCompanyId)
                getAssociateGroups(currentCompanyId)
            setSelectedGroup(undefined)
            setIsAssociateDialogOpen(false)
        },
        [getAssociateGroups, currentCompanyId],
    )

    const handleOnCreateAssociateGroupClick = useCallback(
        () => {
            setSelectedGroup(undefined)
            setIsAssociateDialogOpen(true)
        },
        [],
    )

    const handleOnDeleteAssociateGroup = useCallback(
        (associateGroupId: number) => {
            dispatch(setLoader(true))
            companyApi.deleteAssociatedGroup(associateGroupId)
                .then(() => {
                    toast.success(t('success_deleting_associate_groups'))
                    dispatch(setLoader(false))
                    if (currentCompanyId)
                        getAssociateGroups(currentCompanyId)
                    handleOnWarningDialogClose()
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_deleting_associate_groups'))
                    dispatch(setLoader(false))

                })
        },
        [dispatch, t, getAssociateGroups, handleOnWarningDialogClose, currentCompanyId],
    )

    const onDeleteClick = useCallback(
        (associateGroupId: number) => {
            setAssociateIdToDelete(associateGroupId)
            setIsWarningDialogOpen(true)
        },
        [],
    )

    useEffect(() => {
        if (currentCompanyId)
            getAssociateGroups(currentCompanyId)

    }, [getAssociateGroups, currentCompanyId])

    const columns = getAssociateGroupManagementColumns(t, onDeleteClick)

    const onRowClick = useCallback(
        (params: GridRowParams) => {
            let group: IAssociateGroupInfo = params.row as IAssociateGroupInfo
            setSelectedGroup(group)
            setIsAssociateDialogOpen(true)
        },
        [],
    )

    const onProceedDeleteClick = useCallback(
        () => {
            if (associateIdToDelete) {
                handleOnDeleteAssociateGroup(associateIdToDelete)
            }
        },
        [associateIdToDelete, handleOnDeleteAssociateGroup],
    )

    return (
        <>
            <WarningDialog onProceedClick={onProceedDeleteClick} open={isWarningDialogOpen} handleClose={handleOnWarningDialogClose} message={t('warning_about_to_delete_associate_group')} />
            <AssociateGroupDialog selectedGroup={selectedGroup} open={isAssociateDialogOpen} handleClose={handleAssociateDialogClose} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <Grid item>
                    <Typography>
                        {t('associate_group_management')}
                    </Typography>
                </Grid>
               
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Grid item sm={6} md={4}>
                            <Button onClick={handleOnCreateAssociateGroupClick} startIcon={<AddIcon />} fullWidth color="primary" variant="contained">
                                {t('add_new_associate_group')}
                            </Button>
                        </Grid>
                        <Grid item md={12}>
                            <CustomTable
                                columns={columns}
                                rows={associateGroups}
                                onRowClick={onRowClick}
                            />
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AssociateGroupManagement