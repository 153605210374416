import { Button, Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IOcrData } from "../../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { formatDateTimeDisplay, formatDateTimeDisplayDD } from '../../../../Utilities/DateTime'
interface IColumnsCallbacks {
    onGenerate?: (rowData: any) => void; // Make onGenerate optional
}
export const getMpdReportColumns = (t: TFunction, showType: string, callbacks: IColumnsCallbacks): GridColumns => [
    {
        field: 'date_processed',
        headerName: 'Order Date',
        // hide: showType == 'filter',
        width: 150,
        renderCell: (params) => <Typography>{(params.row as IOcrData).date_processed ? formatDateTimeDisplayDD((params.row as IOcrData).date_processed) : ''}</Typography>,
    },
    {
        field: 'reference',
        headerName: 'MI4U Ref',
        width: 150
    },
    {
        field: 'customer_reference',
        headerName: t(`customer_reference`),
        width: 200
    },
    {
        field: 'pick_up_address',
        headerName: t(`pick_up_city`),
        width: 200,
        renderCell: (params) => <Typography>{(params.row as IOcrData).pickup_add ? (params.row as IOcrData).pickup_add["city"] : ''}</Typography>,
    },
    {
        field: 'pick_up_date',
        headerName: t(`pick_up_date_time`),
        width: 170,
        renderCell: (params) => <Typography>{(params.row as IOcrData).pickup_add ? formatDateTimeDisplay((params.row as IOcrData).pick_up_date) : ''}</Typography>,
    },
    {
        field: 'quantity',
        headerName: t(`quantity`),
        align: 'right',

        width: 150
    },
    {
        field: 'weight',
        headerName: t(`weight`),
        align: 'right',
        width: 150
    },
    {
        field: 'delivery_address',
        headerName: t(`delivery_address_city`),
        width: 200,
        renderCell: (params) => <Typography>{(params.row as IOcrData).delivery_add ? (params.row as IOcrData).delivery_add["city"] : ''}</Typography>,
    },
    {
        field: 'delivery_date',
        headerName: t(`delivery_date`),
        width: 170,
        renderCell: (params) => <Typography>{(params.row as IOcrData).delivery_date ? formatDateTimeDisplay((params.row as IOcrData).delivery_date) : ''}</Typography>,
    },
    {
        field: 'filename',
        headerName: t(`filename`),
        width: 150,
        hide: showType == 'filter',

    }, {
        field: 'remarks',
        headerName: t(`remarks`),
        width: 350
    },
    {
        field: 'status',
        headerName: t(`Columns:status`),
        hide: showType == 'filter',

        renderCell: (params) => <Typography>{t((params.row as IOcrData).status)}</Typography>,
        width: 150
    },

    {
        field: 'actions',
        headerName: t('generate_job'),
        width: 200,
        hide: showType == 'filter',
        renderCell: (params) => {
            if (!['created', 'success'].includes(params.row.status) ) {
                return <Button onClick={() => callbacks.onGenerate(params.row)} variant="contained" color="primary">{t('generate_job')}</Button>
            } else {
                return <Typography></Typography>
            }
        }
    },
]
