import React, { useState, useCallback } from 'react';
import { Button, Typography, Grid, Box } from '@material-ui/core';
import * as XLSX from 'xlsx';
import EmailListDialog from '../../../Dialogs/EmailListDialog/EmailListDialog';
import { IAutocompleteOption } from '../../../Common/types';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { QuotesApi } from '../../../../Api/Quotes/QuotesApi';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../../Redux/Loader/actions';
import { useTranslation } from 'react-i18next';

function QuoteImport() {
    const [t] = useTranslation('ShowQuotes');

    const [isSendEmailDialogOpen, setIsSendEmailDialogOpen] = useState(false);
    const [country, setCountry] = useState('australia');
    const [allFormData, setFormData] = useState<FormData>(new FormData());
    const quotesApi = new QuotesApi();
    const dispatch = useDispatch();
    const [selectedRow, setSelectedRow] = useState(null)
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const requiredHeaders = [
        'company_id',
        'movement_type',
        'job_type',
        'vehicle_type',
        'pickup_address_id',
        'delivery_address_id'
    ];
    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = async (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

            const headers: any = worksheet[0];

            const missingHeaders = requiredHeaders.filter(header => !headers.includes(header));
            if (missingHeaders.length > 0) {
                toast.error("Please use the correct template")
                return;
            }
            const filteredRows: any = worksheet.slice(1).filter((row: any) => {
                return row.some(cell => cell && cell.toString().trim() !== '');
            }).map((row: any, index) => ({
                id: index + 1,
                ...row.reduce((acc, curr, idx) => {
                    acc[headers[idx]] = curr;
                    return acc;
                }, {}),
            }));

            setColumns([
                ...headers.map((header) => ({
                    field: header,
                    hide: header.includes('_'),
                    headerName: header,
                    width: 200,
                })),
                {
                    field: 'create_a_quote',
                    headerName: 'Action',
                    width: 200,
                    sortable: false,
                    renderCell: (params: GridCellParams) => (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleEmailDialogOpen(params.row)}
                        >
                            Create as Quote
                        </Button>
                    ),
                }
            ]);
            setRows(filteredRows);
        };
        reader.readAsArrayBuffer(file);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            handleFileUpload(file);

        }
    };
    const onEmailDialogClose = useCallback(() => {
        setIsSendEmailDialogOpen(false);
    }, []);



    const handleEmailDialogOpen = (data: any) => {
        if (handleSendQuoteFormData(data) != false) {
            setIsSendEmailDialogOpen(true)
        }
    }
    const handleSubmitSendEmail = useCallback(
        (email_list: IAutocompleteOption[]) => {
            handleCreateOrUpdateClick(email_list)
        },
        [allFormData],
    )
    const validateForm = () => {
        return true
    }
    const handleSendQuoteFormData = (data: any) => {
        if (validateForm() == false) { return false }
        const formData = new FormData();
        formData.append('company_id', data.company_id);
        formData.append('movement_type', data.movement_type);
        formData.append('job_type', data.job_types);
        formData.append('vehicle_type', data.vehicle_type);

        formData.append('pickup_address_id', data.pickup_address_id);

        formData.append('delivery_address_id',  data.delivery_address_id);


        formData.append('trip_type', data.trip_type);


        formData.append('lead_time', data['Lead Time']);

        formData.append('quantity', data["Volume"]);
        formData.append('weight', data["Quantity"]);
        formData.append('volume', data["Volume"]);
        formData.append('margin_price', '0');

        formData.append('is_split_delivery', 'false');
        formData.append('is_fixed_date', 'true');
        formData.append('lcl_type', '');
        formData.append('job_classification', 'transport');
        formData.append('instructions', data['Requirements']);

        // formData.append('selected_required_documents', JSON.stringify(selectedRequiredDocuments.map((opt: any) => opt)));

        formData.append(
            'associate_group_ids',
            JSON.stringify([].map((opt) => opt.value))
        );

        setSelectedRow(data.id)
        setFormData(formData)
    };
    const handleCreateOrUpdateClick = (email_list: IAutocompleteOption[]) => {

        if (validateForm() == false) return false

        if (allFormData) {
            allFormData.append('email_list', JSON.stringify(email_list.map((opt) => opt)));
        }

        // Update state with the new FormData object
        setFormData(allFormData)

        quotesApi.create(allFormData).then((response) => {
            setRows((prevRows) => prevRows.filter((row) => row.id !== selectedRow));
            toast.success(t('success_creating_quote') + response.data.reference);
            dispatch(setLoader(false));
            setSelectedRow(null)
            onEmailDialogClose()
        }).catch((err: any) => {
            dispatch(setLoader(false));
            toast.error(t(err.response?.data || 'error_creating_quote'));
        })

    };

    return (

        <Grid container spacing={2} justifyContent="center">
            <EmailListDialog
                open={isSendEmailDialogOpen}
                handleClose={onEmailDialogClose}
                onDone={onEmailDialogClose}
                country={country}
                onSubmit={handleSubmitSendEmail}
                formData={allFormData}
                mode='create'
            />
            <Grid item md={12}>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    border="1px solid #ccc"
                    borderRadius="4px"
                    padding="20px"
                    marginBottom="20px"
                    bgcolor="#fafafa"
                    // minHeight="200px"
                    width="100%"
                >
                    <input
                        accept=".xlsx, .xls, .csv"
                        id="file-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                    <label htmlFor="file-upload">
                        <Button variant="contained" color="primary" component="span">
                            Upload Excel or CSV
                        </Button>
                    </label>
                    <Typography variant="body2" color="textSecondary" style={{ marginTop: 10 }}>
                        (Accepted formats: .xlsx, .xls, .csv)
                    </Typography>
                </Box>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} pageSize={5} />
                </div>
            </Grid>
        </Grid>
    );
}

export default QuoteImport;
