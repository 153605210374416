export const JOB_REPORT_COLUMNS = {
  REFERENCE: 'reference',
  MOVEMENT_TYPE: 'movement_type',
  VEHICLE_TYPE: 'vehicle_type',
  JOB_TYPE: 'job_type',
  JOB_WAIT_TIME: 'job_wait_time',
  JOB_WAIT_TIME_CHARGE: 'job_wait_time_charge',
  BIDDING_COMPANY_NAME: 'bidding_company_name',
  AGREED_PRICE: 'agreed_price',
  FEE: 'fee',
  DRIVER_FEE: 'driver_fee',
  COMPANY_FEE: 'company_fee',
  COMISSION_FEE: 'commission'
}