import { useRouteMatch, Route } from "react-router-dom"
import { routes } from "../../../routes"
// import Dashboard from "./DashboardRevamped"
import Dashboard from "./DashboardRevamped"
import WeeklyDashboard from "./WeeklyDashboard"


function DashboardMenu() {
    const match = useRouteMatch()
    return (
        <>
            {/* /home/dashboard/main */}
            <Route path={match.path + routes.home.dashboard_menu.dashboard}>
                <Dashboard />
            </Route>
            <Route path={match.path + routes.home.dashboard_menu.dashboard_weekly}>
                <WeeklyDashboard />
            </Route>
        </>
        // /home/v2/dashboard
    )
}

export default DashboardMenu