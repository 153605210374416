import { GridColumns } from "@material-ui/data-grid";
import { Typography } from "@material-ui/core";
import { TFunction } from "i18next";
import { formatDateTimeDisplayDD, formatTimeOnly } from '../../../../Utilities/DateTime';

import {
    STATUS,
} from "..././../../../UI/src/Types/Constants";
interface IOnStatusChange {
    (user_id: string | number, isChecked: boolean, company_id: string | number): void
}
const { ACTIVE } = STATUS;



export const getColumns = (t: TFunction, report_type: any): GridColumns => [
    {
        field: 'reference',
        headerName: t('reference'),
        width: 250
    }, {
        field: 'job_type',
        headerName: t('job_type'),
        width: 250,
        renderCell: ((params: any) => {
            return <Typography>{t(params.row.job_type)}</Typography>
        })
    },
    {
        field: 'weight',
        headerName: t('weight'),
        width: 150
    },
    {
        field: 'quantity',
        headerName: t('quantity'),
        width: 150
    },
    {
        field: 'container_no',
        headerName: t('container_no'),
        width: 250
    },
    {
        field: 'collection_date',
        headerName: t('collection_date'),
        hide: (report_type != 'collection'),
        width: 250,
        renderCell: ((params) => {
            const collection_date: string = String(
                params.row.pickup_date_time
            );
            return <Typography>{formatDateTimeDisplayDD(collection_date)}</Typography>;
        })
    },
    {
        field: 'collection_time',
        headerName: t('collection_time'),
        hide: (report_type != 'collection'),
        width: 250,
        renderCell: ((params) => {
            const collection_date: string = String(
                params.row.pickup_date_time
            );
            return <Typography>{formatTimeOnly(collection_date)}</Typography>;
        })
    },
    {
        field: 'delivery_date',
        headerName: t('delivery_date'),
        hide: (report_type != 'delivery'),
        width: 250,
        renderCell: ((params) => {
            const collection_date: string = String(
                params.row.pickup_date_time
            );
            return <Typography>{formatDateTimeDisplayDD(collection_date)}</Typography>;
        })
    },
    {
        field: 'delivery_time',
        headerName: t('delivery_time'),
        hide: (report_type != 'delivery'),
        width: 250,
        renderCell: ((params) => {
            const delivery_date: string = String(
                params.row.delivery_date_time
            );
            return <Typography>{formatTimeOnly(delivery_date)}</Typography>;
        })
    },
]