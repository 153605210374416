import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Divider, Typography, makeStyles, Button, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IMapViewProps } from './MapView.types';
import { Coords } from 'google-map-react'

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setLoader } from '../../../Redux/Loader/actions';
import { setJobList } from '../../../Redux/Job/actions';
import { ALL, USER_ROLES } from '../../../Types/Constants';
import { AppState } from '../../../Redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterPopover from '../../Common/FilterPopover/FilterPopover';
import Legends from './legend';
import CustomTable from '../../Common/CustomTable/CustomTable';
import { GridRowParams } from '@material-ui/data-grid';
import { getShowJobsTableColumns } from './columns';
import { useRouteMatch } from 'react-router-dom';
import ViewEditJob from '../../Dialogs/ViewEditJob/ViewEditJob';
import { AVAILABLE, JOBS_WILDCARDS_KEYS, IN_PROGRESS, ASSIGNED } from '../Jobs/Jobs.constans';
import { Job } from '../../../Api/Job/Job';
import { JobV2 } from '../../../Api/Job/JobV2';

import { Driver } from '../../../Api/Driver/Driver';
import { IJobFilters, IJobInfo, JobDataV2 } from '../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { TShowTypes } from './Jobs.types';
import GoogleMapsComp from './GoogleMaps';
const useStyles = makeStyles(() => ({
    mapBox: {
        width: '100%',
        height: '40vh',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        padding: 0
    }
}));

const jobsApi = new Job();
const jobsApiV2 = new JobV2();


const driverApi = new Driver();

const { ADMIN_USER, SUPER_ADMIN } = USER_ROLES;

function MapViewV2(props: IMapViewProps) {
    const [t] = useTranslation('MapView');
    const dispatch = useDispatch();
    const classes = useStyles();
    const [mapJobs, setMapJobs] = useState<JobDataV2[]>([]);
    const [zoom, setZoom] = useState(5);
    const [showMaps, setShowMaps] = useState(false);
    const [showData, setShowdata] = useState(false);
    const match = useRouteMatch();
    const params: any = match.params;
    const showType: TShowTypes | undefined = (params[JOBS_WILDCARDS_KEYS.SHOW_TYPE] as any) || AVAILABLE;
    const [isViewEditJobDialogOpen, setIsViewEditJobDialogOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState<IJobInfo | null>(null);
    const user = useSelector((state: AppState) => state.user);
    const columns = getShowJobsTableColumns(t, showType, user);
    const isAdminUser = user.response?.type === ADMIN_USER;
    const ownerId = user.response?.company?.id;
    const [center, setCenter] = useState<Coords | undefined>(undefined);
    const [mapZoom, setMapZoom] = useState<number | undefined>(undefined);
    const [popOverAnchorEl, setPopOverAnchorEl] = useState(null);
    const [carMarkers, setCarMarkers] = useState<any>(null);
    const [filters, setFilters] = useState<IJobFilters>({
        country: 'australia',
        jobType: ALL,
        vehicleType: ALL,
        status: ALL
    });
    const [filteredMapData, setFilteredMapData] = useState([]);

    const handlePopOverOpen = useCallback(
        (e) => {
            setPopOverAnchorEl(e.currentTarget);
        },
        [],
    );

    const handlePopOverClose = useCallback(
        () => {
            setPopOverAnchorEl(null);
        },
        [],
    );

    const getAvailableJobs = useCallback(
        (filters?: IJobFilters) => {
            jobsApiV2.get(props.type, isAdminUser ? user.response?.country : undefined, filters)
                .then(async (response) => {
                    const jobs: JobDataV2[] = response.data;

                    await setMapJobs(jobs);
                    const mapData = jobs.map(job => ({
                        lat: parseFloat(job.pickup_address_latitude),
                        lng: parseFloat(job.pickup_address_longitude),
                        title : job.reference,
                        status: job.status.toLowerCase() // Assuming status is used for determining marker type
                    }));
                    setFilteredMapData(mapData);
                    setMapZoom(5);
                })
                .catch((err) => {
                    console.log('err', err);
                    toast.error(t('error_getting_jobs'));
                });
        },
        [dispatch, t, props.type, user.response?.country, isAdminUser],
    );

    useEffect(() => {
        getAvailableJobs(filters);
    }, [filters]);

    const getAllDriverLocation = async (companyId?: string | number) => {
        await driverApi.getAllDriverLocation(companyId)
            .then((response) => {
                const data = response.data;
                setCarMarkers(data);
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const handleFilterChange = useCallback(
        (filter: IJobFilters) => {
            setFilters(filter);
        },
        [],
    );

    const handleOnViewEditJobDialogClose = useCallback(() => {
        setIsViewEditJobDialogOpen(false);
        setSelectedJob(null);
    }, []);

    const onRowClick = useCallback((params: GridRowParams) => {
        const job: IJobInfo = params.row as IJobInfo;
        setSelectedJob(job);
        setIsViewEditJobDialogOpen(true);
    }, []);
    const createKey = (job: IJobInfo) => `${job.id}`;

    return (
        <>
            <Grid
                direction="column"
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
            >
                <Grid item md={3}>
                    <Typography>
                        {t('map_view')}
                    </Typography>
                </Grid>
               
                <Grid item md>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-end"
                    >
                        <Legends />
                        <Grid item md={3}>
                            <Button onClick={handlePopOverOpen} fullWidth startIcon={<FilterListIcon />} variant="contained" color="primary">
                                {t('filter')}
                            </Button>
                            <Popover
                                open={Boolean(popOverAnchorEl)}
                                anchorEl={popOverAnchorEl}
                                onClose={handlePopOverClose}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top'
                                }}
                            >
                                <FilterPopover hideCompletedDateSelector filters={filters} onFilterChange={handleFilterChange} onClose={handlePopOverClose} />
                            </Popover>
                        </Grid>
                        <Grid item lg={12} style={{ width: "100%", marginLeft: 0 }}>
                            <div className={classes.mapBox}>
                                <GoogleMapsComp mapData={filteredMapData} />
                            </div>
                        </Grid>
                        <Grid item md>
                            <Grid item md={12}>
                                <CustomTable
                                    columns={columns}
                                    rows={mapJobs}
                                    onRowClick={onRowClick}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <ViewEditJob
                    key={(selectedJob?.id || 0) + 1}
                    selectedJob={selectedJob}
                    type={'transport'}
                    open={isViewEditJobDialogOpen}
                    handleClose={handleOnViewEditJobDialogClose}
                    isInProgress={selectedJob?.status === IN_PROGRESS}
                    mode={'edit'}
                />
            </Grid>
        </>
    );
}

export default MapViewV2;
