import { Dialog, DialogContent, DialogTitle, Typography, Grid, TextField, FormControl, Button, DialogActions, Select, InputLabel, MenuItem, InputAdornment, FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IDriverDetailsDialogProps } from "./DriverDetailsDialog.types";
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import { formatFileUrl } from "../../../Utilities/Formatting";
import ImagePreviewDialog from '../ImagePreviewDialog/ImagePreviewDialog'
import no_image from '../../../Images/no_image.png'
import { COUNTRIES, COUNTRY_INSURANCES, STATUS, VEHICLE_TYPES_BY_COUNTRY } from "../../../Types/Constants";
import { useCallback, useEffect, useState } from "react";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { Driver } from "../../../Api/Driver/Driver";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { toast } from "react-toastify";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IDriverUpdatePayload } from "../../../../../Application/DTOs/DriverDto/DriverDto.types";
import WarningDialog from '../WarningDialog/WarningDialog'
import { getLabelForRegistrationNumberByCountry } from '../../../Utilities/Country'

import { useSelector } from 'react-redux';
import { AppState } from '../../../Redux';
import { USER_ROLES } from '../../../Types/Constants'
import Image from "../../Common/Image/Image";

const driverApi = new Driver()

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    gap: {
        marginTop: theme.spacing(3)
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    }
}))

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const {
    ACTIVE,
    INACTIVE
} = STATUS

const {
    SUPER_ADMIN,
    ADMIN_USER,
    COMPANY,
    COMPANY_DRIVER,
    DRIVER
} = USER_ROLES

function DriverDetailsDialog(props: IDriverDetailsDialogProps) {
    const [t] = useTranslation('DriverDetailsDialog')
    const classes = useStyles()
    const dispatch = useDispatch()
    const { open, handleClose, selectedUserId, hideActions, showDriverPhotos } = props

    const user = useSelector((state: AppState) => state.user)
    const isSuperAdmin: boolean = [SUPER_ADMIN].includes(user.response?.type ?? '')
    const isUserAdmin: boolean = user.response?.type === ADMIN_USER
    const isCompany = user.response?.type === COMPANY

    const [country, setCountry] = useState(AUSTRALIA)
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [companyUEN, setCompanyUEN] = useState('')
    const [streetNameAddress, setStreetNameAddress] = useState('')
    const [city, setCity] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [vehicleType, setVehicleType] = useState('')
    const [truckRegistration, setTruckRegistration] = useState('')
    const [trailerRegistration, setTrailerRegistration] = useState('')
    const [waitTimeAfter, setWaitTimeAfter] = useState('')
    const [waitTimeCharge, setWaitTimeCharge] = useState('')
    const [isWorkersComp, setIsWorkersComp] = useState(false)
    const [isMotorVehicle, setIsMotorVehicle] = useState(false)
    const [isPublicLiability, setIsPublicLiability] = useState(false)
    const [isMarineTransit, setIsMarineTransit] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [driverInfo, setDriverInfo] = useState<IUserInfo | null>(null)
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false)
    const [imageToPreview, setImageToPreview] = useState<string | undefined>(undefined)
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false)
    const [photoFile, setPhotoFile] = useState<string | undefined>(undefined)
    const [licenseFile, setLicenseFile] = useState<string | undefined>(undefined)
    const [insurances, setInsurances] = useState<string[]>([])
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const INSURANCES = COUNTRY_INSURANCES[country] ?? []

    const handleInsuranceCheck = useCallback(
        (isChecked: boolean, insurance: string) => {
            if (isChecked) {
                if (!insurances.includes(insurance)) {
                    insurances.push(insurance)
                    setInsurances([...insurances])
                }
            } else {
                setInsurances(insurances.filter(ins => ins !== insurance))
            }
        },
        [insurances],
    )


    const handleWarningDialogClose = useCallback(
        () => {
            setIsWarningDialogOpen(false)
        },
        [],
    )

    const handleEditClick = useCallback(
        () => {
            setIsEditMode(true)
        },
        [],
    )

    const setDriverDetails = useCallback(
        (driverInfo: IUserInfo) => {
            setCountry(driverInfo.driver?.country ?? '')
            setEmail(driverInfo.email)
            setMobile(driverInfo.mobile)
            setFirstName(driverInfo.firstName)
            setLastName(driverInfo.lastName)
            setCompanyName(driverInfo.driver?.company_name ?? '')
            setCompanyUEN(driverInfo.driver?.company_uen ?? '')
            setStreetNameAddress(driverInfo.driver?.street_num ?? '')
            setCity(driverInfo.driver?.city ?? '')
            setPostalCode(driverInfo.driver?.postcode ?? '')
            setTruckRegistration(driverInfo.driver?.truck_registration ?? '')
            setTrailerRegistration(driverInfo.driver?.trailer_registration ?? '')
            setVehicleType(driverInfo.driver?.vehicle_type ?? '')
            setWaitTimeAfter(driverInfo.driver?.wait_time_after ?? '')
            setWaitTimeCharge(driverInfo.driver?.wait_time_charge ?? '')
            setIsWorkersComp(driverInfo.driver?.is_worker_comp ?? false)
            setIsPublicLiability(driverInfo.driver?.is_public_liability ?? false)
            setIsMotorVehicle(driverInfo.driver?.is_motor_vehicle ?? false)
            setIsMarineTransit(driverInfo.driver?.is_marine_transit ?? false)
            setPhotoFile(driverInfo.driver?.driver_photo_file)
            setLicenseFile(driverInfo.driver?.driver_license_file)
            setInsurances(driverInfo.driver?.insurances ?? [])
        },
        [],
    )

    const handleCancelClick = useCallback(
        () => {
            if (driverInfo) setDriverDetails(driverInfo)
            setIsEditMode(false)
        },
        [driverInfo, setDriverDetails],
    )

    const getApprovedDriverDetails = useCallback(
        (userId: number | string) => {
            dispatch(setLoader(true))
            driverApi.getDetails(userId)
                .then(response => {
                    const user: IUserInfo = response.data
                    setDriverDetails(user)
                    setDriverInfo(user)
                    dispatch(setLoader(false))
                })
                .catch(err => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_getting_driver_details'))
                })
        },
        [dispatch, t, setDriverDetails],
    )

    const handleOnImageSelect = useCallback(
        (imageSource: string) => {
            setImageToPreview(imageSource)
            setIsImagePreviewOpen(true)
        },
        [],
    )
    const handleImagePreviewClose = useCallback(
        () => {
            setIsImagePreviewOpen(false)
        },
        [],
    )

    useEffect(() => {
        if (selectedUserId) {
            getApprovedDriverDetails(selectedUserId)
        }
    }, [selectedUserId, getApprovedDriverDetails])

    const handleUpdateStatusClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))
                driverApi.updateStatus(selectedUserId, driverInfo?.status === INACTIVE ? ACTIVE : INACTIVE)
                    .then(() => {
                        dispatch(setLoader(false))
                        if (selectedUserId) {
                            getApprovedDriverDetails(selectedUserId)
                        }
                    })
                    .catch(err => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_getting_driver_details'))
                    })
            }

        },
        [selectedUserId, driverInfo, t, dispatch, getApprovedDriverDetails],
    )

    const handleUpdateDriverClick = () => {
        if (selectedUserId) {

            if(newPassword !== '' || confirmPassword !== '') {
                if(newPassword !== confirmPassword) {
                    toast.warning(t('password_do_not_match'))
                    return
                }

                if(newPassword.length < 6) {
                    toast.warning(t('passwords_length_too_short'))
                    return
                }
            }



            const payload: IDriverUpdatePayload = {
                city,
                company_name: companyName,
                company_uen: companyUEN,
                country,
                email,
                first_name: firstName,
                is_marine_transit: isMarineTransit,
                is_motor_vehicle: isMotorVehicle,
                is_public_liability: isPublicLiability,
                is_worker_comp: isWorkersComp,
                last_name: lastName,
                mobile,
                postcode: postalCode,
                street_num: streetNameAddress,
                trailer_registration: trailerRegistration,
                truck_registration: truckRegistration,
                vehicle_type: vehicleType,
                wait_time_after: waitTimeAfter,
                wait_time_charge: waitTimeCharge,
                contact_phone: mobile,
                insurances,
                newPassword: newPassword === '' ? undefined : newPassword,
                confirmPassword: confirmPassword === '' ? undefined : confirmPassword
            }

            dispatch(setLoader(true))
            driverApi.update(selectedUserId, payload)
                .then(() => {
                    dispatch(setLoader(false))
                    toast.success(t('success_updating_driver'))
                    getApprovedDriverDetails(selectedUserId)
                    setIsEditMode(false)
                })
                .catch((err :any) => {
                    console.log('err', err)
                    dispatch(setLoader(false))
                    toast.error(t('error_updating_driver'))
                })
        }
    }

    const handleOnDeleteClick = useCallback(
        () => {
            setIsWarningDialogOpen(true)
        },
        [],
    )

    const handleOnProceedDeleteClick = useCallback(
        () => {
            if (selectedUserId) {
                dispatch(setLoader(true))
                driverApi.delete(selectedUserId)
                    .then(() => {
                        dispatch(setLoader(false))
                        toast.success(t('success_deleting_driver'))
                        handleWarningDialogClose()
                        handleClose()
                    })
                    .catch((err :any) => {
                        console.log('err', err)
                        dispatch(setLoader(false))
                        toast.error(t('error_deleting_driver'))
                    })
            }
        },
        [selectedUserId, dispatch, t, handleClose, handleWarningDialogClose]
    )

    return (
        <>
            <WarningDialog onProceedClick={handleOnProceedDeleteClick} open={isWarningDialogOpen} handleClose={handleWarningDialogClose} message={t('warning_about_to_delete_driver')} />
            <ImagePreviewDialog imageSource={imageToPreview} open={isImagePreviewOpen} handleClose={handleImagePreviewClose} />
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('driver_details')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        {/* <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value as string)
                                        setVehicleType('')
                                    }}
                                >
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>

                                </Select>
                            </FormControl>
                        </Grid> */}
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('email')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('mobile_number')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DialpadIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('first_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('last_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        {
                            isEditMode
                                ? <>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('new_password')}
                                            type="password"
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <TextFieldsIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('confirm_password')}
                                            type="password"
                                            // InputProps={{
                                            //     endAdornment: (
                                            //         <InputAdornment position="end">
                                            //             <TextFieldsIcon />
                                            //         </InputAdornment>
                                            //     )
                                            // }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                                : null
                        }

                        <Grid className={classes.gap} md={12} item>
                            {/* GAP */}
                        </Grid>
                        {
                            isSuperAdmin || isUserAdmin
                                ? <Grid md={6} item>
                                    <TextField
                                        disabled={!isEditMode}
                                        value={companyName}
                                        onChange={(e) => setCompanyName(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t('company_name')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <TextFieldsIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                : null

                        }

                        {
                            isUserAdmin || isSuperAdmin
                                ? <Grid md={6} item>
                                    <TextField
                                        disabled={!isEditMode}
                                        value={companyUEN}
                                        onChange={(e) => setCompanyUEN(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        label={t(getLabelForRegistrationNumberByCountry(country))}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <TextFieldsIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontWeight: 'bold'
                                            }
                                        }}
                                    />
                                </Grid>
                                : null

                        }

                        {
                            !isCompany
                                ? <>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                    <Grid md={12} item>
                                        <Typography className={classes.title} align="left" variant="body1">
                                            {t('address_details')}
                                        </Typography>
                                    </Grid>
                                    <Grid md={12} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={streetNameAddress}
                                            onChange={(e) => setStreetNameAddress(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('enter_street_num_n_name')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HomeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('enter_suburb_or_city')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <HomeIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item></Grid>
                                    <Grid className={classes.gap} md={12} item>
                                        {/* GAP */}
                                    </Grid>
                                </>
                                : null
                        }
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={truckRegistration}
                                onChange={(e) => setTruckRegistration(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('truck_reg')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={6} item>
                            <TextField
                                disabled={!isEditMode}
                                value={trailerRegistration}
                                onChange={(e) => setTrailerRegistration(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('trailer_reg')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('vehicle_type')}</InputLabel>
                                <Select
                                    disabled={!isEditMode}
                                    value={vehicleType}
                                    onChange={(e) => setVehicleType(e.target.value as string)}
                                >
                                    {(VEHICLE_TYPES_BY_COUNTRY[country] || []).map((VEHICLE_TYPE, i) => (
                                        <MenuItem key={i} value={VEHICLE_TYPE}>{t(VEHICLE_TYPE)}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid className={classes.gap} md={12} item>
                            {/* GAP */}
                        </Grid>
                        {
                            !isCompany
                                ? <>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={waitTimeAfter}
                                            onChange={(e) => setWaitTimeAfter(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('wait_time_after')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid md={6} item>
                                        <TextField
                                            disabled={!isEditMode}
                                            value={waitTimeCharge}
                                            onChange={(e) => setWaitTimeCharge(e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            label={t('wait_time_charge')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <TextFieldsIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            InputLabelProps={{
                                                style: {
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </>
                                : null
                        }
                        <Grid className={classes.gap} md={12} item>
                            {/* GAP */}
                        </Grid>
                        <Grid lg={12} item>
                            <Typography className={classes.title} align="left" variant="body1">
                                {t('please_confirm_your_insurance_coverage')}*
                        </Typography>
                        </Grid>
                        {INSURANCES.map((insurance, i) => (
                            <Grid key={i} md={(i % 2) === 0 ? 3 : 9} item>
                                <FormControlLabel
                                    control={<Checkbox disabled={!isEditMode} checked={insurances.includes(insurance)} onChange={(e) => handleInsuranceCheck(e.target.checked, insurance)} color="primary" />}
                                    label={t(insurance)}
                                />
                            </Grid>
                        ))}
                        <Grid className={classes.gap} md={12} item>
                            {/* GAP */}
                        </Grid>
                        {
                            (!isEditMode && !isCompany) || showDriverPhotos
                                ? (
                                    <>
                                        <Grid item md={3}>
                                            <Typography className={classes.title}>
                                                {t('photo')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Image
                                                alt={t('photo')}
                                                src={photoFile ? formatFileUrl(photoFile) : no_image}
                                                onClick={() => {
                                                    if (photoFile) {
                                                        handleOnImageSelect(formatFileUrl(photoFile))
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                            <Typography className={classes.title}>
                                                {t('license')}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9}>
                                            <Image
                                                alt={t('license')}
                                                src={licenseFile ? formatFileUrl(licenseFile) : no_image}
                                                onClick={() => {
                                                    if (licenseFile) {
                                                        handleOnImageSelect(formatFileUrl(licenseFile))
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )
                                : null
                        }
                    </Grid>
                </DialogContent>
                {

                    !hideActions
                        ? <DialogActions>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Grid item md={6}>
                                    {
                                        !isEditMode
                                            ? <Grid item md={3}>
                                                <Button onClick={handleOnDeleteClick} startIcon={<DeleteForeverIcon />} fullWidth className={classes.deletebtn} variant="contained">
                                                    {t('delete')}
                                                </Button>
                                            </Grid>
                                            : null
                                    }
                                </Grid>

                                <Grid item md={6}>
                                    <Grid
                                        spacing={2}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        {
                                            !isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleUpdateStatusClick} className={classes.updatebtn} startIcon={driverInfo?.status === INACTIVE ? <CheckIcon /> : <ClearIcon />} variant="contained" fullWidth>{t(driverInfo?.status === INACTIVE ? 'mark_as_active' : 'mark_as_inactive')}</Button>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            !isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleEditClick} startIcon={<EditIcon />} fullWidth className={classes.editBtn} variant="contained">
                                                        {t('edit')}
                                                    </Button>
                                                </Grid>
                                                : null
                                        }
                                        {
                                            isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleUpdateDriverClick} startIcon={<CheckIcon />} fullWidth className={classes.updatebtn} variant="contained">
                                                        {t('update')}
                                                    </Button>
                                                </Grid>
                                                : null
                                        }

                                        {
                                            isEditMode
                                                ? <Grid item md={6}>
                                                    <Button onClick={handleCancelClick} startIcon={<ClearIcon />} variant="contained" fullWidth>{t('cancel')}</Button>
                                                </Grid>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogActions>
                        : null
                }
            </Dialog>
        </>
    )
}


export default DriverDetailsDialog