import axios from 'axios'
import { IMarginFeeSettingConfig } from '../../../../Application/DTOs/MarginFeeSettingsDto/MarginFeeSettingsDto.types'
import { ICountryConfiguration } from '../../../../Application/Models/CountryConfigurations/CountryConfigurations.types'
import { IRatesManagement, IRatesManagementConfig } from '../../../../Application/Models/RatesManagementSettingsModel/RatesManagement.types'

import { ICommisionFeeConfig } from '../../Components/Home/AdminSettings/Commision/Commision.types'
import { TOKEN_STORAGE_KEY } from '../../Types/Constants'
import { WebStorage } from '../../Utilities/WebStorage'

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()

export class AdminSettings {
    updateMarginConfig(companyId: string, marginFeeSettingsConfig: IMarginFeeSettingConfig) {
        return axios.post(`${baseUrl}/admin/settings/margin/update`, { companyId, marginFeeSettingsConfig }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getMarginFeeConfig(companyId: string) {
        return axios.get(`${baseUrl}/admin/settings/margin/get`, {
            params: {
                companyId
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getCountryConfig(country: string) {
        return axios.get(`${baseUrl}/admin/settings/country/get`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getTypeConfig(country: string, classification: string, trip_type: string) {
        return axios.get(`${baseUrl}/admin/settings/types/config`, {
            params: {
                country,
                classification,
                trip_type
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    saveCountryConfig(country: string, config: ICountryConfiguration) {
        return axios.post(`${baseUrl}/admin/settings/types/config/save`, { country, config }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    saveCountryConfigV2(country: string, config: any) {
        return axios.post(`${baseUrl}/admin/settings/types/config/save`, { country, config }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    saveNotificationSettings(notificationData: any) {
        return axios.post(`${baseUrl}/admin/settings/notification`, notificationData, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getNotifications() {
        return axios.get(`${baseUrl}/notifications`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getNotificationSettings() {
        return axios.get(`${baseUrl}/admin/settings/notification`, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateNotificationSettings(notificationId , notificationData: any) {
        return axios.put(`${baseUrl}/admin/settings/notification/${notificationId}`,notificationData, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    getCommissionFeeConfig(country: string) {
        return axios.get(`${baseUrl}/admin/settings/commission/get`, {
            params: {
                country
            },
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    updateCommissionConfig(country: string, commissionFeeSettingConfig: ICommisionFeeConfig) {
        return axios.post(`${baseUrl}/admin/settings/commission/update`, { country, commissionFeeSettingConfig }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }

    setDefaultRates(config: IRatesManagement) {
        return axios.post(`${baseUrl}/admin/settings/rates/set/default`, { config }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    setRatesConfig(config: IRatesManagement) {
        return axios.post(`${baseUrl}/admin/settings/rates/set`, { config }, {
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
    getRates(config: IRatesManagementConfig) {
        return axios.get(`${baseUrl}/admin/settings/rates/get`, {
            params: config,
            headers: {
                'Authorization': `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`
            }
        })
    }
}