import React from 'react';
import { Chart } from 'chart.js';

interface CustomLegendProps {
    legendItems: any[];
    chart: any; // Reference to the Chart.js instance
    width: number;
    height: number;
}

const CustomLegend: React.FC<CustomLegendProps> = ({ legendItems, chart, width, height }) => {
    const itemWidth = 200; // Maximum width for legend items
    const itemHeight = 20; // Height of each legend item

    const handleLegendClick = (index: number) => {
        const meta = chart.getDatasetMeta(0); // Assuming single dataset
        const dataset = meta.data[index];
        dataset.hidden = !dataset.hidden; // Toggle visibility
        chart.update();
    };

    return (
        <div style={{
            width: width,
            display: 'flex',
            flexWrap: 'wrap', // Allow items to wrap
            boxSizing: 'border-box',
            justifyContent: 'center'
        }}>
            {
                legendItems.map((item, index) => (
                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '5px', // Spacing between rows
                            cursor: 'pointer',
                            width: `calc(${itemWidth}px / 4)` // Adjust width for wrapping
                        }}
                        onClick={() => handleLegendClick(index)}
                    >
                        <div style={{
                            width: 10,
                            height: 10,
                            backgroundColor: item.fillStyle,
                            // borderRadius: 4,
                            marginRight: 10
                        }} />
                        <div style={{ fontSize: '12px' }}>{item.text}</div>
                    </div>
                ))
            }
        </div >
    );
};

export default CustomLegend;
