import { Button, Grid, Typography, Tooltip, Link } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import { TFunction } from "i18next";
import { IJobInfo, JobDataV2 } from "../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { IUserState } from "../../../Redux/User/type";
import { JOB_STATUS, TRIP_TYPE, USER_ROLES, COMPANY_TYPES } from "../../../Types/Constants";
import { ALL_JOBS, AVAILABLE_JOBS, DELIEVERED_JOBS, JOBS_IN_PROGRESS, MY_JOBS_IN_PROGRESS } from "../Sidebar/Sidebar.types";
import { ALL, AVAILABLE, COMPLETED_JOBS, IN_PROGRESS as IN_PROGRESS_ST, MY_BIDS, PENDING, POSTED, BIDS_TO_CONFIRM, MY_IN_PROGRESS, BIDS, PICKED_UP } from "./Jobs.constans";
import { TShowTypes } from "./Jobs.types";
import { formatDateTimeDisplay } from "../../../Utilities/DateTime";


const {
  IN_PROGRESS,
  PICKEDUP,
  DELIVERED,
  RETURNED,
  CANCELLED,
  COMPLETED,
  STORAGE,
  VIA_YARD,
  FUMIGATION,
  CREATED, OPEN, CLONED,
  ASSIGNED,
} = JOB_STATUS
const { FREIGHT_FORWARED, IMPORT_EXPORTER, SHIPPING_LINE, TRANSPORT_COMPANY, NVOCC } = COMPANY_TYPES


const {
  ROUND_TRIP
} = TRIP_TYPE

const {
  DRIVER_MANAGER,
  COMPANY,
  DRIVER,
  COMPANY_DRIVER,
  ADMIN_USER,
  SUPER_ADMIN,
  CUSTOMER,
  WAREHOUSE
} = USER_ROLES


export const getShowJobsTableColumns = (t: TFunction, showType: TShowTypes, currentUser: IUserState): GridColumns => [
 
  {
    field: 'reference',
    headerName: t('Reference'),
    width: 200
  },
  {
    field: 'customer_reference',
    headerName: t('Columns:customer_reference'),
    width: 200,
    hide: (

      [AVAILABLE, AVAILABLE_JOBS].includes(showType) ||
      !([ADMIN_USER, SUPER_ADMIN].includes(currentUser.response?.type ?? '')) &&
      !((([COMPANY].includes(currentUser.response?.type ?? '') && ![TRANSPORT_COMPANY].includes(currentUser.response?.company?.companyType))) && [PENDING, IN_PROGRESS, POSTED, MY_IN_PROGRESS, COMPLETED_JOBS, BIDS_TO_CONFIRM].includes(showType)) &&
      !((([COMPANY].includes(currentUser.response?.type ?? '') && [TRANSPORT_COMPANY].includes(currentUser.response?.company?.companyType))) && [IN_PROGRESS, MY_IN_PROGRESS, COMPLETED_JOBS].includes(showType))
    )
  },
  {
    field: 'pickup_address_city',
    headerName: t('Columns:collection_city'),
    sortable: false,
    width: 200,
  },
  {
    field: 'pickup_date_time',
    headerName: t('Columns:pick_up_date_time'),
    sortable: ![PENDING].includes(showType) ? false : true,
    width: 250,
    hide: ![AVAILABLE, IN_PROGRESS, PENDING].includes(showType),
    renderCell: (params) => {
      let job: JobDataV2 = params.row as JobDataV2
      return (
        <Typography>
          {formatDateTimeDisplay(job.pickup_date_time)}
        </Typography>
      )
    }
  },
  {
    field: 'delivery_address_city',
    headerName: t('Columns:delivery_city'),
    sortable: false,
    width: 200,
  },

  {
    field: 'delivery_date_time',
    headerName: t('Columns:deliver_to_date_time'),
    sortable: false,
    width: 250,
    hide: ![AVAILABLE].includes(showType),
    renderCell: (params) => {
      let job: JobDataV2 = params.row as JobDataV2
      return (
        <Typography>
          {formatDateTimeDisplay(job.delivery_date_time)}
        </Typography>
      )
    }
  },
  {
    field: 'job_type_code',
    headerName: t('Columns:job_type'),
    width: 200,
    hide: [COMPLETED_JOBS].includes(showType),
    sortable: false,

    renderCell: (params) => {
      let job: JobDataV2 = params.row as JobDataV2
      return (
        <Typography>
          {t(job.job_type_code)}
        </Typography>
      )
    }
  },
  {
    field: 'status',
    headerName: t('Columns:status'),
    sortable: false,
    width: 150,
    hide: [COMPLETED_JOBS].includes(showType),
    renderCell: (params) => {
      let job: JobDataV2 = params.row as JobDataV2
      return (
        <Typography>
          {t(job.status)}
        </Typography>
      )
    }
  }
]