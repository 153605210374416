import { useSelector } from "react-redux"
import { Route, RouteProps, Redirect } from "react-router-dom"
import { AppState } from "../../../Redux"
import { routes } from "../../../routes"



export const PrivateRoute = (routeProps: RouteProps) => {
    const isAuthenticated = useSelector((state: AppState) => state.auth.isAuthenticated);

    if (!isAuthenticated) {
        return <Redirect to={routes.landing.login[0]} />
    }
    return (
        <Route {...routeProps}>
            {routeProps.children}
        </Route>
    )
}
