import {
  TextareaAutosize,
  MenuItem,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  makeStyles,
  TextField,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {ICreateNewJobSeaFreightProps} from './CreateNewJobSeaFreight.types';
import TextFieldsIcon from '@material-ui/icons/TextFields';
// import AttachmentIcon from '@material-ui/icons/Attachment';
import {StaticDateTimePicker} from '@material-ui/pickers';
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CreateAddressDialog from '../../../Dialogs/CreateAddressDialog/CreateAddressDialog';
import {IAddress} from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import {Address} from '../../../../Api/Address/Address';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../../../Redux';
import {toast} from 'react-toastify';
import {
  JOB_STATUS,
  POST_TO,
  TYPES_OF_PACKAGES,
  COUNTRIES,
  MOVEMENT_TYPES,
  TRIP_TYPE,
  USER_ROLES,
  // COUNTRY_CODES,
  JOB_TYPES,
  VEHICLE_TYPES,
  CONTAINERS,
} from '../../../../Types/Constants';
import {groupBy, isEqual, isNil} from 'lodash';
import {Job} from '../../../../Api/Job/Job';
import {setLoader} from '../../../../Redux/Loader/actions';
import {IJobInfo} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import JobHistories from '../../../Common/JobHistories/JobHistories';
import {formatDateTime} from '../../../../Utilities/DateTime';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {IAutocompleteOption} from '../../../Common/types';
import {IAssociateGroupInfo} from '../../../../../../Application/DTOs/AssociateGroupDto/AssociateGroupDto.types';
import {Company} from '../../../../Api/Company/Company';
import MultiAutocomplete from '../../../Common/MultiAutocomplete/MultiAutocomplete';
import {ICountryConfiguration} from '../../../../../../Application/Models/CountryConfigurations/CountryConfigurations.types';
import {AdminSettings} from '../../../../Api/AdminSettings/AdminSettings';
import {isNumeric} from '../../../../Utilities/Regex';
import SingleAutocomplete from '../../../Common/SingleAutocomplete/SingleAutocomplete';
import FileInput from '../../../Common/FileInput/FileInput';
import JobFilesTable from '../../../Common/JobFilesTable/JobFilesTable';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  gap: {
    marginTop: 20,
  },
  selectLabel: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
  },
  btnCreate: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
}));

const addressApi = new Address();
const jobApi = new Job();

// const {AUSTRALIA: AU} = COUNTRY_CODES;

const {AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE} = COUNTRIES;

const {FCL, LCL} = MOVEMENT_TYPES;

const {ONE_WAY, ROUND_TRIP} = TRIP_TYPE;

const {CRATES, PACKAGES, PALLETS, PIECES} = TYPES_OF_PACKAGES;

const {ASSOCIATES, PUBLIC} = POST_TO;

const {CREATED, IN_PROGRESS} = JOB_STATUS;

const {ADMIN_USER, SUPER_ADMIN} = USER_ROLES;

const {OTHER: OTHER_JOB_TYPE} = JOB_TYPES;

const {OTHER: OTHER_VEHICLE_TYPE} = VEHICLE_TYPES;

const companyApi = new Company();
const adminSettingApi = new AdminSettings();

const associateGroupsToOptions = (
  associateGroup: IAssociateGroupInfo
): IAutocompleteOption => ({
  label: associateGroup.name,
  value: associateGroup.id,
});

function CreateNewJobSeaFreight(props: ICreateNewJobSeaFreightProps, ref: any) {
  const [t] = useTranslation('CreateNewJobSeaFreight');
  const dispatch = useDispatch();
  const user = useSelector((state: AppState) => state.user.response);
  const company = useSelector((state: AppState) => state.company);
  const currentUserCompanyId: any = user?.company?.id || undefined;
  const isUserAdmin = user?.type === ADMIN_USER;
  const isSuperAdmin = user?.type === SUPER_ADMIN;
  const [companyId, setCompanyId] = useState(
    isUserAdmin ? '' : currentUserCompanyId
  );
  const classes = useStyles();
  const [isCreateAddressDialogOpen, setIsCreateAddressDialogOpen] =
    useState(false);
  const [country, setCountry] = useState(
    isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA
  );
  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [temperature, setTemperature] = useState('');

  const [tripType, setTripType] = useState(ONE_WAY);
  const [movementType, setMovementType] = useState('');
  const [reference, setReference] = useState('');

  const [postingCompanyName, setPostingCompanyName] = useState('');
  const [biddingCompanyName, setBiddingCompanyName] = useState('');

  const [files, setFiles] = useState<any>([]);

  const [pickUpDateTime, setPickUpDateTime] = useState<Date | null>(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState<Date | null>(
    new Date()
  );
  const [returnDateTime, setReturnDateTime] = useState<Date | null>(new Date());
  const [typeOfPackage, setTypeOfPackage] = useState('');
  const [quantity, setQuantity] = useState('');
  const [declaredGrossWeight, setDeclaredGrossWeight] = useState('');
  const [volume, setVolume] = useState('');
  const [containerNo, setContainerNo] = useState('');
  const [sealNo, setSealNo] = useState('');
  const [emptyContainerReleaseNumber, setEmptyContainerReleaseNumber] =
    useState('');
  const [initialPrice, setInitialPrice] = useState('');
  const [wharfSlot, setWharfSlot] = useState('');
  const [postedPrice, setPostedPrice] = useState('');
  const [postTo, setPostTo] = useState(PUBLIC);
  const [jobInstruction, setJobInstruction] = useState('');
  const [isRefrigerated, setIsRefrigerated] = useState(false);
  const [hasDeliveryCharges, setHasDeliveryCharges] = useState(false);

  const [pickUpState, setPickUpState] = useState('');
  const [pickUpGroup, setPickUpGroup] = useState('');
  const [pickUpAddressId, setPickUpAddressId] = useState('');

  const [deliverToState, setDeliverToState] = useState('');
  const [deliverToGroup, setDeliverToGroup] = useState('');
  const [deliverToAddressId, setDeliverToAddressId] = useState('');

  const [returnToAddressId, setReturnToAddressId] = useState('');
  const [pickUpCountryCode, setPickUpCountryCode] = useState('');
  const [deliverToCountryCode, setDeliverToCountryCode] = useState('');
  const [selectedContainer, setSelectedContainer] =
    useState<IAutocompleteOption | null>(null);

  const [associateGroups, setAssociateGroups] = useState<IAutocompleteOption[]>(
    []
  );
  const [selectedAssociateGroups, setSelectedAssociateGroups] = useState<
    IAutocompleteOption[]
  >([]);

  const [movementTypes, setMovementTypes] = useState<string[]>([]);

  const useAlternateAddressSetup = [LCL, FCL].includes(movementType);

  const [isCompanyJobOwner, setIsCompanyJobOwner] = useState(true);

  // Additional Charges
  const [additionalChargeDescription1, setAdditionalChargeDescription1] =
    useState('');
  const [additionalChargeDescription2, setAdditionalChargeDescription2] =
    useState('');
  const [additionalChargeAmount1, setAdditionalChargeAmount1] =
    useState<any>(0);
  const [additionalChargeAmount2, setAdditionalChargeAmount2] =
    useState<any>(0);

  const {readonly, selectedJob, isEditMode, type: jobClassification} = props;

  const formatAddress = useCallback(
    (address: IAddress) =>
      `${address.company_name} ${address.street_number} ${address.city} ${
        address.state
      } ${t(address.country)}`,
    [t]
  );

  let currentPickUpAddressString = '';
  let currentDeliverToAddressString = '';

  if (selectedJob) {
    if (selectedJob.pick_up_address) {
      currentPickUpAddressString = selectedJob.pick_up_address.is_sea_freight
        ? selectedJob.pick_up_address.port_code
        : isCompanyJobOwner || isSuperAdmin || isUserAdmin || status !== CREATED
        ? formatAddress(selectedJob.pick_up_address)
        : selectedJob.pick_up_address.city;
    }

    if (selectedJob.deliver_to_address) {
      currentDeliverToAddressString = selectedJob.deliver_to_address
        .is_sea_freight
        ? selectedJob.deliver_to_address.port_code
        : isCompanyJobOwner || isSuperAdmin || isUserAdmin || status !== CREATED
        ? formatAddress(selectedJob.deliver_to_address)
        : selectedJob.deliver_to_address.city;
    }
  }

  const containerOptions: IAutocompleteOption[] = useMemo(
    () =>
      Object.values(CONTAINERS).map<IAutocompleteOption>((container) => ({
        label: t(container),
        value: container,
      })),
    [t]
  );

  const getCountryConfig = useCallback(() => {
    adminSettingApi
      .getCountryConfig(country)
      .then((response) => {
        const config: ICountryConfiguration = response.data;

        const movementTypesOptions =
          config.jobSettings[jobClassification].movementTypes;

        setMovementTypes(movementTypesOptions);

        if (movementTypesOptions.includes(LCL)) {
          setMovementType(LCL);
        } else {
          setMovementType('');
        }
      })
      .catch((err :any) => {
        console.log(`err`, err);
        toast.error(t('error_getting_config'));
      });
  }, [country, t, jobClassification]);

  const getAssociateGroups = useCallback(() => {
    dispatch(setLoader(true));
    companyApi
      .getCompanyAssociateGroups(companyId)
      .then((response) => {
        const associateGroups: IAssociateGroupInfo[] = response.data;

        setAssociateGroups(associateGroups.map(associateGroupsToOptions));

        dispatch(setLoader(false));
      })
      .catch((err :any) => {
        console.log('err', err);
        toast.error(t('error_getting_associate_groups'));
        dispatch(setLoader(false));
      });
  }, [dispatch, t, companyId]);

  const getAddresses = useCallback(
    (companyId) => {
      if (companyId === '' || isNil(companyId) || readonly) return;

      dispatch(setLoader(true));
      addressApi
        .allForJob(
          companyId,
          true,
          /*!readonly ? country : */ null,
          false,
          useAlternateAddressSetup,
          !useAlternateAddressSetup
        )
        .then((response) => {
          const addresses = response.data as IAddress[];
          setAddresses(addresses);
          dispatch(setLoader(false));
        })
        .catch((err :any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          dispatch(setLoader(false));
        });
    },
    [t, country, readonly, dispatch, useAlternateAddressSetup]
  );

  const setJobDetails = useCallback(
    (job: IJobInfo) => {
      const isCurrentCompanyPostingCompany: boolean = isEqual(
        job.company_id,
        currentUserCompanyId
      );
      setCountry(job.pick_up_address?.country || '');

      setPostingCompanyName(job.company?.companyName || '');
      setBiddingCompanyName(
        job.job_bids?.[0]?.user?.company?.companyName || ''
      );

      setPickUpAddressId(String(job.pickup_address_id));
      setDeliverToAddressId(String(job.delivery_address_id));
      setReturnToAddressId(String(job.return_address_id));
      setStatus(job.status);
      setReference(job.reference);

      setCompanyId(job.company_id);

      setMovementType(job.movement_type);
      setTripType(job.trip_type);


      setPickUpDateTime(job.pickup_date_time ? new Date(job.pickup_date_time) : null);

      setDeliveryDateTime(job.delivery_date_time ? new Date(job.delivery_date_time) : null);

      setReturnDateTime(
        job.return_date_time ? new Date(job.return_date_time) : null
      );

      setTypeOfPackage(job.package_type);

      setQuantity(job.quantity);
      setDeclaredGrossWeight(job.weight);
      setVolume(job.volume);
      setContainerNo(job.container_no);
      setSealNo(job.seal_number);
      setEmptyContainerReleaseNumber(job.ec_release_no);
      setInitialPrice(job.initial_price);
      setWharfSlot(job.wharf_slot);
      setPostedPrice(job.posted_price);
      setPostTo(job.post_to);
      setJobInstruction(job.instructions);

      setSelectedAssociateGroups(
        job.associateGroups.map(associateGroupsToOptions)
      );

      setIsCompanyJobOwner(isCurrentCompanyPostingCompany);
    },
    [currentUserCompanyId]
  );

  const handleDialogClose = useCallback(() => {
    getAddresses(companyId);
    setIsCreateAddressDialogOpen(false);
  }, [companyId, getAddresses]);

  const onCreateAddressDone = useCallback(() => {
    handleDialogClose();
  }, [handleDialogClose]);

  const handleCountryChange = (e: any) => {
    const currentCountry = e.target.value;
    setCountry(currentCountry);
  };

  const addressesByState: any = useMemo(() => {
    if (country === AUSTRALIA) {
      return groupBy(addresses, 'state');
    }

    return [];
  }, [country, addresses]);

  const addressesByCountryCode: any = useMemo(() => {
    if (country === AUSTRALIA) {
      return groupBy(addresses, 'country_code');
    }

    return [];
  }, [country, addresses]);

  const addressesByGroup: any = useMemo(() => {
    if (country !== AUSTRALIA) {
      return groupBy(addresses, 'address_group');
    }

    return [];
  }, [country, addresses]);

  const getUpdatedJobDetails = useCallback(
    (jobId: string | number) => {
      dispatch(setLoader(true));
      jobApi
        .jobDetail(jobId)
        .then((response) => {
          const job: IJobInfo = response.data;
          setJobDetails(job);
          dispatch(setLoader(false));
        })
        .catch((err :any) => {
          dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t('error_getting_job_details'));
        });
    },
    [setJobDetails, t, dispatch]
  );

  useEffect(() => {
    if (addresses.length > 0) {
      if (selectedJob && isEditMode) {
        const pickUpAddress = addresses.find(
          (address) => address.id === selectedJob.pickup_address_id
        );
        if (pickUpAddress && pickUpAddress.address_group) {
          setPickUpState(pickUpAddress.state);
          setPickUpGroup(pickUpAddress.address_group ?? 'Unknown');
        }

        const deliveryAddress = addresses.find(
          (address) => address.id === selectedJob.delivery_address_id
        );

        if (deliveryAddress && deliveryAddress.address_group) {
          setDeliverToState(deliveryAddress.state);
          setDeliverToGroup(deliveryAddress.address_group ?? 'Unknown');
        }
      }
    }
  }, [addresses, selectedJob, isEditMode]);

  useEffect(() => {
    getAddresses(companyId);
    // getCountryConfig();

    if (readonly) {
      if (selectedJob) {
        getUpdatedJobDetails(selectedJob.id);
      }
    } else {
      if (companyId !== '') getAssociateGroups();
    }
  }, [
    getAddresses,
    companyId,
    readonly,
    selectedJob,
    setJobDetails,
    getUpdatedJobDetails,
    getAssociateGroups,
    getCountryConfig,
  ]);

  const stateOptions = useMemo(
    () =>
      Object.keys(addressesByState).map((state, i) => (
        <MenuItem key={i} value={state}>
          {t(state)}
        </MenuItem>
      )),
    [addressesByState, t]
  );

  const countryCodeOptions = useMemo(
    () =>
      Object.keys(addressesByCountryCode).map((countryCode, i) => (
        <MenuItem key={i} value={countryCode}>
          {t(countryCode)}
        </MenuItem>
      )),
    [addressesByCountryCode, t]
  );

  const pickUpGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[pickUpState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>
      )),
    [addressesByState, pickUpState, t, addressesByGroup, country]
  );

  const pickUpAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[pickUpState], 'address_group')
          : addressesByGroup)[pickUpGroup] || []
      ).map((address: IAddress, i: number) => (
        <MenuItem key={i} value={address.id}>
          {formatAddress(address)}
        </MenuItem>
      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      pickUpGroup,
      pickUpState,
    ]
  );

  const pickUpAddressOptionsAutocomplete: IAutocompleteOption[] = useMemo(
    () =>
      (addressesByCountryCode[pickUpCountryCode] || []).map(
        (address: IAddress) => ({label: address.port_code, value: address.id})
      ),
    [addressesByCountryCode, pickUpCountryCode]
  );

  const deliverToGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[deliverToState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>
      )),
    [addressesByState, deliverToState, t, addressesByGroup, country]
  );

  const deliverToAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[deliverToState], 'address_group')
          : addressesByGroup)[deliverToGroup] || []
      ).map((address: IAddress, i: number) => (
        <MenuItem key={i} value={address.id}>
          {formatAddress(address)}
        </MenuItem>
      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      deliverToGroup,
      deliverToState,
    ]
  );

  // const deliverToAddressOptionsAlternate = useMemo(() => (addressesByCountryCode[deliverToCountryCode] || []).map((address: IAddress, i: number) => (<MenuItem key={i} value={address.id}>{address.port_code ?? ''}</MenuItem>)), [addressesByCountryCode, deliverToCountryCode])
  const deliverToAddressOptionsAutoComplete: IAutocompleteOption[] = useMemo(
    () =>
      (addressesByCountryCode[deliverToCountryCode] || []).map(
        (address: IAddress) => ({label: address.port_code, value: address.id})
      ),
    [addressesByCountryCode, deliverToCountryCode]
  );

  const companyListOptions = useMemo(
    () =>
      company.list.map((company, i) => (
        <MenuItem key={i} value={company.id}>
          {company.companyName}
        </MenuItem>
      )),
    [company.list]
  );

  function handleOnSaveAdditionalCharges(callback?: any) {
    if (selectedJob) {
      jobApi
        .updateAdditionalCharges(selectedJob.id, {
          description1: additionalChargeDescription1,
          description2: additionalChargeDescription2,
          amount1: additionalChargeAmount1,
          amount2: additionalChargeAmount2,
        })
        .then(() => {
          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch((err :any)=> {
          toast.error(t('error_updating_additional_charges'));
        });
    }
  }

  const handleCreateOrUpdateClick = (callback?: any) => {
    const formData = new FormData();

    if ((isSuperAdmin || isUserAdmin) && companyId === '') {
      toast.warning(t('company_not_empty'));
      return;
    } else if (movementType === '') {
      toast.warn(t('movement_type_not_empty'));
      return;
    } else if (reference === '') {
      toast.warn(t('reference_not_empty'));
      return;
    } else if (declaredGrossWeight === '') {
      toast.warn(t('declared_gross_weight_not_empty'));
      return;
    }

    if (movementType !== '' && movementType === LCL) {
      if (volume === '') {
        toast.warn(t('volume_not_empty'));
        return;
      }
    }

    if (temperature !== '' && isRefrigerated) {
      if (temperature === '') {
        toast.warn(t('temperature_not_empty'));
        return;
      }
    }

    formData.append('reference', reference);
    formData.append('company_id', companyId);
    formData.append('movement_type', movementType);
    formData.append('job_type', OTHER_JOB_TYPE);
    formData.append('vehicle_type', OTHER_VEHICLE_TYPE);
    formData.append('pickup_address_id', pickUpAddressId);
    formData.append('pickup_date_time', pickUpDateTime as any);
    formData.append('delivery_address_id', deliverToAddressId);
    formData.append('delivery_date_time', deliveryDateTime as any);
    if (tripType === ROUND_TRIP) {
      formData.append('return_address_id', returnToAddressId);
      formData.append('return_date_time', returnDateTime as any);
    }
    formData.append('trip_type', tripType);
    formData.append('container_no', containerNo);
    formData.append('seal_number', sealNo);
    formData.append('package_type', typeOfPackage);
    formData.append('quantity', quantity);
    formData.append('weight', declaredGrossWeight);
    formData.append('volume', volume);
    formData.append('posted_price', postedPrice);
    formData.append('initial_price', initialPrice);
    formData.append('margin_price', '0');
    formData.append('status', status ? status : CREATED);
    formData.append('reason', '');
    // formData.append('job_wait_time', '')
    formData.append('instructions', jobInstruction);
    formData.append('ec_release_no', emptyContainerReleaseNumber);
    formData.append('wharf_slot', wharfSlot);
    formData.append('post_to', postTo);
    formData.append('is_split_delivery', 'false');
    formData.append('is_fixed_date', 'true');
    formData.append('temperature', temperature);
    formData.append('lcl_type', '');
    formData.append('job_classification', props.type);
    formData.append('refrigerated', isRefrigerated.toString());
    formData.append('has_destination_charges', hasDeliveryCharges.toString());
    formData.append(
      'associate_group_ids',
      JSON.stringify(selectedAssociateGroups.map((opt) => opt.value))
    );

    if (selectedContainer) {
      formData.append('container', selectedContainer.value);
    }

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formData.append('files', file);
    }

    dispatch(setLoader(true));

    if (!isEditMode) {
      jobApi
        .create(formData)
        .then(() => {
          toast.success(t('success_creating_job'));
          dispatch(setLoader(false));
        })
        .catch((err :any) => {
          dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t(err.response?.data || 'error_creating_job'));
        });
    } else {
      if (selectedJob) {
        jobApi
          .update(selectedJob.id, formData)
          .then(() => {
            toast.success(t('success_updating_job'));
            dispatch(setLoader(false));
            if (callback) callback();
          })
          .catch((err :any) => {
            dispatch(setLoader(false));
            console.log('err', err);
            toast.error(t(err.response?.data || 'error_updating_job'));
          });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate: (callback: any) => {
      handleCreateOrUpdateClick(callback);
    },
  }));

  const hideNewAddressButton =
    country === AUSTRALIA ? [LCL, FCL].includes(movementType) : false;

  const handleAssociateGroupSelect = useCallback(
    (values: IAutocompleteOption[]) => {
      setSelectedAssociateGroups(values);
    },
    []
  );

  const marginPrice = Number(selectedJob?.margin_price || '0');
  const bidPrice =
    (selectedJob?.job_bids ?? []).length > 0
      ? selectedJob?.job_bids?.[0]?.amount || 0
      : 0;
  const agreedBid =
    isCompanyJobOwner || isSuperAdmin || isUserAdmin
      ? (selectedJob?.job_bids ?? []).length > 0
        ? bidPrice + marginPrice
        : 0
      : (selectedJob?.job_bids ?? []).length > 0
      ? bidPrice
      : 0;
  const jobPostedPrice =
    selectedJob?.subtracted_initial_price || 0 + marginPrice;
  const jobInitialPrice =
    selectedJob?.subtracted_initial_price || 0 + marginPrice;
  const jobPrice = jobPostedPrice === 0 ? jobPostedPrice : jobInitialPrice;
  const priceDiff =
    (selectedJob?.job_bids ?? []).length > 0 ? jobPrice - agreedBid : 0;

  return (
    <>
      <CreateAddressDialog
        isCommon ={true}
        type={jobClassification}
        open={isCreateAddressDialogOpen}
        handleClose={handleDialogClose}
        onDone={onCreateAddressDone}
      />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={5}
      >
        {!readonly && !hideNewAddressButton ? (
          <>
            <Grid md={4} lg={3} item>
              <Button
                fullWidth
                onClick={() => setIsCreateAddressDialogOpen(true)}
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
              >
                {t('add_new_address')}
              </Button>
            </Grid>
           
          </>
        ) : null}
        <Grid md item>
          <Grid container justifyContent="flex-start" direction="row" spacing={4}>
            {readonly && (isSuperAdmin || isUserAdmin || !isCompanyJobOwner) ? (
              <Grid item md={12}>
                <TextField
                  disabled={true}
                  value={postingCompanyName}
                  onChange={(e) => setReference(e.target.value)}
                  label={t('posting_company_name')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            {readonly &&
            (isSuperAdmin || isUserAdmin || isCompanyJobOwner) &&
            status !== CREATED ? (
              <Grid item md={12}>
                <TextField
                  disabled={true}
                  value={biddingCompanyName}
                  onChange={(e) => setReference(e.target.value)}
                  label={t('bidding_company_name')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            {isUserAdmin && !readonly && !isEditMode ? (
              <Grid item md={12}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('select_company')}
                  </InputLabel>
                  <Select
                    value={companyId}
                    onChange={(e) => {
                      const companyId = e.target.value;
                      const selectedCompany = company.list.find(
                        (c) => c.id === companyId
                      );

                      if (selectedCompany) {
                        setCountry(selectedCompany.country);
                        getAddresses(companyId);
                      }

                      setCompanyId(companyId);
                    }}
                  >
                    {companyListOptions}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {/* {isUserAdmin && !readonly ? (
              <Grid item md={12}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('select_your_country')}
                  </InputLabel>
                  <Select
                    disabled={readonly || isUserAdmin}
                    value={country}
                    onChange={handleCountryChange}
                  >
                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            ) : null} */}

            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('movement_type') + '*'}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={movementType}
                  onChange={(e) => {
                    const value: string = e.target.value as string;
                    setMovementType(value);

                    if (country === AUSTRALIA) {
                      setPickUpState('');
                      setPickUpGroup('');
                      setPickUpAddressId('');
                      setDeliverToState('');
                      setDeliverToGroup('');
                      setDeliverToAddressId('');
                    }
                  }}
                >
                  {movementTypes.map((movementType, i) => (
                    <MenuItem key={i} value={movementType}>
                      {t(movementType)}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={LCL}>{t(LCL)}</MenuItem>
                                    <MenuItem value={FCL}>{t(FCL)}</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={readonly}
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                fullWidth
                label={t('your_reference') + '*'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {!readonly ? (
              <Grid item md={6}>
                <FileInput
                  onChange={(e: any) => setFiles(e.target.files)}
                  disabled={readonly}
                  multiple
                />
              </Grid>
            ) : (
              <Grid item md={6}>
                {/* GAP */}
              </Grid>
            )}
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {useAlternateAddressSetup
                  ? t('port_of_loading')
                  : t('pick_up_from')}
              </Typography>
            </Grid>
            {!readonly ? (
              <>
                {useAlternateAddressSetup ? (
                  <>
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('country_codes')}
                        </InputLabel>
                        <Select
                          value={pickUpCountryCode}
                          onChange={(e) =>
                            setPickUpCountryCode(e.target.value as string)
                          }
                          disabled={readonly}
                        >
                          {countryCodeOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <Autocomplete
                        disableClearable
                        options={pickUpAddressOptionsAutocomplete}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, option) => {
                          setPickUpAddressId(option?.value);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('port_codes')}
                            variant="filled"
                          />
                        )}
                      />
                      {/* <FormControl variant="filled" className={classes.formControl}>
                                                            <InputLabel className={classes.selectLabel}>{t('port_codes')}</InputLabel>
                                                            <Select
                                                                disabled={readonly}
                                                                value={pickUpAddressId}
                                                                onChange={(e) => {
                                                                    setPickUpAddressId(e.target.value as any)
                                                                }}
                                                            >
                                                                {pickUpAddressOptionsAlternate}
                                                            </Select>
                                                        </FormControl> */}
                    </Grid>
                  </>
                ) : (
                  <>
                    {country === AUSTRALIA ? (
                      <Grid item md={12}>
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                        >
                          <InputLabel className={classes.selectLabel}>
                            {t('pickup_select_state')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={pickUpState}
                            onChange={(e) => {
                              setPickUpState(e.target.value as any);
                              setPickUpGroup('');
                              setPickUpAddressId('');
                            }}
                          >
                            {stateOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('pickup_select_group')}
                        </InputLabel>
                        <Select
                          disabled={readonly}
                          value={pickUpGroup}
                          onChange={(e) => {
                            setPickUpGroup(e.target.value as any);
                            setPickUpAddressId('');
                          }}
                        >
                          {pickUpGroupOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('pickup_select_address')}
                        </InputLabel>
                        <Select
                          disabled={readonly}
                          value={pickUpAddressId}
                          onChange={(e) => {
                            setPickUpAddressId(e.target.value as any);
                          }}
                        >
                          {pickUpAddressOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  value={currentPickUpAddressString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('pick_up_date_time')}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {!readonly ? (
                <StaticDateTimePicker
                  toolbarTitle={t('select_date_time')}
                  disabled={readonly}
                  renderInput={(props) => (
                    <TextField fullWidth variant="outlined" {...props} />
                  )}
                  value={pickUpDateTime}
                  onChange={setPickUpDateTime}
                />
              ) : (
                <TextField
                  disabled={true}
                  value={formatDateTime(pickUpDateTime)}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {useAlternateAddressSetup
                  ? t('port_of_destination')
                  : t('deliver_to')}
              </Typography>
            </Grid>
            {!readonly ? (
              <>
                {useAlternateAddressSetup ? (
                  <>
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('country_codes')}
                        </InputLabel>
                        <Select
                          value={deliverToCountryCode}
                          onChange={(e) =>
                            setDeliverToCountryCode(e.target.value as string)
                          }
                          disabled={readonly}
                        >
                          {countryCodeOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <Autocomplete
                        disableClearable
                        options={deliverToAddressOptionsAutoComplete}
                        getOptionLabel={(option) => option.label}
                        onChange={(e, option) => {
                          setDeliverToAddressId(option?.value);
                        }}
                        fullWidth
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('port_codes')}
                            variant="filled"
                          />
                        )}
                      />
                      {/* <FormControl variant="filled" className={classes.formControl}>
                                                            <InputLabel className={classes.selectLabel}>{t('port_codes')}</InputLabel>
                                                            <Select
                                                                disabled={readonly}
                                                                value={deliverToAddressId}
                                                                onChange={(e) => {
                                                                    setDeliverToAddressId(e.target.value as any)
                                                                }}
                                                            >
                                                                {deliverToAddressOptionsAlternate}
                                                            </Select>
                                                        </FormControl> */}
                    </Grid>
                  </>
                ) : (
                  <>
                    {country === AUSTRALIA ? (
                      <Grid item md={12}>
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                        >
                          <InputLabel className={classes.selectLabel}>
                            {t('deliver_select_state')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={deliverToState}
                            onChange={(e) => {
                              setDeliverToState(e.target.value as any);
                              setDeliverToGroup('');
                              setDeliverToAddressId('');
                            }}
                          >
                            {stateOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('deliver_select_group')}
                        </InputLabel>
                        <Select
                          disabled={readonly}
                          value={deliverToGroup}
                          onChange={(e) => {
                            setDeliverToGroup(e.target.value as any);
                            setDeliverToAddressId('');
                          }}
                        >
                          {deliverToGroupOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('deliver_select_address')}
                        </InputLabel>
                        <Select
                          disabled={readonly}
                          value={deliverToAddressId}
                          onChange={(e) => {
                            setDeliverToAddressId(e.target.value as any);
                          }}
                        >
                          {deliverToAddressOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </>
            ) : (
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  value={currentDeliverToAddressString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('deliver_to_date_time')}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {!readonly ? (
                <StaticDateTimePicker
                  toolbarTitle={t('select_date_time')}
                  disabled={readonly}
                  renderInput={(props) => (
                    <TextField fullWidth variant="outlined" {...props} />
                  )}
                  value={deliveryDateTime}
                  onChange={setDeliveryDateTime}
                />
              ) : (
                <TextField
                  disabled={true}
                  value={formatDateTime(deliveryDateTime)}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('type_of_packages')}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={typeOfPackage}
                  onChange={(e) => setTypeOfPackage(e.target.value as string)}
                >
                  <MenuItem value={CRATES}>{t(CRATES)}</MenuItem>
                  <MenuItem value={PACKAGES}>{t(PACKAGES)}</MenuItem>
                  <MenuItem value={PALLETS}>{t(PALLETS)}</MenuItem>
                  <MenuItem value={PIECES}>{t(PIECES)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={readonly}
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                fullWidth
                label={t('quantity')}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={readonly}
                value={declaredGrossWeight}
                onChange={(e) => {
                  const value = e.target.value;
                  if (isNumeric(value) || value === '')
                    setDeclaredGrossWeight(value);
                }}
                fullWidth
                label={t('declared_gross_weight') + '*'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={readonly}
                value={volume}
                onChange={(e) => {
                  const value = e.target.value;
                  if (isNumeric(value) || value === '') setVolume(value);
                }}
                fullWidth
                label={t('volume') + (+[LCL].includes(movementType) ? '*' : '')}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={readonly}
                value={initialPrice}
                onChange={(e) => setInitialPrice(e.target.value)}
                fullWidth
                label={t('initial_price')}
                type="number"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {isUserAdmin ? (
              <>
                <Grid item md={4}>
                  <TextField
                    disabled={readonly}
                    value={postedPrice}
                    onChange={(e) => setPostedPrice(e.target.value)}
                    fullWidth
                    label={t('posted_price')}
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {readonly &&
            selectedJob &&
            (isCompanyJobOwner || isSuperAdmin || isUserAdmin) &&
            status !== CREATED ? (
              <>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={agreedBid}
                    fullWidth
                    label={t('agreed_price')}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={priceDiff.toString().replaceAll('-', '')}
                    fullWidth
                    label={t('price_diff')}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('post_to')}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={postTo}
                  onChange={(e) => setPostTo(e.target.value as string)}
                >
                  <MenuItem value={PUBLIC}>{t(PUBLIC)}</MenuItem>
                  <MenuItem value={ASSOCIATES}>{t(ASSOCIATES)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {postTo === ASSOCIATES && isCompanyJobOwner ? (
              <>
                <Grid item md={12}>
                  <MultiAutocomplete
                    disabled={readonly}
                    label={t('asscoiate_groups')}
                    options={associateGroups}
                    values={selectedAssociateGroups}
                    onChange={handleAssociateGroupSelect}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={readonly}
                    color="primary"
                    checked={isRefrigerated}
                    onChange={(e) => setIsRefrigerated(e.target.checked)}
                  />
                }
                label={t('refrigerated')}
              />
            </Grid>
            {isRefrigerated ? (
              <>
                <Grid item md={6}>
                  <TextField
                    value={temperature}
                    onChange={(e) => setTemperature(e.target.value)}
                    disabled={readonly}
                    fullWidth
                    label={t('temperature') + (isRefrigerated ? '*' : '')}
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {[LCL].includes(movementType) ? (
              <>
                <Grid md={6} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={readonly}
                        color="primary"
                        checked={hasDeliveryCharges}
                        onChange={(e) =>
                          setHasDeliveryCharges(e.target.checked)
                        }
                      />
                    }
                    label={t('has_destination_charges')}
                  />
                </Grid>
              </>
            ) : null}
            {[LCL].includes(movementType) ? (
              <Grid item md={12}>
                <SingleAutocomplete
                  disabled={readonly}
                  label={t('container')}
                  options={containerOptions}
                  value={selectedContainer}
                  onChange={(value) => setSelectedContainer(value)}
                />
              </Grid>
            ) : null}

            {/* Additional Charges */}
            <Grid item md={12}>
              <Typography>{t('additional_charges')}</Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={props.isInProgress}
                value={additionalChargeDescription1}
                onChange={(e) => {
                  const value = e.target.value;
                  setAdditionalChargeDescription1(value);
                }}
                fullWidth
                label={t('charge_description') + ' (1)'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={props.isInProgress}
                value={additionalChargeAmount1}
                onChange={(e) => {
                  const regex = /^[+-]?\d*(?:[.,]\d*)?$/;
                  if (regex.test(e.target.value)) {
                    setAdditionalChargeAmount1(e.target.value);
                  }
                }}
                fullWidth
                label={t('charge_amount') + ' (1)'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={props.isInProgress}
                value={additionalChargeDescription2}
                onChange={(e) => {
                  const value = e.target.value;
                  setAdditionalChargeDescription2(value);
                }}
                fullWidth
                label={t('charge_description') + ' (2)'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={props.isInProgress}
                value={additionalChargeAmount2}
                onChange={(e) => {
                  const regex = /^[+-]?\d*(?:[.,]\d*)?$/;
                  if (regex.test(e.target.value)) {
                    setAdditionalChargeAmount2(e.target.value);
                  }
                }}
                fullWidth
                label={t('charge_amount') + ' (2)'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {selectedJob && selectedJob.status === IN_PROGRESS && (
              <>
                <Grid item md={9} />
                <Grid item md={3}>
                  <Button
                    onClick={handleOnSaveAdditionalCharges}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {t('save_additional_charges')}
                  </Button>
                </Grid>
              </>
            )}

            {/* End Additional Charges */}

            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('job_instruction')}
              </Typography>
            </Grid>
            <Grid item md={12}>
              <TextareaAutosize
                disabled={readonly}
                value={jobInstruction}
                onChange={(e) => setJobInstruction(e.target.value)}
                placeholder={t('job_instruction')}
                className={classes.textArea}
                minRows={5}
              />
            </Grid>
            {selectedJob ? (
              <Grid item md={12}>
                <JobFilesTable job={selectedJob} />
              </Grid>
            ) : null}
            {readonly &&
            selectedJob &&
            (isCompanyJobOwner ||
              isSuperAdmin ||
              isUserAdmin ||
              status !== CREATED) ? (
              <Grid item md={12}>
                <JobHistories job={selectedJob} />
              </Grid>
            ) : null}
            {!readonly && !isEditMode && selectedJob?.status !== IN_PROGRESS ? (
              <>
                <Grid md={2} item>
                  <Button
                    onClick={handleCreateOrUpdateClick}
                    fullWidth
                    className={classes.btnCreate}
                    startIcon={<DoneIcon />}
                    color="primary"
                    variant="contained"
                  >
                    {t('create')}
                  </Button>
                </Grid>
                <Grid md={10} item>
                  {/* GAP */}
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default forwardRef(CreateNewJobSeaFreight);
