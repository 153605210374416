import {
  TextareaAutosize,
  MenuItem,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Typography,
  makeStyles,
  Divider,
  TextField,
  InputAdornment,
  GridList,
  GridListTile,
  FormControlLabel,
  Checkbox,
  Badge,
  Tooltip
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ICreateNewJobProps } from './CreateNewJob.types';
import AddIcon from '@material-ui/icons/Add';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import { StaticDateTimePicker, DateTimePicker, MobileDatePicker, MobileDateTimePicker } from '@material-ui/pickers';
import React, {
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import CreateAddressDialog from '../../../Dialogs/CreateAddressDialog/CreateAddressDialog';
import { IAddress } from '../../../../../../Application/DTOs/AddressDto/AddressDto.types';
import { Address } from '../../../../Api/Address/Address';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../Redux';
import { toast } from 'react-toastify';

import {
  JOB_STATUS,
  POST_TO,
  TYPES_OF_PACKAGES,
  COUNTRIES,
  MOVEMENT_TYPES,
  TRIP_TYPE,
  USER_ROLES,
  JOB_FILES_TYPE,
  VEHICLE_TYPES_CONFIG,
  JOB_TYPES,
  CREATE_JOB_ELEMENTS,
  TEMPERATURELIST,
  TEMPERATURE,
  VEHICLE_TYPES
} from '../../../../Types/Constants';
import _, { conforms, get, groupBy, isEqual, isNil, toUpper, update } from 'lodash';
import { Job } from '../../../../Api/Job/Job';
import { setLoader } from '../../../../Redux/Loader/actions';
import {
  IJobFileInfo,
  IJobInfo,
} from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import TodayIcon from '@material-ui/icons/Today';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import JobHistories from '../../../Common/JobHistories/JobHistories';
import { formatDateTime, formatDateTimeDisplay, formatInputDateTime, formatSaveOffset, formatReadOffset } from '../../../../Utilities/DateTime';
import { isNonNumeric, isNumeric } from '../../../../Utilities/Regex';
import { Company } from '../../../../Api/Company/Company';
import { IAssociateGroupInfo } from '../../../../../../Application/DTOs/AssociateGroupDto/AssociateGroupDto.types';
import { IAutocompleteOption } from '../../../Common/types';
import MultiAutocomplete from '../../../Common/MultiAutocomplete/MultiAutocomplete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EditIcon from '@material-ui/icons/Edit';

import SelectUserDialog from './SelectUserDialog/SelectUserDialog';
import RequiredDocuments from '../../../Common/RequiredDocument/RequiredDocuments'
import { AdminSettings } from '../../../../Api/AdminSettings/AdminSettings';
import { IConfiguration, ICountryConfiguration } from '../../../../../../Application/Models/CountryConfigurations/CountryConfigurations.types';
import JobFilesTable from '../../../Common/JobFilesTable/JobFilesTable';
import FileInput from '../../../Common/FileInput/FileInput';
import { IN_PROGRESS } from '../../Jobs/Jobs.constans';
import { formatFileUrl } from '../../../../Utilities/Formatting';
import Image from '../../../Common/Image/Image';
import ImagePreviewDialog from '../../../Dialogs/ImagePreviewDialog/ImagePreviewDialog';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../../routes';
import MultiplePackageType from '../../../Common/Packages/Packages'
import moment from 'moment';
import CommentDialog from '../../../Dialogs/CommentDialog/CommentDialog';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import PalletAccount from '../../../Common/PalletAccount/PalletAccount';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  },
  gap: {
    marginTop: 20,
  },
  selectLabel: {
    fontWeight: 'bold',
  },
  textArea: {
    width: '100%',
  },
  btnCreate: {
    backgroundColor: 'green',
    color: 'white',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  gridList: {
    width: 500,
    height: 450,
  },
}));

const addressApi = new Address();
const jobApi = new Job();
const companyApi = new Company();
const adminSettingApi = new AdminSettings();

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;

const { FULL_TRUCK_LOAD, LOOSE_CARGO, EMPTY_PARK, WHARF, RAIL, OTHER } = MOVEMENT_TYPES;
const { REFEER_20, REFEER_40, HC_20, HC_40 } = JOB_TYPES;

const { CHILLED, FROZEN, AMBIENT } = TEMPERATURE;


const { ONE_WAY, ROUND_TRIP } = TRIP_TYPE;


const { ASSOCIATES, PUBLIC, HIDDEN } = POST_TO;

const { CREATED, COMPLETED, FUMIGATION, VIA_YARD, STORAGE, PICKEDUP, ASSIGNED, DELIVERED, CLONED } = JOB_STATUS;

const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER } = USER_ROLES;

const { DAMAGE_PHOTO } = JOB_FILES_TYPE;
const { REEFER } = VEHICLE_TYPES;


const { QUANTITY, PACKAGES_TYPE, VOLUME, CN, ECRNUM, SEAL_NO, WS, CNTR } =
  CREATE_JOB_ELEMENTS;

const dateNow = new Date();

const associateGroupsToOptions = (
  associateGroup: IAssociateGroupInfo
): IAutocompleteOption => ({
  label: associateGroup.name,
  value: associateGroup.id,
});

function CreateNewJob(props: ICreateNewJobProps, ref: any) {
  const [t] = useTranslation('CreateNewJob');
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [isCreateAddressDialogOpen, setIsCreateAddressDialogOpen] =
    useState(false);
  const user = useSelector((state: AppState) => state.user.response);

  const company = useSelector((state: AppState) => state.company);

  const currentUserCompanyId: any = user?.company?.id || undefined;
  const currentuserId: any = user?.id || undefined;

  const isUserAdmin = user?.type === ADMIN_USER;
  const isSuperAdmin = user?.type === SUPER_ADMIN;
  const isDriverManager = user?.type === DRIVER_MANAGER;
  const [country, setCountry] = useState(isSuperAdmin || isUserAdmin ? AUSTRALIA : user?.company?.country || AUSTRALIA);
  const [triggerUpdate, setTriggerUpdate] = useState<number>(1);

  const [companyId, setCompanyId] = useState(
    isSuperAdmin || isUserAdmin ? '' : currentUserCompanyId
  );

  const [addresses, setAddresses] = useState<IAddress[]>([]);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [isBonds, setIsBonds] = useState<boolean>(true);

  const [jobType, setJobType] = useState('');
  const [tripType, setTripType] = useState(ONE_WAY);
  const [movementType, setMovementType] = useState('');
  const [reference, setReference] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [temperature, setTemperature] = useState(AMBIENT);
  const [temperatureValue, setTemperatureValue] = useState('');


  const [files, setFiles] = useState<any>([]);
  const [photosOfDamages, setPhotosOfDamages] = useState<any>([]);
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>(undefined);
  const [selectedAddressType, setSelectedAddressType] = useState('');;

  const [selectedAddressMode, setSelectedAddressMode] = useState('create');

  const [pickUpDateTime, setPickUpDateTime] = useState<Date | null | string>(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState<Date | null | string>(new Date());
  const [returnDateTime, setReturnDateTime] = useState<Date | null | string>(new Date());
  const [typeOfPackage, setTypeOfPackage] = useState('');
  const [quantity, setQuantity] = useState('');
  const [declaredGrossWeight, setDeclaredGrossWeight] = useState('');
  const [volume, setVolume] = useState('');
  const [containerNo, setContainerNo] = useState('');
  const [CNTRLastDay, setCNTRLastDay] = useState<Date | null>(new Date());


  const [sealNo, setSealNo] = useState('');
  const [emptyContainerReleaseNumber, setEmptyContainerReleaseNumber] =
    useState('');
  const [initialPrice, setInitialPrice] = useState<any>(0);
  const [wharfSlot, setWharfSlot] = useState('');
  const [postedPrice, setPostedPrice] = useState<any>(0);
  const [postTo, setPostTo] = useState(PUBLIC);
  const [jobInstruction, setJobInstruction] = useState('');
  const [postingCompanyName, setPostingCompanyName] = useState('');

  const [biddingCompanyName, setBiddingCompanyName] = useState('');
  const [deliverInstruction, setDeliverInstruction] = useState('');
  const [pickUpInstruction, setPickupInstruction] = useState('');

  const [deliverPalletsConf, setDeliverPalletsConf] = useState([]);
  const [pickUpPalletsConf, setPickUpPalletsConf] = useState([]);

  const [pickUpReceivingHours, setPickUpReceivingHours] = useState('');
  const [deliveryReceivingHours, setDeliveryReceivingHours] = useState('');

  const [mergedInstruction, setMergedInstruction] = useState('');

  const [selectedRequiredDocuments, setSelectedRequiredDocuments] = useState<any[]>([]);
  const [currentSelectedNoteUser, setCurrentSelectedNoteUser] = useState('admin')


  const [bondsJobId, setBondsJobId] = useState('');


  const [jobFiles, setJobFiles] = useState<IJobFileInfo[] | undefined>(
    undefined
  );

  const [imageToPreview, setImageToPreview] = useState<string | undefined>(
    undefined
  );
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  const [isCompanyJobOwner, setIsCompanyJobOwner] = useState(true);

  const [pickUpState, setPickUpState] = useState('');
  const [pickUpGroup, setPickUpGroup] = useState('');
  const [pickUpAddressId, setPickUpAddressId] = useState('');

  const [deliverToState, setDeliverToState] = useState('');
  const [deliverToGroup, setDeliverToGroup] = useState('');
  const [deliverToAddressId, setDeliverToAddressId] = useState('');

  const [deliverToStateType, setDeliverToStateType] = useState('');


  const [returnToState, setReturnToState] = useState('');
  const [returnToGroup, setReturnToGroup] = useState('');
  const [returnToAddressId, setReturnToAddressId] = useState('');

  const [associateGroups, setAssociateGroups] = useState<IAutocompleteOption[]>(
    []
  );
  const [selectedAssociateGroups, setSelectedAssociateGroups] = useState<
    IAutocompleteOption[]
  >([]);


  const [isSelectUserDialogOpen, setIsSelectUserDialogOpen] = useState(false);
  const [isRequiredDialogOpen, setIsRequiredDialogOpen] = useState(false);



  const [movementTypes, setMovementTypes] = useState<string[]>([]);
  const [jobTypes, setJobTypes] = useState<string[]>([]);

  const [showAdditionalSteps, setShowAdditionalSteps] = useState<boolean>(true);

  const [addStepDatetime, setAddStepDatetime] = useState<Date | null>(null);
  const [addStepEndDatetime, setAddStepEndDatetime] = useState<Date | null>(null);
  const [additionalStepStatus, setAdditionalStepStatus] = useState('');

  const [CNTRDehireDay, setCNTRDehireDay] = useState<Date | null>(null);
  const [customerReference, setCustomerReference] = useState('');

  //pallets
  const [deliverPalletValue, setDeliverPalletValue] = useState('');
  const [pickUpPalletValue, setPickUpPalletValue] = useState('');
  const [palletAccountType, setPalletAccountType] = useState('');
  const [palletAccountAction, setPalletAccountAction] = useState('');
  const [palletAccountValue, setPalletAccountValue] = useState('');


  const setTypeOfPackageRef = useRef();

  const [allocationSelectedJob, setAllocationSelectedJob] = useState<any>({});
  interface LooseObject {
    [key: string]: any
  }

  const [configJobTypes, setConfigJobTypes] = useState<LooseObject>({})



  const handleSelectUserDialogClose = useCallback(() => {
    setIsSelectUserDialogOpen(false);
  }, []);
  const handleRequiredDocumentClose = useCallback(() => {
    setIsRequiredDialogOpen(false);
  }, []);
  const [rawDocument, setRawDocuments] = useState<any[]>([]);


  const { readonly, selectedJob, isEditMode, type: _jobClassification, isJobOwner, isAssignedUser, cntrReadOnly, documentUploadReadOnly, mode, readOnlyBidder } = props;

  const [typeOfPackageList, setTypeOfPackageList] = useState<any[]>(selectedJob?.packages_type || []);

  const isCreatteAllocationJob =
    props.type === 'assigned-transport' && props.mode === 'create';
  const jobClassification =
    _jobClassification === 'assigned-transport'
      ? 'transport'
      : _jobClassification;

  const formatAddress = useCallback(
    (address: IAddress) =>
      `${address.street_number} ${address.city} ${address.state} ${t(address.country)}`,
    [t]
  );

  const [currentPickUpAddressString, setCurrentPickUpAddressString] =
    useState('');
  const [currentDeliverToAddressString, setCurrentDeliverToAddressString] =
    useState('');
  const [currentReturnToAddressString, setCurrentReturnToAddressString] =
    useState('');

  // Additional Charges
  const [additionalChargeDescription1, setAdditionalChargeDescription1] =
    useState('');
  const [additionalChargeDescription2, setAdditionalChargeDescription2] =
    useState('');
  const [additionalChargeAmount1, setAdditionalChargeAmount1] =
    useState<any>(0);
  const [additionalChargeAmount2, setAdditionalChargeAmount2] =
    useState<any>(0);

  // Assigned job
  const [transportRef, setTransportRef] = useState('');
  const [transportCompany, setTransportCompany] = useState('');
  const [isCommentDialogOpen, setIsCommentDialogOpen] = useState(false);

  //notes
  const [jobCommentData, setJobCommentData] = useState<any>({})

  const getCountryConfig = useCallback(() => {
    adminSettingApi
      .getCountryConfig(country)
      .then((response) => {
        const config: ICountryConfiguration = response.data;

        setMovementTypes(config.jobSettings[jobClassification].movementTypes);
        setJobTypes(config.jobSettings[jobClassification].jobTypes);

      })
      .catch((err: any) => {
        console.log(`err`, err);
        toast.error(t('error_getting_config'));
      });
  }, [country, t, jobClassification]);

  const getTypesConfig = useCallback(() => {

    const cachedOptions = localStorage.getItem('configTypeOptions');
    if (cachedOptions) {
      setMovementTypes(JSON.parse(cachedOptions).movement_types);
      setConfigJobTypes(JSON.parse(cachedOptions).job_types)
    } else {
      adminSettingApi
        .getTypeConfig(country, jobClassification, tripType)
        .then((response) => {
          const config = response.data;
          setMovementTypes(config.movement_types);
          setConfigJobTypes(config.job_types)
          localStorage.setItem('configTypeOptions ', JSON.stringify(config));
        })
        .catch((err: any) => {
          console.log(`err`, err);
          toast.error(t('error_getting_config'));
        });
    }

  }, [country, t, jobClassification, tripType]);

  const getAssociateGroups = useCallback(() => {

    companyApi
      .getCompanyAssociateGroups(companyId)
      .then((response) => {
        const associateGroups: IAssociateGroupInfo[] = response.data;

        setAssociateGroups(associateGroups.map(associateGroupsToOptions));

      })
      .catch((err: any) => {
        console.log('err', err);
        toast.error(t('error_getting_associate_groups'));
        //dispatch(setLoader(false));
      });
  }, [dispatch, t, companyId]);

  // addressUserOptions
  const getAddresses = useCallback(
    (companyId?: any) => {
      if (companyId === '' || isNil(companyId)) return;
      const cachedOptions = sessionStorage.getItem('addressUserOptions');
      if (cachedOptions) {
        setAddresses(JSON.parse(cachedOptions));
        //dispatch(setLoader(false));

      } else {
        addressApi
          .allForJob(
            companyId,
            true,
            !readonly ? country : null,
            false,
            false,
            true
          )
          .then((response) => {
            const addresses = response.data as IAddress[];
            setAddresses(addresses);
            sessionStorage.setItem('addressUserOptions', JSON.stringify(addresses));
            //dispatch(setLoader(false));
          })
          .catch((err: any) => {
            console.log('err', err);
            toast.error(t('error_getting_addresses'));
            //dispatch(setLoader(false));
          });;
      }

    },
    [t, country, readonly, dispatch]
  );


  const handleMovementTypeChange = (e: string) => {

    setJobTypes(configJobTypes[e].map((a: any) => a.value))
    setMovementType(e)
  }

  useEffect(() => {
    if (selectedJob) {
      try {

        setJobTypes(configJobTypes[movementType].map((a: any) => a.value))
      }
      catch (error) {

      }
    }

  }, [movementType])

  const showTemperature = () => {
    if ([LOOSE_CARGO, OTHER, FULL_TRUCK_LOAD].includes(movementType) || [REFEER_20, REFEER_40].includes(jobType)) {
      if ([FULL_TRUCK_LOAD].includes(movementType) && [HC_20, HC_40].includes(jobType)) {
        return false
      }
      return true
    }
    return false
  }

  const showPalletOptions = () => {
    const isLooseCargoOrOther = [LOOSE_CARGO, OTHER].includes(movementType);
    const isFullTruckLoadAndLC = movementType === FULL_TRUCK_LOAD && jobType === LOOSE_CARGO;

    if (isLooseCargoOrOther || isFullTruckLoadAndLC) {
      return true; // Display 'Pallet Account Type', 'Pallet Account Action', and 'Pallet Account' fields
    }
    return false; // Do not display 'Pallet Account Type', 'Pallet Account Action', and 'Pallet Account' fields
  };
  const setJobDetails = useCallback(
    (job: IJobInfo) => {
      const isCurrentCompanyPostingCompany: boolean = isEqual(
        job.company_id,
        currentUserCompanyId
      );
      setTypeOfPackageList(job.packages_type || [])
      setIsCompanyJobOwner(isCurrentCompanyPostingCompany);

      setPostingCompanyName(job.company?.companyName || '');
      setBiddingCompanyName(
        job.job_bids?.[0]?.user?.company?.companyName || ''
      );

      setCountry(job.pick_up_address?.country || '');

      setPickUpAddressId(String(job.pickup_address_id));


      setDeliverToAddressId(String(job.delivery_address_id));
      setReturnToAddressId(String(job.return_address_id));

      setStatus(job.status);
      setAdditionalStepStatus(job.additional_step_status)
      setIsBonds(job.is_assigned_to_bonds);

      setReference(job.reference);

      setCompanyId(job.company_id);

      setMovementType(job.movement_type);
      setTripType(job.trip_type);
      setJobType(job.job_type);
      setCustomerReference(job.customer_reference)
      setVehicleType(job.vehicle_type);
      if (job.pickup_date_time) {
        setPickUpDateTime(new Date(job.pickup_date_time));
      } else {
        setPickUpDateTime(null);
      }

      if (job.delivery_date_time) {
        setDeliveryDateTime(job.delivery_date_time ? new Date(job.delivery_date_time) : null);
      } else {
        setDeliveryDateTime(null);
      }



      setReturnDateTime(
        job.return_date_time ? new Date(job.return_date_time) : null
      );

      setTypeOfPackage(job.package_type);
      setAddStepDatetime(job.additional_step_date ? new Date(job.additional_step_date) : null);
      setAddStepEndDatetime(job.additional_step_end_date ? new Date(job.additional_step_end_date) : null);


      setQuantity(job.quantity);
      setDeclaredGrossWeight(job.weight);
      setVolume(job.volume);
      setContainerNo(job.container_no);
      setCNTRLastDay(new Date(job.cntr_last_day));
      setCNTRDehireDay(job.cntr_dehire_day ? new Date(job.cntr_dehire_day) : null);

      setSealNo(job.seal_number);
      setEmptyContainerReleaseNumber(job.ec_release_no);
      setInitialPrice(
        isCurrentCompanyPostingCompany || isUserAdmin || isSuperAdmin
          ? parseFloat(job.initial_price).toFixed(2)
          : job.subtracted_initial_price.toFixed(2)
      );
      setWharfSlot(job.wharf_slot);
      setPostedPrice(parseFloat(job.posted_price).toFixed(2));
      setPostTo(job.post_to);
      setJobInstruction(job.instructions);
      setTemperature(job.temperature)
      setTemperatureValue(job.temperature_value)

      addressApi.byId(job.pickup_address_id as any).then((response) => {
        setPickupInstruction(response.data[0]['instruction'] || null)
        setPickUpPalletsConf(response.data[0]['pallet_configurations'] || [])
        setPickUpReceivingHours(response.data[0]['receiving_hours'] || null)
      }).catch((err: any) => {
        console.log('err', err);
        toast.error(t('error_getting_addresses'));
        //dispatch(setLoader(false));
      });
      addressApi.byId(job.delivery_address_id as any).then((response) => {
        setDeliverInstruction(response.data[0]['instruction'] || null)
        setDeliverPalletsConf(response.data[0]['pallet_configurations'] || [])
        setDeliveryReceivingHours(response.data[0]['receiving_hours'] || null)
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          //dispatch(setLoader(false));
        });
      // setPalletAccountValue(job.pallet_value)

      setSelectedAssociateGroups(
        job.associateGroups.map(associateGroupsToOptions)
      );

      setJobFiles(job.job_files);

      let currentPickUpAddressString = '';
      let currentDeliverToAddressString = '';
      let currentReturnToAddressString = '';

      setBondsJobId(job.bond_job_id)
      if (job.job_classification == 'assigned-transport' && job.assignedUser) {
        setTransportCompany(job.assignedUser.id as unknown as string)
        setTransportRef(job.assignedUser.id as unknown as string)

      }

      if ([FUMIGATION, VIA_YARD, STORAGE].includes(job.additional_step_status)) {

        setShowAdditionalSteps(false)
      }

      if (job.pick_up_address) {
        currentPickUpAddressString =
          isCurrentCompanyPostingCompany ||
            isSuperAdmin ||
            isUserAdmin ||
            job.status !== CREATED
            ? formatAddress(job.pick_up_address)
            : job.pick_up_address.city;
        setCurrentPickUpAddressString(currentPickUpAddressString);
      }

      if (job.deliver_to_address) {
        currentDeliverToAddressString =
          isCurrentCompanyPostingCompany ||
            isSuperAdmin ||
            isUserAdmin ||
            job.status !== CREATED
            ? formatAddress(job.deliver_to_address)
            : job.deliver_to_address.city;
        setCurrentDeliverToAddressString(currentDeliverToAddressString);
      }

      if (job.return_to_address) {
        currentReturnToAddressString =
          isCurrentCompanyPostingCompany ||
            isSuperAdmin ||
            isUserAdmin ||
            job.status !== CREATED
            ? formatAddress(job.return_to_address)
            : job.return_to_address.city;
        setCurrentReturnToAddressString(currentReturnToAddressString);
      }

      if (job.additional_charges) {
        setAdditionalChargeAmount1(job.additional_charges?.amount1);
        setAdditionalChargeAmount2(job.additional_charges?.amount2);
        setAdditionalChargeDescription1(job.additional_charges.description1);
        setAdditionalChargeDescription2(job.additional_charges.description2);
      }
      setPalletAccountAction(job.pallet_account_action)
      setPalletAccountType(job.pallet_account_type)
    },
    [currentUserCompanyId, isUserAdmin, isSuperAdmin, formatAddress]
  );

  const handleDialogClose = useCallback((updated_data?: any) => {
    getAddresses(companyId);
    setIsCreateAddressDialogOpen(false);
    if (updated_data) {
      if (selectedAddressType == 'pickup') {
        setPickupInstruction(updated_data.updated_data || null)
        setPickUpPalletsConf(updated_data.palletAccounts || [])
        setPickUpReceivingHours(updated_data.instruction || null)
      }
      else if (selectedAddressType == 'delivery') {
        setDeliverInstruction(updated_data.updated_data || null)
        setDeliverPalletsConf(updated_data.palletAccounts || [])
        setDeliveryReceivingHours(updated_data.instruction || null)

      }
    }
    setTriggerUpdate(triggerUpdate + 1)
  }, [companyId, getAddresses, selectedAddressType]);

  const onCreateAddressDone = useCallback(() => {

    handleDialogClose();

  }, [handleDialogClose]);

  const handleCountryChange = (e: any) => {
    const currentCountry = e.target.value;
    setCountry(currentCountry);
    setJobType('');
    setVehicleType('');
  };

  const addressesByState: any = useMemo(() => {
    if (country === AUSTRALIA) {
      return groupBy(addresses, 'state');
    }

    return [];
  }, [country, addresses]);

  const addressesByGroup: any = useMemo(() => {
    if (country !== AUSTRALIA) {
      return groupBy(addresses, 'address_group');
    }

    return [];
  }, [country, addresses]);

  const handleOnImageSelect = useCallback((imageSource: string) => {
    setImageToPreview(imageSource);
    setIsImagePreviewOpen(true);
  }, []);

  const getUpdatedJobDetails = useCallback(
    (jobId: string | number) => {
      //dispatch(setLoader(true));
      jobApi
        .jobDetail(jobId)
        .then((response) => {
          const job: IJobInfo = response.data;
          setJobDetails(job);
          //dispatch(setLoader(false));
        })
        .catch((err: any) => {
          //dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t('error_getting_job_details'));
        });
    },
    [setJobDetails, t, dispatch]
  );

  const handleDocumentSelectionSubmit = (data: any[], uploadedFiles: any[]) => {
    //data is the IDS of the checkbox/documents id from DB
    //uploadedFiles files,id  from db

    if (isEditMode) {
      let finalizedData: any[] = []
      if (data.length > 0) {
        const groupedData = data.map((element: any) => {
          const foundFile = uploadedFiles.find(x => x.id == element.id);
          return { ...element, job_id: selectedJob?.id, fileName: foundFile ? foundFile['data'][0].name : '', checked: data.indexOf(element.id) > -1 ? true : false, data: foundFile ? foundFile['data'] : [] }
        })
        finalizedData = groupedData
      } else {

        if (uploadedFiles.length > 0) {
          let rawUploaded = uploadedFiles.map((x) => { return x })
          finalizedData = rawUploaded

        }


      }
      //dispatch(setLoader(true));
      let isDone = false
      finalizedData.forEach((element: any) => {
        jobApi
          .uploadFiles(element['creation_id'], element['data'], element['job_id'], element['is_required'], element['id'])
          .then((response) => {
            isDone = true
            setIsRequiredDialogOpen(false)
            //dispatch(setLoader(false));
          })
          .catch((err: any) => {
            //dispatch(setLoader(false));
            console.log('err', err);
            toast.error(t('error_getting_job_details'));
          });
      })
      if (isDone) {
        toast.success('Document Uploaded');
      }
    } else {
      const groupedData = rawDocument.map((element: any) => {
        const foundFile = uploadedFiles.find(x => x.id == element.id);
        return { ...element, fileName: foundFile ? foundFile['data'][0].name : '', checked: element['checked'] ? element['checked'] : false }
      })
      if (uploadedFiles.length > 0) {
        let rawUploaded = uploadedFiles.map((x) => { return x })
        let tempFiles = files
        rawUploaded.forEach((file) => {
          tempFiles.push(file['data'])
        })

        setFiles(tempFiles)
      }
      const finaldata = groupedData.map((element: any) => {
        const foundFile = data.find(x => x.id == element.id);
        return { ...element, checked: foundFile ? foundFile['is_required'] : false }
      })

      setSelectedRequiredDocuments(finaldata)
      setIsRequiredDialogOpen(false)
    }
  };

  const handleDocumentCheckBoxChange = (index: any) => {
    let tempSelected = selectedRequiredDocuments
    const idx = selectedRequiredDocuments.indexOf(index)
    index['checked'] = !index['checked']
    tempSelected[idx] = index
    setSelectedRequiredDocuments(tempSelected)
  };


  useEffect(() => {

    if (addresses.length > 0) {
      if (selectedJob && isEditMode) {
        const pickUpAddress = addresses.find(
          (address) => address.id === selectedJob.pickup_address_id
        );
        if (pickUpAddress && pickUpAddress.address_group) {
          setPickUpState(pickUpAddress.state);
          setPickUpGroup(pickUpAddress.address_group ?? 'Unknown');
        }

        const deliveryAddress = addresses.find(
          (address) => address.id === selectedJob.delivery_address_id
        );

        if (deliveryAddress && deliveryAddress.address_group) {
          setDeliverToState(deliveryAddress.state);
          setDeliverToGroup(deliveryAddress.address_group ?? 'Unknown');
        }

        const returnAddress = addresses.find(
          (address) => address.id === selectedJob.return_address_id
        );

        if (returnAddress && returnAddress.address_group) {
          setReturnToState(returnAddress.state);
          setReturnToGroup(returnAddress.address_group ?? 'Unknown');
        }
      }
    }

  }, [addresses, selectedJob, isEditMode]);

  useEffect(() => {
    getAddresses(companyId);
    getTypesConfig()
    if (readonly) {
      if (selectedJob) {
        setJobDetails(selectedJob);

        // getUpdatedJobDetails(selectedJob.id);
      }
    } else {
      if (companyId !== '') getAssociateGroups();
    }
  }, [
    companyId,
    readonly,
    selectedJob,
    getTypesConfig
  ]);

  const stateOptions = useMemo(
    () =>
      Object.keys(addressesByState).map((state, i) => {
        if (t(state) != "") {
          return <MenuItem key={i} value={state}>
            {t(state)}
          </MenuItem>
        }
      }
      ),
    [addressesByState, t]
  );
  const arrayOfofSteps = [{ 'key': 'storage', value: 'Storage' }, { 'key': 'via_yard', value: 'Via yard' }, { 'key': 'fumigation', value: 'Fumigation' }]
  const additionalStepOptions = useMemo(
    () =>

      arrayOfofSteps.map((state, i) => {
        if (t(state['value']) != "") {
          return <MenuItem key={state['key']} value={state['key']}>
            {t(state['value'])}
          </MenuItem>
        }
      }
      ),
    [addressesByState, t]
  );

  const pickUpGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[pickUpState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>
      )),
    [addressesByState, pickUpState, t, addressesByGroup, country]
  );

  const pickUpAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[pickUpState], 'address_group')
          : addressesByGroup)[pickUpGroup] || []
      ).map((address: IAddress, i: number) => (

        <MenuItem key={i} value={address.id}>
          <div style={{ 'color': 'gray' }}> {address.company_name} &nbsp;</div>  {formatAddress(address)}
        </MenuItem>
      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      pickUpGroup,
      pickUpState,
    ]
  );

  const deliverToGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[deliverToState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>
      )),
    [addressesByState, deliverToState, t, addressesByGroup, country]
  );

  const deliverToAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[deliverToState], 'address_group')
          : addressesByGroup)[deliverToGroup] || []
      ).map((address: IAddress, i: number) => (
        <MenuItem key={i} value={address.id}>
          <div style={{ 'color': 'gray' }}>{address.company_name} &nbsp;</div> {formatAddress(address)}
        </MenuItem>
      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      deliverToGroup,
      deliverToState,
    ]
  );

  const returnToGroupOptions = useMemo(
    () =>
      Object.keys(
        country === AUSTRALIA
          ? groupBy(addressesByState[returnToState], 'address_group')
          : addressesByGroup
      ).map((addressGroup, i) => (
        <MenuItem key={i} value={addressGroup}>
          {t(addressGroup)}
        </MenuItem>

      )),
    [addressesByState, returnToState, t, addressesByGroup, country]
  );

  const returnToAddressOptions = useMemo(
    () =>
      (
        (country === AUSTRALIA
          ? groupBy(addressesByState[returnToState], 'address_group')
          : addressesByGroup)[returnToGroup] || []
      ).map((address: IAddress, i: number) => (
        <MenuItem key={i} value={address.id}>
          <div style={{ 'color': 'gray' }}>{address.company_name} &nbsp;</div> {formatAddress(address)}
        </MenuItem>

      )),
    [
      addressesByState,
      country,
      addressesByGroup,
      formatAddress,
      returnToGroup,
      returnToState,
    ]
  );




  const temperatureMemo = useMemo(() =>
    TEMPERATURELIST.map((temps, i) => (
      <MenuItem key={i} value={temps}>
        {t(temps)}
      </MenuItem>
    )),
    [TEMPERATURELIST]
  );

  const companyListOptions = useMemo(() =>
    company.list.map((company, i) => (
      <MenuItem key={i} value={company.id}>
        {company.companyName}
      </MenuItem>
    )),
    [company.list]
  );

  const transportCompanyListOptions = useMemo(
    () =>
      company.list.map(
        (company, i) =>
          company.companyType === 'transport_company' &&
          typeof company.user !== 'undefined' && (
            <MenuItem key={i} value={company.user.id}>
              {company.companyName}
            </MenuItem>
          )
      ),
    [company.list]
  );

  // function handleOnSaveAdditionalCharges(callback?: any) {
  //   if (selectedJob) {
  //     jobApi
  //       .updateAdditionalCharges(selectedJob.id, {
  //         description1: additionalChargeDescription1,
  //         description2: additionalChargeDescription2,
  //         amount1: additionalChargeAmount1,
  //         amount2: additionalChargeAmount2,
  //       })
  //       .then(() => {
  //         toast.success('Additional Charges updated');

  //         if (typeof callback === 'function') {
  //           callback();
  //         }
  //       })
  //       .catch((err: any) => {
  //         toast.error(t('error_updating_additional_charges'));
  //       });
  //   }
  // }
  const handleSaveAdditionalCharges = (callback?: any) => {
    if (selectedJob) {
      jobApi
        .updateAdditionalCharges(selectedJob.id, {
          description1: additionalChargeDescription1,
          description2: additionalChargeDescription2,
          amount1: additionalChargeAmount1,
          amount2: additionalChargeAmount2,

        })
        .then(() => {
          // toast.success('Additional Charges updated');

          if (typeof callback === 'function') {
            callback();
          }
        })
        .catch((err: any) => {
          toast.error(t('error_updating_additional_charges'));
        });
    }
  }
  const handleCreateOrUpdateClick = (
    callback?: any,
    userId?: string | number,
    sell_price?: any,
    buy_price?: any
  ) => {

    const formData = new FormData();

    if ((isSuperAdmin || isUserAdmin) && companyId === '') {
      toast.warning(t('company_not_empty'));
      return;
    } else if (movementType === '') {
      toast.warn(t('movement_type_not_empty'));
      return;
    } else if (tripType === '') {
      toast.warn(t('trip_type_not_empty'));
      return;
    } else if (jobType === '') {
      toast.warn(t('job_type_not_empty'));
      return;
    } else if (vehicleType === '') {
      toast.warn(t('vehicle_type_not_empty'));
      return;
    } else if (pickUpAddressId === '') {
      toast.warn(t('pickup_address_not_empty'));
      return;
    }
    //  else if (pickUpDateTime === null) {
    //   toast.warn(t('pickup_date_time_not_empty'));
    //   return;
    // }
    else if (deliverToAddressId === '') {
      toast.warn(t('delivery_address_not_empty'));
      return;
    }
    //  else if (deliveryDateTime === null) {
    //   toast.warn(t('delivery_date_time_not_empty'));
    //   return;
    // }
    else if (declaredGrossWeight === '') {
      toast.warn(t('declared_gross_weight_not_empty'));
      return;
    } else if (postTo === '') {
      toast.warn(t('post_to_not_empty'));
      return;
    }

    if (containerNo != '' && containerNo && pickUpDateTime == null && CNTRDehireDay) {
      toast.warn(t('dehire_cannot_past_pickup'));
      return;
    }
    if (containerNo != '' && containerNo && pickUpDateTime && moment(pickUpDateTime).isAfter(moment(CNTRDehireDay))) {
      toast.warn(t('dehire_cannot_past_pickup'));
      return;
    }
    if (pickUpDateTime && addStepDatetime && moment(pickUpDateTime).isAfter(moment(addStepDatetime))) {
      toast.warn(t('additional_cannot_past_pickup'));
      return;
    }
    if (isNil(customerReference) || customerReference === '') {
      toast.warn(t('customer_reference_is_required'));
      return;
    }
    if (tripType === ROUND_TRIP) {
      if (returnToAddressId === '') {
        toast.warn(t('return_address_not_empty'));
        return;
      } else if (returnDateTime === null) {
        toast.warn(t('return_date_time_not_empty'));
        return;
      }
    }

    // Remove Quantity & Volume as Mandatory
    // if (
    //   movementType !== "" &&
    //   ![FULL_TRUCK_LOAD, EMPTY_PARK, RAIL, WHARF].includes(movementType)
    // ) {
    //   if (quantity === "") {
    //     toast.warn(t("quantity_not_empty"));
    //     return;
    //   } else if (volume === "") {
    //     toast.warn(t("volume_not_empty"));
    //     return;
    //   }
    // }


    if (
      postedPrice !== '' &&
      isNonNumeric(postedPrice) &&
      (isSuperAdmin || isUserAdmin)
    ) {
      toast.warning(t('invalid_value_posted_price'));
      return;
    }


    formData.append('reference', reference);
    formData.append('customer_reference', customerReference);
    formData.append('company_id', companyId);
    formData.append('movement_type', movementType);
    formData.append('job_type', jobType);
    formData.append('vehicle_type', vehicleType);

    formData.append('pickup_address_id', pickUpAddressId);
    // if(pickUpDateTime){
    formData.append('pickup_date_time', moment(pickUpDateTime) as any);
    // }


    formData.append('additional_step_status', additionalStepStatus);

    if (addStepDatetime) {
      formData.append('additional_step_date', moment(addStepDatetime) as any);
    }
    if (addStepEndDatetime) {
      formData.append('additional_step_end_date', addStepEndDatetime as any);
    }
    formData.append('delivery_address_id', deliverToAddressId);


    formData.append('delivery_date_time', moment(deliveryDateTime) as any);

    // if (CNTRDehireDay && containerNo) {
    //   formData.append('cntr_dehire_day', moment(CNTRDehireDay) as any);
    // }
    if (tripType === ROUND_TRIP) {
      formData.append('return_address_id', returnToAddressId);
      formData.append('return_date_time', returnDateTime as any);
    }
    formData.append('trip_type', tripType);
    formData.append('container_no', containerNo);
    formData.append('cntr_last_day', moment(CNTRLastDay) as any);


    formData.append('seal_number', sealNo);
    formData.append('package_type', typeOfPackage);
    formData.append('quantity', quantity);
    formData.append('weight', declaredGrossWeight);
    formData.append('volume', volume);
    formData.append('posted_price', parseFloat(postedPrice != '' ? postedPrice : 0).toFixed(2));
    formData.append('initial_price', parseFloat(initialPrice != '' ? initialPrice : 0).toFixed(2));
    formData.append('margin_price', '0');
    formData.append('status', status ? status : CREATED);
    formData.append('reason', '');
    // formData.append('job_wait_time', '')
    formData.append('instructions', jobInstruction);

    formData.append('ec_release_no', emptyContainerReleaseNumber);
    formData.append('wharf_slot', wharfSlot);
    formData.append('post_to', postTo);
    formData.append('is_split_delivery', 'false');
    formData.append('is_fixed_date', 'true');

    if (showTemperature()) {
      formData.append('temperature', temperature)

      if (temperature === '') {
        toast.warn(t('temperature_is_required'));
        return;
      }
      if ([CHILLED, FROZEN].includes(temperature)) {
        if (temperatureValue === '') {
          toast.warn(t('temperature_is_required'));
          return;
        }
        formData.append('temperature_value', temperatureValue);
      }
    }

    formData.append('lcl_type', '');
    formData.append('job_classification', props.type);

    formData.append('selected_required_documents', JSON.stringify(selectedRequiredDocuments.map((opt: any) => opt)));

    formData.append(
      'associate_group_ids',
      JSON.stringify(selectedAssociateGroups.map((opt) => opt.value))
    );

    if (props.type === 'assigned-transport') {
      formData.append('transport_ref', transportRef);
      formData.append('transport_company_user_id', transportCompany);
    }

    for (let i = 0; i < files.length; i++) {

      const file = files[i][0];
      formData.append('files', file);
    }

    if (setTypeOfPackageRef.current) {
      (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {
        formData.append('packages_type', JSON.stringify(data.map((opt: any) => opt)));
      });
    }
    if (showPalletOptions) {
      formData.append('pallet_account_type', palletAccountType);
      formData.append('pallet_account_action', palletAccountAction);
      formData.append('pallet_value', palletAccountValue);
      if (deliverPalletValue != '') {
        formData.append('delivery_pallet', deliverPalletValue);
      }
    }
    if (mode === 'create') {
      formData.append('job_comments', JSON.stringify(jobCommentData));
    }
    //dispatch(setLoader(true));

    let jobManagementRoot = '';

    if (props.type === 'transport' || props.type === 'assigned-transport')
      jobManagementRoot = routes.home.transport_management.root;
    else if (props.type === 'seafreight')
      jobManagementRoot = routes.home.sea_freight_management.root;

    if (userId || isCreatteAllocationJob) {
      formData.set('posted_price', sell_price)
      formData.set('initial_price', buy_price)

      jobApi
        .createAndAllocate(formData, userId || transportCompany)
        .then((response: any) => {
          const refCreated = response['data']['data']['reference']
          toast.success(t('success_creating_job') + ": " + refCreated);
          handleSelectUserDialogClose();
          //dispatch(setLoader(false));
          const isAdminAccess = isUserAdmin || isSuperAdmin

          const toRoute = isAdminAccess ? routes.home.jobs.show_jobs.available : routes.home.jobs.show_jobs.my_in_progress
          history.push({
            pathname:
              routes.home.root +
              routes.home.transport_management.root +
              routes.home.jobs.show_jobs.root + toRoute, 'customRoute': isAdminAccess ? 'available_jobs' : 'my_in_progress'
          }
          );
        })
        .catch((err: any) => {
          //dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t(err.response?.data || 'error_allocating_job'));
        });
    } else if (!isEditMode) {
      jobApi
        .create(formData)
        .then((response: any) => {
          const refCreated = response['data']['data']['reference']
          toast.success(t('success_creating_job') + ": " + refCreated);
          //dispatch(setLoader(false));
          const isAdminAccess = isUserAdmin || isSuperAdmin

          const toRoute = isAdminAccess ? routes.home.jobs.show_jobs.available : routes.home.jobs.show_jobs.posted

          history.push({
            pathname:
              routes.home.root +
              jobManagementRoot +
              routes.home.jobs.show_jobs.root +
              toRoute, 'customRoute': isAdminAccess ? 'available_jobs' : 'posted_jobs'
          }
          );

        })
        .catch((err: any) => {
          //dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t(err.response?.data || 'error_creating_job'));
        });
    } else {
      if (selectedJob) {
        jobApi
          .update(selectedJob.id, formData)
          .then(() => {
            ref = toast.success(t('success_updating_job'));
            handleSaveAdditionalCharges()
            //dispatch(setLoader(false));
            if (callback) callback();
          })
          .catch((err: any) => {
            //dispatch(setLoader(false));
            console.log('err', err);
            toast.error(t(err.response?.data || 'error_updating_job'));
          });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    handleUpdate: (callback: any) => {
      handleCreateOrUpdateClick(callback);
    },
  }));

  const handleAssociateGroupSelect = useCallback(
    (values: IAutocompleteOption[]) => {
      setSelectedAssociateGroups(values);
    },
    []
  );
  const [packageAllocation, setPackageAllocation] = useState([]);

  const onAllocateClick = useCallback(() => {

    let packages_list: any;

    if (setTypeOfPackageRef.current) {
      (setTypeOfPackageRef.current as any).handlePackageChanges((data: any) => {

        packages_list = data
      });
    }
    setPackageAllocation(packages_list)
    // setAllocationSelectedJob({movement_type : movementType,
    //   pickup_address_id : pickUpAddressId ,
    //   delivery_address_id : deliverToAddressId,
    //   company_id : companyId,
    //   quantity :(typeOfPackageList[0] ? typeOfPackageList[0].quantity : 0),
    //   packages_type: packages_list
    // })
    setIsSelectUserDialogOpen(true);
  }, []);
  const onDocumentListClick = useCallback(() => {
    setIsRequiredDialogOpen(true);
  }, []);



  const onSelectedUserCallback = (userId: string | number, sell_price: any, buy_price: any) => {
    handleCreateOrUpdateClick(undefined, userId, sell_price, buy_price);
  };

  const handleImagePreviewClose = useCallback(() => {
    setIsImagePreviewOpen(false);
  }, []);

  const marginPrice = Number(selectedJob?.margin_price || '0');
  const bidPrice =
    (selectedJob?.job_bids ?? []).length > 0
      ? selectedJob?.job_bids?.[0]?.amount || 0
      : 0;
  const agreedBid =
    isCompanyJobOwner || isSuperAdmin || isUserAdmin
      ? (selectedJob?.job_bids ?? []).length > 0
        ? bidPrice + marginPrice
        : 0
      : (selectedJob?.job_bids ?? []).length > 0
        ? bidPrice
        : 0;
  const jobPostedPrice =
    selectedJob?.subtracted_initial_price || 0 + marginPrice;
  const jobInitialPrice =
    selectedJob?.subtracted_initial_price || 0 + marginPrice;
  const jobPrice = jobPostedPrice === 0 ? jobPostedPrice : jobInitialPrice;
  const priceDiff =
    (selectedJob?.job_bids ?? []).length > 0 ? jobPrice - agreedBid : 0;
  const damageFilePhotos: IJobFileInfo[] = (jobFiles || []).filter(
    (jobFile) => jobFile.type === DAMAGE_PHOTO
  );

  const handleOnUploadPhotoDamagesClick = useCallback(() => {
    if (photosOfDamages.length === 0) {
      toast.error(t('no_selected_photos'));
      return;
    }

    const jobId = props.selectedJob?.id;
    if (jobId) {
      //dispatch(setLoader(true));
      jobApi
        .uploadImage(String(jobId), DAMAGE_PHOTO, photosOfDamages)
        .then(() => {
          toast.success(t('success_uploading_photo_of_damages'));
          //dispatch(setLoader(false));
          getUpdatedJobDetails(jobId);
        })
        .catch((err: any) => {
          //dispatch(setLoader(false));
          console.log('err', err);
          toast.error(t('error_uploading_photo_of_damages'));
        });
    }
  }, [dispatch, t, props.selectedJob, photosOfDamages, getUpdatedJobDetails]);

  const isShow = () => {
    return selectedJob?.is_assigned_to_bonds
  }
  const isCNTRAllowed = () => {
    if (containerNo != "" || containerNo) {
      return true
    } else {
      return false
    }
  }
  const isCNTRDehireAllowed = () => {
    if ((containerNo != "" || containerNo) && [COMPLETED].includes(status || '')) {
      return true
    } else {
      return false
    }
  }

  const isHidden = (countries: string[], element: string) => {

    if (countries.indexOf(country) > 1) {

      if ([QUANTITY, VOLUME, PACKAGES_TYPE].includes(element)) {
        // return false
        return ![
          // JOB_TYPES.FIRST_MILE,
          // JOB_TYPES.LAST_MILE,
          // JOB_TYPES.EMPTY_CONTAINER_RETURN,
          // JOB_TYPES.EMPTY_CONTAINER_COLLECTION,
          JOB_TYPES.IMPORT_FULL_CONTAINER,
          JOB_TYPES.EXPORT_FULL_CONTAINER,
          JOB_TYPES.DOMESTIC_FULL_CONTAINER,
          JOB_TYPES.EMPTY,
          // JOB_TYPES.EXPORT_CONTAINER_20,
          // JOB_TYPES.EXPORT_CONTAINER_40,
          // JOB_TYPES.LOCAL_CONTAINER_DELIVERY_20,
          // JOB_TYPES.LOCAL_CONTAINER_DELIVERY_40,
          // JOB_TYPES.RAIL,

        ].includes(jobType);

      } else if ([QUANTITY, PACKAGES_TYPE, VOLUME, ECRNUM, WS].includes(element)) {
        return false
      }
      else if ([CN, CNTR, SEAL_NO, ECRNUM, WS].includes(element)) {
        return ![
          JOB_TYPES.IMPORT_LOOSE_CARGO,
          JOB_TYPES.EXPORT_LOOSE_CARGO,
          JOB_TYPES.DOMESTIC_LOOSE_CARGO,
          JOB_TYPES.BREAK_BULK,


        ].includes(jobType);
        // return false
      }
      else if ([PACKAGES_TYPE, QUANTITY, VOLUME].includes(element)) {
        return false
      }
      // else if([PACKAGES_TYPE, QUANTITY , VOLUME].includes(element)){
      //   return false
      // }
      // else if ([SEAL_NO, CN, ECRNUM, WS , CNTR].includes(element)) {
      //   const GOT_LOOSE = [ MOVEMENT_TYPES.LOOSE_CARGO == movementType , JOB_TYPES.LOOSE_CARGO == jobType ]
      //   return !GOT_LOOSE.includes(true)
      // }
    }
    return false;
  };
  const handleAddNewAddress = useCallback(
    () => {
      setSelectedAddress(undefined)
      setIsCreateAddressDialogOpen(true)
      setSelectedAddressMode('create')
    },
    [],
  )
  const handleEditPickUpAddress = (parameter: any) => (event: any) => {
    setSelectedAddressMode('edit')
    setSelectedAddressType('pickup')
    if (selectedJob) {
      setSelectedAddress(selectedJob?.pick_up_address)

    } else {
      addressApi.byId(pickUpAddressId).then((response) => {
        const addresses = response.data;
        setSelectedAddress(addresses[0])
        //dispatch(setLoader(false));
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          //dispatch(setLoader(false));
        });
    }
    setIsCreateAddressDialogOpen(true)

  }
  const handleEditDeliverAddress = (parameter: any) => (event: any) => {
    setSelectedAddressType('delivery')
    setSelectedAddressMode('edit')
    if (selectedJob) {
      setSelectedAddress(selectedJob?.deliver_to_address)
    } else {
      addressApi.byId(deliverToAddressId).then((response) => {
        const addresses = response.data;
        setSelectedAddress(addresses[0])
        //dispatch(setLoader(false));
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          //dispatch(setLoader(false));
        });
    }

    setIsCreateAddressDialogOpen(true)

  }
  const handleEditReturnAddress = (parameter: any) => (event: any) => {


    if (selectedJob) {
      setSelectedAddress(selectedJob?.return_to_address)
    } else {
      addressApi.byId(returnToAddressId).then((response) => {
        const addresses = response.data;
        setSelectedAddress(addresses[0])
        //dispatch(setLoader(false));
      })
        .catch((err: any) => {
          console.log('err', err);
          toast.error(t('error_getting_addresses'));
          //dispatch(setLoader(false));
        });
    }

    setIsCreateAddressDialogOpen(true)

  }
  const getRequiredDocumentListing = useCallback(() => {

    const cachedOptions = sessionStorage.getItem('documentListing');
    if (cachedOptions) {
      setRawDocuments(JSON.parse(cachedOptions));
      setSelectedRequiredDocuments(JSON.parse(cachedOptions));

    } else {
      jobApi.getDocumentListing()
        .then(response => {
          const data = response.data.map((element: any) => { return { ...element, job_id: selectedJob?.id } })
          setRawDocuments(data)
          setSelectedRequiredDocuments(data)
          sessionStorage.setItem('documentListing', JSON.stringify(data));

          // dispatch(setLoader(false))
        })
        .catch(err => {
          console.log(`err`, err)
        })
    }


  }, [])



  useEffect(() => {
    if (isEditMode) {
      if (selectedJob?.required_document_selection.length > 0) {
        const mergedData = rawDocument.map((element: any) => {
          const foundFile = selectedJob?.required_document_selection.find((x: any) => x.id == element.id);

          return foundFile ? { ...foundFile, is_required: foundFile['checked'] } : { ...element, job_id: selectedJob?.id }

        })
        setSelectedRequiredDocuments(mergedData)
      } else {
        getRequiredDocumentListing()
      }
    }
  }, [isEditMode])

  useEffect(() => {
    getRequiredDocumentListing()
  }, [getRequiredDocumentListing])


  const onCommentDialogUpdate = (comment: any, id: any) => {
    let rawJobComment = jobCommentData
    rawJobComment[currentSelectedNoteUser] = {
      comment_id: '',
      job_id: '',
      is_admin: isSuperAdmin,
      comment: comment,
      user_id: currentuserId,
      company_id: currentUserCompanyId
    }
    setJobCommentData(rawJobComment)

  };
  const handleCommentDialogClose = useCallback(() => {
    setIsCommentDialogOpen(false);
  }, []);
  const handleCommentDialogOpen = (type: boolean) => {
    setIsCommentDialogOpen(true);
    setCurrentSelectedNoteUser(type == false ? 'user' : 'admin')
  }


  //Address Placeholder
  useEffect(() => {
    let message = ''
    if (jobInstruction) { message += jobInstruction.concat("\n") }
    if (pickUpInstruction) {
      message += ["Pickup Instruction : ", pickUpInstruction].filter(Boolean).join("")
      message += "\n"
    }
    if (pickUpReceivingHours) {
      message += ["Pickup Receival hours : ", pickUpReceivingHours].filter(Boolean).join("");
      message += "\n"
    }
    if (deliverInstruction) {
      message += ["Delivery Instruction : ", deliverInstruction].filter(Boolean).join("")
      message += "\n"
    }
    if (deliveryReceivingHours) {
      message += ["Delivery Receival hours : ", deliveryReceivingHours].filter(Boolean).join("");
      message += "\n"
    }
    let instructions = message ? message.split('\n') : [];

    instructions = instructions.filter((str, index) => str !== '\n' || index === instructions.indexOf('\n'));
    const regex = /Pallet\sAccount|Pick\sUp\sPallet\sAccount/g;
    instructions = instructions.filter((instruction, index, arr) => {
      // Remove empty strings
      if (instruction.trim() === '') return false;
      // Remove duplicate instructions
      return arr.indexOf(instruction) === index;
    });
    for (let i = 0; i < instructions.length; i++) {
      if (regex.test(instructions[i])) {
        instructions[i] = '';
        break;
      }
    }
    // Define the new instruction based on the type and action
    let newInstruction = '';
    if (palletAccountType === 'PLAIN') {
      newInstruction = `Pallet Account: Plain Pallets`;
      setPalletAccountValue('Plain Pallets');
    } else if ((palletAccountType === 'CHEPS' || palletAccountType === 'LOSCAMS' || palletAccountType === 'OTHER') && palletAccountAction === 'exchange') {
      if (palletAccountType != 'OTHER') {
        setPalletAccountValue(`${palletAccountType} Exchange`);
        newInstruction = `Pallet Account: ${palletAccountType} Exchange`;

      } else {
        newInstruction = 'Pallet Account: Exchange';
        setPalletAccountValue(`Exchange`);
      }
    } else if ((palletAccountType === 'CHEPS' || palletAccountType === 'LOSCAMS' || palletAccountType === 'OTHER') && palletAccountAction === 'transfer') {
      const pickUpPalletsConfMatched = pickUpPalletsConf.filter(item => item.type === palletAccountType && item.action === palletAccountAction);
      const deliverPalletsConfMatched = deliverPalletsConf.filter(item => item.type === palletAccountType && item.action === palletAccountAction);
      newInstruction = `Pick Up Pallet Account: ${pickUpPalletsConfMatched.length > 0 ? pickUpPalletsConfMatched[0].value : ''}, Delivery Pallet Account: ${deliverPalletsConfMatched.length > 0 ? deliverPalletsConfMatched[0].value : ''}`;
      setDeliverPalletValue(`${(palletAccountType).toUpperCase()}  :  ${deliverPalletsConfMatched.length > 0 ? deliverPalletsConfMatched[0].value : ''}`)
      if (palletAccountType != 'OTHER') {
        setPalletAccountValue(`${palletAccountType} Transfer`);

      } else {
        setPalletAccountValue(`Transfer`);

      }
    } else {
      setPalletAccountValue('');
    }

    // Check if the new instruction already exists in the instructions array
    const instructionExists = instructions.some(instruction => instruction === newInstruction);

    // If the new instruction doesn't already exist, add it to the instructions array
    if (!instructionExists && newInstruction) {
      instructions.push(newInstruction);
    }
    // const newArray = array.filter((str, index, arr) => str !== '\n' || (index !== arr.length - 1 && arr[index + 1] !== '\n'));
    // Update the merged instruction state
    const newArray = instructions.filter(str => str !== '\n' || ' ');
    const newData = newArray.join('\n')
    setMergedInstruction(newData);
    // handlePalletAccountSelection(palletAccountType, palletAccountAction);

  }, [pickUpInstruction, deliverInstruction, jobInstruction, deliveryReceivingHours, pickUpReceivingHours, palletAccountType, palletAccountAction, triggerUpdate])

  return (
    <>
      <SelectUserDialog
        onSelectUserCallback={onSelectedUserCallback}
        open={isSelectUserDialogOpen}
        handleClose={handleSelectUserDialogClose}
        selectedJob={{
          movement_type: movementType,
          pickup_address_id: pickUpAddressId,
          delivery_address_id: deliverToAddressId,
          company_id: companyId,
          quantity: (typeOfPackageList[0] ? typeOfPackageList[0].quantity : 0),
          packages_type: packageAllocation,
          deliver_to_address: { state_type: deliverToStateType },
          job_type: jobType
        }}
        isAdmin={isSuperAdmin || isUserAdmin}
        companyId={companyId}
        allocate={true}
      />

      <RequiredDocuments
        onSubmit={handleDocumentSelectionSubmit}
        open={isRequiredDialogOpen}
        handleRequiredDocumentClose={handleRequiredDocumentClose}
        handleDocumentCheckBoxChange={handleDocumentCheckBoxChange}
        selectedData={selectedRequiredDocuments}
        isAdmin={isSuperAdmin || isCompanyJobOwner || isUserAdmin}
      />

      <ImagePreviewDialog
        imageSource={imageToPreview}
        open={isImagePreviewOpen}
        handleClose={handleImagePreviewClose}
      />
      <CreateAddressDialog
        type={jobClassification}
        open={isCreateAddressDialogOpen}
        handleClose={handleDialogClose}
        onDone={onCreateAddressDone}
        address={selectedAddress}
        mode={selectedAddressMode}
        isCommon={false}
      // mode={props.mode}
      />
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        spacing={5}
      >
        {!readonly ? (
          <>
            <Grid md={12} item>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
              >
                <Grid item>
                  <Button
                    fullWidth
                    onClick={handleAddNewAddress}
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                  >
                    {t('add_new_address')}
                  </Button>
                </Grid>
                {mode === 'create' ?
                  <Grid item style={{ float: 'right' }}>
                    <Tooltip title="Posting Notes">
                      <Button onClick={(e) => { handleCommentDialogOpen(false) }}> <Badge overlap="rectangular" variant="dot" invisible={true} color="primary">
                        <SpeakerNotesIcon color="action" />
                      </Badge></Button>
                    </Tooltip>

                  </Grid> :
                  null}
              </Grid>

            </Grid>

           

          </>

        ) : null}


        <CommentDialog
          onBlurUpdate={onCommentDialogUpdate}
          commentData={jobCommentData}
          open={isCommentDialogOpen}
          handleClose={handleCommentDialogClose}
          isEditable={false}
        />
        <Grid md item>
          <Grid container justifyContent="flex-start" direction="row" spacing={4}>
            {readonly && (isSuperAdmin || isUserAdmin) ? (
              <Grid item md={8}>
                <TextField
                  disabled={true}
                  value={postingCompanyName}
                  label={t('posting_company_name')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : (
              (!isJobOwner && ![CREATED, CLONED, STORAGE, FUMIGATION, VIA_YARD].includes(selectedJob?.status)) && mode != 'create' ? <Grid item md={8}>
                <TextField
                  disabled={true}
                  value={postingCompanyName}
                  // onChange={(e) => setReference(e.target.value)}
                  label={t('posting_company_name')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid> : null


            )}

            {readonly && (isSuperAdmin || isUserAdmin || isCompanyJobOwner) && status !== CREATED ? (
              <Grid item md={12}>
                <TextField
                  disabled={true}
                  value={biddingCompanyName}
                  // onChange={(e) => setReference(e.target.value)}
                  label={t('bidding_company_name')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}
            {readonly && (isSuperAdmin || isUserAdmin || isCompanyJobOwner) && isBonds && status !== CREATED && isShow ? (
              <Grid item md={7}>
                <TextField
                  disabled={true}
                  value={bondsJobId}
                  // onChange={(e) => setReference(e.target.value)}
                  label={t('bonds_job_id')}
                  fullWidth
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            {(isSuperAdmin || isUserAdmin) && !readonly && (!isEditMode || status === CREATED) ? (
              <Grid item md={7}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('select_company') + '*'}
                  </InputLabel>
                  <Select
                    value={companyId}
                    onChange={(e) => {
                      const companyId = e.target.value;
                      const selectedCompany = company.list.find(
                        (c) => c.id === companyId
                      );

                      if (selectedCompany) {
                        setCountry(selectedCompany.country);
                        getAddresses(companyId);
                        setSelectedAssociateGroups([]);
                      }

                      setCompanyId(companyId);
                    }}
                  >
                    {companyListOptions}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            {/* {(isSuperAdmin || isUserAdmin) && !readonly ? (
              <Grid item md={5}>
                <FormControl variant="filled" className={classes.formControl}>
                  <InputLabel className={classes.selectLabel}>
                    {t('select_your_country')}
                  </InputLabel>
                  <Select
                    disabled={readonly || isSuperAdmin || isUserAdmin}
                    value={country}
                    onChange={handleCountryChange}
                  >
                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem> */}
            {/* <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem> */}
            {/* <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem> */}
            {/* <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem> */}
            {/* </Select>
                </FormControl>
              </Grid>
            ) : null} */}

            {(isSuperAdmin || isUserAdmin) &&
              !readonly &&
              !isEditMode &&
              isCreatteAllocationJob ? (
              <>
                <Grid item md={7}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('select_transport_company') + '*'}
                    </InputLabel>
                    <Select
                      value={transportCompany}
                      onChange={(e) => {
                        const companyId = String(e.target.value);
                        setTransportCompany(companyId);
                      }}
                    >
                      {transportCompanyListOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={5}>
                  <TextField
                    disabled={readonly}
                    value={transportRef}
                    onChange={(e) => setTransportRef(e.target.value)}
                    fullWidth
                    label={t('transport_reference')}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('movement_type') + '*'}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={movementType}
                  onChange={(e) => handleMovementTypeChange(e.target.value as string)}
                >
                  {movementTypes.map((movementType, i) => (
                    <MenuItem key={i} value={movementType}>
                      {t(movementType)}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={EMPTY_PARK}>{t(EMPTY_PARK)}</MenuItem>
                                    <MenuItem value={FULL_TRUCK_LOAD}>{t(FULL_TRUCK_LOAD)}</MenuItem>
                                    {
                                        tripType === 'round_trip' 
                                        ? <MenuItem value={LOOSE_CARGO}>{t(LOOSE_CARGO)}</MenuItem>
                                        : null
                                    }
                                    <MenuItem value={RAIL}>{t(RAIL)}</MenuItem>
                                    <MenuItem value={WHARF}>{t(WHARF)}</MenuItem>
                                    <MenuItem value={OTHER}>{t(OTHER)}</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('trip_type') + '*'}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={tripType}
                  onChange={(e) => {
                    setTripType(e.target.value as string)
                  }}
                >
                  <MenuItem value={ONE_WAY}>{t(ONE_WAY)}</MenuItem>
                  <MenuItem value={ROUND_TRIP}>{t(ROUND_TRIP)}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <TextField
                disabled={true}
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                fullWidth
                label={selectedJob ? t('your_reference') : <Grid>{t('reference_auto_gen')}</Grid>}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={4}>
              <TextField
                disabled={readonly}
                value={customerReference}
                onChange={(e) => setCustomerReference(e.target.value)}
                fullWidth
                label={t('customer_reference')}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {!documentUploadReadOnly ? (
              <Grid item md={2}>
                <Button color="primary"
                  variant="contained"
                  onClick={onDocumentListClick}>
                  Documents</Button>
                {/* <FileInput
                  onChange={(e: any) => setFiles(e.target.files)}
                  disabled={readonly}
                  multiple
                /> */}
              </Grid>
            ) : (
              <Grid item md={6}>
                {/* GAP */}
              </Grid>
            )}

            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('job_type') + '*'}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={jobType}
                  onChange={(e) => setJobType(e.target.value as string)}
                >
                  {jobTypes.map((jobType, i) => (
                    <MenuItem key={i} value={jobType}>
                      {t(jobType)}
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
              <FormControl variant="filled" className={classes.formControl}>
                <InputLabel className={classes.selectLabel}>
                  {t('vehicle_type') + '*'}
                </InputLabel>
                <Select
                  disabled={readonly}
                  value={vehicleType}
                  onChange={(e) => setVehicleType(e.target.value as string)}
                >
                  {useMemo(
                    () =>
                      (
                        get(VEHICLE_TYPES_CONFIG, [
                          country,
                          movementType,
                          tripType,
                          jobType,
                        ]) ?? []
                      ).map((vehicleType: string, i) => (
                        <MenuItem key={i} value={vehicleType}>
                          {t(vehicleType)}
                        </MenuItem>
                      )),
                    [country, t, movementType, tripType, jobType]
                  )}
                </Select>
              </FormControl>
            </Grid>

            {
              (showTemperature()) ?
                <Grid item md={3}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('temperature') + '*'}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={temperature}
                      onChange={(e) => setTemperature(e.target.value as string)}
                    >
                      {temperatureMemo}
                    </Select>
                  </FormControl>
                </Grid>

                : null

            }

            {
              ([CHILLED, FROZEN].includes(temperature) && showTemperature()) ?
                <Grid item md={3}>
                  <TextField
                    disabled={readonly}
                    value={temperatureValue}
                    label={t('temperature')}
                    onChange={(e) => setTemperatureValue(e.target.value)}
                    fullWidth
                    type="number"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                : null
            }

            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('pick_up_from') + '*'}
              </Typography>
            </Grid>
            {!readonly ? (
              <>
                {country === AUSTRALIA ? (
                  <Grid item md={12}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <InputLabel className={classes.selectLabel}>
                        {t('pickup_select_state')}
                      </InputLabel>
                      <Select
                        disabled={readonly}
                        value={pickUpState}
                        onChange={(e) => {
                          setPickUpState(e.target.value as any);
                          setPickUpGroup('');
                          setPickUpAddressId('');
                        }}
                      >
                        {stateOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item md={12}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('pickup_select_group')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={pickUpGroup}
                      onChange={(e) => {
                        setPickUpGroup(e.target.value as any);
                        setPickUpAddressId('');
                      }}
                    >
                      {pickUpGroupOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={10}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('pickup_select_address')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={pickUpAddressId}
                      onChange={(e) => {
                        setPickUpAddressId(e.target.value as any);
                        addressApi.byId(e.target.value as any).then((response) => {
                          setPickupInstruction(response.data[0]['instruction'] || null)
                          setPickUpPalletsConf(response.data[0]['pallet_configurations'] || [])
                          setPickUpReceivingHours(response.data[0]['receiving_hours'] || null)
                          setTriggerUpdate(triggerUpdate + 1)
                        })
                          .catch((err: any) => {
                            console.log('err', err);
                            toast.error(t('error_getting_addresses'));
                            //dispatch(setLoader(false));
                          });
                      }}

                    >
                      {pickUpAddressOptions}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={2}>
                  <FormControl variant="filled" className={classes.formControl}>

                    <Button
                      onClick={handleEditPickUpAddress(selectedJob)}
                      startIcon={<EditIcon />}
                      fullWidth

                      color="primary"
                      variant="contained"
                    >{t('edit')}</Button>

                  </FormControl>
                </Grid>
              </>
            ) : (
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  value={currentPickUpAddressString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('pick_up_date_time') + '*'}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {(!readonly || isAssignedUser && selectedJob?.status != 'completed') ? (
                <MobileDateTimePicker
                  inputFormat='dd/MM/yyyy HH:mm'
                  disabled={readOnlyBidder}
                  ampm={true}

                  toolbarTitle={t('select_date_time')}
                  // disabled={!isAssignedUser}
                  renderInput={(props) => (
                    <TextField fullWidth variant="outlined" {...props} />
                  )}
                  value={formatSaveOffset(pickUpDateTime)}
                  minDate={selectedJob ? pickUpDateTime : dateNow}
                  onAccept={(e: any) => {
                    setPickUpDateTime(formatReadOffset(e))
                  }}
                  onChange={(e) => { }}
                // minDate={formatDateTimeRead(dateNow)}
                />
              ) : (
                <TextField
                  disabled={true}
                  value={formatDateTimeDisplay(pickUpDateTime)}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('deliver_to') + '*'}
              </Typography>
            </Grid>
            {!readonly ? (
              <>
                {country === AUSTRALIA ? (
                  <Grid item md={12}>
                    <FormControl
                      variant="filled"
                      className={classes.formControl}
                    >
                      <InputLabel className={classes.selectLabel}>
                        {t('deliver_select_state')}
                      </InputLabel>
                      <Select
                        disabled={readonly}
                        value={deliverToState}
                        onChange={(e) => {
                          setDeliverToState(e.target.value as any);
                          setDeliverToGroup('');
                          setDeliverToAddressId('');
                        }}
                      >
                        {stateOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                ) : null}
                <Grid item md={12}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('deliver_select_group')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={deliverToGroup}
                      onChange={(e) => {
                        setDeliverToGroup(e.target.value as any);
                        setDeliverToAddressId('');
                      }}
                    >
                      {deliverToGroupOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={10}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('deliver_select_address')}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={deliverToAddressId}
                      onChange={(e) => {
                        setDeliverToAddressId(e.target.value as any);
                        addressApi.byId(e.target.value as any).then((response) => {
                          setDeliverInstruction(response.data[0]['instruction'] || null)
                          setDeliveryReceivingHours(response.data[0]['receiving_hours'] || null)
                          setDeliverPalletsConf(response.data[0]['pallet_configurations'] || [])
                          setDeliverToStateType(response.data[0]['state_type'] || '')
                          setTriggerUpdate(triggerUpdate + 1)
                        })
                          .catch((err: any) => {
                            console.log('err', err);
                            toast.error(t('error_getting_addresses'));
                            //dispatch(setLoader(false));
                          });
                      }}
                    >
                      {deliverToAddressOptions}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl variant="filled" className={classes.formControl}>

                    <Button
                      onClick={handleEditDeliverAddress(selectedJob)}
                      startIcon={<EditIcon />}
                      fullWidth

                      color="primary"
                      variant="contained"
                    >{t('edit')}</Button>

                  </FormControl>
                </Grid>
              </>
            ) : (
              <Grid item md={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={true}
                  value={currentDeliverToAddressString}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LocationOnIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

            )}
            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('deliver_to_date_time') + '*'}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {(!readonly || isAssignedUser && selectedJob?.status != 'completed') ? (
                <MobileDateTimePicker
                  inputFormat='dd/MM/yyyy HH:mm'
                  disabled={readOnlyBidder}
                  ampm={true}

                  toolbarTitle={t('select_date_time')}
                  // disabled={!isAssignedUser}
                  renderInput={(props) => (
                    <TextField fullWidth variant="outlined" {...props} />
                  )}
                  value={formatSaveOffset(deliveryDateTime)}
                  minDate={pickUpDateTime}
                  onAccept={(e: any) => {
                    setDeliveryDateTime(formatReadOffset(e))
                  }}
                  onChange={(e) => { }}
                />
                // <DateTimePicker
                //   inputFormat='dd/MM/yyyy HH:mm'
                //   toolbarTitle={t('select_date_time')}
                //   disabled={readOnlyBidder}
                //   renderInput={(props) => (
                //     <TextField fullWidth variant="outlined" {...props} />
                //   )}
                //   ampm={false}
                //   value={deliveryDateTime}
                //   onChange={setDeliveryDateTime}
                //   minDateTime={pickUpDateTime}
                // />
              ) : (
                <TextField
                  disabled={true}
                  value={formatDateTimeDisplay(
                    selectedJob?.actual_delivery_time ?? deliveryDateTime
                  )}
                  fullWidth
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            {[PICKEDUP, ASSIGNED, VIA_YARD, FUMIGATION, STORAGE, PICKEDUP].includes(selectedJob?.status || '') || !selectedJob ? (
              <>
                <Grid item md={12}>
                  <Grid item md={6}>

                    <FormControlLabel control={<Checkbox disabled={readOnlyBidder} checked={!showAdditionalSteps} onChange={(e) => {
                      setShowAdditionalSteps(!e.target.checked)
                      if (additionalStepStatus == 'fumigation' && !jobInstruction.includes("-Fumigation is required") && showAdditionalSteps) {
                        setJobInstruction(jobInstruction + "\n -Fumigation is required.")
                      } else {
                        setJobInstruction(jobInstruction.replace("\n -Fumigation is required.", ''))
                      }

                    }} />}
                      label={t('additional_delivery_step')} />


                  </Grid>

                  <Grid hidden={showAdditionalSteps}>
                    <Grid style={{ 'margin': ' 0 0 2% 0' }}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          Status
                        </InputLabel>
                        <Select
                          disabled={readOnlyBidder}
                          value={additionalStepStatus}
                          onChange={(e) => {
                            setAdditionalStepStatus(e.target.value as any);
                            if (e.target.value == 'fumigation' && !jobInstruction.includes("-Fumigation is required")) {
                              setJobInstruction(jobInstruction + "\n -Fumigation is required.")
                            } else {
                              setJobInstruction(jobInstruction.replace("\n -Fumigation is required.", ''))
                            }
                          }}
                        >
                          {additionalStepOptions}
                        </Select>
                      </FormControl>

                    </Grid >


                  </Grid>
                </Grid>
              </>
            )
              : null
            }


            {tripType === ROUND_TRIP ? (
              <>
                <Grid item md={12}>
                  <Typography variant="body1" gutterBottom>
                    {t('return_to') + '*'}
                  </Typography>
                </Grid>
                {!readonly ? (
                  <>
                    {country === AUSTRALIA ? (
                      <Grid item md={12}>
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                        >
                          <InputLabel className={classes.selectLabel}>
                            {t('return_select_state')}
                          </InputLabel>
                          <Select
                            disabled={readonly}
                            value={returnToState}
                            onChange={(e) => {
                              setReturnToState(e.target.value as any);
                              setReturnToGroup('');
                              setReturnToAddressId('');
                            }}
                          >
                            {stateOptions}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null}
                    <Grid item md={12}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <InputLabel className={classes.selectLabel}>
                          {t('return_select_group')}
                        </InputLabel>
                        <Select
                          disabled={readonly}
                          value={returnToGroup}
                          onChange={(e) => {
                            setReturnToGroup(e.target.value as any);
                            setReturnToAddressId('');
                          }}
                        >
                          {returnToGroupOptions}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item md={10}>
                      <FormControl variant="filled" className={classes.formControl} >
                        <InputLabel className={classes.selectLabel}>
                          {t('return_select_address')}
                        </InputLabel>
                        <Select
                          disabled={readonly}
                          value={returnToAddressId}
                          onChange={(e) => {
                            setReturnToAddressId(e.target.value as any);
                          }}
                        >
                          {returnToAddressOptions}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <FormControl variant="filled" className={classes.formControl}>

                        <Button
                          onClick={handleEditReturnAddress(selectedJob)}
                          startIcon={<EditIcon />}
                          fullWidth

                          color="primary"
                          variant="contained"
                        >{t('edit')}</Button>

                      </FormControl>
                    </Grid>
                  </>
                ) : (
                  <Grid item md={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      disabled={true}
                      value={currentReturnToAddressString}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LocationOnIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}
              </>
            ) : null}
            {tripType === ROUND_TRIP ? (
              <>
                <Grid item md={12}>
                  <Typography variant="body1" gutterBottom>
                    {t('return_to_date_time') + '*'}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  {!readonly ? (
                    <MobileDateTimePicker
                      inputFormat='dd/MM/yyyy HH:mm'
                      disabled={readonly}
                      ampm={true}

                      toolbarTitle={t('select_date_time')}
                      // disabled={!isAssignedUser}
                      renderInput={(props) => (
                        <TextField fullWidth variant="outlined" {...props} />
                      )}
                      value={formatSaveOffset(returnDateTime)}
                      minDate={deliveryDateTime}
                      onAccept={(e: any) => {
                        setReturnDateTime(formatReadOffset(e))
                      }}
                      onChange={(e) => { }}
                    />
                    // <DateTimePicker
                    //   inputFormat='dd/MM/yyyy HH:mm'
                    //   ampm={false}
                    //   toolbarTitle={t('select_date_time')}
                    //   disabled={readonly}
                    //   renderInput={(props) => (
                    //     <TextField fullWidth variant="outlined" {...props} />
                    //   )}
                    //   value={returnDateTime}
                    //   onChange={setReturnDateTime}
                    //   minDate={deliveryDateTime}
                    // />

                  ) : (
                    <TextField
                      disabled={true}
                      value={formatDateTimeDisplay(returnDateTime)}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <TodayIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Grid>
              </>
            ) : null}


            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], PACKAGES_TYPE) && country !== AUSTRALIA ? (
              <Grid item md={12}>

                <MultiplePackageType
                  ref={setTypeOfPackageRef}
                  readonly={readonly}
                  movementType={movementType}
                  packageList={typeOfPackageList}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              ![FULL_TRUCK_LOAD, EMPTY_PARK, RAIL, WHARF].includes(
                movementType
              ) ? (
              <Grid item md={12}>

                <MultiplePackageType
                  ref={setTypeOfPackageRef}
                  readonly={readonly}
                  movementType={movementType}
                  packageList={typeOfPackageList}
                />
              </Grid>
            ) : null}

            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], QUANTITY) && country !== AUSTRALIA ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  label={
                    t(QUANTITY) +
                    (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                      ? '*'
                      : '')
                  }
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              ![FULL_TRUCK_LOAD, EMPTY_PARK, RAIL, WHARF].includes(
                movementType
              ) ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  fullWidth
                  label={
                    t(QUANTITY) +
                    (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                      ? '*'
                      : '')
                  }
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            <Grid item md={6}>
              <TextField
                disabled={readonly}
                value={declaredGrossWeight}
                onChange={(e) => {
                  const value = e.target.value;
                  if (isNumeric(value) || value === '')
                    setDeclaredGrossWeight(value);
                }}
                fullWidth
                label={t('declared_gross_weight') + '*'}
                type="text"
                variant="outlined"
                InputLabelProps={{
                  style: {
                    fontWeight: 'bold',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TextFieldsIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid hidden={!showPalletOptions()} item md={12}>
              <PalletAccount
                readOnly={readonly}
                setPalletAccountType={setPalletAccountType}
                setPalletAccountAction={setPalletAccountAction}
                setPalletAccountValue={setPalletAccountValue}
                palletAccountType={palletAccountType}
                palletAccountAction={palletAccountAction}
                palletAccountValue={palletAccountValue}
              />

            </Grid>
            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], VOLUME) && country !== AUSTRALIA ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={volume}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNumeric(value) || value === '') setVolume(value);
                  }}
                  fullWidth
                  label={
                    t(VOLUME) +
                    (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                      ? '*'
                      : '')
                  }
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              ![FULL_TRUCK_LOAD, EMPTY_PARK, RAIL, WHARF].includes(
                movementType
              ) ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={volume}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (isNumeric(value) || value === '') setVolume(value);
                  }}
                  fullWidth
                  label={
                    t(VOLUME) +
                    (+[FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType)
                      ? '*'
                      : '')
                  }
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], CN) && country !== AUSTRALIA ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={containerNo}
                  onChange={(e) => setContainerNo(e.target.value)}
                  fullWidth
                  label={t(CN)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              (![LOOSE_CARGO].includes(movementType) && ![LOOSE_CARGO].includes(jobType)) ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={containerNo}
                  onChange={(e) => setContainerNo(e.target.value)}
                  fullWidth
                  label={t(CN)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            {/* <Grid item md={6}> */}
            {country === 'PHILIPPINES' ? (
              <Grid item md={6}>{
                !isCNTRAllowed() ? (
                  <TextField
                    disabled={true}
                    value={
                      selectedJob?.container_no ?
                        formatDateTimeDisplay(selectedJob?.cntr_last_day) :
                        null
                    }
                    fullWidth

                    variant="outlined"
                    label={t(CNTR)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <DateTimePicker
                    inputFormat='dd/MM/yyyy HH:mm'
                    ampm={false}
                    toolbarTitle={"DT 1"}
                    disabled={readonly}
                    renderInput={(props) => (
                      <TextField fullWidth variant="outlined" {...props} />
                    )}
                    label={t(CNTR)}
                    value={CNTRLastDay}
                    onChange={setCNTRLastDay}
                  />

                )}
              </Grid>
            )
              :
              country === AUSTRALIA && (![LOOSE_CARGO].includes(movementType) && ![LOOSE_CARGO].includes(jobType)) ? (
                <Grid item md={6}>{
                  !isCNTRAllowed() ? (
                    <TextField
                      disabled={true}
                      value={
                        selectedJob?.container_no ?
                          formatDateTimeDisplay(
                            selectedJob?.cntr_last_day
                          ) : null}
                      label={t(CNTR)}
                      fullWidth
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <TodayIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : (
                    <DateTimePicker
                      inputFormat='dd/MM/yyyy HH:mm'
                      ampm={false}
                      toolbarTitle={t(CNTR)}
                      label={t(CNTR)}

                      disabled={readonly}
                      renderInput={(props) => (
                        <TextField fullWidth variant="outlined" {...props} />
                      )}
                      value={CNTRLastDay}
                      onChange={setCNTRLastDay}
                    />
                  )
                }
                </Grid>
              ) : null}
            {/* </Grid> */}



            {
              (isCNTRDehireAllowed() ?
                <Grid item md={6}>
                  <TextField
                    disabled={true}
                    value={formatDateTimeDisplay(CNTRDehireDay)}
                    label='CNTR Dehire Day'
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid> :
                null
              )
            }



            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], SEAL_NO) && country !== AUSTRALIA ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={sealNo}
                  onChange={(e) => setSealNo(e.target.value)}
                  fullWidth
                  label={t(SEAL_NO)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              (![LOOSE_CARGO].includes(movementType) && ![LOOSE_CARGO].includes(jobType)) ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={sealNo}
                  onChange={(e) => setSealNo(e.target.value)}
                  fullWidth
                  label={t(SEAL_NO)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}
            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], ECRNUM) && country !== AUSTRALIA ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={emptyContainerReleaseNumber}
                  onChange={(e) =>
                    setEmptyContainerReleaseNumber(e.target.value)
                  }
                  fullWidth
                  label={t(ECRNUM)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              (![FULL_TRUCK_LOAD, LOOSE_CARGO].includes(movementType) && ![LOOSE_CARGO].includes(jobType)) ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={emptyContainerReleaseNumber}
                  onChange={(e) =>
                    setEmptyContainerReleaseNumber(e.target.value)
                  }
                  fullWidth
                  label={t(ECRNUM)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}

            {isHidden([SINGAPORE, JAPAN, PHILIPPINES], WS) && country !== AUSTRALIA ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={wharfSlot}
                  onChange={(e) => setWharfSlot(e.target.value)}
                  fullWidth
                  label={t(WS)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : country === AUSTRALIA &&
              (![LOOSE_CARGO, FULL_TRUCK_LOAD].includes(movementType) && ![LOOSE_CARGO].includes(jobType)) ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={wharfSlot}
                  onChange={(e) => setWharfSlot(e.target.value)}
                  fullWidth
                  label={t(WS)}
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            ) : null}
            {isSuperAdmin || isUserAdmin ? (
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={initialPrice}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNonNumeric(value) || value === '') {
                      setInitialPrice(value);
                    }
                  }}
                  fullWidth
                  label={
                    ![CREATED, CLONED].includes(selectedJob?.status || CREATED)
                      ? isSuperAdmin || isUserAdmin ? t('sell_price') : isAssignedUser ? t('buy_price') : t('sell_price')
                      : t('initial_price')
                  }
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )
              : null
            }
            <>
              <Grid item md={6}>
                <TextField
                  disabled={readonly}
                  value={postedPrice}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (!isNonNumeric(value) || value === '') {
                      setPostedPrice(e.target.value);
                    }
                  }}
                  fullWidth
                  label={
                    ![CREATED, CLONED, IN_PROGRESS].includes(selectedJob?.status || CREATED)
                      ? (isSuperAdmin || isUserAdmin) ? t('posted_price') : (isAssignedUser) ? t('buy_price') : t('sell_price')
                      : t('posted_price')
                  }
                  type="text"
                  variant="outlined"
                  InputLabelProps={{
                    style: {
                      fontWeight: 'bold',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <TextFieldsIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>

            {readonly && selectedJob && status !== CREATED && (isUserAdmin || isSuperAdmin) ? (
              <>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={agreedBid}
                    fullWidth
                    label={t('agreed_price')}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}

            {readonly &&
              selectedJob &&
              (isSuperAdmin || isUserAdmin) &&
              status !== CREATED ? (
              <>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={priceDiff.toString().replace('-', '')}
                    fullWidth
                    label={t('price_diff')}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}
            {isCompanyJobOwner || isSuperAdmin || isUserAdmin ? (
              <>
                <Grid item md={6}>
                  <FormControl variant="filled" className={classes.formControl}>
                    <InputLabel className={classes.selectLabel}>
                      {t('post_to') + '*'}
                    </InputLabel>
                    <Select
                      disabled={readonly}
                      value={postTo}
                      onChange={(e) => setPostTo(e.target.value as string)}
                    >
                      <MenuItem value={PUBLIC}>{t(PUBLIC)}</MenuItem>
                      <MenuItem value={ASSOCIATES}>{t(ASSOCIATES)}</MenuItem>
                      <MenuItem value={HIDDEN}>{t(HIDDEN)}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}

            {postTo === ASSOCIATES &&
              (isCompanyJobOwner || isSuperAdmin || isUserAdmin) ? (
              <>
                <Grid item md={6}>
                  <MultiAutocomplete
                    disabled={readonly}
                    label={t('asscoiate_groups')}
                    options={associateGroups}
                    values={selectedAssociateGroups}
                    onChange={handleAssociateGroupSelect}
                  />
                </Grid>
              </>
            ) : null}

            {/* Additional Charges */}
            {props.mode === 'create' ? null : (
              <>
                <Grid item md={12}>
                  <Typography>{t('additional_charges')}</Typography>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={additionalChargeDescription1}
                    onChange={(e) => {
                      const value = e.target.value;
                      setAdditionalChargeDescription1(value);
                    }}
                    fullWidth
                    label={t('charge_description') + ' (1)'}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={additionalChargeAmount1}
                    onChange={(e) => {
                      const regex = /^[+-]?\d*(?:[.,]\d*)?$/;
                      if (regex.test(e.target.value)) {
                        setAdditionalChargeAmount1(e.target.value);
                      }
                    }}
                    fullWidth
                    label={t('charge_amount') + ' (1)'}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={additionalChargeDescription2}
                    onChange={(e) => {
                      const value = e.target.value;
                      setAdditionalChargeDescription2(value);
                    }}
                    fullWidth
                    label={t('charge_description') + ' (2)'}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    disabled={readonly}
                    value={additionalChargeAmount2}
                    onChange={(e) => {
                      const regex = /^[+-]?\d*(?:[.,]\d*)?$/;
                      if (regex.test(e.target.value)) {
                        setAdditionalChargeAmount2(e.target.value);
                      }
                    }}
                    fullWidth
                    label={t('charge_amount') + ' (2)'}
                    type="text"
                    variant="outlined"
                    InputLabelProps={{
                      style: {
                        fontWeight: 'bold',
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFieldsIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}

            {/* End Additional Charges */}

            <Grid item md={12}>
              <Typography variant="body1" gutterBottom>
                {t('job_instruction')}
              </Typography>
            </Grid>
            <Grid item md={12}>
              {(!readonly) ?
                <TextareaAutosize
                  disabled={readonly}
                  value={jobInstruction}
                  onChange={(e) => setJobInstruction(e.target.value)}
                  placeholder={mergedInstruction}
                  className={classes.textArea}
                  minRows={5}
                />
                : <TextareaAutosize
                  disabled={readonly}
                  // value={jobInstruction}
                  // onChange={(e) => setJobInstruction(e.target.value)}
                  placeholder={mergedInstruction}
                  className={classes.textArea}
                  minRows={5}
                />}

            </Grid>
            {selectedJob ? (
              <Grid item md={12}>
                <JobFilesTable job={selectedJob} />
              </Grid>
            ) : null}

            {selectedJob?.status == COMPLETED ?
              <Grid item md={6}>
                <FileInput
                  onChange={(e: any) => setFiles(e.target.files)}
                  disabled={readonly}
                  multiple
                  label='Upload POD'

                />
              </Grid>
              : null}
            {/* Search me */}
            {readonly && isDriverManager ? (
              <>
                <Grid item md={9}>
                  <FileInput
                    onChange={(e: any) => setPhotosOfDamages(e.target.files)}
                    disabled={false}
                    multiple
                    label={t('select_photo_of_damages')}
                    value="testing"
                  />
                </Grid>
                <Grid item md={3}>
                  <Button
                    onClick={handleOnUploadPhotoDamagesClick}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    {t('upload')}
                  </Button>
                </Grid>
              </>
            ) : null}
            {readonly && damageFilePhotos.length > 0 ? (
              <>
                <Grid item md={12}>
                  <Typography variant="body1" gutterBottom>
                    {t('damage_photo')}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <GridList cellHeight={160}>
                    {damageFilePhotos.map((damagePhoto, i) => (
                      <GridListTile key={damagePhoto.id}>
                        <Image
                          alt={t('damage_photo')}
                          src={formatFileUrl(damagePhoto.modified_file_name)}
                          onClick={() => {
                            handleOnImageSelect(
                              formatFileUrl(damagePhoto.modified_file_name)
                            );
                          }}
                        />
                      </GridListTile>
                    ))}
                  </GridList>
                </Grid>
              </>
            ) : null}
            {readonly &&
              selectedJob &&
              (isCompanyJobOwner ||
                isSuperAdmin ||
                isUserAdmin ||
                status !== CREATED) ? (
              <Grid item md={12}>
                <JobHistories job={selectedJob} />
              </Grid>
            ) : null}
            {!readonly && !isEditMode && selectedJob?.status !== IN_PROGRESS ? (
              <>

                <Grid md={2} item>
                  <Button
                    onClick={handleCreateOrUpdateClick}
                    fullWidth
                    className={classes.btnCreate}
                    startIcon={<DoneIcon />}
                    color="primary"
                    variant="contained"
                  >
                    {t('create')}
                  </Button>
                </Grid>
                <Grid md={2} item>
                  <Button
                    onClick={onAllocateClick}
                    fullWidth
                    startIcon={<PlayArrowIcon />}
                    color="primary"
                    variant="contained"
                  >
                    {t('allocate')}
                  </Button>
                </Grid>
                <Grid md={8} item style={{ 'float': 'right' }}>
                  {
                    (mode === 'create') ?
                      <Tooltip title="Admin Notes">
                        <Button onClick={(e) => { handleCommentDialogOpen(true) }}> <Badge overlap="rectangular" variant="dot" invisible={true} color="primary">
                          <SpeakerNotesIcon color="action" />
                        </Badge></Button>
                      </Tooltip> :
                      null
                  }
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default forwardRef(CreateNewJob);