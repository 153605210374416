import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import { useCallback, useEffect, useState } from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import DialpadIcon from "@material-ui/icons/Dialpad";
import HomeIcon from "@material-ui/icons/Home";
import MenuItem from "@material-ui/core/MenuItem";
import {
  COUNTRIES,
  USER_ROLES,
  VEHICLE_TYPES_BY_COUNTRY,
  COUNTRY_INSURANCES,
  LANGUAGES,
} from "../../../Types/Constants";
import { IDriverRegistrationPayload } from "../../../../../Application/DTOs/DriverDto/DriverDto.types";
import { Driver } from "../../../Api/Driver/Driver";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { getLabelForRegistrationNumberByCountry } from "../../../Utilities/Country";
import { isNumeric } from "../../../Utilities/Regex";
import { isNull, isEmpty } from "lodash";
import isEmail from "validator/lib/isEmail";
import { Hidden } from "@material-ui/core";
import { IDriverRegisterProps } from "./DriverRegister.types";
import TermsOfServiceDialog from "../../Dialogs/TermsOfServiceDialog/TermsOfServiceDialog";
import FileInput from "../../Common/FileInput/FileInput";
import useQuery from "../../../hooks/useQuery";
import { WebStorage } from "../../../Utilities/WebStorage";
import { setLanguage } from "../../../Redux/Language/actions";

const useStyles = makeStyles((theme) => ({
  driverRegisterBox: {
    backgroundColor: "white",
    padding: "40px 15px",
    borderRadius: "20px",
    boxShadow: "2px 3px 6px -1px rgba(0,0,0,0.5)",
    overflowY: "auto",
    height: "95vh",
  },
  logo: {
    width: "150px",
    height: "150px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  formControl: {
    minWidth: "100%",
  },
  title: {
    fontWeight: "bold",
  },
  gap: {
    marginTop: theme.spacing(3),
  },
  btnRegister: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  btnBack: {
    backgroundColor: "grey",
    color: "white",
    "&:hover": {
      backgroundColor: "grey",
    },
  },
  termsOfServiceBtn: {
    fontStyle: "italic",
    background: "none",
    border: "none",
    padding: "0",
    textDecoration: "underline",
    color: "#069",
    cursor: "pointer",
  },
}));

const { AUSTRALIA, JAPAN, PHILIPPINES, SINGAPORE } = COUNTRIES;
const { DRIVER } = USER_ROLES;

interface Languages {
  en: string;
  jp: string;
}

const driverApi = new Driver();

function DriverRegister(props: IDriverRegisterProps) {
  const { hideBack } = props;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [t, i18n] = useTranslation("DriverRegister");
  const [showPass, setShowPass] = useState<boolean>(false);
  const history = useHistory();
  const handleBackButtonClick = () => {
    history.push(routes.landing.login[0]);
  };

  const [country, setCountry] = useState(AUSTRALIA);
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileConfirm, setMobileConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUEN, setCompanyUEN] = useState("");
  const [streetNameAddress, setStreetNameAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [truckRegistration, setTruckRegistration] = useState("");
  const [trailerRegistration, setTrailerRegistration] = useState("");
  const [waitTimeAfter, setWaitTimeAfter] = useState("");
  const [waitTimeCharge, setWaitTimeCharge] = useState("");
  const [isWorkersComp, setIsWorkersComp] = useState(false);
  const [isMotorVehicle, setIsMotorVehicle] = useState(false);
  const [isPublicLiability, setIsPublicLiability] = useState(false);
  const [isMarineTransit, setIsMarineTransit] = useState(false);
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [licensePhoto, setLicensePhoto] = useState<File | null>(null);
  const [agreedTos, setAgreedTos] = useState(false);
  const [isTermsOfServiceDialogOpen, setIsTermsOfServiceDialogOpen] =
    useState(false);
  const [insurances, setInsurances] = useState<string[]>([]);
  const INSURANCES = COUNTRY_INSURANCES[country] ?? [];
  const params = useQuery();
  const [selectedLanguage] = useState(params.get("lang") ?? "en");
  const [isLoading, setIsLoading] = useState(selectedLanguage !== "en");

  // Set language based via url
  useEffect(() => {
    const lang: Languages = {
      en: LANGUAGES.ENGLISH,
      jp: LANGUAGES.JAPANESE,
    };
    let _lang = selectedLanguage as keyof Languages;
    let value = lang[_lang];
    i18n.changeLanguage(value);
    dispatch(setLanguage(value));
    setIsLoading(false);
  }, [dispatch, i18n, selectedLanguage]);

  const handleTermsOfServiceDialogClose = useCallback(() => {
    setIsTermsOfServiceDialogOpen(false);
  }, []);

  const handleOnRegisterClick = () => {
    //TODO: add validations

    if (isNull(country) || isEmpty(country)) {
      toast.warning(t("country_cannot_be_empty"));
      return;
    }

    if (email === "") {
      toast.warning(t("email_not_empty"));
      return;
    } else {
      if (!isEmail(email)) {
        toast.warning(t("email_invalid_format"));
        return;
      }
    }

    if (emailConfirm === "") {
      toast.warning(t("confirm_email_not_empty"));
      return;
    } else if (!isEmail(emailConfirm)) {
      toast.warning(t("confirm_email_invalid_format"));
      return;
    }

    if (email !== "" && emailConfirm !== "" && email !== emailConfirm) {
      toast.warning(t("email_not_match"));
      return;
    }

    if (!isNumeric(mobile) || !isNumeric(mobileConfirm)) {
      toast.warning(t("mobile_not_number"));
      return;
    }

    if (isNull(mobile) || isEmpty(mobile)) {
      toast.warning(t("mobile_not_empty"));
      return;
    } else {
      if (mobile !== mobileConfirm) {
        toast.warning(t("mobile_not_match"));
        return;
      }
    }

    if (isNull(firstName) || isEmpty(firstName)) {
      toast.warning(t("firstname_not_empty"));
      return;
    }

    if (isNull(lastName) || isEmpty(lastName)) {
      toast.warning(t("lastname_not_empty"));
      return;
    }

    if (password === "" || passwordConfirm === "") {
      toast.warning(t("password_not_null"));
      return;
    } else if (password.length < 6) {
      toast.warning(t("invalid_password_length"));
      return;
    } else if (password !== passwordConfirm) {
      toast.warning(t("password_did_not_match"));
      return;
    }

    if (isNull(companyName) || isEmpty(companyName)) {
      toast.warning(t("company_name_not_empty"));
      return;
    }

    if (country === PHILIPPINES) {
      if (companyUEN === "") {
        toast.warning(t("tin_not_empty"));
        return;
      } else if (!isNumeric(companyUEN)) {
        toast.warning(t("invalid_tin"));
        return;
      }
    }

    if (isNull(companyUEN) || isEmpty(companyUEN)) {
      if (country === AUSTRALIA) {
        toast.warning(t("abn_not_empty"));
        return;
      } else if (country === JAPAN) {
        toast.warning(t("company_registration_number_not_empty"));
        return;
      } else {
        toast.warning(t("eun_not_empty"));
        return;
      }
    }

    if (postalCode !== "" && !isNumeric(postalCode)) {
      toast.warning(t("postal_code_not_numeric"));
      return;
    }

    if (!isNumeric(waitTimeAfter)) {
      toast.warning(t("wait_time_not_number"));
      return;
    }

    if (!isNumeric(waitTimeCharge)) {
      toast.warning(t("wait_time_charge_not_number"));
      return;
    }
    if (insurances.length < 4) {
      toast.warning(t("select_coverage"));
      return;
    }

    if (photoFile === null) {
      toast.warning(t("photo_not_empty"));
      return;
    }

    if (licensePhoto === null) {
      toast.warning(t("license_not_empty"));
      return;
    }

    if (!agreedTos) {
      toast.warning(t("tc_agree"));
      return;
    }

    const formData = new FormData();
    const payload: IDriverRegistrationPayload = {
      city,
      company_name: companyName,
      company_uen: companyUEN,
      contact_phone: mobile,
      email,
      first_name: firstName,
      last_name: lastName,
      is_marine_transit: isMarineTransit,
      is_motor_vehicle: isMotorVehicle,
      is_public_liability: isPublicLiability,
      is_worker_comp: isWorkersComp,
      mobile,
      password,
      passwordConfirm,
      postcode: postalCode,
      street_num: streetNameAddress,
      trailer_registration: trailerRegistration,
      truck_registration: truckRegistration,
      userType: DRIVER,
      vehicle_type: vehicleType,
      wait_time_after: waitTimeAfter,
      wait_time_charge: waitTimeCharge,
      is_approved: false,
      country,
      is_email_contactable: true,
      is_sms_contactable: true,
      insurances: JSON.stringify(insurances) as any,
    };

    for (const key in payload) {
      const value: any = payload[key];

      formData.append(key, value);
    }

    if (photoFile) {
      formData.append("photoFile", photoFile);
    }

    if (licensePhoto) {
      formData.append("licenseFile", licensePhoto);
    }

    dispatch(setLoader(true));
    driverApi
      .create(formData)
      .then(() => {
        dispatch(setLoader(false));
        toast.success(t("succcess_registering_driver"));
      })
      .catch((err :any) => {
        dispatch(setLoader(false));
        toast.error(t(err.response?.data || "error_registering_driver"));
        console.log("err", err);
      });
  };

  const handleInsuranceCheck = useCallback(
    (isChecked: boolean, insurance: string) => {
      if (isChecked) {
        if (!insurances.includes(insurance)) {
          insurances.push(insurance);
          setInsurances([...insurances]);
        }
      } else {
        setInsurances(insurances.filter((ins) => ins !== insurance));
      }
    },
    [insurances]
  );

  if (isLoading) {
    return <></>;
  }

  return (
    <Container maxWidth="lg">
      <TermsOfServiceDialog
        agreed={agreedTos}
        onCheckBoxCheck={(agree) => {
          setAgreedTos(agree);
        }}
        open={isTermsOfServiceDialogOpen}
        handleClose={handleTermsOfServiceDialogClose}
        country={country}
      />
      <Box className={classes.driverRegisterBox}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={3}
        >
          <Grid lg={12} item>
            <Typography align="center" variant="h4" component="h4">
              {t("driver_registration")}
            </Typography>
          </Grid>
          {/* <Grid xs={12} lg={12} item>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel style={{ fontWeight: "bold" }}>
                {t("select_your_country")}
              </InputLabel>
              <Select
                className={classes.formControl}
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value as string);
                  setVehicleType("");
                }}
              >
                <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
          <Grid xs={12} lg={6} item>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("provide_email_address") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={emailConfirm}
              onChange={(e) => setEmailConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_email_address") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("provide_mobile_number") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DialpadIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={mobileConfirm}
              onChange={(e) => setMobileConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_mobile_number") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <DialpadIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_first_name") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_last_name") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_password") + "*"}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("confirm_password") + "*"}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>
          <Grid xs={12} lg={6} item>
            <TextField
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_company_name") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={companyUEN}
              onChange={(e) => setCompanyUEN(e.target.value)}
              variant="outlined"
              fullWidth
              label={t(getLabelForRegistrationNumberByCountry(country)) + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>

          <Grid lg={12} item>
            <Typography className={classes.title} align="left" variant="body1">
              {t("address_details")}
            </Typography>
          </Grid>
          <Grid xs={12} lg={12} item>
            <TextField
              value={streetNameAddress}
              onChange={(e) => setStreetNameAddress(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_street_num_n_name") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HomeIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={city}
              onChange={(e) => setCity(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_suburb_or_city") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HomeIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("enter_postal_code") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HomeIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>

          <Grid xs={12} lg={6} item>
            <TextField
              value={truckRegistration}
              onChange={(e) => setTruckRegistration(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("truck_reg")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={trailerRegistration}
              onChange={(e) => setTrailerRegistration(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("trailer_reg") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={12} item>
            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel style={{ fontWeight: "bold" }}>
                {t("vehicle_type")}*
              </InputLabel>
              <Select
                className={classes.formControl}
                value={vehicleType}
                onChange={(e) => setVehicleType(e.target.value as string)}
              >
                {(VEHICLE_TYPES_BY_COUNTRY[country] || []).map(
                  (VEHICLE_TYPE, i) => (
                    <MenuItem key={i} value={VEHICLE_TYPE}>
                      {t(VEHICLE_TYPE)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={waitTimeAfter}
              onChange={(e) => setWaitTimeAfter(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("wait_time_after") + "*"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <TextField
              value={waitTimeCharge}
              onChange={(e) => setWaitTimeCharge(e.target.value)}
              variant="outlined"
              fullWidth
              label={t("wait_time_charge") + "*"}
              placeholder={t("per_hour")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <TextFieldsIcon />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                style: {
                  fontWeight: "bold",
                },
              }}
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>

          <Grid lg={12} item>
            <Typography className={classes.title} align="left" variant="body1">
              {t("please_confirm_your_insurance_coverage")}*
            </Typography>
          </Grid>
          {INSURANCES.map((insurance, i) => (
            <Grid key={i} md={i % 2 === 0 ? 3 : 9} item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={insurances.includes(insurance)}
                    onChange={(e) =>
                      handleInsuranceCheck(e.target.checked, insurance)
                    }
                    color="primary"
                  />
                }
                label={t(insurance)}
              />
            </Grid>
          ))}
          <Grid className={classes.gap} md={12} item>
            {/* GAP */}
          </Grid>

          <Grid xs={12} lg={6} item>
            <FileInput
              onChange={(e: any) => setPhotoFile(e.target.files[0])}
              label={t("upload_photo") + "*"}
              multiple={false}
              accept="image/*"
            />
          </Grid>
          <Grid xs={12} lg={6} item>
            <FileInput
              onChange={(e: any) => setLicensePhoto(e.target.files[0])}
              label={t("upload_license") + "*"}
              multiple={false}
              accept="image/*"
            />
          </Grid>
          <Hidden xsDown>
            <Grid className={classes.gap} lg={12} item>
              {/* GAP */}
            </Grid>
          </Hidden>

          <Grid md={12} item>
            <Typography variant="caption" display="block" gutterBottom>
              {t("i_agree_to_tos")}{" "}
              <button
                onClick={() => setIsTermsOfServiceDialogOpen(true)}
                className={classes.termsOfServiceBtn}
              >
                {t("click_here_to_read_tos")}
              </button>{" "}
              {t("and_our")}
              <a href="https://moveit4u.com.au/privacypolicy/" target="_blank">
                {t("privacy_policy")}
              </a>{" "}
              {t("for_the_use_of_the_moveit4u_website_and_mobile_app")}{" "}
              <a href="https://moveit4u.com.au/pricing/" target="_blank">
                {t("pricing_page")}
              </a>
              .
            </Typography>
          </Grid>

          <Grid xs={12} lg={6} item>
            <Button
              onClick={handleOnRegisterClick}
              startIcon={<DoneOutlineIcon />}
              className={classes.btnRegister}
              variant="contained"
              fullWidth
            >
              {t("register")}
            </Button>
          </Grid>
          {!hideBack ? (
            <Grid xs={12} lg={6} item>
              <Button
                startIcon={<ArrowBackIcon />}
                className={classes.btnBack}
                variant="contained"
                fullWidth
                onClick={handleBackButtonClick}
              >
                {t("back")}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Container>
  );
}

export default DriverRegister;
