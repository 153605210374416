import {  Hidden, Typography, Grid, FormControl, InputLabel, MenuItem, Select, makeStyles, TextField, InputAdornment } from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { ICustomerDialog } from "./Customer.types";
import { useDispatch } from "react-redux";

import { useState , useCallback, useEffect, useImperativeHandle, forwardRef} from "react";
import { COUNTRIES } from "../../../Types/Constants";

import TextFieldsIcon from '@material-ui/icons/TextFields';
import DialpadIcon from '@material-ui/icons/Dialpad';
import HomeIcon from '@material-ui/icons/Home';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';


import { getLabelForRegistrationNumberByCountry } from "../../../Utilities/Country";

import useQuery from "../../../hooks/useQuery";
import { setLoader } from "../../../Redux/Loader/actions";
import MapAutocomplete from "../../Common/MapAutocomplete/MapAutocomplete";
import MapAddress from "../../../BP/MapAddress";
import { isNull, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { isNumeric } from "../../../Utilities/Regex";
import isEmail from "validator/lib/isEmail";
import { Customer } from "../../../Api/Customer/Customer";
import { ICustomerDetails } from "../../../../../Application/DTOs/CustomerDto/CustomerDto.types";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    formControl: {
        minWidth: '100%',
    },
    title: {
        fontWeight: 'bold'
    },
    editBtn: {
        backgroundColor: '#F1C40F',
        '&:hover': {
            backgroundColor: '#F1C40F',
        }
    },
    updatebtn: {
        color: 'white',
        backgroundColor: 'green',
        '&:hover': {
            backgroundColor: 'green',
        }
    },
    deletebtn: {
        color: 'white',
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red',
        }
    },
    gap: {
        marginTop: theme.spacing(2)
    }
}))



const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const customerApi = new Customer();

function CustomerDetails(props: ICustomerDialog , ref:any) {
    const [t] = useTranslation('JobHistories')
    const classes = useStyles()
    const dispatch = useDispatch();

    const {editMode , selectedCustomer} = props


    // const [isEditMode ,setIsEditMode] = useState(false)
    const [country, setCountry] = useState(AUSTRALIA);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyUEN, setCompanyUEN] = useState("");
    const [streetNameAddress, setStreetNameAddress] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [state, setState] = useState('');
    const [latitude, setLatitude] = useState<string>('')
    const [longitude, setLongitude] = useState<string>('')
    const [hasSearchAddress, setHasSearchAddress] = useState(false)
  


    const [userId , setUserId] = useState('')

    const handleMapAddressSelect = useCallback(
        (mapAddress: MapAddress) => {
            setHasSearchAddress(true)
            setStreetNameAddress([mapAddress.streetNum, mapAddress.route].join(' '))
            setState(mapAddress.state)
            setPostalCode(mapAddress.postalCode)
            setCity(mapAddress.city)
            setLatitude(String(mapAddress.lat))
            setLongitude(String(mapAddress.long))
        },
        [],
    )
 
    const setCustomerDetails =useCallback((customer : ICustomerDetails | undefined) =>{
        if(customer){
            setUserId(customer.user?.id)
            setCountry(customer.country)
            setEmail(customer.user?.email)
            // setEmailConfirm
            // setMobile(customer.contact_phone || '')
            setFirstName(customer.first_name)
            setLastName(customer.last_name)
            // setPassword()
            setCompanyName(customer.company_name)
            // setCompanyUEN(customer.company_uen || '')
            setStreetNameAddress(customer.street_number)
            setCity(customer.city)
            setPostalCode(customer.postcode)
        }
    },[selectedCustomer])

    useEffect(()=>{
        setCustomerDetails(selectedCustomer)
    },[setCustomerDetails , t, dispatch])

    const handleUpdateClick = (
        callback?: any,
        customer_id?: string | number
      ) => {
            if (isNull(country) || isEmpty(country)) {
                toast.warning(t("country_cannot_be_empty"));
                return;
            }
        
            if (email === "") {
                toast.warning(t("email_not_empty"));
                return;
            } else {
                if (!isEmail(email)) {
                    toast.warning(t("email_invalid_format"));
                    return;
                }
            }

            

            if (isNull(firstName) || isEmpty(firstName)) {
                toast.warning(t("firstname_not_empty"));
                return;
              }
          
              if (isNull(lastName) || isEmpty(lastName)) {
                toast.warning(t("lastname_not_empty"));
                return;
              }

              if (isNull(companyName) || isEmpty(companyName)) {
                toast.warning(t("company_name_not_empty"));
                return;
              }
          
              if (country === PHILIPPINES) {
                if (companyUEN === "") {
                  toast.warning(t("tin_not_empty"));
                  return;
                } else if (!isNumeric(companyUEN)) {
                  toast.warning(t("invalid_tin"));
                  return;
                }
              }
          
              if (postalCode !== "" && !isNumeric(postalCode)) {
                toast.warning(t("postal_code_not_numeric"));
                return;
              }


              const payload   = {
                city,
                company_name: companyName,
                company_uen: companyUEN,
                contact_phone: '',
                first_name: firstName,
                last_name: lastName,
                mobile : '',
                postcode: postalCode,
                street_number: streetNameAddress,
                country,
                state ,
                latitude,
                longitude
              };
              if(selectedCustomer)
              {
                dispatch(setLoader(true));
                customerApi
                    .update(selectedCustomer.id, payload)
                    .then(() => {
                    dispatch(setLoader(false));
                    if (callback) callback();

                    toast.success(t("success_updating_customer"));
                    })
                    .catch((err :any) => {
                    dispatch(setLoader(false));
                    toast.error(t(err.response?.data || "success_updating_customer"));
                    console.log("err", err);
                    });
                }
    }
    useImperativeHandle(ref, () => ({
        handleUpdate: (callback: any) => {
            handleUpdateClick(callback);
        },
      }));
    // const setCustomerDetails = useCallback(
    //     (customer: ICustomerDetails) => {
    //         setUserId(customer.user?.id)
    //         setCountry(customer.country)
    //         setEmail(customer.user?.email)
    //         // setEmailConfirm
    //         setMobile(customer.contact_phone || '')
    //         setFirstName(customer.first_name)
    //         setLastName(customer.last_name)
    //         // setPassword()
    //         setCompanyName(customer.company_name)
    //         setCompanyUEN(customer.company_uen || '')
    //         setStreetNameAddress(customer.street_number)
    //         setCity(customer.city)
    //         setPostalCode(customer.postcode)
           
    //     },
    //     [],
    // )
    

    return (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={3}
                    >
                    {/* <Grid xs={12} lg={12} item>
                        <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel style={{ fontWeight: "bold" }}>
                            {t("select_your_country")}
                        </InputLabel>
                        <Select
                            className={classes.formControl}
                            value={country}
                            disabled={!editMode}
                            onChange={(e) => {
                            setCountry(e.target.value as string);
                            }}
                        >
                            <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                            <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                            <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                            <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid> */}
                    <Grid xs={12} lg={6} item>
                        <TextField
                        disabled={true}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("provide_email_address") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <AlternateEmailIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    <Grid xs={12} lg={6} item>
                        <TextField
                        disabled={!editMode}

                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_first_name") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <TextFieldsIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    <Grid xs={12} lg={6} item>
                        <TextField
                        disabled={!editMode}

                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_last_name") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <TextFieldsIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    {/* <Grid xs={12} lg={6} item>
                        <TextField
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_password") + "*"}
                        type={showPass ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPass(!showPass)}>
                                {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    <Grid xs={12} lg={6} item>
                        <TextField
                        value={passwordConfirm}
                        onChange={(e) => setPasswordConfirm(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("confirm_password") + "*"}
                        type={showPass ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPass(!showPass)}>
                                {!showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid> */}
                    <Hidden xsDown>
                        <Grid className={classes.gap} lg={12} item>
                        {/* GAP */}
                        </Grid>
                    </Hidden>
                    <Grid xs={12} lg={6} item>
                        <TextField
                        disabled={!editMode}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_company_name") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <TextFieldsIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    <Hidden xsDown>
                        <Grid className={classes.gap} lg={12} item>
                        {/* GAP */}
                        </Grid>
                    </Hidden>

                    <Grid lg={12} item>
                        <Typography className={classes.title} align="left" variant="body1">
                        {t("main_address_details")}
                        </Typography>
                    </Grid>
                    <Grid xs={12} lg={12} item>
                    
                        <MapAutocomplete readOnly={!editMode} onAddressSelect={handleMapAddressSelect}  />
                                            
                        
                    </Grid>
                    <Grid xs={12} lg={12} item>
                    <TextField
                        disabled={!editMode}

                        value={streetNameAddress}
                        onChange={(e) => setStreetNameAddress(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_street_num_n_name") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <HomeIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>

                    <Grid xs={12} lg={6} item>
                        <TextField
                        disabled={!editMode}

                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_suburb_or_city") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <HomeIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    <Grid xs={12} lg={6} item>
                        <TextField
                        disabled={!editMode}

                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        variant="outlined"
                        fullWidth
                        label={t("enter_postal_code") + "*"}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <HomeIcon />
                            </InputAdornment>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                            fontWeight: "bold",
                            },
                        }}
                        />
                    </Grid>
                    <Hidden xsDown>
                        <Grid className={classes.gap} lg={12} item>
                        {/* GAP */}
                        </Grid>
                    </Hidden>

                    

                    
                    </Grid>

        
    )
}

export default forwardRef(CustomerDetails)