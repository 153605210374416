import { WebStorage } from "../../Utilities/WebStorage"
import axios from "axios"
import { TOKEN_STORAGE_KEY } from "../../Types/Constants"
import { TShowTypes } from '../../Components/Home/Quotes/Quotes.types'

const baseUrl = process.env.REACT_APP_API_URL

const storage = new WebStorage()

export class QuotesApi {
  create(formData: FormData) {
    return axios.post(`${baseUrl}/quote/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getTempReference() {
    return axios.get(`${baseUrl}/quote/reference`, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getDetails(quote_id: any) {
    return axios.get(`${baseUrl}/quote/${quote_id}`, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  getQuoteResponse(quote_id: any) {
    return axios.get(`${baseUrl}/quote/response/${quote_id}`, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  sendEmails(payload: FormData, id: any) {
    return axios.post(`${baseUrl}/quote/send/emails/${id}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }


  lazyGet(currentPage: number, countPerPage: number, filter: any, page) {
    return axios.get(`${baseUrl}/quote`, {
      params: {
        currentPage,
        countPerPage,
        filter,
        page
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  cancelQuote(id: any) {
    return axios.put(`${baseUrl}/quote/cancel/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  setQuoteToActive(id: any) {
    return axios.put(`${baseUrl}/quote/active/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  
  setQuoteAsGenerated(id: any) {
    return axios.put(`${baseUrl}/quote/generated/${id}`, {}, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }
  approveQuoteResponse(id: any, buy_rate: any, sell_rate: any , approve: any) {
    return axios.post(`${baseUrl}/quote/approve/response/${id}`, { buy_rate, sell_rate , approve }, {
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

 

  downloadQuoteFile(fileId: any) {
    return axios.get(`${baseUrl}/quote/file/download`, {
      responseType: 'blob',
      params: {
        fileId,
      },
      headers: {
        Authorization: `Bearer ${storage.retrieve(TOKEN_STORAGE_KEY)}`,
      },
    });
  }

}