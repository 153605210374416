import { Grid, Typography, Divider, Button, TextField, InputAdornment } from '@material-ui/core'
import { DataGrid, GridRowParams } from '@material-ui/data-grid'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ICompanyInfo } from '../../../../../../Application/DTOs/CompanyDto/CompanyDto.type'
import { Warehouse } from '../../../../Api/Warehouse/Warehouse'

import { AppState } from '../../../../Redux'

import { setLoader } from '../../../../Redux/Loader/actions'
import CustomTable from '../../../Common/CustomTable/CustomTable'
// import ViewCustomerDialog from '../../../Dialogs/ViewCustomerDialog/ViewCustomerDialog'
import ViewWarehouseDialog from '../../../Dialogs/ViewWarehouseDialog/ViewWarehouseDialog'

import { getColumns } from './WarehouseManagementPage.columns'
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import LazyTable from '../../../Common/LazyTable/LazyTable';
import { USER_ROLES } from '../../../../Types/Constants';
import { Address } from "../../../../Api/Address/Address";
import SectionHeader from '../../../Common/SectionHeader/SectionHeader'

const warehouseApi = new Warehouse()
const addressApi = new Address()
function ManagementPage() {
    const [t] = useTranslation(['Warehouse', 'Columns'])
    const dispatch = useDispatch()
    const [selectedCustomerId, setSelectedCustomerId] = useState<string | number | null>(null)
    const [searchByName, setSearchByName] = useState('');
    const [retainCurrent, setRetainCurrent] = useState(false);
    const [filterState, setFilterState] = useState<any>('')
    const [viewDialog, setViewDialog] = useState(false);
    const user = useSelector((state: AppState) => state.user.response);

    const { ADMIN_USER, SUPER_ADMIN, DRIVER_MANAGER, WAREHOUSE } = USER_ROLES;
    const [warehouseDataset, setWarehouseDataset] = useState<any[]>([])
    let userId = user.company.id
    const isUserAdmin = [ADMIN_USER, SUPER_ADMIN].includes(user?.type);
    const isWarehouse = [WAREHOUSE].includes(user?.type);

    if (isWarehouse) { userId = user.warehouse.id }
    const onRowClick = useCallback((params: GridRowParams) => {
        setViewDialog(true)
        setSelectedCustomerId(params.row.warehouse_id)
    }, []);

    // const lazyGet = async (offset: number, pageNumber: number, limit: number, filter: any) => {
    //     return warehouseApi.getAll(offset, limit, isUserAdmin, searchByName == '' ? undefined : searchByName, userId as any)
    //         .then((response) => {
    //             const rows = response.data.rows;
    //             const count = response.data.count;
    //             return { jobs: rows, count }
    //         })
    //         .catch((err: any) => {
    //             toast.error(t('error_getting_customers'));
    //             return { jobs: [], count: 0 }

    //         });

    // }



    const getAll = () => {
        warehouseApi.getAll(isUserAdmin, searchByName == '' ? undefined : searchByName, userId as any).then((response: any) => {
            setWarehouseDataset(response.data)
        })
    }
    useEffect(() => {
        let timeOutId = setTimeout(() => {
            getAll()

        }, 500)
        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchByName])


    const onAddressDisable = useCallback((param) => {

        addressApi.updateStatus(param.id, !param.status).then(() => {
            toast.success(t('address_was_' + param.status))
            getAll()

        }).catch(() => {
            toast.error(t('error_encountered'))
        })

    }, [])
    const columns = getColumns(t, user.type, onAddressDisable)

    const handleViewCustomerDialogclose = useCallback(
        () => {

            setRetainCurrent(!retainCurrent)
            setSelectedCustomerId(null)
            setViewDialog(false)
        },
        [selectedCustomerId],
    )

    return (
        <>
            <ViewWarehouseDialog warehouseId={selectedCustomerId} handleClose={handleViewCustomerDialogclose} open={viewDialog} />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={5}
            >
                <SectionHeader subtitle={t("warehouse_management")} title={t("my_warehouses")} />
                {/* <Grid md item>
                    <Typography>
                        {t('warehouse_management')}
                    </Typography>
            </Grid> */}
               

                <Grid item md={12}>
                    <Grid container spacing={2} justifyContent="flex-end">
                        {/* 
                        <Grid item md={6}></Grid>

                        <Grid item md={4}>
                            <TextField
                                value={searchByName}
                                onChange={(e) => setSearchByName(e.target.value)}
                                // onKeyDown={(e) => handleSearch(e)}
                                variant="outlined"
                                fullWidth
                                label={t('search')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold',
                                    },
                                }}
                            />
                        </Grid> */}

                        <Grid style={{ "height": '500px', width: "100%" }} item md={12}>
                            <CustomTable
                                columns={columns}
                                onRowClick={onRowClick}
                                rows={warehouseDataset}
                            />
                            {/* <LazyTable
                                columns={columns}
                                onRowClick={onRowClick}
                                lazyFunction={lazyGet}
                                lazyFilter={filterState}
                                retainCurrent={retainCurrent}

                            /> */}

                        </Grid>
                    </Grid>
                </Grid>

            </Grid >
        </>
    )
}

export default ManagementPage