import React, { useState, useEffect } from 'react';
import {
    Table, CircularProgress, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Box, TablePagination, makeStyles, TextField, Grid
} from '@material-ui/core';
import useDebouncedValue from '../../../hooks/useDebouncedValue';

const useStyles = makeStyles((theme) => ({
    tableRow: {
        cursor: 'pointer',
        height: '50px',
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2) !important', // Subtle shadow effect
        },
    },
    tableCell: {
        padding: '10px 10px', // Improved padding for better spacing
        fontSize: '12px !important', // Slightly larger font size,
    },
    tableHeader: {
        backgroundColor: '#223A59', // Main blue color for header,
        position: 'sticky',
        top: 0,
        zIndex: 103

    },
    headerText: {
        color: 'white',
        fontSize: '1rem', 
        textTransform: 'uppercase',
        padding: '5px 20px',
        textAlign: 'center'
    },
    pagination: {
        backgroundColor: theme.palette.background.paper,
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent background
    },
    tableContainer: {
        overflow: 'auto', // Only horizontal scrolling
        minHeight: '70vh',
        maxHeight: '70vh',
        '&::-webkit-scrollbar': {

            width: '.5em',
            height: '.5em'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1',
            borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#555',
        },
    },
    alternatingRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.selected, // Alternating row colors
        },
    },
    stickyColumnHead: {
        position: 'sticky',
        left: 0,
        zIndex: 101, // Higher zIndex than the default (100)
        backgroundColor: '#223A59'
    },
    secondStickyColumnHead: {
        position: 'sticky',
        left: '200px', // Adjust based on the width of the first column
        zIndex: 100, // Lower zIndex than the first sticky column,
        backgroundColor: '#223A59'

    },
    stickyColumnBody: {
        position: 'sticky',
        left: 0,
        zIndex: 100,
        backgroundColor: '#f0f0f0'

    },
    secondStickyColumnBody: {
        position: 'sticky',
        left: '200px', // Adjust based on the width of the first column
        zIndex: 99, // Lower zIndex than the first sticky column
        backgroundColor: '#f0f0f0',

    },
    scrollableColumns: {
        width: 'max-content',
        overflowX: 'auto',
    },
    searchBoxContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 0'
    },
    searchBox: {
        width: '300px'
    }
}));

const LazyLoadingTable = ({ columns, fetchDataFromAPI, onRowClick, searchParam, pageType, updateTable }) => {
    const classes = useStyles();
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ field: (pageType == 'completed_jobs') ? 'date_completed' : 'reference', sort: 'asc' });
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState(searchParam);


    const [filterState, setFilterState] = useState<{ [key: string]: any }>({ 'reference': searchTerm })
    const debouncedOffset = useDebouncedValue(offset, 300);
    const debouncedSortConfig = useDebouncedValue(sortConfig, 300);
    const debouncedFilters = useDebouncedValue(filterState, 300);

    useEffect(() => {
        fetchData();
    }, [debouncedOffset, debouncedSortConfig, debouncedFilters, pageType, limit]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await fetchDataFromAPI(limit, offset, debouncedSortConfig, debouncedFilters);
            const { rows, count } = response;
            setTableData(rows);
            setTotalItems(count);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event, newPage) => {
        const newOffset = newPage * limit;
        setOffset(newOffset);
    };

    const handleRowsPerPageChange = (event) => {
        const newLimit = parseInt(event.target.value, 10);
        setOffset(0);
        setLimit(newLimit);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setFilterState({ reference: event.target.value })
    };

    const requestSort = (field) => {
        let newSortConfig = {
            field,
            sort: 'asc'
        };

        if (sortConfig.field === field && sortConfig.sort === 'asc') {
            newSortConfig.sort = 'desc';
        }

        setSortConfig(newSortConfig);
    };

    const handleRowClick = (rowData, event) => {
        if (event.target.tagName.toLowerCase() === 'button') {
            return;
        }
        if (onRowClick) {
            event.preventDefault();
            onRowClick(rowData);
        }
    };

    const renderCellContent = (rowData, column) => {
        const fieldPath = column.field.split('.');
        let value = rowData;

        for (const field of fieldPath) {
            value = value?.[field];
        }

        if (column.formatter && typeof column.formatter === 'function') {
            return column.formatter(value, rowData);
        }

        if (column.renderCell && typeof column.renderCell === 'function') {
            return column.renderCell(rowData);
        }

        return value;
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Grid container className={classes.searchBoxContainer}>
                <TextField
                    id="outlined-basic"
                    value={searchTerm}
                    onChange={handleSearch}
                    label="Search for reference..."
                    variant="outlined"
                    className={classes.searchBox}
                />
            </Grid>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.scrollableColumns}>
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            {columns.map((column, index) => (
                                !column.hidden && (
                                    <TableCell
                                        key={index}
                                        className={`${classes.headerText} ${index === 0 ? classes.stickyColumnHead : index === 1 ? classes.secondStickyColumnHead : ''}`}
                                        style={{ width: column.width, cursor: column.sort ? 'pointer' : 'default' }}
                                        onClick={() => column.sort && requestSort(column.field)}
                                    >
                                        {column.headerName} {column.sort && (sortConfig.field === column.field ? (sortConfig.sort === 'asc' ? '↑' : '↓') : '')}
                                    </TableCell>
                                )
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((item, rowIndex) => (
                            <TableRow  key={rowIndex} onClick={(event) => handleRowClick(item, event)} className={`${classes.tableRow} ${classes.alternatingRow}`}>
                                {columns.map((column, colIndex) => (
                                    !column.hidden && (
                                        <TableCell
                                            key={colIndex}
                                            className={`${classes.tableCell} ${colIndex === 0 ? classes.stickyColumnBody : colIndex === 1 ? classes.secondStickyColumnBody : ''}`}
                                            style={{ width: column.width }}
                                        >
                                            {renderCellContent(item, column)}
                                        </TableCell>
                                    )
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* {loading && (
                <Box className={classes.loadingOverlay}>
                    <CircularProgress />
                </Box>
            )} */}
            <TablePagination
                className={classes.pagination}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalItems}
                rowsPerPage={limit}
                page={Math.floor(offset / limit)}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </Box>
    );
};

export default LazyLoadingTable;
