import { Grid, makeStyles, FormControl, InputLabel, Select, MenuItem, TextField, Container, Button } from "@material-ui/core";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { COUNTRIES, JOB_TYPES, VEHICLE_TYPES, ALL, USER_ROLES } from "../../../Types/Constants";
import { IFilterPopoverProps } from "./FilterPopover.types";
import { MobileDatePicker } from '@material-ui/pickers'
import { IJobFilters } from "../../../../../Application/DTOs/JobsDto/JobsDto.types";
import { useDidStateUpdate } from "../../../Utilities/CustomHooks";
import { useSelector } from "react-redux";
import { AppState } from "../../../Redux";
import { IN_PROGRESS, ASSIGNED } from '../../Home/Jobs/Jobs.constans'
const useStyles = makeStyles(() => ({
    formControl: {
        minWidth: '100%'
    },
    container: {
        padding: '20px'
    }
}))

const {
    AUSTRALIA,
    JAPAN,
    PHILIPPINES,
    SINGAPORE
} = COUNTRIES

const {
    SUPER_ADMIN
} = USER_ROLES

function FilterPopover(props: IFilterPopoverProps) {
    const [t] = useTranslation()
    const user = useSelector((state: AppState) => state.user)
    const isSuperAdmin = user.response?.type === SUPER_ADMIN
    const dateFormat = 'dd/MM/yyyy'
    const { onFilterChange, filters, hidePickUpDateSelector, hideCompletedDateSelector, onClose } = props
    const classes = useStyles()
    const [country, setCountry] = useState<string | undefined>(AUSTRALIA)
    const [pickupFromDate, setPickupFromDate] = useState<Date | null>(filters.pickUpDate?.from ? new Date(filters.pickUpDate?.from) : null);
    const [pickUpToDate, setPickupToDate] = useState<Date | null>(filters.pickUpDate?.to ? new Date(filters.pickUpDate?.to) : null);
    const [completedFromDate, setCompletedFromDate] = useState<Date | null>(filters.completedDate?.from ? new Date(filters.completedDate?.from) : null)
    const [completedToDate, setCompletedToDate] = useState<Date | null>(filters.completedDate?.to ? new Date(filters.completedDate?.to) : null)
    const [jobType, setJobType] = useState<string | undefined>(filters.jobType)
    const [vehicleType, setVehicleType] = useState<string | undefined>(filters.vehicleType)
    const [status, setStatus] = useState<string | undefined>(filters.status)
    const statusList = {
        ASSIGNED: 'assigned',
        // OWNED: 'owned',
        PICKED_UP: "picked_up",
        DELIVERED: 'delivered'
    }

    useDidStateUpdate(() => {
        let jobFilter: IJobFilters = {
            country,
            jobType,
            vehicleType,
            status
        }

        if (pickupFromDate && pickUpToDate) {
            jobFilter.pickUpDate = {
                from: pickupFromDate.toISOString(),
                to: pickUpToDate.toISOString()
            }
        }

        if (completedToDate && completedFromDate) {
            jobFilter.completedDate = {
                from: completedFromDate.toISOString(),
                to: completedToDate.toISOString()
            }
        }

        onFilterChange(jobFilter)

    }, [country, pickupFromDate, pickUpToDate, jobType, vehicleType, completedFromDate, completedToDate, status])

    const handleStatusChange = (e: any) => {
        setStatus(e.target.value)
    }
    const handleOnApplyClear = useCallback(
        () => {
            setJobType(ALL)
            setVehicleType(ALL)
            setStatus(ALL)
            setPickupFromDate(null)
            setPickupToDate(null)
            setCompletedFromDate(null)
            setCompletedToDate(null)
        },
        [],
    )
    return (
        <Container className={classes.container}>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="center"
            >
                {/* {
                    isSuperAdmin
                        ? <Grid md={12} item>
                            <FormControl variant="filled" className={classes.formControl}>
                                <InputLabel style={{ fontWeight: 'bold' }}>{t('country')}</InputLabel>
                                <Select
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value as string)
                                    }}
                                >
                                    <MenuItem value={PHILIPPINES}>{t(PHILIPPINES)}</MenuItem>
                                    <MenuItem value={AUSTRALIA}>{t(AUSTRALIA)}</MenuItem>
                                    <MenuItem value={JAPAN}>{t(JAPAN)}</MenuItem>
                                    <MenuItem value={SINGAPORE}>{t(SINGAPORE)}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        : null
                } */}
                <Grid md={12} item>
                    <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel style={{ fontWeight: 'bold' }}>{t('job_type')}</InputLabel>
                        <Select
                            value={jobType}
                            onChange={(e) => {
                                setJobType(e.target.value as string)
                            }}
                        >
                            <MenuItem value={ALL}>{t(ALL)}</MenuItem>
                            {Object.values(JOB_TYPES).map((jobType, i) => (
                                <MenuItem key={i} value={jobType}>{t(jobType)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid md={12} item>
                    <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel style={{ fontWeight: 'bold' }}>{t('vehicle_type')}</InputLabel>
                        <Select
                            value={vehicleType}
                            onChange={(e) => {
                                setVehicleType(e.target.value as string)
                            }}
                        >
                            <MenuItem value={ALL}>{t(ALL)}</MenuItem>
                            {Object.values(VEHICLE_TYPES).map((vehicleType, i) => (
                                <MenuItem key={i} value={vehicleType}>{t(vehicleType)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid md={12} item>
                    <FormControl variant="filled" className={classes.formControl}>
                        <InputLabel style={{ fontWeight: 'bold' }}>{t('status')}</InputLabel>
                        <Select
                            value={status}
                            onChange={(e) => {
                                handleStatusChange(e)
                            }}
                        >
                            <MenuItem value={ALL}>{t(ALL)}</MenuItem>
                            {Object.values(statusList).map((statusType, i) => (
                                <MenuItem key={i} value={statusType}>{t(statusType)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {
                    !hidePickUpDateSelector
                        ? <>
                            <Grid md={12} item>
                                <MobileDatePicker
                                    label={t('pickup_from_date')}
                                    value={pickupFromDate}
                                    inputFormat={dateFormat}
                                    onChange={setPickupFromDate}
                                    renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                />
                            </Grid>
                            <Grid md={12} item>
                                <MobileDatePicker
                                    label={t('pickup_to_date')}
                                    minDate={pickupFromDate}
                                    inputFormat={dateFormat}
                                    value={pickUpToDate}
                                    onChange={setPickupToDate}
                                    renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                />
                            </Grid>
                        </>
                        : null
                }

                {
                    !hideCompletedDateSelector
                        ? <>
                            <Grid md={12} item>
                                <MobileDatePicker
                                    label={t('completed_from_date')}
                                    value={completedFromDate}
                                    inputFormat={dateFormat}
                                    onChange={setCompletedFromDate}
                                    renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                />
                            </Grid>
                            <Grid md={12} item>
                                <MobileDatePicker
                                    label={t('completed_to_date')}
                                    minDate={completedFromDate}
                                    value={completedToDate}
                                    inputFormat={dateFormat}
                                    onChange={setCompletedToDate}
                                    renderInput={(params) => <TextField fullWidth variant="outlined" {...params} />}
                                />
                            </Grid>
                        </>
                        : null
                }

                <Grid item container spacing={1} md={12}>
                    <Grid item md={6} >
                        <Button fullWidth onClick={onClose} color="default" variant="contained">
                            {t('close')}
                        </Button>
                    </Grid>
                    <Grid item md={6} >

                        <Button fullWidth onClick={handleOnApplyClear} color="primary" variant="contained">
                            Clear
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </Container>
    )
}

export default FilterPopover