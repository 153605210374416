import React from "react"
import { useSelector } from "react-redux"
import { useRouteMatch, Route } from "react-router-dom"
import { AppState } from "../../../Redux"
import { routes } from "../../../routes"
import { USER_ROLES } from "../../../Types/Constants"
import AddUser from "./AddUser/AddUser"
import AdminUsers from "./AdminUsers/AdminUsers"
import AllUsers from "./AllUsers/AllUsers"

const {
    ADMIN,
    SUPER_ADMIN
} = USER_ROLES

function UserManagement() {
    const match = useRouteMatch()
    const user = useSelector((state: AppState) => state.user)
    const isUserAdmin = [ADMIN, SUPER_ADMIN].includes(user.response?.type || '')

    return (
        <React.Fragment>
            <Route path={match.path + routes.home.user_management.add}>
                <AddUser />
            </Route>
            <Route path={match.path + routes.home.user_management.all}>
                <AllUsers />
            </Route>
            {
                isUserAdmin
                    ? <Route path={match.path + routes.home.user_management.admin}>
                        <AdminUsers />
                    </Route>
                    : null
            }

        </React.Fragment>
    )
}

export default UserManagement