import { Typography } from '@material-ui/core';
import { GridColumns } from '@material-ui/data-grid';
import { TFunction } from 'i18next';
import { IJobInfo } from '../../../../../../Application/DTOs/JobsDto/JobsDto.types';
import { OVERDUE_CSV_COLUMNS } from './columns';
import { IUserState } from '../../../../Redux/User/type';
import { formatDate, formatTime } from '../../../../Utilities/DateTime';
export const getOverdueReportColumns = (
  t: TFunction,
  user: IUserState
): GridColumns => {
  return [
    {
      field: 'reference',
      headerName: t(`Columns:${OVERDUE_CSV_COLUMNS.REFERENCE}`),
      width: 200,
    },
    {
      field: 'customer_reference',
      headerName: t('Columns:customer_reference'),
      width: 200,
      renderCell: (params) => {
        const job: IJobInfo = params.row as IJobInfo
        return (
          <Typography>
            {job.customer_reference}
          </Typography>
        )
      }
    },
    {
      field: 'company',
      headerName: t('Columns:company'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const companyName: string = String(jobInfo?.company?.name);
        return <Typography>{companyName}</Typography>;
      },
    },
    {
      field: 'driver',
      headerName: t('Columns:driver'),
      width: 250,
      resizable: true,
      renderCell: (params) => {
        const jobInfo = params.row;
        const drivername: string = String(jobInfo?.assigned_user?.first_name || '') + " " + String(jobInfo?.assigned_user?.last_name || '');
        return <Typography>{drivername || ""}</Typography>;
      },
    },
    {
      field: 'delivery_date_time',
      headerName: t(`Columns:${OVERDUE_CSV_COLUMNS.DUE_DELIVERY_DATE}`),
      width: 220,
      renderCell: (params) => {
        const jobInfo = params.row as IJobInfo;
        const deliver_date: string = String(jobInfo?.delivery_date_time);
        return <Typography>{formatDate(deliver_date)}</Typography>;
      },

    },
    {
      field: 'delivery_time',
      headerName: t(`Columns:${OVERDUE_CSV_COLUMNS.DELIVERY_TIME}`),
      width: 220,
      renderCell: (params) => {
        const jobInfo = params.row as IJobInfo;
        const deliver_date: string = String(jobInfo?.delivery_date_time);
        return <Typography>{formatTime(deliver_date)}</Typography>;
      },

    },
    {
      field: 'transport_company',
      headerName: t('Columns:transport_company'),
      width: 250,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {params.row.assigned_user?.company?.name}
          </Typography>
        )

      }
    },
    {
      field: 'transport_driver',
      headerName: t('Columns:driver'),
      width: 250,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {params.row.assigned_user ? params.row.assigned_user?.first_name || '' + " " + params.row.assigned_user?.last_name || '' : ''}
          </Typography>
        )
      }
    },
    {
      field: 'transport_reference',
      headerName: t('Columns:transport_reference'),
      width: 300,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {job.transport_reference}
          </Typography>
        )
      }
    },
    {
      field: 'movement_type',
      headerName: t('Columns:movement_type'),
      width: 200,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {t(job.movement_type?.code)}
          </Typography>
        )
      }
    },
    {
      field: 'delivery_city',
      headerName: t('Columns:delivery_city'),
      width: 200,
      renderCell: (params) => {
        let job = params.row

        return (
          <Typography>
            {job.delivery_address?.city}
          </Typography>
        )
      }
    },

  ];
};
