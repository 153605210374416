import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { IAssociateGroupDialogProps } from "./AssociateGroupDialog.types";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import MultiAutocomplete from "../../Common/MultiAutocomplete/MultiAutocomplete";
import { useCallback, useEffect, useState } from "react";
import { IAutocompleteOption } from "../../Common/types";
import { Company } from "../../../Api/Company/Company";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Redux/Loader/actions";
import { AppState } from "../../../Redux";
import { toast } from "react-toastify";
import { ICompanyInfo } from "../../../../../Application/DTOs/CompanyDto/CompanyDto.type";
import { Driver } from "../../../Api/Driver/Driver";
import { IUserInfo } from "../../../../../Application/DTOs/UsersDto/UsersDto.type";
import { companiesToOptions, driverToOptions } from "../../../Utilities/Autocomplete";

const companyApi = new Company()

const driverApi = new Driver()

function AssociateGroupDialog(props: IAssociateGroupDialogProps) {
    const [t] = useTranslation('AssociateGroupDialog')
    const user = useSelector((state: AppState) => state.user)
    const dispatch = useDispatch()
    const { open, handleClose, selectedGroup } = props
    const [companyOptions, setCompanyOptions] = useState<IAutocompleteOption[]>([])
    const [driverOptions, setDriverOptions] = useState<IAutocompleteOption[]>([])
    const [name, setName] = useState('')
    const [selectedCompanyOptions, setSelectedCompanyOptions] = useState<IAutocompleteOption[]>([])
    const [selectedDriverOptions, setSelectedDriverOptions] = useState<IAutocompleteOption[]>([])

    const getRelatedDrivers = useCallback(
        () => {
            dispatch(setLoader(true))
            driverApi.getRelatedDrivers(user.response?.company?.id ?? '')
                .then((response) => {
                    const users: IUserInfo[] = response.data

                    setDriverOptions(users.map(user => driverToOptions(user.driver as any)))
                    dispatch(setLoader(false))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_related_drivers'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, user.response?.company?.id]
    )

    const getRelatedCompanies = useCallback(
        () => {
            dispatch(setLoader(true))
            companyApi.getRelatedCompanies(String(user.response?.company?.id), user.response?.company?.country ?? '')
                .then((response) => {
                    const companies: ICompanyInfo[] = response.data
                    dispatch(setLoader(false))
                    setCompanyOptions(companies.map<IAutocompleteOption>(companiesToOptions))
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_getting_related_companies'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, user.response?.company?.country, user.response?.company?.id],
    )


    useEffect(() => {
        const selectedCompanies = selectedGroup?.groupCompanies.map(companiesToOptions) ?? []
        const selectedDrivers = selectedGroup?.groupDrivers.map(driverToOptions) ?? []
        const groupName = selectedGroup?.name ?? ''

        if (open) {
            getRelatedCompanies()
            getRelatedDrivers()
            setName(groupName)
            setSelectedCompanyOptions(selectedCompanies)
            setSelectedDriverOptions(selectedDrivers)
        }
        else {
            setName('')
            setSelectedCompanyOptions([])
            setSelectedDriverOptions([])
            setCompanyOptions([])
            setDriverOptions([])
        }

    }, [getRelatedCompanies, open, selectedGroup, getRelatedDrivers])

    const handleOnCompanySelect = useCallback(
        (values: IAutocompleteOption[]) => {
            setSelectedCompanyOptions(values)
        },
        [],
    )

    const handleOnDriverSelect = useCallback(
        (values: IAutocompleteOption[]) => {
            setSelectedDriverOptions(values)
        },
        [],
    )

    const handleOnSaveClick = useCallback(
        () => {
            if (selectedCompanyOptions.length === 0) {
                toast.warn(t('select_at_least_one_company'))
                return
            }

            if (name === '') {
                toast.warn(t('group_name_is_required'))
                return
            }

            let companyIds = selectedCompanyOptions.map(opt => opt.value)
            let driverIds = selectedDriverOptions.map(opt => opt.value)
            dispatch(setLoader(true))
            companyApi.saveAssociatedGroup(name, companyIds, driverIds, selectedGroup?.id)
                .then(() => {
                    toast.success(t('success_saving_associated_group'))
                    dispatch(setLoader(false))
                    handleClose()
                })
                .catch((err :any) => {
                    console.log('err', err)
                    toast.error(t('error_saving_associated_group'))
                    dispatch(setLoader(false))
                })
        },
        [dispatch, t, selectedCompanyOptions, name, handleClose, selectedGroup, selectedDriverOptions],
    )

    return (
        <>
            <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
                <DialogTitle>
                    <Typography>
                        {t('associate_group')}
                    </Typography>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item md={12}>
                            <TextField
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                variant="outlined"
                                fullWidth
                                label={t('associate_group_name')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldsIcon />
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    style: {
                                        fontWeight: 'bold'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <MultiAutocomplete
                                onChange={handleOnCompanySelect}
                                label={t('companies')}
                                options={companyOptions}
                                placeholder={t('search_company')}
                                values={selectedCompanyOptions}
                            />
                        </Grid>
                        <Grid item md={12}>
                            <MultiAutocomplete
                                onChange={handleOnDriverSelect}
                                label={t('drivers')}
                                options={driverOptions}
                                placeholder={t('search_driver')}
                                values={selectedDriverOptions}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnSaveClick} variant="contained" color="primary">
                        {t('save')}
                    </Button>
                    <Button onClick={handleClose} variant="contained">
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AssociateGroupDialog