import { IJobInfo } from "../../../../Application/DTOs/JobsDto/JobsDto.types";
import { IJobState, SET_JOB_LIST, SET_SELECTED_JOB, TJobActions } from "./types";

const initial_state: IJobState = {
    list: [],
    selectedJob: null
}

export function jobReducer(state: IJobState = initial_state, actions: TJobActions): IJobState {
    switch (actions.type) {
        case SET_JOB_LIST:
            const jobs: IJobInfo[] = actions.payload as IJobInfo[]
            
            state.list = jobs

            return Object.assign({}, state)

        case SET_SELECTED_JOB:
            const selectedJob: IJobInfo = actions.payload as IJobInfo

            state.selectedJob = selectedJob

            return Object.assign({}, state)
    
        default:
            return state
    }

}